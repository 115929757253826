import React, { useContext, useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import SelectProduct from "./SelectProduct";
import SendRequest from "./SendRequest";
import SetPriceField from "./SetPriceField";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Checkbox, TextField } from "@material-ui/core";
import { RateContractContext } from "../../../common/RateContractProvider";
import { ColorButton } from "../../../common/Components/ColorButton";
import { prospectDataforGRContract } from "../../../backend/getDataFromFirestore";

// table view
export const ProductsTable = ({
  isPAN,
  city,
  category,
  subCategory,
  prospectDetails,
  onlyShow = false,
}) => {
  const [products, setProducts] = useState([]);

  const { selectedProducts, changePrice } = useContext(RateContractContext);
  const db = useFirestore();
  let productsRef;
  if (isPAN && category && subCategory) {
    productsRef = db
      .collection("PAN-Products")
      .where("Product Sub-Category", "==", subCategory);
  } else if (isPAN && category) {
    productsRef = db
      .collection("PAN-Products")
      .where("Product Category", "==", category);
  } else if (isPAN) {
    productsRef = db.collection("PAN-Products");
  } else if (city && category && subCategory) {
    productsRef = db
      .collection("Products")
      .doc(city)
      .collection("Products")
      .where("Product Sub-Category", "==", subCategory);
  } else if (city && category) {
    productsRef = db
      .collection("Products")
      .doc(city)
      .collection("Products")
      .where("Product Category", "==", category);
  } else
    productsRef = db.collection("Products").doc(city).collection("Products");

  const { status, data } = useFirestoreCollectionData(productsRef);

  const [filterStatus, setFilterStatus] = useState("");
  const [prospectsData, setProspectsData] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [checkSelectAll, setCheckSelectAll] = useState(false);

  const [ratePer, setRatePer] = useState(0);

  useEffect(() => {
    console.log("It rendered");
    if (data) {
      setProducts(data);
      setSearchResult(searchProductsResult(searchValue, filterStatus));
      checkIfAllProductsAreSelected(data);
    }
  }, [data]);

  const role = localStorage.getItem("role");
  const userDocId = localStorage.getItem("userDocId");
  let seId;
  if (role === "Sales Executive") {
    seId = userDocId;
  }

  useEffect(() => {
    (async () => {
      const d = await prospectDataforGRContract(seId, prospectDetails);
      setProspectsData(d);
    })();
    for (let i = 0; i < prospectsData.length; i++) {
      for (let j = 0; j < products.length; j++) {
        if (
          products[j].id === prospectsData[i].id &&
          prospectsData[i]?.statusBySE
        ) {
          products[j]["isSendRequest"] = prospectsData[i].statusBySE;
          if (
            products[j].id === prospectsData[i].id &&
            prospectsData[i]?.statusBySE &&
            prospectsData[i]?.statusBySE === "Approved"
          ) {
            isPAN
              ? (products[j]["maxPrice"] = prospectsData[i].PmPrice)
              : (products[j]["finalPrice"] = prospectsData[i].PmPrice);
            break;
          }
        }
      }
      setProducts(products);
    }
    //console.log("use2", products);
  }, [products]);

  const checkIfAllProductsAreSelected = (data) => {
    for (const product of data) {
      if (
        selectedProducts[city] &&
        selectedProducts[city].hasOwnProperty(product.id)
      ) {
        continue;
      } else {
        setCheckSelectAll(false);
        return;
      }
    }
    setCheckSelectAll(true);
  };
  //  Search & filter in textbox

  const searchProductsResult = (name) => {
    return products.filter((pros) => {
      if (name) {
        return (
          pros["Product Name"]?.toLowerCase()?.includes(name?.toLowerCase()) ||
          pros["Product Category"]
            ?.toLowerCase()
            ?.includes(name?.toLowerCase()) ||
          pros["Product Sub-Category"]
            ?.toLowerCase()
            ?.includes(name?.toLowerCase()) ||
          pros["Brand Name"]?.toLowerCase()?.includes(name?.toLowerCase())
        );
      }
      return true;
    });
  };

  const search = (e) => {
    setSearchValue(e.target.value);
    const data = searchProductsResult(e.target.value);
    setSearchResult([...data]);
    //setSearchResult(searchProductsResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    setFilterStatus(status);
    setSearchResult(searchProductsResult(searchValue, status));
  };

  const selectAllProducts = (checked) => {
    for (const product of products) {
      const finalPrice = isPAN ? product["maxPrice"] : product["finalPrice"];
      changePrice(
        product.id,
        finalPrice,
        city,
        checked,
        product.MRP,
        product["GST%"],
        finalPrice
      );
    }
    setCheckSelectAll(checked);
  };

  async function getRatesPercentage() {
    for (const product of products) {
      if (selectedProducts[city].hasOwnProperty(product.id)) {
        let tempFinalPrice = "";
        if (
          product["maxPrice"] == null ||
          product["maxPrice"] === "undefined"
        ) {
          tempFinalPrice = product["finalPrice"];
        } else {
          tempFinalPrice = product["maxPrice"];
        }
        const finalPrice = Math.min(
          product["MRP"],
          tempFinalPrice * (1 + ratePer * 0.01)
        );
        changePrice(
          product.id,
          finalPrice,
          city,
          true,
          product.MRP,
          product["GST%"],
          finalPrice
        );
      }
    }
  }

  return (
    <div>
      {!onlyShow && (
        <div className="row pb-3">
          <div className="col-10 col-md-10">
            <TextField
              type="number"
              onWheel={() => document.activeElement.blur()}
              size="small"
              label="Set Rate Percentage "
              variant="outlined"
              value={ratePer}
              onChange={(e) => {
                const val = e.target.valueAsNumber;
                const finalVal = isNaN(val) || val <= 0 ? 0 : val;
                setRatePer(finalVal);
              }}
            />
            &nbsp;
            <ColorButton
              variant="contained"
              color="primary"
              onClick={(e) => {
                getRatesPercentage();
              }}
            >
              Get Rates
            </ColorButton>
            {/* </div>

        <div className="col-10 col-md-10"> */}
            &nbsp; &nbsp; &nbsp; &nbsp;
            <input
              type="text"
              className="search-box"
              placeholder="Search Product Name, Category, Sub-Category, Brand"
              onChange={(e) => {
                search(e);
              }}
              style={{ width: "475px", height: "40px" }}
            />
          </div>
        </div>
      )}

      {products.length === 0 ? (
        <div className="row justify-content-center">
          <p>No request's yet!</p>
        </div>
      ) : (
        <>
          {products.length > 0 && filterStatus === "" && searchValue === "" ? (
            <ProductsTableView
              products={products}
              isPAN={isPAN}
              city={city}
              prospectDetails={prospectDetails}
              onlyShow={onlyShow}
              checkSelectAll={checkSelectAll}
              selectAllProducts={selectAllProducts}
            />
          ) : (
            <>
              {searchResult.length > 0 ? (
                <ProductsTableView
                  products={searchResult}
                  isPAN={isPAN}
                  city={city}
                  prospectDetails={prospectDetails}
                  onlyShow={onlyShow}
                  checkSelectAll={checkSelectAll}
                  selectAllProducts={selectAllProducts}
                />
              ) : (
                <div className="row pb-3 justify-content-center">
                  <div>Search Not Found!</div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

// table view
const ProductsTableView = ({
  products,
  isPAN,
  city,
  prospectDetails,
  onlyShow = false,
  checkSelectAll,
  selectAllProducts,
}) => {
  const { selectedProducts, selectedProductsData } =
    useContext(RateContractContext);
  return (
    <div className="row align-items-center">
      <Table key="LeadTable">
        <Thead>
          <Tr>
            <Th>SKU ID</Th>
            <Th>Brand</Th>
            <Th>Product Image</Th>
            <Th>Product Name</Th>
            <Th>Product Category</Th>
            <Th>Product Sub Category</Th>
            <Th>MRP</Th>
            <Th>NexStoc Price (Excl. GST)</Th>
            <Th>GST%</Th>
            <Th>
              Select Product{" "}
              {!onlyShow && (
                <Checkbox
                  name="checkedC"
                  checked={checkSelectAll}
                  onChange={(e) => {
                    selectAllProducts(e.target.checked);
                  }}
                />
              )}
            </Th>
            <Th>Rate (Excl.GST)</Th>
            <Th>Rate (Incl.GST)</Th>
            {!onlyShow && <Th>Request To PM</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {products.map((pros, index) => {
            const finalPrice = Number(
              isPAN ? pros["maxPrice"] : pros["finalPrice"]
            ).toFixed(2);

            if (finalPrice === 0) return null;

            return (
              <Tr key={index}>
                <Td>{pros["SKU ID"]}</Td>
                <Td>{pros["Brand Name"]}</Td>
                <Td style={{ width: "10%" }} align="center">
                  <img
                    style={{
                      display: "block",
                      maxHeight: "50px",
                      maxWidth: "100%",
                    }}
                    src={
                      pros["Product Images"] && pros["Product Images"][0]
                        ? pros["Product Images"][0]
                        : pros["Product Image"]
                    }
                  />
                </Td>
                <Td>{pros["Product Name"]}</Td>
                <Td>{pros["Product Category"]}</Td>
                <Td>{pros["Product Sub-Category"]}</Td>

                <Td>{Number(pros["MRP"]).toFixed(2)}</Td>
                <Td>{finalPrice}</Td>
                <Td>{pros["GST%"]}%</Td>
                <Td>
                  <SelectProduct
                    city={city}
                    product={pros}
                    price={finalPrice ?? 0}
                    onlyShow={onlyShow}
                    isPAN={isPAN}
                  />
                </Td>
                {!onlyShow && (
                  <Td
                    style={{ width: "55px" }}
                    className={
                      (pros["MRP"] !== 0 &&
                        pros["MRP"] <
                          parseFloat(selectedProducts[city][pros.id]) +
                            (parseFloat(selectedProducts[city][pros.id]) *
                              parseFloat(pros["GST%"])) /
                              100) ||
                      finalPrice > selectedProducts[city][pros.id]
                        ? "backgroundRed"
                        : ""
                    }
                  >
                    <SetPriceField
                      city={city}
                      product={pros}
                      onlyShow={onlyShow}
                    />
                  </Td>
                )}
                {onlyShow && (
                  <Td>
                    {selectedProducts[city][pros.id] !== 0
                      ? selectedProducts[city][pros.id]
                      : " "}
                  </Td>
                )}
                {!onlyShow && (
                  <Td>
                    {parseFloat(
                      +selectedProductsData[pros?.id]?.Rate +
                        +(
                          selectedProductsData[pros?.id]?.Rate *
                          (pros["GST%"] / 100)
                        )
                    ).toFixed(2) || "Not Set"}
                  </Td>
                )}
                {onlyShow && (
                  <Td>
                    {selectedProducts[city][pros.id]
                      ? parseFloat(
                          selectedProducts[city][pros.id] +
                            selectedProducts[city][pros.id] *
                              +(pros["GST%"] / 100)
                        ).toFixed(2)
                      : " "}
                  </Td>
                )}

                {!onlyShow && (
                  <Td>
                    <SendRequest
                      city={city}
                      product={pros}
                      prospectDetails={prospectDetails}
                    />
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
};
