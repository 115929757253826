import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";

import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import swal from "sweetalert";
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { addCSH } from "../../backend/sendRequestToServer";
import { ColorButton } from "../../common/Components/ColorButton";
import {
  getSalesUserFromId,
  getSalesUserFromRole,
} from "../../backend/getDataFromFirestore";
import { getPageName } from "././../../common/const";

const ViewCSH = () => {
  const myRole = localStorage.getItem("role");

  const [blogs, setBlogs] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  var tempCSH = "";
  var emailtest = "";
  const rshDocId = localStorage.getItem("userDocId");

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    let response;
    if (myRole === "User Manager"|| myRole === "Super Admin") {
      response = db
        .collection("Users")
        .where("designation", "==", "City Sales Head")
        .where("userDeleted", "==", true);
    } else {
      response = db
        .collection("Users")
        .where("userDeleted", "==", true)
        .where("createdby", "==", rshDocId);
    }

    const data = await response.get();
    let cshList = [];

    data.docs.forEach((item) => {
      cshList.push(item.data());

      console.log(item.data());
    });

    setBlogs(cshList);
  };

  //  Search in textbox
  const searchBlogResult = (val) => {
    return blogs.filter((data) =>
      data.name.toLowerCase().includes(val.toLowerCase())
    );
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);

    setSearchResult(searchBlogResult(e.target.value));
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>

        <div className="row pb-3 justify-content-center">
          <input
            type="text"
            className="col-10 col-md-6 search-box"
            placeholder="Search City Sales Head"
            onChange={(e) => {
              search(e);
            }}
          />
        </div>
        {blogs.length > 0 && searchValue === "" ? (
          blogs.map((blog) => {
            if (blog.RSH) {
              tempCSH = blog.CSH;
            } else {
              tempCSH = "";
            }
            return <CshCard key={blog.id} blog={blog} />;
          })
        ) : (
          <>
            {searchResult.length > 0 ? (
              searchResult.map((blog) => {
                return <CshCard key={blog.id} blog={blog} />;
              })
            ) : (
              <>
                {searchValue !== "" ? (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ViewCSH;

const CshCard = ({ blog }) => {
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleReset = () => {
    window.location.reload();
  };

  function assignNewCsh(csh) {
    openDialog(csh);
  }

  // function edit blogs

  function EditCSH(id) {
    console.log("this is ", id);
    history.push("/edit-csh", id);
  }

  const { seEmails, setSeFromId } = SeHelper();

  const { dialogInfo, openDialog, closeDialog } = DialogHelper();

  return (
    <div>
      <AssignNewCSH dialogInfo={dialogInfo} handleClose={closeDialog} />
      <Media list key={blog.id} className="blog-card">
        <Media tag="li">
          {/* <Media left href="#">
               <Avatar src={blog.downloadURL} alt={blog.author} 
               style = {{
                   minHeight: 100,
                   minWidth: 100,
                   marginRight: 8
               }}/>
           </Media> */}

          <Media body>
            <Media heading>{blog.name}</Media>
            <p className="author-name">Email : {blog.email}</p>
            <p>Phone: {blog.phone}</p>
            <p>Designation: {blog.designation}</p>
            <p>Priviledges: {blog.privileges.toString()}</p>

            <p>Created SE: {seEmails.toString()}</p>

            <p className="view-edit">
              <ColorButton
                variant="contained"
                className={classes.button}
                aria-label="Show SE"
                onClick={(e) => setSeFromId(blog.SE)}
              >
                Show SE
              </ColorButton>
              <ColorButton
                variant="contained"
                className={classes.button}
                aria-label="Assign New RSH"
                onClick={(e) => assignNewCsh(blog)}
              >
                Assign New CSH
              </ColorButton>
            </p>
          </Media>
        </Media>
      </Media>
    </div>
  );
};

const SeHelper = () => {
  const [seEmails, setSeEmails] = useState([]);

  const setSeFromId = async (seIdList) => {
    if (seEmails.length > 0) return;

    console.log(seIdList);
    let seList = [];

    if (seIdList) {
      for (const id of seIdList) {
        const se = await getSalesUserFromId(id);
        if (se) seList.push(se.email);
      }
    } else {
      seList.push("No RSH Assigned");
    }
    console.log(seList);
    setSeEmails(seList);
  };
  return { seEmails, setSeFromId };
};

const AssignNewCSH = ({ dialogInfo, handleClose }) => {
  console.log(dialogInfo);
  const cshData = dialogInfo.data;
  const useStyles = makeStyles((theme) => ({
    form: {
      margin: "5px",
      borderRadius: "10px",
      padding: "5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
    },
  }));
  const classes = useStyles();

  const db = useFirestore();
  let history = useHistory();
  const [showSpinner, setShowSpinner] = React.useState(false);
  const handleReset = () => {
    window.location.reload();
  };

  // feild empty err hooks
  const [showNameErr, setShowNameErr] = React.useState(false);
  const [showPhoneErr, setShowPhoneErr] = React.useState(false);
  const [showLocationErr, setShowLocationErr] = React.useState(false);
  const [showQualErr, setShowQualErr] = React.useState(false);
  const [showEmailErr, setShowEmailErr] = useState("");

  const [employee, setEmployee] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const { name, email, phone, role, pageName } = employee;

  const onChange = (e) => {
    console.log(e.target.name);
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const handleAssignCSH = async (e) => {
    e.preventDefault();

    // if (
    //   email === "" ||
    //   name === "" ||
    //   phone === ""
    // ) {
    //   alert("Please enter all fields");
    // } else if (showEmailErr !== "" || showMobileErr !== "") {
    //   console.log("Email or phone is wrong");
    //   return false;
    // }
    if (name == "") {
      setShowNameErr("Please Enter  Name");
    } else if (email == "") {
      setShowEmailErr("Please enter Email");
    } else if (!validateEmail(email)) {
      setShowEmailErr("Enter valid Email");
    } else if (phone == "") {
      setShowPhoneErr("Please Enter Phone");
    } else if (!validatePhone(phone)) {
      setShowPhoneErr("Enter valid Mobile Number");
    } else if (!validatePhone(phone)) {
      setShowPhoneErr("Enter valid Mobile Number");
    } else {
      setShowSpinner(true);

      const status = await addCSH({
        ...cshData,
        name: employee.name,
        phone: employee.phone,
        email: employee.email,
        role: "City Sales Head",
        privileges: cshData.privileges,
        rshId: cshData.createdby,
      });

      if (status === 200) {
        setShowSpinner(false);
        swal("Success!", "Employee Added", "success").then((value) =>
          handleReset()
        );
      } else {
        setShowSpinner(false);
        swal("Failed!", "Failed to create Employee. Try Again!!!", "error");
      }
    }
  };

  // email validation
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  // phone validation
  const validatePhone = (mob) => {
    const re = /^[0-9]{10}$/;
    return re.test(mob);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      open={dialogInfo.open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        Assign New CSH For This Deleted CSH
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="container" className={classes.form}>
          <div className="row align-items-center justify-content-center ">
            <div className="col-12 col-md-10">
              <form noValidate autoComplete="off">
                <TextField
                  label="Name*"
                  name="name"
                  variant="outlined"
                  onChange={(e) => {
                    onChange(e);
                    setShowNameErr(false);
                  }}
                  style={{ paddingBottom: "8px" }}
                  error={showNameErr}
                  helperText={showNameErr}
                />
                <br />
                <TextField
                  label="Email*"
                  name="email"
                  variant="outlined"
                  onChange={(e) => {
                    onChange(e);
                    setShowEmailErr(false);
                  }}
                  style={{ paddingBottom: "8px" }}
                  error={showEmailErr}
                  helperText={showEmailErr}
                />

                <br />
                <TextField
                  variant="outlined"
                  aria-label="empty textarea"
                  label="Phone*"
                  name="phone"
                  onChange={(e) => {
                    onChange(e);
                    setShowPhoneErr(false);
                  }}
                  style={{ paddingBottom: "5px" }}
                  error={showPhoneErr}
                  helperText={showPhoneErr}
                />
                <br />
              </form>
              <div>
                {showSpinner ? (
                  <div>
                    <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                      <h5>Please DO NOT REFRESH the page...</h5>
                    </span>
                    <CircularProgress
                      style={{
                        color: "#18D26E",
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <ColorButton onClick={handleAssignCSH}>Assign CSH</ColorButton>
      </DialogActions>
    </Dialog>
  );
};

const DialogHelper = () => {
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    data: {},
  });

  function openDialog(val) {
    console.log("Open", val);
    setDialogInfo({
      open: true,
      data: val,
    });
  }
  function closeDialog() {
    setDialogInfo({
      open: false,
      data: {},
    });
  }
  return { dialogInfo, openDialog, closeDialog };
};
