import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  IconButton,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ClearIcon from "@material-ui/icons/Clear";
import {
  matchNavLabelToLink,
  matchNavLinkToLabel,
  drawerCollapseable,
} from "./const";
import { SIDEBAR_ITEMS } from "./const1";
import getPrivilegesForNav, {
  getRoleForNav,
  getEmailForNav,
} from "../backend/getPrivilegesForNav";
import { LogoutButton, ReferButton } from "./Components/ColorButton";
import { logOutUser } from "../backend/authenticateUser";
import { useUser } from "reactfire";
import {
  getMeetingsForNotification,
  getMeetingsOfMonth,
} from "../backend/getDataFromFirestore";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import ShareIcon from "@material-ui/icons/Share";
import { SidebarItem } from "./Components/SideMenuItem";
import clsx from "clsx";
import Meetings from "./NotificationComponent/MeetingNotification";
import {
  EMAIL_LOCAL_STORAGE_KEY,
  NAME_LOCAL_STORAGE_KEY,
  PRIVILEGES_LOCAL_STORAGE_KEY,
  ROLE_LOCAL_STORAGE_KEY,
  IS_COLLAPSED_LOCAL_STORAGE_KEY,
  USER_DOC_ID_LOCAL_STORAGE_KEY,
} from "./storageKey";

const drawerWidth = 256;
const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "white",
    color: "black",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
    height: "64px",
    zIndex: 1301,
    boxShadow: "8px 0px 20px 0px rgba(163, 163, 163, 0.25)",
  },
  menuButton: {
    fontFamily: "Roboto",
    size: "18px",
    marginLeft: "38px",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  drawerContainer: {
    padding: "10px 0px",
    marginTop: "8px",
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    paddingRight: "10px",
    paddingTop: "10px",
    color: "#18D26E",
  },
  menuButtonList: {
    marginRight: "auto",
    float: "right",
  },
  referButton: {
    marginLeft: "auto",
  },
  sidebar: { color: "#909090", fontWeight: 500 },
  fullList: {
    paddingTop: "16px",
  },
  //---------------
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxShadow: "8px 0px 20px 0px #A3A3A340",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
}));

const GetEmail = () => {
  const [details, setDetails] = useState({
    email: "",
    name: "",
    role: "",
  });

  const { data: firebaseUser } = useUser();

  useEffect(() => {
    getProfileDetails();
  }, [firebaseUser]);

  async function getProfileDetails() {
    let email = localStorage.getItem(EMAIL_LOCAL_STORAGE_KEY);
    let name = localStorage.getItem(NAME_LOCAL_STORAGE_KEY);
    let role = localStorage.getItem(ROLE_LOCAL_STORAGE_KEY);

    if (!email && !name && !role && firebaseUser) {
      const res = await getRoleForNav();

      console.log("khem", res);
      if (res === "") {
        return null;
      }

      const { role, name, email, userDocId } = res;

      localStorage.setItem(ROLE_LOCAL_STORAGE_KEY, role);
      localStorage.setItem(NAME_LOCAL_STORAGE_KEY, name);
      localStorage.setItem(EMAIL_LOCAL_STORAGE_KEY, email);
      localStorage.setItem(USER_DOC_ID_LOCAL_STORAGE_KEY, userDocId);
    }

    setDetails({ name, role, email });
  }

  return (
    <span>
      Welcome,{" "}
      <span style={{ color: "#18D26E" }}>
        {details.name}
        <p></p>
      </span>
      <p>
        Role: <b>{details.role}</b>
      </p>
      <span className="mr-3">
        Email: <b>{details.email}</b>
      </span>
    </span>
  );
};

function Navbar({ children }) {
  const { header, drawerContainer, closeIcon } = useStyles();
  const classes = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: JSON.parse(
      localStorage.getItem(IS_COLLAPSED_LOCAL_STORAGE_KEY) ?? "false"
    ),
  });

  const [privileges, setPrivileges] = useState({});
  const [upcomingMeetings, setUpcomingMeetings] = React.useState([]);
  const [missedMeetings, setMissedMeetings] = useState([]);
  const userRole = localStorage.getItem(ROLE_LOCAL_STORAGE_KEY);

  let publicUrl = process.env.PUBLIC_URL + "/";

  const { mobileView, drawerOpen } = state;
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    getPrivilegesList();
  }, []);

  useEffect(() => {
    (async function () {
      const notificationMeetings = await getMeetingsForNotification();

      const upcomingMeet = notificationMeetings.filter((meet) => {
        return meet.createdByRole === userRole;
      });

      setUpcomingMeetings(upcomingMeet);

      let date = new Date();
      let startDate = new Date(date.getFullYear(), date.getMonth(), 0);
      let endDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      const res = await getMeetingsOfMonth(new Date());
      const missingMeeting = res.filter((meet) => {
        var date = new Date(meet.date.seconds * 1000);
        return (
          date >= startDate &&
          date < endDate &&
          !meet.hasOwnProperty("checkIn") &&
          !meet.hasOwnProperty("missingReason") &&
          meet.createdByRole === userRole
        );
      });
      setMissedMeetings(missingMeeting);
    })();
  }, []);

  //Get list of privileges from localStorage or firebase
  const getPrivilegesList = async () => {
    let prev = JSON.parse(localStorage.getItem(PRIVILEGES_LOCAL_STORAGE_KEY));
    if (prev === undefined || prev === null) {
      prev = await getPrivilegesForNav();
      localStorage.setItem(PRIVILEGES_LOCAL_STORAGE_KEY, JSON.stringify(prev));
    }
    let prevObj = {};
    prev.forEach((val) => {
      prevObj[val] = 1;
    });
    let finalPrev = {};
    for (const prop in drawerCollapseable) {
      finalPrev[prop] = [];
      drawerCollapseable[prop].forEach((val) => {
        if (prevObj[val]) {
          finalPrev[prop].push(val);
        }
      });
    }
    // Todo: temp changed to static permissions
    // setPrivileges(finalPrev);

    setPrivileges(drawerCollapseable);
  };

  //  handle drawer open and close
  const handleDrawerOpen = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
    localStorage.setItem(IS_COLLAPSED_LOCAL_STORAGE_KEY, `${true}`);
  };
  const handleDrawerClose = () => {
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
    localStorage.setItem(IS_COLLAPSED_LOCAL_STORAGE_KEY, `${false}`);
  };

  const drawer = () => {
    return (
      <>
        <Drawer
          variant={mobileView ? "" : "permanent"}
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            }),
          }}
        >
          <div style={{ marginTop: "70px" }}>
            <span className={closeIcon}>
              <ClearIcon onClick={handleDrawerClose} />
            </span>
            <div className={drawerContainer}>{getNewDrawer()}</div>
          </div>
        </Drawer>
      </>
    );
  };

  const displayDesktop = () => {
    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: drawerOpen ? handleDrawerClose : handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        {nexstocLogo}
        <ReferButton
          className={classes.referButton}
          variant="outlined"
          startIcon={<ShareIcon />}
        >
          Refer a Friend
        </ReferButton>
        <div style={{ marginLeft: "10px" }}>{getMeetingNotification()}</div>
        <div style={{ marginLeft: "10px" }}>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            onClick: drawerOpen ? handleDrawerClose : handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>
        {nexstocLogo}
        <div style={{ marginLeft: "auto" }}>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const getNewDrawer = () => {
    return (
      <div>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <SidebarItem title={"Dashboard"} />

          {Object.keys(privileges).map((title, index) => {
            return privileges?.[title]?.length ? (
              <div key={index} className={classes.fullList}>
                <SidebarItem title={title} />
                {/* <Collapse in={val} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {privileges[title].map((item, pos) => {
                      return (
                        <ListItem
                          key={pos}
                          button
                          {...{
                            component: RouterLink,
                            to: matchNavLabelToLink[item],
                            color: "inherit",
                            style: {
                              textDecoration: "none",
                              color: "black",
                              display: "flex",
                              flexDirection: "row",
                              padding: "5px",
                            },
                            key: item,
                          }}
                        >
                          <ListItemIcon></ListItemIcon>
                          <ListItemText
                            primary={
                              localStorage.getItem(ROLE_LOCAL_STORAGE_KEY) === "Super Admin"
                                ? item
                                : item === "Get Leads" ||
                                  item === "Get Leads From NSH" ||
                                  item === "Get Leads From RSH"
                                ? "View Leads"
                                : item
                            }
                            style={{}}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse> */}
              </div>
            ) : null;
          })}
          <div className={classes.fullList}>
            <ListItem
              button
              onClick={(e) => logOutUser()}
              style={{
                paddingLeft: 22,
              }}
            >
              <ListItemIcon>{SIDEBAR_ITEMS["Log Out"]?.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.sidebar} variant="body1">
                    Log Out
                  </Typography>
                }
              />
            </ListItem>
          </div>
        </List>
      </div>
    );
  };

  const nexstocLogo = (
    <Link
      {...{
        component: RouterLink,
        to: "/dashboard",
      }}
    >
      <img
        style={{ width: "113px" }}
        src={publicUrl + "Nexstoc Final Logo.png"}
        alt="logo"
        className={mobileView ? "mob-logo" : "logo"}
      />
    </Link>
  );

  const getMenuButtons = () => {
    return (
      <div>
        <li className="menu-item-has-children">
          <AccountCircleIcon size="large" />
          <ul className="sub-menu">
            <li>
              <GetEmail />
            </li>
            <li>
              <LogoutButton onClick={(e) => logOutUser()}>Log Out</LogoutButton>
            </li>
          </ul>
        </li>
      </div>
    );
  };
  const getMeetingNotification = () => {
    return (
      <div>
        <li className="menu-item-has-children">
          <Badge
            badgeContent={missedMeetings.length + upcomingMeetings.length}
            color="secondary"
            style={{ alignSelf: "center" }}
          >
            <NotificationsNoneOutlinedIcon />
          </Badge>
          <ul className="sub-menu">
            <li
              style={{
                width: "586px",
                padding: "5px 0px",
              }}
            >
              <Meetings
                missedMeetings={missedMeetings}
                upcomingMeetings={upcomingMeetings}
              />
            </li>
          </ul>
        </li>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
      {drawer()}
      <div className="w-full overflow-hidden">{children}</div>
    </div>
  );
}
export default Navbar;
