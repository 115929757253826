import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  desDetails: {
    height: 100,
    overflow: "auto",
  },
});
const VideoField = ({ index, changeFileNames }) => {
  const classes = useStyles();
  const [fileNames, setFileNames] = useState([]);
  const [videoData, setImagesData] = useState();
  const [error, setError] = useState("");
  useEffect(() => {
      changeFileNames(index, videoData);
  }, [videoData]);
  console.log(fileNames, "names")
  console.log(videoData, "vide")

  return (
    <div>
      <div className={classes.desDetails}>
        Product video File name :
        {fileNames.name}
        {/* {fileNames.map((file, i) => {
          return <div key={i}>{file.name}</div>;
        })} */}
      </div>

      <input
        type="file"
        name="productVideo"
        accept="video/*"
        onChange={(e) => {
          if(e.target.files[0].size <= 25000000){
            setImagesData(e.target.files[0]);
            setFileNames(e.target.files[0]);
          }
          else{
            setError("Video size should be upto 25mb only")
          }
        }}
      />
      {error ? <>{error}</> : ""}
    </div>
  );
};

export default VideoField;
