import { Grid, Typography } from "@material-ui/core";
import { Icon } from "@iconify/react";
import { makeStyles } from "@material-ui/styles";
import { disableCache } from "@iconify/react";

disableCache("local");
const useStyles = makeStyles((theme) => ({
  cells: {
    textAlign: "start",
    textTransform: "capitalize",
    overflow: "hidden",
  },
}));

export function UpcomingMeeting({ meetings }) {
  const classes = useStyles();
  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  function formatDate(date) {
    let day = date.getDate();
    let month = date.toLocaleString("default", { month: "short" });
    let year = date.getFullYear();
    let finalDate = `${day}${nth(day)} ${month} ${year} `;
    return finalDate;
  }
  return (
    <>
      {/* {meetings?.length > 0 && (
        <h4 style={{ marginLeft: "10px" }}>Upcoming Meetings</h4>
      )} */}
      {meetings?.map((meet, index) => {
        let date = new Date(meet.date.seconds * 1000);
        return (
          <Grid
            style={{ width: "100%", marginBottom: "10px", marginLeft: "10px" }}
            container
            spacing={2}
          >
            <Grid item xs={3}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                style={{ paddingLeft: "5px" }}
                className={classes.cells}
              >
                {meet.name}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.cells}
              >
                {meet.agenda}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.cells}
              >
                <Icon
                  style={{ fontSize: "x-large", marginRight: "8px" }}
                  icon="material-symbols:calendar-month-outline-rounded"
                />
                {formatDate(date)}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
      {!meetings.length && (
        <p>
          <b>There is no upcoming Meeting</b>
        </p>
      )}
    </>
  );
}
