import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
// import {setTest} from '../firebaseConfig/firebase';
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "10px 15px 10px 15px",
    borderRadius: "10px",
    // padding : '12px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const AddCaseStudy = (props) => {
  // hooks
  const [CSAuthor, setCSAuthor] = React.useState("");
  const [CSTitle, setCSTitle] = React.useState("");
  const [CSDesc, setCSDesc] = React.useState("");

  const [TestimonialTitle, setTestimonialTitle] = React.useState("");
  const [TestimonialAuthor, setTestimonialAuthor] = React.useState("");
  const [CompanyName, setCN] = React.useState("");
  const [CompanyPosition, setCP] = React.useState("");
  const [TDesc, setTDesc] = React.useState("");

  const db = useFirestore();
  let history = useHistory();

  const [imageAsFile, setImageAsFile] = React.useState("");
  const [imageAsFile1, setImageAsFile1] = React.useState("");

  // validation hooks
  const [showCSAuthorErr, setShowCSAuthorErr] = React.useState(false);
  const [showCSTitleErr, setShowCSTitleErr] = React.useState(false);
  const [showCSDescErr, setShowCSDescErr] = React.useState(false);

  const [showTestimonialTitleErr, setShowTestimonialTitleErr] =
    React.useState(false);
  const [showTestimonialAuthorErr, setShowTestimonialAuthorErr] =
    React.useState(false);
  const [showCompanyNameErr, setShowCNErr] = React.useState(false);
  const [showCompanyPositionErr, setShowCPErr] = React.useState(false);
  const [showImageErr, setShowImageErr] = React.useState(false);
  const [showImageErr1, setShowImageErr1] = React.useState(false);
  const [showTDescErr, setShowTDescErr] = React.useState(false);

  // storage
  const storage = useFirebaseApp().storage();
  let downloadURL = "";
  let downloadURL1 = "";

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile((imageFile) => image);
    setShowImageErr(false);
  };

  const handleImageAsFile1 = (e) => {
    const image1 = e.target.files[0];
    setImageAsFile1((imageFile) => image1);
    setShowImageErr1(false);
  };

  async function AddCaseStudyFunction() {
    console.log("The link was clicked.");

    // db.collection("CaseStudy")
    //   .doc()
    //   .set({
    //     CSAuthor: CSAuthor ,
    //     CSTitle: CSTitle ,
    //     CSDesc: CSDesc ,
    //     })
    //   .then(() => {
    //     swal("Success!!!", "Case Study Added", "success");
    //   });

    if (CSTitle === "") {
      setShowCSTitleErr(true);
    } else if (CSDesc === "") {
      setShowCSDescErr(true);
    } else if (CSAuthor === "") {
      setShowCSAuthorErr(true);
    } else if (imageAsFile === "") {
      setShowImageErr(true);
    } else if (imageAsFile1 === "") {
      setShowImageErr1(true);
    } else {
      const uploadTask = storage
        .ref(`/caseStudyImages/${imageAsFile.name}`)
        .put(imageAsFile);
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          //catches the errors
          console.log(err);
        },
        async () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          downloadURL = await storage
            .ref(`/caseStudyImages/${imageAsFile.name}`)
            .getDownloadURL();
          console.log("sss", downloadURL);

          const uploadTask1 = storage
            .ref(`/caseStudyImages/${imageAsFile1.name}`)
            .put(imageAsFile1);
          uploadTask1.on(
            "state_changed",
            (snapShot1) => {
              //takes a snap shot of the process as it is happening
              console.log(snapShot1);
            },
            (err) => {
              //catches the errors
              console.log(err);
            },
            async () => {
              // gets the functions from storage refences the image storage in firebase by the children
              // gets the download url then sets the image from firebase as the value for the imgUrl key:
              downloadURL1 = await storage
                .ref(`/caseStudyImages/${imageAsFile1.name}`)
                .getDownloadURL();
              console.log("sss", downloadURL1);

              db.collection("CaseStudy")
                .add({
                  CSAuthor: CSAuthor,
                  CSTitle: CSTitle,
                  CSDesc: CSDesc,
                  downloadURL: downloadURL,
                  downloadURL1: downloadURL1,
                })
                .then(function (docRef) {
                  console.log("Document written with ID: ", docRef.id);
                  var washingtonRef = db.collection("CaseStudy").doc(docRef.id);

                  // Set the "capital" field of the city 'DC'
                  return washingtonRef
                    .update({
                      id: docRef.id,
                    })
                    .then(() => {
                      console.log("Document successfully updated!");
                      swal("Success!!!", "Case Study Added", "success");
                    })
                    .then(() => history.push("/view-caseStudy"))
                    .catch((error) => {
                      // The document probably doesn't exist.
                      console.error("Error updating document: ", error);
                    });
                })
                .catch(function (error) {
                  console.error("Error adding document: ", error);
                });
            }
          );
        }
      );
    }
  }

  async function AddTestimonialFunction() {
    console.log("The link was clicked.");

    // db.collection("Testimonials")
    //   .doc()
    //   .set({
    //     TestimonialTitle: TestimonialTitle ,
    //     TestimonialAuthor: TestimonialAuthor ,
    //     CompanyName: CompanyName ,
    //     CompanyPosition: CompanyPosition ,
    //     })
    //   .then(() => {
    //     swal("Success!!!", "Testimonial Added", "success");
    //   });

    if (TestimonialTitle === "") {
      setShowTestimonialTitleErr(true);
    } else if (TestimonialAuthor === "") {
      setShowTestimonialAuthorErr(true);
    } else if (CompanyName === "") {
      setShowCNErr(true);
    } else if (CompanyPosition === "") {
      setShowCPErr(true);
    } else if (TDesc === "") {
      setShowTDescErr(true);
    } else {
      console.log("The link was clicked.");
      const pol = "Test text final";

      db.collection("Testimonials")
        .add({
          TestimonialTitle: TestimonialTitle,
          TestimonialAuthor: TestimonialAuthor,
          CompanyName: CompanyName,
          CompanyPosition: CompanyPosition,
          TDesc: TDesc,
        })
        .then(function (docRef) {
          console.log("Document written with ID: ", docRef.id);
          var washingtonRef = db.collection("Testimonials").doc(docRef.id);

          // Set the "capital" field of the city 'DC'
          return washingtonRef
            .update({
              id: docRef.id,
            })
            .then(() => {
              console.log("Document successfully updated!");
              swal("Success!!!", "Testimonial Added", "success");
            })
            .then(() => history.push("/view-testimonial"))
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
  }

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Add Case Study
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                  <TextField
                    id="outlined-basic"
                    label="Case Study Title*"
                    variant="outlined"
                    onChange={(e) => {
                      setCSTitle(e.target.value);
                      setShowCSTitleErr(false);
                    }}
                    error={showCSTitleErr}
                    helperText={showCSTitleErr && "Please enter title"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Case Study Description*"
                    variant="outlined"
                    onChange={(e) => {
                      setCSDesc(e.target.value);
                      setShowCSDescErr(false);
                    }}
                    error={showCSDescErr}
                    helperText={showCSDescErr && "Please enter description"}
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Case Study Author Name*"
                    variant="outlined"
                    onChange={(e) => {
                      setCSAuthor(e.target.value);
                      setShowCSAuthorErr(false);
                    }}
                    error={showCSAuthorErr}
                    helperText={showCSAuthorErr && "Please enter author name"}
                    style={{ marginBottom: "10px" }}
                  />
                  <p style={{ marginBottom: "10px" }}>
                    Logo:
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleImageAsFile}
                    />
                    {showImageErr && (
                      <p style={{ fontSize: "12px", color: "red" }}>
                        Please select image
                      </p>
                    )}
                  </p>

                  <p style={{ marginBottom: "10px" }}>
                    Image:
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleImageAsFile1}
                    />
                    {showImageErr1 && (
                      <p style={{ fontSize: "12px", color: "red" }}>
                        Please select image
                      </p>
                    )}
                  </p>
                </form>
              </CardContent>
              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={AddCaseStudyFunction}
                >
                  Submit Case Study
                </ColorButton>
              </CardActions>

              <span className="mt-5"></span>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Add Testimonials
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                  <TextField
                    id="outlined-basic"
                    label="Testimonial Title*"
                    variant="outlined"
                    onChange={(e) => {
                      setTestimonialTitle(e.target.value);
                      setShowTestimonialTitleErr(false);
                    }}
                    error={showTestimonialTitleErr}
                    helperText={showTestimonialTitleErr && "Please enter title"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Author Name*"
                    variant="outlined"
                    onChange={(e) => {
                      setTestimonialAuthor(e.target.value);
                      setShowTestimonialAuthorErr(false);
                    }}
                    error={showTestimonialAuthorErr}
                    helperText={
                      showTestimonialAuthorErr && "Please enter author name"
                    }
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Company Name*"
                    variant="outlined"
                    onChange={(e) => {
                      setCN(e.target.value);
                      setShowCNErr(false);
                    }}
                    error={showCompanyNameErr}
                    helperText={
                      showCompanyNameErr && "Please enter company name"
                    }
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Company Position*"
                    variant="outlined"
                    onChange={(e) => {
                      setCP(e.target.value);
                      setShowCPErr(false);
                    }}
                    error={showCompanyPositionErr}
                    helperText={
                      showCompanyPositionErr && "Please enter comapny position"
                    }
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Testimonial Description*"
                    variant="outlined"
                    onChange={(e) => {
                      setTDesc(e.target.value);
                      setShowTDescErr(false);
                    }}
                    error={showTDescErr}
                    helperText={showTDescErr && "Please enter description"}
                    style={{ marginBottom: "10px" }}
                  />
                </form>
              </CardContent>

              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={AddTestimonialFunction}
                >
                  Submit Testimonials
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCaseStudy;
