import React, { useContext, useEffect } from "react";
import swal from "sweetalert";
import { getHistoryData } from "../../../backend/getDataFromFirestore";
import { sendProductRequestToPM } from "../../../backend/saveDataToFirestore";
import { ColorButton } from "../../../common/Components/ColorButton";
import { RateContractContext } from "../../../common/RateContractProvider";

const SendRequest = ({ city, product, prospectDetails }) => {
  const finalPrice = product.maxPrice ?? product.finalPrice;

  const { selectedProducts, changePrice, storeRequestForPM } =
    useContext(RateContractContext);
  async function sendProductRequest() {
    const price = selectedProducts[city][product.id]; //remain
    let productDetails = product;
    productDetails.prospectPrice = price;
    const res = await storeRequestForPM(city, product);
    if (res.successfull) {
      swal("Success!", "Request Sent to PM", "success").then(
        changePrice(
          product.id,
          price, //finalPrice,
          city,
          true,
          product.MRP,
          product["GST%"],
          finalPrice
        )
      );
    }
  }

  return (
    <div>
      <ColorButton
        className="mt-2"
        disabled={
          (product["MRP"] !== 0 &&
            product["MRP"] <= selectedProducts[city][product.id]) ||
          parseFloat(finalPrice).toFixed(2) <=
            selectedProducts[city][product.id] ||
          !selectedProducts[city].hasOwnProperty(product.id) ||
          (product.isSendRequest && product.isSendRequest === "Pending")
        }
        variant="contained"
        color="primary"
        onClick={() => {
          product.isSendRequest = "Pending";
          sendProductRequest();
        }}
        style={{
          backgroundColor:
            product.isSendRequest && product.isSendRequest === "Pending"
              ? "#16ce16"
              : null,
        }}
      >
        {product.isSendRequest && product.isSendRequest === "Pending"
          ? "Requested To PM"
          : "Send Request To PM"}
      </ColorButton>
    </div>
  );
};

export default SendRequest;
