import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getProductsCategoriesDetails } from "../../backend/getDataFromFirestore";
import Layout from "../../common/Layout";
import { ColorButton } from "../../common/Components/ColorButton";
import { MenuItem, TextField } from "@material-ui/core";
import RateContractProvider from "../../common/RateContractProvider";
import { ProductsTable } from "./Components/ProductsTable";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: "69ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  stepperColor: {
    color: "#18D26E",
  },
  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
  },
  products: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  category: {
    width: "29ch",
    marginRight: "2em",
    paddingBottom: "10px",
  },
  button: {
    margin: "1em",
    width: "20ch",
  },
}));

const ViewRateContract = () => {
  const classes = useStyles();

  const location = useLocation();

  console.log("----->", {});
  let isPAN = location.state.isPAN ?? false;

  let branchCities = location.state.branchCities ?? [];
  let selectedProducts = location.state.selectedProducts ?? {};

  let prospectId = location.state.prospectId ?? "";
  let prospectName = location.state.prospectName ?? "";
  const prospectDetails = { prospectId, prospectName };

  console.log(branchCities);

  const [city, setCity] = useState("");

  const [category, setCategory] = useState({});
  const [categoryDetails, setCategoryDetails] = useState({
    category: "",
    subCategory: "",
  });

  const [cities, setCities] = useState({});
  const [showProducts, setShowProducts] = useState(false);

  useEffect(() => {
    getCitiesAndSelectedProducts();
    // setSelectedProducts(newProducts);
  }, []);

  async function getCitiesAndSelectedProducts() {
    let newCities = {};

    if (isPAN) {
      newCities["PAN-Products"] = selectedProducts["PAN-Products"] ?? {};
      setCity("PAN-Products");
    } else {
      for (let city of branchCities) {
        if (newCities[city.value]) continue;
        // let sProducts = await getSelectedProductsOfCity(prospectId, branch.city);
        if (selectedProducts.hasOwnProperty(city.value))
          newCities[city.value] = selectedProducts[city.value];
        else newCities[city.value] = {};
      }
    }

    console.log(newCities);

    setCities(newCities);
  }

  async function getCategories() {
    let temp = await getProductsCategoriesDetails();
    console.log("temp : ", temp);
    setCategory(temp);
  }

  // const savePriceOfProduct = (price, id, checked = true) => {
  //   if (checked) {
  //     cities[city][id] = price;
  //     setCities({ ...cities });
  //     // setSelectedProducts({ ...cities[city] });
  //   } else {
  //     delete cities[city][id];
  //     setCities({ ...cities });

  //     // setSelectedProducts({ ...cities[city] });
  //   }
  //   // setSelectedProducts(newProducts);
  //   console.log(cities);
  // };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h3 className="text-center">Product List</h3>
        <RateContractProvider cityProducts={cities}>
          <form className={classes.form} noValidate autoComplete="off">
            <div>
              {!isPAN && (
                <TextField
                  required
                  id="outlined-branches"
                  select
                  label="Select City"
                  variant="outlined"
                  className={classes.category}
                  onChange={(e) => {
                    setCity(e.target.value);
                    setShowProducts(false);
                  }}
                >
                  {Object.keys(cities).map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                id="outlined-category"
                select
                label="Type of Category"
                variant="outlined"
                className={classes.category}
                onChange={(e) => {
                  setCategoryDetails({
                    ...categoryDetails,
                    category: e.target.value,
                  });
                  setShowProducts(false);
                }}
              >
                {Object.keys(category).map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                disabled={categoryDetails.category === ""}
                required
                id="outlined-sub-category"
                select
                label="Type of Sub-Category"
                variant="outlined"
                className={classes.category}
                onChange={(e) => {
                  setCategoryDetails({
                    ...categoryDetails,
                    subCategory: e.target.value,
                  });
                  setShowProducts(false);
                }}
              >
                {category[categoryDetails.category] ? (
                  category[categoryDetails.category].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value={""}>
                    No Option
                  </MenuItem>
                )}
              </TextField>

              <ColorButton
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setShowProducts(true);
                }}
              >
                Get Products
              </ColorButton>
            </div>
          </form>

          {showProducts && city && (
            <>
              <div className="row align-items-center justify-content-center mt-5">
                <ProductsTable
                  isPAN={isPAN}
                  city={city}
                  category={categoryDetails.category}
                  subCategory={categoryDetails.subCategory}
                  prospectDetails={prospectDetails}
                  onlyShow={true}
                />
              </div>
            </>
          )}
        </RateContractProvider>
      </div>
    </Layout>
  );
};

export default ViewRateContract;
