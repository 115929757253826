import React from "react";
import { Box, Divider, Tab, Tabs, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { UpcomingMeeting } from "./UpcomingMeeting";
import { MissedMeeting } from "./MissedMeeting";
import { Icon } from "@iconify/react";
import { disableCache } from "@iconify/react";

disableCache("local");
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTab-wrapper": {
      display: "-webkit-box",
    },
    "& .MuiTab-labelIcon": {
      minHeight: "0px",
    },
    "& .MuiTab-root": {
      minWidth: 0,
      marginRight: "15px",
    },
    "& .MuiTypography-body1": {
      fontFamily: "Roboto, Poppins",
      fontWeight: 600,
    },
  },
}));

export default function Meetings({ missedMeetings, upcomingMeetings }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
  }
  // <Badge
  //   badgeContent={missedMeetings.length}
  //   color="secondary"
  //   style={{ alignSelf: "center" }}
  // >
  //   <NotificationsNoneOutlinedIcon />
  // </Badge>;
  const missedMeetingIcon = (
    <Icon
      style={{ fontSize: "x-large", marginRight: "8px" }}
      icon="material-symbols:call-missed-outgoing"
    />
  );
  const upcomingMeetIcon = (
    <Icon
      style={{ fontSize: "x-large", marginRight: "8px" }}
      icon="material-symbols:event-upcoming-outline"
    />
  );
  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        className={classes.tabs}
        textColor="primary"
        aria-label="scrollable force tabs example"
      >
        <Tab
          label={`Missed Meetings (${missedMeetings.length})`}
          style={{ textTransform: "capitalize", marginRight: "100px" }}
          icon={missedMeetingIcon}
          {...a11yProps(0)}
        />
        <Tab
          label={`Upcoming Meetings (${upcomingMeetings.length})`}
          style={{ textTransform: "capitalize" }}
          icon={upcomingMeetIcon}
          {...a11yProps(1)}
        />
      </Tabs>
      <Divider />
      <TabPanel value={value} index={0}>
        <MissedMeeting meetings={missedMeetings} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UpcomingMeeting meetings={upcomingMeetings} />
      </TabPanel>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 10, paddingBottom: 0 }} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
