import React from "react";
import Marketing from "../Marketing";
import { ViewBlogTable } from "./ViewBlogTable";
import { useGetPermissions } from "context/PermissionsProvider";

function BlogView() {
  const { userPermissions } = useGetPermissions();

  const canViewBlog = userPermissions?.["Blog"]?.View;

  return (
    <Marketing>
      {canViewBlog ? (
        <ViewBlogTable />
      ) : canViewBlog === false ? (
        <h1>You do not have permission to view blogs</h1>
      ) : (
        "Loading..."
      )}
    </Marketing>
  );
}

export default BlogView;
