import { FirebaseAppProvider } from "reactfire";

// new fire base

// const firebaseConfig = {   
//   /* Add your config from the Firebase Console */                                           //New firebase
//   apiKey: "AIzaSyCHOXYdfNZUs78hQ2o0QTc6a7nT0J3p5Vo",
//   authDomain: "nexstoc-5d2eb.firebaseapp.com",
//   projectId: "nexstoc-5d2eb",
//   storageBucket: "nexstoc-5d2eb.appspot.com",
//   messagingSenderId: "312744466678",
//   appId: "1:312744466678:web:75fee681be27556431b4d7",
//   measurementId: "G-8KE3KM3VXK" 
// };


// old fire base
const firebaseConfig = {
  /* Add your config from the Firebase Console */

  // apiKey: "AIzaSyDdCeStVIQLfJwkLWrY62iU53qZKmI5xuQ",
  // authDomain: "nexstoc-8fb29.firebaseapp.com",
  // projectId: "nexstoc-8fb29",
  // storageBucket: "nexstoc-8fb29.appspot.com",
  // messagingSenderId: "7424837949",
  // appId: "1:7424837949:web:943846bebfdf726c111d54",
  // measurementId: "G-1ENMGPJDQN",

  /* Add your config from the Firebase Console */
  apiKey: "AIzaSyCHOXYdfNZUs78hQ2o0QTc6a7nT0J3p5Vo",
  authDomain: "nexstoc-5d2eb.firebaseapp.com",
  projectId: "nexstoc-5d2eb",
  storageBucket: "nexstoc-5d2eb.appspot.com",
  messagingSenderId: "312744466678",
  appId: "1:312744466678:web:75fee681be27556431b4d7",
  measurementId: "G-8KE3KM3VXK",
};

const Firebase = (props) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      {props.children}
    </FirebaseAppProvider>
  );
};

export default Firebase;
