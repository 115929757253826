import React, { useMemo } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import {
  actionConfig,
  resourcesConfig,
} from "globals/permissions/constants/constants";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingRight: 0,
    paddingLeft: 0,
  },

  header: {
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },

    "& .MuiTableCell-root": {
      padding: 10,
    },
  },

  actionName: {
    position: "absolute",
    padding: "10px 0px",
    fontWeight: 500,
  },

  dialog: {
    "& .MuiDialog-paperFullWidth": { maxHeight: "calc(100% - 250px)" },
  },

  accordion: {
    "& .MuiAccordionSummary-content": {
      margin: 0,
      width: "100%",
    },

    "& .MuiAccordion-rounded": {
      boxShadow: "none",
    },

    "& .MuiAccordionSummary-root": {
      background: "#FFF4D5",
      padding: 0,
      height: 62,
    },

    "& .MuiAccordionSummary-root.Mui-expanded": {
      maxHeight: 62,
      minHeight: 62,
      overflow: "hidden",
    },

    "& .MuiAccordion-root.Mui-expanded": {
      margin: 0,
    },

    "& .MuiTypography-body1": {
      fontSize: 19,
      fontWeight: 600,
      padding: "0px 10px",
    },

    "& .MuiAccordionDetails-root": {
      padding: 0,
    },

    "& .MuiTableCell-root": {
      padding: 10,
    },
  },
}));

const maxWidth = 300;

export const AddPermissions = ({
  open,
  handleClose,
  permissions,
  setPermissions,
}) => {
  const classes = useStyles();
  const [expandedId, setExpandedId] = React.useState(false);

  useMemo(() => {
    const schema = {};

    resourcesConfig.forEach(({ resources }) => {
      resources?.forEach((resource) => {
        schema[resource?.resource] = {};

        actionConfig.forEach((action) => {
          schema[resource?.resource][action?.action] = false;
        });
      });
    });

    setPermissions((prevSchema) => ({ ...schema, ...prevSchema }));
    return schema;
  }, []);

  function isTabActionChecked(resources, action) {
    return resources.every(
      ({ resource }) => permissions?.[resource]?.[action] === true
    );
  }

  function isAllTabActionChecked(action) {
    return resourcesConfig.every(({ resources }) =>
      isTabActionChecked(resources, action)
    );
  }

  const handleAccordion = (id) => {
    setExpandedId((prevId) => (prevId !== id ? id : false));
  };

  const handleTabAction = (resources = [], action, checked) => {
    resources?.forEach(({ resource }) => {
      handlePermissions(resource, action, checked);
    });
  };

  const handleAllTabAction = (action, checked) => {
    resourcesConfig.forEach(({ resources }) => {
      handleTabAction(resources, action, checked);
    });
  };

  const handlePermissions = (resource, action, checked) => {
    setPermissions((prevState) => {
      return {
        ...prevState,
        [resource]: {
          ...prevState[resource],
          [action]: checked,
        },
      };
    });
  };

  return (
    <Dialog
      maxWidth="md"
      className={classes.dialog}
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Add Privileges</DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.header}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    className={`max-w-[${maxWidth}px] overflow-hidden`}
                    width={maxWidth}
                  >
                    <Typography>Permission Type</Typography>
                  </TableCell>
                  {actionConfig.map(({ id: configId, action }) => (
                    <TableCell id={configId}>
                      <Checkbox
                        color="primary"
                        id={configId}
                        checked={isAllTabActionChecked(action)}
                        onChange={(e) =>
                          handleAllTabAction(action, e.target.checked)
                        }
                      />
                      <span className={classes.actionName}>{action}</span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={classes.accordion}>
          {resourcesConfig.map(
            ({ id: resourcesId, resourceGroup, resources }) => (
              <Accordion
                key={resourcesId}
                expanded={expandedId === resourcesId}
              >
                <AccordionSummary>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow key={resourcesId}>
                          <TableCell
                            className={`max-w-[${maxWidth}px] overflow-hidden`}
                            width={maxWidth}
                          >
                            <div onClick={() => handleAccordion(resourcesId)}>
                              <Typography>{resourceGroup}</Typography>
                            </div>
                          </TableCell>
                          {actionConfig.map(({ id: configId, action }) => (
                            <TableCell id={configId}>
                              <Checkbox
                                color="primary"
                                id={configId}
                                checked={isTabActionChecked(resources, action)}
                                onChange={(e) =>
                                  handleTabAction(
                                    resources,
                                    action,
                                    e.target.checked
                                  )
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {resources?.map(({ resource, id: resourceId }) => (
                          <TableRow key={resourceId}>
                            <TableCell
                              className={`max-w-[${maxWidth}px] overflow-hidden`}
                              width={maxWidth}
                            >
                              {resource}
                            </TableCell>
                            {actionConfig.map(({ id: configId, action }) => (
                              <TableCell id={configId}>
                                <Checkbox
                                  color="primary"
                                  id={configId}
                                  checked={permissions?.[resource]?.[action]}
                                  onChange={(e) =>
                                    handlePermissions(
                                      resource,
                                      action,
                                      e.target.checked
                                    )
                                  }
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            )
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
