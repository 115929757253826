import app from "firebase/app";

export default function deleteDataFromFirebase() {}

export async function deleteApprovalClient(id) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Prospects").doc(id);
  await docRef.delete().catch((e) => {
    return { successfull: false };
  });
  console.log("ApproveClient Deleted.");
  return { successfull: true };
}

export async function deleteMeetingWithID(id) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Meetings").doc(id);
  await docRef.delete().catch((e) => {
    return { successfull: false };
  });
  console.log("ApproveClient Deleted.");
  return { successfull: true };
}

export async function deleteLeadWithID(id) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Leads").doc(id);
  await docRef.delete().catch((e) => {
    return { successfull: false };
  });
  console.log("Lead Deleted.");
  return { successfull: true };
}

export async function deleteProspectWithID(id) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Prospects").doc(id);
  await docRef.delete().catch((e) => {
    return { successfull: false };
  });
  console.log("Lead Deleted.");
  return { successfull: true };
}

export async function deleteContactBlog(id) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Contact").doc(id);
  await docRef.delete().catch((e) => {
    return { successfull: false };
  });
  console.log("Contact Deleted.");
  return { successfull: true };
}

export async function deletePreviousFile(logo) {
  const storage = app.storage();
  // Create a reference to the file to delete
  const logoRef = storage.ref(logo);

  try {
    await logoRef.delete();
    return { successfull: true };
  } catch (e) {
    console.log(e);
    return { successfull: false };
  }
}

export async function deleteClientRegion(client_id, region_id) {
  const firestore = app.firestore();

  firestore
    .collection(`Clients/${client_id}/Branches`)
    .where("region_id", "==", region_id)
    .get()
    .then((querySnapshot) => {
      // Once we get the results, begin a batch
      var batch = firestore.batch();

      querySnapshot.forEach(function (doc) {
        // For each doc, add a delete operation to the batch
        console.log("delete branch data===>", doc.data());
        if (doc.data().gstImage) {
          deleteFileFromStorage(doc.data().gstImage);
        }
        if (doc.data().sezImage) {
          deleteFileFromStorage(doc.data().sezImage);
        }
        batch.delete(doc.ref);
      });

      // Commit the batch
      return batch.commit();
    })
    .then(() => {
      console.log("Region Branches Deleted");
    });

  firestore
    .collection(`Clients/${client_id}/Cities`)
    .where("region_id", "==", region_id)
    .get()
    .then((querySnapshot) => {
      // Once we get the results, begin a batch
      var batch = firestore.batch();

      querySnapshot.forEach(function (doc) {
        // For each doc, add a delete operation to the batch
        batch.delete(doc.ref);
      });

      // Commit the batch
      return batch.commit();
    })
    .then(() => {
      console.log("Region Cities Deleted");
    });

  firestore
    .collection(`Clients/${client_id}/Clusters`)
    .where("region_id", "==", region_id)
    .get()
    .then((querySnapshot) => {
      // Once we get the results, begin a batch
      var batch = firestore.batch();

      querySnapshot.forEach(function (doc) {
        // For each doc, add a delete operation to the batch
        batch.delete(doc.ref);
      });

      // Commit the batch
      return batch.commit();
    })
    .then(() => {
      console.log("Region Clusters Deleted");
    });

  const docRef = firestore
    .collection(`Clients/${client_id}/Regions`)
    .doc(region_id);

  try {
    await docRef.delete();
    console.log("Client Region Deleted.");
    return { successfull: true };
  } catch (e) {
    console.log(e);
    return { successfull: false };
  }
}

export async function deleteRegionCluster(client_id, cluster_id) {
  const firestore = app.firestore();

  firestore
    .collection(`Clients/${client_id}/Branches`)
    .where("cluster_id", "==", cluster_id)
    .get()
    .then((querySnapshot) => {
      // Once we get the results, begin a batch
      var batch = firestore.batch();

      querySnapshot.forEach(function (doc) {
        // For each doc, add a delete operation to the batch
        console.log("delete branch data===>", doc.data());
        if (doc.data().gstImage) {
          deleteFileFromStorage(doc.data().gstImage);
        }
        if (doc.data().sezImage) {
          deleteFileFromStorage(doc.data().sezImage);
        }
        batch.delete(doc.ref);
      });

      // Commit the batch
      return batch.commit();
    })
    .then(() => {
      console.log("Cluster Branches Deleted");
    });

  firestore
    .collection(`Clients/${client_id}/Cities`)
    .where("cluster_id", "==", cluster_id)
    .get()
    .then((querySnapshot) => {
      // Once we get the results, begin a batch
      var batch = firestore.batch();

      querySnapshot.forEach(function (doc) {
        // For each doc, add a delete operation to the batch
        batch.delete(doc.ref);
      });

      // Commit the batch
      return batch.commit();
    })
    .then(() => {
      console.log("Cluster Cities Deleted");
    });

  const docRef = firestore
    .collection(`Clients/${client_id}/Clusters`)
    .doc(cluster_id);

  try {
    await docRef.delete();
    console.log("Region Cluster Deleted.");
    return { successfull: true };
  } catch (e) {
    console.log(e);
    return { successfull: false };
  }
}

export async function deleteClusterCity(client_id, city_id) {
  const firestore = app.firestore();

  firestore
    .collection(`Clients/${client_id}/Branches`)
    .where("city_id", "==", city_id)
    .get()
    .then((querySnapshot) => {
      // Once we get the results, begin a batch
      var batch = firestore.batch();

      querySnapshot.forEach(function (doc) {
        // For each doc, add a delete operation to the batch
        console.log("delete branch data===>", doc.data());
        if (doc.data().gstImage) {
          deleteFileFromStorage(doc.data().gstImage);
        }
        if (doc.data().sezImage) {
          deleteFileFromStorage(doc.data().sezImage);
        }
        batch.delete(doc.ref);
      });

      // Commit the batch
      return batch.commit();
    })
    .then(() => {
      console.log("City Branches Deleted");
    });

  const docRef = firestore
    .collection(`Clients/${client_id}/Cities`)
    .doc(city_id);

  try {
    await docRef.delete();
    console.log("Cluster City Deleted.");
    return { successfull: true };
  } catch (e) {
    console.log(e);
    return { successfull: false };
  }
}

export async function deleteBranchData(client_id, branch_id) {
  const firestore = app.firestore();
  const docRef = firestore
    .collection(`Clients/${client_id}/Branches`)
    .doc(branch_id);

  try {
    await docRef.delete();
    console.log("Branch Deleted.");
    return { successfull: true };
  } catch (e) {
    console.log(e);
    return { successfull: false };
  }
}

export function deleteFileFromStorage(image) {
  // Delete the file using the delete() method
  const storage = app.storage();
  const ref = storage.refFromURL(image);
  ref
    .delete()
    .then(function () {
      // File deleted successfully
      console.log("File Deleted");
    })
    .catch(function (error) {
      console.log("File Deletion Failed");
      return { successful: false };
      // Some Error occurred
    });
  return { successful: true };
}

export async function deleteProductCityWise(cities, product) {
  const firestore = app.firestore();
  for (let city of cities) {
      firestore
      .collection("FranchiseProductApprovals")
      .doc(city)
      .collection("Products")
      .doc(product)
      .delete().then(()=>{
        console.log(`${product} deleted successfully from ${city}`)
      }).catch((e)=>{
        console.log(e)
        return false
      })
  }
  return true
}
