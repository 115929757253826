import React from "react";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import {getPageName} from "././../../common/const";

import Layout from "../../common/Layout";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    //   margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    textAlign: "justify",
    boxShadow: "0 3px 5px rgba(0,0,0,0.12), 0 3px 3px rgba(0,0,0,0.15)",
    fontFamily: "Roboto",
  },
  blog_desc: {
    justifyContent: "center",
    textAlign: "center",
    textAlign: "justify",
    marginTop: "5px",
  },
  authorDetail: {
    marginTop: "10px",
    padding: "10px",
    fontFamily: "Roboto",
  },
  author: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    fontSize: "18px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button : {
    float : 'right'
  },
});

// custom button

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#FCBF00",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#FCBF00",
    },
  },
}))(Button);

function ViewSellerDetails() {
  const location = useLocation();
  const classes = useStyles();
  let blog = location.state;
  let date = new Date(blog.date.seconds * 1000);
  console.log("selected blog : => ", location.state, "date is : ", date);
  return (
    <>
      <Layout />
      
      <div className="container mt-5 pt-5">
   
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ textAlign: "center" }}
                >
                  Seller Details
                </Typography>
                <p className={classes.blog_desc}>
                  <b>Seller Name:</b> {blog.companyName}
                </p>
                <p className={classes.blog_desc}><b>Seller Code:</b> {blog.sellerCode}</p>
                <p className={classes.blog_desc}><b>Address:</b> {blog.address}</p>
                <p className={classes.blog_desc}><b>city:</b> {blog.city}</p>
                <p className={classes.blog_desc}>
                  <b>indianState:</b> {blog.indianState}
                </p>
                <p className={classes.blog_desc}>
                  <b>postalCode:</b> {blog.postalCode}
                </p>
                <p className={classes.blog_desc}><b>panNo:</b> {blog.panNo}</p>
                <p className={classes.blog_desc}><b>gstNo:</b> {blog.gstNo}</p>
                <p className={classes.blog_desc}><b>shopNo:</b> {blog.shopNo}</p>
                <p className={classes.blog_desc}>
                  <b>Udyam Number:</b> {blog.udyamNo}
                </p>
                <p className={classes.blog_desc}>
                  <b>Udyog Aadhar Number:</b> {blog.udyogAadharNo}
                </p>
                <p className={classes.blog_desc}>
                  <b>Type of Enterprise:</b> {blog.typeEnterprise}
                </p>
                <p className={classes.blog_desc}>
                  <b>Type of Good:</b> {blog.typeGoods}
                </p>
                <p className={classes.blog_desc}>
                  <b>Additional License:</b> {blog.additionalLicense}
                </p>
                <p className={classes.blog_desc}>
                  <b>Date:</b>{" "}
                  {`${date.getDate()}/${
                    date.getMonth() + 1
                  }/${date.getFullYear()}`}
                </p>
              </CardContent>
            </Card>
            <br/>

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ textAlign: "center" }}
                >
                  Seller Owner Details
                </Typography>

                <p className={classes.blog_desc}>
                  <b>Owner Name:</b> {blog.branchHead}
                </p>
                <p className={classes.blog_desc}>
                  <b>Seller Email:</b> {blog.email}
                </p>
              </CardContent>
            </Card>
                  <br/>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ textAlign: "center" }}
                >
                  Seller Documents
                </Typography>

                <p className={classes.blog_desc}>
                  <b>GST Certificate:</b>
                  <ColorButton
                    variant="contained"
                    className={classes.button}
                    onClick={() => window.open(blog.gstCerti, "_blank")}
                  >
                    View
                  </ColorButton>
                </p>
                <p className={classes.blog_desc}>
                  <b>Rent Agreement:</b>
                  <ColorButton
                    variant="contained"
                    className={classes.button}
                    onClick={() => window.open(blog.rentAgrement, "_blank")}
                  >
                    View
                  </ColorButton>
                </p>

                <p className={classes.blog_desc}>
                  <b>Shop Lisence:</b>
                  <ColorButton
                    variant="contained"
                    className={classes.button}
                    onClick={() => window.open(blog.shopLicense, "_blank")}
                  >
                    View
                  </ColorButton>
                </p>

                <p className={classes.blog_desc}>
                  <b>Udyam Certificate:</b>
                  <ColorButton
                    variant="contained"
                    className={classes.button}
                    onClick={() => window.open(blog.udyamCerti, "_blank")}
                  >
                    View
                  </ColorButton>
                </p>

                <p className={classes.blog_desc}>
                  <b>Cancelled Cheque:</b>
                  <ColorButton
                    variant="contained"
                    className={classes.button}
                    onClick={() => window.open(blog.cancelledChq, "_blank")}
                  >
                    View
                  </ColorButton>
                </p>
              </CardContent>
            </Card>
            <br/>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewSellerDetails;
