import { useState } from "react";

export const DialogHelper = () => {
    const [dialogInfo, setDialogInfo] = useState({
      open: false
    });
  
    function openDialog(val, type, index) {
      setDialogInfo({
        open: true,
      });
    }
    function closeDialog() {
      setDialogInfo({
        open: false
      });
    }
    return { dialogInfo, openDialog, closeDialog };
  };