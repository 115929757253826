import React from "react";
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import {
  CancelButton,
  StyledCreateButton,
} from "../../common/Components/ColorButton";
import { saveProfileData } from "../../backend/saveDataToFirestore";
import swal from "sweetalert";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { AssignedPrivilege } from "./AssignedPrivilege";
import { AddPermissions } from "./AddPermissions";

const useStyles = makeStyles((theme) => ({
  privilegeButton: {
    width: "100%",
    marginTop: 24,
    marginBottom: 48,
    height: 42,
    textTransform: "capitalize",
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
  },
}));

const AddProfile = ({ handleDrawerClose }) => {
  const [open, setOpen] = React.useState(false);

  const [profileName, setProfileName] = useState(null);
  const [permissions, setPermissions] = React.useState({});

  const handleReset = () => {
    setProfileName(null);
    setPermissions({});
  };

  const handleModal = () => {
    setOpen((state) => !state);
  };

  const classes = useStyles();
  const [showSpinner, setShowSpinner] = useState(false);

  const addNewProfile = async () => {
    if (!profileName) return;

    setShowSpinner(true);

    const result = await saveProfileData({ name: profileName, permissions });

    if (result.successful) {
      await swal("Success!", "Profile Added", "success");
      handleReset();
      handleDrawerClose(true);
    } else {
      swal("Failed!", "Failed to add Profile. Try Again!!!", "error");
    }

    setShowSpinner(false);
  };
  return (
    <div>
      <Typography
        variant="h5"
        style={{ fontWeight: 600, marginBottom: "28px" }}
      >
        Create Profile
      </Typography>
      <form
        className="flex flex-col"
        noValidate
        autoComplete="off"
        id="addEmpForm"
      >
        <TextField
          label="New Profile*"
          value={profileName}
          onChange={(e) => setProfileName(e.target.value)}
        />
      </form>
      <div>
        <Button
          className={classes.privilegeButton}
          variant="outlined"
          onClick={handleModal}
          startIcon={<AddIcon />}
        >
          Add Privileges
        </Button>
        <AddPermissions
          open={open}
          handleClose={handleModal}
          permissions={permissions}
          setPermissions={setPermissions}
        />
      </div>
      <div>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Assigned Privileges:
        </Typography>
        <AssignedPrivilege privileges={permissions} />
      </div>
      <div className="fixed bottom-0 mb-14 flex gap-1.5">
        <StyledCreateButton onClick={addNewProfile}>Create</StyledCreateButton>
        <CancelButton onClick={() => handleDrawerClose()}>Cancel</CancelButton>
      </div>
      <div>
        {showSpinner ? (
          <div>
            <span style={{ marginLeft: "10px", color: "#ff0000" }}>
              <h5>Please DO NOT REFRESH the page...</h5>
            </span>
            <CircularProgress
              style={{
                color: "#18D26E",
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AddProfile;
