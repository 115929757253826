import app from "firebase/app";

//params: ref like '/documents/file_name/ and file
export const uploadFileToStorage = (ref, file) => {
  const storage = app.storage();
  const uploadTask = storage.ref(ref).put(file);
  return uploadTask;
};

//params : uploadTask needed
export const getDownloadURL = async (uploadTask) => {
  const url = await (await uploadTask).ref.getDownloadURL();
  return url;
};
