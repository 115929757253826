import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  getCitiesFromProductApproval,
  getFranchiseofCity,
  getFranchiseOfCityWithProductApproved,
  getFranchisePendingProductDataOfCity,
  getProductDataOfCity,
  getVendorsOfCity,
} from "../../backend/getDataFromFirestore";
import { changeProductStatusToApproved } from "../../backend/saveDataToFirestore";
import { saveFinalProducts } from "../../backend/sendRequestToServer";
import { ColorButton } from "../../common/Components/ColorButton";
import ImageModel from "../../common/ImageDialog";
import Layout from "../../common/Layout";
import VideoModel from "../../common/VideoDialog";
import FinalPriceField from "../ShowProductsAverage/Components/FinalPriceField";
import IndivisualFranchisePrice from "./ViewFranchisePrice";

const useStyles = makeStyles({
  container: {
    maxHeight: "75vh",
    marginTop: "1em",
  },
  desDetails: {
    height: 100,
    overflow: "auto",
  },
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "100px",
  },
}))(TableRow);

function ApprovalFromFranchise() {
  const [filterStatus, setFilterStatus] = useState("");
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [productData, setProductData] = useState([]);
  const [franchisedata, setFranchiseData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [modelOpen, setModelOpen] = useState(false);
  const history = useHistory();

  const prospectStatus = [
    {
      value: "approved",
      label: "Approved",
    },
    {
      value: "pending",
      label: "Pending",
    },
  ];

  const handleOpen = () => {
    setModelOpen(true);
  };
  const handleClose = () => {
    setModelOpen(false);
  };

  const classes = useStyles();

  async function getCities() {
    const temp = await getCitiesFromProductApproval();
    setCities(temp);
    console.log(temp);
  }

  async function getFranchiseCity(passedCity) {
    const franchises = await getFranchiseofCity(passedCity);
    setFranchiseData(franchises);
  }
  console.log(franchisedata, "franchiseData");

  const searchLeadResult = (name, status) => {
    // console.log(name, status, searchResult);
    return franchisedata.filter((lead) => {
      if (name && status) {
        return lead["name"].toLowerCase().includes(name.toLowerCase());
      }
      if (name) {
        return (
          lead["name"].toLowerCase().includes(name.toLowerCase()) ||
          lead["email"].toLowerCase().includes(name.toLowerCase()) ||
          lead["mobile"].toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const getProducts = async (selectedCity) => {
    swal(
      "Recieving Franchise Products",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    const data = await getFranchisePendingProductDataOfCity(selectedCity);
    swal.close();
    if (data.products.length === 0 || !data.products) {
      swal("Error!", "Products not found !", "error");
    }
    setProductData(data.products);
  };
  console.log(productData, "productData");

  const searchProductsResult = (name, status) => {
    // return console.log(name, status, productData)
    let tempProducts = franchisedata;
    switch (status) {
      case "approved":
        tempProducts = tempProducts.filter((item) =>
          item["status"].toLowerCase().includes("approved")
        );
        break;
      case "pending":
        tempProducts = tempProducts.filter((item) =>
          item["status"].toLowerCase().includes("pending")
        );
        break;
      default:
        break;
    }
    return tempProducts.filter((pros) => {
      if (name) {
        return (
          (pros["name"] &&
            pros["name"].toLowerCase().includes(name.toLowerCase())) ||
          (pros["email"] &&
            pros["email"].toLowerCase().includes(name.toLowerCase())) ||
          (pros["mobile"] &&
            pros["mobile"].toLowerCase().includes(name.toLowerCase()))
        );
      }

      return true;
    });
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    // console.log(searchProductsResult(e.target.value, filterStatus))
    setSearchResult(searchProductsResult(e.target.value, filterStatus));
  };
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    // console.log(searchProductsResult(searchValue, status))
    setSearchResult(searchProductsResult(searchValue, status));
  };
  console.log(searchResult, "search res");

  const saveProductstoFinal = async () => {
    if (!city) {
      swal("Error", "Please Select City First", "error");
      return;
    }
    const swalRes = await swal(
      "Are you sure you want to save Final Product",
      "Please make sure you want to save products. You will not be able to change revert now.",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    swal(
      "Saving Final Products",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );

    const prodUpdate = await changeProductStatusToApproved(city);
    if (!prodUpdate) {
      swal.close();
      swal("Error!", "Error updating Products, Something went wrong", "error");
      return;
    }
    const res = await saveFinalProducts({ city: city });
    swal.close();
    if (res.status === 200) {
      swal("Success!", "Prices Updated", "success").then((val) =>
        history.push("show-final-products")
      );
    } else {
      swal("Error!", "Something went wrong", "error");
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    franchisedata && setSearchResult(franchisedata);
  }, [franchisedata]);
  return (
    <>
      <Layout />
      <div className=" m-5 pt-5">
        <h2 style={{ textAlign: "center" }}>Approval From Franchise</h2>
        <div className="row">
          <div className="col-12 col-md-12">
            <TextField
              required
              id="outlined-city"
              select
              label="City"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
                getFranchiseCity(e.target.value);
                getProducts(e.target.value);
              }}
              variant="outlined"
              style={{ marginBottom: "10px", width: "30%" }}
            >
              {cities.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            <div className="row">
              <div className="col-12 col-md-10">
                <input
                  type="text"
                  className="search-box col-12 col-md-8"
                  placeholder="Search Franchise Name, email, mobile no"
                  onChange={(e) => {
                    search(e);
                  }}
                />
              </div>
              <TextField
                className="col-12 col-md-2"
                required
                id="outlined-franchise-sorting"
                select
                label="Filter"
                // value={employee.indianState}
                // onClick={AssignDrop()}
                value={filterStatus}
                onChange={(e) => onFilter(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                // SelectProps={{
                //   classes: {
                //     root: classes.selectRoot,
                //     select: classes.select,
                //   },
                // }}
                style={{
                  width: "11ch",
                  float: "right",
                  verticalAlign: "center",
                }}
              >
                {prospectStatus.map((val, index) => (
                  <MenuItem key={val.value} value={val.value}>
                    {val.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <ColorButton
                variant="contained"
                color="primary"
                onClick={handleOpen}
              >
                View Product List
              </ColorButton>
              &nbsp;
              <ColorButton
                variant="contained"
                color="primary"
                onClick={saveProductstoFinal}
              >
                Save Final Product
              </ColorButton>
            </div>
          </div>
        </div>
        <TableContainer className={classes.container} component={Paper}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "10em" }}>
                  Nama Of Franchise
                </TableCell>

                <TableCell style={{ width: "10em" }}>E-mail Id</TableCell>

                <TableCell style={{ width: "10em" }}>Phone</TableCell>

                <TableCell style={{ width: "10em" }}>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {city && (!franchisedata || franchisedata.length == 0) ? (
                <p>There is No Franchise under this city.</p>
              ) : !city && searchValue ? (
                <p>Please Select City</p>
              ) : searchValue && searchResult && searchResult.length === 0 ? (
                <div className="row pb-3 justify-content-center">
                  <TableCell style={{ marginTop: "10px" }}>
                    Search Not Found!
                  </TableCell>
                </div>
              ) : (
                searchResult.map((p, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell component="th" scope="row">
                        {p["name"]}
                      </TableCell>

                      <TableCell>{p["email"]}</TableCell>

                      <TableCell>{p["mobile"]}</TableCell>

                      <TableCell>
                        {/* <ColorButton */}
                        {/* onClick={() => viewFranPrices(p, index)} */}
                        {/* > */}
                        {/* View */}
                        {/* </ColorButton> */}
                        {p["status"]}
                      </TableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <FranchiseDialog
          open={modelOpen}
          handleClose={handleClose}
          productData={productData}
          city={city}
        />
      </div>
    </>
  );
}

export default ApprovalFromFranchise;

const FranchiseDialog = ({ open, handleClose, productData, city }) => {
  const [productList, setProductList] = useState(productData);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState();
  const [avg, setAvg] = useState([]);
  const rates = React.useRef(null);

  const { dialogInfo, openDialog, closeDialog } = DialogHelper();
  const [franchiseData, setVendors] = useState({});
  const [productGST, setProductGST] = useState({});

  const [openModel, setOpen] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  const [dialogImages, setDialogImages] = useState([]);
  const [dialogVideo, setDialogVideo] = useState([]);

  useEffect(() => {
    rates.current = new Array(productData.length).fill();
    let avgTemp = new Array(productData.length).fill();
    for (let i = 0; i < productData.length; i++) {
      avgTemp[i] = getAvgPrice(productData[i]);
      rates.current[i] = avgTemp[i] ?? undefined;
    }
    setAvg(avgTemp);
  }, [productData]);

  useEffect(() => {
    async function getAllCityVendors(city) {
      const vens = await getVendorsOfCity(city);
      setVendors(vens);
    }
    getAllCityVendors(city);
  }, [city]);

  const viewFranPrices = (product, index) => {
    if (product["franchise"]) {
      Object.keys(product["franchise"]).map(
        (keyy) => (productGST[keyy] = product["GST%"])
      );
      setProductGST(productGST);
      openDialog(franchiseData, product["franchise"], avg[index], productGST);
    } else openDialog(franchiseData, {}, avg[index], {});
  };

  function getAvgPrice(product) {
    let sum = 0,
      count = 0,
      avg = 0;
    if (product["franchise"]) {
      for (const fKey of Object.keys(product["franchise"])) {
        sum += product["franchise"][fKey];
        if (product["franchise"][fKey] > 0) count++;
      }

      avg = sum > 0 && count > 0 ? sum / count : 0;

      console.log("Average is ", avg);
    }

    return parseFloat(avg).toFixed(2);
  }

  const classes = useStyles();

  const search = (e) => {
    setSearchValue(e.target.value);
    const data = searchLeadResult(e.target.value, "");
    setSearchResult([...data]);
  };

  const searchLeadResult = (name, status) => {
    // console.log(name, status, searchResult);
    return productData.filter((lead) => {
      if (name && status) {
        return lead["Product Name"].toLowerCase().includes(name.toLowerCase());
      }
      if (name) {
        return (
          lead["Product Category"].toLowerCase().includes(name.toLowerCase()) ||
          lead["Product Sub-Category"]
            .toLowerCase()
            .includes(name.toLowerCase()) ||
          lead["Brand Name"].toLowerCase().includes(name.toLowerCase()) ||
          lead["Product Name"].toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };
  // useEffect(() => {
  //   setProductList(productData);
  // }, [productData]);
  // console.log(productData, "dialog data");
  // console.log(productList, "dialog list");
  useEffect(() => {
    productList && setSearchResult(productList);
  }, [productData]);

  const openImageModel = (data) => {
    setOpen(true);
    setDialogImages(data);
  };
  const openVideoModel = (data) => {
    setVideoOpen(true);
    setDialogVideo(data);
  };
  const closeModel = () => {
    setOpen(false);
    setVideoOpen(false);
    setDialogImages([]);
    setDialogVideo([]);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Product List</DialogTitle>
      <DialogContent dividers={true}>
        <div style={{ margin: "10px 0px" }}>
          <input
            type="text"
            className="search-box col-12 col-md-8"
            placeholder="Search Product Name, catgory, sub-category, brand"
            onChange={(e) => {
              search(e);
            }}
          />
        </div>
        <TableContainer component={Paper}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "10em" }}>SKU Id</TableCell>

                <TableCell style={{ width: "10em" }}>Brand</TableCell>

                <TableCell style={{ width: "10em" }}>
                  Product Category
                </TableCell>

                <TableCell style={{ width: "10em" }}>
                  Product Sub-Category
                </TableCell>

                <TableCell style={{ width: "10em" }}>Product Name</TableCell>

                <TableCell style={{ width: "10em" }}>Description</TableCell>

                <TableCell style={{ width: "20em" }}>
                  Description Details
                </TableCell>

                <TableCell style={{ width: "20em" }}>Product Image</TableCell>

                <TableCell style={{ width: "20em" }}>Product Video</TableCell>

                <TableCell style={{ width: "10em" }}>UNIT</TableCell>

                <TableCell style={{ width: "10em" }}>GST%</TableCell>

                <TableCell style={{ width: "10em" }}>HSN CODE</TableCell>

                <TableCell style={{ width: "10em" }}>MRP</TableCell>

                <TableCell style={{ width: "10em" }}>Average Price</TableCell>

                <TableCell style={{ width: "10em" }}>
                  View Individual Franchise Prices
                </TableCell>

                <TableCell style={{ width: "10em" }}>
                  Final Price (Excl.GST)
                </TableCell>

                <TableCell style={{ width: "10em" }}>
                  Final Price (Incl.GST)
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {productData === undefined ||
              (productData && productData.length === 0) ? (
                <p>There are no products.</p>
              ) : searchValue === "" && searchResult.length === 0 ? (
                productData.map((p, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell component="th" scope="row">
                        {p["SKU ID"]}
                      </TableCell>

                      <TableCell>{p["Brand Name"]}</TableCell>

                      <TableCell>{p["Product Category"]}</TableCell>

                      <TableCell>{p["Product Sub-Category"]}</TableCell>

                      <TableCell>{p["Product Name"]}</TableCell>

                      <TableCell>{p["Description"]}</TableCell>

                      <TableCell>
                        <div className={classes.desDetails}>
                          {p["Description Details"]}
                        </div>
                      </TableCell>

                      <TableCell>
                        {p["Product Images"] && p["Product Images"][0] ? (
                          <img
                            onClick={() => {
                              openImageModel(p["Product Images"]);
                            }}
                            width="50%"
                            src={`${p["Product Images"][0]}`}
                          />
                        ) : (
                          <img width="50%" src={`${p["Product Image"]}`} />
                        )}
                      </TableCell>

                      <TableCell>
                        {p["Product Video"].length && (
                          <ColorButton
                            onClick={() => {
                              openVideoModel(p["Product Video"]);
                            }}
                          >
                            View Video
                          </ColorButton>
                        )}
                      </TableCell>

                      <TableCell>{p["UNIT"]}</TableCell>

                      <TableCell>{p["GST%"]}%</TableCell>

                      <TableCell>{p["HSN CODE"]}</TableCell>

                      <TableCell>{Number(p["MRP"]).toFixed(2)}</TableCell>

                      <TableCell>
                        {avg[index] === 0 ? "NA" : avg[index]}
                      </TableCell>

                      <TableCell>
                        <ColorButton onClick={() => viewFranPrices(p, index)}>
                          View
                        </ColorButton>
                      </TableCell>

                      <TableCell>
                        {p.finalPrice
                          ? parseFloat(p.finalPrice).toFixed(2)
                          : "Not Set"}
                      </TableCell>

                      <TableCell>
                        {p.finalPrice
                          ? Number(
                              +p.finalPrice +
                                +(p.finalPrice * (p["GST%"] / 100))
                            ).toFixed(2)
                          : "Not Set"}
                      </TableCell>
                    </StyledTableRow>
                  );
                })
              ) : searchValue && searchResult && searchResult.length === 0 ? (
                <div className="row pb-3 justify-content-center">
                  <TableCell style={{ marginTop: "10px" }}>
                    Search Not Found!
                  </TableCell>
                </div>
              ) : (
                searchResult.map((p, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell component="th" scope="row">
                        {p["SKU ID"]}
                      </TableCell>

                      <TableCell>{p["Brand Name"]}</TableCell>

                      <TableCell>{p["Product Category"]}</TableCell>

                      <TableCell>{p["Product Sub-Category"]}</TableCell>

                      <TableCell>{p["Product Name"]}</TableCell>

                      <TableCell>{p["Description"]}</TableCell>

                      <TableCell>
                        <div className={classes.desDetails}>
                          {p["Description Details"]}
                        </div>
                      </TableCell>

                      <TableCell>
                        {p["Product Images"] && p["Product Images"][0] ? (
                          <img width="50%" src={`${p["Product Images"][0]}`} />
                        ) : (
                          <img width="50%" src={`${p["Product Image"]}`} />
                        )}
                      </TableCell>

                      <TableCell>
                        {p["Product Video"].length && (
                          <ColorButton
                            onClick={() => {
                              openVideoModel(p["Product Video"]);
                            }}
                          >
                            View Video
                          </ColorButton>
                        )}
                      </TableCell>

                      <TableCell>{p["UNIT"]}</TableCell>

                      <TableCell>{p["GST%"]}%</TableCell>

                      <TableCell>{p["HSN CODE"]}</TableCell>

                      <TableCell>{Number(p["MRP"]).toFixed(2)}</TableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <ImageModel
          openModel={openModel}
          closeModel={closeModel}
          imagesData={dialogImages}
        />
        <VideoModel
          openModel={videoOpen}
          closeModel={closeModel}
          videoData={dialogVideo}
        />
        <IndivisualFranchisePrice
          dialogInfo={dialogInfo}
          handleClose={closeDialog}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const DialogHelper = () => {
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    data: {},
    fPrices: {},
    avg: 0,
    gst: {},
  });

  function closeDialog() {
    setDialogInfo({ ...dialogInfo, open: false, fPrices: {}, avg: 0, gst: {} });
  }

  function openDialog(data, fPrices, avg, gst) {
    setDialogInfo({
      data: data,
      open: true,
      fPrices: fPrices,
      avg: avg,
      gst: gst,
    });
  }

  return { dialogInfo, openDialog, closeDialog };
};
