import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import { getPageName, NO_IMAGE_URL, UnitValues } from "././../../common/const";
import {
  indianStates,
  enterpriseTypes,
  goodsTypes,
} from "../../common/const.js";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import clsx from "clsx";
// import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Layout from "../../common/Layout";
import "firebase/storage";
import {
  uploadFileToStorage,
  getDownloadURL,
} from "../../backend/uploadFileToStorage";
import { CircularProgress, Container } from "@material-ui/core";
import {
  getFranchiseProductsCounter,
  getSellerCount,
  incrementFranchiseProductsCount,
  incrementSellerCount,
} from "../../backend/getCounters";
import {
  saveCityCounter,
  savePANProductsApprovalsData,
  saveProductsApprovalsData,
  saveSellerData,
} from "../../backend/saveDataToFirestore";

import sendEmailAndPassToServer from "../../backend/sendRequestToServer";
import swal from "sweetalert";
import { useLocation } from "react-router";
import { ColorButton } from "../../common/Components/ColorButton.js";
import { editSellerData } from "../../backend/editDataInFirestore.js";
import {
  getAllSkuId,
  getCitiesList,
  getCitiesListFromState,
  getProductsCategoriesDetails,
} from "../../backend/getDataFromFirestore.js";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ImagesField from "./ImagesField";
import VideoField from "./VideoField";

const AddNewProduct = () => {
  const location = useLocation();
  let data = location.state;
  console.log("Data is ", data);

  const initalFormData = {
    "SKU ID": "",
    "Brand Name": "",
    "Product Category": "",
    "Product Sub-Category": "",
    "Product Name": "",
    Description: "",
    "Description Details": "",
    "Product Images": [],
    "Product Image": NO_IMAGE_URL,
    "Product Video": NO_IMAGE_URL,
    UNIT: "",
    "GST%": "",
    "HSN CODE": "",
    MRP: "",
    TAT: "",
    STATUS: "pending",
  };
  const [formData, setFormData] = React.useState(initalFormData);
  const filesData = React.useRef(null);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const [imageError, setImageError] = useState("");
  const [videoError, setVideoError] = useState("");
  const [cities, setCities] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState({});
  const [skuId, setSkuId] = useState("");
  const [count, setCount] = useState(0);

  console.log(formData, "formData");
  useEffect(() => {
    if (data !== undefined) {
      setFormData({
        ...data,
      });
    }
    getCitiesList().then((arr) => {
      setCities(arr);
    });
    getProductsCategoriesDetails().then((data) => {
      setCategoryDetails(data);
    });
  }, []);

  const changeValues = (e) => {
    setFormData({ ...formData, [`${e.target.name}`]: e.target.value });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: "10px",
      boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
    },
    form: {
      margin: "15px 15px 15px 15px",
      borderRadius: "10px",
      padding: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    textField: {
      width: "69ch",
    },
    margin: {
      margin: theme.spacing(1),
    },

    errMsg: {
      color: "red",
      fontWeight: "bold",
      fontSize: "small",
      textAlign: "left",
      marginBottom: "10px",
    },
  }));

  function getSteps() {
    return ["Product Details - 1", "Product Details - 2", "Upload Documents"];
  }

  const changeFileNames = (val) => {
    if (images.length < 4 && val[0].size <= 5000000) {
      setImages([...images, val[0]]);
      setImageError("");
    } else if (val[0].size > 5000000) {
      setImageError("Image Size Should be lesser then 5 mb only !");
    } else {
      setImageError("Cannot upload more than 4 images !");
    }
    // filesData.current = val;
    // console.log(filesData.current, "current");
  };

  const addVideos = (file) => {
    if (file[0].size <= 25000000) {
      setVideo(file);
    } else {
      setVideoError("Video File size should be lesser than 25 mb only !");
    }
  };

  console.log(video.length, "video");
  console.log(images, "images");
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <form className={classes.form} noValidate autoComplete="off">
              {/* <div> */}

              <TextField
                required
                id="outlined-skuId"
                label="SKU ID"
                name="SKU ID"
                variant="outlined"
                disabled
                value={formData["SKU ID"]}
                // onChange={changeValues}
                style={{ marginBottom: "10px" }}
              />

              <TextField
                required
                fullWidth
                select
                id="outlined-category"
                label="Product Category"
                name="Product Category"
                value={formData["Product Category"]}
                onChange={changeValues}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                {Object.keys(categoryDetails).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                disabled={
                  !formData["Product Category"] &&
                  formData["Product Category"] === ""
                }
                required
                fullWidth
                select
                id="outlined-city"
                label="Sub Category"
                name="Product Sub-Category"
                value={formData["Product Sub-Category"]}
                onChange={changeValues}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                {formData["Product Category"] ? (
                  categoryDetails[formData["Product Category"]].map(
                    (option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    )
                  )
                ) : (
                  <MenuItem disabled value={null}>
                    No Options
                  </MenuItem>
                )}
              </TextField>
              <TextField
                required
                id="outlined-postal-code"
                label="Product Name"
                name="Product Name"
                variant="outlined"
                value={formData["Product Name"]}
                onChange={changeValues}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                id="outlined-pan-number"
                label="Description"
                name="Description"
                multiline
                maxRows={4}
                // inputProps={{ maxLength: 10 }}
                variant="outlined"
                value={formData["Description"]}
                onChange={changeValues}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                id="outlined-gst-number"
                label="Description Details"
                name="Description Details"
                // inputProps={{ maxLength: 15 }}
                multiline
                maxRows={6}
                variant="outlined"
                value={formData["Description Details"]}
                onChange={changeValues}
                style={{ marginBottom: "10px" }}
              />
            </form>
          </>
        );

      case 1:
        return (
          <>
            <form className={classes.form} noValidate autoComplete="off">
              <TextField
                required
                id="outlined-address"
                label="Brand"
                name="Brand Name"
                variant="outlined"
                value={formData["Brand Name"]}
                onChange={changeValues}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                select
                id="outlined-category"
                label="UNIT"
                name="UNIT"
                value={formData["UNIT"]}
                onChange={changeValues}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                {Object.keys(UnitValues).map((option, index) => (
                  <MenuItem key={UnitValues[index]} value={UnitValues[option]}>
                    {UnitValues[option]}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                id="outlined-additional-license-number"
                type="number"
                label="GST%"
                name="GST%"
                onWheel={() => document.activeElement.blur()}
                variant="outlined"
                value={formData["GST%"]}
                onChange={changeValues}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                id="outlined-udyam-number*"
                label="HSN CODE"
                name="HSN CODE"
                inputProps={{ maxLength: 10 }}
                variant="outlined"
                value={formData["HSN CODE"]}
                onChange={changeValues}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                id="outlined-aadhar-number"
                type="number"
                label="MRP"
                onWheel={() => document.activeElement.blur()}
                name="MRP"
                variant="outlined"
                value={formData["MRP"]}
                onChange={(e) => {
                  if (/^\d+(\.\d{0,2})?$/.test(e.target.value)) {
                    changeValues(e);
                  }
                }}
                style={{ marginBottom: "10px" }}
              />

              {/* <TextField
                required
                id="outlined-aadhar-number"
                type="number"
                onWheel={() => document.activeElement.blur()}
                label="TAT"
                name="TAT"
                variant="outlined"
                value={formData["TAT"]}
                onChange={changeValues}
                style={{ marginBottom: "10px" }}
              /> */}
            </form>
          </>
        );
      case 2:
        return (
          <Container style={{ padding: "10px" }}>
            <h5 style={{ textAlign: "center" }}>Upload Task</h5>

            <InputLabel>Upload Product Images</InputLabel>
            <ImagesField
              changeFileNames={changeFileNames}
              video={addVideos}
              error={imageError}
            />
            {formData["Product Images"].length > 0 && "Show Uploaded Images"}
            <br />
            <br />
            <InputLabel>Upload Product Video</InputLabel>
            <br />
            <VideoField video={addVideos} error={videoError} />
            <br />
            <br />
          </Container>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  function checkFormCompletion() {
    if (activeStep === 0) {
      if (
        formData["SKU ID"] === "" ||
        formData["Product Category"] === "" ||
        formData["Product Sub-Category"] === "" ||
        formData["Product Name"] === "" ||
        formData.Description === "" ||
        formData["Description Details"] === ""
      ) {
        setErrorMsg("Fill all the form fields.");
        return false;
      }
    }

    if (activeStep === 1) {
      if (
        formData["Brand Name"] === "" ||
        formData.UNIT === "" ||
        formData["GST%"] === "" ||
        formData["HSN CODE"] === "" ||
        formData.MRP === ""
        // || formData.TAT === ""
      ) {
        setErrorMsg("Fill all the form fields.");
        return false;
      }
    }

    if (activeStep === 2) {
      if (images.length == 0) {
        setErrorMsg("Atleast upload 1 Image !");
        return false;
      }
    }
    return true;
  }

  const uploadImagesOfProduct = async () => {
    let ref = `/product-images/${formData["SKU ID"]}`;
    let imagesURL = [];
    if (images.length > 0) {
      for (let file of images) {
        let task = uploadFileToStorage(ref + `/${file}`, file);
        imagesURL.push(await getDownloadURL(task));
      }
    } else {
      imagesURL = [NO_IMAGE_URL];
    }

    // setFormData({ ...formData, "Product Images": imagesURL });
    formData["Product Images"] = [...imagesURL];
  };

  // LCk1BnkzB49Vkmn8soSz
  const uploadVideoOfProduct = async () => {
    let ref = `/product-video/${formData["SKU ID"]}`;
    let videoUrl = [];
    console.log(video.length, "function vide ");
    if (video.length > 0) {
      for (let file of video) {
        console.log("for loop if");
        let task = uploadFileToStorage(ref + `/${file}`, file);
        let vUrl = await getDownloadURL(task);
        console.log(vUrl, "videoUrl");
        console.log(videoUrl, "video url 1");
        videoUrl.push(vUrl);
        console.log(videoUrl, "video url 2");
      }
    } else {
      console.log("for loop else");
      videoUrl = [];
    }
    console.log(formData, "formData 1");
    console.log(videoUrl, "video url 3");
    // setFormData({ ...formData, "Product Video": videoUrl });
    console.log(formData, "formData 2");

    formData["Product Video"] = [...videoUrl];
    console.log(videoUrl, "video url 4");
  };
  console.log(formData, "formdata after video upload");
  async function saveDataToFirestore() {
    setShowSpinner(true);

    try {
      const swalRes = await swal(
        "Are you sure you want to Upload Product",
        "Please make sure you want to upload products. You will not be able to change now.",
        "warning",
        {
          buttons: {
            cancel: "Cancel",
            catch: {
              text: "Save",
              value: true,
            },
          },
        }
      );
      if (!swalRes) {
        return;
      }
      swal(
        "Saving Product",
        "Please wait it will take some time. Please do not refresh the page",
        "info",
        {
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: false,
        }
      );

      await uploadImagesOfProduct();
      await uploadVideoOfProduct();
      incrementFranchiseProductsCount(count);

      console.log(formData);

      console.log(formData, "try formdata");
      let res = await savePANProductsApprovalsData(cities, formData);
      swal.close();
      if (!res.successfull) {
        swal("Failed!", "Unable to Add Product.", "error").then((value) =>
          handleBack()
        );
      }
      swal("Success!", "Product Added", "success").then((value) =>
        handleReset()
      );
    } catch (e) {
      console.log(e);
      setShowSpinner(false);
      swal("Failed!", "Unable to Add Product.", "error").then((value) =>
        handleBack()
      );
    }
  }

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      if (checkFormCompletion()) {
        await saveDataToFirestore();
        return null;
      }
    }

    //nullchecks
    if (!checkFormCompletion()) {
      return null;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    // setFormData(initalFormData);
    // setActiveStep(0);
    window.location.reload();
  };

  const [errorMsg, setErrorMsg] = React.useState("");
  const [showSpinner, setShowSpinner] = React.useState(false);

  const getSkuInfo = async () => {
    let res = await getFranchiseProductsCounter();
    console.log(res, "count query");
    setFormData({
      ...formData,
      ["SKU ID"]: "F" + ("000000000" + (res + 1)).substr(-9),
    });
    setCount(res + 1);
  };

  useEffect(() => {
    getSkuInfo();
  }, []);

  return (
    <>
      <Layout />

      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>
          {formData.id ? "Edit Product Details" : "Add Product"}
        </h2>
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {activeStep === steps.length ? (
                    <div>
                      {showSpinner ? (
                        <div>
                          <span
                            style={{ marginLeft: "10px", color: "#ff0000" }}
                          >
                            <h5>Please DO NOT REFRESH the page...</h5>
                          </span>
                          <CircularProgress
                            style={{
                              color: "#18D26E",
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {getStepContent(activeStep)}
                      <CardActions
                        style={{ justifyContent: "center", fontWeight: "bold" }}
                      >
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          Back
                        </Button>
                        <ColorButton
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                        >
                          {activeStep === steps.length - 1 ? "Submit" : "Next"}
                        </ColorButton>
                        <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                          {errorMsg}
                        </span>
                      </CardActions>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewProduct;
