import React from "react";
import Marketing from "../Marketing";
import { ViewCareerTable } from "./ViewCareerTable";
import { useGetPermissions } from "context/PermissionsProvider";

function CareerView() {
  const { userPermissions } = useGetPermissions();

  const canViewCareer = userPermissions?.["Career"]?.View;

  return (
    <Marketing>
      {canViewCareer ? (
        <ViewCareerTable />
      ) : canViewCareer === false ? (
        <h1>You do not have permission to view career</h1>
      ) : (
        "Loading..."
      )}
    </Marketing>
  );
}

export default CareerView;
