import React, { useState } from "react";
import CommonTable from "../../../common/Table";
import { ActionCol } from "../../../common/Table/ActionCol";
import { Avatar } from "@material-ui/core";
import AdvancedSearch from "common/AdvancedSearch/AdvancedSearch";

const advancedSearchConfig = {
  searchProps: {
    searchTitle: "Author Name, Title, etc...",
    searchByOptions: ["author", "title"],
  },
};

export function ViewMarketingLinkTable() {
  const [marketingLinks, setMarketingLinks] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);

  if (!marketingLinks?.length) {
    return (
      <div className="row justify-content-center">
        <p>No lead's yet!</p>
      </div>
    );
  }

  return (
    <div>
      <AdvancedSearch
        data={marketingLinks}
        searchConfig={advancedSearchConfig}
        setFilteredData={setFilteredData}
      />
      <CommonTable data={filteredData} cols={columnsData} />
    </div>
  );
}

const columnsData = [
  {
    headerName: "Avatar",
    field: "downloadURL",
    width: 120,
    renderCell: ({ row }) => <Avatar alt="logo" src={row.downloadURL} />,
  },
  {
    headerName: "Marketing Link Title",
    field: "title",
    width: 180,
  },
  {
    headerName: "Author Name",
    field: "author",
    width: 180,
  },
  {
    headerName: "Description",
    field: "short_desc",
    width: 210,
  },
  {
    headerName: "Actions",
    field: "actions",
    width: 190,
    renderCell: ({ row }) => (
      <ActionCol
        data={row}
        onView={(row) => {
          console.log(row);
        }}
        onDelete={(row) => {
          console.log(row);
        }}
        onEdit={(row) => {
          console.log(row);
        }}
      />
    ),
  },
];

const initialData = [
  {
    author: "Arjun",
    downloadURL:
      "https://firebasestorage.googleapis.com/v0/b/nexstoc-5d2eb.appspot.com/o/blogImages%2FScreenshot%202023-02-14%20141438.png?alt=media&token=b487f88d-1f15-43a3-9738-d38d3dba18d9",
    id: "4LYkHnxlrp2sAJ3tKR3d",
    long_desc: "Hi",
    title: "Eat your frog",
    short_desc: "About Marketing Links",
  },
];
