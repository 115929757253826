import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import {setTest} from '../firebaseConfig/firebase';
import "firebase/firestore";
import { useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useHistory } from "react-router";
import { getPageName, indianStates } from "././../../common/const";
import { MenuItem } from "@material-ui/core";
import { getProductsCategories } from "../../backend/getDataFromFirestore";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const SubCategory = (props) => {
  // hooks
  const [category, setCategory] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [newSubCategory, setNewSubCategory] = React.useState("");
  const [indianState, setIndianState] = React.useState("");

  const db = useFirestore();
  let history = useHistory();

  // feild empty err hooks
  const [showTitleErr, setShowTitleErr] = React.useState(false);
  const [showDescErr, setShowDescErr] = React.useState(false);
  const [showLocationErr, setShowLocationErr] = React.useState(false);
  const [showQualErr, setShowQualErr] = React.useState(false);

  function handleReset() {
    setNewSubCategory("");
  }

  useEffect(() => {
    fetchCategory()
  }, [])

  const fetchCategory = async () => {
    const getCatList = await getProductsCategories();
    setCategory(getCatList);
  }

  async function AddCityFunction() {
    console.log("The link was clicked.");

      console.log(newSubCategory);

      var docRef = db
        .collection("ProductCategories")
        .where("main","==",selectedCategory)

      docRef
    //   .where("sub", "array-contains", newSubCategory)
        .get()
        .then((doc) => {
          if (doc.docs[0].data().sub.includes(newSubCategory)) {
            console.log("Document data:", doc.docs[0].data());
            swal("Error!!!", "Sub-Category Already Exists", "error");
          } else {
              let subCat = doc.docs[0].data().sub
              subCat.push(newSubCategory);
            // doc.data() will be undefined in this case
            console.log("No such document!", doc.docs[0].id, subCat);
            db.collection("ProductCategories")
              .doc(doc.docs[0].id)
              .update({
                sub : subCat
              })
              .then(() => {
                swal("Success!!!", "New Sub-Category Added", "success").then((e) =>
                  handleReset()
                );
              });
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    
  }

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>Add New Sub Category</h2>
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <form className={classes.form} noValidate autoComplete="off">
                  <TextField
                    fullWidth
                    id="outlined-corporate-office-state"
                    label="Category"
                    select
                    variant="outlined"
                    name="category"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    style={{ marginBottom: "10px", textAlign:"left" }}
                  >
                    {category.map((option) => (
                      <MenuItem key={option.main} value={option.main}>
                        {option.main}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-basic"
                    label="Add New Sub-Category"
                    variant="outlined"
                    value={newSubCategory}
                    onChange={(e) => {
                        setNewSubCategory(e.target.value);
                      
                    }}
                    style={{ marginBottom: "10px" }}
                  />
                </form>
              </CardContent>
              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={AddCityFunction}
                >
                  Submit
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubCategory;
