import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import "firebase/auth";
import { useUser } from "reactfire";
import { useHistory } from "react-router-dom";
import authenticateUser, {
  checkUserLoggedOutState,
} from "../../backend/authenticateUser";

import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

// import {setTest} from '../firebaseConfig/firebase';
import "firebase/firestore";
import swal from "sweetalert";
import "firebase/storage";
import { reset_password } from "../../backend/sendRequestToServer";
import { LogInButton } from "../../common/Components/ColorButton";
import { CircularProgress } from "@material-ui/core";
import { USER_UID_LOCAL_STORAGE_KEY } from "../../common/storageKey";

const useStyles = makeStyles({
  root: {
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  input: {},
  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
    marginBottom: "10px",
  },
});

const SuperAdminLogin = ({ getPrivileges }) => {
  let userUID = localStorage.getItem(USER_UID_LOCAL_STORAGE_KEY);

  const history = useHistory();

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [showEmailErr, setEmailErr] = useState("");
  const [showPassErr, setPassErr] = useState("");

  const [showLoading, setshowLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const { email, password } = user;

  const { data: firebaseUser } = useUser();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    console.log(e.target.name, e.target.value);
    if (e.target.name !== "email" && !validatePassword(e.target.value)) {
      // setPassErr("Choose Strong Password")
    } else {
      setPassErr(" ");
    }
  };

  // email validation
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  // email validation
  const validatePassword = (pass) => {
    const re = /^[A-za-z0-9]{7,}$/;
    return re.test(pass);
  };

  const onSubmit = async (e) => {
    if (e) e.preventDefault();
    if (email === "" || password === "") {
      alert("Please enter all fields");
    } else {
      if (validateEmail(email)) {
        setShowSpinner(true);
        setEmailErr(" ");

        console.log("Submit");
        const res = await authenticateUser(email, password);
        console.log("res=> ", res);
        if (res.success) {
          setShowSpinner(false);
          const email = res.authState.user.email;
          console.log("Email : ", email);
          getPrivileges();
        } else {
          setShowSpinner(false);
          swal("Error!", res.message, "error");
        }
      } else {
        setShowSpinner(false);
        setEmailErr("Please Enter Valid Email");
      }
    }
  };

  const reset = (e) => {
    e.preventDefault();
    if (email === "") {
      alert("Enter your email");
    } else {
      setshowLoading(true);
      reset_password({
        email: email,
      }).then(() => {
        setshowLoading(false);
        swal(
          "Email Sent!",
          "Please check your mail to reset the Password",
          "success"
        );
      });
    }
  };

  useEffect(() => {
    if (firebaseUser) {
      history.push("/dashboard");
    }
  }, [firebaseUser]);

  const classes = useStyles();

  const [showSpinner, setShowSpinner] = useState(false);

  function keyPress(e) {
    if (e.keyCode === 13) {
      console.log("value", e.target.value);
      onSubmit();
    }
  }

  return userUID || showLoading ? (
    <CircularProgress
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        color: "#18D26E",
      }}
    />
  ) : (
    <div className="container mt-5 pt-5">
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-6">
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Login
              </Typography>
              <form className={classes.form} noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  label="Email*"
                  variant="outlined"
                  type="email"
                  name="email"
                  onChange={onChange}
                  onKeyDown={keyPress}
                  style={{ marginBottom: "10px" }}
                />
                <span className={classes.errMsg}>{showEmailErr}</span>

                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password*
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={onChange}
                    onKeyDown={keyPress}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
                {/* <TextField
                  id="outlined-basic"
                  label="Password*"
                  variant="outlined"
                  type="password"
                  name="password"
                  onChange={onChange}
                /> */}

                <span className={classes.errMsg}>{showPassErr}</span>

                <Link
                  underline="hover"
                  href="#"
                  onClick={reset}
                  style={{ color: "#18D26E" }}
                >
                  Forgot Password?
                </Link>
              </form>
            </CardContent>
            {showSpinner ? (
              <div>
                <span>
                  {" "}
                  <CircularProgress
                    style={{
                      color: "#18D26E",
                    }}
                  />{" "}
                </span>{" "}
              </div>
            ) : (
              <div></div>
            )}

            <CardActions
              style={{ justifyContent: "center", fontWeight: "bold" }}
            >
              <LogInButton
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Login
              </LogInButton>
            </CardActions>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminLogin;
