import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";

const AdvancedSearch = ({
  data = [],
  searchConfig = {},
  setFilteredData,
  children,
}) => {
  const { searchProps = {}, sortProps } = searchConfig;

  const { searchTitle = "Name, email, etc...", searchByOptions = [] } =
    searchProps;

  const { isShowSort = true, sortByOptions = [] } = sortProps ?? {};

  const [searchText, setSearchText] = useState("");
  const [sortOption, setSortOption] = useState("default");

  useEffect(() => {
    setFilteredData?.(filteredAndSortedData());
  }, [searchText, sortOption]);

  const filteredAndSortedData = () => {
    if (!searchText && sortOption === "default") {
      return data;
    }

    return data
      .filter((item) => {
        if (!searchText) return true;
        return searchByOptions?.some((option) =>
          item?.[option]?.toLowerCase()?.includes(searchText?.toLowerCase())
        );
      })
      .sort((a, b) => {
        const key = sortByOptions?.find(
          (option) => option?.key === sortOption
        )?.key;
        if (key) {
          return a?.[key]?.localeCompare(b?.[key]);
        }
        return 0;
      });
  };

  return (
    <div className="p-4 flex justify-content-between align-items-center">
      <TextField
        type="text"
        className="w-[300px] h-10"
        placeholder={searchTitle}
        variant="outlined"
        label="Search"
        size="small"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div className="flex">
        {!!sortProps && !!isShowSort && (
          <TextField
            required
            select
            className="w-44 float-right mr-4"
            label="Sort By"
            size="small"
            variant="outlined"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <MenuItem value="default" key="default">
              All
            </MenuItem>
            {sortByOptions?.map(({ label, key }) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {children}
      </div>
    </div>
  );
};

export default AdvancedSearch;
