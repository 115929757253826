import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import { ColorButton } from "../../common/Components/ColorButton";

import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import swal from "sweetalert";
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import { useHistory } from "react-router-dom";
import { deleteSalesUser } from "../../backend/sendRequestToServer";
import { getSalesUserFromId } from "../../backend/getDataFromFirestore";
import { getPageName } from "././../../common/const";

const ViewNSH = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  var tempRSH = "";

  let history = useHistory();

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    const response = db
      .collection("Users")
      .where("userDeleted", "==", false)
      .where("designation", "==", "National Sales Head");
    const data = await response.get();
    let nshList = [];
    data.docs.forEach((item) => {
      nshList.push(item.data());

      console.log(item.data());
    });
    setBlogs(nshList);
  };
  // redirect to add csh
  const handleAddNSH = () => {
    history.push('/add-nsh')
  }

  //  Search in textbox
  const searchBlogResult = (val) => {
    return blogs.filter((data) =>
      data.name.toLowerCase().includes(val.toLowerCase())
    );
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);

    setSearchResult(searchBlogResult(e.target.value));
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
      <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>

        <div className="row pb-3 justify-content-center">
          <input
            type="text"
            className="col-10 col-md-6 search-box"
            placeholder="Search National Sales Head"
            onChange={(e) => {
              search(e);
            }}
          />
        </div>
        {blogs.length === 0 ? <><div className="row justify-content-center">
            <p style={{marginBottom:"10px"}}>No National Sales Head yet!</p>
            </div>
            <div className="row justify-content-center"><ColorButton 
              onClick={handleAddNSH}>Add NSH</ColorButton>

        </div></> : (<>
        {blogs.length > 0 && searchValue === "" ? (
          blogs.map((blog) => {
            return <NSHCard key={blog.id} blog={blog} />;
          })
        ) : (
          <>
            {searchResult.length > 0 ? (
              searchResult.map((blog) => {
                return <NSHCard key={blog.id} blog={blog} />;
              })
            ) : (
              <>
                {searchValue !== "" ? (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            )}
          </>
        )}
        </>
        )}
      </div>
    </Layout>
  );
};

export default ViewNSH;

const NSHCard = ({ blog }) => {
  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  let history = useHistory();

  const [nshData, setNshData] = useState(blog);

  // custom button

  // const ColorButton = withStyles((theme) => ({
  //   root: {
  //     backgroundColor: "#FCBF00",
  //     color: "white",
  //     fontWeight: "bold",
  //     "&:hover": {
  //       backgroundColor: "#FCBF00",
  //     },
  //   },
  // }))(Button);

  // function edit blogs

  function EditNSH(id) {
    console.log("this is ", id);
    history.push("/edit-nsh", id);
  }

  // function delete blogs
  async function DeleteNSH(id, email) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this employee!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: "Deleting the user. Do not Refresh",
          icon: "warning",
          buttons: false,
          dangerMode: true,
        });

        console.log("email", email);

        const res = await deleteSalesUser({
          id: id,
          email: email,
        });

        if (res.status === 200) {
          swal("Success", "Employee Successfully Deleted!!!", "success").then(
            (value) => handleReset()
          );
        } else {
          swal(
            "Error",
            "Error Occur while Deleting. Please Try Again.",
            "error"
          );
        }
      } else {
      }
    });
  }

  const handleReset = () => {
    window.location.reload();
  };

  const { rshEmails, setRshFromId } = RshHelper();

  return (
    <Media list key={nshData.id} className="blog-card">
      <Media tag="li">
        {/* <Media left href="#">
                <Avatar src={blog.downloadURL} alt={blog.author} 
                style = {{
                    minHeight: 100,
                    minWidth: 100,
                    marginRight: 8
                }}/>
            </Media> */}

        <Media body>
          <Media heading>{nshData.name}</Media>
          <p className="author-name">Email : {nshData.email}</p>
          <p>Phone: {nshData.phone}</p>
          <p>Designation: {nshData.designation}</p>
          <p>Priviledges: {nshData.privileges.toString()}</p>

          {rshEmails.length > 0 ? (
            <p>Created RSH: {rshEmails.toString()}</p>
          ) : null}

          <p className="view-edit">
            <ColorButton
              variant="contained"
              className={classes.button}
              aria-label="Show RSH"
              onClick={(e) => {
                setRshFromId(blog.RSH);
              }}
            >
              Show RSH
            </ColorButton>
            <IconButton
              aria-label="delete"
              id={blog.id}
              onClick={(e) => DeleteNSH(blog.id, blog.email)}
            >
              <DeleteIcon />
            </IconButton>
            {/* <IconButton aria-label="delete" 
                    id={blog.id} 
                    onClick = {(e) => EditBlog(blog)}>
                        <CreateIcon />
                    </IconButton> */}
            <ColorButton
              variant="contained"
              className={classes.button}
              onClick={() => EditNSH(blog)}
            >
              {" "}
              <IconButton
                aria-label="delete"
                id={blog.id}
                onClick={(e) => EditNSH(blog)}
              >
                <CreateIcon />
              </IconButton>
            </ColorButton>
          </p>
        </Media>
      </Media>
    </Media>
  );
};

const RshHelper = () => {
  const [rshEmails, setRshEmails] = useState([]);

  const setRshFromId = async (rshIdList) => {
    if (rshEmails.length > 1) return;

    console.log(rshIdList);
    let rshList = [];

    if (rshIdList) {
      for (const id of rshIdList) {
        const rsh = await getSalesUserFromId(id);
        if (rsh) rshList.push(rsh.email);
      }
    } else {
      rshList.push("No RSH Assigned");
    }
    setRshEmails(rshList);
  };

  return { rshEmails, setRshFromId };
};
