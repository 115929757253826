import { Link } from "react-router-dom";
import "./PermissionDenied.css";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#FCBF00",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#18D26E",
    },
  },
}))(Button);

const PermissionDenied = () => {
  return (
    // <div className="not-found">
    //     <h2>Sorry</h2>
    //     <p>That page cannot be found!</p>
    //     <Link to="/" >Back to the homepage...</Link>
    // </div>
    <div class="img-container-block">
      <img
        width="44%"
        height="44%"
        src="https://firebasestorage.googleapis.com/v0/b/nexstoc-8fb29.appspot.com/o/images%2F24451278.jpg?alt=media&token=72362847-ea14-467f-956b-f8ad0a1caae2"
      />
      <h3>
        You don't have permission to access this page. Please contact your
        Admin.
      </h3>
      <ColorButton
        variant="contained"
        onClick={() => {
          window.location.href = "./dashboard";
        }}
      >
        {" "}
        Go to Dashboard
      </ColorButton>
    </div>
  );
};

export default PermissionDenied;
