import React, { useState } from "react";
import initialData from "./locations.json";
import CommonTable from "../../../common/Table";
import LeadsManagementWrapper from "../LeadsManagement";
import AdvancedSearch from "common/AdvancedSearch/AdvancedSearch";

const advancedSearchConfig = {
  searchProps: {
    searchTitle: "Meeting Name, Client Email, etc...",
    searchByOptions: ["name", "email", "createdByRole", "createdByEmail"],
  },
  sortProps: {
    isShowSort: true,
    sortByOptions: [
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Email",
        key: "email",
      },
      {
        label: "Created By Role",
        key: "createdByRole",
      },
      {
        label: "Created By Email",
        key: "createdByEmail",
      },
    ],
  },
};
const ShowLocations = () => {
  const [data, setData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);

  return (
    <LeadsManagementWrapper>
      <AdvancedSearch
        data={data}
        searchConfig={advancedSearchConfig}
        setFilteredData={setFilteredData}
      />
      <CommonTable data={filteredData} cols={columnsData} />
    </LeadsManagementWrapper>
  );
};

export default ShowLocations;

const columnsData = [
  {
    headerName: "Meeting Name",
    field: "name",
    width: 150,
  },
  {
    headerName: "Client Email",
    field: "email",
    width: 150,
  },
  {
    headerName: "Role",
    field: "createdByRole",
    width: 150,
  },
  {
    headerName: "Creator Email",
    field: "createdByEmail",
    width: 190,
  },
  {
    headerName: "Check In",
    field: "checkIn",
    width: 190,
  },
  {
    headerName: "Check Out",
    field: "checkOut",
    width: 190,
  },
  {
    headerName: "Remarks",
    field: "remarks",
    width: 210,
  },
];
