import React, { useEffect, useState } from "react";
import CommonTable from "../../../common/Table";
import EmployeeManagement from "../EmployeeManagement";
import { ActionCol } from "../../../common/Table/ActionCol";
import ModalWrapper from "../../../common/ModalWrapper/ModalWrapper";
import AdvancedSearch from "common/AdvancedSearch/AdvancedSearch";
import { getUsers } from "backend/getDataFromFirestore";
import { USER_STATUS_TITLE } from "globals/constants";

const advancedSearchConfig = {
  searchProps: {
    searchTitle: "Employee Name, Email, etc...",
    searchByOptions: ["employeeName", "email", "phone"],
  },
};

const ViewEmployees = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchEmployees = async () => {
    const { status, data } = await getUsers();
    if (status === 200) {
      setEmployees(data);
      setFilteredData(data);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  function editLead(lead) {
    return;
  }

  const columnsData = [
    {
      headerName: "User Name",
      field: "name",
      width: 160,
    },
    {
      headerName: "Email ID",
      field: "email",
      width: 230,
    },
    {
      headerName: "Phone",
      field: "phone",
      width: 160,
    },
    {
      headerName: "Designation",
      field: "designation",
      width: 160,
    },
    {
      headerName: "Reporting Manger",
      field: "reportingManagerName",
      width: 160,
    },
    {
      headerName: "Status",
      field: "userStatus",
      width: 120,
      renderCell: (data) => {
        return <div>{USER_STATUS_TITLE[data?.value]}</div>;
      },
    },
    {
      headerName: "City",
      field: "city",
      width: 140,
    },
    {
      headerName: "Action",
      field: "actions",
      width: 160,
      renderCell: ({ row }) => (
        <ActionCol
          data={row}
          onEdit={() => editLead(row)}
          onDelete={() => {}}
        />
      ),
    },
  ];

  return (
    <EmployeeManagement>
      <AdvancedSearch
        data={employees}
        searchConfig={advancedSearchConfig}
        setFilteredData={setFilteredData}
      />
      <CommonTable data={filteredData} cols={columnsData} />
    </EmployeeManagement>
  );
};

export default ViewEmployees;
