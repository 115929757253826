import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import clsx from "clsx";
import { CircularProgress } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import {setTest} from '../firebaseConfig/firebase';

import "firebase/firestore";
import { useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useHistory, useLocation } from "react-router";
import { useState, useContext, useEffect } from "react";
import { addEmployee, editNSH } from "../../backend/sendRequestToServer";
import { pages } from "../../common/const";
import { getPageName } from "././../../common/const";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  pos: {
    marginTop: 20,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  label: {
    color: "#000",
  },

  textField: {
    "& .MuiInputBase-input": {
      color: "#000",
    },
  },

  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
    marginBottom: "10px",
  },
}));

const EditSE = (props) => {
  const location12 = useLocation();
  let selectedSE = location12.state;
  console.log("aa", selectedSE);

  // hooks
  const [location, setLocation] = React.useState("");
  const [title, setJobTitle] = React.useState("");
  const [jobDesc, setJobDesc] = React.useState("");
  const [qual, setQual] = React.useState("");

  const [showMobileErr, setMobileErr] = useState("");
  const [showEmailErr, setEmailErr] = useState("");

  const db = useFirestore();
  let history = useHistory();
  const [showSpinner, setShowSpinner] = React.useState(false);
  const handleReset = () => {
    window.location.href = "/view-se";
  };

  // feild empty err hooks
  const [showNameErr, setShowNameErr] = React.useState(false);
  const [showPhoneErr, setShowPhoneErr] = React.useState(false);
  const [showTitleErr, setShowTitleErr] = React.useState(false);
  const [showDescErr, setShowDescErr] = React.useState(false);
  const [showLocationErr, setShowLocationErr] = React.useState(false);
  const [showQualErr, setShowQualErr] = React.useState(false);

  //priviledges

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: "#fff",
        color: "#000",
      },
    },
  };

  const [employee, setEmployee] = useState({
    name: selectedSE.name,
    email: selectedSE.email,
    phone: selectedSE.phone,
    role: selectedSE.designation,
    pageName: selectedSE.privileges,
  });

  const { name, email, phone, role, pageName } = employee;

  const onChange = (e) => {
    console.log(e.target.name);
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const EditSE = async (e) => {
    e.preventDefault();
    if (name == "") {
      setShowNameErr("Please Enter  Name");
    } else if (phone == "") {
      setShowPhoneErr("Please Enter Phone");
    } else if (!validatePhone(phone)) {
      setShowPhoneErr("Enter valid Mobile Number");
    } else {
      setShowSpinner(true);
      const status = await editNSH({
        name,
        email,
        phone,
        role: "Sales Executive",
        privileges: pageName,
      });

      if (status === 200) {
        setShowSpinner(false);
        swal("Success!", "Employee Edited", "success").then((value) =>
          handleReset()
        );
      } else {
        setShowSpinner(false);
        swal("Failed!", "Failed to edit Employee. Try Again!!!", "error");
      }
    }
  };

  // email validation
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  // phone validation
  const validatePhone = (mob) => {
    const re = /^[0-9]{10}$/;
    return re.test(mob);
  };

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>

        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Edit Sales Executive
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                  <TextField
                    id="outlined-basic"
                    name="name"
                    value={employee.name}
                    variant="outlined"
                    onChange={(e) => {
                      onChange(e);
                      setShowNameErr(false);
                    }}
                    error={showNameErr}
                    helperText={showNameErr}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    value={employee.email}
                    name="email"
                    variant="outlined"
                    disabled
                    onChange={onChange}
                    style={{ marginBottom: "10px" }}
                    error={showDescErr}
                    helperText={showDescErr && "Please Enter Email"}
                  />

                  <TextField
                    variant="outlined"
                    aria-label="empty textarea"
                    value={employee.phone}
                    name="phone"
                    onChange={(e) => {
                      onChange(e);
                      setShowPhoneErr(false);
                    }}
                    style={{ marginBottom: "10px" }}
                    error={showPhoneErr}
                    helperText={showPhoneErr}
                  />
                </form>
              </CardContent>
              <div>
                {showSpinner ? (
                  <div>
                    <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                      <h5>Please DO NOT REFRESH the page...</h5>
                    </span>
                    <CircularProgress
                      style={{
                        color: "#18D26E",
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={EditSE}
                >
                  Edit SE
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSE;
