import app from "firebase/app";
import swal from "sweetalert";
import sendEmailAndPassToServer, {
  saveApprovedSellerProducts,
  saveProspectAsApproved,
  startTimerForSeLeave,
} from "./sendRequestToServer";
import Swal from "sweetalert2";

export default function editDataInFirestore() {}

//Delete any seller products from Back Office

export async function deleteProductsByID(id, sellerId) {
  const role = localStorage.getItem("role");
  //   "Reject Seller Products",
  //   "Are you sure you want to Reject Products",
  //   "warning",
  Swal.fire({
    title: "Are you sure you want to Reject Products? Please give us a reason",
    html: '<input id="swal-input1" class="swal2-input">',
    inputLabel: "Your Reason",
    showCancelButton: true,
    focusConfirm: false,

    preConfirm: async () => {
      if (document.getElementById("swal-input1").value) {
        const reason = document.getElementById("swal-input1").value;
        await rejectProduct(reason);
      } else {
        Swal.showValidationMessage("First input missing");
      }
    },
  });

  async function rejectProduct(reason) {
    try {
      const firestore = app.firestore();
      console.log(id);
      let docRef = firestore
        .collection("SellerProducts")
        .doc(sellerId)
        .collection("Products")
        .doc(id);

      if (role === "Product Manager") {
        await docRef.update({
          status: "Rejected By PM",
          rejectedByPM: reason,
        });
      } else {
        await docRef.update({
          status: "Rejected",
          rejectedBySA: reason,
        });
      }
      swal("Success!", "Rejected Seller Products", "success").then(() => {
        window.location.reload();
      });
    } catch (error) {
      alert(error);
    }
  }
}

//This function saves Franchise Data to firestore.
export async function editFranchiseData(data) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Franchises").doc(data.id);
  await docRef.update(data).catch((e) => {
    return { successfull: false };
  });
  console.log("Franchise edited.");
  return { successfull: true };
}

//This function saves Seller Data to firestore.
export async function editSellerData(data) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Sellers").doc(data.id);
  await docRef.update(data).catch((e) => {
    return { successfull: false };
  });
  console.log("Seller edited.");
  return { successfull: true };
}

//This function saves E-store Data to firestore.
export async function editEstoreData(data) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Estores").doc(data.id);
  await docRef.update(data).catch((e) => {
    return { successfull: false };
  });
  console.log("Estore edited.");
  return { successfull: true };
}

//This function changes Lead status to  Hot, cold, warm & lost Data to firestore.
export async function changeLeadStatus(data, id) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Leads").doc(id);
  await docRef.update({ status: data }).catch((e) => {
    return { successfull: false };
  });
  console.log("Lead edited.");
  return { successfull: true };
}

export async function editClientData(data) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Prospects").doc(data.id);
  await docRef.update(data).catch((e) => {
    return { successfull: false };
  });
  console.log("Client edited.");
  return { successfull: true };
}

export async function editApprovedClientData(data) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Clients").doc(data.id);
  await docRef.update(data).catch((e) => {
    return { successfull: false };
  });
  console.log("Client edited.");
  return { successfull: true };
}

export async function editMeetingData(data) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Meetings").doc(data.id);
  await docRef.update(data).catch((e) => {
    return { successfull: false };
  });
  console.log("Client edited.");
  return { successfull: true };
}

export async function changeConvertedInLead(leadId) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Leads").doc(leadId);
  await docRef.update({ converted: true }).catch((e) => {
    return { successfull: false };
  });
  console.log("Lead Converted");
  return { successfull: true };
}

//This function saves Final Product Rate Data to firestore.
export async function editFinalProductRate(city, product) {
  const firestore = app.firestore();
  console.log(city, product);
  const docRef = firestore
    .collection("FranchiseProductApprovals")
    .doc(city)
    .collection("Products")
    .doc(product.id);
  await docRef
    .update({
      finalPrice: product.finalPrice,
    })
    .catch((e) => {
      return { successfull: false };
    });
  console.log("Final Price Updated.");
  return { successfull: true };
}

//This function update Seller Individual Product Data to firestore.
export async function updateSellerProductsData(sellerId, data) {
  const firestore = app.firestore();

  const productRef = firestore
    .collection("SellerProducts")
    .doc(sellerId)
    .collection("Products")
    .doc(data.id);
  await productRef.update({ ...data }).catch((e) => {
    return { successfull: false };
  });
  console.log("Product saved.");
  return { successfull: true };
}

//This function saves approve status of Product to firestore.
export async function approveSellerProductsByPM(seller) {
  if (!seller) return { successfull: false };

  const firestore = app.firestore();
  const docRef = firestore.collection("SellerProducts").doc(seller);
  await docRef.update({
    status: "Approved",
  });

  await saveApprovedSellerProducts({ sellerId: seller });

  console.log("Final Status Updated.");
  return { successfull: true };
}

//This function saves approve status of Product to firestore.
export async function approveSellerProductsBySA(seller) {
  if (!seller) return { successfull: false };

  const firestore = app.firestore();
  const docRef = firestore.collection("SellerProducts").doc(seller);
  await docRef
    .update({
      approvedBySA: true,
    })
    .catch((e) => {
      return { successfull: false };
    });
  console.log("Final Status Updated.");
  return { successfull: true };
}

//This function saves approve status of Product to firestore.
export async function rejectSellerProductsByPM(seller) {
  if (!seller) return { successfull: false };

  const firestore = app.firestore();
  const docRef = firestore.collection("SellerProducts").doc(seller);
  const role = localStorage.getItem("role");
  await docRef
    .update(
      role === "Product Manager"
        ? {
            // status: "Rejected",
            rejectedBy: role,
          }
        : {
            status: "Rejected",
            rejectedBy: role,
          }
    )
    .catch((e) => {
      return { successfull: false };
    });
  console.log("Final Status Updated.");
  return { successfull: true };
}

//This function saves approve status of Product to firestore.
export async function rejectSellerProductsBySA(seller) {
  if (!seller) return { successfull: false };

  const firestore = app.firestore();
  const docRef = firestore.collection("SellerProducts").doc(seller);
  await docRef
    .update({
      rejectedBySA: true,
    })
    .catch((e) => {
      return { successfull: false };
    });
  console.log("Final Status Updated.");
}
export async function editLeadsData(leadData) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Leads").doc(leadData.id);
  await docRef.update(leadData).catch((e) => {
    return { successfull: false };
  });
  console.log("Lead Converted");
  return { successfull: true };
}

export async function updateLeadData(lead, nsh, rsh, csh) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Leads").doc(lead.id);
  await docRef
    .update({
      nsh: nsh,
      rsh: rsh,
      csh: csh ?? "",
    })
    .catch((e) => {
      return { successfull: false };
    });
  console.log("Lead Updated");
  return { successfull: true };
}

export async function saveProspectForApproval(pros) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Prospects").doc(pros.id);
  try {
    await docRef.update({ status: "Pending" });
    console.log("Prospect Status updated.");
    return { successfull: true };
  } catch (e) {
    return { successfull: false };
  }
}

export async function modifyProspectToApproved(pros) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Prospects").doc(pros.id);
  const leadRef = firestore.collection("Leads").doc(pros.leadId);

  try {
    await docRef.update({ status: "Approved" });
    await leadRef.update({ converted: true });

    await saveProspectAsApproved({ prospectId: pros.id });
    console.log("Prospect Status updated.");
    return { successfull: true };
  } catch (e) {
    return { successfull: false };
  }
}

export async function modifyProspectToRejected(pros) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Prospects").doc(pros.id);
  try {
    await docRef.update({ status: "Rejected" });
    console.log("Prospect Status updated.");
    return { successfull: true };
  } catch (e) {
    return { successfull: false };
  }
}

export async function modifyProspectPANStatus(pros, checked) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Prospects").doc(pros.id);
  try {
    await docRef.update({
      isPAN: checked,
      selectedProducts: {},
      selectedProductsData: {},
    });
    console.log("Prospect Status updated.");
    return { successfull: true };
  } catch (e) {
    return { successfull: false };
  }
}

export async function editProspectPriceForApproval(
  status,
  seId,
  prospectId,
  product,
  priceHistory,
  price
) {
  const firestore = app.firestore();
  const docRef = firestore
    .collection("PM-Requests")
    .doc(seId)
    .collection(prospectId)
    .doc(product.id);
  try {
    if (status === "Reject") {
      await docRef.update({
        status: "Rejected",
        PmPrice: price,
        prospectPriceHistory: priceHistory,
      });
    } else {
      await docRef.update({
        status: "Approved",
        PmPrice: product.prospectPrice,
        prospectPriceHistory: priceHistory,
      });
    }
    console.log("Prospect Price Status updated.");
    return { successfull: true };
  } catch (e) {
    console.log(e.message);
    return { successfull: false };
  }
}

//This function edit Final Product to firestore.
export async function editFinalProductsOfCity(city, product) {
  const firestore = app.firestore();

  //Using productId from reactFire hook due to a bug in saving final price api
  const productId = product["NO_ID_FIELD"];

  const docRef = firestore
    .collection("Products")
    .doc(city)
    .collection("Products")
    .doc(productId);
  await docRef.update(product).catch((e) => {
    return { successfull: false };
  });
  console.log(productId, "Products Updated.");
  return { successfull: true };
}

//This function saves meeting Check IN Data to firestore.
export async function updateCheckInToMeeting(meeting) {
  const firestore = app.firestore();
  const role = localStorage.getItem("role");
  let seDocRef;
  console.log({ meeting });
  const docRef = firestore.collection("Meetings").doc(meeting.id);
  if (role === "National Sales Head") {
    seDocRef = firestore.collection("Users").doc(meeting.nsh);
  } else if (role === "Regional Sales Head") {
    seDocRef = firestore.collection("Users").doc(meeting.rsh);
  } else if (role === "City Sales Head") {
    seDocRef = firestore.collection("Users").doc(meeting.csh);
  } else {
    seDocRef = firestore.collection("Users").doc(meeting.se);
  }
  await docRef
    .update({
      checkIn: app.firestore.FieldValue.serverTimestamp(),
    })
    .catch((e) => {
      console.log(e);
      return { successfull: false };
    });

  //Update SE user check in status and store meeting id
  await seDocRef.update({ checkInMeeting: meeting.id });

  console.log("Check In Updated.");
  return { successfull: true };
}

//This function saves meeting Check IN Data to firestore.
export async function updateLocationStatusToMeeting(meeting, status) {
  const firestore = app.firestore();
  console.log({ meeting });
  const docRef = firestore.collection("Meetings").doc(meeting.id);
  // status can be Left, Reentered
  let obj = {};
  if (status === "Left") {
    obj["locationLeft"] = app.firestore.FieldValue.serverTimestamp();
  } else if (status === "Reentered") {
    obj["locationReentered"] = app.firestore.FieldValue.serverTimestamp();
  }
  await docRef.update(obj).catch((e) => {
    return { successfull: false };
  });

  if (status === "Left") {
    const payload = { meetingId: meeting.id, userDocId: meeting.se };
    await startTimerForSeLeave(payload);
  }

  console.log("Check In Updated.");
  return { successfull: true };
}

//This function saves meeting Check Out Data to firestore.
export async function updateCheckOutToMeeting(meeting) {
  const firestore = app.firestore();
  let seDocRef;
  const role = localStorage.getItem("role");
  console.log({ meeting });
  const docRef = firestore.collection("Meetings").doc(meeting.id);
  await docRef
    .update({
      checkOut: app.firestore.FieldValue.serverTimestamp(),
    })
    .catch((e) => {
      return { successfull: false };
    });

  if (role === "National Sales Head") {
    seDocRef = firestore.collection("Users").doc(meeting.nsh);
  } else if (role === "Regional Sales Head") {
    seDocRef = firestore.collection("Users").doc(meeting.rsh);
  } else if (role === "City Sales Head") {
    seDocRef = firestore.collection("Users").doc(meeting.csh);
  } else {
    seDocRef = firestore.collection("Users").doc(meeting.se);
  }
  //Update SE user check in status and remove checkInMeeting field
  await seDocRef.update({ checkInMeeting: app.firestore.FieldValue.delete() });

  console.log("Check Out Updated.");
  return { successfull: true };
}

export async function modifyEstoreToApproved(estore) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Estores").doc(estore.id);

  try {
    await docRef.update({ status: "Approved" });
    sendEmailAndPassToServer({
      email: estore.email,
      password: estore.password,
    });
    console.log("Estore Status updated.");
    return { successfull: true };
  } catch (e) {
    console.log(e);
    return { successfull: false };
  }
}

export async function modifyEstoreToRejected(estore, remark) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Estores").doc(estore.id);
  try {
    await docRef.update({ status: "Rejected", remark });
    console.log("Estore Status updated.");
    return { successfull: true };
  } catch (e) {
    return { successfull: false };
  }
}
