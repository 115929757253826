import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  desDetails: {
    height: 120,
    overflow: "auto",
  },
});
const VideoField = ({ video, error }) => {

  return (
    <div>
      <input
        type="file"
        name="productImages"
        accept="video/*"
        multiple
        onChange={(e) => {
            video(e.target.files);
        }}
      />
      {error !== "" &&<p className="text-red">{error}</p>}
    </div>
  );
};

export default VideoField;
