import React, { useEffect, useState } from "react";
import Layout from "../../common/Layout";

import { Media } from "reactstrap";
import { Button, IconButton, MenuItem, TextField } from "@material-ui/core";
import { ColorButton, MarkVisited } from "../../common/Components/ColorButton";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import {
  changeLeadStatus,
  editProspectPriceForApproval,
} from "../../backend/editDataInFirestore";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteLeadWithID } from "../../backend/deleteDataFromFirestore";
import swal from "sweetalert";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  checkProspectPanStatus,
  getHistoryData,
  getProspetsForProductRequests,
} from "../../backend/getDataFromFirestore";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const ShowRequests = () => {
  let history = useHistory();
  let location = useLocation();
  const [leads, setLeads] = useState([]);
  const role = localStorage.getItem("role");
  const userDocId = localStorage.getItem("userDocId");

  const db = useFirestore();
  let seId;
  if (role === "Product Manager") {
    seId = location.state ? location.state.seId : "";
    if (seId === "") window.location.pathname = "/dashboard";
  } else {
    window.location.pathname = "/dashboard";
  }

  const [prospects, setProspects] = useState({});
  const [prospect, setProspect] = useState("");

  useEffect(() => {
    getProspetsForProductRequests(seId).then((data) => {
      setProspects(data);
    });
  }, []);
  const handleProspectId = async(e) => {
    setProspect(e.target.value);
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h3 style={{ textAlign: "center" }}>Requests From SE</h3>
        <TextField
          required
          fullWidth
          select
          id="outlined-prospect"
          label="Select Prospect"
          variant="outlined"
          value={prospect}
          onChange={(e) => handleProspectId(e)}
          style={{ marginBottom: "10px" }}
        >
          {Object.keys(prospects).map((option) => (
            <MenuItem key={option} value={option}>
              {prospects[option]}
            </MenuItem>
          ))}
        </TextField>

        <br />
        {prospect ? (
          <ShowProductsRequest seId={seId} prospectId={prospect} />
        ) : null}
      </div>
    </Layout>
  );
};

const ShowProductsRequest = ({ seId, prospectId }) => {
  const role = localStorage.getItem("role");

  const seDocRef = useFirestore()
    .collection("PM-Requests")
    .doc(seId)
    .collection(prospectId);

  const { status, data } = useFirestoreCollectionData(seDocRef);
  const [filterStatus, setFilterStatus] = useState("All");

  const [leads, setLeads] = useState([]);

  useEffect(() => {
    console.log("It rendered", seId, prospectId);
    if (data) {
      console.log(data);
      setLeads(data);
      setSearchResult(searchLeadResult(searchValue, filterStatus));
    }
  }, [data]);

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");

  //  Search & filter in textbox
  const searchLeadResult = (name, status) => {
    return data.filter((lead) => {
      if (lead && status !== "All") {
        return (
          lead["Product Name"].toLowerCase().includes(name.toLowerCase()) &&
          lead.status.toLowerCase() === status.toLowerCase()
        );
      }
      if (name || status === "All") {
        return lead["Product Name"].toLowerCase().includes(name.toLowerCase());
      }
      if (status) {
        return lead.status.toLowerCase() === status.toLowerCase();
      }
      return true;
    });
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchLeadResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    setSearchResult(searchLeadResult(searchValue, status));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  async function approveProspectPrice(product) {
    const swalRes = await swal(
      "Are you sure you want to Approve Prospect Price",
      "Please make sure information is correct. You will not be able to change it again.",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }

    const history = {
      price: product?.prospectPrice,
      role: role,
      date: new Date(),
    };
    // let priceHistory = product?.prospectPriceHistory;
    // priceHistory.push(history);
    let priceHistory = [];
    priceHistory = await getHistoryData(seId, product, prospectId);
    priceHistory.push(history);

    const res = await editProspectPriceForApproval(
      "Approve",
      seId,
      prospectId,
      product,
      priceHistory
    );
    console.log(res);
    if (res.successfull) {
      swal("Success", "Prospect Price Approved", "success");
    } else {
      swal("Error", "Error in saving Prospect", "error");
    }
  }

  async function rejectProspectPrice(product) {
    const swalRes = await swal(
      "Are you sure you want to Reject Prospect Price",
      "Please make sure information is correct. You will not be able to change it again.",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    setSelectedProduct(product);
    setOpen(true);
  }

  async function saveProductPrice(product, price) {
    if (price == 0) return;
    const history = {
      price: price,
      role: role,
      date: new Date(),
    };
    let priceHistory = product?.prospectPriceHistory;
    priceHistory.push(history);
    const res = await editProspectPriceForApproval(
      "Reject",
      seId,
      prospectId,
      product,
      priceHistory,
      price
    );
    console.log(res);
    if (res.successfull) {
      swal("Success", "Prospect Price Rejected", "success");
    } else {
      swal("Error", "Error in saving Prospect", "error");
    }

    handleClose();
  }

  const LeadsTableView = ({ leads }) => {
    const [showStatus, setShowStatus] = React.useState(false);
    const [statusDetails, setStatusDetails] = React.useState([]);

    async function handleViewOrderStatus(data) {
      setStatusDetails(data.prospectPriceHistory);
      setShowStatus(true);
      console.log(data.prospectPriceHistory);
    }

    const handleClose = () => {
      setOpen(false);
      setShowStatus(false);
    };

    return (
      <div className="row align-items-center">
        <Table key="LeadTable">
          <Thead>
            <Tr>
              <Th>Product Name</Th>
              <Th>Product Category</Th>
              <Th>Product Sub-Category</Th>
              <Th>City</Th>
              <Th>Nexstoc Minimum Price</Th>
              <Th>Prospect Price</Th>
              <Th>PM Price</Th>
              <Th>PM Status</Th>
              <Th>SE Status</Th>
              <Th>History</Th>
              <Th>Action</Th>
              {/* <Th>Details</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {viewOrderStatus()}
            {leads.map((product, index) => {
              const finalPrice = Number(
                product.city === "PAN-Products" ? product["maxPrice"] : product["finalPrice"]
              ).toFixed(2);
              return (
                <Tr key={index}>
                  <Td>{product["Product Name"]}</Td>
                  <Td>{product["Product Category"]}</Td>
                  <Td>{product["Product Sub-Category"]}</Td>
                  <Td>{product.city}</Td>
                  <Td>{finalPrice}</Td>
                  <Td>{product.prospectPrice}</Td>
                  <Td>{product.PmPrice ?? "Not Set"}</Td>

                  <Td>{product.status}</Td>
                  <Td>{product.statusBySE ?? "Not Set"}</Td>
                  <Td>
                    <ColorButton
                      className="mt-2"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleViewOrderStatus(product);
                      }}
                    >
                      History
                    </ColorButton>
                  </Td>
                  <Td>
                    <IconButton
                      disabled={
                        product.status === "Approved" ||
                        product.status === "Rejected"
                      }
                      aria-label="approve"
                      onClick={(e) => approveProspectPrice(product)}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      disabled={
                        product.status === "Approved" ||
                        product.status === "Rejected"
                      }
                      aria-label="reject"
                      onClick={(e) => rejectProspectPrice(product)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Td>

                  {/* <Td>
                        <MarkVisited
                          variant="contained"
                          className={classes.button}
                          onClick={() => addClient(lead)}
                        >
                          Add Client
                        </MarkVisited>
                      </Td> */}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Set Price For Product"}
          </DialogTitle>
          <PMPrice
            product={selectedProduct}
            handleClose={handleClose}
            saveProductPrice={saveProductPrice}
          />
        </Dialog>
      </div>
    );

    function viewOrderStatus() {
      function getTimeFormat(dateData) {
        var date = new Date(dateData);
        var options = {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        };
        var timeString = date.toLocaleString("en-US", options);
        return timeString;
      }
      return (
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          open={showStatus}
        >
          <DialogTitle id="simple-dialog-title">Price History</DialogTitle>
          <div className="container pl-3 pr-3">
            <>
              {statusDetails?.length ? (
                <div className="row align-items-center">
                  <Table key="historyData">
                    <Thead>
                      <Tr>
                        <Th>Role</Th>
                        <Th>Price</Th>
                        <Th>Date</Th>
                        <Th>Time</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {statusDetails.map((history, index) => {
                        const date = new Date(history.date.seconds * 1000);
                        return (
                          <Tr key={index}>
                            <Td>{history.role}</Td>
                            <Td>{history.price}</Td>
                            <Td>{date.toLocaleDateString()}</Td>
                            <Td>{getTimeFormat(date)}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </div>
              ) : (
                <p>No History</p>
              )}
            </>
          </div>

          <ColorButton onClick={handleClose} style={{ margin: "10px" }}>
            Close
          </ColorButton>
        </Dialog>
      );
    }
  };

  return (
    <div>
      <div className="row pb-3 justify-content-center align-items-center">
        <div className="col-10 col-md-10">
          <input
            type="text"
            className="search-box"
            placeholder="Search Products"
            onChange={(e) => {
              search(e);
            }}
            style={{ width: "275px", height: "40px" }}
          />
        </div>
        <div className="col col-md-2 mt-2 float-right">
          <TextField
            required
            id="outlined-indian-state"
            select
            label="Status"
            // value={employee.indianState}
            // onClick={AssignDrop()}
            value={filterStatus}
            onChange={(e) => onFilter(e.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              classes: {
                root: classes.selectRoot,
                select: classes.select,
              },
            }}
            style={{ width: "11ch", float: "right", verticalAlign: "center" }}
          >
            <MenuItem key="All" value="All">
              All
            </MenuItem>

            <MenuItem key="Pending" value="Pending">
              Pending
            </MenuItem>
            <MenuItem key="Approved" value="Approved">
              Approved
            </MenuItem>
            <MenuItem key="Rejected" value="Rejected">
              Rejected
            </MenuItem>
          </TextField>
        </div>
      </div>

      {leads.length === 0 ? (
        <div className="row justify-content-center">
          <p>No lead's yet!</p>
        </div>
      ) : (
        <>
          {leads.length > 0 && filterStatus === "" && searchValue === "" ? (
            <LeadsTableView leads={leads} />
          ) : (
            <>
              {searchResult.length > 0 ? (
                <LeadsTableView leads={searchResult} />
              ) : (
                <div className="row pb-3 justify-content-center">
                  <div>Search Not Found!</div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const PMPrice = ({ product, handleClose, saveProductPrice }) => {
  const [price, setPrice] = useState(0);
  return (
    <span>
      <DialogContent>
        <TextField
          required
          fullWidth
          id="outlined-price"
          label="Set Price"
          variant="outlined"
          value={price}
          type="number"
          onWheel={() => document.activeElement.blur()}
          style={{ marginBottom: "10px" }}
          onChange={(e) => {
            let p = e.target.valueAsNumber;
            if (p && p > 0) setPrice(p);
            else setPrice(0);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <ColorButton onClick={() => saveProductPrice(product, price)}>
          Save
        </ColorButton>
      </DialogActions>
    </span>
  );
};

export default ShowRequests;
