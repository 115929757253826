import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const ColorButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#FCBF00",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#FCBF00",
    },
  },
}))(Button);

export const CancelButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#F5F6F8",
    color: "#333333",
    "&:hover": {
      backgroundColor: "#F5F6F8",
    },
    height: "42px",
    width: "259px",
    fontSize: "15px",
    fontWeight: "600",
    boxShadow: "none",
    textTransform: "uppercase",
    border: "none",
  },
}))(Button);

CancelButton.defaultProps = {
  variant: "outlined",
};

export const LogoutButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#D22B2B",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#FF3131",
    },
  },
}))(Button);

export const LogInButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#FCBF00",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#18D26E",
    },
  },
}))(Button);

export const MarkVisited = withStyles((theme) => ({
  root: {
    backgroundColor: "#c00000",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#18D26E",
    },
  },
}))(Button);

export const GoogleMeetButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#41A3FC",
    color: "#FFFFFF",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#41A3FC",
    },
    fontSize: "15px",
  },
}))(Button);

export const ReferButton = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(25, 118, 210, 0.04)",
    borderColor: "#41A3FC",
    color: "#1976D2",
    "&:hover": {
      backgroundColor: "#D3E6F7",
    },
    fontSize: "15px",
    textTransform: "capitalize",
    letterSpacing: "0.46px",
    lineHeight: "26px",
    width: "186px",
    height: "42px",
  },
}))(Button);

export const StyledPrimaryButton = withStyles((theme) => ({
  root: {
    height: 42,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "600",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    fontSize: `${theme.typography.button.fontSize}px`,
    textTransform: "uppercase",
  },
}))(Button);

StyledPrimaryButton.defaultProps = {
  variant: "outlined",
};

export const StyledCreateButton = withStyles((theme) => ({
  root: {
    height: 42,
    width: 273,
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "600",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    fontSize: `${theme.typography.button.fontSize}px`,
    textTransform: "uppercase",
    boxShadow: "none",
  },
}))(Button);

StyledCreateButton.defaultProps = {
  variant: "outlined",
};
