export const CORE_BLOG_VIEW = "/marketing/blog";
export const CORE_CAREER_VIEW = "/marketing/career";
export const CORE_CASE_STUDIES_VIEW = "/marketing/case-studies";
export const CORE_TESTIMONIALS_VIEW = "/marketing/testimonials";
export const CORE_VIEW_MARKETING_LINK_VIEW = "/marketing/view-marketing-links";
export const CORE_LEADS_VIEW = "/leads/get-leads";
export const CORE_SHOW_LOCATIONS_VIEW = "/leads/show-locations";
export const CORE_ASSIGNED_LEADS_VIEW = "/leads/assigned-leads";
export const CORE_PROSPECTS_VIEW = "/leads/view-prospects";
export const CORE_PROFILES_VIEW = "/employee/view-profiles";
export const CORE_EMPLOYEES_VIEW = "/employee/view-employees";
