import { Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  warm: {
    backgroundColor: "#FFF4D5",
    borderColor: "#FFF4D5",
    color: "#FFBD00",
    "&:hover": {
      backgroundColor: "#FFF4D5",
    },
    textTransform: "capitalize",
    borderRadius: "50px",
    width: "31px",
    height: "42px",
  },
  cold: {
    backgroundColor: "#D3E6F7",
    borderColor: "#D3E6F7",
    color: "#41A3FC",
    "&:hover": {
      backgroundColor: "#D3E6F7",
    },
    textTransform: "capitalize",
    borderRadius: "50px",
    width: "31px",
    height: "42px",
  },
  lost: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    borderColor: "rgba(0, 0, 0, 0.08)",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    textTransform: "capitalize",
    borderRadius: "50px",
    width: "31px",
    height: "42px",
  },
  hot: {
    backgroundColor: "#FFBABA",
    borderColor: "#FFBABA",
    color: "#F44336",
    "&:hover": {
      backgroundColor: "#FFBABA",
    },
    textTransform: "capitalize",
    borderRadius: "50px",
    width: "31px",
    height: "42px",
  },
}));

export default function StatusButton({ status }) {
  const classes = useStyles();
  console.log(status);
  return (
    <div>
      <Button size="small" className={classes[status]} variant="outlined">
        <Typography>{status}</Typography>
      </Button>
    </div>
  );
}
