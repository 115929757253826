import React, { useContext, useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import SelectProduct from "./SelectProduct";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { RateContractContext } from "../../../common/RateContractProvider";
import { prospectDataforGRContract } from "../../../backend/getDataFromFirestore";
import { DownloadAsPDF } from "./PDFDownload";
import { DownloadAsExcel } from "./ExcelDownload";

// table view
export const QuotationProductsTable = ({
  versionName,
  isPAN,
  city,
  category,
  subCategory,
  prospectDetails,
  onlyShow = false,
}) => {
  const [products, setProducts] = useState([]);

  const { selectedProducts } = useContext(RateContractContext);
  const db = useFirestore();
  let productsRef;
  if (isPAN && category && subCategory) {
    productsRef = db
      .collection("PAN-Products")
      .where("Product Sub-Category", "==", subCategory);
  } else if (isPAN && category && subCategory) {
    productsRef = db
      .collection("PAN-Products")
      .where("Product Category", "==", subCategory);
  } else if (isPAN) {
    productsRef = db.collection("PAN-Products");
  } else if (city !== "PAN-Products" && category && subCategory) {
    productsRef = db
      .collection("Products")
      .doc(city)
      .collection("Products")
      .where("Product Sub-Category", "==", subCategory);
  } else if (city !== "PAN-Products" && category) {
    productsRef = db
      .collection("Products")
      .doc(city)
      .collection("Products")
      .where("Product Category", "==", category);
  } else
    productsRef = db.collection("Products").doc(city).collection("Products");

  const { status, data } = useFirestoreCollectionData(productsRef);

  const [filterStatus, setFilterStatus] = useState("");
  const [prospectsData, setProspectsData] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    console.log("It rendered");
    if (data) {
      //
      let finalResult = [];
      const selectedproductsIds = Object.keys(selectedProducts[city]);
      selectedproductsIds.forEach((id) => {
        const result = data.filter((product) => id === product.id);
        finalResult.push(result[0]);
      });
      setProducts(finalResult);

      // setProducts(data);
      setSearchResult(searchProductsResult(searchValue, filterStatus));
    }
  }, [data]);

  const role = localStorage.getItem("role");
  const userDocId = localStorage.getItem("userDocId");
  let seId;
  if (role === "Sales Executive") {
    seId = userDocId;
  }

  useEffect(() => {
    (async () => {
      const d = await prospectDataforGRContract(seId, prospectDetails);
      setProspectsData(d);
    })();
    for (let i = 0; i < prospectsData.length; i++) {
      for (let j = 0; j < products.length; j++) {
        if (
          products[j].id === prospectsData[i].id &&
          prospectsData[i]?.statusBySE
        ) {
          products[j]["isSendRequest"] = prospectsData[i].statusBySE;
          if (
            products[j].id === prospectsData[i].id &&
            prospectsData[i]?.statusBySE &&
            prospectsData[i]?.statusBySE === "Approved"
          ) {
            isPAN
              ? (products[j]["productManagerPrice"] = prospectsData[i].PmPrice) //productManagerPrice
              : (products[j]["productManagerPrice"] = prospectsData[i].PmPrice);
            break;
          }
        }
      }
      setProducts(products);
    }
    //console.log("use2", products);
  }, [products]);

  //  Search & filter in textbox

  const searchProductsResult = (name) => {
    return products.filter((pros) => {
      if (name) {
        return (
          pros["Product Name"].toLowerCase().includes(name.toLowerCase()) ||
          pros["Product Category"].toLowerCase().includes(name.toLowerCase()) ||
          pros["Product Sub-Category"]
            .toLowerCase()
            .includes(name.toLowerCase()) ||
          pros["Brand Name"].toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const search = (e) => {
    setSearchValue(e.target.value);
    const data = searchProductsResult(e.target.value);
    setSearchResult([...data]);
    //setSearchResult(searchProductsResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    setFilterStatus(status);
    setSearchResult(searchProductsResult(searchValue, status));
  };

  return (
    <div>
      {products.length === 0 ? (
        <div className="row justify-content-center">
          <p>No request's yet!</p>
        </div>
      ) : (
        <>
          {products.length > 0 && filterStatus === "" && searchValue === "" ? (
            <ProductsTableView
              version={versionName}
              products={products}
              isPAN={isPAN}
              city={city}
              onlyShow={onlyShow}
            />
          ) : (
            <>
              {searchResult.length > 0 ? (
                <ProductsTableView
                  products={searchResult}
                  isPAN={isPAN}
                  city={city}
                  onlyShow={onlyShow}
                />
              ) : (
                <div className="row pb-3 justify-content-center">
                  <div>Search Not Found!</div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

// table view
const ProductsTableView = ({
  version,
  products,
  isPAN,
  city,
  onlyShow = false,
}) => {
  const { selectedProducts } = useContext(RateContractContext);
  return (
    <div className="row align-items-center">
      <DownloadAsExcel
        version={version}
        isPAN={isPAN}
        products={products}
        city={city}
      />
      <DownloadAsPDF
        version={version}
        isPAN={isPAN}
        products={products}
        city={city}
      />
      <Table key="LeadTable" id="table-to-xls">
        <Thead>
          <Tr>
            <Th>SKU ID</Th>
            <Th>Brand</Th>
            <Th>Product Image</Th>
            <Th>Product Name</Th>
            <Th>Product Category</Th>
            <Th>Product Sub Category</Th>
            <Th>MRP</Th>
            <Th>NexStoc Price (Excl. GST)</Th>
            {/* <Th>PM Final Price</Th> */}
            <Th>GST%</Th>
            <Th>Select Product </Th>
            <Th>Rate (Excl.GST)</Th>
            <Th>Rate (Incl.GST)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {products.map((pros, index) => {
            const finalPrice = Number(
              isPAN ? pros["maxPrice"] : pros["finalPrice"]
            ).toFixed(2);

            if (finalPrice === 0) return null;

            return (
              <Tr key={index}>
                <Td>{pros["SKU ID"]}</Td>
                <Td>{pros["Brand Name"]}</Td>
                <Td style={{ width: "10%" }} align="center">
                  <img
                    style={{
                      display: "block",
                      maxHeight: "50px",
                      maxWidth: "100%",
                    }}
                    src={
                      pros["Product Images"] && pros["Product Images"][0]
                        ? pros["Product Images"][0]
                        : pros["Product Image"]
                    }
                  />
                </Td>
                <Td>{pros["Product Name"]}</Td>
                <Td>{pros["Product Category"]}</Td>
                <Td>{pros["Product Sub-Category"]}</Td>

                <Td>{Number(pros["MRP"]).toFixed(2)}</Td>
                <Td>{finalPrice}</Td>
                {/* <Td>{pros["productManagerPrice"] ?? " "}</Td> */}
                <Td>{pros["GST%"]}%</Td>
                <Td>
                  <SelectProduct
                    city={city}
                    product={pros}
                    price={finalPrice ?? 0}
                    onlyShow={onlyShow}
                  />
                </Td>
                {
                  <Td>
                    {selectedProducts[city][pros.id] !== 0
                      ? parseFloat(selectedProducts[city][pros.id]).toFixed(2)
                      : " "}
                  </Td>
                }
                {
                  <Td>
                    {selectedProducts[city][pros.id]
                      ? parseFloat(
                          selectedProducts[city][pros.id] +
                            selectedProducts[city][pros.id] *
                              +(pros["GST%"] / 100)
                        ).toFixed(2)
                      : " "}
                  </Td>
                }
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
};
