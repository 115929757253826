import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import swal from "sweetalert";
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { deleteSalesUser } from "../../backend/sendRequestToServer";
import { ColorButton } from "../../common/Components/ColorButton";
import { getSalesUserFromId } from "../../backend/getDataFromFirestore";
import { getPageName } from "././../../common/const";

const ViewCSH = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  var tempCSH = "";
  var emailtest = "";
  const rshDocId = localStorage.getItem("userDocId");
  let history = useHistory();

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    const myRole = localStorage.getItem("role");
    let response;
    if (myRole === "User Manager" || myRole === "Super Admin") {
      response = db
        .collection("Users")
        .where("designation", "==", "City Sales Head")
        .where("userDeleted", "==", false);
    } else {
      response = db
        .collection("Users")
        .where("userDeleted", "==", false)
        .where("createdby", "==", rshDocId);
    }

    const data = await response.get();
    let cshList = [];

    data.docs.forEach((item) => {
      cshList.push(item.data());

      console.log(item.data());
    });

    setBlogs(cshList);
  };

  // redirect to add csh
  const handleAddCSH = () => {
    history.push("/add-csh");
  };
  //  Search in textbox
  const searchBlogResult = (val) => {
    return blogs.filter((data) =>
      data.name.toLowerCase().includes(val.toLowerCase())
    );
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);

    setSearchResult(searchBlogResult(e.target.value));
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>

        <div className="row pb-3 justify-content-center">
          <input
            type="text"
            className="col-10 col-md-6 search-box"
            placeholder="Search City Sales Head"
            onChange={(e) => {
              search(e);
            }}
          />
        </div>
        {blogs.length === 0 ? (
          <>
            <div className="row justify-content-center">
              <p style={{ marginBottom: "10px" }}>No City Sales Head yet!</p>
            </div>
            <div className="row justify-content-center">
              <ColorButton onClick={handleAddCSH}>Add CSH</ColorButton>
            </div>{" "}
          </>
        ) : (
          <>
            {blogs.length > 0 && searchValue === "" ? (
              blogs.map((blog) => {
                if (blog.RSH) {
                  tempCSH = blog.CSH;
                } else {
                  tempCSH = "";
                }
                return <CshCard key={blog.id} blog={blog} />;
              })
            ) : (
              <>
                {searchResult.length > 0 ? (
                  searchResult.map((blog) => {
                    return <CshCard key={blog.id} blog={blog} />;
                  })
                ) : (
                  <>
                    {searchValue !== "" ? (
                      <div className="row pb-3 justify-content-center">
                        <div>Search Not Found!</div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ViewCSH;

const CshCard = ({ blog }) => {
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleReset = () => {
    window.location.reload();
  };

  // function delete blogs
  async function DeleteCSH(id, email) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this employee!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: "Deleting the user. Do not Refresh",
          icon: "warning",
          buttons: false,
          dangerMode: true,
        });

        console.log("email", email);

        const res = await deleteSalesUser({
          id: id,
          email: email,
        });

        if (res.status === 200) {
          swal("Success", "Employee Successfully Deleted!!!", "success").then(
            (value) => handleReset()
          );
        } else {
          swal(
            "Error",
            "Error Occur while Deleting. Please Try Again.",
            "error"
          );
        }
      } else {
      }
    });

    //aDD SWAL CODE below
  }

  // function edit blogs

  function EditCSH(id) {
    console.log("this is ", id);
    history.push("/edit-csh", id);
  }

  const { seEmails, setSeFromId } = SeHelper();

  return (
    <Media list key={blog.id} className="blog-card">
      <Media tag="li">
        {/* <Media left href="#">
               <Avatar src={blog.downloadURL} alt={blog.author} 
               style = {{
                   minHeight: 100,
                   minWidth: 100,
                   marginRight: 8
               }}/>
           </Media> */}

        <Media body>
          <Media heading>{blog.name}</Media>
          <p className="author-name">Email : {blog.email}</p>
          <p>Phone: {blog.phone}</p>
          <p>Designation: {blog.designation}</p>
          <p>Priviledges: {blog.privileges.toString()}</p>
          <p>City: {blog.city}</p>

          {seEmails.length > 0 ? (
            <p>Created SE: {seEmails.toString()}</p>
          ) : null}

          <p className="view-edit">
            <ColorButton
              variant="contained"
              className={classes.button}
              aria-label="Show SE"
              onClick={(e) => setSeFromId(blog.SE)}
            >
              Show SE
            </ColorButton>
            <IconButton
              aria-label="delete"
              id={blog.id}
              onClick={(e) => DeleteCSH(blog.id, blog.email)}
            >
              <DeleteIcon />
            </IconButton>
            {/* <IconButton aria-label="delete" 
                   id={blog.id} 
                   onClick = {(e) => EditBlog(blog)}>
                       <CreateIcon />
                   </IconButton> */}
            <ColorButton
              variant="contained"
              className={classes.button}
              onClick={() => EditCSH(blog)}
            >
              {" "}
              <IconButton
                aria-label="delete"
                id={blog.id}
                onClick={(e) => EditCSH(blog)}
              >
                <CreateIcon />
              </IconButton>
            </ColorButton>
          </p>
        </Media>
      </Media>
    </Media>
  );
};

const SeHelper = () => {
  const [seEmails, setSeEmails] = useState([]);

  const setSeFromId = async (seIdList) => {
    if (seEmails.length > 0) return;

    console.log(seIdList);
    let seList = [];

    if (seIdList) {
      for (const id of seIdList) {
        const se = await getSalesUserFromId(id);
        if (se) seList.push(se.email);
      }
    } else {
      seList.push("No SE Assigned");
    }
    console.log(seList);
    setSeEmails(seList);
  };
  return { seEmails, setSeFromId };
};
