import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import CardActions from "@material-ui/core/CardActions";
import { indianStates } from "../../common/const.js";
import Layout from "../../common/Layout";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import "firebase/storage";
import { useFirestore } from "reactfire";
import { CircularProgress } from "@material-ui/core";
import "./style.css";
import {
  uploadFileToStorage,
  getDownloadURL,
} from "../../backend/uploadFileToStorage";
import getFranchiseCount, {
  incrementFranchiseCount,
} from "../../backend/getCounters";
import saveFranchiseData, {
  saveLeadsData,
} from "../../backend/saveDataToFirestore";
import { getLocationUsingAddress } from "../../backend/sendRequestToServer";
import swal from "sweetalert";
import { editFranchiseData } from "../../backend/editDataInFirestore.js";

const ContactUs = () => {
  const db = useFirestore();
  const location = useLocation();
  let data = location.state;

  const [formData, setFormData] = React.useState({
    companyName: "",
    address: "",
    city: "",
    indianState: "",
    postalCode: "",
    personalEmail: "",
    email: "",
    contactPerson: "",
    departmentName: "",
  });
  const [showCompanyErr, setShowCompanyErr] = useState("");
  const [showNumberErr, setShowNumberErr] = useState("");
  const [showEmailErr, setShowEmailErr] = useState("");
  const [showAddressErr, setShowAddressErr] = useState("");
  const [showCityErr, setShowCityErr] = useState("");
  const [showStateErr, setShowStateErr] = useState("");
  const [showPostalErr, setShowPostalErr] = useState("");
  const [showDeptErr, setShowDeptErr] = useState("");
  const [showContactPerErr, setShowContactPerErr] = useState("");

  const [showSpinner, setShowSpinner] = React.useState(false);
  // const [showAddressErr, setShowAddressErr] = useState("");

  useEffect(() => {
    if (data !== undefined) {
      setFormData({ ...data, date: new Date(data.date.seconds * 100) });
    }
  }, []);

  const changeValues = (e, field) => {
    setFormData({ ...formData, [`${field}`]: e.target.value });
  };

  const handleDateChange = (newDate) => {
    setFormData({ ...formData, date: newDate });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: "10px",
      boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
    },
    form: {
      margin: "15px 15px 15px 15px",
      borderRadius: "10px",
      padding: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    textField: {
      width: "69ch",
    },
    margin: {
      margin: theme.spacing(1),
    },
    stepperColor: {
      color: "#18D26E",
    },
    errMsg: {
      color: "red",
      fontWeight: "bold",
      fontSize: "small",
      textAlign: "left",
    },
  }));

  // email validation
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  // phone validation
  const validatePhone = (mob) => {
    const re = /^[0-9]{10}$/;
    return re.test(mob);
  };

  //Upload Documents
  const handleFiles = (e, field) => {
    setFormData({ ...formData, [`${field}`]: e.target.files[0] });
  };

  const classes = useStyles();

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const handleReset = () => {
    window.location.reload();
  };

  const [errorMsg, setErrorMsg] = React.useState("");

  async function getData() {
    if (formData.companyName === "") {
      setShowCompanyErr("Please Enter Compnay Name");
    } else if (formData.personalEmail === "") {
      setShowEmailErr("Please Enter your Email");
      // console.log()
    } else if (!validateEmail(formData.personalEmail)) {
      setShowEmailErr("Enter valid Email");
    } else if (!formData.mobileNo) {
      setShowNumberErr("Please Enter Mobile Number");
    } else if (!validatePhone(formData.mobileNo)) {
      setShowNumberErr("Enter valid Mobile Number");
    } else if (formData.address === "") {
      setShowAddressErr("Please Enter Address");
    } else if (formData.city === "") {
      setShowCityErr("Please Enter City");
    } else if (!formData.indianState) {
      setShowStateErr("Please Enter State");
    } else if (formData.postalCode === "") {
      setShowPostalErr("Please Enter Postal Code");
    } else if (formData.contactPerson === "") {
      setShowContactPerErr("Please Enter Contact Person Name");
    } else if (formData.departmentName === "") {
      setShowDeptErr("Please Enter Department Name");
    } else {
      setShowSpinner(true);
      var url_string = window.location.href;
      var url = new URL(url_string);
      var c = url.searchParams.get("data");
      const newFormData = {
        companyName: formData.companyName,
        personalemail: formData.personalEmail,
        mobileno: formData.mobileNo,
        city: formData.city,
        state: formData.indianState,
        pincode: formData.postalCode,
        source: c,
        status: "cold",
        address: formData.address,
        nsh: "",
        rsh: "",
        csh: "",
        se: "",
        contactPerson: formData.contactPerson,
        departmentName: formData.departmentName,
      };

      const completeAddress =
        formData.companyName +
        ", " +
        formData.address +
        ", " +
        formData.city +
        ", " +
        formData.indianState.substr(0, formData.indianState.length - 5) +
        ", India, " +
        formData.postalCode;
      try {
        const location = await getLocationUsingAddress({
          address: completeAddress,
        });
        if (location.status == 200) {
          newFormData["lat"] = location.lat;
          newFormData["lng"] = location.lng;

          const res = await saveLeadsData(newFormData);
          if (res.successfull) {
            setShowSpinner(false);
            swal(
              "Success!!!",
              "Thank you! Your response has been added.",
              "success"
            ).then(() => {
              handleReset();
            });
          } else {
            setShowSpinner(false);
            console.error("Error updating document: ");
          }
        }
      } catch (e) {
        setShowSpinner(false);
        console.log(
          "Failed to fetch Location. Please make sure your address is correct and use comma to seperate landmarks/streets/town. Some Error Occured ",
          e
        );
      }
    }
  }

  return (
    <>
      {/* <Layout /> */}
      <div className="container mt-5 pt-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2" align="center">
                  Contact Us
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                  {/* <div> */}
                  <TextField
                    required
                    fullWidth
                    id="outlined-company-name"
                    label=" Company Name"
                    variant="outlined"
                    value={formData.companyName}
                    onChange={(e) => {
                      changeValues(e, "companyName");
                      setShowCompanyErr(false);
                    }}
                    error={showCompanyErr}
                    helperText={showCompanyErr}
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    required
                    fullWidth
                    id="outlined-address"
                    label="Email"
                    variant="outlined"
                    value={formData.personalEmail}
                    onChange={(e) => {
                      changeValues(e, "personalEmail");
                      setShowEmailErr(false);
                    }}
                    error={showEmailErr}
                    helperText={showEmailErr}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="outlined-address"
                    label="Phone"
                    variant="outlined"
                    value={formData.mobileNo}
                    onChange={(e) => {
                      changeValues(e, "mobileNo");
                      setShowNumberErr(false);
                    }}
                    error={showNumberErr}
                    helperText={showNumberErr}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="outlined-address"
                    label="Company Address"
                    variant="outlined"
                    value={formData.address}
                    onChange={(e) => {
                      changeValues(e, "address");
                      setShowAddressErr(false);
                    }}
                    error={showAddressErr}
                    helperText={showAddressErr}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="outlined-city"
                    label="City"
                    variant="outlined"
                    value={formData.city}
                    onChange={(e) => {
                      changeValues(e, "city");
                      setShowCityErr(false);
                    }}
                    error={showCityErr}
                    helperText={showCityErr}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="outlined-indian-state"
                    select
                    label="State"
                    value={formData.indianState}
                    onChange={(e) => {
                      changeValues(e, "indianState");
                      setShowStateErr(false);
                    }}
                    error={showStateErr}
                    helperText={showStateErr}
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  >
                    {indianStates.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    required
                    fullWidth
                    id="outlined-postal-code"
                    type="postal"
                    inputProps={{ maxLength: 6 }}
                    label="Postal Code"
                    variant="outlined"
                    value={formData.postalCode}
                    onChange={(e) => {
                      changeValues(e, "postalCode");
                      setShowPostalErr(false);
                    }}
                    error={showPostalErr}
                    helperText={showPostalErr}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="outlined-postal-code"
                    label="Contact Person"
                    variant="outlined"
                    value={formData.contactPerson}
                    onChange={(e) => {
                      changeValues(e, "contactPerson");
                      setShowContactPerErr(false);
                    }}
                    error={showContactPerErr}
                    helperText={showContactPerErr}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="outlined-department-name"
                    label="Department Name"
                    variant="outlined"
                    value={formData.departmentName}
                    onChange={(e) => {
                      changeValues(e, "departmentName");
                      setShowDeptErr(false);
                    }}
                    error={showDeptErr}
                    helperText={showDeptErr}
                    style={{ marginBottom: "10px" }}
                  />
                  <br />
                </form>

                {/* <ColorButton style={{justifyContent: 'center', fontWeight : 'bold',margin:5}}onClick={getData} >Submit Details</ColorButton> */}
              </CardContent>
              <div>
                {showSpinner ? (
                  <div>
                    <span
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block",
                        color: "#ff0000",
                      }}
                      id="x"
                    >
                      <h5 style={{ textAlign: "center" }}>
                        Please DO NOT REFRESH the page...
                      </h5>
                    </span>
                    <CircularProgress
                      style={{
                        color: "#18D26E",
                        marginLeft: "50%",
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  disabled={showSpinner}
                  variant="contained"
                  color="primary"
                  onClick={getData}
                >
                  Submit Details
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
      {/* </Layout> */}
    </>
  );
};

export default ContactUs;
