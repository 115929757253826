import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Container, Button, TextField, MenuItem } from "@material-ui/core";
import RateField from "./RateField";
import ImagesField from "./ImagesField";
import { ColorButton } from "../../../common/Components/ColorButton";
import {
  saveCityCounter,
  saveProductsApprovalsData,
} from "../../../backend/saveDataToFirestore";
import swal from "sweetalert";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../../backend/uploadFileToStorage";
import { UnitValues } from "../../../common/const";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "100px",
  },
}))(TableRow);

const useStyles = makeStyles({
  container: {
    maxHeight: "75vh",
  },
  desDetails: {
    height: 100,
    overflow: "auto",
  },
  duplicate: {
    backgroundColor: "red",
  },
});

const ProductsTable = ({ productData, productLength, city, title }) => {
  const rates = React.useRef(null);
  const filesData = React.useRef(null);
  const unitData = React.useRef(null);

  const [upload, setUpload] = useState(true);
  const [unitValue, setUnitValue] = useState();

  useEffect(() => {
    console.log(productData);
    rates.current = new Array(productLength).fill();
    filesData.current = new Array(productLength).fill();
    unitData.current = new Array(productLength).fill();
    for (let product of productData) {
      if (product["duplicate"]) {
        setUpload(false);
        break;
      }
    }
  }, [productLength]);

  const classes = useStyles();
  // console.log("length of products", productLength, "filenames are ", fileNames);
  console.log(rates);

  const uploadImagesAndUnitsOfProduct = async (index) => {
    let ref = `/product-images/${productData[index]["SKU ID"]}`;
    let imagesURL = [];
    if (filesData.current[index]) {
      for (let file of filesData.current[index]) {
        let task = uploadFileToStorage(ref + `/${file.name}`, file);
        imagesURL.push(await getDownloadURL(task));
      }
    }

    productData[index]["Product Images"] = imagesURL;
    productData[index]["UNIT"] = unitData.current[index];
  };

  const uploadData = async (event) => {
    const swalRes = await swal(
      "Are you sure you want to upload these Products",
      "Please make sure all prices are correct. You will not be able to change prices again.",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    swal(
      "Uploading Products",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );

    console.log("Upload Data");
    console.log(productData, rates.current, filesData.current);
    // TODO : Upload All Images of Product
    // TODO : Save Product data on Firestore
    //        FranchiseProductApprovals > CityName
    try {
      for (let i = 0; i < productLength; i++) {
        await uploadImagesAndUnitsOfProduct(i);
        const res = await saveProductsApprovalsData(city, productData[i]);
      }
      // TODO : Add Counter of all Franchise and Estore to CityName Document and save title
      saveCityCounter(city, title);
      swal.close();
      swal("Success!", "Products Uploaded", "success").then((val) =>
        window.location.reload()
      );
    } catch (e) {
      swal.close();
      swal("Error!", "Something went wrong", "error");
    }
  };

  const changeRate = (index, val) => {
    rates.current[index] = isNaN(val) ? undefined : val;
    // console.log(rates.current);
  };

  const changeFileNames = (index, val) => {
    filesData.current[index] = val;
    console.log(filesData.current);
  };

  const changeUnitNames = (e, index) => {
    setUnitValue(e.target.value);
    unitData.current[index] = e.target.value;
    // console.log(unitData.current)
  };
  return (
    <div>
      <br />
      <br />
      <ColorButton
        disabled={!upload}
        variant="contained"
        color="primary"
        onClick={uploadData}
      >
        Upload Data
      </ColorButton>
      <TableContainer className={classes.container} component={Paper}>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "10em" }}>SKU ID</TableCell>

              <TableCell style={{ width: "10em" }}>Brand</TableCell>

              <TableCell style={{ width: "10em" }}>Product Category</TableCell>

              <TableCell style={{ width: "10em" }}>
                Product Sub-Category
              </TableCell>

              <TableCell style={{ width: "10em" }}>Product Name</TableCell>

              <TableCell style={{ width: "10em" }}>Description</TableCell>

              <TableCell style={{ width: "20em" }}>
                Description Details
              </TableCell>

              <TableCell style={{ width: "20em" }}>Product Image</TableCell>

              <TableCell style={{ width: "10em" }}>UNIT</TableCell>

              <TableCell style={{ width: "10em" }}>GST%</TableCell>

              <TableCell style={{ width: "10em" }}>HSN CODE</TableCell>

              <TableCell style={{ width: "10em" }}>MRP</TableCell>

              {/* <TableCell style={{ width: "10em" }}>Rate</TableCell> */}

              <TableCell style={{ width: "10em" }}>TAT</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {productData === undefined ? (
              <div></div>
            ) : (
              productData.map((p, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    className={p["duplicate"] ? classes.duplicate : null}
                  >
                    <TableCell component="th" scope="row">
                      {p["SKU ID"]}
                    </TableCell>

                    <TableCell>{p["Brand Name"]}</TableCell>

                    <TableCell>{p["Product Category"]}</TableCell>

                    <TableCell>{p["Product Sub-Category"]}</TableCell>

                    <TableCell>{p["Product Name"]}</TableCell>

                    <TableCell>
                      <div className={classes.desDetails}>
                        {p["Description"]}
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className={classes.desDetails}>
                        {p["Description Details"]}
                      </div>
                    </TableCell>

                    <TableCell>
                      <ImagesField
                        index={index}
                        changeFileNames={changeFileNames}
                      />
                    </TableCell>

                    <TableCell>
                      <TextField
                        required
                        fullWidth
                        select
                        id="outlined-category"
                        label="UNIT"
                        name="UNIT"
                        value={unitValue}
                        onChange={(e) => changeUnitNames(e, index)}
                        variant="outlined"
                        style={{ marginBottom: "10px" }}
                      >
                        {Object.keys(UnitValues).map((option, index) => (
                          <MenuItem
                            key={UnitValues[index]}
                            value={UnitValues[option]}
                          >
                            {UnitValues[option]}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>

                    <TableCell>{p["GST%"] * 100}%</TableCell>

                    <TableCell>{p["HSN CODE"]}</TableCell>

                    <TableCell>{p["MRP"]}</TableCell>

                    {/* <TableCell>
                      <RateField index={index} changeRate={changeRate} />
                    </TableCell> */}

                    <TableCell>{p["TAT"]}</TableCell>
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductsTable;
