import app from "firebase/app";
import {
  BlockingCategories,
  checkDistance,
  getBranchLocation,
  getRoleSF,
} from "../common/const";
export default function getDataFromFirestore() {}

export async function getProductsCategoriesDetails() {
  const firestore = app.firestore();

  let categoryDetails = {};
  const collectionRef = await firestore.collection("ProductCategories").get();
  collectionRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    categoryDetails[doc.data().main] = doc.data().sub;
  });
  console.log(categoryDetails);
  return categoryDetails;
}

export async function getProductsWithCategoryAndSubCategory(
  category,
  subcategory,
  start,
  end,
  city
) {
  const firestore = app.firestore();
  console.log(category, subcategory, city);
  let products = [];
  const collectionRef = await firestore
    .collection("Products")
    .doc(city)
    .collection("Products")
    .where("Product Category", "==", category)
    .where("Product Sub-Category", "==", subcategory)
    .get();
  collectionRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    products.push(doc.data());
  });
  console.log(products.slice(start, end));
  return [products.slice(start, end), products.length];
}

export async function getAssignedLeads(seId) {
  const firestore = app.firestore();
  const role = localStorage.getItem("role");
  const roleSF = getRoleSF[role];
  let leads = {};
  let collectionRef;
  if (roleSF === "sa" || roleSF === "nsh") {
    collectionRef = await firestore
      .collection("Leads")
      .where("converted", "==", false)
      .get();
  } else {
    collectionRef = await firestore
      .collection("Leads")
      .where(roleSF, "==", seId)
      .where("converted", "==", false)
      .get();
  }

  collectionRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    let lead = doc.data();
    leads[lead.id] = lead;
  });
  console.log(leads);
  return leads;
}

export async function getFranchiseNameList() {
  const firestore = app.firestore();
  let franchiseNames = {};
  const collectionRef = await firestore.collection("Franchises").get();
  collectionRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // franchiseNames.push({
    //   value: doc.data().companyName,
    //   label: doc.data().companyName,
    //   id: doc.id,
    // });

    //Storing Object inside of array to fetch id and store in formData for E-store
    franchiseNames[doc.data().companyName] = doc.id;
  });

  console.log(franchiseNames);
  return franchiseNames;
}

export async function getEstoreDetails(estore) {
  const firestore = app.firestore();

  if (estore.franchiseId) {
    const franchiseData = await firestore
      .collection("Franchises")
      .doc(estore.franchiseId)
      .get();
    const franchise = franchiseData.data();
    estore = {
      ...estore,
      panNo: franchise.panNo,
      gstNo: franchise.gstNo,
      udyamNo: franchise.udyamNo,
      udyogAadharNo: franchise.udyogAadharNo,
      typeEnterprise: franchise.typeEnterprise,
      typeGoods: franchise.typeGoods,
      commission: franchise.commission,
      bankName: franchise.bankName,
      bankBranch: franchise.bankBranch,
      ifsc: franchise.ifsc,
      accountNo: franchise.accountNo,
      branchHead: franchise.branchHead,
      mobileNo: franchise.mobileNo,
      personalEmail: franchise.personalEmail,
      personalAadhar: franchise.personalAadhar,
      gstCerti: franchise.gstCerti,
      udyamCerti: franchise.udyamCerti,
      cancelledChq: franchise.cancelledChq,
    };
  }
  return estore;
}

export async function getCitiesList() {
  const firestore = app.firestore();
  let cities = [];
  const collectionRef = await firestore
    .collection("CityCodes")
    .orderBy("city")
    .get();
  collectionRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    cities.push({
      id: doc.id,
      state: doc.data().state,
      value: doc.data().city,
    });
  });

  // console.log("cities form firestore", cities);
  return cities;
}

export async function getCitiesListFromState(state) {
  const firestore = app.firestore();
  let cities = [];
  const collectionRef = await firestore
    .collection("CityCodes")
    .where("state", "==", state)
    .orderBy("city")
    .get();
  collectionRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    cities.push({
      value: doc.data().city,
    });
  });

  console.log("cities form firestore", cities);
  return cities;
}

export async function getProductFromId(id, category, subcategory, start, end) {
  const firestore = app.firestore();
  let products = [];

  for (let val of id) {
    let collectionRef = await firestore.collection("Products").doc(val).get();
    const data = collectionRef.data();
    console.log(val);
    if (data.category == category && data["sub-category"] == subcategory) {
      products.push(data);
    }
  }
  return [products.slice(start, end), products.length];
}

export async function getClientProductsCategories(ids) {
  const firestore = app.firestore();
  let categoryList = {};
  const docRef = await firestore
    .collection("Products")
    .where("id", "in", ids)
    .get();
  docRef.forEach((doc) => {
    const data = docRef.data();

    categoryList[data.category] = categoryList[data["sub-category"]];
  });
  console.log(categoryList);
  return categoryList;
}

export async function getClientProductsSubCategories(id, cat) {
  const firestore = app.firestore();
  const subCat = [];
  const docRef = await firestore
    .collection("Products")
    .where("id", "==", id)
    .where("category", "==", cat)
    .get();
  docRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    const data = doc.data();

    subCat.push(data["sub-category"]);
  });
  console.log(subCat);
  return subCat;
}

export async function getClients_() {
  // TEST
  let role = localStorage.getItem("role");
  const DocId = localStorage.getItem("userDocId");

  let designation = "se";
  switch (role) {
    case "Super Admin":
      designation = "sa";
      break;
    case "National Sales Head":
      designation = "nsh";
      break;
    case "Regional Sales Head":
      designation = "rsh";
      break;
    case "City Sales Head":
      designation = "csh";
      break;
    case "Sales Executive":
      designation = "se";
      break;
    default:
      designation = "se";
  }
  const firestore = app.firestore();

  let collRef;
  if (designation === "sa" || designation === "nsh") {
    collRef = await firestore.collection("Leads").get();
  } else {
    collRef = await firestore
      .collection("Leads")
      .where(designation, "==", DocId)
      .get();
  }

  let clients = {};
  collRef.docs.forEach((val) => {
    const data = val.data();
    clients[data.id] = data;
  });
  console.log(clients);
  return clients;

  // TEST ENDS
  // const seDocId = localStorage.getItem("userDocId");
  // const firestore = app.firestore();

  // const collRef = await firestore
  //   .collection("Leads")
  //   .where("se", "==", seDocId)
  //   .get();
  // let clients = {};
  // collRef.docs.forEach((val) => {
  //   const data = val.data();
  //   clients[data.id] = data;
  // });
  // console.log(clients);
  // return clients;
}

export async function getClientRegions(client_id) {
  const firestore = app.firestore();
  const docRef = await firestore
    .collection("Clients/" + client_id + "/Regions")
    .get();
  const regions = [];
  docRef.forEach((doc) => {
    const region = {
      ...doc.data(),
      id: doc.id,
    };
    regions.push(region);
  });
  return regions;
}

export async function getRegionClusters(client_id, region_id) {
  const firestore = app.firestore();
  const docRef = await firestore
    .collection(`Clients/${client_id}/Clusters`)
    .where("region_id", "==", region_id)
    .get();
  const clusters = [];
  docRef.forEach((doc) => {
    const cluster = {
      ...doc.data(),
      id: doc.id,
    };
    clusters.push(cluster);
  });
  return clusters;
}

export async function getClusterCitiesData(client_id, cluster_id) {
  const firestore = app.firestore();
  const docRef = await firestore
    .collection(`Clients/${client_id}/Cities`)
    .where("cluster_id", "==", cluster_id)
    .get();
  const cities = [];
  docRef.forEach((doc) => {
    const city = {
      id: doc.id,
      cityCode_id: doc.data().cityCode_id,
    };
    cities.push(city);
  });
  return cities;
}

export async function getClientCitiesList(client_id) {
  const firestore = app.firestore();
  const docRef = await firestore
    .collection(`Clients/${client_id}/Cities`)
    .get();
  const cities = [];
  docRef.forEach((doc) => {
    const city = {
      id: doc.id,
      cityCode_id: doc.data().cityCode_id,
    };
    cities.push(city);
  });
  return cities;
}

export async function getClientCityById(client_id, cityCode_id) {
  const firestore = app.firestore();
  let city = {};
  const docRef = await firestore
    .collection(`Clients/${client_id}/Cities`)
    .where("cityCode_id", "==", cityCode_id)
    .get();
  city = {
    id: docRef.docs[0].id,
    region_id: docRef.docs[0].data().region_id,
    cluster_id: docRef.docs[0].data().cluster_id,
    cityCode_id: docRef.docs[0].data().cityCode_id,
  };
  return city;
}

export async function getCityById(id) {
  const firestore = app.firestore();
  const docRef = await firestore.collection("CityCodes").doc(id).get();
  const data = docRef.data();
  return data;
}

export async function getCityId(city_data) {
  const firestore = app.firestore();
  const docRef = await firestore
    .collection("CityCodes")
    .where("city", "==", city_data)
    .get();
  let data = {};
  docRef.forEach((doc) => {
    data = {
      id: doc.id,
      city: doc.data().city,
      state: doc.data().state,
      code: doc.data().code,
    };
  });
  return data;
}

export async function getClientById(id) {
  const firestore = app.firestore();
  const docRef = await firestore.collection("Clients").doc(id).get();
  if (!docRef.exists) return {};
  const data = docRef.data();
  return { ...data, id: docRef.id };
}

export async function getMeetingsOfMonth(monthDate) {
  let startDate = new Date(monthDate.getFullYear(), monthDate.getMonth(), 0);
  let endDate = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0);
  let role = localStorage.getItem("role");
  const seDocId = localStorage.getItem("userDocId");

  let designation = "se";
  switch (role) {
    case "Super Admin":
      designation = "sa";
      break;
    case "National Sales Head":
      designation = "nsh";
      break;
    case "Regional Sales Head":
      designation = "rsh";
      break;
    case "City Sales Head":
      designation = "csh";
      break;
    case "Sales Executive":
      designation = "se";
      break;
    default:
      designation = "se";
  }

  const firestore = app.firestore();
  let collRef;
  if (designation === "sa" || designation === "nsh") {
    collRef = await firestore
      .collection("Meetings")
      .where("date", ">=", startDate)
      .where("date", "<", endDate)
      .get();
  } else {
    collRef = await firestore
      .collection("Meetings")
      .where("date", ">=", startDate)
      .where("date", "<", endDate)
      .where(designation, "==", seDocId)
      .get();
  }
  let meetings = [];

  for (const val of collRef.docs) {
    let meet = val.data();
    let createdBy = await getSalesUserFromId(meet["createdBy"]);
    if (createdBy) {
      meetings.push({
        ...meet,
        createdByEmail: createdBy.email,
        createdByRole: createdBy.designation,
      });
    }
  }

  // console.log(meetings);
  return meetings;
}

export async function getMeetingsForNotification() {
  let startDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  // let endDate = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0);
  // const startDate = new Date();
  const todayDate = new Date();
  let numberOfDaysToAdd = 2;
  let result = todayDate.setDate(todayDate.getDate() + numberOfDaysToAdd);
  const endDate = new Date(result);

  let role = localStorage.getItem("role");
  const seDocId = localStorage.getItem("userDocId");

  let designation = "se";
  switch (role) {
    case "Super Admin":
      designation = "sa";
      break;
    case "National Sales Head":
      designation = "nsh";
      break;
    case "Regional Sales Head":
      designation = "rsh";
      break;
    case "City Sales Head":
      designation = "csh";
      break;
    case "Sales Executive":
      designation = "se";
      break;
    default:
      designation = "se";
  }

  const firestore = app.firestore();
  let collRef;
  if (designation === "sa" || designation === "nsh") {
    collRef = await firestore
      .collection("Meetings")
      .where("date", ">=", startDate)
      .where("date", "<", endDate)
      .get();
  } else {
    collRef = await firestore
      .collection("Meetings")
      .where("date", ">=", startDate)
      .where("date", "<", endDate)
      .where(designation, "==", seDocId)
      .get();
  }
  let meetings = [];

  for (const val of collRef.docs) {
    let meet = val.data();
    let createdBy = await getSalesUserFromId(meet["createdBy"]);
    if (createdBy) {
      meetings.push({
        ...meet,
        createdByEmail: createdBy.email,
        createdByRole: createdBy.designation,
      });
    }
  }

  // console.log(meetings);
  return meetings;
}

export async function getMeetingsByDate(date) {
  let startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  let endDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 1
  );
  let role = localStorage.getItem("role");
  const seDocId = localStorage.getItem("userDocId");

  let designation = "se";
  switch (role) {
    case "Super Admin":
      designation = "sa";
      break;
    case "National Sales Head":
      designation = "nsh";
      break;
    case "Regional Sales Head":
      designation = "rsh";
      break;
    case "City Sales Head":
      designation = "csh";
      break;
    case "Sales Executive":
      designation = "se";
      break;
    default:
      designation = "se";
  }

  const firestore = app.firestore();
  let collRef;
  if (designation === "sa" || designation === "nsh") {
    collRef = await firestore
      .collection("Meetings")
      .where("date", ">=", startDate)
      .where("date", "<", endDate)
      .get();
  } else {
    collRef = await firestore
      .collection("Meetings")
      .where("date", ">=", startDate)
      .where("date", "<", endDate)
      .where(designation, "==", seDocId)
      .get();
  }
  let meetings = [];

  for (const val of collRef.docs) {
    let meet = val.data();
    let createdBy = await getSalesUserFromId(meet["createdBy"]);
    if (createdBy) {
      meetings.push({
        ...meet,
        createdByEmail: createdBy.email,
        createdByRole: createdBy.designation,
      });
    }
  }

  console.log(meetings);
  return meetings;
}

export async function getCreatedByEmailsOfMeetings(meetings) {
  for (const meet of meetings) {
    let createdBy = await getSalesUserFromId(meet["createdBy"]);
    if (createdBy) {
      meetings.push({
        ...meet,
        createdByEmail: createdBy.email,
        createdByRole: createdBy.designation,
      });
    }
  }

  console.log(meetings);
  return meetings;
}

export async function getSalesUserFromId(id) {
  if (!id) return null;
  const firestore = app.firestore();

  const docRef = await firestore.collection("Users").doc(id).get();
  if (!docRef.exists) return {};
  const data = docRef.data();

  // console.log(data);
  return data;
}

export async function getFranchiseCityCounter(city) {
  const firestore = app.firestore();

  const fdocRef = await firestore.collection("Counters").doc("Franchise").get();
  // const edocRef = await firestore.collection("Counters").doc("Estore").get();
  // let fCount = 0;

  // if (fdocRef.data()[city]) {
  //   fCount += fdocRef.data()[city];
  // } else if (edocRef.data()[city]) {
  //   fCount += edocRef.data()[city];
  // }
  // console.log(fCount);
  return fdocRef.data()?.[city] || 0;
}

export async function getProductDataOfCity(city) {
  const firestore = app.firestore();

  const cityRef = firestore.collection("FranchiseProductApprovals").doc(city);

  const docRef = await cityRef.get();

  let franchise = docRef.data()["franchise"];

  let counter = docRef.data()["counter"];

  const collRef = await cityRef.collection("Products").get();

  let products = [];
  for (const val of collRef.docs) {
    let pro = val.data();
    products.push(pro);
  }

  console.log(products);
  return { counter, franchise, products };
}
export async function getPendingProductDataOfCity(city) {
  const firestore = app.firestore();

  const cityRef = firestore.collection("FranchiseProductApprovals").doc(city);

  const docRef = await cityRef.get();

  let franchise = docRef.data()["franchise"];

  let counter = docRef.data()["counter"];

  const collRef = await cityRef
    .collection("Products")
    .where("STATUS", "==", "pending")
    .get();

  let products = [];
  for (const val of collRef.docs) {
    let pro = val.data();
    products.push(pro);
  }

  console.log(products);
  return { counter, franchise, products };
}
export async function getFranchisePendingProductDataOfCity(city) {
  const firestore = app.firestore();

  const cityRef = firestore.collection("FranchiseProductApprovals").doc(city);

  const docRef = await cityRef.get();

  let franchise = docRef.data()["franchise"];

  let counter = docRef.data()["counter"];

  const collRef = await cityRef
    .collection("Products")
    .where("STATUS", "==", "franchise-pending")
    .get();

  let products = [];
  for (const val of collRef.docs) {
    let pro = val.data();
    products.push(pro);
  }

  console.log(products);
  return { counter, franchise, products };
}

export async function getFranchiseDataOfCityFromFinalProducts(city) {
  const firestore = app.firestore();

  const cityRef = firestore.collection("Products").doc(city);

  const docRef = await cityRef.get();

  let franchise = docRef.data()["franchise"] ?? "";

  return franchise;
}

export async function getFinalProductDataOfCity(city) {
  const firestore = app.firestore();

  const cityRef = firestore.collection("Products").doc(city);

  const docRef = await cityRef.get();

  let franchise = docRef.data()["franchise"] ?? "";

  const collRef = await cityRef.collection("Products").get();

  let products = [];
  for (const val of collRef.docs) {
    let pro = val.data();
    products.push(pro);
  }

  console.log(products);
  return { franchise, products };
}

export async function getPanLevelProducts() {
  const collArr = [];
  const firestore = app.firestore();

  const collRef = await firestore.collection("PAN-Products").get();

  let products = {};
  for (const val of collRef.docs) {
    let pro = val.data();
    collArr.push(pro);
  }

  console.log(collArr);
  return collArr;
}

export async function getCitiesFromProductApproval() {
  const firestore = app.firestore();
  let cities = [];
  const collectionRef = await firestore
    .collection("FranchiseProductApprovals")
    .get();

  collectionRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    cities.push({
      value: doc.id,
    });
  });

  console.log("cities form firestore", cities);
  return cities;
}

export async function getCitiesFromProducts() {
  const firestore = app.firestore();
  let cities = [];
  const collectionRef = await firestore.collection("Products").get();

  collectionRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    cities.push({
      value: doc.id,
    });
  });

  console.log("cities form firestore", cities);
  return cities;
}

export async function getSellersForProductApproval() {
  const firestore = app.firestore();
  let sellers = {};
  const role = localStorage.getItem("role");
  let collectionRef;
  if (role === "Super Admin") {
    collectionRef = await firestore
      .collection("SellerProducts")
      .where("status", "==", "Pending")
      .get();
  } else if (role === "Product Manager") {
    collectionRef = await firestore.collection("SellerProducts").get();
  } else {
    return {};
  }

  collectionRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    sellers[doc.id] = {
      id: doc.id,
      ...doc.data(),
    };
  });

  console.log("sellers form firestore", sellers);
  return sellers;
}

export async function getProductDataOfSeller(seller, isApproved) {
  const firestore = app.firestore();
  const role = localStorage.getItem("role");

  const cityRef = firestore.collection("SellerProducts").doc(seller);

  let collRef;
  if (isApproved) {
    collRef = await cityRef
      .collection("Products")
      .where("status", "==", "Approved")
      .get();
  } else {
    if (role === "Product Manager") {
      collRef = await cityRef
        .collection("Products")
        .where("status", "==", "Pending")
        .get();
    } else if (role === "Super Admin") {
      collRef = await cityRef
        .collection("Products")
        .where("status", "==", "Rejected By PM")
        .get();
    }
  }
  let products = [];
  for (const val of collRef.docs) {
    let pro = val.data();
    products.push(pro);
  }

  console.log(products);
  return products;
}
export async function getSalesUserFromRole(role) {
  const firestore = app.firestore();

  const collRef = await firestore
    .collection("Users")
    .where("designation", "==", role)
    .where("userDeleted", "==", false)
    .get();
  let users = [];
  for (const user of collRef.docs) {
    users.push(user.data());
  }
  return users;
}

export async function getProspectDataFromLeadId(leadId) {
  const firestore = app.firestore();

  const collRef = await firestore
    .collection("Prospects")
    .where("leadId", "==", leadId)
    .get();
  if (collRef.docs.length > 0) {
    const prospect = collRef.docs[0].data();
    return { isPresent: true, prospect };
  }
  return { isPresent: false };
}

export async function getDataToViewProspects(data) {
  const firestore = app.firestore();
  let res = {};
  // console.log(data);
  const docRef = await firestore.collection("Leads").doc(data["leadId"]).get();

  if (!docRef.exists) return res;

  res = {
    city: docRef.data()["city"],
    state: docRef.data()["state"],
  };

  // console.log(res);
  return res;
}

export async function getSellerData(id) {
  const firestore = app.firestore();

  const docRef = await firestore.collection("Sellers").doc(id).get();

  if (docRef.exists) {
    const data = docRef.data();
    return { successfull: true, seller: data };
  }

  return { successfull: false };
}

export async function getBranchesOfClient(id) {
  const firestore = app.firestore();
  console.log(id);
  let branches = [];
  const collRef = await firestore
    .collection("Clients")
    .where("createdBy", "==", id)
    .get();

  if (collRef.empty) {
    return branches;
  }

  for (let doc of collRef.docs) {
    branches.push(doc.data());
  }
  return branches;
}

export async function getClientBranches(client_id) {
  const firestore = app.firestore();
  let branches = [];
  const collRef = await firestore
    .collection("Clients/" + client_id + "/Branches")
    .get();

  if (collRef.empty) {
    return branches;
  }

  for (let doc of collRef.docs) {
    branches.push(doc.data());
  }
  return branches;
}

export async function getNewBranchDocId(client_id) {
  const firestore = app.firestore();
  let docRef = firestore.collection("Clients").doc(client_id);
  docRef = docRef.collection("Branches").doc();
  return docRef.id;
}

export async function getProductsFromBranchName(clientId, branchName) {
  const firestore = app.firestore();

  let products = {};
  const collRef = await firestore
    .collection("Clients")
    .doc(clientId)
    .collection(branchName)
    .get();

  if (collRef.empty) {
    return products;
  }

  for (let doc of collRef.docs) {
    products[doc.id] = doc.data()["clientPrice"];
  }
  return products;
}

export async function getCityAndRegionOfSE(seId) {
  const firestore = app.firestore();

  let data = {};
  const docRef = await firestore.collection("Users").doc(seId).get();

  if (!docRef.exists) {
    return data;
  }
  data["contact"] = docRef.data()["phone"];

  data["csh"] = docRef.data()["createdby"];

  const cshRef = await firestore.collection("Users").doc(data.csh).get();
  data["city"] = cshRef.data()["city"] ?? "";
  data["rsh"] = cshRef.data()["createdby"];

  const rshRef = await firestore.collection("Users").doc(data.rsh).get();
  data["region"] = rshRef.data()["region"] ?? "";
  data["nsh"] = rshRef.data()["createdby"];

  return data;
}

export async function getProspetsForProductRequests(seId) {
  const firestore = app.firestore();

  let prospects = {};
  const docRef = await firestore.collection("PM-Requests").doc(seId).get();

  if (!docRef.exists) {
    return prospects;
  }

  prospects = docRef.data()["prospects"];
  return prospects;
}

export async function getRemarksOfMeeting(id) {
  const firestore = app.firestore();

  const docRef = await firestore.collection("Meetings").doc(id).get();
  let remarks = [];
  if (!docRef.exists) {
    return remarks;
  }
  remarks = docRef.data()["remarks"];
  if (remarks) return remarks;
  else return [];
}

export async function getNearestFranchiseAndEstore(
  name,
  address,
  city,
  state,
  postalCode,
  isPAN,
  isPurpose
) {
  const locationName = name;
  const locationAddress = address;
  const locationCity = city;
  const locationState = state;
  const locationPostalCode = postalCode;

  const branchLocation = await getBranchLocation(
    locationName,
    locationAddress,
    locationCity,
    locationState,
    locationPostalCode
  );
  // console.log("Branch Location is ", branchLocation);

  const franchises = await getNearestFranchisesInCity(
    city,
    branchLocation,
    state,
    isPAN
  );
  const estores = await getNearestEstoresInCity(city, branchLocation);

  const totalStores = { ...franchises, ...estores };

  //short all stores(franchise and estore) by distance
  const sortedTotalStores = {};
  //for reducing the franchise and estore for blocking purspose
  if (isPurpose?.puspose === "forBlocking") {
    for (let vendorId of isPurpose?.vendorsId) {
      delete totalStores[vendorId];
    }
  }
  const unshortedStoreArray = Object.values(totalStores);
  const shortedStoreArray = unshortedStoreArray.sort(
    (a, b) => a.distance - b.distance
  );
  shortedStoreArray.map((store, index) => {
    return (sortedTotalStores[store.ids] = {
      name: store.name,
      distance: store.distance,
      type: store.type,
      noOfClients: store.noOfClients,
    });
  });
  return {
    franchises: sortedTotalStores,
    shortestFranchise: shortedStoreArray[0],
  };
}

export async function getNearestFranchisesInCity(
  city,
  branchLocation,
  state,
  isPan
) {
  const firestore = app.firestore();
  let collRef;
  let franchises = {};
  if (!isPan) {
    collRef = await firestore
      .collection("Franchises")
      .where("city", "==", city)
      .get();
  } else {
    collRef = await firestore
      .collection("Franchises")
      .where("indianState", "==", state)
      .get();
  }
  if (collRef.empty) return franchises;
  const sortByDist = async (dist) => {
    for (let doc of collRef.docs) {
      if (!doc.data()["isBlockedFranchise"]) {
        let name = doc.data()["companyName"];
        let noOfClients = doc.data()["noOfClients"] ?? 0;
        const companyLocation = {
          lat: doc.data()["lat"],
          lng: doc.data()["lng"],
        };
        const { inRadius, distance } = checkDistance(
          branchLocation,
          companyLocation,
          dist
        );
        if (inRadius)
          franchises[doc.id] = {
            name,
            distance,
            type: "Franchise",
            noOfClients,
            ids: doc.id,
          };
      }
    }
  };
  await sortByDist(50);

  return franchises;
}

export async function getNearestEstoresInCity(city, branchLocation) {
  const firestore = app.firestore();

  let estores = {};
  const collRef = await firestore
    .collection("Estores")
    .where("city", "==", city)
    .get();

  if (collRef.empty) return estores;
  const sortByDist = async (dist) => {
    for (let doc of collRef.docs) {
      if (!doc.data()["isBlockedFranchise"]) {
        let name = doc.data()["companyName"];
        let noOfClients = doc.data()["noOfClients"] ?? 0;

        const companyLocation = {
          lat: doc.data()["lat"],
          lng: doc.data()["lng"],
        };
        const { inRadius, distance } = checkDistance(
          branchLocation,
          companyLocation,
          50
        );
        if (inRadius)
          estores[doc.id] = {
            name,
            distance,
            type: "Estore",
            noOfClients,
            ids: doc.id,
          };
      }
    }
  };
  await sortByDist(50);

  return estores;
}

export async function getSelectedProductsOfCity(prospectId, city) {
  const firestore = app.firestore();

  let products = {};
  const collRef = await firestore
    .collection("Prospects")
    .doc(prospectId)
    .collection(city)
    .get();

  if (!collRef.exists) {
    return {};
  }

  for (let doc of collRef.docs) {
    let pro = doc.data();
    products = { ...products, ...pro };
  }

  return products;
}

export async function getProductsOfBranch(clientId, branchName) {
  const firestore = app.firestore();

  let products = {};
  const collRef = await firestore
    .collection("Clients")
    .doc(clientId)
    .collection(branchName)
    .get();
  if (collRef.empty) {
    return {};
  }

  for (let doc of collRef.docs) {
    let pro = doc.data();
    products[pro.id] = pro;
  }
  console.log(products);
  return products;
}

export async function getVendorsOfCity(city) {
  const firestore = app.firestore();
  let vendors = {};
  const collRef = await firestore
    .collection("Franchises")
    .where("city", "==", city)
    .get();

  // const estoreRef = await firestore
  //   .collection("Estores")
  //   .where("city", "==", city)
  //   .get();

  if (!collRef.empty) {
    for (let doc of collRef.docs) {
      let ven = doc.data();
      vendors[ven.id] = {
        name: ven.companyName,
        email: ven.email,
        mobile: ven.mobileNo,
      };
    }
  }

  // if (!estoreRef.empty) {
  //   for (let doc of estoreRef.docs) {
  //     let ven = doc.data();
  //     vendors[ven.id] = {
  //       name: ven.companyName,
  //       email: ven.email,
  //       mobile: ven.mobileNo ?? "NA",
  //     };
  //   }
  // }

  return vendors;
}

// get franchise who has approved products

export async function getFranchiseOfCityWithProductApproved(city) {
  const firestore = app.firestore();
  let vendors = [];
  let approvedVendors = [];
  const collRef = await firestore
    .collection("Franchises")
    .where("city", "==", city)
    .get();

  if (!collRef.empty) {
    for (let doc of collRef.docs) {
      let ven = doc.data();

      let data = {
        id: ven.id,
        name: ven.companyName,
        email: ven.email,
        mobile: ven.mobileNo,
        status: "pending",
      };
      vendors.push(data);
    }
  }

  const franRef = await firestore
    .collection("FranchiseProductApprovals")
    .doc(city)
    .get();
  const franchaiseData = franRef.data();
  console.log(franRef.data(), "franref");
  if (franchaiseData.counter === 0 || !franchaiseData.franchiseApproval) {
    return false;
  }

  for (let i = 0; i < franchaiseData.franchiseApproval.length; i++) {
    for (let j = 0; j < vendors.length; j++) {
      if (franchaiseData.franchiseApproval[i] === vendors[j].id) {
        vendors[j].status = "approved";
      }
    }
  }

  return vendors;
}

export async function getFranchiseofCity(city) {
  const firestore = app.firestore();
  let vendors = [];
  const collRef = await firestore
    .collection("Franchises")
    .where("city", "==", city)
    .get();

  const franProdRef = await firestore
    .collection("FranchiseProductApprovals")
    .doc(city)
    .get();

  let franProdDoc = franProdRef.data();

  if (!collRef.empty) {
    for (let doc of collRef.docs) {
      let ven = doc.data();
      let approve =
        franProdDoc.franchiseApproval &&
        franProdDoc.franchiseApproval.filter((item) => {
          return item === ven.id ? true : false;
        });
      if (approve && approve.length > 0) {
        let data = {
          id: ven.id,
          name: ven.companyName,
          email: ven.email,
          mobile: ven.mobileNo,
          status: "Approved",
        };
        vendors.push(data);
      } else {
        let data = {
          id: ven.id,
          name: ven.companyName,
          email: ven.email,
          mobile: ven.mobileNo,
          status: "Pending",
        };

        vendors.push(data);
      }
    }
  }

  return vendors;
}

export async function getProfiles() {
  const data = [];
  const firestore = app.firestore();
  let collectionRef = await firestore.collection("Profile").get();
  collectionRef.forEach(async (doc) => {
    let fData = doc.data();
    fData["id"] = doc.id;
    data.push(fData);
  });
  return { status: 200, data };
}

export async function getUsers() {
  const data = [];
  const firestore = app.firestore();
  let collectionRef = await firestore
    .collection("Users")
    .orderBy("createdDate", "asc")
    .get();
  collectionRef.forEach(async (doc) => {
    let fData = doc.data();
    fData["id"] = doc.id;
    data.push(fData);
  });
  return { status: 200, data };
}

export async function getLocationLeftFromMeeting(meeting) {
  const firestore = app.firestore();
  console.log({ meeting });
  let res = { successfull: false };

  if (!meeting.id) return res;
  const docRef = firestore.collection("Meetings").doc(meeting.id);

  const docData = await docRef.get();
  if (docData.exists) {
    res["successfull"] = true;
    if (docData.data()["locationLeft"]) res["locationLeft"] = true;
    else res["locationLeft"] = false;

    if (docData.data()["locationReentered"]) res["locationReentered"] = true;
    else res["locationReentered"] = false;
  }
  return res;
}

export async function getMeetingDataOfSE(docId) {
  const firestore = app.firestore();
  const docRef = await firestore.collection("Users").doc(docId).get();

  let meeting = null;
  if (docRef.exists) {
    let meetingId = docRef.data()["checkInMeeting"] ?? "";
    if (meetingId === "") {
      return null;
    }
    meeting = await getMeetingsDataFromId(meetingId);
  }

  return meeting;
}

export const getMeetingsDataFromId = async (id) => {
  const firestore = app.firestore();
  const docRef = await firestore.collection("Meetings").doc(id).get();

  let meeting = null;
  if (docRef.exists) {
    meeting = docRef.data();
  }

  return meeting;
};

// city codes
export async function getCities() {
  const firestore = app.firestore();
  let cityName = [];
  const collectionRef = await firestore.collection("CityCodes").get();
  collectionRef.forEach((doc) => {
    let city = doc.data();
    city["id"] = doc.id;
    cityName.push(doc.data());
  });

  console.log(cityName);
  return cityName;
}

// city codes with id
export async function getCitiesWithId() {
  const firestore = app.firestore();
  let cityData = {};
  const collectionRef = await firestore.collection("CityCodes").get();
  collectionRef.forEach((doc) => {
    cityData[doc.id] = doc.data();
  });
  return cityData;
}

// export async function getCitiesUsingIds(city_data) {
//   const firestore = app.firestore();
//   let cityData = firestore.getAll(...city_data).then(docs => {
//     console.log(docs);
//     return docs;
//   })
//   const collectionRef = await firestore.collection("CityCodes").get();
//   collectionRef.forEach((doc) => {
//     cityData[doc.id] = doc.data();
//   });
//   return cityData;
// }

// get categories
export async function getProductsCategories() {
  const firestore = app.firestore();

  let categories = [];
  const collectionRef = await firestore.collection("ProductCategories").get();
  collectionRef.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    categories.push(doc.data());
  });
  console.log(categories);
  return categories;
}
export async function prospectDataforGRContract(seId, prospectDetails) {
  const prospects = [];
  const firestore = app.firestore();
  const sedocRef = await firestore
    .collection("PM-Requests")
    .doc(seId)
    .collection(prospectDetails.prospectId)
    .get();
  sedocRef.forEach((doc) => {
    prospects.push(doc.data());
  });
  return prospects ?? [];
}

export async function getHistoryData(seId, product, prospectId) {
  const firestore = app.firestore();
  const docRef = await firestore
    .collection("PM-Requests")
    .doc(seId)
    .collection(prospectId)
    .doc(product.id)
    .get();
  return docRef.data()?.prospectPriceHistory ?? [];
}

export async function getBlockSubCategoryData(clientId, hierarchyName) {
  const firestore = app.firestore();
  const docRef = await firestore
    .collection("Clients")
    .doc(clientId)
    .collection(BlockingCategories[hierarchyName])
    .get();
  let usersData = [];
  for (const user of docRef.docs) {
    let data;
    if (hierarchyName === "City") {
      data = await getCityById(user.data().cityCode_id);
      usersData.push({
        ...user.data(),
        id: user.id,
        name: data.city,
      });
    } else {
      usersData.push({
        ...user.data(),
        id: user.id,
      });
    }
  }
  return usersData;
}

export async function getUnblockSubCategoryData(clientId, hierarchyName) {
  const firestore = app.firestore();
  const docRef = await firestore
    .collection("Clients")
    .doc(clientId)
    .collection(BlockingCategories[hierarchyName])
    .where("isBlocked", "==", true)
    .get();
  let blockedCoasterData = [];
  for (const user of docRef.docs) {
    let data;
    if (hierarchyName === "City") {
      data = await getCityById(user.data().cityCode_id);
      blockedCoasterData.push({
        ...user.data(),
        id: user.id,
        name: data.city,
      });
    } else {
      blockedCoasterData.push({
        ...user.data(),
        id: user.id,
      });
    }
  }
  return blockedCoasterData;
}

export async function getBranchAssignedFranchise(branch_id, clientId) {
  const firestore = app.firestore();
  const branchRef = await firestore
    .collection("Clients")
    .doc(clientId)
    .collection("Branches")
    .doc(branch_id)
    .get();
  const branchData = branchRef.data();
  const vendorId = branchData.vendor;
  let vendorData; //all data of branch assigned vendor
  if (branchData.vendorType === "Franchise") {
    //for Franchise
    const franchiseRef = await firestore
      .collection("Franchises")
      .doc(vendorId)
      .get();
    vendorData = franchiseRef.data();
  } else {
    //for estore
    const estoreRef = await firestore.collection("Estores").doc(vendorId).get();
    vendorData = estoreRef.data();
  }
  return vendorData;
}
//  get city wise products

export async function getEstoresBranchIds(franchiseData) {
  const firestore = app.firestore();
  let vendorsBranch = { ...franchiseData.assignedBranch };
  const collRef = await firestore
    .collection("Estores")
    .where("franchiseId", "==", franchiseData.id)
    .get();

  if (!collRef.empty) {
    for (let doc of collRef.docs) {
      const estoreBranchData = doc.data().assignedBranch;
      if (estoreBranchData) {
        vendorsBranch = { ...vendorsBranch, ...estoreBranchData };
      }
    }
  }
  return vendorsBranch;
}

export async function getBranchdataFromIds(assignedBranchObj) {
  const firestore = app.firestore();
  const branchIds = Object.keys(assignedBranchObj);
  const docRef = firestore.collection("Clients");
  const branchData = [];

  // get data of every branch
  if (branchIds.length) {
    for (let branchId of branchIds) {
      let clientId = assignedBranchObj[branchId];
      const branchRef = await docRef
        .doc(clientId)
        .collection("Branches")
        .doc(branchId)
        .get();
      branchData.push({
        ...branchRef.data(),
        client_id: clientId,
        nearestVendors: [],
        isChangeVendor: false,
        isPan: await (await docRef.doc(clientId).get()).data().isPAN,
        previousVendor: branchRef.data().vendor,
        previousVendorName: branchRef.data().vendorName,
        previousVendorType: branchRef.data().vendorType,
      });
    }
    return branchData;
  }
}
export async function getFranchiseEstoresData(franchiseData) {
  const firestore = app.firestore();
  let vendorsData = [franchiseData];

  const collRef = await firestore
    .collection("Estores")
    .where("franchiseId", "==", franchiseData.id)
    .get();

  if (!collRef.empty) {
    for (let doc of collRef.docs) {
      const estoreBranchData = doc.data();
      vendorsData.push(estoreBranchData);
    }
  }
  return vendorsData;
}

export async function franchiseVendorIds(franchiseId) {
  const firestore = app.firestore();
  let vendorIds = [franchiseId];
  const collRef = await firestore
    .collection("Estores")
    .where("franchiseId", "==", franchiseId)
    .get();

  if (!collRef.empty) {
    for (let doc of collRef.docs) {
      const estoreDocId = doc.id;
      vendorIds.push(estoreDocId);
    }
  }
  return vendorIds;
}

export async function getDivertRequest() {
  const firestore = app.firestore();
  const data = [];
  const requestRef = await firestore.collection("DivertRequest").get();
  if (!requestRef.empty) {
    for (let doc of requestRef.docs) {
      data.push({ ...doc.data(), id: doc.id });
    }
  }
  return data;
}
//  get city wise products

export async function getFInalProducts(city) {
  let docArr = [];
  const firestore = app.firestore();
  const docRef = await firestore
    .collection("Products")
    .doc(city)
    .collection("Products")
    .get();
  // const data = docRef.data()

  // docRef.get().then((doc)=>{
  //   if(doc.exists){
  //     docArr.push(doc.data())
  //   }else{
  //     console.log(doc)
  //   }
  // })
  console.log(docArr);

  if (!docRef.empty) {
    docRef.forEach((data) => {
      docArr.push(data.data());
    });
  }

  return docArr;
}

// this function get store managers of frnachise

export async function getStoreManagerOfFanchise(franchise) {
  let StoreManagers = [];
  const firestore = app.firestore();
  const docRef = await firestore
    .collection("Franchises")
    .where("role", "==", "Store Manager")
    .where("createdBY", "==", franchise)
    .get();

  if (docRef.empty) {
    return false;
  }

  docRef.forEach((doc) => {
    StoreManagers.push(doc.data());
  });

  return StoreManagers;
}

export async function getLeadsForMarketing() {
  let leads = [];
  let firestore = app.firestore();

  const docRef = await firestore.collection("Leads").get();

  if (docRef.empty) {
    return false;
  }

  docRef.forEach((item) => {
    leads.push(item.data());
  });

  return leads;
}

export async function getAllUsersforMarketingLeads() {
  let firestore = app.firestore();
  let allUsers = [];

  let usersRef = await firestore
    .collection("Users")
    .where("designation", "in", [
      "National Sales Head",
      "Regional Sales Head",
      "City Sales Head",
      "Sales Executive",
    ])
    .get();

  if (usersRef.empty) {
    return false;
  }

  usersRef.forEach((item) => {
    allUsers.push(item.data());
  });

  return allUsers;
}

export async function getAllSkuId() {
  try {
    let products = [];
    let firestore = app.firestore();
    let docRef = await firestore.collection("PAN-Products").get();
    // console.log(docRef, "docref")
    docRef.docs.forEach((item) => {
      products.push(item.data()["SKU ID"]);
    });
    return products;
  } catch (error) {
    console.log(error);
    return false;
  }
}
