import React from "react";
import Marketing from "../Marketing";
import { ViewCaseStudyTable } from "./ViewCaseStudyTable";
import { useGetPermissions } from "context/PermissionsProvider";

function CaseStudyView() {
  const { userPermissions } = useGetPermissions();

  const canViewCaseStudy = userPermissions?.["Career"]?.View;

  return (
    <Marketing>
      {canViewCaseStudy ? (
        <ViewCaseStudyTable />
      ) : canViewCaseStudy === false ? (
        <h1>You do not have permission to view case study</h1>
      ) : (
        "Loading..."
      )}
    </Marketing>
  );
}

export default CaseStudyView;
