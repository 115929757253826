import React from "react";
import { useHistory, useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";

import Layout from "../../common/Layout";
import { ColorButton } from "../../common/Components/ColorButton";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    //   margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    textAlign: "justify",
    boxShadow: "0 3px 5px rgba(0,0,0,0.12), 0 3px 3px rgba(0,0,0,0.15)",
    fontFamily: "Roboto",
  },
  blog_desc: {
    justifyContent: "center",
    textAlign: "center",
    textAlign: "justify",
    // textIndent: "70px",
    marginTop: "5px",
  },
  authorDetail: {
    marginTop: "10px",
    padding: "10px",
    fontFamily: "Roboto",
  },
  author: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    fontSize: "18px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    float: "right",
  },
});

function ClientApprovalDetails() {
  let history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  let client = location.state;
  console.log("selected client : => ", location.state);

  function showProducts() {
    history.push({pathname:"/client-approval-product-list", state : { state : client.selectedProducts, categories : client.categories }});
  }
  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>Client Details</h2>
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ textAlign: "center" }}
                >
                  Client Details
                </Typography>
                <p className={classes.client_desc}>
                  <b>Client Name: </b> {client.name}
                </p>
                <p className={classes.client_desc}>
                  <b>Client Webiste:</b> {client.website}
                </p>
                <p className={classes.client_desc}>
                  <b>Type of Model:</b> {client.modelType}
                </p>
                <p className={classes.client_desc}>
                  <b>Corporate Name: </b> {client.corporateName}
                </p>
                <p className={classes.client_desc}>
                  <b>Corporate Address: </b> {client.corporateAddress}
                </p>
                <p className={classes.client_desc}>
                  <b>Corporate Pincode: </b> {client.corporatePincode}
                </p>
                <p className={classes.client_desc}>
                  <b>Corporate Type of GST: </b> {client.corporateGstType}
                </p>
                <p className={classes.client_desc}>
                  <b>Corporate GST Number: </b> {client.corporateGstNo}
                </p>
                <p className={classes.client_desc}>
                  <b>Corporate State: </b> {client.corporateState}
                </p>

                <p className={classes.client_desc}>
                  <b>Registered Name: </b> {client.registeredName}
                </p>
                <p className={classes.client_desc}>
                  <b>Registered Address: </b> {client.registeredAddress}
                </p>
                <p className={classes.client_desc}>
                  <b>Registered Pincode: </b> {client.registeredPincode}
                </p>
                <p className={classes.client_desc}>
                  <b>Registered Type of GST: </b> {client.registeredGstType}
                </p>
                <p className={classes.client_desc}>
                  <b>Registered GST Number: </b> {client.registeredGstNo}
                </p>
                <p className={classes.client_desc}>
                  <b>Registered State: </b> {client.registeredState}
                </p>
              </CardContent>
            </Card>
            <br></br>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ textAlign: "center" }}
                >
                  Client Branch Details
                </Typography>
                <p className={classes.client_desc}>
                  <b>Branch Name:</b> {client.branch[0].name}
                </p>

                <p className={classes.client_desc}>
                  <b>Branch Address:</b> {client.branch[0].address}
                </p>
                <p className={classes.client_desc}>
                  <b>Pincode:</b> {client.branch[0].pincode}
                </p>
                <p className={classes.client_desc}>
                  <b>Shipping Address:</b> {client.branch[0].shippingAddress}
                </p>
                <p className={classes.client_desc}>
                  <b>SEZ letter:</b> {client.branch[0].sezLetter}
                </p>
                <p className={classes.client_desc}>
                  <b>GST Type:</b> {client.branch[0].gstType}
                </p>
                <p className={classes.client_desc}>
                  <b>GST Number:</b> {client.branch[0].gstNo}
                </p>
                <p className={classes.client_desc}>
                  <b>State:</b> {client.branch[0].state}
                </p>
                <p className={classes.client_desc}>
                  <b>City:</b> {client.branch[0].city}
                </p>
                <p className={classes.client_desc}>
                  <b>District:</b> {client.branch[0].district}
                </p>
                <p className={classes.client_desc}>
                  <b>Is Mail Room Available:</b>{" "}
                  {client.branch[0].isMailRoom ? "YES" : "NO"}
                </p>
                <p className={classes.client_desc}>
                  <b>Franchise:</b> {client.branch[0].franchise}
                </p>
              </CardContent>
            </Card>
            <br />
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ textAlign: "center" }}
                >
                  Client Billing Details
                </Typography>

                <p className={classes.client_desc}>
                  <b>Cost Center Email:</b> {client.costCenterEmail}
                </p>
                <p className={classes.client_desc}>
                  <b>Billing City:</b> {client.billingCity}
                </p>
                <p className={classes.client_desc}>
                  <b>Billing State:</b> {client.billingState}
                </p>
                <p className={classes.client_desc}>
                  <b>Pan Number:</b> {client.panNo}
                </p>
              </CardContent>
            </Card>
            <br />

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ textAlign: "center" }}
                >
                  Client Products:
                </Typography>

                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={showProducts}
                >
                  Show Product List
                </ColorButton>
              </CardContent>
            </Card>
            <br />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ClientApprovalDetails;
