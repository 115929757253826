import React, { createContext, useEffect, useRef, useState } from "react";

export const RateContractContext = createContext({
  selectedProducts: {},
  changePrice: null,
  selectedProductsData: {},
});

const RateContractProvider = ({
  children,
  cityProducts,
  cities,
  productsData,
}) => {
  const [selectedProducts, setSelectedProducts] = useState(cityProducts);
  const [selectedProductsData, setSelectedProductsData] = useState({});
  const [selectedSendRqstToPM, setSelectedSendRqstToPM] = useState({});
  useEffect(() => {
    setSelectedSendRqstToPM(cities);
  }, [cities]);

  useEffect(() => {
    setSelectedProducts(cityProducts);
  }, [cityProducts]);

  useEffect(() => {
    setSelectedProductsData(productsData);
  }, [productsData]);

  const changePrice = (id, price, city, checked, MRP, GST, nexstocPrice) => {
    if (checked) {
      selectedProducts[city][id] = price;
      selectedProductsData[id] = {};
      selectedProductsData[id]["Rate"] = price;
      selectedProductsData[id]["MRP"] = MRP;
      selectedProductsData[id]["GST"] = GST;
      selectedProductsData[id]["NexstocPrice"] =
        parseFloat(nexstocPrice).toFixed(2);
    } else {
      delete selectedProducts[city][id];
      delete selectedProductsData[id];
    }

    setSelectedProducts({ ...selectedProducts });
    setSelectedProductsData({ ...selectedProductsData });
  };

  function clearSelectedProducts() {
    setSelectedProducts({ cityProducts });
  }

  async function storeRequestForPM(city, product) {
    selectedSendRqstToPM[city][product.id] = product;
    setSelectedSendRqstToPM({ ...selectedSendRqstToPM });
    return { successfull: true };
  }

  return (
    <RateContractContext.Provider
      value={{
        selectedProducts,
        changePrice,
        selectedProductsData,
        storeRequestForPM,
        selectedSendRqstToPM,
      }}
    >
      {children}
    </RateContractContext.Provider>
  );
};
export default RateContractProvider;
