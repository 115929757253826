export const resourcesConfig = [
  {
    id: 1,
    resourceGroup: "Marketing Tab",
    resources: [
      {
        id: 1,
        resource: "Blog",
      },
      {
        id: 2,
        resource: "Career",
      },
      {
        id: 3,
        resource: "Case Study",
      },
      {
        id: 4,
        resource: "Testimonial",
      },
      {
        id: 5,
        resource: "Marketing Link",
      },
    ],
  },
  {
    id: 2,
    resourceGroup: "Employee Tab",
    resources: [
      {
        id: 1,
        resource: "Employee",
      },
      {
        id: 2,
        resource: "Profile",
      },
    ],
  },
];

export const actionConfig = [
  {
    id: 1,
    action: "Add",
  },
  {
    id: 2,
    action: "View",
  },
  {
    id: 3,
    action: "Edit",
  },
  {
    id: 4,
    action: "Delete",
  },
];
