import React, { useEffect, useState } from 'react';
import { Media } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Layout from '../../common/Layout';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import 'firebase/storage';
import {useHistory} from 'react-router-dom';
import { useLocation } from 'react-router';
import {getPageName} from "././../../common/const";

const Applications = () => {
    const [blogs, setBlogs] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [searchResult, setSearchResult] = useState([])
    
    const db = useFirestore();
    const storage = useFirebaseApp().storage();
    let history = useHistory();
    const location = useLocation();

    
    useEffect(() => {
        fetchBlogs();
    }, [])


    const useStyles = makeStyles((theme) => ({
        button: {
        margin: theme.spacing(1),
        },
    }));
    const classes = useStyles();

// function delete blogs

// function DeleteCareer(id){
//     console.log("this is ",id);
//     db.collection("Careers").doc(id).delete().then(() => {
//         console.log("Document successfully deleted!");
//         window.location.reload();

//     }).catch((error) => {
//         console.error("Error removing document: ", error);
//     });
// }



  // custom button

const ColorApproveButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#18d26e",
      color : 'white',
      fontWeight : 'bold',
      '&:hover': {
        backgroundColor: "#18d26e",
      },
    },
  }))(Button);

const ColorRejectButton = withStyles((theme) => ({
    root: {
      backgroundColor: "red",
      color : 'white',
      fontWeight : 'bold',
      '&:hover': {
        backgroundColor: "red",
      },
    },
  }))(Button);

    const fetchBlogs=async()=>{
        let doc = location.state;
        console.log("selected job : => ",location.state);

        const response=db.collection('Careers').doc(doc).collection("Applications");
        const data=await response.get();
        data.docs.forEach(item=>{
         setBlogs(blogs => [...blogs,item.data()])
         console.log(item.data())
        })
    }

    //  View selected blog btn click
    const ViewApplications = (job,applications) => {
        console.log(applications);
        history.push(`/view-career/${job}`,applications)
    }

    //  Search in textbox
    const searchBlogResult = (val) => {
        return blogs.filter(data => data.name.toLowerCase().includes(val.toLowerCase())|| data.email.toLowerCase().includes(val.toLowerCase()))
        
    }
    const search = (e) => {
        setSearchValue(e.target.value)
        console.log(e.target.value)
  
        setSearchResult(searchBlogResult(e.target.value))
    }

  return (
    <Layout>
        <div className="container mt-5 pt-5">
        <h2 style={{ textAlign:"center" }}>{getPageName()}</h2>
        {blogs.length === 0 ? <div className="row justify-content-center">
            <p>No Applications yet!</p>
        </div> : (<>
            <div className="row pb-3 justify-content-center"><input type="text"
             className="col-10 col-md-6 search-box" 
            placeholder = "Search Job" onChange={e => {search(e)}}/></div>
            {
                
                blogs.length > 0 && searchValue === '' ? blogs.map((blog)=>{
                return(
       
                <Media list key={blog.id} className="blog-card">
                    <Media tag="li">
                        {/* <Media left href="#">
                            <Avatar src={blog.downloadURL} alt={blog.author} 
                            style = {{
                                minHeight: 100,
                                minWidth: 100,
                                marginRight: 8
                            }}/>
                        </Media> */}
                        <Media body>
                            <p className="author-name">Name : {blog.name}</p>
                            <p>Email: {blog.email}</p>
                            <p>Mobile Number: {blog.phone}</p>
                            <p className="resume-link">Resume: <a href={blog.resume} target="_blank">{blog.resume}</a></p>
                            <p className="view-edit">
                                
                                <ColorApproveButton
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() =>  ViewApplications(blog.title,blog.Applications)}
                                >Approve </ColorApproveButton>
                                <ColorRejectButton
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() =>  ViewApplications(blog.title,blog.Applications)}
                                >Reject </ColorRejectButton>
      
                            </p>
                        </Media>
                    </Media>
                </Media>
            )}
            ) : (
                <>
                    {searchResult.length > 0 ? searchResult.map(blog => {

                    return(
       
                        <Media list key={blog.id} className="blog-card">
                            <Media tag="li">
                                {/* <Media left href="#">
                                    <Avatar src={blog.downloadURL} alt={blog.author} 
                                    style = {{
                                        minHeight: 100,
                                        minWidth: 100,
                                        marginRight: 8
                                    }}/>
                                </Media> */}
                                <Media body>
                            
                            <p className="author-name">Name : {blog.name}</p>
                            <p>Email: {blog.email}</p>
                            <p>Mobile Number: {blog.phone}</p>
                            <p className="resume-link">Resume: <a href={blog.resume} target="_blank">{blog.resume}</a></p>
                            
                            <p className="view-edit">
                                
                                <ColorApproveButton
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() =>  ViewApplications(blog.title,blog.Applications)}
                                >Approve </ColorApproveButton>
                                <ColorRejectButton
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() =>  ViewApplications(blog.title,blog.Applications)}
                                >Reject </ColorRejectButton>
      
                            </p>
                        </Media>
                    </Media>
                        </Media>
                  )
                }) : (
                  <>
                  {searchValue !== '' ? (

                  <div className="row pb-3 justify-content-center">
                  <div>
                 Search Not Found!</div>
   
              </div>
                  ) : <div></div>
                  }
                
                </>
                )
                }
                </>
            )} 
        </>
            )}     
        </div>
    </Layout>
  );
};

export default Applications;
