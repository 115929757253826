import React, { useEffect } from "react";
import { TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import DrawerWrapper from "../../../common/DrawerWrapper/DrawerWrapper";
import {
  CancelButton,
  StyledCreateButton,
} from "../../../common/Components/ColorButton";
import { OPEN_MODAL_TITLE } from "../constants.js";
import { useFirestore } from "reactfire";
import swal from "sweetalert";

const initialState = {
  title: "",
  location: "",
  jobDesc: "",
  qual: "",
};
function EditCareer({ open, onClose, editCareerDetails = {} }) {
  const db = useFirestore();
  const [career, setCareer] = useState(initialState);
  const [showSpinner, setShowSpinner] = React.useState(false);

  useEffect(() => {
    if (editCareerDetails) {
      setCareer(editCareerDetails);
    }
  }, [editCareerDetails]);

  const onChange = (e) => {
    setCareer({ ...career, [e.target.name]: e.target.value });
  };

  const handleUpdateCareer = async () => {
    if (!career?.id) return;

    setShowSpinner(true);

    await db.collection("Careers").doc(career?.id).update({
      location: career?.location,
      title: career?.title,
      jobDesc: career?.jobDesc,
      qual: career?.qual,
    });

    setShowSpinner(false);
    onClose();
    swal("Success!!!", "Career Updated", "success").then(() => {
      window.location.reload();
    });
  };

  return (
    <DrawerWrapper state={open} onClose={onClose}>
      <Typography variant="h5" className="!font-semibold !mb-7">
        {OPEN_MODAL_TITLE.EDIT_CAREER}
      </Typography>
      <form
        className="flex flex-col gap-6"
        noValidate
        autoComplete="off"
        id="updatedCareerForm"
      >
        <TextField
          label="Career Title*"
          name="title"
          value={career?.title}
          onChange={onChange}
        />
        <TextField
          label="Location Name*"
          name="location"
          value={career?.location}
          onChange={onChange}
        />
        <TextField
          label="Job Description*"
          name="jobDesc"
          value={career?.jobDesc}
          onChange={onChange}
        />
        <TextField
          label="Qualification*"
          name="qual"
          value={career?.qual}
          onChange={onChange}
        />
      </form>
      <div className="fixed bottom-0 mb-14 flex gap-1.5">
        <StyledCreateButton onClick={handleUpdateCareer}>
          Update
        </StyledCreateButton>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </div>
    </DrawerWrapper>
  );
}

export default EditCareer;
