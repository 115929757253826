import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
import { useUser } from "reactfire";
import MenuItem from "@material-ui/core/MenuItem";
// import {setTest} from '../firebaseConfig/firebase';

import "firebase/firestore";
import { useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useHistory } from "react-router";
import { useState } from "react";
import { addCSH } from "../../backend/sendRequestToServer";
import {
  getCitiesList,
  getSalesUserFromRole,
} from "../../backend/getDataFromFirestore.js";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  pos: {
    marginTop: 20,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  label: {
    color: "#000",
  },

  textField: {
    "& .MuiInputBase-input": {
      color: "#000",
    },
  },

  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",

    marginBottom: "10px",
  },
}));

const AddCSH = (props) => {
  // hooks
  const [location, setLocation] = React.useState("");
  const [title, setJobTitle] = React.useState("");
  const [jobDesc, setJobDesc] = React.useState("");
  const [qual, setQual] = React.useState("");

  const [showMobileErr, setMobileErr] = useState("");
  const [showEmailErr, setShowEmailErr] = useState("");
  const [cities, setCities] = useState([]);

  const db = useFirestore();
  let history = useHistory();
  const [showSpinner, setShowSpinner] = React.useState(false);

  // feild empty err hooks
  const [showNameErr, setShowNameErr] = React.useState(false);
  const [showPhoneErr, setShowPhoneErr] = React.useState(false);
  const [showRSHErr, setShowRSHErr] = useState("");

  // const [rshEmail, setRshEmail] = useState("");
  const myRole = localStorage.getItem("role");

  const [rshDocId, setRshDocId] = useState(localStorage.getItem("userDocId"));
  const [rshList, setRshList] = useState([]);

  const { data: firebaseUser } = useUser();

  //priviledges

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: "#fff",
        color: "#000",
      },
    },
  };

  const initialState = {
    name: "",
    email: "",
    phone: "",
    role: "",
    city: "",
    pageName: [
      "Add Sales Executive",
      "View Sales Executive",
      "Deleted Sales Executive",
      "Get Leads From RSH",
      "View Meetings",
      "View Approved Clients",
      "My Client Approvals",
      "Add Meeting",
      "Send lead to NSH",
    ],
  };
  const [employee, setEmployee] = useState(initialState);

  useEffect(() => {
    getCitiesList().then((data) => {
      console.log(data);
      setCities(data);
    });

    if (myRole === "User Manager"|| myRole === "Super Admin") {
      getSalesUserFromRole("Regional Sales Head").then((users) => {
        console.log(users);
        setRshList(users);
        setRshDocId("");
      });
    }
  }, []);

  const { name, email, phone, role, pageName } = employee;

  const handleReset = () => {
    document.getElementById("sales-form").reset();
    setEmployee(initialState);
    if (myRole === "User Manager"|| myRole === "Super Admin") {
      setRshDocId("");
    }
  };

  const onChange = (e) => {
    console.log(e.target.value);
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const AddCSH = async (e) => {
    e.preventDefault();
    if (name == "") {
      setShowNameErr("Please Enter  Name");
    } else if (email == "") {
      setShowEmailErr("Please enter Email");
    } else if (!validateEmail(email)) {
      setShowEmailErr("Enter valid Email");
    } else if (phone == "") {
      setShowPhoneErr("Please Enter Phone");
    } else if (!validatePhone(phone)) {
      setShowPhoneErr("Enter valid Mobile Number");
    } else if (rshDocId === "") {
      setShowRSHErr("Please Choose RSH");
    } else {
      setShowSpinner(true);
      // console.log(name,email,phone,employee.city)
      const status = await addCSH({
        name,
        email,
        phone,
        role: "City Sales Head",
        privileges: pageName,
        rshId: rshDocId,
        city: employee.city,
      });

      if (status === 200) {
        setShowSpinner(false);
        swal("Success!", "Employee Added", "success").then((value) =>
          handleReset()
        );
      } else {
        setShowSpinner(false);
        swal("Failed!", "Failed to create Employee. Try Again!!!", "error");
      }
    }
  };

  // email validation
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  // phone validation
  const validatePhone = (mob) => {
    const re = /^[0-9]{10}$/;
    return re.test(mob);
  };

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const SelectRSH = () => {
    return (
      <TextField
        required
        select
        label="Assign RSH"
        value={rshDocId}
        onChange={(e) => {
          setRshDocId(e.target.value);
          setShowRSHErr("");
        }}
        variant="outlined"
        style={{ marginBottom: "10px" }}
        error={showRSHErr}
        helperText={showRSHErr}
      >
        {rshList.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.email}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Add City Sales Head
                </Typography>
                <form
                  id="sales-form"
                  className={classes.form}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Name*"
                    name="name"
                    variant="outlined"
                    onChange={(e) => {
                      onChange(e);
                      setShowNameErr(false);
                    }}
                    error={showNameErr}
                    helperText={showNameErr}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Email*"
                    name="email"
                    variant="outlined"
                    onChange={(e) => {
                      onChange(e);
                      setShowEmailErr(false);
                    }}
                    error={showEmailErr}
                    helperText={showEmailErr}
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    variant="outlined"
                    aria-label="empty textarea"
                    label="Phone*"
                    name="phone"
                    onChange={(e) => {
                      onChange(e);
                      setShowPhoneErr(false);
                    }}
                    style={{ marginBottom: "10px" }}
                    error={showPhoneErr}
                    helperText={showPhoneErr}
                  />
                  <TextField
                    required
                    fullWidth
                    id="outlined-city"
                    select
                    label="City"
                    name="city"
                    value={employee.city}
                    onChange={(e) => onChange(e)}
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  >
                    {cities.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>

                  {(myRole === "User Manager"|| myRole === "Super Admin") ? <SelectRSH /> : null}
                </form>
              </CardContent>
              <div>
                {showSpinner ? (
                  <div>
                    <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                      <h5>Please DO NOT REFRESH the page...</h5>
                    </span>
                    <CircularProgress
                      style={{
                        color: "#18D26E",
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={AddCSH}
                >
                  Add CSH
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCSH;
