import React, { useEffect, useState } from "react";
import Layout from "../../common/Layout";
import {
  getAssignedLeads,
  getSalesUserFromId,
  getSellerData,
} from "../../backend/getDataFromFirestore";
import { Media } from "reactstrap";
import {
  Avatar,
  Button,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { ColorButton, MarkVisited } from "../../common/Components/ColorButton";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import {
  changeLeadStatus,
  modifyProductToApproved,
  modifyProductToRejected,
  saveProductForApproval,
} from "../../backend/editDataInFirestore";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  deleteLeadWithID,
  deleteProductWithID,
} from "../../backend/deleteDataFromFirestore";
import swal from "sweetalert";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const SellerProductsRequests = () => {
  const seId = localStorage.getItem("userDocId");

  let history = useHistory();

  const [products, setProducts] = useState([]);

  const db = useFirestore();

  let productsRef = db.collection("SellerProducts");

  const { status, data } = useFirestoreCollectionData(productsRef);
  const [filterStatus, setFilterStatus] = useState("");

  useEffect(() => {
    console.log("It rendered");
    if (data) {
      getBasicData(data);
      setSearchResult(searchProductsResult(searchValue, filterStatus));
    }
  }, [data]);

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [selectedLead, setSelectedLead] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const productStatus = [
    {
      value: "Approved",
      label: "Approved",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
    {
      value: "Pending",
      label: "Pending",
    },
    {
      value: "Renewal",
      label: "Renewal",
    },
  ];

  // const [currentLeadStatus, setLeadStatus] = useState(
  //   {
  //     status: "",
  //   }
  // );

  async function getBasicData(data) {
    for (let request of data) {
      const res = await getSellerData(request.id);
      if (res.successfull) {
        request.sellerData = res.seller;
      }
    }
    console.log(data);
    setProducts(data);
  }

  const showProducts = (catalog, isApproved = false) => {
    history.push("/show-seller-products", { catalogData: catalog, isApproved });
  };

  //  Search & filter in textbox
  const searchProductsResult = (name, status) => {
    return products.filter((pros) => {
      console.log(pros);

      if (name && status) {
        console.log(pros);
        return (
          ((pros.name &&
            pros.name.toLowerCase().includes(name.toLowerCase())) ||
            (pros.city &&
              pros.city.toLowerCase().includes(name.toLowerCase()))) &&
          pros.status &&
          pros.status.toLowerCase() === status.toLowerCase()
        );
      }
      if (name) {
        return (
          (pros.name && pros.name.toLowerCase().includes(name.toLowerCase())) ||
          (pros.city && pros.city.toLowerCase().includes(name.toLowerCase()))
        );
      }
      if (status) {
        return (
          pros.status && pros.status.toLowerCase() === status.toLowerCase()
        );
      }
      return true;
    });
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchProductsResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    setSearchResult(searchProductsResult(searchValue, status));
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  // table view
  const LeadsTableView = ({ products }) => {
    return (
      <div className="row align-items-center">
        <Table key="LeadTable">
          <Thead>
            <Tr>
              <Th>Seller Name</Th>
              <Th>Email</Th>
              <Th>Contact</Th>
              <Th>City</Th>
              <Th>State</Th>
              <Th>Status</Th>
              <Th>Show Products</Th>
              <Th>Show Approved Products</Th>
            </Tr>
          </Thead>
          <Tbody>
            {products.map((pros, index) => {
              return (
                <Tr key={index}>
                  <Td>{pros.name}</Td>
                  <Td>{pros.email}</Td>
                  <Td>{pros.sellerData.mobileNo}</Td>
                  <Td>{pros.city}</Td>
                  <Td>{pros.sellerData.indianState}</Td>
                  <Td>{pros.status ?? "Pending"}</Td>
                  <Td>
                    <ColorButton onClick={() => showProducts(pros, false)}>
                      Show Products
                    </ColorButton>
                  </Td>
                  <Td>
                    <ColorButton onClick={() => showProducts(pros, true)}>
                      Show Approved Products
                    </ColorButton>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
    );
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h3 style={{ textAlign: "center" }}>View Seller Products Requests</h3>

        <div className="row pb-3 justify-content-center align-items-center">
          <div className="col-10 col-md-10">
            <input
              type="text"
              className="search-box"
              placeholder="Search by Name and City"
              onChange={(e) => {
                search(e);
              }}
              style={{ width: "275px", height: "40px" }}
            />
          </div>
          <div className="col col-md-2 mt-2 float-right">
            <TextField
              required
              id="outlined-indian-state"
              select
              label="Status"
              // value={employee.indianState}
              // onClick={AssignDrop()}
              value={filterStatus}
              onChange={(e) => onFilter(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                classes: {
                  root: classes.selectRoot,
                  select: classes.select,
                },
              }}
              style={{ width: "11ch", float: "right", verticalAlign: "center" }}
            >
              {productStatus.map((val, index) => (
                <MenuItem key={val.value} value={val.value}>
                  {val.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>

        {products.length === 0 ? (
          <div className="row justify-content-center">
            <p>No request's yet!</p>
          </div>
        ) : (
          <>
            {products.length > 0 &&
            filterStatus === "" &&
            searchValue === "" ? (
              <LeadsTableView products={products} />
            ) : (
              <>
                {searchResult.length > 0 ? (
                  <LeadsTableView products={searchResult} />
                ) : (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default SellerProductsRequests;
