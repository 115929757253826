import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { getToken, logOutUser } from "../../backend/authenticateUser";
import Layout from "../../common/Layout";
import AddFranchise from "../AddFranchise/AddFranchise";
import AddSeller from "../AddSeller/AddSeller";
import AddCareer from "../Career/AddCareer";
import AddCaseStudy from "../CaseStudy/AddCaseStudy";
import ViewBlogComponent from "../Home/Components/ViewBlogComponent";
import { Geolocation } from "@capacitor/geolocation";
import { Capacitor } from "@capacitor/core";
import { startTimerForSeLeave } from "../../backend/sendRequestToServer";
import { PRIVILEGES_LOCAL_STORAGE_KEY } from "../../common/storageKey";

const routesData = {
  "View Blog": { path: "/view-blog", component: ViewBlogComponent },
  "Add Career": { path: "/AddCareer", component: AddCareer },
  "Add Case Study and Testimonial": {
    path: "/AddCaseStudy",
    component: AddCaseStudy,
  },
  "Add Franchise": { path: "/add-franchise", component: AddFranchise },
  "Add Seller": { path: "/add-seller", component: AddSeller },
};

const Demo = () => {
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [status, setStatus] = useState(null);

  function saveData(prev) {
    localStorage.setItem(PRIVILEGES_LOCAL_STORAGE_KEY, JSON.stringify(prev));
  }

  function deleteData() {
    localStorage.removeItem("previleges");
  }

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const getLocation = async () => {
    if (Capacitor.isNativePlatform()) {
      const coordinates = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });

      if (!coordinates || !coordinates.coords) {
        console.log("Permission not granted");
        return null;
      }

      console.log("Current position:", coordinates);
      setLat(coordinates.coords.latitude);
      setLng(coordinates.coords.longitude);
      setAccuracy(coordinates.coords.accuracy);
    } else {
      if (!navigator.geolocation) {
        setStatus("Geolocation is not supported by your browser");
      } else {
        setStatus("Locating...");
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setStatus(null);
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
            setAccuracy(position.coords.accuracy);
          },
          (err) => {
            setStatus("Unable to retrieve your location", err);
          },
          options
        );
      }
    }
  };

  const getUserToken = async () => {
    const token = await getToken();
    console.log({ token });
  };

  const callServer = async () => {
    console.log("Call server api");
  };

  return (
    <Layout>
      <div className="mt-5 pt-5">
        <button onClick={getLocation}>Get Location</button>
        <button onClick={getUserToken}>Get Token</button>
        <button onClick={callServer}>Call Server</button>

        <h1>Coordinates</h1>
        <p>{status}</p>
        {lat && <p>Latitude: {lat}</p>}
        {lng && <p>Longitude: {lng}</p>}
        {accuracy && <p>Accuracy: {accuracy}</p>}
      </div>
    </Layout>
  );
};

export default Demo;
