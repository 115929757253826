import { IconButton, makeStyles, Checkbox } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@material-ui/core";

import { useLocation } from "react-router";
import {
  getProductFromId,
  getProductsCategoriesDetails,
  getProductsOfBranch,
  getProductsWithCategoryAndSubCategory,
  getSelectedProductsOfCity,
} from "../../backend/getDataFromFirestore";
import Layout from "../../common/Layout";
// import ProductCard from "./ProductCard";
import { ColorButton } from "../../common/Components/ColorButton";
import { MenuItem, TextField } from "@material-ui/core";
import {
  saveSelectedProducts,
  sendProductRequestToPM,
} from "../../backend/saveDataToFirestore";
import swal from "sweetalert";

import RateContractProvider, {
  RateContractContext,
} from "../../common/RateContractProvider";
import { ProductsTable } from "./ProductsTable";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: "69ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  stepperColor: {
    color: "#18D26E",
  },
  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
  },
  products: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  category: {
    width: "29ch",
    marginRight: "2em",
    paddingBottom: "10px",
  },
  button: {
    margin: "1em",
    width: "20ch",
  },
}));

const ClientProductList = () => {
  const classes = useStyles();

  const location = useLocation();
  let isPAN = location.state.isPAN ?? false;

  let branches = location.state.branches ?? [];

  let clientId = location.state.clientId ?? "";

  console.log(branches);

  const [branch, setBranch] = useState("");
  const [city, setCity] = useState("");

  const [category, setCategory] = useState({});
  const [categoryDetails, setCategoryDetails] = useState({
    category: "",
    subCategory: "",
  });

  const [showProducts, setShowProducts] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState({});

  useEffect(() => {
    getSelectedProducts();
    // setSelectedProducts(newProducts);
  }, []);

  async function getSelectedProducts() {
    let products = {};

    for (let branch of branches) {
      console.log(branch, clientId);
      const res = await getProductsOfBranch(clientId, branch.name);
      products[branch.name] = res;
    }
    console.log(products);
    setSelectedProducts(products);
  }

  async function getCategories() {
    let temp = await getProductsCategoriesDetails();
    console.log("temp : ", temp);
    setCategory(temp);
  }

  // const savePriceOfProduct = (price, id, checked = true) => {
  //   if (checked) {
  //     cities[city][id] = price;
  //     setCities({ ...cities });
  //     // setSelectedProducts({ ...cities[city] });
  //   } else {
  //     delete cities[city][id];
  //     setCities({ ...cities });

  //     // setSelectedProducts({ ...cities[city] });
  //   }
  //   // setSelectedProducts(newProducts);
  //   console.log(cities);
  // };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h3 className="text-center">Product List</h3>

        <form className={classes.form} noValidate autoComplete="off">
          <div>
            <TextField
              required
              id="outlined-branches"
              select
              label="Select Branch"
              variant="outlined"
              className={classes.category}
              onChange={(e) => {
                setBranch(e.target.value.name);
                setCity(e.target.value.city);

                setShowProducts(false);
              }}
            >
              {branches.map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              required
              id="outlined-category"
              select
              label="Type of Category"
              variant="outlined"
              className={classes.category}
              onChange={(e) => {
                setCategoryDetails({
                  ...categoryDetails,
                  category: e.target.value,
                });
                setShowProducts(false);
              }}
            >
              {Object.keys(category).map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              disabled={categoryDetails.category === ""}
              required
              id="outlined-sub-category"
              select
              label="Type of Sub-Category"
              variant="outlined"
              className={classes.category}
              onChange={(e) => {
                setCategoryDetails({
                  ...categoryDetails,
                  subCategory: e.target.value,
                });
                setShowProducts(false);
              }}
            >
              {category[categoryDetails.category] ? (
                category[categoryDetails.category].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value={""}>
                  No Option
                </MenuItem>
              )}
            </TextField>

            <ColorButton
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setShowProducts(true);
              }}
            >
              Get Products
            </ColorButton>
          </div>
        </form>

        {showProducts && branch && (
          <>
            <div className="row align-items-center justify-content-center mt-5">
              <ProductsTable
                isPAN={isPAN}
                branch={branch}
                city={city}
                category={categoryDetails.category}
                subCategory={categoryDetails.subCategory}
                selectedProducts={selectedProducts[branch]}
              />
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default ClientProductList;
