import React from "react";
import Marketing from "../Marketing";
import { ViewMarketingLinkTable } from "./ViewMarketingLinkTable";
import { useGetPermissions } from "context/PermissionsProvider";

function MarketingLinkView() {
  const { userPermissions } = useGetPermissions();

  const canViewMarketingLink = userPermissions?.["Career"]?.View;

  return (
    <Marketing>
      {canViewMarketingLink ? (
        <ViewMarketingLinkTable />
      ) : canViewMarketingLink === false ? (
        <h1>You do not have permission to view marketing Link</h1>
      ) : (
        "Loading..."
      )}
    </Marketing>
  );
}

export default MarketingLinkView;
