import React, { useState } from "react";
import Layout from "../../common/Layout";
import { TabMenu } from "../../common/Components/TabMenu";
import { TAB_NAME } from "../../common/const1";
import { StyledPrimaryButton } from "../../common/Components/ColorButton";
import AddIcon from "@material-ui/icons/Add";
import { OPEN_LEADS_MANAGEMENT_MODAL_TITLE } from "./constants";
import AddMeeting from "Pages/Meetings/AddMeeting";

const LeadsManagementWrapper = ({ children }) => {
  const [addLeadsModal, setShowLeadsModal] = useState(null);

  const onCloseModal = () => {
    setShowLeadsModal(null);
  };

  //Todo: Buttons Feature pending

  return (
    <>
      <AddMeeting
        open={
          OPEN_LEADS_MANAGEMENT_MODAL_TITLE.ADD_NEW_MEETING === addLeadsModal
        }
        onClose={onCloseModal}
      />
      <Layout>
        <div className="mt-5 pt-5 mr-5 ml-5">
          <TabMenu tabName={TAB_NAME.LEADS_MANAGEMENT}>
            <div className="flex gap-2">
              <StyledPrimaryButton
                onClick={() =>
                  setShowLeadsModal(
                    OPEN_LEADS_MANAGEMENT_MODAL_TITLE.SEND_LEADS_TO_NSH
                  )
                }
              >
                SEND LEADS TO NSH
              </StyledPrimaryButton>
              <StyledPrimaryButton
                startIcon={<AddIcon />}
                onClick={() =>
                  setShowLeadsModal(
                    OPEN_LEADS_MANAGEMENT_MODAL_TITLE.ADD_NEW_MEETING
                  )
                }
              >
                ADD NEW MEETING
              </StyledPrimaryButton>
            </div>
          </TabMenu>
          <div className="bg-white max-sm:p-2 shadow-md">{children}</div>
        </div>
      </Layout>
    </>
  );
};

export default LeadsManagementWrapper;
