import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import {setTest} from '../firebaseConfig/firebase';
import "firebase/firestore";
import { useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useLocation, useHistory } from "react-router";
import { getPageName } from "././../../common/const";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const EditCareer = (props) => {
  const location12 = useLocation();
  let selectedCareer = location12.state;
  console.log(selectedCareer);

  // hooks
  const [location, setLocation] = React.useState(selectedCareer.location);
  const [title, setJobTitle] = React.useState(selectedCareer.title);
  const [jobDesc, setJobDesc] = React.useState(selectedCareer.jobDesc);
  const [qual, setQual] = React.useState(selectedCareer.qual);

  // feild empty err hooks
  const [showTitleErr, setShowTitleErr] = React.useState(false);
  const [showDescErr, setShowDescErr] = React.useState(false);
  const [showLocationErr, setShowLocationErr] = React.useState(false);
  const [showQualErr, setShowQualErr] = React.useState(false);

  const db = useFirestore();
  let history = useHistory();

  async function EditCareerFunction() {
    console.log("The link was clicked.");

    // db.collection("Careers")
    //   .doc()
    //   .set({
    //     location: location ,
    //     title: title ,
    //     jobDesc: jobDesc ,
    //     qual: qual
    //     })
    //   .then(() => {
    //     swal("Success!!!", "Career Added", "success");
    //   });

    //     db.collection("Careers")
    //     .add({
    //       location: location ,
    //         title: title ,
    //         jobDesc: jobDesc ,
    //         qual: qual
    //       })
    //    .then(function(docRef) {
    //        console.log("Document written with ID: ", docRef.id);
    //        var washingtonRef = db.collection("Careers").doc(docRef.id);

    // // Set the "capital" field of the city 'DC'
    // return washingtonRef.update({
    //  id: docRef.id
    // })
    // .then(() => {
    //  console.log("Document successfully updated!");
    //  swal("Success!!!", "Career Added", "success");
    // })
    // .then(() => history.push('/view-career'))
    // .catch((error) => {
    //  // The document probably doesn't exist.
    //  console.error("Error updating document: ", error);
    // });

    //    })
    //    .catch(function(error) {
    //        console.error("Error adding document: ", error);
    //    });

    if (title === "") {
      setShowTitleErr(true);
    } else if (jobDesc === "") {
      setShowDescErr(true);
    } else if (qual === "") {
      setShowQualErr(true);
    } else if (location === "") {
      setShowLocationErr(true);
    } else {
      var washingtonRef = db.collection("Careers").doc(selectedCareer.id);

      // Set the "capital" field of the city 'DC'
      return washingtonRef
        .update({
          location: location,
          title: title,
          jobDesc: jobDesc,
          qual: qual,
        })
        .then(() => {
          console.log("Document successfully updated!");
          swal("Success!!!", "Career Updated", "success");
          history.push("/view-career");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }
  }
  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Edit Career
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                  <TextField
                    id="outlined-basic"
                    label="Job Title"
                    variant="outlined"
                    value={title}
                    onChange={(e) => {
                      setJobTitle(e.target.value);
                      setShowTitleErr(false);
                    }}
                    error={showTitleErr}
                    helperText={showTitleErr && "Please Enter job title"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Job Description"
                    variant="outlined"
                    value={jobDesc}
                    onChange={(e) => {
                      setJobDesc(e.target.value);
                      setShowDescErr(false);
                    }}
                    error={showDescErr}
                    helperText={showDescErr && "Please Enter job description"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    rows={4}
                    multiline
                    variant="outlined"
                    aria-label="empty textarea"
                    label="Qualifications Required"
                    value={qual}
                    onChange={(e) => {
                      setQual(e.target.value);
                      setShowQualErr(false);
                    }}
                    style={{ marginBottom: "10px" }}
                    error={showQualErr}
                    helperText={showQualErr && "Please Enter job qualification"}
                  />
                  <br />
                  <TextField
                    id="outlined-basic"
                    label="Location"
                    variant="outlined"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                      setShowLocationErr(false);
                    }}
                    error={showLocationErr}
                    helperText={showLocationErr && "Please Enter job location"}
                    style={{ marginBottom: "10px" }}
                  />
                </form>
              </CardContent>
              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={EditCareerFunction}
                >
                  Update Career
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCareer;
