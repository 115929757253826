import React, { useEffect, useState } from "react";
import CommonTable from "../../../common/Table";
import { ActionCol } from "../../../common/Table/ActionCol";
import AdvancedSearch from "common/AdvancedSearch/AdvancedSearch";
import { useFirestore } from "reactfire";
import { useGetPermissions } from "context/PermissionsProvider";
import EditTestimonial from "../EditTestimonial/EditTestimonial";

const advancedSearchConfig = {
  searchProps: {
    searchTitle: "CompanyName, etc...",
    searchByOptions: ["CompanyName"],
  },
};

export function ViewTestimonialTable() {
  const { userPermissions } = useGetPermissions();
  const db = useFirestore();
  const [testimonials, setTestimonials] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editTestimonialData, setEditTestimonialData] = useState(null);

  const canEditTestimonial = userPermissions?.["Testimonial"]?.Edit;
  const canDeleteTestimonial = userPermissions?.["Testimonial"]?.Delete;

  const fetchTestimonials = async () => {
    const response = db.collection("Testimonials").orderBy("CompanyName");
    const data = await response.get();
    const testimonialsData = [];
    data.docs.forEach((item) => {
      testimonialsData.push(item.data());
    });
    setTestimonials(testimonialsData);
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const handleEdit = (testimonialData) => {
    setEditTestimonialData(testimonialData);
  };

  const handleDelete = async (testimonialData) => {
    if (!testimonialData?.id) return;
    await db.collection("Testimonials").doc(testimonialData?.id).delete();
    window.location.reload();
  };

  const columnsData = [
    {
      headerName: "TestimonialTitle",
      field: "TestimonialTitle",
      width: 210,
    },
    {
      headerName: "Company Name",
      field: "CompanyName",
      width: 180,
    },
    {
      headerName: "Company Position",
      field: "CompanyPosition",
      width: 150,
    },
    {
      headerName: "Description",
      field: "TDesc",
      width: 180,
    },
    {
      headerName: "Testimonial Author",
      field: "TestimonialAuthor",
      width: 210,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 190,
      renderCell: ({ row }) => (
        <ActionCol
          data={row}
          onDelete={canDeleteTestimonial ? handleDelete : null}
          onEdit={canEditTestimonial ? handleEdit : null}
        />
      ),
    },
  ];

  if (!testimonials?.length) {
    return (
      <div className="row justify-content-center">
        <p>No lead's yet!</p>
      </div>
    );
  }

  return (
    <div>
      <EditTestimonial
        open={!!editTestimonialData}
        editTestimonialDetails={editTestimonialData}
        onClose={() => setEditTestimonialData(null)}
      />
      <AdvancedSearch
        data={testimonials}
        searchConfig={advancedSearchConfig}
        setFilteredData={setFilteredData}
      />
      <CommonTable data={filteredData} cols={columnsData} />
    </div>
  );
}
