import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { ColorButton } from "../../../common/Components/ColorButton";
import { saveBranchData, saveBranchDataToFranchise } from "../../../backend/saveDataToFirestore";
import swal from "sweetalert";
import {
  getCityId,
  getClientCityById,
  getNearestFranchiseAndEstore,
  getNewBranchDocId,
} from "../../../backend/getDataFromFirestore";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../../backend/uploadFileToStorage";
import { deletePreviousFile } from "../../../backend/deleteDataFromFirestore";
import { clientModelTypes } from "../../../common/const";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "100px",
  },
}))(TableRow);

const useStyles = makeStyles({
  container: {
    maxHeight: "75vh",
  },
  desDetails: {
    height: 100,
    overflow: "auto",
  },
  duplicate: {
    backgroundColor: "red",
  },
});

const validateBranchFields = (branch) => {
  if (
    branch === {} ||
    branch.name === "" ||
    branch.address === "" ||
    branch.pincode === "" ||
    branch.shippingAddress === "" ||
    branch.gstType === "" ||
    (branch.gstType === "SEZ" && branch.sezLetter === "") ||
    (branch.gstType === "SGST/CGST" && branch.gstNo === "") ||
    (branch.gstType === "IGST" && branch.gstNo === "") ||
    branch.state === "" ||
    branch.city === "" ||
    branch.district === "" ||
    branch.vendor === "" ||
    (branch.gstType === "SGST/CGST" && branch.gstImage === null) ||
    (branch.gstType === "IGST" && branch.gstImage === null) ||
    (branch.gstType === "SEZ" && branch.sezImage === null)
  ) {
    return false;
  }

  return true;
};

const BranchTable = ({
  client_id,
  branchData = [],
  setBranchData,
  clientData,
  clientClusterCitiesData,
  cities,
}) => {
  const classes = useStyles();
  const [upload, setUpload] = useState(false);

  useEffect(() => {
    if (branchData.length > 0) {
      setUpload(true);
    }
  }, [branchData]);

  const validateBranchCity = (branch) => {
    let validCitiesList = [];

    if (clientData.isPAN && clientData.modelType === clientModelTypes.model3) {
      validCitiesList = clientClusterCitiesData;
    } else if (
      clientData.isPAN &&
      clientData.modelType === clientModelTypes.model2
    ) {
      validCitiesList = cities;
    } else {
      // for city Level rate contracts
      validCitiesList = clientData.cities;
    }

    // checking if city is valid
    for (let cityData of validCitiesList) {
      if (cityData.value === branch.city) {
        return true;
      }
    }

    return false;
  };

  const uploadData = async () => {
    let isValidBranchData = true;
    let isValidBranchCity = true;

    for (let branch of branchData) {
      if (!validateBranchFields(branch)) {
        isValidBranchData = false;
        break;
      }
      if (!validateBranchCity(branch)) {
        isValidBranchCity = false;
        break;
      }
    }

    if (!isValidBranchData) {
      swal("Error!", "Please Provide all the Details!", "error");
    } else if (!isValidBranchCity) {
      swal("Error!", "Provided Branch City not available!", "error");
    } else {
      const swalRes = await swal(
        "Are you sure you want to upload the data?",
        "",
        "warning",
        {
          buttons: {
            cancel: "Cancel",
            catch: {
              text: "Save",
              value: true,
            },
          },
        }
      );
      if (!swalRes) {
        return;
      }
      swal(
        "Uploading Data",
        "Please wait it will take some time. Please do not refresh the page",
        "info",
        {
          closeOnClickOutside: false,
          closeOnEsc: false,
          buttons: false,
        }
      );
      console.log("Upload Data");
      try {
        for (const branch of branchData) {
          delete branch.nearestVendor;
          delete branch.nearestVendors;
          await saveDataToFirestore(branch);
        }
        swal.close();
        swal("Success!", "Data Uploaded", "success").then((val) =>
          window.location.reload()
        );
      } catch (e) {
        swal.close();
        swal("Error!", "Something went wrong", "error");
      }
    }
  };

  const handleUpload = async (file, logoRef) => {
    if (typeof file === "string") return { url: file, ref: logoRef };

    if (logoRef) await deletePreviousFile(logoRef);
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    const ref = `/client-documents/${id}`;
    const uploadTask = uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return { url, ref };
  };

  async function saveDataToFirestore(branch) {
    console.log(branch);
    let newBranch = { ...branch };
    let branch_id = newBranch.id;
    if (!newBranch.id) {
      branch_id = await getNewBranchDocId(client_id);
      newBranch["id"] = branch_id;
    }
    let sez = "";
    const gst = await handleUpload(branch.gstImage, branch.gstRef);
    console.log("GST", gst);
    if (branch.sezImage) {
      sez = await handleUpload(branch.sezImage, branch.sezRef);
      newBranch["sezImage"] = sez.url;
      newBranch["sezRef"] = sez.ref ?? "";
    } else {
      delete newBranch.sezImage;
      delete newBranch.sezRef;
      delete newBranch.sezLetter;
    }

    newBranch["gstImage"] = gst.url;
    newBranch["gstRef"] = gst.ref ?? "";
    const cityData = await getCityId(newBranch["city"]);
    newBranch["state"] = cityData.state;
    if (clientData.modelType === clientModelTypes.model3) {
      const clientCityData = await getClientCityById(client_id, cityData.id);
      newBranch["region_id"] = clientCityData.region_id;
      newBranch["cluster_id"] = clientCityData.cluster_id;
      newBranch["city_id"] = clientCityData.id;
      newBranch["cityCode_id"] = clientCityData.cityCode_id;
    }
    await saveBranchDataToFranchise(client_id, newBranch)
    const res = await saveBranchData(client_id, newBranch);
    if (res.successfull) {
      console.log(branch);
      return "Saved";
    } else {
      return "Failed";
    }
  }

  const updateFiles = (e, index) => {
    let data = [...branchData];
    data[index][e.target.name] = e.target.files[0];
    setBranchData(data);
    console.log(branchData);
  };

  async function getNearestVendors(index, branch, isPAN) {
    if (
      branch.name === "" ||
      branch.address === "" ||
      branch.city === "" ||
      branch.state === "" ||
      branch.pincode === ""
    ) {
      console.log("Please Select Name , Address, City, State, Pincode");
      return null;
    }

    const { franchises, shortestFranchise } =
      await getNearestFranchiseAndEstore(
        branch.name,
        branch.address,
        branch.city,
        branch.state,
        branch.pincode,
        isPAN
      );

    // console.log("Nearest vendors are:", franchises, branchLocation);
    let data = [...branchData];
    data[index]["nearestVendors"] = franchises;
    data[index]["nearestVendor"] = shortestFranchise;
    // console.log("Vendor Branch Data: ", data);
    setBranchData(data);
    // console.log("Vendor Branch Data: ", branchData);
  }

  async function updateBranchDetails(name, vendorId, index) {
    let data = [...branchData];
    let newBranch = data[index];
    if (name === "vendor") {
      newBranch[name] = vendorId;
      newBranch["vendorName"] =
        branchData[index].nearestVendors[vendorId]["name"];
      newBranch["vendorType"] =
        branchData[index].nearestVendors[vendorId]["type"];
      newBranch["vendorDist"] =
        branchData[index].nearestVendors[vendorId]["distance"];
    } else {
      newBranch[name] = vendorId;
    }
    data[index] = newBranch;
    setBranchData(data);
  }
  async function uploadNearestVendors() {
    console.log(branchData);
    branchData.forEach(async (branch, index) => {
      await getNearestVendors(index, branch, clientData.isPAN);
      await updateBranchDetails("vendor", branch.nearestVendor.ids, index);
    });
  }

  return (
    <div>
      <br />
      <br />
      <div style={{ paddingBottom: "20px" }}>
        <ColorButton
          disabled={!upload}
          variant="contained"
          color="primary"
          onClick={uploadData}
        >
          Upload Branch
        </ColorButton>
      </div>

      <TableContainer className={classes.container} component={Paper}>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "10em" }}>Branch Name</TableCell>
              <TableCell style={{ width: "10em" }}>Address</TableCell>
              <TableCell style={{ width: "10em" }}>Pincode</TableCell>
              <TableCell style={{ width: "10em" }}>Shipping Address</TableCell>
              <TableCell style={{ width: "10em" }}>GST Type</TableCell>
              <TableCell style={{ width: "10em" }}>SEZ Letter Number</TableCell>
              <TableCell style={{ width: "20em" }}>SEZ File</TableCell>
              <TableCell style={{ width: "10em" }}>GST Number</TableCell>
              <TableCell style={{ width: "20em" }}>GST File</TableCell>
              <TableCell style={{ width: "10em" }}>State</TableCell>
              <TableCell style={{ width: "10em" }}>City</TableCell>
              <TableCell style={{ width: "10em" }}>District</TableCell>
              <TableCell style={{ width: "10em" }}>MailRoom</TableCell>
              <TableCell style={{ width: "20em" }}>
                Nearest Vendor{" "}
                {
                  <ColorButton
                    onClick={() => uploadNearestVendors()}
                    disabled={branchData.length === 0}
                    style={{ marginBottom: "10px" }}
                  >
                    Nearest Vendors
                  </ColorButton>
                }
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {branchData === undefined ? (
              <div></div>
            ) : (
              branchData.map((branch, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    className={branch["duplicate"] ? classes.duplicate : null}
                  >
                    <TableCell component="th" scope="row">
                      {branch.name}
                    </TableCell>
                    <TableCell>{branch.address}</TableCell>
                    <TableCell>{branch.pincode}</TableCell>
                    <TableCell>{branch.shippingAddress}</TableCell>
                    <TableCell>{branch.gstType}</TableCell>
                    <TableCell>{branch.sezLetter}</TableCell>
                    <TableCell>
                      {branchData[index].sezLetter && (
                        <input
                          name="sezImage"
                          id="sez-letter-image"
                          type="file"
                          onChange={(e) => updateFiles(e, index)}
                          style={{ marginBottom: "10px" }}
                        />
                      )}
                      {branchData[index].sezImage ? (
                        typeof branchData[index].sezImage === "string" ? (
                          <ColorButton
                            variant="contained"
                            className={classes.button}
                            onClick={() =>
                              window.open(branchData[index].sezImage, "_blank")
                            }
                            style={{ marginBottom: "10px" }}
                          >
                            View
                          </ColorButton>
                        ) : (
                          <ColorButton
                            variant="contained"
                            className={classes.button}
                            onClick={() =>
                              window.open(
                                URL.createObjectURL(branchData[index].sezImage),
                                "_blank"
                              )
                            }
                            style={{ marginBottom: "10px" }}
                          >
                            View
                          </ColorButton>
                        )
                      ) : null}
                    </TableCell>
                    <TableCell>{branch.gstNo}</TableCell>
                    <TableCell>
                      {branchData[index].gstNo && (
                        <input
                          name="gstImage"
                          id="branch-gst"
                          type="file"
                          onChange={(e) => updateFiles(e, index)}
                          style={{ marginBottom: "10px" }}
                        />
                      )}
                      {branchData[index].gstImage ? (
                        typeof branchData[index].gstImage === "string" ? (
                          <ColorButton
                            variant="contained"
                            className={classes.button}
                            onClick={() =>
                              window.open(branch.gstImage, "_blank")
                            }
                            style={{ marginBottom: "10px" }}
                          >
                            View
                          </ColorButton>
                        ) : (
                          <ColorButton
                            variant="contained"
                            className={classes.button}
                            onClick={() =>
                              window.open(
                                URL.createObjectURL(branch.gstImage),
                                "_blank"
                              )
                            }
                            style={{ marginBottom: "10px" }}
                          >
                            View
                          </ColorButton>
                        )
                      ) : null}
                    </TableCell>
                    <TableCell>{branch.state}</TableCell>
                    <TableCell>{branch.city}</TableCell>
                    <TableCell>{branch.district}</TableCell>
                    <TableCell>{branch.isMailRoom ? "YES" : "NO"}</TableCell>
                    <TableCell>
                      <div>
                        <ColorButton
                          onClick={() => getNearestVendors(index, branch, clientData.isPAN)}
                          style={{ marginBottom: "10px" }}
                        >
                          Get Nearest Vendors
                        </ColorButton>
                        {branchData[index].vendorName ||
                        Object.keys(branchData[index].nearestVendors).length >
                          0 ? (
                          <TextField
                            fullWidth
                            required
                            select
                            id="outlined-branch-vendor-store"
                            label="Branch Vendor Store"
                            variant="outlined"
                            name="vendor"
                            value={branchData[index].vendor}
                            onChange={(e) =>
                              updateBranchDetails(
                                e.target.name,
                                e.target.value,
                                index
                              )
                            }
                            style={{ marginBottom: "10px" }}
                          >
                            {Object.keys(branchData[index]?.nearestVendors).map(
                              (option) => (
                                <MenuItem key={option} value={option}>
                                  {`Name: ${
                                    branchData[index].nearestVendors[option][
                                      "name"
                                    ]
                                  } | Type: ${
                                    branchData[index].nearestVendors[option][
                                      "type"
                                    ]
                                  } | Customers: ${
                                    branchData[index].nearestVendors[option][
                                      "noOfClients"
                                    ]
                                  } | Distance : ${branchData[
                                    index
                                  ].nearestVendors[option]["distance"].toFixed(
                                    2
                                  )} Kms`}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        ) : (
                          ""
                        )}
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BranchTable;
