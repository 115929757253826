import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { ColorButton } from "../../../common/Components/ColorButton";
import {
  franchiseVendorIds,
  getNearestFranchiseAndEstore,
} from "../../../backend/getDataFromFirestore";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "100px",
  },
}))(TableRow);

const useStyles = makeStyles({
  container: {
    maxHeight: "75vh",
  },
  desDetails: {
    height: 100,
    overflow: "auto",
  },
  duplicate: {
    backgroundColor: "red",
  },
});

const DivertBranchTable = ({franchiseData, branchData = [], setBranchData }) => {
  const classes = useStyles();

  async function getNearestVendors(index, branch, isPAN) {
    if (
      branch.name === "" ||
      branch.address === "" ||
      branch.city === "" ||
      branch.state === "" ||
      branch.pincode === ""
    ) {
      alert("Please Select Name , Address, City, State, Pincode");
      return null;
    }

    const { franchises, shortestFranchise } =
      await getNearestFranchiseAndEstore(
        branch.name,
        branch.address,
        branch.city,
        branch.state,
        branch.pincode,
        isPAN,
        { puspose: "forBlocking", vendorsId: await franchiseVendorIds(franchiseData.id) }
      );

    let data = [...branchData];
    data[index]["nearestVendors"] = franchises;
    data[index]["nearestVendor"] = shortestFranchise;
    // console.log("data", data)
    setBranchData(data);
  }

  async function updateBranchDetails(name, vendorId, index) {
    let data = [...branchData];
    let newBranch = data[index];
    if (name === "vendor") {
      newBranch[name] = vendorId;
      newBranch["vendorName"] =
        branchData[index].nearestVendors[vendorId]["name"];
      newBranch["vendorType"] =
        branchData[index].nearestVendors[vendorId]["type"];
      newBranch["vendorDist"] =
        branchData[index].nearestVendors[vendorId]["distance"];
      newBranch["isChangeVendor"] = true;
    } else {
      newBranch[name] = vendorId;
    }
    data[index] = newBranch;
    setBranchData(data);
  }
  async function uploadNearestVendors() {
    console.log(branchData);
    branchData.forEach(async (branch, index) => {
      await getNearestVendors(index, branch, branch.isPAN);
      await updateBranchDetails("vendor", branch.nearestVendor.ids, index);
    });
  }

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Divert Franchise</h3>
      <TableContainer className={classes.container} component={Paper}>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "10em" }}>Branch Name</TableCell>
              <TableCell style={{ width: "10em" }}>Address</TableCell>
              <TableCell style={{ width: "10em" }}>Pincode</TableCell>
              <TableCell style={{ width: "10em" }}>Shipping Address</TableCell>
              <TableCell style={{ width: "10em" }}>GST Type</TableCell>
              <TableCell style={{ width: "10em" }}>GST Number</TableCell>
              <TableCell style={{ width: "10em" }}>State</TableCell>
              <TableCell style={{ width: "10em" }}>City</TableCell>
              <TableCell style={{ width: "10em" }}>District</TableCell>
              <TableCell style={{ width: "10em" }}>MailRoom</TableCell>
              <TableCell style={{ width: "20em" }}>
                Nearest Vendor{" "}
                {
                  <ColorButton
                    onClick={() => uploadNearestVendors()}
                    disabled={branchData.length === 0}
                    style={{ marginBottom: "10px" }}
                  >
                    Nearest Vendors
                  </ColorButton>
                }
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {branchData === undefined ? (
              <div></div>
            ) : (
              branchData.map((branch, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    className={branch["duplicate"] ? classes.duplicate : null}
                  >
                    <TableCell component="th" scope="row">
                      {branch.name}
                    </TableCell>
                    <TableCell>{branch.address}</TableCell>
                    <TableCell>{branch.pincode}</TableCell>
                    <TableCell>{branch.shippingAddress}</TableCell>
                    <TableCell>{branch.gstType}</TableCell>
                    <TableCell>{branch.gstNo}</TableCell>
                    <TableCell>{branch.state}</TableCell>
                    <TableCell>{branch.city}</TableCell>
                    <TableCell>{branch.district}</TableCell>
                    <TableCell>{branch.isMailRoom ? "YES" : "NO"}</TableCell>
                    <TableCell>
                      <div>
                        <ColorButton
                          onClick={() =>
                            getNearestVendors(index, branch, branch.isPan)
                          }
                          style={{ marginBottom: "10px" }}
                        >
                          Get Nearest Vendors
                        </ColorButton>
                        {Object.keys(branchData[index].nearestVendors).length >
                        0 ? (
                          <TextField
                            fullWidth
                            required
                            select
                            id="outlined-branch-vendor-store"
                            label="Branch Vendor Store"
                            variant="outlined"
                            name="vendor"
                            value={branchData[index].vendor}
                            onChange={(e) =>
                              updateBranchDetails(
                                e.target.name,
                                e.target.value,
                                index
                              )
                            }
                            style={{ marginBottom: "10px" }}
                          >
                            {Object.keys(branchData[index]?.nearestVendors).map(
                              (option) => (
                                <MenuItem key={option} value={option}>
                                  {`Name: ${
                                    branchData[index].nearestVendors[option][
                                      "name"
                                    ]
                                  } | Type: ${
                                    branchData[index].nearestVendors[option][
                                      "type"
                                    ]
                                  } | Customers: ${
                                    branchData[index].nearestVendors[option][
                                      "noOfClients"
                                    ]
                                  } | Distance : ${branchData[
                                    index
                                  ].nearestVendors[option]["distance"].toFixed(
                                    2
                                  )} Kms`}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        ) : (
                          ""
                        )}
                      </div>
                    </TableCell>
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DivertBranchTable;
