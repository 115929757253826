import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  desDetails: {
    height: 100,
    overflow: "auto",
  },
});
const ImagesField = ({ index, changeFileNames }) => {
  const classes = useStyles();
  const [fileNames, setFileNames] = useState([]);

  return (
    <div>
      <div className={classes.desDetails}>
        Show Names of files :
        {fileNames.map((file, i) => {
          return <div key={i}>{file.name}</div>;
        })}
      </div>

      <input
        type="file"
        name="productImages"
        accept="image/*"
        multiple
        onChange={(e) => {
          changeFileNames(index, e.target.files);
          setFileNames(Array.from(e.target.files));
        }}
      />
    </div>
  );
};

export default ImagesField;
