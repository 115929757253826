import React, { useEffect, useState } from "react";
import Layout from "../../common/Layout";
import {
  getCitiesList,
  getDataToViewProspects,
  getSalesUserFromId,
} from "../../backend/getDataFromFirestore";
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { ColorButton } from "../../common/Components/ColorButton";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  modifyProspectPANStatus,
  modifyProspectToApproved,
  modifyProspectToRejected,
  saveProspectForApproval,
} from "../../backend/editDataInFirestore";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteProspectWithID } from "../../backend/deleteDataFromFirestore";
import swal from "sweetalert";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { getRoleSF, ProspectStatuses } from "../../common/const";

const ViewProspects = () => {
  let history = useHistory();
  const role = localStorage.getItem("role");
  const roleSF = getRoleSF[role];
  if (!roleSF) {
    window.location.pathname = "/dashboard";
  }
  const seId = localStorage.getItem("userDocId");

  const [prospects, setProspects] = useState([]);

  const db = useFirestore();

  let prospectRef;
  if (roleSF === "sa") {
    prospectRef = db.collection("Prospects");
  } else {
    prospectRef = db.collection("Prospects").where(roleSF, "==", seId);
  }

  const { status, data } = useFirestoreCollectionData(prospectRef);
  const [filterStatus, setFilterStatus] = useState("");

  const [cities, setCities] = useState([]);
  useEffect(() => {
    console.log("It rendered");
    if (data) {
      getBasicData(data);
      setSearchResult(searchProspectResult(searchValue, filterStatus));
    }
  }, [data]);
  useEffect(() => {
    getCities();
  }, []);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [selectedLead, setSelectedLead] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const prospectStatusOptions = [
    {
      value: ProspectStatuses.APPROVED,
      label: "Approved",
    },
    {
      value: ProspectStatuses.REJECTED,
      label: "Rejected",
    },
    {
      value: ProspectStatuses.PENDING,
      label: "Pending",
    },
    {
      value: ProspectStatuses.NOT_SENT,
      label: "Not Sent",
    },
  ];

  // const [currentLeadStatus, setLeadStatus] = useState(
  //   {
  //     status: "",
  //   }
  // );
  async function getCities() {
    const temp = await getCitiesList();
    setCities(temp);
  }
  async function getBasicData(data) {
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      const res = await getDataToViewProspects(data[i]);
      if (res) {
        temp.push({ ...data[i], ...res });
      }
    }
    setProspects(temp);
  }

  const handleClose = () => {
    setOpen(false);
  };

  //  Search & filter in textbox
  const searchProspectResult = (name, status) => {
    return prospects.filter((pros) => {
      if (name && status) {
        console.log(pros);
        return (
          (pros.name &&
            pros.name.toLowerCase().includes(name.toLowerCase()) &&
            pros.status &&
            pros.status.toLowerCase() === status.toLowerCase()) ||
          (pros.city &&
            pros.city.toLowerCase().includes(name.toLowerCase()) &&
            pros.status &&
            pros.status.toLowerCase() === status.toLowerCase())
        );
      }
      if (name) {
        console.log("pros", pros);
        return (
          (pros.name && pros.name.toLowerCase().includes(name.toLowerCase())) ||
          (pros.city && pros.city.toLowerCase().includes(name.toLowerCase()))
        );
      }
      if (status) {
        return (
          pros.status && pros.status.toLowerCase() === status.toLowerCase()
        );
      }
      return true;
    });
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchProspectResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    setSearchResult(searchProspectResult(searchValue, status));
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    bar: {
      transition: "none",
    },
  }));
  const classes = useStyles();

  const viewProspectDetails = (lead_details) => {
    console.log("view details button pressed", JSON.stringify(lead_details));
    setOpen(true);
    setSelectedLead(lead_details);
    // history.push("/view-lead-details", lead_details);
  };

  // const addClient = (lead_details) => {
  //   console.log("Add Client pressed", {
  //     nsh: lead_details.nsh,
  //     rsh: lead_details.rsh,
  //     csh: lead_details.csh,
  //     se: lead_details.se,
  //   });
  //   history.push("/add-client", {
  //     nsh: lead_details.nsh,
  //     rsh: lead_details.rsh,
  //     csh: lead_details.csh,
  //     se: lead_details.se,
  //     leadId: lead_details.id,
  //     approved: false,
  //   });
  // };

  // const [status, setStatus] = useState("");
  function editProspect(pros) {
    history.push("/add-prospect", { leadId: pros.leadId, pageType: "edit" });
  }

  function deleteProspect(pros) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Prospect!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: "Deleting the Prospect. Do not Refresh",
          icon: "warning",
          buttons: false,
          dangerMode: true,
        });

        const res = await deleteProspectWithID(pros.id);

        if (res.successfull) {
          swal("Success", "Prospect Successfully Deleted!!!", "success");
        } else {
          swal(
            "Error",
            "Error Occur while Deleting. Please Try Again.",
            "error"
          );
        }
      }
    });
  }

  function checkFields(pros) {
    if (
      pros.leadId === "" ||
      pros.name === "" ||
      pros.logo === null ||
      pros.logoRef === "" ||
      pros.modelType === "" ||
      pros.website === "" ||
      pros.corporateName === "" ||
      pros.corporateAddress === "" ||
      pros.corporatePincode === "" ||
      pros.corporateGstType === "" ||
      pros.corporateGstNo === "" ||
      pros.corporateState === "" ||
      pros.registeredName === "" ||
      pros.registeredAddress === "" ||
      pros.registeredPincode === "" ||
      pros.registeredGstType === "" ||
      pros.registeredGstNo === "" ||
      pros.registeredState === "" ||
      pros.billingCity === "" ||
      pros.billingState === "" ||
      pros.costCenterEmail === "" ||
      !pros.cities ||
      pros.cities?.length === 0 ||
      pros.panNo === ""
    ) {
      return false;
    }
    return true;
  }

  async function generateRateContract(pros) {
    // if (!checkFields(pros)) {
    //   swal("Error!", "Please make sure all fields are filled ", "error");
    //   return;
    // }
    const swalRes = await swal(
      "Are you sure you want to generate Rate Contact",
      "Please make sure all prices are correct. You will not be able to change prices again.",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    history.push({
      pathname: "/generate-rate-contract",
      state: {
        isPAN: pros.isPAN ?? false,
        branchCities: pros.cities,
        selectedProducts: pros.selectedProducts ?? {},
        selectedProductsData: pros.selectedProductsData ?? {},
        prospectId: pros.id,
        prospectName: pros.name,
        noOfQuotation: pros.noOfQuotation ? pros.noOfQuotation + 1 : 1,
      },
    });
  }

  async function viewRateContract(pros) {
    history.push({
      pathname: "/view-rate-contract",
      state: {
        isPAN: pros.isPAN ?? false,
        prospectId: pros.id,
        branchCities: pros.cities,
        selectedProducts: pros.selectedProducts ?? {},
        quotationProducts: pros.quotationProducts ?? {},
        noOfQuotation: pros.noOfQuotation ? pros.noOfQuotation + 1 : 1,
      },
    });
  }

  async function viewQuotation(pros) {
    history.push({
      pathname: "/view-versions",
      state: {
        isPAN: pros.isPAN ?? false,
        prospectId: pros.id,
        branchCities: pros.cities,
        selectedProducts: pros.selectedProducts ?? {},
        quotationProducts: pros.quotationProducts ?? {},
        noOfQuotation: pros.noOfQuotation ? pros.noOfQuotation + 1 : 1,
      },
    });
  }

  function checkIfProductsSaved(pros) {
    if (
      pros.isPAN &&
      pros.selectedProducts &&
      Object.keys(pros.selectedProducts["PAN-Products"]).length > 0
    ) {
      return true;
    } else if (pros.selectedProducts && pros.cities) {
      for (let city_id of pros.cities) {
        let city_name = "";
        for (let city of cities) {
          if (city.id === city_id.id) {
            city_name = city.value;
            break;
          }
        }
        if (
          !(
            pros.selectedProducts[city_name] &&
            Object.keys(pros.selectedProducts[city_name]).length > 0
          )
        ) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  async function sendProspectForApproval(pros) {
    if (!checkFields(pros)) {
      swal("Error!", "Please make sure all fields are filled ", "error");
      return;
    } else if (!checkIfProductsSaved(pros)) {
      swal(
        "Error!",
        "Please make sure you have generated correct rate contract",
        "error"
      );
      return;
    } else {
      const swalRes = await swal(
        "Are you sure you want to Send Prospect For Approval",
        "Please make sure prospect information is correct. You will not be able to change it again.",
        "warning",
        {
          buttons: {
            cancel: "Cancel",
            catch: {
              text: "Save",
              value: true,
            },
          },
        }
      );
      if (!swalRes) {
        return;
      }
      const res = await saveProspectForApproval(pros);
      console.log(res);
      if (res.successfull) {
        swal("Success", "Prospect Saved", "success");
      } else {
        swal("Error", "Error in saving Prospect", "error");
      }
    }
  }

  async function approveProspect(pros) {
    const swalRes = await swal(
      "Approve Prospect",
      "Are you sure you want to Approve this Prospect",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }

    swal(
      "Approving Prospect",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );

    const res = await modifyProspectToApproved(pros);
    console.log(res);
    swal.close();

    if (res.successfull) {
      swal("Success", "Prospect Approved", "success");
    } else {
      swal("Error", "Error in approving Prospect", "error");
    }
  }

  async function rejectProspect(pros) {
    const swalRes = await swal(
      "Reject Prospect",
      "Are you sure you want to Reject this Prospect",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    const res = await modifyProspectToRejected(pros);
    console.log(res);
    if (res.successfull) {
      swal("Success", "Prospect Rejected", "success");
    } else {
      swal("Error", "Error in rejecting Prospect", "error");
    }
  }

  async function changeIsPANStatus(product, checked) {
    const swalRes = await swal(
      "Change Rate Contract Status",
      "Are you sure you want to change status",
      "warning",
      {
        buttons: {
          cancel: "No",
          catch: {
            text: "Yes",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    const res = await modifyProspectPANStatus(product, checked);
    console.log(res);
    if (res.successfull) {
      swal("Success", "Prospect PAN Status Changed", "success");
    } else {
      swal("Error", "Error changing Prospect PAN status", "error");
    }
  }
  // table view
  const LeadsTableView = ({ prospects }) => {
    return (
      <div className="row align-items-center">
        <Table key="LeadTable">
          <Thead>
            <Tr>
              <Th>Company Logo</Th>
              <Th>Company Name</Th>
              <Th>Model Type</Th>
              <Th>City</Th>
              <Th>State</Th>
              <Th>Status</Th>

              <Th>Action</Th>
              <Th>PAN Level</Th>
              <Th>Rate Contract</Th>
              {roleSF === "se" && <Th>Send For Approval</Th>}
              {roleSF === "sa" && <Th>Approve/Reject</Th>}

              {/* <Th>Details</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {prospects.map((pros, index) => {
              if (
                roleSF === "se" ||
                (roleSF !== "se" && pros.status && pros.status !== "Not Sent")
              )
                return (
                  <Tr key={index}>
                    <Td>
                      <Avatar alt="logo" width="40%" src={pros.logo} />
                    </Td>
                    <Td>{pros.name}</Td>
                    <Td>{pros.modelType}</Td>
                    <Td>{pros.city}</Td>
                    <Td>{pros.state}</Td>
                    <Td>{pros.status ?? "Not Sent"}</Td>

                    <Td>
                      <span>
                        <IconButton
                          aria-label="delete"
                          onClick={(e) => deleteProspect(pros)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          id={pros.id}
                          onClick={(e) => editProspect(pros)}
                        >
                          <CreateIcon />
                        </IconButton>
                      </span>

                      <ColorButton
                        variant="contained"
                        className={classes.button}
                        onClick={() => viewProspectDetails(pros)}
                      >
                        View Details
                      </ColorButton>
                    </Td>
                    <Td>
                      <Checkbox
                        disabled={pros.status !== "Not Sent"}
                        name="checkedC"
                        checked={pros.isPAN ?? false}
                        onChange={(e) => {
                          changeIsPANStatus(pros, e.target.checked);
                        }}
                      />
                    </Td>
                    <Td>
                      <ColorButton
                        variant="contained"
                        className={classes.button}
                        disabled={pros.noOfQuotation === undefined || pros.noOfQuotation === 0}
                        onClick={() => viewQuotation(pros)}
                      >
                        Version
                      </ColorButton>
                      <ColorButton
                        variant="contained"
                        className={classes.button}
                        onClick={() =>
                          pros.status === "Not Sent"
                            ? generateRateContract(pros)
                            : viewRateContract(pros)
                        }
                      >
                        {pros.status === "Not Sent" ? "Generate" : "View"}
                      </ColorButton>
                    </Td>
                    {roleSF === "se" && (
                      <Td>
                        <ColorButton
                          disabled={pros.status !== "Not Sent"}
                          variant="contained"
                          className={classes.button}
                          onClick={() => sendProspectForApproval(pros)}
                        >
                          Send
                        </ColorButton>
                      </Td>
                    )}

                    {roleSF === "sa" && (
                      <Td>
                        <IconButton
                          disabled={
                            pros.status === "Approved" ||
                            pros.status === "Rejected"
                          }
                          aria-label="approve"
                          onClick={(e) => approveProspect(pros)}
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          disabled={
                            pros.status === "Approved" ||
                            pros.status === "Rejected"
                          }
                          aria-label="reject"
                          onClick={(e) => rejectProspect(pros)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Td>
                    )}
                  </Tr>
                );
            })}
          </Tbody>
        </Table>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"View Prospect Details"}
          </DialogTitle>
          <DialogContent>
            <LeadCard lead={selectedLead} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h3 style={{ textAlign: "center" }}>View Prospects</h3>

        <div className="row pb-3 justify-content-center align-items-center">
          <div className="col-10 col-md-10">
            <input
              type="text"
              className="search-box"
              placeholder="Search Lead"
              onChange={(e) => {
                search(e);
              }}
              style={{ width: "275px", height: "40px" }}
            />
          </div>
          <div className="col col-md-2 mt-2 float-right">
            <TextField
              required
              id="outlined-indian-state"
              select
              label="Status"
              // value={employee.indianState}
              // onClick={AssignDrop()}
              value={filterStatus}
              onChange={(e) => onFilter(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                classes: {
                  root: classes.selectRoot,
                  select: classes.select,
                },
              }}
              style={{ width: "11ch", float: "right", verticalAlign: "center" }}
            >
              {prospectStatusOptions.map((val, index) => (
                <MenuItem key={val.value} value={val.value}>
                  {val.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>

        {prospects.length === 0 ? (
          <div className="row justify-content-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <CircularProgress
              variant="determinate"
              classes={{ bar: classes.bar }}
            />
            {prospects.length > 0 &&
            filterStatus === "" &&
            searchValue === "" ? (
              <LeadsTableView prospects={prospects} />
            ) : (
              <>
                {searchResult.length > 0 ? (
                  <LeadsTableView prospects={searchResult} />
                ) : (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ViewProspects;

const LeadCard = ({ lead }) => {
  const [emails, setEmails] = useState({
    nsh: "",
    rsh: "",
    csh: "",
    se: "",
  });

  const [checkMail, setCheckMail] = useState(false);

  async function getEmails() {
    let nshData = await getSalesUserFromId(lead.nsh);
    let rshData = await getSalesUserFromId(lead.rsh);
    let cshData = await getSalesUserFromId(lead.csh);
    let seData = await getSalesUserFromId(lead.se);

    setEmails({
      nsh: nshData ? nshData.email : "",
      rsh: rshData ? rshData.email : "",
      csh: cshData ? cshData.email : "",
      se: seData ? seData.email : "",
    });

    setCheckMail(true);
  }
  const myEmail = localStorage.getItem("email");
  return (
    <div key={lead.id} style={{ width: "275px", padding: "2px" }}>
      <p className="author-name">Company Name:{lead.name}</p>
      <p>Email: {lead.superAdminEmailId}</p>
      {/* <p>Phone: {lead.mobileno}</p> */}
      <p>Status: {lead.status ?? "Not Sent"}</p>
      <p>Website : {lead.website}</p>
      <p>Corporate Address: {lead.corporateAddress}</p>
      <p>Corporate State: {lead.corporateState}</p>
      <p>Corporate Pincode: {lead.corporatePincode}</p>

      <p>Registered Address: {lead.registeredAddress}</p>
      <p>Registered State: {lead.registeredState}</p>
      <p>Registered Pincode: {lead.registeredPincode}</p>
      <p>Billing City : {lead.billingCity}</p>
      <p>Billing State : {lead.billingState}</p>
      <p>Billing Cost Center Email : {lead.costCenterEmail}</p>
      <p>Pan Number : {lead.panNo}</p>

      {/* <p>
        Contact Person:{" "}
        {lead.contactPerson === undefined ? "" : lead.contactPerson}
      </p>
      <p>
        Department Name:{" "}
        {lead.departmentName === undefined ? "" : lead.departmentName}
      </p> */}

      {!checkMail ? (
        <ColorButton onClick={getEmails}>Show Lead Refrences</ColorButton>
      ) : (
        <span>
          <p>NSH: {emails.nsh}</p>
          <p>RSH: {emails.rsh}</p>
          <p>CSH: {emails.csh}</p>
          <p>SE: {emails.se}</p>
        </span>
      )}

      <br />
    </div>
  );
};
