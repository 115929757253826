import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { sidebarLinks, tabMenuLinks } from "../const1";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

// tabs = [{ title: 'Leads',url: ''}, { title: 'Leads', Icon: '',url: ''}]
function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //   backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTab-wrapper": {
      display: "-webkit-box",
    },
    "& .MuiTab-labelIcon": {
      minHeight: "0px",
    },
    "& .MuiTab-root": {
      minWidth: 0,
      maxWidth: 300,
      marginRight: "11px",
    },
    "& .MuiTypography-body1": {
      fontFamily: "Roboto, Poppins",
      fontWeight: 600,
    },
  },
  mobilepageHeader: {
    fontWeight: 600,
    color: "#909090",
    marginBottom: "25px",
    marginLeft: "15px",
  },
  pageHeader: {
    fontWeight: 600,
    color: "#909090",
    marginBottom: "30px",
  },
}));

export function TabMenu({ children, tabName }) {
  const tabValuesArray = sidebarLinks[tabName] || [];
  const classes = useStyles();
  const [value, setValue] = React.useState("");
  const [isMobileView, setIsMobileView] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setIsMobileView(true)
        : setIsMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  useEffect(() => {
    setValue(history.location.pathname);
  }, []);

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
    history.push(newValue);
  };
  return (
    <div className={classes.root}>
      <div className="flex justify-between">
        <Typography
          variant={isMobileView ? "h5" : "h4"}
          className={
            isMobileView ? classes.mobilepageHeader : classes.pageHeader
          }
        >
          {tabName}
        </Typography>
        <div>{children}</div>
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="nav tabs example"
        textColor="primary"
        indicatorColor="primary"
        className={classes.tabs}
        variant="scrollable"
      >
        {tabValuesArray?.map((tab, index) => (
          <Tab
            onClick={(event) => {
              event.preventDefault();
            }}
            textColor="primary"
            label={<Typography>{tab}</Typography>}
            icon={tabMenuLinks[tab]?.icon}
            value={tabMenuLinks[tab]?.url}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </div>
  );
}
