import { useContext } from "react";
import swal from "sweetalert";
import { getHistoryData } from "../../../backend/getDataFromFirestore";
import {
  saveSelectedProducts,
  sendProductRequestToPM,
} from "../../../backend/saveDataToFirestore";
import { ColorButton } from "../../../common/Components/ColorButton";
import { RateContractContext } from "../../../common/RateContractProvider";

const SaveSelectedButton = ({
  prospectDetails,
  buttonLocation,
  selectedVersion,
  selectedCity,
  editProductsStatus,
  isPAN
}) => {
  const { prospectId } = prospectDetails;
  const { selectedProducts, selectedProductsData, selectedSendRqstToPM } =
    useContext(RateContractContext);

  //TODO: Save products w.r.t Cities
  async function saveProducts() {
    for (let checkMRP of Object.keys(selectedProductsData)) {
      if (
        parseFloat(selectedProductsData[checkMRP].Rate) +
          parseFloat(selectedProductsData[checkMRP].Rate) *
            (selectedProductsData[checkMRP].GST / 100) >
        parseFloat(selectedProductsData[checkMRP].MRP)
      ) {
        swal(
          "Error",
          "Inclusive GST Rate should not be more than MRP",
          "error"
        );
        return;
      } else {
        continue;
      }
    }

    const swalRes = await swal(
      "Save Rate Contract",
      "Are you sure you have selected all the client's requested products for the rate contract ? ",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    for (let city of Object.keys(selectedProducts)) {
      if (
        selectedProducts[city] &&
        Object.keys(selectedProducts[city]).length > 0
      ) {
        continue;
      } else {
        swal(
          "Error",
          "Please select atleast one product for each city.",
          "error"
        );

        return;
      }
    }
    const res = await saveSelectedProducts(prospectId, selectedProducts, isPAN, selectedProductsData);
    if (res.successfull) {
      const seId = localStorage.getItem("userDocId");
      const role = localStorage.getItem("role");
      const seEmail = localStorage.getItem("email");
      const seName = localStorage.getItem("name");

      for (let cities of Object.keys(selectedSendRqstToPM)) {
        for (let productId of Object.keys(selectedSendRqstToPM[cities])) {
          //
          if (productId) {
            const nexstocPrice = selectedProducts[cities][productId];
            if (!!nexstocPrice) {
              const product = selectedSendRqstToPM[cities][productId];
              const history = {
                price: product.prospectPrice,
                role: role,
                date: new Date(),
              };
              let priceHistory = [];
              priceHistory = await getHistoryData(
                seId,
                product,
                prospectDetails.prospectId
              );
              priceHistory.push(history);
              await sendProductRequestToPM(
                product,
                cities,
                seId,
                seEmail,
                seName,
                prospectDetails.prospectId,
                prospectDetails.prospectName,
                product.prospectPrice,
                priceHistory
              );
            }
          }
          //
        }
      }
      swal("Success", "Product Prices Saved", "success").then((val) =>
        window.history.back()
      );
    } else {
      swal("Error", "Error in saving Product Prices", "error");
    }
  }

  return (
    <ColorButton
      variant="contained"
      color="primary"
      style={{ marginLeft: "10px" }}
      disabled={
        buttonLocation === "View Quotation" &&
        (editProductsStatus === false || !selectedVersion || !selectedCity)
      }
      onClick={(e) => {
        e.preventDefault();
        saveProducts();
      }}
    >
      Save Products
    </ColorButton>
  );
};
export default SaveSelectedButton;
