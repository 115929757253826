import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Media } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import { ColorButton } from "../../common/Components/ColorButton";
import {
  getClusterCitiesData,
  getCitiesWithId,
} from "../../backend/getDataFromFirestore";
import AddClusterCitiesDialog from "./AddClusterCitiesDialog";
import { DialogHelper } from "../../common/Components/DialogHelper";
import { deleteClusterCity } from "../../backend/deleteDataFromFirestore";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const initialCityData = {
  name: "",
};

const AddClusterCities = () => {
  const classes = useStyles();
  const location = useLocation();
  const { client_id, region_id, cluster_id } = location.state;

  const [cityData, setCityData] = useState(initialCityData);
  const [isUpdate, setUpdate] = useState(false);
  const { dialogInfo, openDialog, closeDialog } = DialogHelper();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    getClusterCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getClusterCities() {
    const clientCities = await getClusterCitiesData(client_id, cluster_id);
    const allCities = await getCitiesWithId();

    const cityDataToRender = clientCities.map(({ id, cityCode_id }) => ({
      ...allCities[cityCode_id], // single city data
      id, // id of the cities doc from client -> city
    }));
    setCities(cityDataToRender);
  }

  async function deleteCity(client_id, city_id) {
    const toDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this City!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!toDelete) {
      return;
    }

    swal("Deleting City", "Please do not refresh this page", "info", {
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: false,
    });

    const res = await deleteClusterCity(client_id, city_id);

    swal.close();

    if (res.successfull) {
      swal("Success!", "City deleted", "success");
      getClusterCities();
    } else {
      swal("Failed!", "Failed to delete City. Try Again!!!", "error");
    }
  }

  function editCity(city) {
    setUpdate(true);
    setCityData({ id: city.id, name: city.city });
    openDialog();
  }

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>Cities</h2>
        <div row align-items-center justify-content-center>
          <form className={classes.form} noValidate autoComplete="off">
            <AddClusterCitiesDialog
              initialCityData={initialCityData}
              isUpdate={isUpdate}
              setUpdate={setUpdate}
              cityData={cityData}
              setCityData={setCityData}
              dialogInfo={dialogInfo}
              classes={classes}
              closeDialog={closeDialog}
              client_id={client_id}
              region_id={region_id}
              cluster_id={cluster_id}
              getClusterCities={getClusterCities}
              style={{ marginBottom: "10px" }}
            />
            {cities.map((city) => {
              return (
                <Media
                  list
                  key={`${city.id}-${city.city}`}
                  className="blog-card"
                >
                  <Media tag="li">
                    <Media body>
                      <Media heading>{city.city}</Media>
                      <p className="view-edit">
                        <IconButton
                          aria-label="delete"
                          onClick={() => deleteCity(client_id, city.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          onClick={() => editCity(city)}
                        >
                          <CreateIcon />
                        </IconButton>
                      </p>
                    </Media>
                  </Media>
                </Media>
              );
            })}

            <ColorButton fullWidth="max" onClick={() => openDialog()}>
              Add New City
            </ColorButton>

            <br />
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AddClusterCities;
