import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { ColorButton } from "./ColorButton";
import { gstTypes, clientModelTypes } from "../const";
import {
  getNearestFranchiseAndEstore,
  getNewBranchDocId,
  getClientCityById,
  getClientById,
  getCityId,
  getCitiesList,
} from "../../backend/getDataFromFirestore";
import swal from "sweetalert";
import { deletePreviousFile } from "../../backend/deleteDataFromFirestore";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../backend/uploadFileToStorage";
import { saveBranchData } from "../../backend/saveDataToFirestore";

const BranchDialog = ({
  dialogInfo,
  classes,
  closeDialog,
  client_id,
  data,
  citiesData,
  clientData,
  clientClusterCitiesData,
}) => {
  const [branchData, setBranchData] = useState({
    id: "",
    name: "",
    address: "",
    pincode: "",
    shippingAddress: "",
    sezLetter: "",
    sezImage: null,
    sezRef: "",
    gstType: "",
    gstNo: "",
    gstImage: null,
    gstRef: "",
    state: "",
    city: "",
    district: "",
    isMailRoom: false,
    vendor: "",
    vendorName: "",
    vendorType: "",
    vendorDist: 0,
    lat: 0,
    lng: 0,
  });
  const [nearVendor, setNearVendor] = useState({});
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setBranchData(data);
  }, [data]);

  useEffect(() => {
    setCities(citiesData);
  }, [cities]);

  useEffect(() => {
    // for model3 - any type of rate contract
    if (clientData.modelType === clientModelTypes.model3) {
      setCities(clientClusterCitiesData);
      return;
    }

    // for model2 PAN India level rate contract
    if (clientData.isPAN && clientData.modelType === clientModelTypes.model2) {
      setCities(citiesData);
      return;
    }

    // for model2 City level rate contract
    if (!clientData.isPAN && clientData.modelType === clientModelTypes.model2) {
      setCities(clientData.cities);
      return;
    }
// for model1 City level rate contract
    if (clientData.modelType === clientModelTypes.model1) {
      setCities(clientClusterCitiesData);
      return;
    }
  }, [dialogInfo]);

  useEffect(() => {
    if (
      dialogInfo.type === "edit" &&
      Object.keys(nearVendor).length === 0 // making sure this doesnt happen every time any field is changed
    ) {
      getNearestVendors(branchData);
    }
  }, [branchData]);

  async function getNearestVendors(data) {
    if (
      data.name === "" ||
      data.address === "" ||
      data.city === "" ||
      data.state === "" ||
      data.pincode === ""
    ) {
      console.log("Please Select Name , Address, City, State, Pincode");
      return null;
    }

    const { franchises, branchLocation } = await getNearestFranchiseAndEstore(
      data.name,
      data.address,
      data.city,
      data.state,
      data.pincode
    );

    console.log("Nearest vendors are:", franchises, branchLocation);
    setNearVendor(franchises);
    setBranchData({ ...branchData, ...branchLocation });
  }

  function updateBranchDetails(e) {
    let newBranch = { ...branchData };
    if (e.target.name === "isMailRoom") {
      newBranch[e.target.name] = e.target.checked;
    } else if (e.target.name === "gstType") {
      newBranch[e.target.name] = e.target.value;
      newBranch["sezLetter"] = "";
      newBranch["sezImage"] = null;
    } else if (e.target.name === "vendor") {
      newBranch[e.target.name] = e.target.value;
      newBranch["vendorName"] = nearVendor[e.target.value]["name"];
      newBranch["vendorType"] = nearVendor[e.target.value]["type"];
      newBranch["vendorDist"] = nearVendor[e.target.value]["distance"];
    } else if (e.target.name === "city") {
      const filterCity = citiesData.filter(
        (city) => city.value === e.target.value
      );
      newBranch[e.target.name] = e.target.value;
      newBranch["state"] = filterCity[0].state;
    } else {
      newBranch[e.target.name] = e.target.value;
    }
    setBranchData(newBranch);
  }

  function validateBranchFields(branchData) {
    if (
      branchData === {} ||
      branchData.name === "" ||
      branchData.address === "" ||
      branchData.pincode === "" ||
      branchData.shippingAddress === "" ||
      branchData.gstType === "" ||
      (branchData.gstType === "SEZ" && branchData.sezLetter === "") ||
      branchData.gstNo === "" ||
      branchData.state === "" ||
      branchData.state === "" ||
      branchData.city === "" ||
      branchData.district === "" ||
      branchData.vendor === "" ||
      branchData.gstImage === null ||
      (branchData.gstType === "SEZ" && branchData.sezImage === null)
    )
      return false;

    return true;
  }

  //Update Documents
  const updateFiles = (e) => {
    let newBranch = { ...branchData };
    newBranch[e.target.name] = e.target.files[0];
    setBranchData(newBranch);
  };

  const handleClose = () => {
    resetAndCloseDialog();
  };

  async function handleSubmit() {
    const res = await saveDataToFirestore();
    if (res === "Saved") {
      if (dialogInfo.type === "edit") {
        swal("Success!", "Branch Data Edited", "success").then((val) => {
          window.location.pathname = "/view-branch";
        });
      } else {
        swal("Success!", "Branch Data Saved", "success").then((val) => {
          window.location.pathname = "/add-branch";
        });
      }
    } else {
      swal("Error!", "Error while saving Branch Data ", "error");
    }
  }

  async function saveDataToFirestore() {
    console.log(branchData);
    let newBranch = { ...branchData };
    let branch_id = newBranch.id;
    if (!newBranch.id) {
      branch_id = await getNewBranchDocId(client_id);
      newBranch["id"] = branch_id;
    }
    validateBranchFields(newBranch);
    let sez = "";
    const gst = await handleUpload(
      branchData.gstImage,
      branchData.gstRef,
      client_id,
      branch_id,
      "gst"
    );
    console.log("GST", gst);
    if (branchData.sezImage) {
      sez = await handleUpload(
        branchData.sezImage,
        branchData.sezRef,
        client_id,
        branch_id,
        "sez"
      );
      newBranch["sezImage"] = sez.url;
      newBranch["sezRef"] = sez.ref ?? "";
    } else {
      delete newBranch.sezImage;
      delete newBranch.sezRef;
      delete newBranch.sezLetter;
    }

    newBranch["gstImage"] = gst.url;
    newBranch["gstRef"] = gst.ref ?? "";
    const cityData = citiesData.filter(
      (city) => city.value === newBranch["city"]
    )[0];
    if (clientData.modelType === clientModelTypes.model3) {
      const clientCityData = await getClientCityById(client_id, cityData.id);
      newBranch["region_id"] = clientCityData.region_id;
      newBranch["cluster_id"] = clientCityData.cluster_id;
      newBranch["city_id"] = clientCityData.id;
      newBranch["cityCode_id"] = clientCityData.cityCode_id;
    }
    const res = await saveBranchData(client_id, newBranch);
    if (res.successfull) {
      setBranchData(res.data);
      console.log(branchData);
      return "Saved";
    } else {
      return "Failed";
    }
  }

  const handleUpload = async (file, logoRef) => {
    if (typeof file === "string") return { url: file, ref: logoRef };

    if (logoRef) await deletePreviousFile(logoRef);
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    console.log(id);
    const ref = `/client-documents/${id}`;
    const uploadTask = uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return { url, ref };
  };

  const resetAndCloseDialog = () => {
    setNearVendor([]);
    closeDialog();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={dialogInfo.open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {dialogInfo.type === "edit" ? "Edit Branch" : "Add Branch"}
      </DialogTitle>
      <DialogContent dividers={true}>
        <TextField
          required
          fullWidth
          id="outlined-branch-name"
          label="Branch Name"
          variant="outlined"
          name="name"
          value={branchData.name}
          style={{ marginBottom: "10px" }}
          onChange={(e) => updateBranchDetails(e)}
        />
        <TextField
          required
          fullWidth
          id="outlined-branch-address"
          label="Branch Address"
          variant="outlined"
          name="address"
          value={branchData.address}
          style={{ marginBottom: "10px" }}
          onChange={(e) => updateBranchDetails(e)}
        />
        <TextField
          required
          fullWidth
          id="outlined-branch-pincode"
          label="Branch PinCode"
          variant="outlined"
          name="pincode"
          value={branchData.pincode}
          onChange={(e) => updateBranchDetails(e)}
          style={{ marginBottom: "10px" }}
        />
        <TextField
          required
          fullWidth
          id="outlined-branch-shipping-address"
          label="Branch Shipping Address"
          variant="outlined"
          name="shippingAddress"
          value={branchData.shippingAddress}
          onChange={(e) => updateBranchDetails(e)}
          style={{ marginBottom: "10px" }}
        />

        <TextField
          required
          fullWidth
          id="outlined-branch-type-gst"
          select
          label="Type of Branch GST"
          variant="outlined"
          name="gstType"
          value={branchData.gstType ?? ""}
          onChange={(e) => updateBranchDetails(e)}
          style={{ marginBottom: "10px" }}
        >
          {gstTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        {branchData.gstType === "SEZ" && (
          <>
            <TextField
              fullWidth
              id="outlined-branch-sez-letter-number"
              label="Branch SEZ Letter Number"
              variant="outlined"
              name="sezLetter"
              value={branchData.sezLetter}
              onChange={(e) => updateBranchDetails(e)}
              style={{ marginBottom: "10px" }}
            />
            <InputLabel htmlFor="sez-letter-image">
              Upload SEZ Letter Image
            </InputLabel>
            <input
              name="sezImage"
              id="sez-letter-image"
              type="file"
              onChange={(e) => updateFiles(e)}
              style={{ marginBottom: "10px" }}
            />
            {branchData.sezImage ? (
              typeof branchData.sezImage === "string" ? (
                <ColorButton
                  variant="contained"
                  className={classes.button}
                  onClick={() => window.open(branchData.sezImage, "_blank")}
                  style={{ marginBottom: "10px" }}
                >
                  View
                </ColorButton>
              ) : (
                <ColorButton
                  variant="contained"
                  className={classes.button}
                  onClick={() =>
                    window.open(
                      URL.createObjectURL(branchData.sezImage),
                      "_blank"
                    )
                  }
                  style={{ marginBottom: "10px" }}
                >
                  View
                </ColorButton>
              )
            ) : null}
          </>
        )}

{branchData.gstType !== "SEZ" && (
          <>

        <TextField
          required
          fullWidth
          id="outlined-branch-gst-number"
          label="Branch GST Number"
          variant="outlined"
          name="gstNo"
          value={branchData.gstNo}
          onChange={(e) => updateBranchDetails(e)}
          style={{ marginBottom: "10px" }}
        />
        <InputLabel htmlFor="branch-gst">Upload Branch GST</InputLabel>
        <input
          name="gstImage"
          id="branch-gst"
          type="file"
          onChange={(e) => updateFiles(e)}
          style={{ marginBottom: "10px" }}
        />
        </>
        )}
        {branchData.gstImage ? (
          typeof branchData.gstImage === "string" ? (
            <ColorButton
              variant="contained"
              className={classes.button}
              onClick={() => window.open(branchData.gstImage, "_blank")}
              style={{ marginBottom: "10px" }}
            >
              View
            </ColorButton>
          ) : (
            <ColorButton
              variant="contained"
              className={classes.button}
              onClick={() =>
                window.open(URL.createObjectURL(branchData.gstImage), "_blank")
              }
              style={{ marginBottom: "10px" }}
            >
              View
            </ColorButton>
          )
        ) : null}
        <TextField
          required
          fullWidth
          select
          id="outlined-branch-city"
          label="Branch city"
          variant="outlined"
          name="city"
          value={branchData.city ?? ""}
          onChange={(e) => updateBranchDetails(e)}
          style={{ marginBottom: "10px" }}
        >
          {cities.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id="outlined-branch-district"
          label="Branch District"
          variant="outlined"
          name="district"
          value={branchData.district}
          onChange={(e) => updateBranchDetails(e)}
          style={{ marginBottom: "10px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="isMailRoom"
              checked={branchData.isMailRoom ?? false}
              onChange={(e) => updateBranchDetails(e)}
            />
          }
          label="Add Mailroom for ordering"
          style={{ marginBottom: "10px" }}
        />
        <Button
          onClick={() => {
            getNearestVendors(branchData);
          }}
          style={{ marginBottom: "10px" }}
        >
          Get Nearest Vendors
        </Button>
        {branchData.vendorName || Object.keys(nearVendor).length ? (
          <TextField
            fullWidth
            required
            select
            id="outlined-branch-vendor-store"
            label="Branch Vendor Store"
            variant="outlined"
            name="vendor"
            value={branchData.vendor}
            onChange={(e) => updateBranchDetails(e)}
            style={{ marginBottom: "10px" }}
          >
            {Object.keys(nearVendor).map((option) => (
              <MenuItem key={option} value={option}>
                {`Name: ${nearVendor[option]["name"]} | Type: ${
                  nearVendor[option]["type"]
                } | Customers: ${
                  nearVendor[option]["noOfClients"]
                } | Distance : ${nearVendor[option]["distance"].toFixed(
                  2
                )} Kms`}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <ColorButton onClick={handleSubmit}>Submit</ColorButton>
      </DialogActions>
    </Dialog>
  );
};

export default BranchDialog;
