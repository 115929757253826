import React, { useState } from "react";
import Layout from "../../common/Layout";
import { TabMenu } from "../../common/Components/TabMenu";
import { TAB_NAME } from "../../common/const1";
import { StyledPrimaryButton } from "../../common/Components/ColorButton";
import AddIcon from "@material-ui/icons/Add";
import { OPEN_MODAL_TITLE } from "./constants";
import BlogAdd from "./BlogAdd/BlogAdd";
import CareerAdd from "./CareerAdd/CareerAdd";
import CaseStudyAdd from "./CaseStudyAdd/CaseStudyAdd";
import TestimonialAdd from "./TestimonialAdd/TestimonialAdd";
import MarketingLinkAdd from "./MarketingLinkAdd/MarketingLinkAdd";
import { useGetPermissions } from "context/PermissionsProvider";

function Marketing({ children }) {
  const { userPermissions } = useGetPermissions();

  const canAddBlog = userPermissions?.["Blog"]?.Add;
  const canAddCareer = userPermissions?.["Career"]?.Add;
  const canAddCaseStudy = userPermissions?.["Case Study"]?.Add;
  const canAddTestimonial = userPermissions?.["Testimonial"]?.Add;
  const canAddMarketingLink = userPermissions?.["Marketing Link"]?.Add;

  const [addMarketingModal, setShowMarketingModal] = useState(null);

  const onCloseModal = () => {
    setShowMarketingModal(null);
  };

  return (
    <>
      <Layout>
        <div className="mt-5 pt-5 mr-5 ml-5">
          <TabMenu tabName={TAB_NAME.MARKETING}>
            <div className="flex gap-2">
              {canAddBlog && (
                <StyledPrimaryButton
                  startIcon={<AddIcon />}
                  onClick={() =>
                    setShowMarketingModal(OPEN_MODAL_TITLE.ADD_BLOG)
                  }
                >
                  ADD BLOG
                </StyledPrimaryButton>
              )}
              {canAddCareer && (
                <StyledPrimaryButton
                  startIcon={<AddIcon />}
                  onClick={() =>
                    setShowMarketingModal(OPEN_MODAL_TITLE.ADD_CAREER)
                  }
                >
                  ADD CAREER
                </StyledPrimaryButton>
              )}
              {canAddCaseStudy && (
                <StyledPrimaryButton
                  startIcon={<AddIcon />}
                  onClick={() =>
                    setShowMarketingModal(OPEN_MODAL_TITLE.ADD_CASE_STUDY)
                  }
                >
                  ADD CASE STUDY
                </StyledPrimaryButton>
              )}
              {canAddTestimonial && (
                <StyledPrimaryButton
                  startIcon={<AddIcon />}
                  onClick={() =>
                    setShowMarketingModal(OPEN_MODAL_TITLE.ADD_TESTIMONIAL)
                  }
                >
                  ADD TESTIMONIAL
                </StyledPrimaryButton>
              )}
              {canAddMarketingLink && (
                <StyledPrimaryButton
                  startIcon={<AddIcon />}
                  onClick={() =>
                    setShowMarketingModal(OPEN_MODAL_TITLE.ADD_MARKETING_LINK)
                  }
                >
                  GENERATE MARKETING LINK
                </StyledPrimaryButton>
              )}
            </div>
          </TabMenu>
          <div className="bg-white max-sm:p-2 shadow-md">{children}</div>
        </div>
      </Layout>
      {canAddBlog && (
        <BlogAdd
          open={OPEN_MODAL_TITLE.ADD_BLOG === addMarketingModal}
          onClose={onCloseModal}
        />
      )}
      {canAddCareer && (
        <CareerAdd
          open={OPEN_MODAL_TITLE.ADD_CAREER === addMarketingModal}
          onClose={onCloseModal}
        />
      )}
      {canAddCaseStudy && (
        <CaseStudyAdd
          open={OPEN_MODAL_TITLE.ADD_CASE_STUDY === addMarketingModal}
          onClose={onCloseModal}
        />
      )}
      {canAddTestimonial && (
        <TestimonialAdd
          open={OPEN_MODAL_TITLE.ADD_TESTIMONIAL === addMarketingModal}
          onClose={onCloseModal}
        />
      )}
      {canAddMarketingLink && (
        <MarketingLinkAdd
          open={OPEN_MODAL_TITLE.ADD_MARKETING_LINK === addMarketingModal}
          onClose={onCloseModal}
        />
      )}
    </>
  );
}

export default Marketing;
