import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chips: {
    margin: 4,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.dark,
    "&&:focus": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.dark,
    },
  },
}));

export function AssignedPrivilege({ privileges }) {
  const classes = useStyles();

  const permissionLabels = Object.entries(privileges).flatMap(
    ([category, actions]) =>
      Object.entries(actions)
        .filter(([, value]) => value)
        .map(([action]) => `${action} ${category}`)
  );

  return permissionLabels.map((value, index) => {
    return <Chip key={index} label={value} className={classes.chips} />;
  });
}
