import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Container, Button, TextField, MenuItem } from "@material-ui/core";
import RateField from "./RateField";
import ImagesField from "./ImagesField";
import { ColorButton } from "../../../common/Components/ColorButton";
import {
  saveCityCounter,
  savePANProductsApprovalsData,
  saveProductsApprovalsData,
} from "../../../backend/saveDataToFirestore";
import swal from "sweetalert";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../../backend/uploadFileToStorage";
import { NO_IMAGE_URL, UnitValues } from "../../../common/const";
import { incrementFranchiseProductsCount } from "../../../backend/getCounters";
import VideoField from "./VideoField";
import { getProductsCategoriesDetails } from "../../../backend/getDataFromFirestore";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "100px",
  },
}))(TableRow);

const useStyles = makeStyles({
  container: {
    maxHeight: "75vh",
  },
  desDetails: {
    height: 100,
    overflow: "auto",
  },
  duplicate: {
    backgroundColor: "red",
  },
});

const ProductsTable = ({
  productData,
  productLength,
  cities,
  title,
  totalCounter,
}) => {
  // const rates = React.useRef(null);
  const filesData = React.useRef(null);
  const videoData = React.useRef(null);
  const unitData = React.useRef(null);

  const [upload, setUpload] = useState(true);

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [unitValue, setUnitValue] = useState();

  const [productCategories, setProductCategories] = useState({});

  useEffect(() => {
    // console.log(productData);
    // rates.current = new Array(productLength).fill();
    videoData.current = new Array(productLength).fill();
    filesData.current = new Array(productLength).fill();
    unitData.current = new Array(productLength).fill();
    for (let product of productData) {
      if (product["duplicate"]) {
        setUpload(false);
        break;
      }
    }
  }, [productLength]);

  useEffect(() => {
    setSearchResult(productData);
  }, [productData]);

  useEffect(() => {
    async function getProductsCategories() {
      const categories = await getProductsCategoriesDetails();
      setProductCategories(categories);
    }
    getProductsCategories();
  }, []);

  const classes = useStyles();
  // console.log("length of products", productLength, "filenames are ", fileNames);

  const uploadImagesAndUnitsOfProduct = async (index) => {
    let ref = `/product-images/${productData[index]["SKU ID"]}`;
    let imagesURL = [];
    if (filesData.current[index]) {
      for (let file of filesData.current[index]) {
        let task = uploadFileToStorage(ref + `/${file.name}`, file);
        imagesURL.push(await getDownloadURL(task));
      }
    } else {
      imagesURL = [NO_IMAGE_URL];
    }

    productData[index]["Product Images"] = imagesURL;
    productData[index]["UNIT"] = unitData.current[index];
  };

  const uploadVideoOfProduct = async (index) => {
    let ref = `/product-video/${productData[index]["SKU ID"]}`;
    let videoUrl = [];

    if (videoData.current[index]) {
      let task = uploadFileToStorage(
        ref + `/${videoData.current[index].name}`,
        videoData.current[index]
      );
      videoUrl.push(await getDownloadURL(task));
      // for (let file of videoData.current[index]) {
      // }
    } else {
      videoUrl = [];
    }
    productData[index]["Product Video"] = videoUrl;
  };

  const uploadData = async (event) => {
    //check category and sub-category
    let isSubCategoryNotExist = 0;
    productData.forEach((data) => {
      let subCategory;
      if (productCategories[data["Product Category"]] === undefined) {
        //value not exist
        isSubCategoryNotExist++;
      } else if (!productCategories[data["Product Category"]].length) {
        //empty sub category array
        isSubCategoryNotExist++;
      } else {
        subCategory = productCategories[data["Product Category"]].find(
          (subCat) => data["Product Sub-Category"] === subCat
        );
        if (subCategory !== data["Product Sub-Category"]) {
          isSubCategoryNotExist++;
        }
      }
    });
    if (isSubCategoryNotExist) {
      await swal(
        "Failed!",
        "Please Check Category and Sub-Categories!!!",
        "error"
      );
      return;
    }
    //check user should upload minimum 1 image to each product
    let checkImageValidation = 0;
    filesData?.current?.forEach((imageArr) => {
      if (imageArr?.length === 0 || imageArr === undefined) {
        checkImageValidation++;
      }
    });
    if (checkImageValidation) {
      await swal("Failed!", "Please Upload at least one image!!!", "error");
      return;
    }

    const swalRes = await swal(
      "Are you sure you want to upload these Products",
      "Please make sure all prices are correct. You will not be able to change prices again.",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    swal(
      "Uploading Products",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );

    console.log("Upload Data");
    // console.log(productData, rates.current, filesData.current);
    // TODO : Upload All Images of Product
    // TODO : Save Product data on Firestore
    //        FranchiseProductApprovals > CityName
    try {
      for (let i = 0; i < productLength; i++) {
        await uploadImagesAndUnitsOfProduct(i);
        await uploadVideoOfProduct(i);
        const res = await savePANProductsApprovalsData(cities, productData[i]);
      }
      // TODO : Add Counter of all Franchise and Estore to CityName Document and save title
      for (let city of cities) await saveCityCounter(city.value, title);
      //Save New Counter of franchise products
      incrementFranchiseProductsCount(totalCounter);

      swal.close();
      swal("Success!", "Products Uploaded", "success").then((val) =>
        window.location.reload()
      );
    } catch (e) {
      swal.close();
      console.log("Error is ", e);
      swal("Error!", "Something went wrong", "error");
    }
  };

  // const changeRate = (index, val) => {
  //   rates.current[index] = isNaN(val) ? undefined : val;
  // console.log(rates.current);
  // };

  const changeFileNames = (index, val) => {
    filesData.current[index] = val;
    // console.log(filesData.current);
  };
  const videoHandle = (index, val) => {
    videoData.current[index] = val;
  };

  const changeUnitNames = (e, index) => {
    setUnitValue(e.target.value);
    unitData.current[index] = e.target.value;
    // console.log(unitData.current)
  };

  const search = (e) => {
    setSearchValue(e.target.value);
    const data = searchLeadResult(e.target.value, "");
    setSearchResult([...data]);
  };
  const searchLeadResult = (name, status) => {
    // console.log(name, status, searchResult);
    return productData.filter((lead) => {
      if (name && status) {
        return lead["Brand Name"].toLowerCase().includes(name.toLowerCase());
      }
      if (name) {
        return (
          lead["Brand Name"].toLowerCase().includes(name.toLowerCase()) ||
          lead["Product Category"].toLowerCase().includes(name.toLowerCase()) ||
          lead["Product Sub-Category"]
            .toLowerCase()
            .includes(name.toLowerCase()) ||
          lead["Product Name"].toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const scrolldiv = React.createRef(null);

  const scroll = (scrollOffset) => {
    scrolldiv.current.scrollLeft += scrollOffset;
  };
  return (
    <div>
      {productLength !== 0 && (
        <div className="row pb-3">
          <div className="col-10 col-md-10">
            <input
              type="text"
              className="search-box"
              placeholder="Search Name, Category, Sub-Category, Brand"
              onChange={(e) => {
                search(e);
              }}
              style={{ width: "475px", height: "40px" }}
            />
          </div>
        </div>
      )}
      <div style={{ paddingBottom: "10px" }}>
        <ColorButton
          disabled={!upload}
          variant="contained"
          color="primary"
          onClick={uploadData}
        >
          Upload Data
        </ColorButton>
        {/* &nbsp;&nbsp;&nbsp; */}
        {/* <ColorButton variant="contained"
          color="primary" onClick={() => scroll(-20)}>LEFT</ColorButton>
        &nbsp;&nbsp;&nbsp;
        <ColorButton variant="contained"
          color="primary" onClick={() => scroll(20)}>RIGHT</ColorButton> */}
      </div>
      <TableContainer
        className={classes.container}
        component={Paper}
        ref={scrolldiv}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "10em" }}>SKU ID</TableCell>

              <TableCell style={{ width: "10em" }}>Brand</TableCell>

              <TableCell style={{ width: "10em" }}>Product Category</TableCell>

              <TableCell style={{ width: "10em" }}>
                Product Sub-Category
              </TableCell>

              <TableCell style={{ width: "10em" }}>Product Name</TableCell>

              <TableCell style={{ width: "10em" }}>Description</TableCell>

              <TableCell style={{ width: "20em" }}>
                Description Details
              </TableCell>

              <TableCell style={{ width: "20em" }}>Product Image</TableCell>
              <TableCell style={{ width: "20em" }}>Product Video</TableCell>

              <TableCell style={{ width: "10em" }}>UNIT</TableCell>

              <TableCell style={{ width: "10em" }}>GST%</TableCell>

              <TableCell style={{ width: "10em" }}>HSN CODE</TableCell>

              <TableCell style={{ width: "10em" }}>MRP</TableCell>

              {/* <TableCell style={{ width: "10em" }}>Rate</TableCell> */}

              {/* <TableCell style={{ width: "10em" }}>TAT</TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {productData === undefined ? (
              <div></div>
            ) : searchValue && searchResult.length === 0 ? (
              <div className="row pb-3 justify-content-center">
                <TableCell style={{ marginTop: "10px" }}>
                  Search Not Found!
                </TableCell>
              </div>
            ) : (
              searchResult.map((p, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    className={p["duplicate"] ? classes.duplicate : null}
                  >
                    <TableCell component="th" scope="row">
                      {p["SKU ID"]}
                    </TableCell>

                    <TableCell>{p["Brand Name"]}</TableCell>

                    <TableCell>{p["Product Category"]}</TableCell>

                    <TableCell>{p["Product Sub-Category"]}</TableCell>

                    <TableCell>{p["Product Name"]}</TableCell>

                    <TableCell>
                      <div className={classes.desDetails}>
                        {p["Description"]}
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className={classes.desDetails}>
                        {p["Description Details"]}
                      </div>
                    </TableCell>

                    <TableCell>
                      <ImagesField
                        index={index}
                        changeFileNames={changeFileNames}
                      />
                    </TableCell>
                    <TableCell>
                      <VideoField index={index} changeFileNames={videoHandle} />
                    </TableCell>

                    <TableCell>
                      <TextField
                        required
                        fullWidth
                        select
                        id="outlined-category"
                        label="UNIT"
                        name="UNIT"
                        value={unitValue}
                        onChange={(e) => changeUnitNames(e, index)}
                        variant="outlined"
                        style={{ marginBottom: "10px" }}
                      >
                        {Object.keys(UnitValues).map((option, index) => (
                          <MenuItem
                            key={UnitValues[index]}
                            value={UnitValues[option]}
                          >
                            {UnitValues[option]}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>

                    <TableCell>{p["GST%"]}%</TableCell>

                    <TableCell>{p["HSN CODE"]}</TableCell>

                    <TableCell>{Number(p["MRP"]).toFixed(2)}</TableCell>

                    {/* <TableCell>
                      <RateField index={index} changeRate={changeRate} />
                    </TableCell> */}

                    {/* <TableCell>{p["TAT"]}</TableCell> */}
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductsTable;
