import React, { useEffect } from "react";
import { TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import DrawerWrapper from "../../../common/DrawerWrapper/DrawerWrapper";
import {
  CancelButton,
  StyledCreateButton,
} from "../../../common/Components/ColorButton";
import { OPEN_MODAL_TITLE } from "../constants.js";
import { useFirestore } from "reactfire";
import swal from "sweetalert";

const initialState = {
  CompanyName: "",
  CompanyPosition: "",
  TDesc: "",
  TestimonialAuthor: "",
  TestimonialTitle: "",
};

function EditTestimonial({ open, onClose, editTestimonialDetails = {} }) {
  const db = useFirestore();
  const [testimonial, setTestimonial] = useState(initialState);
  const [showSpinner, setShowSpinner] = React.useState(false);

  useEffect(() => {
    if (editTestimonialDetails) {
      setTestimonial(editTestimonialDetails);
    }
  }, [editTestimonialDetails]);

  const onChange = (e) => {
    setTestimonial({ ...testimonial, [e.target.name]: e.target.value });
  };

  const handleUpdateTestimonial = async () => {
    setShowSpinner(true);

    if (!testimonial?.id) return;

    await db.collection("Testimonials").doc(testimonial?.id).update({
      CompanyName: testimonial.CompanyName,
      CompanyPosition: testimonial.CompanyPosition,
      TDesc: testimonial.TDesc,
      TestimonialAuthor: testimonial.TestimonialAuthor,
      TestimonialTitle: testimonial.TestimonialTitle,
    });
    setShowSpinner(false);
    onClose();
    swal("Success!!!", "Testimonials Updated", "success").then(() => {
      window.location.reload();
    });
  };

  return (
    <DrawerWrapper state={open} onClose={onClose}>
      <Typography variant="h5" className="!font-semibold !mb-7">
        {OPEN_MODAL_TITLE.EDIT_TESTIMONIAL}
      </Typography>
      <form
        className="flex flex-col gap-6"
        noValidate
        autoComplete="off"
        id="editTestimonialForm"
      >
        <TextField
          label="Company Name*"
          name="CompanyName"
          value={testimonial.CompanyName}
          onChange={onChange}
        />
        <TextField
          label="Company Position*"
          name="CompanyPosition"
          value={testimonial.CompanyPosition}
          onChange={onChange}
        />
        <TextField
          label="TDesc"
          name="TDesc"
          value={testimonial.TDesc}
          onChange={onChange}
        />
        <TextField
          label="Testimonial Author"
          name="TestimonialAuthor"
          value={testimonial.TestimonialAuthor}
          onChange={onChange}
        />
        <TextField
          label="Testimonial Title"
          name="TestimonialTitle"
          value={testimonial.TestimonialTitle}
          onChange={onChange}
        />
      </form>
      <div className="fixed bottom-0 mb-14 flex gap-1.5">
        <StyledCreateButton onClick={handleUpdateTestimonial}>
          Update
        </StyledCreateButton>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </div>
    </DrawerWrapper>
  );
}

export default EditTestimonial;
