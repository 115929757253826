import React, { useContext, useEffect, useState } from "react";
import Layout from "../../common/Layout";
import { Geolocation } from "@capacitor/geolocation";
import { Capacitor } from "@capacitor/core";
import {
  updateCheckOutToMeeting,
  updateLocationStatusToMeeting,
} from "../../backend/editDataInFirestore";
import { useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { getLocationLeftFromMeeting } from "../../backend/getDataFromFirestore";
import { LocationContext } from "../../common/LocationProvider";
import { MarkVisited } from "../../common/Components/ColorButton";

const LocationTracking = () => {
  const [status, setStatus] = useState(null);
  const [locationLog, setLocationLog] = useState({});
  let history = useHistory();
  const { locationData, checkOut } = useContext(LocationContext);

  // useEffect(() => {
  //   const intervalId = setInterval(() => getLocation(), 5000);
  //   return () => clearInterval(intervalId);
  // }, [locationLog]);

  let location = useLocation();
  if (!location.state) {
    history.replace("/dashboard");
    return null;
  }
  let { meeting } = location.state;

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const getLocation = async () => {
    console.log("Get Location Triggered", { locationLog });
    if (Capacitor.isNativePlatform()) {
      const coordinates = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });

      if (!coordinates || !coordinates.coords) {
        console.log("Permission not granted");
        return null;
      }

      console.log("Current position:", coordinates);
      const locObj = {
        lat: coordinates.coords.latitude,
        lng: coordinates.coords.longitude,
        accuracy: coordinates.coords.accuracy,
      };
      setLocationLog(locObj);
    } else {
      if (!navigator.geolocation) {
        setStatus("Geolocation is not supported by your browser");
      } else {
        setStatus("Locating...");
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setStatus(null);
            const locObj = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              accuracy: position.coords.accuracy,
            };
            setLocationLog(locObj);
            checkLocationStatus(locObj);
          },
          (err) => {
            setStatus("Unable to retrieve your location", err);
          },
          options
        );
      }
    }
  };

  function checkDistance(checkPoint, centerPoint, km) {
    console.log("checkpoitn: ", checkPoint);
    console.log("centerpoint: ", centerPoint);
    var ky = 40000 / 360;
    var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
    var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= km;
  }

  const checkLocationStatus = async (location) => {
    const companyLocation = { lat: meeting.lat, lng: meeting.lng };
    const res = checkDistance(location, companyLocation, 1);
    const { successfull, locationLeft, locationReentered } =
      await getLocationLeftFromMeeting(meeting);

    if (res) {
      //User Already left the place and now he reenters
      if (locationLeft && !locationReentered) {
        updateLocationStatusToMeeting(meeting, "Reentered");
      }
    } else {
      //User Already left the place and now he leaves again
      if (locationLeft && locationReentered) {
        await swal("Warning", "You will be check out in 5 min", "warning");
        await checkOut();
      }
      //User Leaves the meeting for 1st time
      else if (!locationLeft) {
        const swalRes = await swal(
          "Do you want to check out ?",
          "You are Outside Meeting. Please make sure you reenter the meeting if you don't want to check out",
          "warning",
          {
            buttons: {
              cancel: "No",
              catch: {
                text: "Yes",
                value: true,
              },
            },
          }
        );
        if (swalRes) {
          checkOut();
          return;
        }
        updateLocationStatusToMeeting(meeting, "Left");
      }
    }
  };

  const checkOutMeeting = async (fromButton = false) => {
    if (fromButton) {
      const { successfull, locationLeft, locationReentered } =
        await getLocationLeftFromMeeting(meeting);
      if (locationLeft && !locationReentered) {
        swal("Warning", "To Check out please re-enter meeting", "warning");
        return;
      }
    }
    const res = await updateCheckOutToMeeting(meeting);
    if (res.successfull) {
      history.goBack();
    } else {
      swal("Error!", "Can't Update Check Out Status of Meeting", "error");
    }
  };

  return (
    <Layout>
      <div className="mt-5 pt-5 d-flex flex-column align-items-center">
        {/* <MarkVisited
          variant="contained"
          onClick={() => checkOut({ fromButton: true })}
        >
          Check Out
        </MarkVisited> */}
        <p>{status}</p>
        <h1>Coordinates Logs </h1>

        {locationData && (
          <p>
            Latitude: {locationData.lat}
            <br />
            Longitude: {locationData.lng}
            <br />
            Accuracy: {locationData.accuracy}
            <br />
          </p>
        )}
      </div>
    </Layout>
  );
};

export default LocationTracking;
