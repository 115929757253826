import React, { useContext, useEffect, useState } from "react";

import "firebase/auth";
import { useUser } from "reactfire";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AddRegion from "../Pages/AddRegion/AddRegion";
import AddRegionClusters from "../Pages/AddRegionClusters/AddRegionClusters";
import AddBlog from "../Pages/Home/Home";
import AddCareer from "../Pages/Career/AddCareer";
import AddCaseStudy from "../Pages/CaseStudy/AddCaseStudy";
import ViewBlogComponent from "../Pages/Home/Components/ViewBlogComponent";
import EditBlogComponent from "../Pages/Home/Components/EditBlogComponent";
import ViewBlogDetailComponent from "../Pages/Home/Components/ViewBlogDetailComponent";
import ViewCareer from "../Pages/Career/ViewCareer";
import EditCareer from "../Pages/Career/EditCareer";
import ViewCaseStudy from "../Pages/CaseStudy/ViewCaseStudy";
import ViewTestimonial from "../Pages/CaseStudy/ViewTestimonial";
import EditCaseStudy from "../Pages/CaseStudy/EditCaseStudy";
import EditTestimonial from "../Pages/CaseStudy/EditTestimonial";
import AddProducts from "../Pages/AddProducts/AddProducts";
import AddPANProducts from "../Pages/AddPANProducts/AddPANProducts";

import AddFranchise from "../Pages/AddFranchise/AddFranchise";
import NotFound from "../Pages/NotFound/NotFound";
import AddSeller from "../Pages/AddSeller/AddSeller";
import AddEstore from "../Pages/AddEstore/AddEstore";
import ViewFranchise from "../Pages/AddFranchise/ViewFranchise";
import ViewEStore from "../Pages/AddEstore/ViewEStore";
import ViewSeller from "../Pages/AddSeller/ViewSeller";
import ViewFranchiseDetails from "../Pages/AddFranchise/ViewFranchiseDetails";
import ViewEstoreDetails from "../Pages/AddEstore/ViewEstoreDetails";
import ViewSellerDetails from "../Pages/AddSeller/ViewSellerDetails";
import Demo from "../Pages/Demo/Demo";
import SuperAdminLogin from "../Pages/SuperAdminLogin/SuperAdminLogin";
import { checkUserLoggedOutState } from "../backend/authenticateUser";
import getPrivilegesForNav, {
  getRoleForNav,
  getEmailForNav,
} from "../backend/getPrivilegesForNav";
import AddEmployee from "../Pages/Employee/AddEmployee";
import ViewEmployee from "../Pages/Employee/ViewEmployee";
import EditEmployee from "../Pages/Employee/EditEmployee";
import Dashboard from "../Pages/Dashboard/Dashboard";
import PermissionDenied from "../Pages/PermissionDenied/PermissionDenied";
// import AssignedLeads from "../Pages/AssignedLeads/AssignedLeads";
import AddNSH from "../Pages/NSH/AddNSH";
import AddRSH from "../Pages/RSH/AddRSH";
import ViewNSH from "../Pages/NSH/ViewNSH";
import ViewRSH from "../Pages/RSH/ViewRSH";
import EditNSH from "../Pages/NSH/EditNSH";
import EditRSH from "../Pages/RSH/EditRSH";
import Marketing from "../Pages/Marketing/marketing";
import ContactUs from "../Pages/Marketing/contactus";
import AddCSH from "../Pages/CSH/AddCSH";
import ViewCSH from "../Pages/CSH/ViewCSH";
import EditCSH from "../Pages/CSH/EditCSH";
import AddSE from "../Pages/SE/AddSE";
import ViewSE from "../Pages/SE/ViewSE";
import EditSE from "../Pages/SE/EditSE";
import DeletedNSH from "../Pages/NSH/DeletedNSH";
import DeletedRSH from "../Pages/RSH/DeletedRSH";
import DeletedCSH from "../Pages/CSH/DeletedCSH";
import DeletedSE from "../Pages/SE/DeletedSE";

import AddCity from "../Pages/AddCity/AddCity";
import ViewLeadDetails from "../Pages/AssignedLeads/ViewLeadDetails";
import ApproveClient from "../Pages/ApproveClients/ApproveClients";
import ApproveClientDetails from "../Pages/ApproveClients/ApproveClientDetails";
import ApproveProductList from "../Pages/ApproveClients/ApproveProductList";
import AddMeeting from "../Pages/Meetings/AddMeeting";
import ViewMeetings from "../Pages/Meetings/ViewMeetings";
import ClientApprovalList from "../Pages/ClientApprovalList/ClientApprovalList";
import ClientApprovalDetails from "../Pages/ClientApprovalList/ClientApprovalDetails";
import ClientApprovalProductList from "../Pages/ClientApprovalList/ClientApprovalProductList";
import ClientList from "../Pages/Clients/ClientList";
import ClientDetails from "../Pages/Clients/ClientDetails";
import ClientProductList from "../Pages/Clients/ClientProductList";
import SendLeadsToNSH from "../Pages/NSH/SendLeadsToNSH";
import GetLeads from "../Pages/NSH/GetLeads";
import GetLeadsFromNSH from "../Pages/RSH/GetLeadsFromNSH";
import GetLeadsFromRSH from "../Pages/CSH/GetLeadsFromRSH";
import ViewContactUs from "../Pages/Marketing/ViewContactUs";
import SendLeadsToSales from "../Pages/Marketing/SendLeadsToSales";

import Applications from "../Pages/Career/Applications";
import ShowProductsAverage from "../Pages/ShowProductsAverage/ShowProductsAverage";
import ShowSellerProducts from "../Pages/Seller/ShowSellerProducts";
import SellerProductsRequests from "../Pages/Seller/SellerProductsRequests";

import ShowFinalProducts from "../Pages/ShowFinalProducts/ShowFinalProducts";

import AddUserManager from "../Pages/AddUserManager/AddUserManager";
import AddLead from "../Pages/SalesCommon/AddLead";
import EditLead from "../Pages/EditLead/EditLead";
import AddProspect from "../Pages/AddProspect/AddProspect";
import ViewProspects from "../Pages/ViewProspects/ViewProspects";
import GenerateRateContract from "../Pages/RateContract/GenerateRateContract";
import ViewRateContract from "../Pages/RateContract/ViewRateContract";

import ImageUpload from "../Pages/PrintingModule/ImageUpload";

import SocialMediaLeads from "../Pages/Marketing/SocialMediaLeads";

import ShowSeRequests from "../Pages/PM-Requests/ShowSeRequests";

import ClientAskingPriceApproval from "../Pages/PM-Requests/ClientAskingPriceApproval";

import ClientAskingPriceForSe from "../Pages/PM-Requests/ClientAskingPriceForSe";
import AddNewProduct from "../Pages/AddNewProduct/AddNewProduct";
import LocationTracking from "../Pages/LocationTracking/LocationTracking";
import LocationProvider, { LocationContext } from "../common/LocationProvider";
import { getMeetingDataOfSE } from "../backend/getDataFromFirestore";
// import ShowLocations from "../Pages/ShowLocations/ShowLocations";
import ShowLocations from "../PagesV2/LeadsManagement/ShowLocations/ShowLocations";
import ShowEstoreRequests from "../Pages/AddEstore/ShowEstoreRequests";
import ViewCity from "../Pages/AddCity/ViewCity";
import MainCategory from "../Pages/Category/MainCategory";
import SubCategory from "../Pages/Category/SubCategory";
import ViewCategories from "../Pages/Category/ViewCategories";
import AddCityCluster from "../Pages/AddClusterCities/AddClusterCities";
import AddBranch from "../Pages/AddBranch/AddBranch";
import ViewBranch from "../Pages/ViewBranch/ViewBranch";
import ShowMissedMeetings from "../Pages/ShowMissedMeetings/ShowMissedMeetings";

import ApprovalFromFranchise from "../Pages/ApprovalFromFranchise/ApprovalFromFranchise";

import ViewQuotation from "../Pages/RateContract/ViewQuotation";
import GetAllLeads from "../Pages/Marketing/GetAllLeads";
import { DivertRequest } from "../Pages/AddFranchise/DivertRequest/DivertRequest";
import GetProfiles from "../Pages/EmployeeManagement/Profiles/Profiles";
import AddProfile from "../Pages/AddProfile/AddProfile";
import {
  EMAIL_LOCAL_STORAGE_KEY,
  NAME_LOCAL_STORAGE_KEY,
  PRIVILEGES_LOCAL_STORAGE_KEY,
  ROLE_LOCAL_STORAGE_KEY,
  USER_DOC_ID_LOCAL_STORAGE_KEY,
} from "../common/storageKey";
import {
  CORE_ASSIGNED_LEADS_VIEW,
  CORE_BLOG_VIEW,
  CORE_CAREER_VIEW,
  CORE_CASE_STUDIES_VIEW,
  CORE_EMPLOYEES_VIEW,
  CORE_LEADS_VIEW,
  CORE_PROFILES_VIEW,
  CORE_PROSPECTS_VIEW,
  CORE_SHOW_LOCATIONS_VIEW,
  CORE_TESTIMONIALS_VIEW,
  CORE_VIEW_MARKETING_LINK_VIEW,
} from "../common/constantsUrls";
import BlogView from "../PagesV2/Marketing/BlogView/BlogView";
import CareerView from "../PagesV2/Marketing/CareerView/CareerView";
import CaseStudyView from "../PagesV2/Marketing/CaseStudyView/CaseStudyView";
import TestimonialView from "../PagesV2/Marketing/TestimonialView/TestimonialView";
import MarketingLinkView from "../PagesV2/Marketing/MarketingLinkView/MarketingLinkView";
import AssignedLeads from "../PagesV2/LeadsManagement/AssignedLeads/AssignedLeads";
import Prospects from "../PagesV2/LeadsManagement/Prospects/Prospects";
import ViewEmployees from "../Pages/EmployeeManagement/ViewEmployees/ViewEmployees";

function toObject(arr) {
  var rv = {};
  if (arr) {
    for (var i = 0; i < arr.length; ++i) rv[`${arr[i]}`] = 1;
  }
  return rv;
}
const AppRouter = () => {
  const { setMeetingData } = useContext(LocationContext);

  const [privileges, setPrivileges] = useState(
    toObject(JSON.parse(localStorage.getItem(PRIVILEGES_LOCAL_STORAGE_KEY)))
  );

  async function getPrivileges() {
    let prev = JSON.parse(localStorage.getItem(PRIVILEGES_LOCAL_STORAGE_KEY));
    let role = localStorage.getItem(ROLE_LOCAL_STORAGE_KEY);
    let email = localStorage.getItem(EMAIL_LOCAL_STORAGE_KEY);
    if (!prev) {
      prev = await getPrivilegesForNav();
      localStorage.setItem(PRIVILEGES_LOCAL_STORAGE_KEY, JSON.stringify(prev));
    }
    if (!role) {
      const res = await getRoleForNav();
      if (res === "") {
        return null;
      }

      const { role, name, email, userDocId } = res;

      localStorage.setItem(ROLE_LOCAL_STORAGE_KEY, role);
      localStorage.setItem(NAME_LOCAL_STORAGE_KEY, name);
      localStorage.setItem(EMAIL_LOCAL_STORAGE_KEY, email);
      localStorage.setItem(USER_DOC_ID_LOCAL_STORAGE_KEY, userDocId);
    }
    // if (!email) {
    //   email = await getEmailForNav();
    //   localStorage.setItem(EMAIL_LOCAL_STORAGE_KEY, email);
    // }

    console.log("In Use effect");
    setPrivileges(toObject(prev));
    console.log("Role in app router: ", role);
    console.log("Email in app router: ", email);
  }

  useEffect(() => {
    let role = localStorage.getItem(ROLE_LOCAL_STORAGE_KEY);
    let docId = localStorage.getItem(USER_DOC_ID_LOCAL_STORAGE_KEY);

    if (role === "Sales Executive") {
      getMeetingDataOfSE(docId).then((meeting) => {
        console.log("Sales User ", { meeting });
        if (meeting) {
          setMeetingData(meeting);
        }
      });
    }
  }, []);

  const ProtectedRoute = ({ label, children, ...rest }) => {
    console.log("label is : ", label, privileges);
    console.log("childer is : ", label, children);
    // console.log("rest is : ", label, ...rest);
    return <Route {...rest} exact render={({ location }) => children} />;
  };

  const DashboardRoute = ({ children, ...rest }) => {
    // console.log("label is : ", label, previleges);
    return (
      <Route
        {...rest}
        render={({ location }) =>
          !checkUserLoggedOutState() ? (
            children
          ) : (
            <Redirect
              exact
              to={{
                pathname: "/",
              }}
            />
          )
        }
      />
    );
  };

  return (
    <>
      <LocationProvider>
        <Router>
          <Switch>
            <ProtectedRoute exact path="/add-blog" label="Add Blog">
              <AddBlog />
            </ProtectedRoute>
            <ProtectedRoute path="/view-blog" label="View Blog">
              <ViewBlogComponent />
            </ProtectedRoute>
            <ProtectedRoute
              path="/edit-blog"
              label="Edit Blog" //Add Blog
            >
              <EditBlogComponent />
            </ProtectedRoute>
            <ProtectedRoute path="/blog-detail" label="View Blog">
              <ViewBlogDetailComponent />
            </ProtectedRoute>

            <ProtectedRoute path="/AddCareer" label="Add Career">
              <AddCareer />
            </ProtectedRoute>
            <ProtectedRoute exact path="/view-career" label="View Career">
              <ViewCareer />
            </ProtectedRoute>
            <ProtectedRoute path="/view-career/:id" label="View Career">
              <Applications />
            </ProtectedRoute>

            <ProtectedRoute
              path="/edit-career"
              label="Edit Career" //Add Career
            >
              <EditCareer />
            </ProtectedRoute>

            <ProtectedRoute
              path="/AddCaseStudy"
              label="Add Case Study and Testimonial"
            >
              <AddCaseStudy />
            </ProtectedRoute>
            <ProtectedRoute path="/view-caseStudy" label="View Case Study">
              <ViewCaseStudy />
            </ProtectedRoute>
            <ProtectedRoute path="/view-testimonial" label="View Testimonial">
              <ViewTestimonial />
            </ProtectedRoute>
            <ProtectedRoute
              path="/edit-caseStudy"
              label="Edit Case Study" //Add Case Study and Testimonial
            >
              <EditCaseStudy />
            </ProtectedRoute>
            <ProtectedRoute
              path="/edit-testimonial"
              label="Edit Testimonial" //Add Case Study and Testimonial
            >
              <EditTestimonial />
            </ProtectedRoute>
            <ProtectedRoute path="/add-franchise" label="Add Franchise">
              <AddFranchise />
            </ProtectedRoute>
            <ProtectedRoute path="/add-seller" label="Add Seller">
              <AddSeller />
            </ProtectedRoute>
            <ProtectedRoute path="/add-estore" label="Add E-Store">
              <AddEstore />
            </ProtectedRoute>
            <ProtectedRoute path="/add-products" label="Add Products">
              <AddProducts />
            </ProtectedRoute>
            <ProtectedRoute path="/view-franchise" label="View Franchise">
              <ViewFranchise />
            </ProtectedRoute>
            <ProtectedRoute path="/view-eStore" label="View E-Store">
              <ViewEStore />
            </ProtectedRoute>
            <ProtectedRoute path="/view-seller" label="View Seller">
              <ViewSeller />
            </ProtectedRoute>

            <ProtectedRoute path="/ViewFranchiseDetails" label="View Franchise">
              <ViewFranchiseDetails />
            </ProtectedRoute>
            <ProtectedRoute path="/viewEstoreDetails" label="View E-Store">
              <ViewEstoreDetails />
            </ProtectedRoute>
            <ProtectedRoute path="/ViewSellerDetails" label="View Seller">
              <ViewSellerDetails />
            </ProtectedRoute>

            <ProtectedRoute exact path="/add-employee" label="Add Employees">
              <AddEmployee />
            </ProtectedRoute>
            <ProtectedRoute exact path="/view-employee" label="View Employees">
              <ViewEmployee />
            </ProtectedRoute>
            <ProtectedRoute exact path="/edit-employee" label="Add Employees">
              <EditEmployee />
            </ProtectedRoute>
            <Route exact path="/add-designation" label="Add Designation">
              <AddProfile />
            </Route>
            <ProtectedRoute
              exact
              path="/approve-clients"
              label="View Client Approvals"
            >
              <ApproveClient />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/approve-client-details"
              label="View Client Approvals"
            >
              <ApproveClientDetails />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/approve-product-list"
              label="View Client Approvals"
            >
              <ApproveProductList />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/client-approval-list"
              label="My Client Approvals"
            >
              <ClientApprovalList />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/client-approval-details"
              label="My Client Approvals"
            >
              <ClientApprovalDetails />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/client-approval-product-list"
              label="My Client Approvals"
            >
              <ClientApprovalProductList />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/client-list"
              label="View Approved Clients"
            >
              <ClientList />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/client-details"
              label="View Approved Clients"
            >
              <ClientDetails />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/client-product-list"
              label="View Approved Clients"
            >
              <ClientProductList />
            </ProtectedRoute>
            <ProtectedRoute exact path="/add-region" label="Add Region">
              <AddRegion />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/add-region-clusters"
              label="Add Region Clusters"
            >
              <AddRegionClusters />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/add-cluster-cities"
              label="Add Cluster Cities"
            >
              <AddCityCluster />
            </ProtectedRoute>
            <ProtectedRoute exact path="/add-branch" label="Add Branch">
              <AddBranch />
            </ProtectedRoute>
            <ProtectedRoute exact path="/view-branch" label="View Branch">
              <ViewBranch />
            </ProtectedRoute>

            {/* Added V2 */}
            {/* <ProtectedRoute exact path="/assigned-leads" label="Assigned Leads">
              <AssignedLeads />
            </ProtectedRoute> */}

            <ProtectedRoute
              exact
              path="/view-lead-details"
              label="Assigned Leads"
            >
              <ViewLeadDetails />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/add-nsh"
              label="Add National Sales Head"
            >
              <AddNSH />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/view-nsh"
              label="View National Sales Head"
            >
              <ViewNSH />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/add-rsh"
              label="Add Regional Sales Head"
            >
              <AddRSH />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/add-category"
              label="Add Product Main Category"
            >
              <MainCategory />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/add-subcategory"
              label="Add Product Sub Category"
            >
              <SubCategory />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/view-category"
              label="View Product Categories"
            >
              <ViewCategories />
            </ProtectedRoute>
            <ProtectedRoute exact path="/add-city" label="Add City">
              <AddCity />
            </ProtectedRoute>
            <ProtectedRoute exact path="/view-city" label="View City">
              <ViewCity />
            </ProtectedRoute>

            <ProtectedRoute exact path="/add-csh" label="Add City Sales Head">
              <AddCSH />
            </ProtectedRoute>

            <ProtectedRoute exact path="/add-se" label="Add Sales Executive">
              <AddSE />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/view-rsh"
              label="View Regional Sales Head"
            >
              <ViewRSH />
            </ProtectedRoute>

            <ProtectedRoute exact path="/view-csh" label="View City Sales Head">
              <ViewCSH />
            </ProtectedRoute>

            <ProtectedRoute exact path="/view-se" label="View Sales Executive">
              <ViewSE />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/edit-nsh"
              label="Add National Sales Head"
            >
              <EditNSH />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/edit-rsh"
              label="Add Regional Sales Head"
            >
              <EditRSH />
            </ProtectedRoute>

            <ProtectedRoute exact path="/edit-csh" label="Add City Sales Head">
              <EditCSH />
            </ProtectedRoute>

            <ProtectedRoute exact path="/edit-se" label="Add Sales Executive">
              <EditSE />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/deleted-nsh"
              label="Deleted National Sales Head"
            >
              <DeletedNSH />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/deleted-rsh"
              label="Deleted Regional Sales Head"
            >
              <DeletedRSH />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/deleted-csh"
              label="Deleted City Sales Head"
            >
              <DeletedCSH />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/deleted-se"
              label="Deleted Sales Executive"
            >
              <DeletedSE />
            </ProtectedRoute>

            <ProtectedRoute exact path="/marketing" label="Marketing">
              <Marketing />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/marketing-view-contactus"
              label="Marketing View Contact Us"
            >
              <ViewContactUs />
            </ProtectedRoute>

            <ProtectedRoute exact path="/add-meeting" label="Add Meeting">
              <AddMeeting />
            </ProtectedRoute>

            <ProtectedRoute exact path="/view-meetings" label="View Meetings">
              <ViewMeetings />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/sendLeadsToNSH"
              label="Send Leads To NSH"
            >
              <SendLeadsToNSH />
            </ProtectedRoute>

            <ProtectedRoute exact path={CORE_LEADS_VIEW} label="Get Leads">
              <GetLeads />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/getLeadsfromnsh"
              label="Get Leads From NSH"
            >
              <GetLeadsFromNSH />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/getLeadsfromrsh"
              label="Get Leads From RSH"
            >
              <GetLeadsFromRSH />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/show-products-average"
              label="Show Products"
            >
              <ShowProductsAverage />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/show-estore-requests"
              label="Show Estore Requests"
            >
              <ShowEstoreRequests />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/show-seller-products"
              label="Show Seller Products"
            >
              <ShowSellerProducts />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/seller-products-requests"
              label="Seller Products Requests"
            >
              <SellerProductsRequests />
            </ProtectedRoute>

            <Route
              exact
              path="/marketing-contactus"
              label="Marketing Contact Us"
            >
              <ContactUs />
            </Route>

            <ProtectedRoute
              exact
              path="/add-user-manager"
              label="Add User Manager"
            >
              <AddUserManager />
            </ProtectedRoute>

            <ProtectedRoute
              label="Send lead to NSH"
              exact
              path="/send-lead-to-nsh"
            >
              <AddLead />
            </ProtectedRoute>

            <ProtectedRoute label="Add Lead" exact path="/add-lead">
              <AddLead />
            </ProtectedRoute>

            <ProtectedRoute
              label="Get Leads" //Edit Lead
              exact
              path="/edit-lead"
            >
              <EditLead />
            </ProtectedRoute>

            <ProtectedRoute exact path="/send-leads-to-sales" label="Marketing">
              <SendLeadsToSales />
            </ProtectedRoute>

            <ProtectedRoute exact path="/add-prospect" label="Assigned Leads">
              <AddProspect />
            </ProtectedRoute>

            <ProtectedRoute exact path="/view-prospects" label="View Prospects">
              <ViewProspects />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/generate-rate-contract"
              label="Generate Rate Contract"
            >
              <GenerateRateContract />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/view-rate-contract"
              label="View Rate Contract"
            >
              <ViewRateContract />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/view-versions"
              label="View Rate Contract"
            >
              <ViewQuotation />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/social-media-leads"
              label="Social Media Leads"
            >
              <SocialMediaLeads />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/show-final-products"
              label="Show Final Products"
            >
              <ShowFinalProducts />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/show-se-requests"
              label="Show SE Requests"
            >
              <ShowSeRequests />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/client-asking-price-approval"
              label="Client Asking Price Approval"
            >
              <ClientAskingPriceApproval />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/client-asking-price-for-se"
              label="Client Asking Price For SE"
            >
              <ClientAskingPriceForSe />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/add-pan-products"
              label="Add PAN Products"
            >
              <AddPANProducts />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path="/add-new-product"
              label="Add New Product"
            >
              <AddNewProduct />
            </ProtectedRoute>

            {/* Added V2 */}
            {/* <ProtectedRoute exact path="/show-locations" label="Show Locations">
              <ShowLocations />
            </ProtectedRoute> */}

            <ProtectedRoute
              exact
              path="/missed-meetings"
              label="Missed Meetings"
            >
              <ShowMissedMeetings />
            </ProtectedRoute>
            <Route exact path="/get-all-leads">
              <GetAllLeads />
            </Route>

            <ProtectedRoute
              exact
              path="/franchise-divert-request"
              label="View Divert Franchise Request"
            >
              <DivertRequest />
            </ProtectedRoute>

            <ProtectedRoute exact path={CORE_PROFILES_VIEW} label="Get Leads">
              <GetProfiles />
            </ProtectedRoute>

            <Route exact path="/location-tracking">
              <LocationTracking />
            </Route>

            <DashboardRoute exact path="/dashboard">
              <Dashboard />
            </DashboardRoute>

            <Route exact path="/demo">
              <Demo />
            </Route>

            <Route exact path="/printing-image-upload">
              <ImageUpload />
            </Route>
            {/* approval from franchise */}

            <Route exact path="/approval-from-franchise">
              <ApprovalFromFranchise />
            </Route>

            {/* V2 Pages(new UI pages with new Routes) */}

            <ProtectedRoute exact path={CORE_BLOG_VIEW} label="View Blog">
              <BlogView />
            </ProtectedRoute>
            <ProtectedRoute exact path={CORE_CAREER_VIEW} label="View Career">
              <CareerView />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={CORE_CASE_STUDIES_VIEW}
              label="View Case Study"
            >
              <CaseStudyView />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={CORE_TESTIMONIALS_VIEW}
              label="View Testimonial"
            >
              <TestimonialView />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={CORE_VIEW_MARKETING_LINK_VIEW}
              label="Marketing"
            >
              <MarketingLinkView />
            </ProtectedRoute>

            <ProtectedRoute
              exact
              path={CORE_SHOW_LOCATIONS_VIEW}
              label="Show Locations"
            >
              <ShowLocations />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={CORE_ASSIGNED_LEADS_VIEW}
              label="Assigned Leads"
            >
              <AssignedLeads />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={CORE_PROSPECTS_VIEW}
              label="View Prospects"
            >
              <Prospects />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path={CORE_EMPLOYEES_VIEW}
              label="View Employees"
            >
              <ViewEmployees />
            </ProtectedRoute>

            {/* end */}

            <Route path="/permission-denied">
              <PermissionDenied />
            </Route>
            <Route exact path="/">
              <SuperAdminLogin getPrivileges={getPrivileges} />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </LocationProvider>
    </>
  );
};

export default AppRouter;
