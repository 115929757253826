import { Checkbox } from "@material-ui/core";
import React, { useContext } from "react";
import { RateContractContext } from "../../../common/RateContractProvider";

const SelectProduct = ({ city, product, price, onlyShow, isPAN }) => {
  const { selectedProducts, changePrice } = useContext(RateContractContext);

  return (
    <div>
      <Checkbox
        disabled={onlyShow}
        name="checkedC"
        checked={
          selectedProducts[city] &&
          selectedProducts[city].hasOwnProperty(product.id)
            ? true
            : false
        }
        onChange={(e) => {
          const finalPrice = isPAN ? product["maxPrice"] : product["finalPrice"];
          changePrice(product.id, price, city, e.target.checked, product.MRP, product["GST%"], finalPrice);
        }}
      />
    </div>
  );
};

export default SelectProduct;
