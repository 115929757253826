import React, { useEffect, useState } from "react";

import XLSX from "xlsx";
import Layout from "../../common/Layout";
import ProductsTable from "./Components/ProductsTable";
import Typography from "@material-ui/core/Typography";

import { Button, MenuItem, TextField } from "@material-ui/core";
import { ColorButton } from "../../common/Components/ColorButton";
import { getPageName } from "../../common/const";
import { getCitiesList } from "../../backend/getDataFromFirestore";
import { getFranchiseProductsCounter } from "../../backend/getCounters";

const Products = () => {
  let file = [];
  const [productData, setProductData] = useState([]);
  const [cities, setCities] = useState([]);
  const [title, setTitle] = useState("NexStoc Product Catalog");
  const [count, setCount] = useState(0);

  useEffect(() => {
    getCities();
    getFranchiseProductsCounter().then((c) => setCount(c));
  }, []);

  async function getCities() {
    const temp = await getCitiesList();
    setCities(temp);
  }

  const handleUpload = (event) => {
    file = event.target.files;
  };

  const handleData = async (e) => {
    if (file.length < 1) {
      console.log("Choose Correct File...");
      return;
    }

    let skuIds = {},
      counter = count;
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          const workbook = XLSX.read(reader.result, { type: "binary" });
          const firstSheet = workbook.SheetNames[0];
          let excelRows = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[firstSheet]
          );
          for (let row in excelRows) {
            counter++;
            excelRows[row]["SKU ID"] = "F" + ("000000000" + counter).substr(-9);
            excelRows[row]["STATUS"] = "pending";
            // if (skuIds[excelRows[row]["SKU ID"]]) {
            //   excelRows[row]["duplicate"] = true;
            // } else {
            //   excelRows[row]["duplicate"] = false;
            //   skuIds[excelRows[row]["SKU ID"]] = 1;
            // }
          }
          console.log(excelRows, "ecxel rows")
          setProductData(excelRows);
          setCount(counter);
        };
        reader.readAsBinaryString(file[0]);
      }
    } else {
      console.log("This browser does not support HTML5.");
    }
  };
  console.log(productData, "ecxel rows 2")

  return (
    <>
      <Layout />
      <div className=" m-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>
        <div className="row">
          <div className="col-12 col-md-12">
            <TextField
              required
              id="outlined-seller-aadhar"
              type="text"
              label="Enter Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={{ paddingBottom: "10px" }}
            />
            <br />
            {/* <Typography variant="h5" component="h2" align="center">
              Add Products
            </Typography> */}
            <>
              <div style={{ paddingBottom: "10px" }}>
                <input type="file" accept=".xlsx" onChange={handleUpload} />
                <span>
                  <ColorButton
                    size="small"
                    padding="10px"
                    variant="contained"
                    color="primary"
                    onClick={handleData}
                  >
                    Extract Data
                  </ColorButton>
                </span>
              </div>

              <ProductsTable
                productData={productData}
                productLength={productData.length}
                cities={cities}
                title={title}
                totalCounter={count}
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
