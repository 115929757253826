import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { SIDEBAR_ITEMS } from "common/const1";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
  sidebar: {
    color: "#909090",
    fontWeight: "500",
  },
  active_sidebar: {
    fontWeight: "500",
  },
}));

export function SidebarItem({ title }) {
  const location = useLocation();
  const { pathname } = location;
  const isActive = pathname?.split("/")[1] === SIDEBAR_ITEMS[title]?.key;
  const classes = useStyles(isActive);

  return (
    <ListItem
      button
      {...{
        component: RouterLink,
        to: SIDEBAR_ITEMS[title]?.pathname,
        color: "inherit",
        style: {
          textDecoration: "none",
          color: "black",
          paddingLeft: 16,
          borderLeft: "6px solid #FFFF",
          ...(isActive && { background: "#F5F6F8" }),
          ...(isActive && { borderLeft: "6px solid #FFBB00" }),
        },
      }}
    >
      <ListItemIcon className="sidebarIcon">
        {SIDEBAR_ITEMS[title]?.icon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            className={isActive ? classes.active_sidebar : classes.sidebar}
            variant="body1"
          >
            {SIDEBAR_ITEMS[title]?.title ?? title}
          </Typography>
        }
      />
    </ListItem>
  );
}
