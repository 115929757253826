import React, { useContext } from "react";
import ReactExport from "react-export-excel";
import { ColorButton } from "../../../common/Components/ColorButton";
import { RateContractContext } from "../../../common/RateContractProvider";

export const DownloadAsExcel = ({ version, isPAN, products, city }) => {
  const { selectedProducts } = useContext(RateContractContext);
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  return (
    <ExcelFile
      element={
        <ColorButton
          variant="contained"
          style={{ margin: "10px" }}
          color="secondary"
        >
          Excel Download
        </ColorButton>
      }
      filename={`${version}_${city}`}
    >
      <ExcelSheet data={products} name="Quotation">
        <ExcelColumn label="SKU ID" value="SKU ID" />
        <ExcelColumn label="Brand Name" value="Brand Name" />
        <ExcelColumn label="Product Name" value="Product Name" />
        <ExcelColumn label="Product Category" value="Product Category" />
        <ExcelColumn label="GST%" value={(col) => parseFloat(col["GST%"])} />
        <ExcelColumn label="MRP" value={(col) => parseFloat(col["MRP"])} />
        <ExcelColumn
          label="Nexstoc Price (Excl. GST)"
          value={(col) =>
            parseFloat(isPAN ? col["maxPrice"] : col["finalPrice"]).toFixed(2)
          }
        />
        {/* <ExcelColumn label="PM Price" value="productManagerPrice" /> */}
        <ExcelColumn
          label="Final Rate (Excl. GST)"
          value={(col) =>
            selectedProducts[city][col.id]
              ? parseFloat(selectedProducts[city][col.id]).toFixed(2)
              : " "
          }
        />
        <ExcelColumn
          label="Final Rate (Incl. GST)"
          value={(col) =>
            selectedProducts[city][col.id]
              ? parseFloat(
                  selectedProducts[city][col.id] +
                    selectedProducts[city][col.id] * +(col["GST%"] / 100)
                ).toFixed(2)
              : " "
          }
        />
      </ExcelSheet>
    </ExcelFile>
  );
};
