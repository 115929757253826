import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "100px",
  },
}))(TableRow);

export default function IndivisualFranchisePrice({ dialogInfo, handleClose }) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={dialogInfo.open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Franchise Prices</DialogTitle>
      <DialogContent dividers={true}>
        <p>Average Price : {dialogInfo.avg}</p>
        {/* {Object.keys(dialogInfo.data).map((item, index) => (
              <p key={index}>
                {dialogInfo.data[item]["name"]} : {dialogInfo.fPrices[item]}
              </p>
            ))} */}

        <TableContainer component={Paper}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "7em" }}>Franchise Name</TableCell>

                <TableCell style={{ width: "12em" }}>Franchise Email</TableCell>

                <TableCell style={{ width: "6em" }}>Franchise Phone</TableCell>

                <TableCell style={{ width: "8em" }}>
                  Franchise Price (Excl.GST)
                </TableCell>

                <TableCell style={{ width: "8em" }}>
                  Franchise Price (Incl.GST)
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(dialogInfo.data).map((item, index) => {
                let fData = dialogInfo.data[item];
                let showRed = dialogInfo.fPrices[item] > dialogInfo.avg;
                let showYellow = !dialogInfo.fPrices.hasOwnProperty(item);
                return (
                  <StyledTableRow
                    key={index}
                    className={
                      showRed
                        ? "backgroundRed"
                        : showYellow
                        ? "backgroundYellow"
                        : ""
                    }
                  >
                    <TableCell component="th" scope="row">
                      {fData["name"]}
                    </TableCell>

                    <TableCell>{fData["email"]}</TableCell>

                    <TableCell>{fData["mobile"]}</TableCell>

                    <TableCell>
                      {dialogInfo.fPrices.hasOwnProperty(item)
                        ? dialogInfo.fPrices[item] === 0
                          ? "NA"
                          : dialogInfo.fPrices[item]
                        : "Not Set"}
                    </TableCell>

                    <TableCell>
                      {dialogInfo.gst.hasOwnProperty(item)
                        ? dialogInfo.gst[item] === 0
                          ? "NA"
                          : +dialogInfo.fPrices[item] +
                            +(
                              dialogInfo.fPrices[item] *
                              (dialogInfo.gst[item] / 100)
                            )
                        : "Not Set"}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
