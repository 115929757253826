import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

export default function VideoModel({ openModel, closeModel, videoData }) {
  console.log(videoData);
  return (
    <div>
      <Dialog
        open={openModel}
        onClose={closeModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="slide-container">
            {videoData.length
              ? videoData?.map((slideVideoUrl, index) => (
                  <div className="each-slide" key={index}>
                    <div>
                      <video width="100%" height="100%" controls>
                        <source src={`${slideVideoUrl}`} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModel} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
