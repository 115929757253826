import swal from "sweetalert";
import {
  getBranchdataFromIds,
  getEstoresBranchIds,
  getFranchiseEstoresData,
} from "../../../backend/getDataFromFirestore";
import {
  discontinueAssignFranchise,
  discontinueFranchiseAndAllEstores,
} from "../../../backend/saveDataToFirestore";
import { discontinueFranchisee } from "../../../backend/sendRequestToServer";

import { ColorButton } from "../../../common/Components/ColorButton";

export const DiscontinueFranchise = ({ franchiseData }) => {
  async function handleUnblock() {
    swal(
      "Discontinue Franchise...",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    try {
      const assignedBranchObj = await getEstoresBranchIds(franchiseData);
      const branchData = await getBranchdataFromIds(assignedBranchObj);
      await discontinueAssignFranchise(branchData);
      await discontinueFranchiseAndAllEstores(franchiseData.id);
      const franchiseAndEstoreData = await getFranchiseEstoresData(
        franchiseData
      );
      for (let vendor of franchiseAndEstoreData) {
        await discontinueFranchisee({ email: vendor.email }); // connect to server
      }
      swal.close();
      swal("Success!", "Discontinued Franchise Successfully", "success").then(
        window.location.reload()
      );
    } catch (error) {
      console.log(error);
      swal("Error!", error, "error");
    }
  }
  return (
    <ColorButton
      variant="contained"
      style={{ margin: "10px" }}
      disabled={franchiseData.isDiscontinueFranchise}
      onClick={() => handleUnblock()}
    >
      Discontinue Franchise
    </ColorButton>
  );
};
