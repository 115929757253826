import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

const useStyles = makeStyles({
  desDetails: {
    height: 100,
    overflow: "auto",
  },
});
const ImagesField = ({ index, changeFileNames }) => {
  const classes = useStyles();
  const [fileNames, setFileNames] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    if (imagesData.length < 5) {
      changeFileNames(index, imagesData);
    } else {
      setError("Max 4 images for product");
    }
  }, [imagesData]);

  return (
    <div>
      <div className={classes.desDetails}>
        Show Names of files :
        {fileNames.map((file, i) => {
          return <div key={i}>{file.name}</div>;
        })}
      </div>

      <input
        type="file"
        name="productImages"
        accept="image/*"
        multiple
        onChange={(e) => {
          if (e.target.files[0]?.size <= 5000000) {
            if (imagesData.length < 4) {
              setImagesData([...imagesData, e.target.files[0]]);
              setFileNames([...fileNames, e.target.files[0]]);
            } else {
              swal("Failed!", "Max 4 images for product allowed!!!", "error");
              return;
            }
          } else {
            setError("Images size should be upto 5mb only");
          }
        }}
      />
      {error ? <>{error}</> : ""}
    </div>
  );
};

export default ImagesField;
