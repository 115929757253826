import React, { useEffect, useState } from 'react';
import { Media } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Layout from '../../../common/Layout';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import 'firebase/storage';
import {useHistory} from 'react-router-dom';
import {getPageName} from "././../../../common/const";

const ViewBlogComponent = () => {
    const [blogs, setBlogs] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [searchResult, setSearchResult] = useState([])
    const db = useFirestore();
    const storage = useFirebaseApp().storage();
    let history = useHistory();

    useEffect(() => {
        fetchBlogs();
    }, [])


    const useStyles = makeStyles((theme) => ({
        button: {
        margin: theme.spacing(1),
        },
    }));
    const classes = useStyles();

// function delete blogs

function DeleteBlog(id){
    console.log("this is ",id);
    db.collection("Blogs").doc(id).delete().then(() => {
        console.log("Document successfully deleted!");
        window.location.reload();

    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
}

// function edit blogs

function EditBlog(id){
    console.log("this is ",id);
    history.push('/edit-blog',id);
    
}


  // custom button

const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      color : 'white',
      fontWeight : 'bold',
      '&:hover': {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

    const fetchBlogs=async()=>{
        const response=db.collection('Blogs').orderBy("title");
        const data=await response.get();
        data.docs.forEach(item=>{
         setBlogs(blogs => [...blogs,item.data()])
         console.log(item.data())
        })
    }

    //  View selected blog btn click
    const handleViewBtn = (blog_detail) => {
        console.log(blog_detail);
        history.push('/blog-detail',blog_detail)
    }

    
    //  Search in textbox
    const searchBlogResult = (val) => {
        return blogs.filter(data => data.title.toLowerCase().includes(val.toLowerCase())|| data.author.toLowerCase().includes(val.toLowerCase()))
        
    }
    const search = (e) => {
        setSearchValue(e.target.value)
        console.log(e.target.value)
  
        setSearchResult(searchBlogResult(e.target.value))
    }
  
  

  return (
    <Layout>
        <div className="container mt-5 pt-5">
        <h2 style={{ textAlign:"center" }}>{getPageName()}</h2>
            <div className="row pb-3 justify-content-center"><input type="text"
             className="col-10 col-md-6 search-box" 
            placeholder = "Search Blog" onChange={e => {search(e)}}/></div>
            {blogs.length === 0 ? <div className="row justify-content-center">
            <p>No Blog's yet!</p>
        </div> : (<>
            {
                
                blogs.length > 0 && searchValue === '' ? blogs.map((blog)=>{
                return(

                    <Media list key={blog.id} className="blog-card">
                        <Media tag="li">
                            <Media left href="#">
                                <Avatar src={blog.downloadURL} alt={blog.author} 
                                style = {{
                                    minHeight: 100,
                                    minWidth: 100,
                                    marginRight: 8
                                }}/>
                            </Media>
                            <Media body>
                                <Media heading>
                                    {blog.title}
                                </Media>
                                <p className="author-name">~ {blog.author}</p>
                                {blog.short_desc}
                                <p className="view-edit">
                                    <IconButton aria-label="delete"  
                                    id={blog.id} 
                                    onClick = {(e) => DeleteBlog(blog.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" 
                                    id={blog.id} 
                                    onClick = {(e) => EditBlog(blog)}>
                                        <CreateIcon />
                                    </IconButton>
                                    <ColorButton
                                        variant="contained"
                                        className={classes.button}
                                        onClick={() => handleViewBtn(blog)}
                                    >View</ColorButton>
        
                                </p>
                            </Media>
                        </Media>
                    </Media>
                )}
                ) : (
                    <>
                        {searchResult.length > 0 ? searchResult.map(blog => {
                            return (
                            <Media list key={blog.id} className="blog-card">
                        <Media tag="li">
                            <Media left href="#">
                                <Avatar src={blog.downloadURL} alt={blog.author} 
                                style = {{
                                    minHeight: 100,
                                    minWidth: 100,
                                    marginRight: 8
                                }}/>
                            </Media>
                            <Media body>
                                <Media heading>
                                    {blog.title}
                                </Media>
                                <p className="author-name">~ {blog.author}</p>
                                {blog.short_desc}
                                <p className="view-edit">
                                    <IconButton aria-label="delete"  
                                    id={blog.id} 
                                    onClick = {(e) => DeleteBlog(blog.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" 
                                    id={blog.id} 
                                    onClick = {(e) => EditBlog(blog)}>
                                        <CreateIcon />
                                    </IconButton>
                                    <ColorButton
                                        variant="contained"
                                        className={classes.button}
                                        onClick={() => handleViewBtn(blog)}
                                    >View</ColorButton>
        
                                </p>
                            </Media>
                        </Media>
                    </Media>
                     )
                    }) : (
                      <>
                      {searchValue !== '' ? (

                      <div className="row pb-3 justify-content-center">
                      <div>
                     Search Not Found!</div>
       
                  </div>
                      ) : <div></div>
                      }
                    
                    </>
                    )
                    }
                    </>
                )}     
                </>
        )}      
        </div>
    </Layout>
  );
};

export default ViewBlogComponent;
