import React from "react";
import Marketing from "../Marketing";
import { ViewTestimonialTable } from "./ViewTestimonialTable";
import { useGetPermissions } from "context/PermissionsProvider";

function TestimonialView() {
  const { userPermissions } = useGetPermissions();

  const canViewTestimonial = userPermissions?.["Career"]?.View;

  return (
    <Marketing>
      {canViewTestimonial ? (
        <ViewTestimonialTable />
      ) : canViewTestimonial === false ? (
        <h1>You do not have permission to view testimonial</h1>
      ) : (
        "Loading..."
      )}
    </Marketing>
  );
}

export default TestimonialView;
