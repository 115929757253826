export const OPEN_MODAL_TITLE = {
  ADD_BLOG: "Add Blog",
  ADD_CAREER: "Add Career",
  ADD_CASE_STUDY: "Add Case Study",
  ADD_TESTIMONIAL: "Add Testimonial",
  ADD_MARKETING_LINK: "Add Marketing Link",
  ADD_EMPLOYEE: "Add Employee",
  ADD_PROFILE: "Add Profile",

  EDIT_BLOG: "Edit Blog",
  EDIT_CAREER: "Edit Career",
  EDIT_CASE_STUDY: "Edit Case Study",
  EDIT_TESTIMONIAL: "Edit Testimonial",
  EDIT_MARKETING_LINK: "Edit Marketing Link",
  EDIT_EMPLOYEE: "Edit Employee",
  EDIT_PROFILE: "Edit Profile",
};
