import React from 'react';
import { useLocation } from 'react-router';
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Avatar from '@material-ui/core/Avatar';

import Layout from "../../../common/Layout";


const useStyles = makeStyles({
    root: {
      // minWidth: 275,
    //   margin: "15px 15px 15px 15px",
      borderRadius : '10px',
      padding : '10px',
      display : "flex",
      flexDirection : 'column',
      justifyContent : 'center',
      textAlign : 'center',
      textAlign: 'justify',
      boxShadow: '0 3px 5px rgba(0,0,0,0.12), 0 3px 3px rgba(0,0,0,0.15)',
      fontFamily : 'Roboto',
  
    },
    blog_desc : {
        
      justifyContent : 'center',
      textAlign : 'center',
      textAlign: 'justify',
      textIndent: '70px',
       marginTop: '5px'
    },
    authorDetail : {
        marginTop : '10px',
        padding : '10px',
        fontFamily : 'Roboto',
    },
    author : {
        marginTop : '10px',
        display : 'flex',
        flexDirection : 'row',
        fontSize : '18px',
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

function ViewBlogDetailComponent () {
    const location = useLocation();
    const classes = useStyles();
    let blog = location.state;
  console.log("selected blog : => ",location.state);
    return (
        <>
        <Layout />
            <div className="container mt-5 pt-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-6">
              <Card className={classes.root} variant="outlined">
                <CardContent>
                <Typography variant="h5" component="h2" style={{textAlign: 'center'}}>
                    {blog.title}
                </Typography>
                    <p className={classes.blog_desc}>{blog.long_desc}</p>
                </CardContent>
                
              </Card>
              <div className={classes.authorDetail}>
                <Typography variant="h5" component="h2">
                    About the Author
                </Typography>
                <div className={classes.author}>
                    <Avatar src={blog.downloadURL} alt={blog.author} 
                        style = {{
                            minHeight: 100,
                            minWidth: 100,
                            marginRight: 8
                    }}/>
                    <p>{blog.author}<br /><span className={classes.title}>{blog.short_desc}</span></p>
                </div>
            </div>
            </div>
            </div>
            </div>
        </>
    )
}

export default ViewBlogDetailComponent;