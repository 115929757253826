import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import swal from "sweetalert";
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import { useHistory } from "react-router-dom";
import { deleteSalesUser } from "../../backend/sendRequestToServer";
import firebase from "firebase/app";
import { getPageName } from "././../../common/const";

const ViewSE = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  let history = useHistory();
  var tempSE = "";
  var emailtest = "";
  const cshDocId = localStorage.getItem("userDocId"); //CSH Doc Id

  useEffect(() => {
    fetchBlogs();
  }, []);

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleReset = () => {
    window.location.reload();
  };

  // function delete blogs
  async function DeleteSE(id, email) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this employee!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: "Deleting the user. Do not Refresh",
          icon: "warning",
          buttons: false,
          dangerMode: true,
        });

        console.log("email", email);

        const res = await deleteSalesUser({
          email: email,
          id: id,
        });

        if (res.status === 200) {
          swal("Success", "Employee Successfully Deleted!!!", "success").then(
            (value) => handleReset()
          );
        } else {
          swal(
            "Error",
            "Error Occur while Deleting. Please Try Again.",
            "error"
          );
        }
      } else {
      }
    });

    //aDD SWAL CODE below
  }

  // function edit blogs

  function EditSE(id) {
    console.log("this is ", id);
    history.push("/edit-se", id);
  }

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const fetchBlogs = async () => {
    const myRole = localStorage.getItem("role");
    let response;
    if (myRole === "User Manager" || myRole === "Super Admin") {
      response = db
        .collection("Users")
        .where("designation", "==", "Sales Executive")
        .where("userDeleted", "==", false);
    } else {
      response = db
        .collection("Users")
        .where("userDeleted", "==", false)
        .where("createdby", "==", cshDocId);
    }

    const data = await response.get();
    let seList = [];
    data.docs.forEach((item) => {
      seList.push(item.data());
      console.log(item.data());
    });

    setBlogs(seList);
  };

  // redirect to add csh
  const handleAddSE = () => {
    history.push("/add-se");
  };
  //  View selected blog btn click
  const handleViewBtn = (blog_detail) => {
    console.log(blog_detail);
    history.push("/blog-detail", blog_detail);
  };

  //  Search in textbox
  const searchBlogResult = (val) => {
    return blogs.filter((data) =>
      data.name.toLowerCase().includes(val.toLowerCase())
    );
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);

    setSearchResult(searchBlogResult(e.target.value));
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>

        <div className="row pb-3 justify-content-center">
          <input
            type="text"
            className="col-10 col-md-6 search-box"
            placeholder="Search Sales Executive"
            onChange={(e) => {
              search(e);
            }}
          />
        </div>
        {blogs.length === 0 ? (
          <>
            <div className="row justify-content-center">
              <p style={{ marginBottom: "10px" }}>No Sales Executive yet!</p>
            </div>
            <div className="row justify-content-center">
              <ColorButton onClick={handleAddSE}>Add SE</ColorButton>
            </div>{" "}
          </>
        ) : (
          <>
            {blogs.length > 0 && searchValue === "" ? (
              blogs.map((blog) => {
                return (
                  <Media list key={blog.id} className="blog-card">
                    <Media tag="li">
                      {/* <Media left href="#">
                            <Avatar src={blog.downloadURL} alt={blog.author} 
                            style = {{
                                minHeight: 100,
                                minWidth: 100,
                                marginRight: 8
                            }}/>
                        </Media> */}

                      <Media body>
                        <Media heading>{blog.name}</Media>
                        <p className="author-name">Email : {blog.email}</p>
                        <p>Phone: {blog.phone}</p>
                        <p>Designation: {blog.designation}</p>
                        <p>Priviledges: {blog.privileges.toString()}</p>

                        <p className="view-edit">
                          <IconButton
                            aria-label="delete"
                            id={blog.id}
                            onClick={(e) => DeleteSE(blog.id, blog.email)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {/* <IconButton aria-label="delete" 
                                id={blog.id} 
                                onClick = {(e) => EditBlog(blog)}>
                                    <CreateIcon />
                                </IconButton> */}
                          <ColorButton
                            variant="contained"
                            className={classes.button}
                            onClick={() => EditSE(blog)}
                          >
                            {" "}
                            <IconButton
                              aria-label="delete"
                              id={blog.id}
                              onClick={(e) => EditSE(blog)}
                            >
                              <CreateIcon />
                            </IconButton>
                          </ColorButton>
                        </p>
                      </Media>
                    </Media>
                  </Media>
                );
              })
            ) : (
              <>
                {searchResult.length > 0 ? (
                  searchResult.map((blog) => {
                    return (
                      <Media list key={blog.id} className="blog-card">
                        <Media tag="li">
                          {/* <Media left href="#">
                        <Avatar src={blog.downloadURL} alt={blog.author} 
                        style = {{
                            minHeight: 100,
                            minWidth: 100,
                            marginRight: 8
                        }}/>
                    </Media> */}

                          <Media body>
                            <Media heading>{blog.name}</Media>
                            <p className="author-name">Email : {blog.email}</p>
                            <p>Phone: {blog.phone}</p>
                            <p>Designation: {blog.designation}</p>
                            <p>Priviledges: {blog.privileges.toString()}</p>

                            <p className="view-edit">
                              <IconButton
                                aria-label="delete"
                                id={blog.id}
                                onClick={(e) => DeleteSE(blog.id, blog.email)}
                              >
                                <DeleteIcon />
                              </IconButton>
                              {/* <IconButton aria-label="delete" 
                            id={blog.id} 
                            onClick = {(e) => EditBlog(blog)}>
                                <CreateIcon />
                            </IconButton> */}
                              <ColorButton
                                variant="contained"
                                className={classes.button}
                                onClick={() => EditSE(blog)}
                              >
                                View Sales Executive
                              </ColorButton>
                            </p>
                          </Media>
                        </Media>
                      </Media>
                    );
                  })
                ) : (
                  <>
                    {searchValue !== "" ? (
                      <div className="row pb-3 justify-content-center">
                        <div>Search Not Found!</div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ViewSE;
