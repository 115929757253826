import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import swal from "sweetalert";
import {getPageName} from "././../../common/const";
import { useHistory } from "react-router-dom";
import { deleteUser } from "../../backend/sendRequestToServer";

const ViewSeller = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchValue, setSearchValue] = useState('')
    const [searchResult, setSearchResult] = useState([])
    
  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  let history = useHistory();

  useEffect(() => {
    fetchBlogs();
  }, []);

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleReset = () => {
    window.location.reload();
  };

  // function delete EStore

  async function DeleteSeller(id, email, sellerCode) {

    
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Seller!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async(willDelete) => {
      if (willDelete) {
          swal({
              title: "Deleting the seller. Do not Refresh",
              icon: "warning",
              buttons: false,
              dangerMode: true,
            })

          console.log("email",email);
          
        
         
              const res = await deleteUser(
                {
                  "email": email, 
                  "collection": "Sellers", 
                  "storagePath": "seller-documents",
                  "branchCode": sellerCode,
                }
              );
        

            if(res.status === 200) {
        
              swal("Success","Seller Successfully Deleted!!!","success").then((value) => handleReset());
            }else {
            
              swal("Error","Error Occur while Deleting. Please Try Again.","error");
            }
      } else {
       
      }
    });



   
  }

  // function edit blogs

  function EditSeller(data) {
    history.push("/add-seller", data);
  }

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const fetchBlogs = async () => {
    const response = db.collection("Sellers").orderBy("companyName");
    const data = await response.get();
    data.docs.forEach((item) => {
      setBlogs((blogs) => [...blogs, item.data()]);
      console.log(item.data());
    });
  };

  //  View selected blog btn click
  const ViewSeller = (blog_detail) => {
    console.log(blog_detail);
    history.push("/ViewSellerDetails", blog_detail);
  };

  //  Search in textbox
  const searchBlogResult = (val) => {
    return blogs.filter(data => data.companyName.toLowerCase().includes(val.toLowerCase()) || data.city.toLowerCase().includes(val.toLowerCase()) || data.sellerCode.toLowerCase().includes(val.toLowerCase()))
    
}
const search = (e) => {
    setSearchValue(e.target.value)
    console.log(e.target.value)

    setSearchResult(searchBlogResult(e.target.value))
}



  return (
    <Layout>
      <div className="container mt-5 pt-5">
      <h2 style={{ textAlign:"center" }}>{getPageName()}</h2>
      <div className="row pb-3 justify-content-center"><input type="text"
             className="col-10 col-md-6 search-box" 
            placeholder = "Search Seller" onChange={e => {search(e)}}/></div>
            {blogs.length === 0 ? <div className="row justify-content-center">
            <p>No Seller's yet!</p>
        </div> : (<>
            {
                
                blogs.length > 0 && searchValue === '' ? blogs.map((blog)=>{
                return (
              <Media list key={blog.id} className="blog-card">
                <Media tag="li">
                  {/* <Media left href="#">
                            <Avatar src={blog.downloadURL} alt={blog.author} 
                            style = {{
                                minHeight: 100,
                                minWidth: 100,
                                marginRight: 8
                            }}/>
                        </Media> */}
                  <Media body>
                    <Media heading>{blog.companyName}</Media>
                    <p className="author-name">{blog.sellerCode}</p>
                    <p>City: {blog.city}</p>
                    <p>Type of Enterprise: {blog.typeEnterprise}</p>
                    <p className="view-edit">
                      <IconButton
                        aria-label="delete"
                        id={blog.id}
                        onClick={(e) =>
                          DeleteSeller(blog.id, blog.email, blog.sellerCode)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        id={blog.id}
                        onClick={(e) => EditSeller(blog)}
                      >
                        <CreateIcon />
                      </IconButton>
                      <ColorButton
                        variant="contained"
                        className={classes.button}
                        onClick={() => ViewSeller(blog)}
                      >
                        View Seller
                      </ColorButton>
                    </p>
                  </Media>
                </Media>
              </Media>
            )}
            ) : (
                <>
                    {searchResult.length > 0 ? searchResult.map(blog => {
                      return (
                        <Media list key={blog.id} className="blog-card">
                          <Media tag="li">
                            {/* <Media left href="#">
                                      <Avatar src={blog.downloadURL} alt={blog.author} 
                                      style = {{
                                          minHeight: 100,
                                          minWidth: 100,
                                          marginRight: 8
                                      }}/>
                                  </Media> */}
                            <Media body>
                              <Media heading>{blog.companyName}</Media>
                              <p className="author-name">{blog.sellerCode}</p>
                              <p>City: {blog.city}</p>
                              <p>Type of Enterprise: {blog.typeEnterprise}</p>
                              <p className="view-edit">
                                <IconButton
                                  aria-label="delete"
                                  id={blog.id}
                                  onClick={(e) =>
                                    DeleteSeller(blog.id, blog.email, blog.sellerCode)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  id={blog.id}
                                  onClick={(e) => EditSeller(blog)}
                                >
                                  <CreateIcon />
                                </IconButton>
                                <ColorButton
                                  variant="contained"
                                  className={classes.button}
                                  onClick={() => ViewSeller(blog)}
                                >
                                  View Seller
                                </ColorButton>
                              </p>
                            </Media>
                          </Media>
                        </Media>
              )
            }) : (
              <>
              {searchValue !== '' ? (

              <div className="row pb-3 justify-content-center">
              <div>
             Search Not Found!</div>

          </div>
              ) : <div></div>
              }
            
            </>
            )
            }
            </>
        )}   
            </>
        )}
      </div>
    </Layout>
  );
};

export default ViewSeller;
