import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import FinalPriceField from "./FinalPriceField";
import { ColorButton } from "../../../common/Components/ColorButton";
import {
  addFranchiseArray,
  saveCityCounter,
  saveProductsApprovalsData,
  sendProductRequestforApproval,
} from "../../../backend/saveDataToFirestore";
import {
  editFinalProductRate,
  updateSellerProductsData,
} from "../../../backend/editDataInFirestore";
import swal from "sweetalert";
import { saveFinalProducts } from "../../../backend/sendRequestToServer";
import {
  getSellerProductsCounter,
  incrementSellerProductsCount,
} from "../../../backend/getCounters";
import { getFranchisePendingProductDataOfCity } from "../../../backend/getDataFromFirestore";
import ImageModel from "../../../common/ImageDialog";
import VideoModel from "../../../common/VideoDialog";
import { prospectSortStatus } from "../../../common/const";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "100px",
  },
}))(TableRow);

const useStyles = makeStyles({
  container: {
    maxHeight: "75vh",
  },
  desDetails: {
    height: 100,
    overflow: "auto",
  },
});

const DialogHelper = () => {
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    data: {},
    fPrices: {},
    avg: 0,
    gst: {},
  });

  function closeDialog() {
    setDialogInfo({ ...dialogInfo, open: false, fPrices: {}, avg: 0, gst: {} });
  }

  function openDialog(data, fPrices, avg, gst) {
    setDialogInfo({
      data: data,
      open: true,
      fPrices: fPrices,
      avg: avg,
      gst: gst,
    });
  }

  return { dialogInfo, openDialog, closeDialog };
};

const ProductsTable = ({ productData, productLength, city, franchiseData }) => {
  const rates = React.useRef(null);
  const [avg, setAvg] = useState([]);
  const [productGST, setProductGST] = useState({});

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");

  const [openModel, setOpen] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  const [dialogImages, setDialogImages] = useState([]);
  const [dialogVideo, setDialogVideo] = useState([]);

  const { dialogInfo, openDialog, closeDialog } = DialogHelper();
  const classes = useStyles();

  function getAvgPrice(product) {
    let sum = 0,
      count = 0,
      avg = 0;
    if (product["franchise"]) {
      for (const fKey of Object.keys(product["franchise"])) {
        sum += product["franchise"][fKey];
        if (product["franchise"][fKey] > 0) count++;
      }

      avg = sum > 0 && count > 0 ? sum / count : 0;

      console.log("Average is ", avg);
    }

    return parseFloat(avg).toFixed(2);
  }

  useEffect(() => {
    rates.current = new Array(productLength).fill();
    let avgTemp = new Array(productLength).fill();
    for (let i = 0; i < productLength; i++) {
      avgTemp[i] = getAvgPrice(productData[i]);
      rates.current[i] = avgTemp[i] ?? undefined;
    }
    setAvg(avgTemp);
  }, [productLength]);

  useEffect(() => {
    setSearchResult(productData);
  }, [productData]);

  const openImageModel = (data) => {
    setOpen(true);
    setDialogImages(data);
  };
  const openVideoModel = (data) => {
    setVideoOpen(true);
    setDialogVideo(data);
  };
  const closeModel = () => {
    setOpen(false);
    setVideoOpen(false);
    setDialogImages([]);
    setDialogVideo([]);
  };
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    setSearchResult(searchLeadResult(searchValue, status));
  };

  const search = (e) => {
    setSearchValue(e.target.value);
    const data = searchLeadResult(e.target.value, "");
    setSearchResult([...data]);
  };
  const searchLeadResult = (name, status) => {
    // console.log(name, status, searchResult);
    const productss = productData;
    if (status === "SKU ID Ascending") {
      productss.sort((a, b) => compareAB(a["SKU ID"], b["SKU ID"]));
    } else if (status === "SKU ID Descending") {
      productss.sort((a, b) => -1 * compareAB(a["SKU ID"], b["SKU ID"]));
    }
    return productss.filter((lead) => {
      if (name && status) {
        return lead["Brand Name"].toLowerCase().includes(name.toLowerCase());
      }
      if (name) {
        return (
          lead["Brand Name"].toLowerCase()?.includes(name?.toLowerCase()) ||
          lead["Product Category"]
            ?.toLowerCase()
            ?.includes(name?.toLowerCase()) ||
          lead["Product Sub-Category"]
            ?.toLowerCase()
            ?.includes(name?.toLowerCase()) ||
          lead["Product Name"]?.toLowerCase()?.includes(name?.toLowerCase())
        );
      }
      return true;
    });
  };
  function compareAB(a, b) {
    var nameA = a ? a.toString().toUpperCase() : ""; // ignore upper and lowercase
    var nameB = b ? b.toString().toUpperCase() : ""; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  console.log(rates.current, "rates current");
  async function updateProductRates() {
    for (let i = 0; i < productLength; i++) {
      let product = productData[i];
      product["finalPrice"] = rates.current[i];
      if (rates.current[i] === undefined) {
        return;
      }
      const res = await editFinalProductRate(city, product);
      if (!res.successfull) return false;
    }
    return true;
  }
  async function addProductToApproval() {
    for (let i = 0; i < productLength; i++) {
      let product = productData[i];
      product["STATUS"] = "franchise-pending";
      if (rates.current[i] !== undefined) {
        product["finalPrice"] = rates.current[i];
      } else {
        product["finalPrice"] = avg[i];
      }
      console.log(product, "product query");
      const res = await sendProductRequestforApproval(city, product);
      if (!res) {
        return false;
      }
    }
    return true;
  }

  const updateData = async (event) => {
    const swalRes = await swal(
      "Update Final Product Rates",
      "Are you sure you want to update Final Product Rates",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Update",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    swal(
      "Updating Final Product Rates",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    console.log("Update Rates");
    console.log(productData, rates.current);

    // TODO : Update Rates of each Product set by each franchise on Firestore
    //        FranchiseProductApprovals > CityName
    await updateProductRates();
    swal.close();

    swal("Success!", "Prices Updated", "success");
  };

  const saveFinalPrice = async (event) => {
    console.log(rates);

    for (let i = 0; i < productLength; i++) {
      if (!rates.current[i] || rates.current[i] <= 0) {
        swal("Error!", "Pleas make sure all prices are filled", "error");
        return;
      }
      // const res = await saveProductsApprovalsData(city, product);
    }

    const swalRes = await swal(
      "Are you sure you want to save Final Product Rates",
      "Please make sure all prices are correct. You will not be able to change prices again.",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    swal(
      "Saving Final Product Rates",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    console.log("Saving Rates");
    console.log(productData, rates.current);

    await updateProductRates(); //Updating Last time for safety

    const res = await saveFinalProducts({ city: city });

    swal.close();

    if (res.status === 200) {
      swal("Success!", "Prices Updated", "success").then((val) =>
        window.location.reload()
      );
    } else {
      swal("Error!", "Something went wrong", "error");
    }
  };

  // save partail func
  const savePartialPrice = async () => {
    // console.log(rates)
    // for (let i = 0; i < productLength; i++) {
    //   if (!rates.current[i] || rates.current[i] <= 0) {
    //     swal("Error!", "Please make sure atleast one price to be updated", "error");
    //     return;
    //   }
    // }
    const swalRes = await swal(
      "Save Partial Product Price",
      "Are you sure you want to save Partial Products Price",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Update",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    swal(
      "Saving Parial Products Price",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    // api query
    let res = await updateProductRates();

    // if return true
    if (!res) {
      swal("Error!", "Something went wrong", "error");
    }

    swal("Success!", "Prices Updated", "success");
    window.location.reload();
  };

  // send for approval

  const sendApproval = async () => {
    if (!city) {
      swal("Error", "Please Select City First", "error");
      return;
    }
    if (!productLength) {
      swal("Error", "Please get data then send to franchise", "error");
      return;
    }
    const { products } = await getFranchisePendingProductDataOfCity(city);
    if (products.length) {
      swal(
        "Error",
        "There is already products for approval. Approve them first",
        "error"
      );
      return;
    }
    var swalRes;

    let counter = 0;

    rates.current &&
      rates.current.filter((item) =>
        item === 0 || item === undefined ? counter++ : ""
      );

    if (counter > 0) {
      swalRes = await swal(
        "Check All the Products!",
        "All prices are not filled are you sure you want to continue ?",
        "warning",
        {
          buttons: {
            cancel: "Cancel",
            catch: {
              text: "Update",
              value: true,
            },
          },
        }
      );
    } else {
      swalRes = await swal(
        "Send Product For Approval",
        "Are you sure you want to send Products to Franchises for Approval",
        "warning",
        {
          buttons: {
            cancel: "Cancel",
            catch: {
              text: "Update",
              value: true,
            },
          },
        }
      );
    }
    if (!swalRes) {
      return;
    }
    swal(
      "Sending Products for Approval",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    let res = await addProductToApproval();
    if (res) {
      swal("Success!", "Products Sent for Approval !", "success").then(() => {
        window.location.reload();
      });
    }
  };

  const changeRate = (index, val) => {
    rates.current[index] = isNaN(val) ? undefined : val;
    // console.log(rates.current);
  };

  const viewFranPrices = (product, index) => {
    if (product["franchise"]) {
      Object.keys(product["franchise"]).map(
        (keyy) => (productGST[keyy] = product["GST%"])
      );
      setProductGST(productGST);
      openDialog(franchiseData, product["franchise"], avg[index], productGST);
    } else openDialog(franchiseData, {}, avg[index], {});
  };

  useEffect(() => {
    productData.map((item, index) => {
      changeRate(index, item.finalPrice);
    });
  }, [productData]);

  return (
    <div>
      <br />
      <br />
      {/* <ColorButton variant="contained" color="primary" onClick={updateData}>
        Update Data
      </ColorButton>{" "}
      &nbsp; */}
      <ColorButton
        variant="contained"
        color="primary"
        onClick={savePartialPrice}
      >
        Partial Save
      </ColorButton>
      &nbsp;
      {/* <ColorButton variant="contained" color="primary" onClick={saveFinalPrice}>
        Save Final Price
      </ColorButton>
      &nbsp; */}
      <ColorButton variant="contained" color="primary" onClick={sendApproval}>
        Send for Franchise
      </ColorButton>
      <div>
        <div style={{ margin: "10px 0px" }}>
          <input
            type="text"
            className="search-box col-12 col-md-8"
            placeholder="Search Product Name, Category, Sub-Category, Brand"
            onChange={(e) => {
              search(e);
            }}
          />
        </div>
      </div>
      <div className="col col-md-2 mt-2 float-right">
        <TextField
          required
          id="outlined-indian-state"
          select
          label="Status"
          // value={employee.indianState}
          // onClick={AssignDrop()}
          value={filterStatus}
          onChange={(e) => onFilter(e.target.value)}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: "11ch", float: "right", verticalAlign: "center" }}
        >
          {prospectSortStatus.map((val, index) => (
            <MenuItem key={val.value} value={val.value}>
              {val.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <TableContainer className={classes.container} component={Paper}>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "10em" }}>SKU Id</TableCell>

              <TableCell style={{ width: "10em" }}>Brand</TableCell>

              <TableCell style={{ width: "10em" }}>Product Category</TableCell>

              <TableCell style={{ width: "10em" }}>
                Product Sub-Category
              </TableCell>

              <TableCell style={{ width: "10em" }}>Product Name</TableCell>

              <TableCell style={{ width: "10em" }}>Description</TableCell>

              <TableCell style={{ width: "20em" }}>
                Description Details
              </TableCell>

              <TableCell style={{ width: "20em" }}>Product Image</TableCell>

              <TableCell style={{ width: "20em" }}>Product Video</TableCell>

              <TableCell style={{ width: "10em" }}>UNIT</TableCell>

              <TableCell style={{ width: "10em" }}>GST%</TableCell>

              <TableCell style={{ width: "10em" }}>HSN CODE</TableCell>

              <TableCell style={{ width: "10em" }}>MRP</TableCell>

              <TableCell style={{ width: "10em" }}>Average Price</TableCell>

              <TableCell style={{ width: "10em" }}>
                View Individual Franchise Prices
              </TableCell>

              <TableCell style={{ width: "10em" }}>
                Final Price (Excl.GST)
              </TableCell>

              <TableCell style={{ width: "10em" }}>
                Final Price (Incl.GST)
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {productData === undefined ? (
              <p>There is No product.</p>
            ) : !city && searchValue ? (
              <p>Please Select City</p>
            ) : searchValue && searchResult.length === 0 ? (
              <div className="row pb-3 justify-content-center">
                <TableCell style={{ marginTop: "10px" }}>
                  Search Not Found!
                </TableCell>
              </div>
            ) : (
              searchResult.map((p, index) => {
                return (
                  <StyledTableRow key={index}>
                    <TableCell component="th" scope="row">
                      {p["SKU ID"]}
                    </TableCell>

                    <TableCell>{p["Brand Name"]}</TableCell>

                    <TableCell>{p["Product Category"]}</TableCell>

                    <TableCell>{p["Product Sub-Category"]}</TableCell>

                    <TableCell>{p["Product Name"]}</TableCell>

                    <TableCell>
                      <div className={classes.desDetails}>
                        {p["Description"]}
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className={classes.desDetails}>
                        {p["Description Details"]}
                      </div>
                    </TableCell>

                    <TableCell>
                      {p["Product Images"] && p["Product Images"][0] ? (
                        <img
                          onClick={() => {
                            openImageModel(p["Product Images"]);
                          }}
                          width="50%"
                          src={`${p["Product Images"][0]}`}
                        />
                      ) : (
                        <img width="50%" src={`${p["Product Image"]}`} />
                      )}
                    </TableCell>

                    <TableCell>
                      {p["Product Video"].length && (
                        <ColorButton
                          onClick={() => {
                            openVideoModel(p["Product Video"]);
                          }}
                        >
                          View Video
                        </ColorButton>
                      )}
                    </TableCell>

                    <TableCell>{p["UNIT"]}</TableCell>

                    <TableCell>{p["GST%"]}%</TableCell>

                    <TableCell>{p["HSN CODE"]}</TableCell>

                    <TableCell>{Number(p["MRP"]).toFixed(2)}</TableCell>

                    <TableCell>
                      {avg[index] === 0 ? "NA" : avg[index]}
                    </TableCell>

                    <TableCell>
                      <ColorButton onClick={() => viewFranPrices(p, index)}>
                        View
                      </ColorButton>
                    </TableCell>
                    {
                      <FinalPriceField
                        index={index}
                        changeRate={changeRate}
                        price={p["finalPrice"]}
                        avg={avg[index]}
                        product={p}
                      />
                    }
                  </StyledTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ImageModel
        openModel={openModel}
        closeModel={closeModel}
        imagesData={dialogImages}
      />
      <VideoModel
        openModel={videoOpen}
        closeModel={closeModel}
        videoData={dialogVideo}
      />
      <FranchiseDialog dialogInfo={dialogInfo} handleClose={closeDialog} />
    </div>
  );
};

export default ProductsTable;

const FranchiseDialog = ({ dialogInfo, handleClose }) => {
  // console.log(dialogInfo);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={dialogInfo.open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Franchise Prices</DialogTitle>
      <DialogContent dividers={true}>
        <p>Average Price : {dialogInfo.avg}</p>
        {/* {Object.keys(dialogInfo.data).map((item, index) => (
          <p key={index}>
            {dialogInfo.data[item]["name"]} : {dialogInfo.fPrices[item]}
          </p>
        ))} */}

        <TableContainer component={Paper}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "7em" }}>Franchise Name</TableCell>

                <TableCell style={{ width: "12em" }}>Franchise Email</TableCell>

                <TableCell style={{ width: "6em" }}>Franchise Phone</TableCell>

                <TableCell style={{ width: "8em" }}>
                  Franchise Price (Excl.GST)
                </TableCell>

                <TableCell style={{ width: "8em" }}>
                  Franchise Price (Incl.GST)
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(dialogInfo.data).map((item, index) => {
                let fData = dialogInfo.data[item];
                let showRed = dialogInfo.fPrices[item] > dialogInfo.avg;
                let showYellow = !dialogInfo.fPrices.hasOwnProperty(item);
                return (
                  <StyledTableRow
                    key={index}
                    className={
                      showRed
                        ? "backgroundRed"
                        : showYellow
                        ? "backgroundYellow"
                        : ""
                    }
                  >
                    <TableCell component="th" scope="row">
                      {fData["name"]}
                    </TableCell>

                    <TableCell>{fData["email"]}</TableCell>

                    <TableCell>{fData["mobile"]}</TableCell>

                    <TableCell>
                      {dialogInfo.fPrices.hasOwnProperty(item)
                        ? dialogInfo.fPrices[item] === 0
                          ? "NA"
                          : dialogInfo.fPrices[item]
                        : "Not Set"}
                    </TableCell>

                    <TableCell>
                      {dialogInfo.gst.hasOwnProperty(item)
                        ? dialogInfo.gst[item] === 0
                          ? "NA"
                          : +dialogInfo.fPrices[item] +
                            +(
                              dialogInfo.fPrices[item] *
                              (dialogInfo.gst[item] / 100)
                            )
                        : "Not Set"}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
