import React, { useState } from "react";
import { makeStyles, TableCell, TextField } from "@material-ui/core";

const useStyles = makeStyles({
  showRedBg: {
    display: "inline-block",
    backgroundColor: "red",
    height: "100%",
  },
});

const FinalPriceField = ({ index, changeRate, price = 0, product }) => {
  const [rate, setRate] = useState(price);
  const classes = useStyles();

  return (
    <>
      <TableCell>
        {product["finalPrice"] ?? (
          <div
            className={
              product["MRP"] !== 0 &&
              (1 + product["GST%"] / 100) * rate > product["MRP"]
                ? classes.showRedBg
                : ""
            }
          >
            <TextField
              type="Number"
              onWheel={() => document.activeElement.blur()}
              size="small"
              label="Set Final Price"
              variant="outlined"
              value={rate}
              onChange={(e) => {
                if(/^\d+(\.\d{0,2})?$/.test(e.target.value)){
                const val = e.target.valueAsNumber;
                const finalVal = isNaN(val) || val <= 0 ? 0 : val;
                changeRate(index, finalVal);
                setRate(finalVal);
                }
              }}
            />
          </div>
        )}
      </TableCell>
      <TableCell>{Number(+rate + +(rate * (product["GST%"] / 100))).toFixed(2)}</TableCell>
    </>
  );
};

export default FinalPriceField;
