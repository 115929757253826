import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AddProfile from "../../AddProfile/AddProfile";

const useStyles = makeStyles({
  fullList: {
    width: "640px",
    height: "1016px",
    paddingTop: "54px",
    paddingLeft: "46px",
    paddingRight: "54px",
    paddingBottom: "50px",
  },
});

export default function AddNewProfile({ open, onClose }) {
  const classes = useStyles();

  const customClass = `container mt-5 ${classes.fullList}`;

  return (
    <div>
      <React.Fragment key={"right"}>
        <SwipeableDrawer anchor={"right"} open={open} onClose={onClose}>
          <div className={customClass}>
            <AddProfile handleDrawerClose={onClose} />
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
