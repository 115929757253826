import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MaterialTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  MenuItem,
} from "@material-ui/core";
import FinalPriceField from "./FinalPriceField";
import { ColorButton } from "../../../common/Components/ColorButton";
import {
  saveCityCounter,
  saveProductsApprovalsData,
} from "../../../backend/saveDataToFirestore";
import {
  editFinalProductRate,
  editFinalProductsOfCity,
} from "../../../backend/editDataInFirestore";
import swal from "sweetalert";
import { saveFinalProducts } from "../../../backend/sendRequestToServer";
import CreateIcon from "@material-ui/icons/Create";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import {
  getFInalProducts,
  getPanLevelProducts,
} from "../../../backend/getDataFromFirestore";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "100px",
  },
}))(TableRow);

const useStyles = makeStyles({
  container: {
    maxHeight: "75vh",
  },
  desDetails: {
    height: 100,
    overflow: "auto",
  },
});

const DialogHelper = () => {
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    data: {},
    fPrices: {},
    avg: 0,
  });

  function closeDialog() {
    setDialogInfo({ ...dialogInfo, open: false, fPrices: {}, avg: 0 });
  }

  function openDialog(data, fPrices, avg) {
    setDialogInfo({ data: data, open: true, fPrices: fPrices, avg: avg });
  }

  return { dialogInfo, openDialog, closeDialog };
};
const ProductsTable = ({ city, franchiseData, panProducts }) => {
  const [products, setProducts] = useState([]);
  console.log(products, "products");

  // const db = useFirestore();

  // let productsRef = db.collection("Products").doc(city).collection("Products");
  // if (city) {
  //   productsRef.get().then((doc) => {
  //     if (!doc.empty) {
  //       console.log(doc.data(), "get query loop");
  //     } else {
  //       console.log("not found");
  //     }
  //   });
  // }

  // const { status, data } = useFirestoreCollectionData(productsRef);

  const [filterStatus, setFilterStatus] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function editProduct(product) {
    setSelectedProduct(product);
    handleClickOpen();
  }

  const getCityProducts = async () => {
    swal(
      "Recieving Data",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    let res = await getFInalProducts(city);
    setProducts(res);
    setSearchResult(searchProductsResult(searchValue, filterStatus));
    swal.close();
  };

  useEffect(() => {
    city && getCityProducts();
  }, [city]);
  // useEffect(() => {
  //   console.log("It rendered");
  //   if (data) {
  //     console.log(data[0]);
  //     setProducts(data);

  //     setSearchResult(searchProductsResult(searchValue, filterStatus));
  //   }
  // }, [data]);

  const { dialogInfo, openDialog, closeDialog } = DialogHelper();

  function compareAB(a, b) {
    var nameA = a ? a.toString().toUpperCase() : ""; // ignore upper and lowercase
    var nameB = b ? b.toString().toUpperCase() : ""; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }
  //  Search & filter in textbox
  const searchProductsResult = (name, status) => {
    let tempProducts = products.length > 0 ? products : panProducts;
    switch (status) {
      case "SKU ID Ascending":
        tempProducts.sort((a, b) => compareAB(a["SKU ID"], b["SKU ID"]));
        break;
      case "SKU ID Descending":
        tempProducts.sort((a, b) => -1 * compareAB(a["SKU ID"], b["SKU ID"]));
        break;
      case "Latest Added":
        break;
    }
    return tempProducts.filter((pros) => {
      if (name) {
        return (
          (pros["Product Name"] &&
            pros["Product Name"].toLowerCase().includes(name.toLowerCase())) ||
          (pros["Product Category"] &&
            pros["Product Category"]
              .toLowerCase()
              .includes(name.toLowerCase())) ||
          (pros["Product Sub-Category"] &&
            pros["Product Sub-Category"]
              .toLowerCase()
              .includes(name.toLowerCase())) ||
          (pros["Brand Name"] &&
            pros["Brand Name"].toLowerCase().includes(name.toLowerCase()))
        );
      }

      return true;
    });
  };

  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchProductsResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    setSearchResult(searchProductsResult(searchValue, status));
  };

  const viewFranPrices = (product) => {
    if (product["franchise"])
      openDialog(franchiseData, product["franchise"], getAvgPrice(product));
  };

  function getAvgPrice(product) {
    let sum = 0,
      count = 0,
      avg = 0;
    if (product["franchise"]) {
      for (const fKey of Object.keys(product["franchise"])) {
        sum += product["franchise"][fKey];
        count++;
      }

      avg = sum / count;

      console.log("Average is ", avg);
    }

    return avg;
  }

  // table view
  const ProductsTableView = ({ products, panProducts }) => {
    return (
      <>
        <h1 className="text-center">
          {city ? <>City Wise Products</> : <>Pan India Wise Products</>}
        </h1>
        <div className="row align-items-center overflow-auto">
          {city ? (
            <>
              <Table key="LeadTable">
                <Thead>
                  <Tr>
                    <Th>SKU ID</Th>
                    <Th>Brand</Th>
                    <Th>Product Category</Th>
                    <Th>Product Sub Category</Th>
                    <Th>Product Name</Th>
                    <Th>Description</Th>
                    <Th>Description Details</Th>
                    <Th>Product Image</Th>
                    <Th>MRP</Th>
                    <Th>NexStoc Price</Th>
                    <Th>Max Price</Th>
                    <Th>Franchise Prices</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {products.map((pros, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{pros["SKU ID"]}</Td>
                        <Td>{pros["Brand Name"]}</Td>

                        <Td>{pros["Product Category"]}</Td>
                        <Td>{pros["Product Sub-Category"]}</Td>
                        <Td>{pros["Product Name"]}</Td>
                        <Td style={{ maxWidth: "15em", overflow: "auto" }}>
                          {pros["Description"]}
                        </Td>
                        <Td style={{ maxWidth: "15em", overflow: "auto" }}>
                          {pros["Description Details"]}
                        </Td>
                        <Td style={{ width: "10%" }} align="center">
                          <img
                            style={{
                              display: "block",
                              maxHeight: "50px",
                              maxWidth: "100%",
                            }}
                            src={
                              pros["Product Images"] &&
                              pros["Product Images"][0]
                                ? pros["Product Images"][0]
                                : pros["Product Image"]
                            }
                          />
                        </Td>

                        <Td>{Number(pros["MRP"]).toFixed(2)}</Td>
                        <Td>{Number(pros["finalPrice"]).toFixed(2)}</Td>
                        <Td>
                          {panProducts[pros.id]
                            ? Number(panProducts[pros.id]["maxPrice"]).toFixed(
                                2
                              )
                            : "Not Set"}
                        </Td>

                        <Td>
                          {" "}
                          <ColorButton onClick={() => viewFranPrices(pros)}>
                            View
                          </ColorButton>
                        </Td>
                        <Td>
                          <IconButton
                            aria-label="Edit"
                            onClick={(e) => editProduct(pros)}
                          >
                            <CreateIcon />
                          </IconButton>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
              <CustomDialog
                city={city}
                productData={selectedProduct}
                open={open}
                handleClose={handleClose}
              />
            </>
          ) : (
            <>
              {console.log(panProducts, "other ternary")}
              <Table key="LeadTable">
                <Thead>
                  <Tr>
                    <Th>SKU ID</Th>
                    <Th>Brand</Th>
                    <Th>Product Category</Th>
                    <Th>Product Sub Category</Th>
                    <Th>Product Name</Th>
                    <Th>Description</Th>
                    <Th>Description Details</Th>
                    <Th>Product Image</Th>
                    <Th>MRP</Th>
                    <Th>Max Price</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {panProducts &&
                    panProducts.map((pros, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{pros["SKU ID"]}</Td>
                          <Td>{pros["Brand Name"]}</Td>

                          <Td>{pros["Product Category"]}</Td>
                          <Td>{pros["Product Sub-Category"]}</Td>
                          <Td>{pros["Product Name"]}</Td>
                          <Td style={{ maxWidth: "15em", overflow: "auto" }}>
                            {pros["Description"]}
                          </Td>
                          <Td style={{ maxWidth: "15em", overflow: "auto" }}>
                            {pros["Description Details"]}
                          </Td>
                          <Td style={{ width: "10%" }} align="center">
                            <img
                              style={{
                                display: "block",
                                maxHeight: "50px",
                                maxWidth: "100%",
                              }}
                              src={
                                pros["Product Images"] &&
                                pros["Product Images"][0]
                                  ? pros["Product Images"][0]
                                  : pros["Product Image"]
                              }
                            />
                          </Td>

                          <Td>{Number(pros["MRP"]).toFixed(2)}</Td>
                          <Td>{Number(pros["maxPrice"]).toFixed(2)}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </>
          )}
        </div>
      </>
    );
  };

  const prospectStatus = [
    {
      value: "SKU ID Ascending",
      label: "SKU ID Ascending",
    },
    {
      value: "SKU ID Descending",
      label: "SKU ID Descending",
    },
    {
      value: "Latest Added",
      label: "Latest Added",
    },
  ];

  return (
    <div>
      <div className="row pb-3 justify-content-center align-items-center">
        <div className="col-10 col-md-10">
          <input
            type="text"
            className="search-box"
            placeholder="Search Products"
            onChange={(e) => {
              search(e);
            }}
            style={{ width: "275px", height: "40px" }}
          />
        </div>
        <div className="col col-md-2 mt-2 float-right">
          <TextField
            required
            id="outlined-indian-state"
            select
            label="Status"
            // value={employee.indianState}
            // onClick={AssignDrop()}
            value={filterStatus}
            onChange={(e) => onFilter(e.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            // SelectProps={{
            //   classes: {
            //     root: classes.selectRoot,
            //     select: classes.select,
            //   },
            // }}
            style={{ width: "11ch", float: "right", verticalAlign: "center" }}
          >
            {prospectStatus.map((val, index) => (
              <MenuItem key={val.value} value={val.value}>
                {val.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      {products.length === 0 && panProducts.length === 0 ? (
        <div className="row justify-content-center">
          <p>No request's yet!</p>
        </div>
      ) : (
        <>
          {(products.length > 0 || panProducts.length > 0) &&
          filterStatus === "" &&
          searchValue === "" ? (
            <ProductsTableView products={products} panProducts={panProducts} />
          ) : (
            <>
              {searchResult.length > 0 ? (
                <ProductsTableView
                  products={searchResult}
                  panProducts={searchResult}
                />
              ) : (
                <div className="row pb-3 justify-content-center">
                  <div>Search Not Found!</div>
                </div>
              )}
            </>
          )}
        </>
      )}
      <FranchiseDialog dialogInfo={dialogInfo} handleClose={closeDialog} />
    </div>
  );
};

export default ProductsTable;

const FranchiseDialog = ({ dialogInfo, handleClose }) => {
  // console.log(dialogInfo);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={dialogInfo.open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Franchise Prices</DialogTitle>
      <DialogContent dividers={true}>
        <p>Average Price : {dialogInfo.avg}</p>
        {/* {Object.keys(dialogInfo.data).map((item, index) => (
          <p key={index}>
            {dialogInfo.data[item]["name"]} : {dialogInfo.fPrices[item]}
          </p>
        ))} */}

        <TableContainer component={Paper}>
          <MaterialTable
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "10em" }}>Franchise Name</TableCell>

                <TableCell style={{ width: "10em" }}>Franchise Email</TableCell>

                <TableCell style={{ width: "10em" }}>Franchise Phone</TableCell>

                <TableCell style={{ width: "10em" }}>Franchise Price</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(dialogInfo.data).map((item, index) => {
                let fData = dialogInfo.data[item];
                let showRed = dialogInfo.fPrices[item] > dialogInfo.avg;
                return (
                  <StyledTableRow
                    key={index}
                    className={showRed ? "backgroundRed" : ""}
                  >
                    <TableCell component="th" scope="row">
                      {fData["name"]}
                    </TableCell>

                    <TableCell>{fData["email"]}</TableCell>

                    <TableCell>{fData["mobile"]}</TableCell>

                    <TableCell>{dialogInfo.fPrices[item]}</TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </MaterialTable>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const CustomDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const CustomDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const CustomDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CustomDialog = ({ city, productData, open, handleClose }) => {
  const [product, setProduct] = useState(productData);

  // useEffect(() => {
  //   setProduct(productData === null ? {} : productData);
  // }, [productData]);

  const handleSave = async () => {
    if (product.finalPrice == 0) return;

    const res = await editFinalProductsOfCity(city, product);
    if (res.successfull) {
      handleClose();
      swal("Success!", "Product Edited", "success");
    } else {
      swal("Error!", "Something went wrong", "error");
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Product
        </CustomDialogTitle>
        <CustomDialogContent dividers>
          {product && (
            <Container>
              <TextField
                type="Number"
                onWheel={() => document.activeElement.blur()}
                size="small"
                label="Set New Price"
                variant="outlined"
                value={product.finalPrice}
                onChange={(e) => {
                  const val = e.target.valueAsNumber;
                  setProduct({ ...product, finalPrice: isNaN(val) ? 0 : val });
                }}
              />
            </Container>
          )}
        </CustomDialogContent>
        <CustomDialogActions>
          <Button autoFocus onClick={handleSave} color="primary">
            Save changes
          </Button>
        </CustomDialogActions>
      </Dialog>
    </div>
  );
};
