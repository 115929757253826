import { IconButton } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";

export function ActionCol({ data, onView, onEdit, onDelete }) {
  return (
    <div>
      {onView ? (
        <IconButton
          aria-label="view"
          className="px-2 py-0"
          id={data?.id}
          onClick={() => onView?.(data)}
        >
          <VisibilityIcon fontSize="small" />
        </IconButton>
      ) : null}
      {onEdit ? (
        <IconButton
          aria-label="edit"
          className="px-2 py-0"
          id={data?.id}
          onClick={() => onEdit?.(data)}
        >
          <CreateIcon fontSize="small" />
        </IconButton>
      ) : null}
      {onDelete ? (
        <IconButton
          aria-label="delete"
          className="p-2 !text-[#F44336]"
          id={data?.id}
          onClick={() => onDelete?.(data)}
        >
          <DeleteOutlineOutlinedIcon fontSize="small" />
        </IconButton>
      ) : null}
    </div>
  );
}
