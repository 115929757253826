import { getRoleForNav } from "backend/getPrivilegesForNav";
import React, { createContext, useContext, useEffect, useState } from "react";

const PermissionsContext = createContext();

export const useGetPermissions = () => useContext(PermissionsContext);

export const PermissionsProvider = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState(null);
  const [userDetails, setUserDetails] = useState({
    profile: "",
    name: "",
    email: "",
    userDocId: "",
  });

  useEffect(() => {
    refetchUserPermissions();
  }, []);

  const refetchUserPermissions = async () => {
    const { role, name, email, userDocId, permissions } = await getRoleForNav();
    setUserPermissions(permissions);
    setUserDetails({ profile: role, name, email, userDocId });
  };

  return (
    <PermissionsContext.Provider
      value={{ userPermissions, userDetails, refetchUserPermissions }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
