import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ColorButton } from "../../common/Components/ColorButton";
import { TextField } from "@material-ui/core";
import { saveClusterData } from "../../backend/saveDataToFirestore";
import "firebase/firestore";

const AddRegionClustersDialog = ({
  initialClusterData,
  clusterData,
  setClusterData,
  isUpdate,
  setUpdate,
  dialogInfo,
  closeDialog,
  client_id,
  region_id,
  getClusters,
}) => {
  const handleClose = () => {
    setClusterData(initialClusterData);
    setUpdate(false);
    closeDialog();
  };

  async function handleSubmit() {
    await saveClusterData(client_id, region_id, clusterData);
    getClusters(client_id, region_id);
    handleClose();
  }

  function updateClusterDetails(e) {
    let newCluster = { ...clusterData };
    newCluster[e.target.name] = e.target.value;
    setClusterData(newCluster);
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={dialogInfo.open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {isUpdate ? "Edit Cluster" : "Add Cluster"}
      </DialogTitle>
      <DialogContent dividers={true}>
        <TextField
          required
          fullWidth
          label="Cluster Name"
          variant="outlined"
          name="name"
          value={clusterData.name}
          style={{ marginBottom: "10px" }}
          onChange={(e) => updateClusterDetails(e)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <ColorButton onClick={handleSubmit}>Submit</ColorButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddRegionClustersDialog;
