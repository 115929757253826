import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Media } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import { ColorButton } from "../../common/Components/ColorButton";
import { getRegionClusters } from "../../backend/getDataFromFirestore";
import AddRegionClustersDialog from "./AddRegionClustersDialog";
import { DialogHelper } from "../../common/Components/DialogHelper";
import { deleteRegionCluster } from "../../backend/deleteDataFromFirestore";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const initialClusterData = {
  name: "",
};

const AddRegionClusters = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const client_id = location.state.client_id;
  const region_id = location.state.region_id;
  const { dialogInfo, openDialog, closeDialog } = DialogHelper();
  const [clusters, setClusters] = useState([]);
  const [clusterData, setClusterData] = useState(initialClusterData);
  const [isUpdate, setUpdate] = useState(false);

  useEffect(() => {
    getClusters();
  }, []);

  async function getClusters() {
    const temp = await getRegionClusters(client_id, region_id);
    setClusters(temp);
  }

  const addCityBtn = (client_id, region_id, cluster_id) => {
    history.push("/add-cluster-cities", { client_id, region_id, cluster_id });
  };

  async function deleteCluster(client_id, region_id, cluster_id) {
    const toDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Cluster!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!toDelete) {
      return;
    }

    swal("Deleting Cluster", "Please do not refresh this page", "info", {
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: false,
    });

    const res = await deleteRegionCluster(client_id, cluster_id);

    swal.close();

    if (res.successfull) {
      swal("Success!", "Cluster deleted", "success");
      getClusters();
    } else {
      swal("Failed!", "Failed to delete Cluster. Try Again!!!", "error");
    }
  }

  function editCluster(cluster) {
    setUpdate(true);
    setClusterData(cluster);
    openDialog();
  }
  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>Clusters</h2>
        <div row align-items-center justify-content-center>
          <form className={classes.form} noValidate autoComplete="off">
            <AddRegionClustersDialog
              initialClusterData={initialClusterData}
              isUpdate={isUpdate}
              setUpdate={setUpdate}
              clusterData={clusterData}
              setClusterData={setClusterData}
              dialogInfo={dialogInfo}
              classes={classes}
              closeDialog={closeDialog}
              client_id={client_id}
              region_id={region_id}
              getClusters={getClusters}
              style={{ marginBottom: "10px" }}
            />
            {clusters.map((cluster) => {
              return (
                <Media
                  list
                  key={`${cluster.id}-${cluster.name}`}
                  className="blog-card"
                >
                  <Media tag="li">
                    <Media body>
                      <Media heading>{cluster.name}</Media>
                      <p className="view-edit">
                        <IconButton
                          aria-label="delete"
                          onClick={(e) =>
                            deleteCluster(client_id, region_id, cluster.id)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          onClick={(e) => editCluster(cluster)}
                        >
                          <CreateIcon />
                        </IconButton>
                        <ColorButton
                          variant="contained"
                          className={classes.button}
                          onClick={() =>
                            addCityBtn(client_id, region_id, cluster.id)
                          }
                        >
                          View/Edit Cities
                        </ColorButton>
                      </p>
                    </Media>
                  </Media>
                </Media>
              );
            })}

            <ColorButton fullWidth="max" onClick={() => openDialog()}>
              Add New Cluster
            </ColorButton>

            <br />
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AddRegionClusters;
