import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import swal from "sweetalert";
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import { useHistory } from "react-router-dom";
import { deleteSalesUser, deleteUser } from "../../backend/sendRequestToServer";
import { getPageName } from "././../../common/const";

const ViewEmployee = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  let history = useHistory();
  const [loggedEmp, showLoggedEmp] = useState(false);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleReset = () => {
    window.location.reload();
  };

  function checkIfSalesUser(emp) {
    const designations = [
      "National Sales Head",
      "Regional Sales Head",
      "City Sales Head",
      "Sales Executive",
    ];
    return designations.includes(emp.designation);
  }

  // function delete blogs
  async function DeleteEmployee(blog) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this employee!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: "Deleting the user. Do not Refresh",
          icon: "warning",
          buttons: false,
          dangerMode: true,
        });

        console.log("email", blog.email);

        let res;
        if (checkIfSalesUser(blog)) {
          res = await deleteSalesUser({
            id: blog.id,
            email: blog.email,
          });
        } else {
          res = await deleteUser({
            email: blog.email,
            collection: "Users",
          });
        }

        if (res.status === 200) {
          swal("Success", "Employee Successfully Deleted!!!", "success").then(
            (value) => handleReset()
          );
        } else {
          swal(
            "Error",
            "Error Occur while Deleting. Please Try Again.",
            "error"
          );
        }
      } else {
      }
    });

    //aDD SWAL CODE below
  }

  // function edit blogs

  function EditEmployee(id) {
    console.log("this is ", id);
    history.push("/edit-employee", id);
  }

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const fetchBlogs = async () => {
    const response = db.collection("Users").orderBy("name");
    const data = await response.get();
    let emps = [];
    data.docs.forEach((item) => {
      const emp = item.data();
      if (!emp.userDeleted) emps.push(emp);
      console.log(emp);
    });

    setBlogs(emps);
  };

  //  View selected blog btn click
  const handleViewBtn = (blog_detail) => {
    console.log(blog_detail);
    history.push("/blog-detail", blog_detail);
  };

  //  Search in textbox
  const searchBlogResult = (val) => {
    return blogs.filter(
      (data) =>
        data.name.toLowerCase().includes(val.toLowerCase()) ||
        data.designation.toLowerCase().includes(val.toLowerCase())
    );
  };

  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchBlogResult(e.target.value));
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>
        <div className="row pb-3 justify-content-center">
          <input
            type="text"
            className="col-10 col-md-6 search-box"
            placeholder="Search Employee"
            onChange={(e) => {
              search(e);
            }}
          />
        </div>
        {blogs.length === 0 ? (
          <div className="row justify-content-center">
            <p>No Employee's yet!</p>
          </div>
        ) : (
          <>
            {blogs.length > 0 && searchValue === "" ? (
              blogs.map((blog) => {
                return (
                  <Media list key={blog.id} className="blog-card">
                    <Media tag="li">
                      {/* <Media left href="#">
                            <Avatar src={blog.downloadURL} alt={blog.author} 
                            style = {{
                                minHeight: 100,
                                minWidth: 100,
                                marginRight: 8
                            }}/>
                        </Media> */}

                      <Media body>
                        <Media heading>{blog.name}</Media>
                        <p className="author-name">Email : {blog.email}</p>
                        <p>Phone: {blog.phone}</p>
                        <p>Designation: {blog.designation}</p>
                        <p>Priviledges: {blog.privileges.toString()}</p>
                        {localStorage.getItem("email") !== blog.email && (
                          <p className="view-edit">
                            <IconButton
                              aria-label="delete"
                              id={blog.id}
                              onClick={(e) => DeleteEmployee(blog)}
                            >
                              <DeleteIcon />
                            </IconButton>
                            {/* <IconButton aria-label="delete" 
                                id={blog.id} 
                                onClick = {(e) => EditBlog(blog)}>
                                    <CreateIcon />
                                </IconButton> */}
                            <ColorButton
                              variant="contained"
                              className={classes.button}
                              onClick={() => EditEmployee(blog)}
                            >
                              {" "}
                              <IconButton
                                aria-label="delete"
                                id={blog.id}
                                onClick={(e) => EditEmployee(blog)}
                              >
                                <CreateIcon />
                              </IconButton>
                            </ColorButton>
                          </p>
                        )}
                      </Media>
                    </Media>
                  </Media>
                );
              })
            ) : (
              <>
                {searchResult.length > 0 ? (
                  searchResult.map((blog) => {
                    return (
                      <Media list key={blog.id} className="blog-card">
                        <Media tag="li">
                          {/* <Media left href="#">
                        <Avatar src={blog.downloadURL} alt={blog.author} 
                        style = {{
                            minHeight: 100,
                            minWidth: 100,
                            marginRight: 8
                        }}/>
                    </Media> */}

                          <Media body>
                            <Media heading>{blog.name}</Media>
                            <p className="author-name">Email : {blog.email}</p>
                            <p>Phone: {blog.phone}</p>
                            <p>Designation: {blog.designation}</p>
                            <p>Priviledges: {blog.privileges.toString()}</p>
                            {localStorage.getItem("email") !== blog.email && (
                              <p className="view-edit">
                                <IconButton
                                  aria-label="delete"
                                  id={blog.id}
                                  onClick={(e) => DeleteEmployee(blog)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                                {/* <IconButton aria-label="delete" 
                            id={blog.id} 
                            onClick = {(e) => EditBlog(blog)}>
                                <CreateIcon />
                            </IconButton> */}
                                <ColorButton
                                  variant="contained"
                                  className={classes.button}
                                  onClick={() => EditEmployee(blog)}
                                >
                                  Edit Employee
                                </ColorButton>
                              </p>
                            )}
                          </Media>
                        </Media>
                      </Media>
                    );
                  })
                ) : (
                  <>
                    {searchValue !== "" ? (
                      <div className="row pb-3 justify-content-center">
                        <div>Search Not Found!</div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ViewEmployee;
