import swal from "sweetalert";
import { getFranchiseEstoresData } from "../../../backend/getDataFromFirestore";
import {
  unblockFranchiseAndAllEstores,
  unblockFranchiseBranch,
} from "../../../backend/saveDataToFirestore";
import { ColorButton } from "../../../common/Components/ColorButton";

export const UnblockFranchise = ({ franchiseData }) => {
  async function handleUnblock() {
    try {
      const res = await getFranchiseEstoresData(franchiseData);
      await unblockFranchiseBranch(res);
      await unblockFranchiseAndAllEstores(res);
      swal("Success!", "Unblocked Franchise Successfully", "success").then(
        window.location.reload()
      );
    } catch (error) {
      console.log(error);
      swal("Error!", error, "error");
    }
  }
  return (
    <ColorButton
      variant="contained"
      style={{ margin: "10px" }}
      onClick={() => handleUnblock()}
    >
      Unblock Franchise
    </ColorButton>
  );
};
