import React, { useEffect, useState } from "react";

import XLSX from "xlsx";
import Layout from "../../common/Layout";
import ProductsTable from "./Components/ProductsTable";
import Typography from "@material-ui/core/Typography";

import { Button, MenuItem, TextField } from "@material-ui/core";
import { ColorButton } from "../../common/Components/ColorButton";
import { getPageName } from "../../common/const";
import {
  getCitiesFromProductApproval,
  getCitiesFromProducts,
  getCitiesList,
  getFinalProductDataOfCity,
  getFranchiseDataOfCityFromFinalProducts,
  getPanLevelProducts,
  getProductDataOfCity,
} from "../../backend/getDataFromFirestore";
import swal from "sweetalert";

const Products = () => {
  let file = [];

  const [franchiseData, setFranchiseData] = useState({});
  const [city, setCity] = useState();

  const [cities, setCities] = useState([]);
  const [panProducts, setPanProducts] = useState({});

  const getInitialData = async () => {
    swal(
      "Recieving Data",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    const temp = await getCitiesFromProducts();
    setCities(temp);

    const panTemp = await getPanLevelProducts();

    setPanProducts(panTemp);

    swal.close();
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handleData = async (e) => {
    let selectedCity = e.target.value;
    setCity(selectedCity);

    const franchise = await getFranchiseDataOfCityFromFinalProducts(
      selectedCity
    );

    setFranchiseData(franchise);
  };

  return (
    <>
      <Layout />
      <div className=" m-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>
        <div className="row">
          <div className="col-12 col-md-12">
            <TextField
              required
              id="outlined-city"
              select
              label="City"
              value={city}
              onChange={(e) => {
                handleData(e);
              }}
              variant="outlined"
              style={{ marginBottom: "10px", width: "30%" }}
            >
              {cities.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            <br />
          </div>
          <div className="col-12 col-md-12">
            <div className="mt-3">
              <ProductsTable
                city={city}
                franchiseData={franchiseData}
                panProducts={panProducts}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
