import React, { useEffect } from "react";
import Firebase from "./backend/firebase";
import AppRouter from "./Router/AppRouter";
import { ThemeProvider } from "@material-ui/styles";
import { App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { theme } from "./theme";
import { disableCache } from "@iconify/react";
import { PermissionsProvider } from "context/PermissionsProvider";

function App() {
  disableCache("local");
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      CapacitorApp.addListener("backButton", (e) => {
        if (
          window.location.pathname === "/" ||
          window.location.pathname === "/dashboard"
        ) {
          // Show A Confirm Box For User to exit app or not
          let ans = window.confirm("Are you sure");
          if (ans) {
            CapacitorApp.exitApp();
          }
        } else {
          window.history.back();
          // Show A Confirm Box For User to exit app or not
          // let ans = window.confirm("Are you sure");
          // if (ans) {
          // CapacitorApp.exitApp();
          // }
        }
      });
    }
  }, []);

  return (
    <Firebase>
      <ThemeProvider theme={theme}>
        <PermissionsProvider>
          <AppRouter />
        </PermissionsProvider>
      </ThemeProvider>
    </Firebase>
  );
}

export default App;
