import React, { useState } from "react";
import CommonTable from "../../../common/Table";
import initialData from "./data.json";
import LeadsManagementWrapper from "../LeadsManagement";
import { ActionCol } from "../../../common/Table/ActionCol";
import { Avatar, Button, Checkbox } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AdvancedSearch from "common/AdvancedSearch/AdvancedSearch";

const advancedSearchConfig = {
  searchProps: {
    searchTitle: "Company Name, Model Type, etc...",
    searchByOptions: ["companyName", "modelType", "city", "state"],
  },
  sortProps: {
    isShowSort: true,
    sortByOptions: [
      {
        label: "Company Name",
        key: "companyName",
      },
      {
        label: "Model Type",
        key: "modelType",
      },
      {
        label: "City",
        key: "city",
      },
      {
        label: "State",
        key: "state",
      },
    ],
  },
};

const Prospects = () => {
  const [data, setData] = useState(initialData);
  const [filteredData, setFilteredData] = useState(initialData);

  return (
    <LeadsManagementWrapper>
      <AdvancedSearch
        data={data}
        searchConfig={advancedSearchConfig}
        setFilteredData={setFilteredData}
      />
      <CommonTable data={filteredData} cols={columns} />
    </LeadsManagementWrapper>
  );
};

export default Prospects;

const imageUrl =
  "https://w7.pngwing.com/pngs/676/74/png-transparent-fast-food-mcdonald-s-logo-golden-arches-restaurant-mcdonalds-mcdonald-s-logo-miscellaneous-food-company-thumbnail.png";

const columns = [
  {
    headerName: "PAN Level",
    field: "_",
    width: 90,
    renderCell: ({ row }) => <Checkbox />,
  },
  {
    headerName: "Company Logo",
    field: "imageUrl",
    width: 130,
    renderCell: () => <Avatar alt="logo" src={imageUrl} />,
  },
  {
    headerName: "Company Name",
    field: "companyName",
    width: 140,
  },
  {
    headerName: "Model Type",
    field: "modelType",
    width: 140,
  },
  {
    headerName: "City",
    field: "city",
    width: 120,
  },
  {
    headerName: "State",
    field: "state",
    width: 120,
  },
  {
    headerName: "Rate Contract",
    field: "rateContract",
    width: 140,
    renderCell: () => (
      <div>
        <Button
          size="small"
          variant="text"
          color="primary"
          className="bg-white text-green"
        >
          Version
        </Button>
        <Button
          size="small"
          variant="text"
          color="primary"
          className="bg-white text-green"
        >
          View
        </Button>
      </div>
    ),
  },
  {
    headerName: "Approve",
    field: "approve",
    width: 100,
    renderCell: () => <CheckCircleOutlineIcon className="text-[#219653]" />,
  },
  {
    headerName: "Reject",
    field: "reject",
    width: 100,
    renderCell: () => <CancelOutlinedIcon className="text-[#F44336]" />,
  },
  {
    headerName: "Status",
    field: "status",
    width: 100,
  },
  {
    headerName: "Actions",
    field: "actions",
    width: 150,
    renderCell: ({ row }) => (
      <ActionCol
        data={row}
        onView={(row) => {
          console.log(row);
        }}
        onDelete={(row) => {
          console.log(row);
        }}
        onEdit={(row) => {
          console.log(row);
        }}
      />
    ),
  },
];
