import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Media } from "reactstrap";
import Layout from "../../common/Layout";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import { ColorButton } from "../../common/Components/ColorButton";
import { getClientRegions } from "../../backend/getDataFromFirestore";
import RegionDialog from "./RegionDialog";
import { DialogHelper } from "../../common/Components/DialogHelper";
import { deleteClientRegion } from "../../backend/deleteDataFromFirestore";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const initialRegionData = {
  name: "",
};

const AddRegion = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const client_id = location.state;
  const { dialogInfo, openDialog, closeDialog } = DialogHelper();
  const [regions, setClientRegions] = useState([]);
  const [regionData, setRegionData] = useState(initialRegionData);
  const [isUpdate, setUpdate] = useState(false);

  useEffect(() => {
    getRegions();
  }, []);

  async function getRegions() {
    const temp = await getClientRegions(client_id);
    setClientRegions(temp);
  }

  const addClusterBtn = (client_id, region_id) => {
    history.push("/add-region-clusters", { client_id, region_id });
  };

  async function deleteRegion(client_id, region_id) {
    const toDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Region!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!toDelete) {
      return;
    }

    swal("Deleting Region", "Please do not refresh this page", "info", {
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: false,
    });

    const res = await deleteClientRegion(client_id, region_id);

    swal.close();

    if (res.successfull) {
      swal("Success!", "Region deleted", "success");
      getRegions();
    } else {
      swal("Failed!", "Failed to delete Region. Try Again!!!", "error");
    }
  }

  function editRegion(region) {
    setUpdate(true);
    setRegionData(region);
    openDialog();
  }

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>Regions</h2>
        <div row align-items-center justify-content-center>
          <form className={classes.form} noValidate autoComplete="off">
            <RegionDialog
              initialRegionData={initialRegionData}
              isUpdate={isUpdate}
              setUpdate={setUpdate}
              regionData={regionData}
              setRegionData={setRegionData}
              dialogInfo={dialogInfo}
              classes={classes}
              closeDialog={closeDialog}
              getRegions={getRegions}
              client_id={client_id}
              style={{ marginBottom: "10px" }}
            />
            {regions.map((region) => {
              return (
                <Media
                  list
                  key={`${region.id}-${region.name}`}
                  className="blog-card"
                >
                  <Media tag="li">
                    <Media body>
                      <Media heading>{region.name}</Media>
                      <p className="view-edit">
                        <IconButton
                          aria-label="delete"
                          onClick={(e) => deleteRegion(client_id, region.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          onClick={(e) => editRegion(region)}
                        >
                          <CreateIcon />
                        </IconButton>
                        <ColorButton
                          variant="contained"
                          className={classes.button}
                          onClick={() => addClusterBtn(client_id, region.id)}
                        >
                          View/Edit Clusters
                        </ColorButton>
                      </p>
                    </Media>
                  </Media>
                </Media>
              );
            })}

            <ColorButton fullWidth="max" onClick={openDialog}>
              Add New Region
            </ColorButton>

            <br />
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AddRegion;
