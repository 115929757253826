import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DivertBranchTable from "./DivertBranchTable";
import {
  getBranchdataFromIds,
  getEstoresBranchIds,
} from "../../../backend/getDataFromFirestore";
import { ColorButton } from "../../../common/Components/ColorButton";
import swal from "sweetalert";
import { createDivertRequest } from "../../../backend/saveDataToFirestore";

export const DivertFranchise = ({ franchiseData }) => {
  //It will divert franchise permanently
  const [open, setOpen] = React.useState(false);
  const [branchData, setBranchData] = React.useState([]);

  const handleClickOpen = async () => {
    const allBranchObj = await getEstoresBranchIds(franchiseData);
    const res = await getBranchdataFromIds(allBranchObj);
    setBranchData(res);
    setOpen(true);
  };

  const handleClose = async () => {
    setOpen(false);
  };

  return (
    <div>
      <ColorButton
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => handleClickOpen()}
      >
        Divert Franchise
      </ColorButton>
      <Dialog
        fullWidth
        maxWidth="1216px"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DivertBranchTable
          branchData={branchData}
          setBranchData={setBranchData}
          franchiseData={franchiseData}
        />
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <ColorButton
            variant="contained"
            color="primary"
            disabled={!branchData?.length}
            onClick={() => uploadData(branchData)}
          >
            Divert
          </ColorButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const uploadData = async (branchData) => {
  let isValidDivert = true;
  isValidDivert = branchData.find((branch) => branch.isChangeVendor === true);
  if (!isValidDivert) {
    swal("Error!", "Please Provide at least one Vendor!", "error");
  } else {
    const swalRes = await swal(
      "Are you sure you want to Divert Franchise?",
      "",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    swal(
      "Uploading Data",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    console.log("Upload Data");
    try {
      for (const branch of branchData) {
        if (branch.isChangeVendor) {
          branch["branch_id"] = branch.id;
          delete branch.id;
          delete branch.nearestVendors;
          delete branch.isChangeVendor;
          delete branch.isPan;
          delete branch.nearestVendor;
          await createDivertRequest({ ...branch });
        }
      }
      swal.close();
      swal("Success!", "Send Request To Super Admin", "success").then(
        window.location.reload()
      );
    } catch (e) {
      swal.close();
      swal("Error!", "Something went wrong", "error");
    }
  }
};
