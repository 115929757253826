import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CommonTable from "../../../common/Table";
import { Chip, makeStyles } from "@material-ui/core";
import EmployeeManagement from "../EmployeeManagement";
import AdvancedSearch from "common/AdvancedSearch/AdvancedSearch";
import { getProfiles } from "backend/getDataFromFirestore";
import EditProfile from "../EditProfile/EditProfile";
import { ActionCol } from "common/Table/ActionCol";
import { deleteProfileData } from "backend/saveDataToFirestore";

const advancedSearchConfig = {
  searchProps: {
    searchTitle: "Search By Profile Name",
    searchByOptions: ["name"],
  },
};

const useStyles = makeStyles((theme) => ({
  chips: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.info.dark,
    margin: 4,
  },
  moreChip: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[30],
    margin: 4,
  },
  dialog: {
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: "calc(100% - 163px)",
    },
  },
}));

const GetProfiles = () => {
  const classes = useStyles();

  const [allLeads, setAllLeads] = useState([]);
  const [leads, setLeads] = useState([]);

  const [showSelectedPrivilege, setShowSelectedPrivilege] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [openEditProfile, setOpenEditProfile] = React.useState(false);
  const [selectProfile, setSelectProfile] = React.useState(null);

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    const { status, data } = await getProfiles();
    if (status === 200) {
      setAllLeads(data);
      setLeads(data);
    }
  };

  function handleShowPrev(prev) {
    setShowSelectedPrivilege(prev);
    handleClickOpen();
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowSelectedPrivilege([]);
  };

  const onEditProfile = (profileDetails) => {
    setOpenEditProfile(true);
    setSelectProfile(profileDetails);
  };

  const onDeleteProfile = async (profileDetails) => {
    const res = await deleteProfileData(profileDetails?.id);
    if (res.successful) {
      fetchProfiles();
    }
  };

  const onCloseEditProfile = (isFetch) => {
    setOpenEditProfile(false);
    setSelectProfile(null);

    if (isFetch) {
      fetchProfiles();
    }
  };

  const columnsData = [
    {
      headerName: "Profile Name",
      field: "name",
      width: 300,
    },
    {
      headerName: "Privileges",
      field: "permissions",
      width: 700,
      sortable: false,
      renderCell: ({ row }) => (
        <div className="w-full">
          <PrivilegeChip
            privileges={row?.permissions}
            handleShowPrev={handleShowPrev}
          />
        </div>
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      renderCell: ({ row }) => (
        <ActionCol
          data={row}
          onEdit={() => onEditProfile(row)}
          onDelete={() => onDeleteProfile(row)}
        />
      ),
    },
  ];

  return (
    <>
      <EditProfile
        show={openEditProfile}
        onClose={onCloseEditProfile}
        profileDetails={selectProfile}
      />
      <EmployeeManagement fetchProfiles={fetchProfiles}>
        <AdvancedSearch
          data={allLeads}
          searchConfig={advancedSearchConfig}
          setFilteredData={setLeads}
        />
        {allLeads.length === 0 ? (
          <div className="row justify-content-center">
            <p>No privileges yet!</p>
          </div>
        ) : (
          <>
            {leads.length > 0 ? (
              <CommonTable data={leads} cols={columnsData} />
            ) : (
              <div className="row pb-3 justify-content-center">
                <div>Search Not Found!</div>
              </div>
            )}

            <Dialog
              className={classes.dialog}
              open={open}
              onClose={handleClose}
            >
              <DialogTitle>View All Privileges</DialogTitle>
              <DialogContent>
                <PrivilegeCard selectedPrivilege={showSelectedPrivilege} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </EmployeeManagement>
    </>
  );
};

export default GetProfiles;

const PrivilegeCard = ({ selectedPrivilege }) => {
  return (
    <div key={1} style={{ width: "275px", padding: "2px" }}>
      {selectedPrivilege.map((prev) => {
        return <p>{prev}</p>;
      })}
    </div>
  );
};

const PrivilegeChip = ({ privileges, handleShowPrev }) => {
  const classes = useStyles();

  const permissionLabels = Object.entries(privileges)?.flatMap(
    ([category, actions]) =>
      Object.entries(actions)
        ?.filter(([, value]) => value)
        ?.map(([action]) => `${action} ${category}`)
  );

  return permissionLabels.map((value, index) => {
    if (index < 5) {
      return <Chip color="primary" className={classes.chips} label={value} />;
    } else if (index === 5) {
      return (
        <Chip
          color="secondary"
          label={`+${permissionLabels.length - 5} more`}
          className={classes.moreChip}
          clickable
          onClick={() => handleShowPrev(permissionLabels)}
        />
      );
    }
    return null;
  });
};
