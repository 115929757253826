import React, { useEffect, useState } from "react";
import Layout from "../../common/Layout";

import { Media } from "reactstrap";
import { Button, IconButton, MenuItem, TextField } from "@material-ui/core";
import { ColorButton, MarkVisited } from "../../common/Components/ColorButton";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

import swal from "sweetalert";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import {
//   getFinalProductDataOfCityById,
//   getSalesUserFromId,
// } from "../../backend/getDataFromFirestore";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {
  modifyEstoreToApproved,
  modifyEstoreToRejected,
} from "../../backend/editDataInFirestore";
// import {
//   modifyProductToApproved,
//   modifyProductToRejected,
// } from "../../backend/editDataInFirestore";

const ShowEstoreRequests = () => {
  const [estores, setEstores] = useState([]);
  const db = useFirestore();

  let history = useHistory();

  let location = useLocation();

  const leadsRef = useFirestore().collection("Estores");
  const { status, data } = useFirestoreCollectionData(leadsRef);
  const [filterStatus, setFilterStatus] = useState("All");

  useEffect(() => {
    console.log("It rendered");
    if (data) {
      setEstores(data);
      setSearchResult(searchLeadResult(searchValue, filterStatus));
    }
  }, [data]);

  // fetch estores

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [selectedLead, setSelectedLead] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const [remark, setRemark] = React.useState("");

  const leadStatus = [
    {
      value: "Approved",
      label: "Approved",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
    {
      value: "Pending",
      label: "Pending",
    },
    {
      value: "All",
      label: "All",
    },
  ];

  // const [currentLeadStatus, setLeadStatus] = useState(
  //   {
  //     status: "",
  //   }
  // );

  function closeDetails() {
    setOpen(false);
    setSelectedProduct(null);
  }

  //  Search & filter in textbox
  const searchLeadResult = (name, status) => {
    return data.filter((lead) => {
      if (name && status !== "All") {
        return (
          lead["companyName"] &&
          lead.status &&
          lead["companyName"].toLowerCase().includes(name.toLowerCase()) &&
          lead.status.toLowerCase() === status.toLowerCase()
        );
      }
      if (name || status === "All") {
        return (
          lead["companyName"] &&
          lead["companyName"].toLowerCase().includes(name.toLowerCase())
        );
      }
      if (status) {
        console.log(
          lead.status,
          status,
          lead.hasOwnProperty("status") &&
            lead.status.toLowerCase() === status.toLowerCase()
        );
        return (
          lead.hasOwnProperty("status") &&
          lead.status.toLowerCase() === status.toLowerCase()
        );
      }
      return true;
    });
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    setSearchResult(searchLeadResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    console.log(
      status,
      "=>",
      searchValue,
      searchLeadResult(searchValue, status)
    );
    setFilterStatus(status);

    setSearchResult(searchLeadResult(searchValue, status));
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const viewLeadDetails = (lead_details) => {
    console.log("view details button pressed", JSON.stringify(lead_details));
    setOpen(true);
    setSelectedLead(lead_details);
    // history.push("/view-lead-details", lead_details);
  };

  // const addClient = (lead_details) => {
  //   console.log("Add Client pressed", {
  //     nsh: lead_details.nsh,
  //     rsh: lead_details.rsh,
  //     csh: lead_details.csh,
  //     se: lead_details.se,
  //   });
  //   history.push("/add-client", {
  //     nsh: lead_details.nsh,
  //     rsh: lead_details.rsh,
  //     csh: lead_details.csh,
  //     se: lead_details.se,
  //     leadId: lead_details.id,
  //     approved: false,
  //   });
  // };

  // const [status, setStatus] = useState("");
  function editLead(lead) {
    history.push("/edit-lead", lead);
  }

  async function approveProduct(estore) {
    const swalRes = await swal(
      "Approve E-Store",
      "Are you sure you want to Approve this E-Store",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }

    swal(
      "Approving E-Store",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );

    const res = await modifyEstoreToApproved(estore);
    console.log(res);
    swal.close();

    if (res.successfull) {
      swal("Success", "Estore Approved", "success");
    } else {
      swal("Error", "Error in approving Estore", "error");
    }
  }

  async function rejectProduct(estore) {
    swal(
      "Reject this E-Store",
      // "Are you sure you want to Reject this Product",
      // "warning",
      "Remark:",
      {
        content: "input",
      }
      // {
      //   buttons: {
      //     cancel: "Cancel",
      //     catch: {
      //       text: "Save",
      //       value: true,
      //     },
      //   },
      // }
    ).then(async (inputVal) => {
      console.log(inputVal);

      if (!inputVal) {
        console.log(inputVal);
        return;
      }
      const res = await modifyEstoreToRejected(estore, inputVal);
      console.log(res);
      if (res.successfull) {
        swal("Success", "Estore Rejected", "success");
      } else {
        swal("Error", "Error in rejecting Estore", "error");
      }
    });
  }

  //  View selected estore on btn click
  const ViewEStore = (blog_detail) => {
    console.log(blog_detail);
    history.push("/viewEstoreDetails", blog_detail);
  };

  // edit store
  function EditEStore(data) {
    history.push("/add-estore", data);
  }

  // table view
  const LeadsTableView = ({ leads }) => {
    return (
      <div className="row align-items-center">
        <Table key="LeadTable">
          <Thead>
            <Tr>
              <Th>Franchise Name</Th>
              <Th>Franchise Email</Th>
              <Th>E-Store Name</Th>
              <Th>E-Store Details</Th>
              <Th>Edit</Th>
              <Th>Status</Th>
              <Th>Approve/Reject</Th>
              {/* <Th>Details</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {leads.map((store, index) => {
              return (
                <Tr key={index}>
                  <Td>{store["assignedFranchise"]}</Td>
                  <Td>{store["franchiseEmail"]}</Td>
                  <Td>{store["companyName"]}</Td>
                  <Td>
                    <ColorButton
                      onClick={(e) => {
                        console.log({ store });
                        ViewEStore(store);
                        // setOpen(true);
                      }}
                    >
                      View
                    </ColorButton>
                  </Td>
                  <Td>
                    <ColorButton
                      onClick={(e) => {
                        console.log({ store });
                        EditEStore(store);
                        // setOpen(true);
                      }}
                    >
                      Edit
                    </ColorButton>
                  </Td>
                  <Td> {store.status ?? "Pending"}</Td>

                  <Td>
                    <IconButton
                      disabled={
                        store.status === "Approved" ||
                        store.status === "Rejected"
                      }
                      aria-label="approve"
                      onClick={(e) => approveProduct(store)}
                    >
                      <CheckIcon />
                    </IconButton>
                    <IconButton
                      disabled={
                        store.status === "Approved" ||
                        store.status === "Rejected"
                      }
                      aria-label="reject"
                      onClick={(e) => rejectProduct(store)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Td>
                  {/* <Td>
                    <MarkVisited
                      variant="contained"
                      className={classes.button}
                      onClick={() => addClient(lead)}
                    >
                      Add Client
                    </MarkVisited>
                  </Td> */}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <ShowDetails
          productData={selectedProduct}
          open={open}
          closeDetails={closeDetails}
        />
      </div>
    );
  };
  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h3 style={{ textAlign: "center" }}>E-Store Requests </h3>

        <div className="row pb-3 justify-content-center align-items-center">
          <div className="col-10 col-md-10">
            <input
              type="text"
              className="search-box"
              placeholder="Search E-Store"
              onChange={(e) => {
                search(e);
              }}
              style={{ width: "275px", height: "40px" }}
            />
          </div>
          <div className="col col-md-2 mt-2 float-right">
            <TextField
              required
              id="outlined-indian-state"
              select
              label="Status"
              // value={employee.indianState}
              // onClick={AssignDrop()}
              value={filterStatus}
              onChange={(e) => onFilter(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                classes: {
                  root: classes.selectRoot,
                  select: classes.select,
                },
              }}
              style={{ width: "11ch", float: "right", verticalAlign: "center" }}
            >
              <MenuItem key="All" value="All">
                All
              </MenuItem>

              <MenuItem key="Pending" value="Pending">
                Pending
              </MenuItem>
              <MenuItem key="Approved" value="Approved">
                Approved
              </MenuItem>
              <MenuItem key="Rejected" value="Rejected">
                Rejected
              </MenuItem>
            </TextField>
          </div>
        </div>

        {estores.length === 0 ? (
          <div className="row justify-content-center">
            <p>No estore's yet!</p>
          </div>
        ) : (
          <>
            {estores.length > 0 &&
            filterStatus === "All" &&
            searchValue === "" ? (
              <LeadsTableView leads={estores} />
            ) : (
              <>
                {searchResult.length > 0 ? (
                  <LeadsTableView leads={searchResult} />
                ) : (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ShowEstoreRequests;

const ShowDetails = ({ closeDetails, open, productData }) => {
  const [product, setProduct] = useState(productData);
  // useEffect(() => {
  //   const city = localStorage.getItem("city");

  //   if (productData)
  //     getFinalProductDataOfCityById(city, productData.id).then((pro) =>
  //       setProduct(pro)
  //     );
  //   console.log(productData);
  // }, [productData]);

  return (
    <Dialog
      onClose={closeDetails}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Product Details</DialogTitle>

      {product && (
        <DialogContent>
          <p>Product Name: {product["Product Name"]}</p>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => closeDetails()} style={{ margin: "10px" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
