import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import { useHistory } from "react-router-dom";
import { getPageName } from "././../../common/const";

const ViewContactUs = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  let history = useHistory();

  useEffect(() => {
    fetchBlogs();
  }, []);

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  // function delete blogs

  function DeleteCareer(id) {
    console.log("this is ", id);
    db.collection("Contact")
      .doc(id)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  }

  // function edit blogs

  // function EditCareer(id){
  //     console.log("this is ",id);
  //     history.push('/edit-career',id);
  // }

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const fetchBlogs = async () => {
    const response = db.collection("Contact");
    const data = await response.orderBy("date","desc").get();
    data.docs.forEach((item) => {
      setBlogs((blogs) => [...blogs, item.data()]);
      console.log(item.data());
    });
  };

  //  View selected blog btn click
  const handleViewBtn = (blog_detail) => {
    console.log(blog_detail);
    history.push("/blog-detail", blog_detail);
  };

  //  Search in textbox
  const searchBlogResult = (val) => {
    return blogs.filter(
      (data) =>
        data.name.toLowerCase().includes(val.toLowerCase()) ||
        data.query.toLowerCase().includes(val.toLowerCase())
    );
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);

    setSearchResult(searchBlogResult(e.target.value));
  };

  function addNewLead(blog) {
    history.push("/send-leads-to-sales", { blog: blog });
  }

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>
        <div className="row pb-3 justify-content-center">
          <input
            type="text"
            className="col-10 col-md-6 search-box"
            placeholder="Search"
            onChange={(e) => {
              search(e);
            }}
          />
        </div>
        {blogs.length > 0 && searchValue === "" ? (
          blogs.map((blog) => {
            let timestamp = new Date(blog.date.seconds * 1000);
            let date = timestamp.getDate()+"/"+(timestamp.getMonth()+1)+"/"+timestamp.getFullYear()
            return (
              <Media list key={blog.id} className="blog-card">
                <Media tag="li">
                  {/* <Media left href="#">
                            <Avatar src={blog.downloadURL} alt={blog.author} 
                            style = {{
                                minHeight: 100,
                                minWidth: 100,
                                marginRight: 8
                            }}/>
                        </Media> */}
                  <Media body>
                    <Media heading>{blog.title}</Media>
                    <p className="author-name">Name : {blog.name}</p>
                    <p>Email: {blog.email}</p>
                    <p>Mobile Number: {blog.phone}</p>
                    <p>Query: {blog.query}</p>
                    <p>Message: {blog.msg}</p>
                    <p>Date: {date}</p>
                    <p className="view-edit">
                      <IconButton
                        aria-label="delete"
                        id={blog.id}
                        onClick={(e) => DeleteCareer(blog.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                      {/* <IconButton aria-label="delete" 
                                id={blog.id} 
                                onClick = {(e) => EditBlog(blog)}>
                                    <CreateIcon />
                                </IconButton> */}
                      {/* <ColorButton
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() =>  EditCareer(blog)}
                                > <IconButton aria-label="delete" 
                                id={blog.id} 
                                onClick = {(e) => EditCareer(blog)}>
                                    <CreateIcon />
                                </IconButton></ColorButton> */}

                      <ColorButton
                        variant="contained"
                        className={classes.button}
                        onClick={() => addNewLead(blog)}
                      >
                        Add Lead
                      </ColorButton>
                    </p>
                  </Media>
                </Media>
              </Media>
            );
          })
        ) : (
          <>
            {searchResult.length > 0 ? (
              searchResult.map((blog) => {
                return (
                  <Media list key={blog.id} className="blog-card">
                    <Media tag="li">
                      {/* <Media left href="#">
                                    <Avatar src={blog.downloadURL} alt={blog.author} 
                                    style = {{
                                        minHeight: 100,
                                        minWidth: 100,
                                        marginRight: 8
                                    }}/>
                                </Media> */}
                      <Media body>
                        <Media heading>{blog.title}</Media>
                        <p className="author-name">Name : {blog.name}</p>
                        <p>Email: {blog.email}</p>
                        <p>Mobile Number: {blog.phone}</p>
                        <p>Query: {blog.query}</p>
                        <p>Message: {blog.msg}</p>
                        <p className="view-edit">
                          <IconButton
                            aria-label="delete"
                            id={blog.id}
                            onClick={(e) => DeleteCareer(blog.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          {/* <IconButton aria-label="delete" 
                                        id={blog.id} 
                                        onClick = {(e) => EditBlog(blog)}>
                                            <CreateIcon />
                                        </IconButton> */}
                          <ColorButton
                            variant="contained"
                            className={classes.button}
                            onClick={() => addNewLead(blog)}
                          >
                            Add Lead
                          </ColorButton>
                        </p>
                      </Media>
                    </Media>
                  </Media>
                );
              })
            ) : (
              <>
                {searchValue !== "" ? (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ViewContactUs;
