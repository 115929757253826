import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { meetingDetails } from "../../common/const1";

export function MeetingDetails({ title, show }) {
  return (
    <ListItem style={{paddingLeft: "5px" }}>
      <ListItemIcon style={{ minWidth: "25px"}}>
        {meetingDetails[title]}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body1" color={grey[40]}>
            {show}
          </Typography>
        }
      />
    </ListItem>
  );
}
