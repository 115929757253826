import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';
import {ColorButton} from '../../common/Components/ColorButton';


const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    margin: "1em",
    width: "50ch",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  // // content: {
  // //   flex: "1 0 auto",
  // // },
  // // cover: {
  // //   width: 151,
  // // },
  controls: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  // checkbox: {
  //   display: "flex",
  // },
  image: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const ApproveProductCard = ({ productDetail }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [price,setPrice] = React.useState(productDetail.price);
  const [showForm,setShowForm] = React.useState(false);

  const handleSaveBtn = () => {
    console.log(price)
    setShowForm(!showForm)
  }
  return (
    <div className="col-10 col-md-3 mb-5">
          <div className="row justify-content-center align-items-center">
          <img
            src={productDetail.image}
            style={{
              width:'150px',
              height:'100px'
            }}
          />
          </div>
          <div className="row">
            <div className="col-12"><b>{productDetail.name}</b></div>
            <div className="col-12">
          
            Price : {price} &nbsp;
            {showForm ? <form noValidate autoComplete="off">
                <TextField
              id="standard-helperText"
              label=""
              defaultValue={productDetail.price}
              helperText="Enter new price"
              onChange={e => {setPrice(e.target.value)}}
            /> &nbsp; 
            <ColorButton onClick={handleSaveBtn}>Save</ColorButton>
            </form> : <IconButton aria-label="edit" 
                       
                    onClick = {(e) => {setShowForm(!showForm)}}>
                      <CreateIcon  className={classes.icons} /> 
              </IconButton>
            }                   
            </div>
            </div>
          <br />
      </div>
  );
};

export default ApproveProductCard;
