// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  'Blog 1',
  "Blog 2",
  "Blog 3",
  "Blog 4"
];
var website = [
  "Required Full Stack Developer",
  'Required Android App Developer'
];
var server = [
  "Case Study 1",
  "Case Study 2",
  'Case Study 3'
];

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  bugs,
  website,
  server
};
