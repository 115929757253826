import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import { CircularProgress } from "@material-ui/core";

// import {setTest} from '../firebaseConfig/firebase';
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import swal from "sweetalert";
import "firebase/storage";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  input: {},
});

const BlogComponent = (props) => {
  // hooks
  const [avatar, setAvatar] = React.useState("");
  const [showAvatar, setShowAvatar] = React.useState(false);

  const [author, setAuthor] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [short_desc, setShortDesc] = React.useState("");
  const [long_desc, setLongDesc] = React.useState("");
  const [showSpinner, setShowSpinner] = React.useState(false);

  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = React.useState("");
  const [imageAsUrl, setImageAsUrl] = React.useState(allInputs);

  // feild empty err hooks
  const [showTitleErr, setShowTitleErr] = React.useState(false);
  const [showAuthorErr, setShowAuthorErr] = React.useState(false);
  const [showShortDescErr, setShowShortDescErr] = React.useState(false);
  const [showLongDescErr, setShowLongDescErr] = React.useState(false);
  const [showImageErr, setShowImageErr] = React.useState(false);

  let history = useHistory();

  const db = useFirestore();

  // storage
  const storage = useFirebaseApp().storage();
  let downloadURL = "";

  console.log(imageAsFile);

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile((imageFile) => image);
    setAvatar(URL.createObjectURL(image));
    setShowAvatar(true);
    setShowImageErr(false);
  };

  function AddBlogsFunction() {
    if (title === "") {
      setShowTitleErr(true);
    } else if (short_desc === "") {
      setShowShortDescErr(true);
    } else if (author === "") {
      setShowAuthorErr(true);
    } else if (long_desc === "") {
      setShowLongDescErr(true);
    } else if (imageAsFile === "") {
      setShowImageErr(true);
    } else {
      setShowSpinner(true);
      const uploadTask = storage
        .ref(`/blogImages/${imageAsFile.name}`)
        .put(imageAsFile);
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          console.log(snapShot);
        },
        (err) => {
          //catches the errors
          console.log(err);
        },
        async () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          downloadURL = await storage
            .ref(`/blogImages/${imageAsFile.name}`)
            .getDownloadURL();
          console.log("sss", downloadURL);
          console.log("The link was clicked.");
          const pol = "Test text final";

          db.collection("Blogs")
            .add({
              author: author,
              title: title,
              short_desc: short_desc,
              long_desc: long_desc,
              downloadURL: downloadURL,
            })
            .then(function (docRef) {
              console.log("Document written with ID: ", docRef.id);
              var washingtonRef = db.collection("Blogs").doc(docRef.id);

              // Set the "capital" field of the city 'DC'
              return washingtonRef
                .update({
                  id: docRef.id,
                })
                .then(() => {
                  setShowSpinner(false);
                  console.log("Document successfully updated!");

                  swal("Success!!!", "Blog Added", "success");
                })
                .then(() => history.push("/view-blog"))
                .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error);
                });
            })
            .catch(function (error) {
              console.error("Error adding document: ", error);
            });
        }
      );
    }
  }

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();

  return (
    <div className="container mt-5 pt-5">
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-6">
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Add Blog
              </Typography>
              <form className={classes.form} noValidate autoComplete="off">
                {showAvatar && (
                  <Avatar
                    alt="Profile"
                    src={avatar}
                    style={{ minWidth: 100, minHeight: 100, margin: "auto" }}
                  />
                )}
                <br />
                <TextField
                  id="outlined-basic"
                  label="Blog Title*"
                  variant="outlined"
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setShowTitleErr(false);
                  }}
                  error={showTitleErr}
                  helperText={showTitleErr && "Please Enter title"}
                  style={{ marginBottom: "10px" }}
                />
                <TextField
                  id="outlined-basic"
                  label="Blog Short Description*"
                  variant="outlined"
                  onChange={(e) => {
                    setShortDesc(e.target.value);
                    setShowShortDescErr(false);
                  }}
                  error={showShortDescErr}
                  helperText={
                    showShortDescErr && "Please Enter blog short description"
                  }
                  style={{ marginBottom: "10px" }}
                />
                <TextField
                  id="outlined-basic"
                  label="Blog Author Name*"
                  variant="outlined"
                  onChange={(e) => {
                    setAuthor(e.target.value);
                    setShowAuthorErr(false);
                  }}
                  error={showAuthorErr}
                  helperText={showAuthorErr && "Please Enter Author Name"}
                  style={{ marginBottom: "10px" }}
                />
                <TextField
                  rows={4}
                  id="outlined-multiline-static"
                  label="Blog Long Description*"
                  multiline
                  variant="outlined"
                  onChange={(e) => {
                    setLongDesc(e.target.value);
                    setShowLongDescErr(false);
                  }}
                  style={{ margin: 2 }}
                  error={showLongDescErr}
                  helperText={
                    showLongDescErr && "Please Enter blog long description"
                  }
                  style={{ marginBottom: "10px" }}
                />

                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleImageAsFile}
                />
                {showImageErr && (
                  <p style={{ fontSize: "12px", color: "red" }}>
                    Please select image
                  </p>
                )}
              </form>
            </CardContent>
            <CardActions
              style={{ justifyContent: "center", fontWeight: "bold" }}
            >
              <ColorButton
                variant="contained"
                color="primary"
                onClick={AddBlogsFunction}
              >
                Submit
              </ColorButton>

              {/* <Button className="btn btn-small submit-btn"  >
            Submit
          </Button> */}
            </CardActions>
            <div>
              {showSpinner ? (
                <div>
                  <span
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                      color: "#ff0000",
                    }}
                    id="x"
                  >
                    <h5 style={{ textAlign: "center" }}>
                      Please DO NOT REFRESH the page...
                    </h5>
                  </span>
                  <CircularProgress
                    style={{
                      color: "#18D26E",
                    }}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default BlogComponent;
