import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import clsx from "clsx";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import {setTest} from '../firebaseConfig/firebase';
import { drawerCollapseable } from "../../common/const";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import "firebase/firestore";
import { useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useLocation, useHistory } from "react-router";
import { useState, useContext, useEffect } from "react";
import { addEmployee, editEmployee } from "../../backend/sendRequestToServer";
import { pages } from "../../common/const";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import DialogActions from "@material-ui/core/DialogActions";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  pos: {
    marginTop: 20,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  label: {
    color: "#000",
  },

  textField: {
    "& .MuiInputBase-input": {
      color: "#000",
    },
  },
}));

const EditEmployee = (props) => {
  const location12 = useLocation();
  let selectedEmployee = location12.state;
  console.log("aa", selectedEmployee);

  const db = useFirestore();
  let history = useHistory();

  // feild empty err hooks
  const [showTitleErr, setShowTitleErr] = React.useState(false);
  const [showDescErr, setShowDescErr] = React.useState(false);
  const [showLocationErr, setShowLocationErr] = React.useState(false);
  const [showQualErr, setShowQualErr] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const handleReset = () => {
    window.location.href = "/view-employee";
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChangex = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //priviledges dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //priviledges

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: "#fff",
        color: "#000",
      },
    },
  };

  const [employee, setEmployee] = useState({
    name: selectedEmployee.name,
    email: selectedEmployee.email,
    phone: selectedEmployee.phone,
    role: selectedEmployee.designation,
    pageName: selectedEmployee.privileges,
  });

  const onChangePrivilege = (e) => {
    console.log(e.target.name);
    const prv = e.target.value;
    let tempArr = [];
    if (e.target.checked) {
      tempArr = employee.pageName;
      tempArr.push(prv);
    } else {
      tempArr = employee.pageName;
      const index = tempArr.indexOf(prv);
      tempArr.splice(index, 1);
    }

    setEmployee({ ...employee, pageName: tempArr });

    console.log(employee.pageName);
  };

  const { name, email, phone, role, pageName } = employee;

  const onChange = (e) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const EditEmployee = async (e) => {
    e.preventDefault();
    if (
      email === "" ||
      name === "" ||
      phone === "" ||
      role === "" ||
      pageName.length === 0
    ) {
      alert("Please enter all fields");
    } else {
      setShowSpinner(true);
      console.log("ikik", name, email, phone, role, pageName);
      const status = await editEmployee({
        name,
        email,
        phone,
        role,
        privileges: pageName,
      });

      if (status === 200) {
        setShowSpinner(false);
        swal("Success!", "Employee Updated", "success").then((value) =>
          handleReset()
        );
      } else {
        swal(
          "Failed!",
          "Failed to update Employee. Try Again!!!",
          "error"
        ).then((value) => handleReset());
      }
    }
  };

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Edit Employee
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                  <TextField
                    id="outlined-basic"
                    name="name"
                    value={employee.name}
                    variant="outlined"
                    onChange={onChange}
                    error={showTitleErr}
                    helperText={showTitleErr && "Please Enter Name"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    disabled
                    value={employee.email}
                    name="email"
                    variant="outlined"
                    onChange={onChange}
                    error={showDescErr}
                    style={{ marginBottom: "10px" }}
                    helperText={showDescErr && "Please Enter Email"}
                  />
                  <TextField
                    variant="outlined"
                    aria-label="empty textarea"
                    value={employee.phone}
                    name="phone"
                    onChange={onChange}
                    style={{ marginBottom: "10px" }}
                    error={showQualErr}
                    helperText={showQualErr && "Please Enter Phone"}
                  />
                  <TextField
                    id="outlined-basic"
                    value={employee.role}
                    variant="outlined"
                    name="role"
                    onChange={onChange}
                    error={showLocationErr}
                    helperText={showLocationErr && "Please Enter Designation"}
                    style={{ marginBottom: "10px" }}
                  />

                  <div>
                    <b style={{ marginBottom: "10px" }}>Selected Privileges:</b>
                    {employee.pageName.map((value, index) => {
                      return <span key={index}>{value}; </span>;
                    })}
                  </div>

                  {/* <FormControl className={clsx(classes.pos, classes.textField, classes.formControl)}>
              <InputLabel id="demo-mutiple-checkbox-label" className ={classes.label}>Privileges</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                name='pageName'
                multiple
                value={employee.pageName}
                onChange={onChange}
                input={<Input />}
                renderValue={(selected) => selected.join(',')}
                MenuProps={MenuProps}
              >
                {pages.map((page) => (
                  <MenuItem key={page} value={page}>
                    <Checkbox checked={employee.pageName.indexOf(page) > -1} color='primary'/>
                    <ListItemText primary={page} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
                </form>
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    Add Privileges
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Select Privileges"}
                    </DialogTitle>
                    <DialogContent>
                      <div>
                        {Object.keys(drawerCollapseable).map((page, index) => (
                          <Accordion
                            expanded={expanded === page}
                            onChange={handleChangex(page)}
                            key={page}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2bh-content"
                              id="panel2bh-header"
                            >
                              <Typography className={classes.heading}>
                                {page}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormGroup row>
                                {drawerCollapseable[page].map((pagex) => (
                                  <FormControlLabel
                                    key={pagex}
                                    control={
                                      <Checkbox
                                        name="pageName"
                                        value={pagex}
                                        checked={
                                          employee.pageName.indexOf(pagex) > -1
                                        }
                                        onChange={onChangePrivilege}
                                      />
                                    }
                                    label={pagex}
                                  />
                                ))}
                              </FormGroup>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={handleClose} color="primary" autoFocus>
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </CardContent>

              <div>
                {showSpinner ? (
                  <div>
                    <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                      <h5>Please DO NOT REFRESH the page...</h5>
                    </span>
                    <CircularProgress style={{ color: "#18D26E" }} />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={EditEmployee}
                >
                  Update Employee
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEmployee;
