import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const proprietes = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
};

export default function ImageModel({ openModel, closeModel, imagesData }) {
  console.log(imagesData);
  return (
    <div>
      <Dialog
        open={openModel}
        onClose={closeModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="slide-container">
            <Slide {...proprietes}>
              {imagesData.length
                ? imagesData?.map((slideImageUrl, index) => (
                    <div className="each-slide" key={index}>
                      <div>
                        <img width="70%" src={slideImageUrl} alt="img1" />
                      </div>
                    </div>
                  ))
                : null}
            </Slide>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModel} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
