import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
export default function MobileInnerCol({ cols, data }) {
  return (
    <List component="ul" aria-label="data" style={{ flex: 1 }}>
      {cols.map((col, index) => (
        <React.Fragment key={col.key}>
          <Divider />
          <ListItem>
            <ListItemText primary={col.label} />
            <ListItemText
              primary={""}
              secondary={data[col.key]}
              style={{ flex: 1 }}
            />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
}
