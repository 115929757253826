import React, { useEffect, useState } from "react";

import XLSX from "xlsx";
import Layout from "../../common/Layout";
import ProductsTable from "./Components/ProductsTable";
import Typography from "@material-ui/core/Typography";

import { Button, MenuItem, TextField } from "@material-ui/core";
import { ColorButton } from "../../common/Components/ColorButton";
import { getPageName } from "../../common/const";

import swal from "sweetalert";
import {
  getProductDataOfSeller,
  getSellersForProductApproval,
} from "../../backend/getDataFromFirestore";
import { useLocation } from "react-router-dom";

const Products = () => {
  let file = [];
  const [productData, setProductData] = useState([]);
  const location = useLocation();

  const catalogData = location.state.catalogData;

  const isApproved = location.state.isApproved;
  const rejectedBy = location.state.catalogData.rejectedBy;

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async (e) => {
    //Read Data from Firebase

    if (catalogData) {
      const data = await getProductDataOfSeller(catalogData.id, isApproved);
      setProductData(data);
    } else {
      swal("Error", "Please Select seller", "error");
    }
  };

  return (
    <>
      <Layout />
      <div className="m-5 pt-5">
        <h2 style={{ textAlign: "center" }}>Seller Product Catalog</h2>
        <div className="row">
          <div className="col-12 col-md-12">
            <ProductsTable
              productData={productData}
              productLength={productData.length}
              seller={catalogData}
              isApproved={isApproved}
              rejectedBy={rejectedBy}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
