import React, { useEffect, useState } from "react";
import Layout from "../../common/Layout";
import { makeStyles } from "@material-ui/core/styles";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getRoleSF } from "../../common/const";
import { getSalesUserFromId } from "../../backend/getDataFromFirestore";
import { ColorButton } from "../../common/Components/ColorButton";
import { Button, Typography } from "@material-ui/core";

const ShowMissedMeetings = () => {
  const role = localStorage.getItem("role");
  const roleSF = getRoleSF[role];
  if (!roleSF) {
    window.location.pathname = "/dashboard";
  }
  const seId = localStorage.getItem("userDocId");

  const [meetings, setMeetings] = useState([]);
  const date = new Date();
  let endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()-1);

  const db = useFirestore();
  let meetingRef;
  if (roleSF === "sa") {
    meetingRef = db.collection("Meetings").where("date", "<", endDate);
    //   .where("checkIn", "==", "");
  } else {
    meetingRef = db
      .collection("Meetings")
      //   .where("checkIn", "!=", "")
      .where("date", "<", endDate)
      .where(roleSF, "==", seId);
  }

  const { status, data } = useFirestoreCollectionData(meetingRef);
  const [filterStatus, setFilterStatus] = useState("");

  useEffect(() => {
    console.log("It rendered");
    if (data) {
      console.log(data);
      getBasicDataa(data);
      setSearchResult(searchMeetingResult(searchValue, filterStatus));
    }
  }, [data]);

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  async function getBasicDataa(data) {
    const missingMeeting = await data.filter((meet) => {
        console.log(getDateFormat(meet.date), endDate)
      return !meet.hasOwnProperty("checkIn");
    });
    getBasicData(missingMeeting);
  }
 
  async function getBasicData(data) {
    let meetingss = [];
    for (const meet of data) {
      let createdBy = await getSalesUserFromId(meet["createdBy"]);
      if (createdBy) {
        meetingss.push({
          ...meet,
          createdByEmail: createdBy.email,
          createdByRole: createdBy.designation,
        });
      }
    }
    setMeetings(meetingss);
  }

  //  Search & filter in textbox
  const searchMeetingResult = (name, status) => {
    return meetings.filter((pros) => {
      if (name && status) {
        console.log(pros);
        return (
          pros.name &&
          pros.name.toLowerCase().includes(name.toLowerCase()) &&
          pros.status &&
          pros.status.toLowerCase() === status.toLowerCase()
        );
      }
      if (name) {
        return (
          pros.name && pros.name.toLowerCase().includes(name.toLowerCase())
        );
      }
      if (status) {
        return (
          pros.status && pros.status.toLowerCase() === status.toLowerCase()
        );
      }
      return true;
    });
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchMeetingResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    setSearchResult(searchMeetingResult(searchValue, status));
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const getDateFormat = (obj) => {
    let date = new Date(obj.seconds * 1000);
    return date.toLocaleString();
  };
  // table view
  const LeadsTableView = ({ meetings }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedMeeting, setSelectedMeeting] = useState(null);

    async function showDialog(meeting) {
      setSelectedMeeting({ ...meeting });
      setOpenDialog(true);
    }
    async function handleClose() {
      setOpenDialog(false);
    }
    return (
      <div className="row align-items-center">
        <Table key="LeadTable">
          <Thead>
            <Tr>
              <Th>Meeting Name</Th>
              <Th>Client Email</Th>
              <Th>Role</Th>
              <Th>Creator Email</Th>
              <Th>Created Date</Th>
              <Th>Reason</Th>
            </Tr>
          </Thead>
          <Tbody>
            {meetings.map((meet, index) => {
              return (
                <Tr key={index}>
                  <Td>{meet.name}</Td>
                  <Td>{meet.email}</Td>
                  <Td>{meet.createdByRole}</Td>
                  <Td>{meet.createdByEmail}</Td>
                  <Td>{getDateFormat(meet.date)}</Td>
                  <Td>
                    <ColorButton onClick={(e) => showDialog(meet)}>
                      Reason
                    </ColorButton>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>

          <Dialog
            open={openDialog}
            fullWidth={true}
            maxWidth={"xs"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <ShowReasonDialog
              meeting={selectedMeeting}
              handleClose={handleClose}
            />
          </Dialog>
        </Table>
      </div>
    );
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h3 style={{ textAlign: "center" }}>View Locations</h3>

        {meetings.length === 0 ? (
          <div className="row justify-content-center">
            <p>No lead's yet!</p>
          </div>
        ) : (
          <>
            {meetings.length > 0 ? (
              <LeadsTableView meetings={meetings} />
            ) : (
              <>
                {searchResult.length > 0 ? (
                  <LeadsTableView meetings={searchResult} />
                ) : (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

const ShowReasonDialog = ({ meeting, handleClose }) => {
  return (
    <span>
      <DialogTitle id="alert-dialog-title">{"Show Reason"}</DialogTitle>
      <DialogContent>
        <Typography>
          {meeting.missingReason ?? "No reason was given"}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </span>
  );
};

export default ShowMissedMeetings;
