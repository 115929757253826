import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";
import { useFirebaseApp, useFirestore, useUser } from "reactfire";
import "firebase/storage";
import { useHistory } from "react-router-dom";
import { getPageName, clientModelTypes } from "../../common/const";
import {
  sendMailToFranchise,
} from "../../backend/sendRequestToServer";
import swal from "sweetalert";
import { Card, Dialog } from "@material-ui/core";
import BlockClient from "../Blocking/BlockClient";
import UnblockClient from "../Blocking/UnblockClientDialog";
import DiscontinueClient from "../Blocking/DiscontinueClient";

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  let history = useHistory();
  const { data: firebaseUser } = useUser();
  let role = localStorage.getItem("role");

  useEffect(() => {
    if (firebaseUser) {
      fetchClients();
    }
  }, [firebaseUser]);

  function showActions() {
    if (role === "Super Admin" || role === "User Manager") {
      return true;
    }
    return false;
  }

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  // function delete blogs

  function DeleteClient(id) {
    console.log("this is ", id);
    db.collection("Clients")
      .doc(id)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  }

  // function edit blogs

  function EditClient(client) {
    console.log("this is ", client);
    history.push("/add-client", { ...client, approved: true });
  }

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const fetchClients = async () => {
    console.log("Role: ", role);
    let designation = "se";
    switch (role) {
      case "Super Admin":
        designation = "sa";
        break;
      case "User Manager":
        designation = "sa";
        break;
      case "National Sales Head":
        designation = "sa";
        break;
      case "Regional Sales Head":
        designation = "rsh";
        break;
      case "City Sales Head":
        designation = "csh";
        break;
      case "Sales Executive":
        designation = "se";
        break;
      default:
        designation = "se";
    }
    let response;
    if (designation === "sa") {
      response = db.collection("Clients").where("role", "==", "Super Admin");
    } else {
      const userDocId = localStorage.getItem("userDocId");
      response = db
        .collection("Clients")
        .where("role", "==", "Super Admin")
        .where(designation, "==", userDocId);
    }

    const data = await response.get();
    let clientsData = [];
    data.docs.forEach((item) => {
      clientsData.push(item.data());
      // console.log(item.data());
    });

    setClients(clientsData);
  };

  //  View selected blog btn click
  const handleViewBtn = (client_detail) => {
    console.log(client_detail);
    history.push("/client-details", client_detail);
  };

  // Edit Region
  const addRegionBtn = (client_id) => {
    console.log(client_id);
    history.push("/add-region", client_id);
  };

  const addBranchBtn = (client_id) => {
    history.push("/add-branch", client_id);
  };

  const viewBranchBtn = (client_id) => {
    history.push("/view-branch", client_id);
  };
  //  Search in textbox
  const searchClientResult = (val) => {
    return clients.filter(
      (data) =>
        data.name.toLowerCase().includes(val.toLowerCase()) ||
        data.billingCity.toLowerCase().includes(val.toLowerCase()) ||
        data.billingState.toLowerCase().includes(val.toLowerCase())
    );
  };

  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);

    setSearchResult(searchClientResult(e.target.value));
  };

  const [selectedClientData, setSelectedClientData] = useState(null);
  const [openBlockDialog, setOpenBlockDialog] = useState(false);
  const [openUnblockDialog, setOpenUnblockDialog] = useState(false);
  const [openDiscontinueDialog, setOpenDiscontinueDialog] = useState(false);

  async function handleClose() {
    setOpenBlockDialog(false);
    setOpenUnblockDialog(false);
    setOpenDiscontinueDialog(false);
  }

  const blockClientDialog = (data) => {
    setSelectedClientData({ ...data });
    setOpenBlockDialog(true);
  };
  const unblockClientDialog = (data) => {
    setSelectedClientData({ ...data });
    setOpenUnblockDialog(true);
  };
  const discontinueClientDialog = (data) => {
    setSelectedClientData({ ...data });
    setOpenDiscontinueDialog(true);
    // const toDelete = await swal({
    //   title: "Are you sure?",
    //   text: "Once deleted, you will not be able to recover this Client!",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // });
    // if (!toDelete) {
    //   return;
    // }
    // const res = await discontinueClient({ clientId: clientId });
    // if (res.message) {
    //   swal("Success!", "Client has been Deleted Successfully", "success").then(
    //     () => {
    //       // window.location.reload();
    //     }
    //   );
    // } else {
    //   swal("Error!", "There is something error", "error");
    // }
  };
  const client_list = (client) => {
    return (
      <Media list key={client.id} className="blog-card">
        <Media tag="li">
          <Media left href="#">
            <Avatar
              src={client.logo}
              alt={client.name}
              style={{
                minHeight: 100,
                minWidth: 100,
                marginRight: 8,
              }}
            />
          </Media>
          <Media body>
            <Media heading>{client.name}</Media>
            <div>
              <p className="author-name">~ {client.billingCity}</p>
              {client.billingState}
              <p className="view-edit">
                {showActions() ? (
                  <span>
                    <IconButton
                      aria-label="delete"
                      id={client.id}
                      onClick={(e) => DeleteClient(client.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      id={client.id}
                      onClick={(e) => EditClient(client)}
                    >
                      <CreateIcon />
                    </IconButton>
                  </span>
                ) : null}
                <ColorButton
                  variant="contained"
                  className={classes.button}
                  onClick={() => handleViewBtn(client)}
                >
                  View
                </ColorButton>
                {(client.modelType === clientModelTypes.model1 ||
                  client.modelType === clientModelTypes.model2 ||
                  client.modelType === clientModelTypes.model3) && (
                  <ColorButton
                    variant="contained"
                    className={classes.button}
                    onClick={() => addBranchBtn(client.id)}
                    disabled={client?.isDiscontinue}
                  >
                    Add Branch
                  </ColorButton>
                )}
                {(client.modelType === clientModelTypes.model1 ||
                  client.modelType === clientModelTypes.model2 ||
                  client.modelType === clientModelTypes.model3) && (
                  <ColorButton
                    variant="contained"
                    className={classes.button}
                    onClick={() => viewBranchBtn(client.id)}
                  >
                    View Branch
                  </ColorButton>
                )}
                {client.modelType === clientModelTypes.model3 && (
                  <ColorButton
                    variant="contained"
                    className={classes.button}
                    onClick={() => addRegionBtn(client.id)}
                    disabled={client?.isDiscontinue}
                  >
                    View/Edit Regions
                  </ColorButton>
                )}
              </p>
              <br></br>
              <div className="view-edit">
                <ColorButton
                  variant="contained"
                  className={classes.button}
                  onClick={() => blockClientDialog(client)}
                >
                  Block Client
                </ColorButton>
                <ColorButton
                  variant="contained"
                  className={classes.button}
                  onClick={
                    () => unblockClientDialog(client)
                    // console.log(sendMailToFranchise())
                  }
                >
                  Unblock Client
                </ColorButton>
                <ColorButton
                  variant="contained"
                  className={classes.button}
                  onClick={() => discontinueClientDialog(client)}
                  disabled={client?.isActive}
                >
                  Discontinue Client
                </ColorButton>
              </div>
            </div>
            <Card>
              <Dialog
                open={openBlockDialog}
                fullWidth={true}
                maxWidth={"xs"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <BlockClient
                  clientData={selectedClientData}
                  handleClose={handleClose}
                />
              </Dialog>
              <Dialog
                open={openUnblockDialog}
                fullWidth={true}
                maxWidth={"xs"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <UnblockClient
                  clientData={selectedClientData}
                  handleClose={handleClose}
                />
              </Dialog>
              <Dialog
                open={openDiscontinueDialog}
                fullWidth={true}
                maxWidth={"xs"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DiscontinueClient
                  clientData={selectedClientData}
                  handleClose={handleClose}
                />
              </Dialog>
            </Card>
          </Media>
        </Media>
      </Media>
    );
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>Approved Clients</h2>
        <div className="row pb-3 justify-content-center">
          <input
            type="text"
            className="col-10 col-md-6 search-box"
            placeholder="Search Client"
            onChange={(e) => {
              search(e);
            }}
          />
        </div>
        {clients.length === 0 ? (
          <div className="row justify-content-center">
            <p>No Client's yet!</p>
          </div>
        ) : (
          <>
            {clients.length > 0 && searchValue === "" ? (
              clients.map((client) => {
                return client_list(client);
              })
            ) : (
              <>
                {searchResult.length > 0 ? (
                  searchResult.map((client) => {
                    return client_list(client);
                  })
                ) : (
                  <>
                    {searchValue !== "" ? (
                      <div className="row pb-3 justify-content-center">
                        <div>Search Not Found!</div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ClientList;
