import { makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  getHistoryData,
  getProductsCategoriesDetails,
  prospectDataforGRContract,
} from "../../backend/getDataFromFirestore";
import Layout from "../../common/Layout";
import { ColorButton } from "../../common/Components/ColorButton";
import { MenuItem, TextField } from "@material-ui/core";
import {
  saveQuotationAsVersion,
  saveSelectedProducts,
  sendProductRequestToPM,
} from "../../backend/saveDataToFirestore";
import swal from "sweetalert";
import RateContractProvider, {
  RateContractContext,
} from "../../common/RateContractProvider";
import { ProductsTable } from "./Components/ProductsTable";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: "69ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  stepperColor: {
    color: "#18D26E",
  },
  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
  },
  products: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  category: {
    width: "29ch",
    marginRight: "2em",
    paddingBottom: "10px",
  },
  button: {
    margin: "1em",
    width: "20ch",
  },
}));

const ClientProductList = () => {
  const classes = useStyles();

  const location = useLocation();
  let isPAN = location.state.isPAN ?? false;
  let branchCities = location.state.branchCities ?? [];
  let selectedProducts = location.state.selectedProducts ?? {};
  let selectedProductsData = location.state.selectedProductsData ?? {};
  let noOfQuotation = location.state.noOfQuotation ?? 1;

  let prospectId = location.state.prospectId ?? "";
  let prospectName = location.state.prospectName ?? "";
  const prospectDetails = { prospectId, prospectName };

  const [city, setCity] = useState("");

  const [category, setCategory] = useState({});
  const [categoryDetails, setCategoryDetails] = useState({
    category: "",
    subCategory: "",
  });

  const [cities, setCities] = useState({});
  const [citiesSRPM, setCitiesSRPM] = useState({});
  const [showProducts, setShowProducts] = useState(false);

  useEffect(() => {
    getCategories();
    getCitiesAndSelectedProducts();
  }, []);

  async function getCitiesAndSelectedProducts() {
    let newCities = {};

    if (isPAN) {
      newCities["PAN-Products"] = selectedProducts["PAN-Products"] ?? {};
      setCity("PAN-Products");
    } else {
      for (let city of branchCities) {
        if (newCities[city.value]) continue;
        // let sProducts = await getSelectedProductsOfCity(prospectId, branch.city);
        if (selectedProducts.hasOwnProperty(city.value))
          newCities[city.value] = selectedProducts[city.value];
        else newCities[city.value] = {};
      }
    }
    setCities(newCities);
  }

  useEffect(() => {
    async function getRequestt() {
      let newCitiesSRPM = {};
      if (isPAN) {
        newCitiesSRPM["PAN-Products"] = {};
        setCity("PAN-Products");
      } else {
        for (let city of branchCities) {
          newCitiesSRPM[city.value] = {};
        }
      }
      let pmRequests = [];
      pmRequests = await getPmRequests();
      if (pmRequests.length) {
        pmRequests.forEach((req) => {
          if (isPAN && req.city === "PAN-Products" && req.status === "Pending") {
            newCitiesSRPM["PAN-Products"][req.id] = req;
          } else if (!isPAN && req.city !== "PAN-Products" && req.status === "Pending") {
            newCitiesSRPM[req.city][req.id] = req;
          }
        });
      }
      setCitiesSRPM(newCitiesSRPM);
    }
    getRequestt();
  }, []);

  async function getPmRequests() {
    const seId = localStorage.getItem("userDocId");
    const requestSentToPM = await prospectDataforGRContract(
      seId,
      prospectDetails
    );
    return requestSentToPM;
  }
  async function getCategories() {
    let temp = await getProductsCategoriesDetails();
    setCategory(temp);
  }

  // const savePriceOfProduct = (price, id, checked = true) => {
  //   if (checked) {
  //     cities[city][id] = price;
  //     setCities({ ...cities });
  //     // setSelectedProducts({ ...cities[city] });
  //   } else {
  //     delete cities[city][id];
  //     setCities({ ...cities });

  //     // setSelectedProducts({ ...cities[city] });
  //   }
  //   // setSelectedProducts(newProducts);
  //   console.log(cities);
  // };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h3 className="text-center">Product List</h3>
        <RateContractProvider
          cityProducts={cities}
          cities={citiesSRPM}
          productsData={selectedProductsData}
        >
          <form className={classes.form} noValidate autoComplete="off">
            <div>
              {!isPAN && (
                <TextField
                  required
                  id="outlined-branches"
                  select
                  label="Select City"
                  variant="outlined"
                  className={classes.category}
                  onChange={(e) => {
                    setCity(e.target.value);
                    setShowProducts(false);
                  }}
                >
                  {Object.keys(cities).map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <TextField
                id="outlined-category"
                select
                label="Type of Category"
                variant="outlined"
                className={classes.category}
                value={categoryDetails.category || ""}
                onChange={(e) => {
                  setCategoryDetails({
                    category: e.target.value,
                    subCategory: "",
                  });
                  setShowProducts(false);
                }}
              >
                {Object.keys(category).map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                disabled={categoryDetails.category === ""}
                id="outlined-sub-category"
                select
                label="Type of Sub-Category"
                variant="outlined"
                className={classes.category}
                value={categoryDetails.subCategory || ""}
                onChange={(e) => {
                  setCategoryDetails({
                    ...categoryDetails,
                    subCategory: e.target.value,
                  });
                  setShowProducts(false);
                }}
              >
                {category[categoryDetails.category] ? (
                  category[categoryDetails.category].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value={""}>
                    No Option
                  </MenuItem>
                )}
              </TextField>

              <ColorButton
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setShowProducts(true);
                }}
              >
                Get Products
              </ColorButton>

              <SaveSelectedButton
                prospectDetails={prospectDetails}
                isPAN={isPAN}
              />
              <SaveQuotationButton
                isPAN={isPAN}
                prospectDetails={prospectDetails}
                noOfQuotation={noOfQuotation}
              />
            </div>
          </form>

          {showProducts && city && (
            <>
              <div className="row align-items-center justify-content-center mt-5">
                <ProductsTable
                  isPAN={isPAN}
                  city={city}
                  category={categoryDetails.category}
                  subCategory={categoryDetails.subCategory}
                  prospectDetails={prospectDetails}
                />
              </div>
            </>
          )}
        </RateContractProvider>
      </div>
    </Layout>
  );
};

export default ClientProductList;

const SaveSelectedButton = ({ prospectDetails, isPAN }) => {
  const { prospectId } = prospectDetails;
  const { selectedProducts, selectedProductsData, selectedSendRqstToPM } =
    useContext(RateContractContext);

  //TODO: Save products w.r.t Cities
  async function saveProducts() {
    for (let checkMRP of Object.keys(selectedProductsData)) {
      if (
        parseFloat(selectedProductsData[checkMRP].Rate) +
          parseFloat(selectedProductsData[checkMRP].Rate) *
            (selectedProductsData[checkMRP].GST / 100) >
        parseFloat(selectedProductsData[checkMRP].MRP)
      ) {
        swal(
          "Error",
          "Inclusive GST Rate should not be more than MRP",
          "error"
        );
        return;
      } else {
        continue;
      }
    }

    const swalRes = await swal(
      "Save Rate Contract",
      "Are you sure you have selected all the client's requested products for the rate contract ? ",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    for (let city of Object.keys(selectedProducts)) {
      if (
        selectedProducts[city] &&
        Object.keys(selectedProducts[city]).length > 0
      ) {
        continue;
      } else {
        swal(
          "Error",
          "Please select atleast one product for each city.",
          "error"
        );

        return;
      }
    }
    const res = await saveSelectedProducts(
      prospectId,
      selectedProducts,
      isPAN,
      selectedProductsData
    );
    if (res.successfull) {
      const seId = localStorage.getItem("userDocId");
      const role = localStorage.getItem("role");
      const seEmail = localStorage.getItem("email");
      const seName = localStorage.getItem("name");

      for (let cities of Object.keys(selectedSendRqstToPM)) {
        for (let productId of Object.keys(selectedSendRqstToPM[cities])) {
          //
          if (productId) {
            const nexstocPrice = selectedProducts[cities][productId];
            if (!!nexstocPrice) {
              const product = selectedSendRqstToPM[cities][productId];
              const history = {
                price: product.prospectPrice,
                role: role,
                date: new Date(),
              };
              let priceHistory = [];
              priceHistory = await getHistoryData(
                seId,
                product,
                prospectDetails.prospectId
              );
              priceHistory.push(history);
              await sendProductRequestToPM(
                product,
                cities,
                seId,
                seEmail,
                seName,
                prospectDetails.prospectId,
                prospectDetails.prospectName,
                product.prospectPrice,
                priceHistory
              );
            }
          }
          //
        }
      }
      swal("Success", "Product Prices Saved", "success").then((val) =>
        window.history.back()
      );
    } else {
      swal("Error", "Error in saving Product Prices", "error");
    }
  }

  return (
    <ColorButton
      variant="contained"
      color="primary"
      onClick={(e) => {
        e.preventDefault();
        saveProducts();
      }}
    >
      Save Products
    </ColorButton>
  );
};

const SaveQuotationButton = ({ isPAN, prospectDetails, noOfQuotation }) => {
  const { prospectId } = prospectDetails;
  const { selectedProducts, selectedSendRqstToPM, selectedProductsData } =
    useContext(RateContractContext);

  async function saveQuotation() {
    const seId = localStorage.getItem("userDocId");
    const requestSentToPM = await prospectDataforGRContract(
      seId,
      prospectDetails
    );
    let checking = false;
    // console.log(selectedSendRqstToPM);
    requestSentToPM.forEach(
      (req) => (checking = req.statusBySE === "Pending" ? true : false)
    );
    //
    if (isPAN) {
      checking =
        Object.keys(selectedSendRqstToPM["PAN-Products"]).length !== 0
          ? true
          : false;
    } else {
      for (let city of Object.keys(selectedSendRqstToPM)) {
        checking =
          Object.keys(selectedSendRqstToPM[city]).length !== 0 ? true : false;
      }
    }
    //
    if (checking) {
      swal(
        "Error",
        "There is Pending request To PM. You Can't Save Quotaion!",
        "error"
      );
      return;
    } else {
      for (let productId of Object.keys(selectedProductsData)) {
        if (
          selectedProductsData[productId] &&
          +selectedProductsData[productId]["Rate"] <
            +selectedProductsData[productId]["NexstocPrice"]
        ) {
          swal("Error", "Rate should not be less than Nexstoc Price!", "error");
          return;
        }
      }
      for (let city of Object.keys(selectedProducts)) {
        if (
          selectedProducts[city] &&
          Object.keys(selectedProducts[city]).length > 0
        ) {
          continue;
        } else {
          swal(
            "Error",
            "Please select atleast one product for each city.",
            "error"
          );
          return;
        }
      }
    }
    const swalRes = await swal(
      "Save Quotation As Version",
      "Are you sure you wanted to save Rate Contract as a Version ? ",
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    const res = await saveQuotationAsVersion(
      prospectId,
      selectedProducts,
      noOfQuotation
    );
    if (res.successfull) {
      swal("Success", res.message, "success").then(() => {
        window.history.back();
      });
    }
  }

  return (
    <ColorButton
      variant="contained"
      color="primary"
      style={{ marginLeft: "10px" }}
      onClick={(e) => {
        e.preventDefault();
        saveQuotation();
      }}
    >
      Save Quotation As Version {noOfQuotation}
    </ColorButton>
  );
};
