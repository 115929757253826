import React, { useState } from "react";
import Layout from "../../common/Layout";
import { TabMenu } from "../../common/Components/TabMenu";
import { TAB_NAME } from "../../common/const1";
import { StyledPrimaryButton } from "../../common/Components/ColorButton";
import AddIcon from "@material-ui/icons/Add";
import { OPEN_EMPLOYEE_MANAGEMENT_MODAL_TITLE } from "./constants";
import AddNewProfile from "./Common/AddNewProfile";
import AddNewEmployee from "./Common/AddNewEmployee";
import { useGetPermissions } from "context/PermissionsProvider";

function EmployeeManagement({ children, fetchProfiles }) {
  const { userPermissions } = useGetPermissions();
  const [showModal, setShowModal] = useState(null);

  const canAddEmployee = userPermissions?.["Employee"]?.Add;
  const canAddProfile = userPermissions?.["Profile"]?.Add;

  const onCloseModal = (isFetch) => {
    if (
      isFetch === true &&
      showModal === OPEN_EMPLOYEE_MANAGEMENT_MODAL_TITLE.PROFILE
    ) {
      fetchProfiles();
    }
    setShowModal(null);
  };

  return (
    <>
      {canAddEmployee && (
        <AddNewEmployee
          open={OPEN_EMPLOYEE_MANAGEMENT_MODAL_TITLE.EMPLOYEE === showModal}
          onClose={onCloseModal}
        />
      )}
      {canAddProfile && (
        <AddNewProfile
          open={OPEN_EMPLOYEE_MANAGEMENT_MODAL_TITLE.PROFILE === showModal}
          onClose={onCloseModal}
        />
      )}
      <Layout>
        <div className="mt-5 pt-5 mx-5">
          <TabMenu tabName={TAB_NAME.EMPLOYEES}>
            <div className="flex gap-2">
              {canAddEmployee && (
                <StyledPrimaryButton
                  startIcon={<AddIcon />}
                  onClick={() =>
                    setShowModal(OPEN_EMPLOYEE_MANAGEMENT_MODAL_TITLE.EMPLOYEE)
                  }
                >
                  Create Employee
                </StyledPrimaryButton>
              )}
              {canAddProfile && (
                <StyledPrimaryButton
                  startIcon={<AddIcon />}
                  onClick={() =>
                    setShowModal(OPEN_EMPLOYEE_MANAGEMENT_MODAL_TITLE.PROFILE)
                  }
                >
                  Create Profile
                </StyledPrimaryButton>
              )}
            </div>
          </TabMenu>
          <div className="bg-white max-sm:p-2 shadow-md">{children}</div>
        </div>
      </Layout>
    </>
  );
}

export default EmployeeManagement;
