import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import "firebase/firestore";
import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import { useHistory } from "react-router-dom";
import { getPageName } from "../../common/const";

const ApproveClient = () => {
  const [clients, setClients] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  let history = useHistory();

  useEffect(() => {
    fetchClients();
  }, []);

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  // function delete blogs

  function DeleteClient(id) {
    console.log("this is ", id);
    db.collection("Prospects")
      .doc(id)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  }

  // function edit blogs

  function EditClient(client) {
    console.log("this is ", client);
    history.push("/add-client", { ...client, approved: false });
  }

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const fetchClients = async () => {
    const response = db.collection("Prospects");
    const data = await response.get();
    let clientsData = [];
    data.docs.forEach((item) => {
      clientsData.push(item.data());
      console.log(item.data());
    });

    setClients(clientsData);
  };

  //  View selected blog btn click
  const handleViewBtn = (client_detail) => {
    console.log(client_detail);
    history.push("/approve-client-details", client_detail);
  };

  //  Search in textbox
  const searchClientResult = (val) => {
    return clients.filter(
      (data) =>
        data.name.toLowerCase().includes(val.toLowerCase()) ||
        data.billingCity.toLowerCase().includes(val.toLowerCase()) ||
        data.billingState.toLowerCase().includes(val.toLowerCase())
    );
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);

    setSearchResult(searchClientResult(e.target.value));
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>
        <div className="row pb-3 justify-content-center">
          <input
            type="text"
            className="col-10 col-md-6 search-box"
            placeholder="Search Client"
            onChange={(e) => {
              search(e);
            }}
          />
        </div>
        {clients.length > 0 && searchValue === "" ? (
          clients.map((client) => {
            return (
              <Media list key={client.id} className="blog-card">
                <Media tag="li">
                  <Media left href="#">
                    <Avatar
                      src={client.logo}
                      alt={client.name}
                      style={{
                        minHeight: 100,
                        minWidth: 100,
                        marginRight: 8,
                      }}
                    />
                  </Media>
                  <Media body>
                    <Media heading>{client.name}</Media>
                    <p className="author-name">City: {client.billingCity}</p>
                    State : {client.billingState}
                    <p className="view-edit">
                      <IconButton
                        aria-label="delete"
                        id={client.id}
                        onClick={(e) => DeleteClient(client.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        id={client.id}
                        onClick={(e) => EditClient(client)}
                      >
                        <CreateIcon />
                      </IconButton>
                      <ColorButton
                        variant="contained"
                        className={classes.button}
                        onClick={() => handleViewBtn(client)}
                      >
                        View
                      </ColorButton>
                    </p>
                  </Media>
                </Media>
              </Media>
            );
          })
        ) : (
          <>
            {searchResult.length > 0 ? (
              searchResult.map((client) => {
                return (
                  <Media list key={client.id} className="blog-card">
                    <Media tag="li">
                      <Media left href="#">
                        <Avatar
                          src={client.logo}
                          alt={client.name}
                          style={{
                            minHeight: 100,
                            minWidth: 100,
                            marginRight: 8,
                          }}
                        />
                      </Media>
                      <Media body>
                        <Media heading>{client.name}</Media>
                        <p className="author-name">~ {client.billingCity}</p>
                        {client.billingState}
                        <p className="view-edit">
                          <IconButton
                            aria-label="delete"
                            id={client.id}
                            onClick={(e) => DeleteClient(client.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            id={client.id}
                            onClick={(e) => EditClient(client)}
                          >
                            <CreateIcon />
                          </IconButton>
                          <ColorButton
                            variant="contained"
                            className={classes.button}
                            onClick={() => handleViewBtn(client)}
                          >
                            View
                          </ColorButton>
                        </p>
                      </Media>
                    </Media>
                  </Media>
                );
              })
            ) : (
              <>
                {searchValue !== "" ? (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ApproveClient;
