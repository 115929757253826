import DateFnsUtils from "@date-io/date-fns";
import { Divider, MenuItem, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import {
  CancelButton,
  ColorButton,
  GoogleMeetButton,
} from "../../common/Components/ColorButton";
import Layout from "../../common/Layout";
import { getClients_ } from "../../backend/getDataFromFirestore";
import { useUser } from "reactfire";
import { saveMeetingData } from "../../backend/saveDataToFirestore";
import swal from "sweetalert";
import { useLocation } from "react-router";
import { editMeetingData } from "../../backend/editDataInFirestore";
import { Icon } from "@iconify/react";
import { disableCache } from "@iconify/react";
import { Autocomplete } from "@material-ui/lab";
import DrawerWrapper from "common/DrawerWrapper/DrawerWrapper";

disableCache("local");
const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
  },
  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
    marginBottom: "10px",
  },
  textField: {
    marginBottom: "24px",
  },
  textFieldInput: {
    fontWeight: 500,
  },
  textFieldInputt: {
    marginBottom: "24px",
    fontWeight: 500,
  },
  saveButton: {
    height: "42px",
    width: "273px",
    fontSize: "15px",
    fontWeight: "600",
    marginRight: "6px",
  },
  meetButton: {
    height: "42px",
    width: "540px",
    marginBottom: "20px",
    textTransform: "capitalize",
  },
}));

const AddMeeting = ({ open, onClose }) => {
  const location = useLocation();
  let meeting = location.state;

  const classes = useStyles();

  const [showClientErr, setShowClientErr] = useState(false);
  const [clients, setClients] = useState({});

  const initialState = {
    client: "",
    name: "",
    email: "",
    phone: "",
    agenda: "",
    date: new Date(),
    csh: "",
    nsh: "",
    rsh: "",
    se: "",
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (meeting) {
      setFormData({ ...meeting, date: new Date(meeting.date.seconds * 1000) });
    }
    getClients();
  }, []);

  async function getClients() {
    const res = await getClients_();
    setClients(res);
  }

  const handleDateChange = (newDate) => {
    setFormData({ ...formData, date: newDate });
  };
  function handleChangeClient(id) {
    if (!id) {
      setFormData({
        ...formData,
        client: "",
        name: "",
        email: "",
        phone: "",
        csh: "",
        nsh: "",
        rsh: "",
        se: "",
        lat: "",
        lng: "",
      });
    } else {
      setFormData({
        ...formData,
        client: id,
        name: clients[id]["companyName"],
        email: clients[id]["personalemail"],
        phone: clients[id]["mobileno"],
        csh: clients[id]["csh"],
        nsh: clients[id]["nsh"],
        rsh: clients[id]["rsh"],
        se: clients[id]["se"],
        lat: clients[id]["lat"],
        lng: clients[id]["lng"],
      });
    }
  }

  function handleChange(e) {
    console.log("e.target.name", e.target.name);
    if (e.target.name === "client") {
      setFormData({
        ...formData,
        client: e.target.value,
        name: clients[e.target.value]["companyName"],
        email: clients[e.target.value]["personalemail"],
        phone: clients[e.target.value]["mobileno"],
        csh: clients[e.target.value]["csh"],
        nsh: clients[e.target.value]["nsh"],
        rsh: clients[e.target.value]["rsh"],
        se: clients[e.target.value]["se"],
        lat: clients[e.target.value]["lat"],
        lng: clients[e.target.value]["lng"],
      });
      setShowClientErr(false);
    } else {
      setFormData({ ...formData, [`${e.target.name}`]: e.target.value });
    }
  }

  async function editMeeting() {
    const res = await editMeetingData(formData);
    if (res) {
      swal("Success!", "Meeting Updated", "success").then((value) => {
        window.location.pathname = "/view-meetings";
      });
    } else {
      swal("Failed!", "Unable to update Meeting. Please try again.", "error");
    }
  }

  async function saveMeeting() {
    if (validateField()) {
      const res = await saveMeetingData(formData);
      if (res) {
        swal("Success!", "Meeting Saved", "success").then((value) => {
          setFormData(initialState);
        });
      } else {
        swal("Failed!", "Unable to Save Meeting. Please try again.", "error");
      }
    }
  }

  function validateField() {
    if (formData.client === "") {
      setShowClientErr(true);
      return false;
    }
    return true;
  }
  console.log(formData);
  return (
    <DrawerWrapper state={open} onClose={onClose}>
      <Typography
        variant="h5"
        style={{ fontWeight: 600, marginBottom: "28px" }}
      >
        Add Meeting
      </Typography>
      <form
        id="meeting-form"
        className={classes.form}
        noValidate
        autoComplete="off"
      >
        <Autocomplete
          id="clientName"
          name="client"
          options={Object.keys(clients)}
          getOptionLabel={(option) => clients[option].companyName}
          // onInputChange={handleChangeClient}
          onChange={(e, id) => {
            handleChangeClient(id);
          }}
          classes={{ inputRoot: classes.textFieldInputt }}
          ListboxProps={{ style: { maxHeight: "210px" } }}
          renderInput={(params) => (
            <TextField
              name="client"
              {...params}
              label="Choose From Existing Leads"
              error={showClientErr}
              helperText={showClientErr && "Please Choose A Client"}
            />
          )}
        />

        {/* <TextField
          required
          fullWidth
          className={classes.textField}
          InputProps={{
            className: classes.textFieldInput,
          }}
          id="outlined-choose-leads"
          select
          label="Choose From Existing Leads"
          value={formData.client}
          name="client"
          onChange={handleChange}
          error={showClientErr}
          helperText={showClientErr && "Please Choose A Client"}
        >
          {Object.keys(clients).map((option) => (
            <MenuItem key={option} value={option}>
              {clients[option].companyName}
            </MenuItem>
          ))}
        </TextField> */}
        <TextField
          id="outlined-basic"
          label="Meeting Agenda*"
          className={classes.textField}
          InputProps={{
            className: classes.textFieldInput,
          }}
          name="agenda"
          value={formData.agenda}
          onChange={handleChange}
        />
        <TextField
          id="outlined-basic"
          label="Client Name*"
          className={classes.textField}
          InputProps={{
            className: classes.textFieldInput,
          }}
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <div>
          <TextField
            id="outlined-basic"
            label="Client Email*"
            className={classes.textField}
            style={{
              width: "261px",
              marginRight: "18px",
              marginBottom: "0px",
            }}
            InputProps={{
              className: classes.textFieldInput,
            }}
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <span className={classes.errMsg}></span>

          <TextField
            aria-label="empty textarea"
            className={classes.textField}
            style={{ width: "261px", marginBottom: "0px" }}
            InputProps={{
              className: classes.textFieldInput,
            }}
            label="Contact No*"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
          <span className={classes.errMsg}></span>
        </div>
        <br />
        <Divider />
        <br />
        {/* <Typography variant="h6">Time</Typography>
        <Divider /> 
        <br />*/}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            fullWidth
            id="date-picker-dialog"
            required
            InputProps={{
              className: classes.textFieldInput,
            }}
            label="Date"
            format="dd/MM/yyyy"
            value={formData.date}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            inputVariant="filled"
          />
        </MuiPickersUtilsProvider>
        <br />
      </form>
      <GoogleMeetButton variant="contained" className={classes.meetButton}>
        <Icon icon="logos:google-meet" style={{ marginRight: "8px" }} />
        Add Google Meet Video Conferencing
      </GoogleMeetButton>
      <div>
        <ColorButton
          variant="contained"
          color="primary"
          onClick={formData.id ? editMeeting : saveMeeting}
          className={classes.saveButton}
        >
          {formData.id ? "Update Meeting" : "Save Meeting"}
        </ColorButton>
        <CancelButton variant="contained" onClick={() => onClose()}>
          Cancel
        </CancelButton>
      </div>
    </DrawerWrapper>
  );
};

export default AddMeeting;
