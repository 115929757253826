import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import Layout from "../../common/Layout";
import BranchTable from "./Components/BranchTable";
import { ColorButton } from "../../common/Components/ColorButton";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BranchDialog from "../../common/Components/BranchDialog";
import {
  getCitiesList,
  getCityById,
  getClientBranches,
  getClientById,
  getClientCitiesList,
} from "../../backend/getDataFromFirestore";
import swal from "sweetalert";
import { clientModelTypes, SAMPLE_BRANCH_URL } from "../../common/const";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const branchIntialState = {
  name: "",
  address: "",
  pincode: "",
  shippingAddress: "",
  sezLetter: "",
  sezImage: null,
  sezRef: "",
  gstType: "",
  gstNo: "",
  gstImage: null,
  gstRef: "",
  state: "",
  city: "",
  district: "",
  isMailRoom: false,
  franchise: "",
};

const AddBranch = () => {
  const [branchData, setBranchData] = useState([]);
  const [file, setFile] = useState([]);
  const [cities, setCities] = useState([]);
  const [clientClusterCities, setClientClusterCities] = useState([]);
  const [clientClusterCitiesData, setClientClusterCitiesData] = useState([]);
  const [clientData, setClientData] = useState({});
  const [branches, setBranches] = useState([]);

  const classes = useStyles();

  const { dialogInfo, openDialog, closeDialog } = DialogHelper();

  const location = useLocation();
  const client_id = location.state;

  const handleUpload = (event) => {
    setFile(event.target.files);
  };

  const getCities = async () => {
    const cityList = await getCitiesList();
    setCities(cityList);
  };

  useEffect(() => {
    getBranches();
  }, []);

  async function getBranches() {
    const temp = await getClientBranches(client_id);
    setBranches(temp);
    console.log(temp);
  }

  useEffect(() => {
    getCities();
    getClient();
  }, []);

  useEffect(() => {
    if (clientData.modelType === clientModelTypes.model3) {
      getClientClusterCities();
    }
  }, [clientData]);
  useEffect(() => {
    if (
      clientClusterCities.length > 0 &&
      clientData.modelType === clientModelTypes.model3
    ) {
      getClientCitiesData();
    }
  }, [clientData, clientClusterCities]);
  async function getClient() {
    const temp = await getClientById(client_id);
    setClientData(temp);
  }

  async function getClientClusterCities() {
    const temp = await getClientCitiesList(client_id);
    console.log("clientCities ===>", temp);
    setClientClusterCities(temp);
  }
  async function getClientCitiesData() {
    let temp = [];
    for (let clientCity of clientClusterCities) {
      const cityData = await getCityById(clientCity.cityCode_id);
      const data = {
        id: clientCity.cityCode_id,
        value: cityData.city,
      };
      temp.push(data);
    }
    console.log("clientCitiesData ===>", temp);
    setClientClusterCitiesData(temp);
  }
  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `sample.xlsx`;
    link.href = SAMPLE_BRANCH_URL;
    link.click();
  };

  const handleData = async () => {
    if (file.length < 1) {
      await swal("Failed!", "Choose Correct File. Try Again!!!", "error");
      return;
    }
    if (typeof FileReader === "undefined") {
      await swal(
        "Failed!",
        "FileReader not supported in your browser!!!",
        "error"
      );
      return;
    }

    const reader = new FileReader();
    if (reader.readAsBinaryString) {
      reader.onload = (e) => {
        const workbook = XLSX.read(reader.result, { type: "binary" });
        const firstSheet = workbook.SheetNames[0];
        let excelRows = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[firstSheet]
        );
        console.log("excelRow", excelRows);
        let branchArray = [];
        excelRows.forEach((data) => {
          console.log(data);
          const filterCity = cities.filter(
            (city) => city.value === data["City"]
          );
          if (!filterCity.length) {
            swal("Error!", "Cities are not matched! Check again", "error");
            return;
          }
          const state = filterCity[0]?.state;
          let branch = {
            ...branchIntialState,
            name: data["Name"],
            address: data["Address"],
            pincode: data["Pincode"],
            shippingAddress: data["Shipping Address"],
            gstType: data["GST Type"],
            sezLetter: data["SEZ Letter Number"],
            gstNo: data["GST Number"],
            state: state,
            city: data["City"],
            district: data["District"],
            isMailRoom: data["Mailroom"] === "YES",
            nearestVendors: [],
            nearestVendor: {},
            vendor: "",
            vendorName: "",
            vendorType: "",
            vendorDist: "",
          };

          branchArray.push(branch);
        });
        setBranchData(branchArray);
        console.log("Branch Data", branchArray);
      };
      reader.readAsBinaryString(file[0]);
    }
  };

  return (
    <>
      <Layout />
      <div className=" m-5 pt-5">
        <h2 style={{ textAlign: "center" }}>Add Branch</h2>
        <div className="row">
          <div className="col-12 col-md-12">
            <br />
            {clientData.modelType === clientModelTypes.model1 ? (
              <div>
                <BranchDialog
                  key={1}
                  dialogInfo={dialogInfo}
                  classes={classes}
                  closeDialog={closeDialog}
                  client_id={client_id}
                  clientData={clientData}
                  citiesData={cities}
                  clientClusterCitiesData={clientClusterCitiesData}
                  data={branchIntialState}
                  style={{ marginBottom: "10px" }}
                />
                <ColorButton
                  size="small"
                  padding="10px"
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  style={{ margin: "auto", display: "grid" }}
                  disabled={
                    clientData.modelType === clientModelTypes.model1 &&
                    branches.length > 0
                  }
                  onClick={() =>
                    openDialog(branchIntialState, "save", -1, cities)
                  }
                >
                  Add Branch
                </ColorButton>
              </div>
            ) : (
              <div>
                <>
                  <input type="file" accept=".xlsx" onChange={handleUpload} />
                  <span>
                    <ColorButton
                      size="small"
                      padding="10px"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={handleData}
                    >
                      Extract Data
                    </ColorButton>
                    <ColorButton
                      size="small"
                      padding="10px"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={onDownload}
                    >
                      Download Sample File
                    </ColorButton>
                  </span>
                  <BranchDialog
                    key={1}
                    dialogInfo={dialogInfo}
                    classes={classes}
                    closeDialog={closeDialog}
                    client_id={client_id}
                    clientData={clientData}
                    citiesData={cities}
                    clientClusterCitiesData={clientClusterCitiesData}
                    data={branchIntialState}
                    style={{ marginBottom: "10px" }}
                  />
                  <ColorButton
                    size="small"
                    padding="10px"
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    style={{ float: "right" }}
                    onClick={() =>
                      openDialog(branchIntialState, "save", -1, cities)
                    }
                  >
                    Add Branch
                  </ColorButton>
                  <BranchTable
                    client_id={client_id}
                    branchData={branchData}
                    setBranchData={setBranchData}
                    clientData={clientData}
                    clientClusterCitiesData={clientClusterCitiesData}
                    cities={cities}
                  />
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBranch;

const DialogHelper = () => {
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    branchVal: {},
    type: "",
    index: -1,
    cities: [],
  });
  const { cities } = dialogInfo;
  const openDialog = (val, type, index, citie) => {
    console.log("cities", citie);
    console.log("val", val);
    setDialogInfo({
      open: true,
      branchVal: val,
      type: type,
      index: index,
      cities: citie,
    });
    dialogInfo.cities = citie;
    // setDialogInfo((preState) => ({
    //   open: true,
    //   branchVal: val,
    //   type: type,
    //   index: index,
    //   cities: [...preState.cities, { id: "4", name: "c" }],
    // }));
    console.log("dialogInfo", dialogInfo);
  };

  const closeDialog = () => {
    setDialogInfo({
      open: false,
      branchVal: {},
      type: "",
      index: -1,
      cities: [],
    });
  };
  return { dialogInfo, openDialog, closeDialog };
};
