import { CircularProgress } from "@material-ui/core";
import React from "react";

const CustomSpinner = () => {
  return (
    <div>
      <span style={{ marginLeft: "10px", color: "#ff0000" }}>
        <h5>Please DO NOT REFRESH the page...</h5>
      </span>
      <CircularProgress
        style={{
          color: "#18D26E",
        }}
      />
    </div>
  );
};

export default CustomSpinner;
