import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Layout from "../../common/Layout";
import { getSalesUserFromId } from "../../backend/getDataFromFirestore";
import { ColorButton } from "../../common/Components/ColorButton";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    //   margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    textAlign: "justify",
    boxShadow: "0 3px 5px rgba(0,0,0,0.12), 0 3px 3px rgba(0,0,0,0.15)",
    fontFamily: "Roboto",
  },
  blog_desc: {
    justifyContent: "center",
    textAlign: "center",
    textAlign: "justify",
    // textIndent: "70px",
    marginTop: "5px",
  },
  authorDetail: {
    marginTop: "10px",
    padding: "10px",
    fontFamily: "Roboto",
  },
  author: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "row",
    fontSize: "18px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  button: {
    float: "right",
  },
});

const ViewLeadDetails = () => {
  const location = useLocation();
  const classes = useStyles();
  const lead = location.state;

  const [salesEmails, setSalesEmails] = useState({
    nsh: "",
    rsh: "",
    csh: "",
    se: "",
  });

  async function getSalesUserEmails() {
    let nsh = await getSalesUserFromId(lead.nsh);
    let rsh = await getSalesUserFromId(lead.rsh);
    let csh = await getSalesUserFromId(lead.csh);
    let se = await getSalesUserFromId(lead.se);

    setSalesEmails({
      nsh: nsh.email,
      rsh: rsh.email,
      csh: csh.email,
      se: se.email,
    });
  }

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ textAlign: "center" }}
                >
                  Lead Details
                </Typography>
                <br></br>
                <p className={classes.lead_details}>
                  <b>Lead Status</b> {lead.status}
                </p>
                <p className={classes.lead_details}>
                  <b>Company Name:</b> {lead.companyName}
                </p>
                <p className={classes.lead_details}>
                  <b>Lead Source:</b> {lead.source}
                </p>
                <p className={classes.lead_details}>
                  <b>Phone Number:</b> {lead.mobileno}
                </p>
                <p className={classes.lead_details}>
                  <b>Email:</b> {lead.personalemail}
                </p>
                <p className={classes.lead_details}>
                  <b>Address:</b> {lead.address}
                </p>
                <p className={classes.lead_details}>
                  <b>City:</b> {lead.city}
                </p>
                <p className={classes.lead_details}>
                  <b>State:</b> {lead.state}
                </p>
              </CardContent>
            </Card>
            <br></br>
            {salesEmails.nsh === "" ? (
              <ColorButton onClick={getSalesUserEmails}>
                Show Lead Refrences
              </ColorButton>
            ) : (
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{ textAlign: "center" }}
                  >
                    Lead Refrences
                  </Typography>
                  <br></br>
                  <p className={classes.lead_details}>
                    <b>National Sales Head:</b> {salesEmails.nsh}
                  </p>
                  <p className={classes.lead_details}>
                    <b>Regiional Sales Head:</b> {salesEmails.rsh}
                  </p>
                  <p className={classes.lead_details}>
                    <b>City Sales Head:</b> {salesEmails.csh}
                  </p>
                  <p className={classes.lead_details}>
                    <b>Sales Person:</b> {salesEmails.se}
                  </p>
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ViewLeadDetails;
