import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import swal from "sweetalert";
import { addMissingMettingReason } from "../../backend/saveDataToFirestore";
import { ColorButton } from "../Components/ColorButton";
import { Icon } from "@iconify/react";
import { makeStyles } from "@material-ui/styles";
import { disableCache } from "@iconify/react";

disableCache("local");
const useStyles = makeStyles((theme) => ({
  cells: {
    textAlign: "start",
    textTransform: "capitalize",
    overflow: "hidden",
  },
}));

export function MissedMeeting({ meetings }) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  async function showDialog(meeting) {
    setSelectedMeeting({ ...meeting });
    setOpenDialog(true);
  }

  async function handleClose() {
    setTimeout(() => {
      setOpenDialog(false);
    }, 0);
  }

  const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  function formatDate(date) {
    let day = date.getDate();
    let month = date.toLocaleString("default", { month: "short" });
    let year = date.getFullYear();
    let finalDate = `${day}${nth(day)} ${month} ${year} `;
    return finalDate;
  }

  return (
    <>
      {/* {meetings?.length > 0 && (
        <h4 style={{ marginLeft: "10px" }}>Missed Meetings</h4>
      )} */}
      {meetings?.map((meet, index) => {
        let date = new Date(meet.date.seconds * 1000);
        return (
          <ButtonBase
            style={{ width: "100%" }}
            onClick={(e) => showDialog(meet)}
          >
            <Grid
              style={{ marginBottom: "10px", marginLeft: "10px" }}
              container
              spacing={2}
            >
              <Grid item xs={3}>
                <Typography
                  className={classes.cells}
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ paddingLeft: "5px" }}
                >
                  {meet.name}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  className={classes.cells}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  {meet.agenda}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  className={classes.cells}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  <Icon
                    style={{ fontSize: "x-large", marginRight: "8px" }}
                    icon="material-symbols:calendar-month-outline-rounded"
                  />
                  {formatDate(date)}
                </Typography>
              </Grid>
            </Grid>
            <Dialog
              open={openDialog}
              // onClose={handleClose}
              fullWidth={true}
              maxWidth={"xs"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <ShowReasonDialog
                meeting={selectedMeeting}
                handleClose={handleClose}
              />
            </Dialog>
          </ButtonBase>
        );
      })}
      {!meetings.length && (
        <p>
          <b>There is no Missing Meeting</b>
        </p>
      )}
    </>
  );
}

const ShowReasonDialog = ({ meeting, handleClose }) => {
  const [reason, setReason] = useState("");

  async function handleSendReason() {
    const res = await addMissingMettingReason(meeting, reason);
    if (res.successfull) {
      await handleClose();
      swal("Success!", "Reason Added", "success").then(() => {
        window.location.reload();
      });
    } else {
    }
  }
  return (
    <span>
      <DialogTitle id="alert-dialog-title">{"Add Reason"}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Reason"
          variant="outlined"
          placeholder="Tell your Reason"
          multiline
          rows={4}
          maxRows={Infinity}
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose(false);
          }}
        >
          Close
        </Button>
        <ColorButton
          disabled={!reason}
          onClick={() => {
            handleSendReason();
          }}
        >
          Add Reason
        </ColorButton>
      </DialogActions>
    </span>
  );
};
