import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import clsx from "clsx";
import { indianStates } from "../../common/const";
import { CircularProgress } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import {setTest} from '../firebaseConfig/firebase';
import { saveLeadsData } from "../../backend/saveDataToFirestore";

import "firebase/firestore";
import { useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useHistory } from "react-router";
import { useState, useContext, useEffect } from "react";
import {
  getLocationUsingAddress,
  sendLeadNotification,
  sendLeadsToNSH,
} from "../../backend/sendRequestToServer";
import { pages } from "../../common/const";
import { getPageName } from "../../common/const";
import {
  getCitiesListFromState,
  getSalesUserFromId,
  getSalesUserFromRole,
} from "../../backend/getDataFromFirestore";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  pos: {
    marginTop: 20,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  label: {
    color: "#000",
  },

  textField: {
    "& .MuiInputBase-input": {
      color: "#000",
    },
  },

  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
    marginBottom: "10px",
  },
}));

const AddLead = (props) => {
  // hooks
  const [location, setLocation] = React.useState("");
  const [title, setJobTitle] = React.useState("");
  const [jobDesc, setJobDesc] = React.useState("");
  const [qual, setQual] = React.useState("");

  const [showMobileErr, setMobileErr] = useState("");
  const [showEmailErr, setEmailErr] = useState("");

  const db = useFirestore();
  let history = useHistory();
  const [showSpinner, setShowSpinner] = React.useState(false);
  const handleReset = () => {
    document.getElementById("lead-form").reset();
    setEmployee(empInitState);
  };

  // feild empty err hooks
  const [showTitleErr, setShowTitleErr] = React.useState(false);
  const [showDescErr, setShowDescErr] = React.useState(false);
  const [showLocationErr, setShowLocationErr] = React.useState(false);
  const [showCityErr, setShowCityErr] = React.useState(false);
  const [showPinErr, setShowPinErr] = React.useState(false);
  const [showQualErr, setShowQualErr] = React.useState(false);
  const [showSourceErr, setShowSourceErr] = React.useState(false);
  const [cities, setCities] = useState([]);

  const userDocId = localStorage.getItem("userDocId");
  const role = localStorage.getItem("role")
  const userName = localStorage.getItem("name")

  const [salesUser, setSalesUser] = useState({ nsh: "", rsh: "", csh: "" });

  useEffect(() => {
    getSalesUserFromId(userDocId).then(async (user) => {
      switch (user.designation) {
        case "National Sales Head":
          setSalesUser({ ...salesUser, nsh: userDocId });
          break;

        case "Regional Sales Head":
          const val = await getSalesUserFromId(user.createdby);
          setSalesUser({ ...salesUser, nsh: val.id, rsh: userDocId });
          break;

        case "City Sales Head":
          const rshval = await getSalesUserFromId(user.createdby);
          const nshval = await getSalesUserFromId(rshval.createdby);

          setSalesUser({ nsh: nshval.id, rsh: rshval.id, csh: userDocId });

          break;

        default:
          break;
      }
    });
  }, []);

  async function getCities(state) {
    const temp = await getCitiesListFromState(state);
    setCities(temp);
  }

  //priviledges

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: "#fff",
        color: "#000",
      },
    },
  };

  const empInitState = {
    name: "",
    email: "",
    phone: "",
    address: "",
    indianState: "",
    city: "",
    pincode: "",
    source: "",
  };
  const [employee, setEmployee] = useState(empInitState);

  const { name, email, phone, address, indianState, city, pincode, source } =
    employee;

  const onChange = (e, field) => {
    console.log(e.target.name);
    setEmployee({ ...employee, [`${field}`]: e.target.value });
    switch (e.target.name) {
      case "phone":
        if (!validatePhone(e.target.value)) {
          setMobileErr("Please Enter Valid Phone number");
        } else {
          setMobileErr("");
        }
        break;

      case "email":
        if (!validateEmail(e.target.value)) {
          setEmailErr("Please Enter Valid Email");
        } else {
          setEmailErr("");
        }
        break;

      case "indianState":
        getCities(e.target.value);
        break;

      default:
        break;
    }
  };

  const saveLeads = async (e) => {
    e.preventDefault();

    if (email === "" || name === "" || phone === "" || address === "") {
      alert("Please enter all fields");
    } else if (showEmailErr !== "" || showMobileErr !== "") {
      console.log("Email or phone is wrong");
      return false;
    } else {
      setShowSpinner(true);
      let lat = "",
        lng = "";

      const completeAddress =
        name +
        ", " +
        address +
        ", " +
        city +
        ", " +
        indianState.substr(0, indianState.length - 5) +
        ", India, " +
        pincode;
      try {
        const location = await getLocationUsingAddress({
          address: completeAddress,
        });
        if (location.status == 200) {
          lat = location.lat;
          lng = location.lng;
          console.log("lat : ", lat, "lng : ", lng);

          const status = await saveLeadsData({
            // ...salesUser,
            personalemail: email,
            address: address,
            city: city,
            companyName: name,
            mobileno: phone,
            pincode: pincode,
            se: "",
            source: role,
            sourceName: userName,
            state: indianState,
            status: "cold",
            lat: lat,
            lng: lng,
          });

          if (status.successfull) {
            sendLeadNotification({ leadId: status.leadId });

            setShowSpinner(false);
            swal("Success!", "Lead Added", "success").then((value) =>
              handleReset()
            );
          } else {
            setShowSpinner(false);
            swal("Failed!", "Failed to add Lead. Try Again!!!", "error");
          }
        }
      } catch (e) {
        setShowSpinner(false);
        swal(
          "Failed!",
          "Failed to fetch Location. Please make sure your address is correct and use Comma (,) to seperate landmarks / streets / town.",
          "error"
        );
      }
    }
  };

  // email validation
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  // phone validation
  const validatePhone = (mob) => {
    const re = /^[0-9]{10}$/;
    return re.test(mob);
  };

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        {/* <h2 style={{ textAlign: "center" }}>{getPageName()}</h2> */}

        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Add Leads
                </Typography>
                <form
                  id="lead-form"
                  className={classes.form}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Lead Company Name*"
                    name="name"
                    variant="outlined"
                    onChange={(e) => onChange(e, "name")}
                    error={showTitleErr}
                    helperText={showTitleErr && "Please Enter Name"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Email*"
                    name="email"
                    variant="outlined"
                    onChange={(e) => onChange(e, "email")}
                    error={showDescErr}
                    helperText={showDescErr && "Please Enter Email"}
                  />
                  <span className={classes.errMsg}>{showEmailErr}</span>

                  <TextField
                    variant="outlined"
                    aria-label="empty textarea"
                    label="Phone*"
                    name="phone"
                    onChange={(e) => onChange(e, "phone")}
                    error={showQualErr}
                    helperText={showQualErr && "Please Enter Phone"}
                  />
                  <span className={classes.errMsg}>{showMobileErr}</span>

                  <TextField
                    id="outlined-basic"
                    label="Company Address*"
                    variant="outlined"
                    name="address"
                    onChange={(e) => onChange(e, "address")}
                    error={showLocationErr}
                    helperText={showLocationErr && "Please Enter Address"}
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    required
                    fullWidth
                    id="outlined-indian-state"
                    select
                    label="State"
                    name="indianState"
                    onChange={(e) => onChange(e, "indianState")}
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  >
                    {indianStates.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    disabled={indianState === ""}
                    required
                    fullWidth
                    id="outlined-city"
                    select
                    label="City*"
                    onChange={(e) => onChange(e, "city")}
                    error={showCityErr}
                    helperText={showCityErr && "Please Select City"}
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  >
                    {cities.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-basic"
                    label="Pin Code*"
                    variant="outlined"
                    name="pincode"
                    onChange={(e) => onChange(e, "pincode")}
                    error={showPinErr}
                    helperText={showPinErr && "Please Enter Pin Code"}
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Reference*"
                    variant="outlined"
                    name="source"
                    value={role}
                    disabled
                    // onChange={(e) => onChange(e, "source")}
                    error={showSourceErr}
                    helperText={showSourceErr && "Please Enter Source"}
                    style={{ marginBottom: "10px" }}
                  />
                </form>
              </CardContent>
              <div>
                {showSpinner ? (
                  <div>
                    <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                      <h5>Please DO NOT REFRESH the page...</h5>
                    </span>
                    <CircularProgress
                      style={{
                        color: "#18D26E",
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  disabled={showSpinner}
                  variant="contained"
                  color="primary"
                  onClick={saveLeads}
                >
                  Send lead to marketing
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLead;
