import { Icon } from "@iconify/react";
import { disableCache } from "@iconify/react";
import {
  CORE_ASSIGNED_LEADS_VIEW,
  CORE_BLOG_VIEW,
  CORE_CAREER_VIEW,
  CORE_CASE_STUDIES_VIEW,
  CORE_EMPLOYEES_VIEW,
  CORE_LEADS_VIEW,
  CORE_PROFILES_VIEW,
  CORE_PROSPECTS_VIEW,
  CORE_SHOW_LOCATIONS_VIEW,
  CORE_TESTIMONIALS_VIEW,
  CORE_VIEW_MARKETING_LINK_VIEW,
} from "./constantsUrls";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as FranchiseIcon } from "globals/Icons/franchise-icon.svg";

disableCache("local");

export const SIDEBAR_ITEMS = {
  Dashboard: {
    icon: (
      <Icon style={{ fontSize: "x-large" }} icon="material-symbols:dashboard" />
    ),
    pathname: "/dashboard",
    title: "Dashboard",
    key: "dashboard",
  },

  Marketing: {
    icon: <Icon style={{ fontSize: "x-large" }} icon="nimbus:marketing" />,
    pathname: CORE_BLOG_VIEW,
    title: "Marketing",
    key: "marketing",
  },

  Franchise: {
    icon: <SvgIcon component={FranchiseIcon} />,
    pathname: "/dashboard",
    title: "Franchise",
    key: "franchise",
  },

  "E-Store": {
    icon: (
      <Icon
        style={{ fontSize: "x-large" }}
        icon="material-symbols:store-outline"
      />
    ),
    pathname: "/dashboard",
    title: "E-Store",
    key: "e-store",
  },
  Seller: {
    icon: <Icon style={{ fontSize: "x-large" }} icon="ic:sharp-people-alt" />,
    pathname: "/dashboard",
    title: "Seller",
    key: "seller",
  },
  "Product Manager": {
    icon: (
      <Icon
        style={{ fontSize: "x-large" }}
        icon="material-symbols:manage-accounts"
      />
    ),
    pathname: "/dashboard",
    title: "Product Manager",
    key: "product-manager",
  },
  Employee: {
    icon: (
      <Icon
        style={{ fontSize: "x-large" }}
        icon="clarity:employee-group-solid"
      />
    ),
    pathname: CORE_PROFILES_VIEW,
    title: "Employee",
    key: "employee",
  },
  Sales: {
    icon: (
      <Icon
        style={{ fontSize: "x-large" }}
        icon="icon-park-outline:sales-report"
      />
    ),
    pathname: "/dashboard",
    title: "Sales",
    key: "sales",
  },
  Client: {
    icon: (
      <Icon style={{ fontSize: "x-large" }} icon="fa6-solid:handshake-simple" />
    ),
    pathname: "/dashboard",
    title: "Client",
    key: "client",
  },
  Lead: {
    icon: <Icon style={{ fontSize: "x-large" }} icon="ion:magnet" />,
    pathname: CORE_LEADS_VIEW,
    title: "Lead",
    key: "leads",
  },
  Other: {
    icon: (
      <Icon
        style={{ fontSize: "x-large" }}
        icon="carbon:overflow-menu-horizontal"
      />
    ),
    pathname: "/dashboard",
    title: "Other",
    key: "other",
  },
  "Log Out": {
    icon: (
      <Icon style={{ fontSize: "x-large" }} icon="material-symbols:logout" />
    ),
    pathname: "/dashboard",
    title: "Log Out",
    key: "logout",
  },
};

export const meetingDetails = {
  mail: <Icon icon="mdi:email-outline" />,
  phone: <Icon icon="material-symbols:phone-enabled" hFlip={true} />,
  role: <Icon icon="carbon:user-role" />,
  createdByMail: (
    <Icon icon="material-symbols:calendar-month-outline-rounded" />
  ),
};

export const TAB_NAME = {
  LEADS_MANAGEMENT: "Leads Management",
  EMPLOYEES: "Employees",
  MARKETING: "Marketing",
};

export const SUB_TAB_NAME = {
  LEADS: "Leads",
  ASSIGNED_LEADS: "Assigned Leads",
  MEETINGS: "Meetings",
  PROSPECTS: "Prospects",
  SHOW_LOCATIONS: "Show Locations",
  SHOW_EMPLOYEE: "Show Employee",
  PROFILE: "Profile",
  BLOG: "Blogs",
  CAREER: "Career",
  CASE_STUDIES: "Case Studies",
  TESTIMONIALS: "Testimonials",
  MARKETING_VIEW_CONTACT_US: "Marketing View Contact US",
};

export const sidebarLinks = {
  [TAB_NAME.LEADS_MANAGEMENT]: [
    SUB_TAB_NAME.LEADS,
    SUB_TAB_NAME.ASSIGNED_LEADS,
    SUB_TAB_NAME.MEETINGS,
    SUB_TAB_NAME.PROSPECTS,
    SUB_TAB_NAME.SHOW_LOCATIONS,
  ],
  [TAB_NAME.EMPLOYEES]: [SUB_TAB_NAME.SHOW_EMPLOYEE, SUB_TAB_NAME.PROFILE],
  [TAB_NAME.MARKETING]: [
    SUB_TAB_NAME.BLOG,
    SUB_TAB_NAME.CAREER,
    SUB_TAB_NAME.CASE_STUDIES,
    SUB_TAB_NAME.TESTIMONIALS,
    SUB_TAB_NAME.MARKETING_VIEW_CONTACT_US,
  ],
};

export const tabMenuLinks = {
  [SUB_TAB_NAME.LEADS]: {
    url: CORE_LEADS_VIEW,
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="ion:magnet-sharp"
      />
    ),
  },
  [SUB_TAB_NAME.ASSIGNED_LEADS]: {
    url: CORE_ASSIGNED_LEADS_VIEW,
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="mdi:person-check"
      />
    ),
  },
  [SUB_TAB_NAME.MEETINGS]: {
    url: "/view-meetings",
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="mdi:bell"
      />
    ),
  },
  [SUB_TAB_NAME.PROSPECTS]: {
    url: CORE_PROSPECTS_VIEW,
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="material-symbols:store-outline"
      />
    ),
  },
  [SUB_TAB_NAME.SHOW_LOCATIONS]: {
    url: CORE_SHOW_LOCATIONS_VIEW,
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="material-symbols:location-on"
      />
    ),
  },
  [SUB_TAB_NAME.SHOW_EMPLOYEE]: {
    url: CORE_EMPLOYEES_VIEW,
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="material-symbols:location-on"
      />
    ),
  },
  [SUB_TAB_NAME.PROFILE]: {
    url: CORE_PROFILES_VIEW,
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="material-symbols:location-on"
      />
    ),
  },
  [SUB_TAB_NAME.BLOG]: {
    url: CORE_BLOG_VIEW,
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="material-symbols:location-on"
      />
    ),
  },
  [SUB_TAB_NAME.CAREER]: {
    url: CORE_CAREER_VIEW,
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="material-symbols:location-on"
      />
    ),
  },
  [SUB_TAB_NAME.CASE_STUDIES]: {
    url: CORE_CASE_STUDIES_VIEW,
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="material-symbols:location-on"
      />
    ),
  },
  [SUB_TAB_NAME.TESTIMONIALS]: {
    url: CORE_TESTIMONIALS_VIEW,
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="material-symbols:location-on"
      />
    ),
  },
  [SUB_TAB_NAME.MARKETING_VIEW_CONTACT_US]: {
    url: CORE_VIEW_MARKETING_LINK_VIEW,
    icon: (
      <Icon
        style={{ fontSize: "x-large", marginRight: "8px" }}
        icon="material-symbols:location-on"
      />
    ),
  },
};
