import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
// import {setTest} from '../firebaseConfig/firebase';
import "firebase/firestore";
import { useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useLocation, useHistory } from "react-router";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../backend/uploadFileToStorage";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const EditCaseStudy = (props) => {
  const location12 = useLocation();
  let selectedCaseStudy = location12.state;
  console.log(selectedCaseStudy);
  // hooks
  const [CSAuthor, setCSAuthor] = React.useState(selectedCaseStudy.CSAuthor);
  const [CSTitle, setCSTitle] = React.useState(selectedCaseStudy.CSTitle);
  const [CSDesc, setCSDesc] = React.useState(selectedCaseStudy.CSDesc);

  // validation hooks
  const [showCSAuthorErr, setShowCSAuthorErr] = React.useState(false);
  const [showCSTitleErr, setShowCSTitleErr] = React.useState(false);
  const [showCSDescErr, setShowCSDescErr] = React.useState(false);

  const [imageAsFile, setImageAsFile] = React.useState(null);
  const [imageAsFile1, setImageAsFile1] = React.useState(null);

  const db = useFirestore();
  let history = useHistory();

  async function EditCaseStudyFunction() {
    console.log("The link was clicked.");
    let downloadURL = "",
      downloadURL1 = "";
    if (imageAsFile) {
      const upTask = uploadFileToStorage(
        `/caseStudyImages/${imageAsFile.name}`,
        imageAsFile
      );
      downloadURL = await getDownloadURL(upTask);
    } else {
      downloadURL = selectedCaseStudy.downloadURL;
    }

    if (imageAsFile1) {
      const upTask1 = uploadFileToStorage(
        `/caseStudyImages/${imageAsFile1.name}`,
        imageAsFile1
      );
      downloadURL1 = await getDownloadURL(upTask1);
    } else {
      downloadURL1 = selectedCaseStudy.downloadURL1;
    }

    // db.collection("CaseStudy")
    //   .doc()
    //   .set({
    //     CSAuthor: CSAuthor ,
    //     CSTitle: CSTitle ,
    //     CSDesc: CSDesc ,
    //     })
    //   .then(() => {
    //     swal("Success!!!", "Case Study Added", "success");
    //   });

    //     db.collection("CaseStudy")
    //     .add({
    //       CSAuthor: CSAuthor ,
    //           CSTitle: CSTitle ,
    //           CSDesc: CSDesc ,
    //       })
    //    .then(function(docRef) {
    //        console.log("Document written with ID: ", docRef.id);
    //        var washingtonRef = db.collection("CaseStudy").doc(docRef.id);

    // // Set the "capital" field of the city 'DC'
    // return washingtonRef.update({
    //  id: docRef.id
    // })
    // .then(() => {
    //  console.log("Document successfully updated!");
    //  swal("Success!!!", "Case Study Added", "success");
    // })
    // .then(() => history.push('/view-caseStudy'))
    // .catch((error) => {
    //  // The document probably doesn't exist.
    //  console.error("Error updating document: ", error);
    // });

    //    })
    //    .catch(function(error) {
    //        console.error("Error adding document: ", error);
    //    });

    if (CSTitle === "") {
      setShowCSTitleErr(true);
    } else if (CSDesc === "") {
      setShowCSDescErr(true);
    } else if (CSAuthor === "") {
      setShowCSAuthorErr(true);
    } else {
      var washingtonRef = db.collection("CaseStudy").doc(selectedCaseStudy.id);

      // Set the "capital" field of the city 'DC'
      return washingtonRef
        .update({
          CSAuthor: CSAuthor,
          CSTitle: CSTitle,
          CSDesc: CSDesc,
          downloadURL: downloadURL,
          downloadURL1: downloadURL1,
        })
        .then(() => {
          console.log("Document successfully updated!");
          swal("Success!!!", "Case Study Updated", "success");
          history.push("/view-caseStudy");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }
  }

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile(image);
  };

  const handleImageAsFile1 = (e) => {
    const image1 = e.target.files[0];
    setImageAsFile1(image1);
  };

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Edit Case Study
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                  <TextField
                    id="outlined-basic"
                    label="Case Study Title"
                    variant="outlined"
                    value={CSTitle}
                    onChange={(e) => {
                      setCSTitle(e.target.value);
                      setShowCSTitleErr(false);
                    }}
                    error={showCSTitleErr}
                    helperText={showCSTitleErr && "Please enter title"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Case Study Description"
                    variant="outlined"
                    value={CSDesc}
                    onChange={(e) => {
                      setCSDesc(e.target.value);
                      setShowCSDescErr(false);
                    }}
                    error={showCSDescErr}
                    helperText={showCSDescErr && "Please enter description"}
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Case Study Author Name"
                    variant="outlined"
                    value={CSAuthor}
                    onChange={(e) => {
                      setCSAuthor(e.target.value);
                      setShowCSAuthorErr(false);
                    }}
                    error={showCSAuthorErr}
                    style={{ marginBottom: "10px" }}
                    helperText={showCSAuthorErr && "Please enter author name"}
                  />
                  <p style={{ marginBottom: "10px" }}>
                    Logo:
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      onChange={handleImageAsFile}
                    />
                    <ColorButton
                      variant="contained"
                      className={classes.button}
                      onClick={() =>
                        window.open(
                          imageAsFile
                            ? URL.createObjectURL(imageAsFile)
                            : selectedCaseStudy.downloadURL,
                          "_blank"
                        )
                      }
                    >
                      View
                    </ColorButton>
                  </p>

                  <p style={{ marginBottom: "10px" }}>
                    Image:
                    <input
                      accept="image/*"
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      onChange={handleImageAsFile1}
                    />
                    <ColorButton
                      variant="contained"
                      className={classes.button}
                      onClick={() =>
                        window.open(
                          imageAsFile1
                            ? URL.createObjectURL(imageAsFile1)
                            : selectedCaseStudy.downloadURL1,
                          "_blank"
                        )
                      }
                    >
                      View
                    </ColorButton>
                  </p>
                </form>
              </CardContent>
              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={EditCaseStudyFunction}
                >
                  Update Case Study
                </ColorButton>
              </CardActions>

              <span className="mt-5"></span>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCaseStudy;
