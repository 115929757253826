import React, { useEffect, useState } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import SelectProduct from "./SelectProduct";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { IconButton } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";

// table view
export const ProductsTable = ({
  isPAN,
  branch,
  city,
  category,
  subCategory,
  selectedProducts,
}) => {
  const [products, setProducts] = useState([]);

  const db = useFirestore();
  let productsRef;
  if (isPAN && category && subCategory) {
    productsRef = db
      .collection("PAN-Products")
      .where("Product Sub-Category", "==", subCategory);
  } else if (isPAN && category && subCategory) {
    productsRef = db
      .collection("PAN-Products")
      .where("Product Category", "==", subCategory);
  } else if (isPAN) {
    productsRef = db.collection("PAN-Products");
  } else if (city && category && subCategory) {
    productsRef = db
      .collection("Products")
      .doc(city)
      .collection("Products")
      .where("Product Sub-Category", "==", subCategory);
  } else if (city && category) {
    productsRef = db
      .collection("Products")
      .doc(city)
      .collection("Products")
      .where("Product Category", "==", category);
  } else
    productsRef = db.collection("Products").doc(city).collection("Products");

  const { status, data } = useFirestoreCollectionData(productsRef);

  const [filterStatus, setFilterStatus] = useState("");

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    console.log("It rendered");
    if (data) {
      console.log(data[0]);
      setProducts(data);
      setSearchResult(searchProductsResult(searchValue, filterStatus));
    }
  }, [data]);

  //  Search & filter in textbox
  const searchProductsResult = (name, status) => {
    return products.filter((pros) => {
      if (name && status) {
        console.log(pros);
        return (
          pros.name &&
          pros.name.toLowerCase().includes(name.toLowerCase()) &&
          pros.status &&
          pros.status.toLowerCase() === status.toLowerCase()
        );
      }
      if (name) {
        return (
          pros.name && pros.name.toLowerCase().includes(name.toLowerCase())
        );
      }
      if (status) {
        return (
          pros.status && pros.status.toLowerCase() === status.toLowerCase()
        );
      }
      return true;
    });
  };

  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchProductsResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    setSearchResult(searchProductsResult(searchValue, status));
  };

  // table view
  const ProductsTableView = ({ products }) => {
    return (
      <div className="row align-items-center">
        <Table key="LeadTable">
          <Thead>
            <Tr>
              <Th>Product Image</Th>
              <Th>Product Name</Th>
              <Th>Product Category</Th>
              <Th>Product Sub Category</Th>
              <Th>MRP</Th>
              <Th>NexStoc Price</Th>
              {/* <Th>Select Product</Th> */}
              <Th>Client Price</Th>
            </Tr>
          </Thead>
          <Tbody>
            {products.map((pros, index) => {
              const finalPrice = isPAN ? pros["maxPrice"] : pros["finalPrice"];

              if (finalPrice === 0) return null;

              if (!selectedProducts.hasOwnProperty(pros["id"])) return null;

              return (
                <Tr key={index}>
                  <Td style={{ width: "10%" }} align="center">
                    <img
                      style={{
                        display: "block",
                        maxHeight: "50px",
                        maxWidth: "100%",
                      }}
                      src={
                        pros["Product Images"] && pros["Product Images"][0]
                          ? pros["Product Images"][0]
                          : pros["Product Image"]
                      }
                    />
                  </Td>
                  <Td>{pros["Product Name"]}</Td>
                  <Td>{pros["Product Category"]}</Td>
                  <Td>{pros["Product Sub-Category"]}</Td>

                  <Td>{pros["MRP"]}</Td>
                  <Td>{finalPrice}</Td>
                  {/* <Td>
                    <SelectProduct
                      checked={
                        selectedProducts &&
                        selectedProducts.hasOwnProperty(pros["id"])
                      }
                      city={city}
                      product={pros}
                    />
                  </Td> */}
                  <Td>
                    {selectedProducts.hasOwnProperty(pros["id"])
                      ? selectedProducts[pros["id"]].clientPrice
                      : "NA"}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
    );
  };

  return (
    <div>
      {products.length === 0 ? (
        <div className="row justify-content-center">
          <p>No request's yet!</p>
        </div>
      ) : (
        <>
          {products.length > 0 && filterStatus === "" && searchValue === "" ? (
            <ProductsTableView products={products} />
          ) : (
            <>
              {searchResult.length > 0 ? (
                <ProductsTableView products={searchResult} />
              ) : (
                <div className="row pb-3 justify-content-center">
                  <div>Search Not Found!</div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
