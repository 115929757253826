import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MobileInnerCol from "./MobileInnerCol";
import {
  DataGrid,
  GridColumnMenu,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

const MobileView = ({
  data,
  mobileInnerCol = [],
  mobileOuterCol = [],
  allCols = [],
}) => {
  const useStyles = makeStyles((theme) => ({
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    middle: {
      margin: "10px",
    },
  }));
  const classes = useStyles();
  return data.map((value) => {
    return (
      <>
        <Card
          variant="outlined"
          style={{
            marginBottom: "30px",
            borderRadius: "4px",
            border: "1px solid #D9D9D9",
          }}
        >
          <CardContent style={{ padding: 0 }}>
            <Box style={{ padding: "16px" }}>
              <div className={classes.header}>
                <Typography
                  style={{
                    fontWeight: 500,
                    marginBottom: "14px",
                    textTransform: "capitalize",
                  }}
                  variant="body2"
                >
                  {value[mobileOuterCol[0]]}
                </Typography>
                <div className={classes.middle}>
                  {" "}
                  {value[mobileOuterCol[1]]}
                </div>
              </div>
              <div>{value[mobileOuterCol[2]]}</div>
            </Box>
            <div>
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {value[mobileOuterCol[3]]}
                  {/* <ActionCol data={value} /> */}
                </AccordionSummary>
                <AccordionDetails>
                  <MobileInnerCol
                    data={value}
                    cols={allCols.filter(
                      (item) => !mobileOuterCol?.includes(item?.field)
                    )}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </CardContent>
        </Card>
      </>
    );
  });
};

const DesktopView = ({ data, cols = [] }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiDataGrid-root": {
        border: "none",
      },
      "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within":
        {
          outline: "none !important",
        },
      "& .MuiDataGrid-columnHeaders": {
        borderBottom: `1.5px solid ${theme.palette.grey[30]} !important`,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
      },
      "& .MuiTablePagination-displayedRows": {
        margin: 0,
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DataGrid
        rows={data}
        columns={cols}
        getRowHeight={() => "auto"}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        getRowId={(row) => row?.id || Math.floor(Math.random() * 1000000)}
        disableColumnMenu
      />
    </div>
  );
};

export default function CommonTable({
  data = [],
  cols,
  mobileInnerCol = [
    {
      headerName: "Comapany Name",
      field: "companyName",
    },
    {
      headerName: "Email",
      field: "companyName",
    },
  ],
  mobileOuterCol = ["companyName", "status", "rsh"],
}) {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setIsMobileView(true)
        : setIsMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  return (
    <>
      {!isMobileView ? (
        <DesktopView data={data} cols={cols} />
      ) : (
        <MobileView
          data={data}
          allCols={cols}
          mobileInnerCol={mobileInnerCol}
          mobileOuterCol={mobileOuterCol}
        />
      )}
    </>
  );
}
