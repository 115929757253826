import app from "firebase/app";
import "firebase/auth";
import { extraRoleBasedPrivileges } from "../common/const";

//Get Privileges of User by checking current logged in email
const getPrivilegesForNav = async (email) => {
  console.log("Called from Firebase");
  const user = app.auth().currentUser;
  if (user) {
    const firestore = app.firestore();
    const privileges = await firestore
      .collection("Users")
      .where("email", "==", user.email)
      .get();

    let userData = privileges.docs[0].data();

    let prv = userData.privileges ?? [];

    if (extraRoleBasedPrivileges[userData.designation])
      prv = [...prv, ...extraRoleBasedPrivileges[userData.designation]];

    return prv;
  }
  return [];
};

export async function getRoleForNav() {
  return new Promise((resolve) => {
    return app.auth().onAuthStateChanged(async (user) => {
      let roleData = {};

      if (user) {
        const firestore = app.firestore();
        const userDataRef = (
          await firestore
            .collection("Users")
            .where("email", "==", user.email)
            .get()
        ).docs[0];

        const userData = userDataRef.data();
        const userId = userDataRef.id;

        let profileData;

        if (userData?.profileId) {
          profileData = (
            await firestore.collection("Profile").doc(userData?.profileId).get()
          ).data();
        }
        roleData = {
          role: profileData?.name ?? "",
          name: userData.name,
          email: user.email,
          userDocId: userId,
          permissions: profileData?.permissions,
        };
      }
      resolve(roleData);
    });
  });
}

export async function getEmailForNav() {
  const user = app.auth().currentUser;
  if (user) {
    return user.email;
  }
  return "";
}

export default getPrivilegesForNav;
