import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import {setTest} from '../firebaseConfig/firebase';
import "firebase/firestore";
import { useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useHistory } from "react-router";
import { getPageName, indianStates } from "././../../common/const";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const AddCity = (props) => {
  // hooks
  const [location, setLocation] = React.useState("");
  const [title, setJobTitle] = React.useState("");
  const [jobDesc, setJobDesc] = React.useState("");
  const [indianState, setIndianState] = React.useState("");

  const db = useFirestore();
  let history = useHistory();

  // feild empty err hooks
  const [showTitleErr, setShowTitleErr] = React.useState(false);
  const [showDescErr, setShowDescErr] = React.useState(false);
  const [showLocationErr, setShowLocationErr] = React.useState(false);
  const [showQualErr, setShowQualErr] = React.useState(false);

  function handleReset() {
    setIndianState("");
    setJobTitle("");
    setJobDesc("");
  }

  async function AddCityFunction() {
    console.log("The link was clicked.");

    if (title === "") {
      setShowTitleErr(true);
    } else if (jobDesc === "") {
      setShowDescErr(true);
    } else {
      console.log(jobDesc);

      var docRef = db
        .collection("CityCodes")
        .where("code", "==", jobDesc.toUpperCase());

      docRef
        .get()
        .then((doc) => {
          if (doc.size > 0) {
            console.log("Document data:", doc.docs[0].data());
            swal("Error!!!", "City Already Exists", "error");
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            db.collection("CityCodes")
              .doc()
              .set({
                city: title + " " + "(" + jobDesc.toUpperCase() + ")",
                code: jobDesc.toUpperCase(),
                state: indianState,
              })
              .then(() => {
                swal("Success!!!", "City Added", "success").then((e) =>
                  handleReset()
                );
              });
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  }

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <form className={classes.form} noValidate autoComplete="off">
                  <TextField
                    required
                    fullWidth
                    id="outlined-corporate-office-state"
                    label="Select State"
                    select
                    variant="outlined"
                    name="indianState"
                    value={indianState}
                    onChange={(e) => setIndianState(e.target.value)}
                    style={{ marginBottom: "10px" }}
                  >
                    {indianStates.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    disabled={indianState === ""}
                    id="outlined-basic"
                    label="City Name*"
                    variant="outlined"
                    value={title}
                    onChange={(e) => {
                      setJobTitle(e.target.value);
                      setShowTitleErr(false);
                    }}
                    error={showTitleErr}
                    helperText={showTitleErr && "Please Enter city name"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    disabled={indianState === ""}
                    id="outlined-basic"
                    label="City Code*"
                    variant="outlined"
                    value={jobDesc}
                    onChange={(e) => {
                      setJobDesc(e.target.value);
                      setShowDescErr(false);
                    }}
                    error={showDescErr}
                    helperText={showDescErr && "Please Enter city code"}
                    style={{ marginBottom: "10px" }}
                  />
                </form>
              </CardContent>
              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={AddCityFunction}
                >
                  Submit
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCity;
