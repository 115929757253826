import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

const useStyles = makeStyles({
  fullList: {
    width: 640,
    height: 1016,
    marginTop: 48,
    padding: "54px 54px 50px 46px",
  },
});

export default function DrawerWrapper({ children, state, onClose }) {
  const classes = useStyles();
  const customClass = `container ${classes.fullList}`;

  return (
    <div>
      <React.Fragment key={"right"}>
        <SwipeableDrawer
          anchor={"right"}
          open={state}
          onClose={() => onClose()}
        >
          <div className={customClass}>{children}</div>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
