import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ColorButton } from "../../common/Components/ColorButton";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { saveClusterCityData } from "../../backend/saveDataToFirestore";
import {
  getCitiesList,
  getCityId,
  getClientById,
  getClientCitiesList,
} from "../../backend/getDataFromFirestore";

const AddClusterCitiesDialog = ({
  initialCityData,
  cityData,
  setCityData,
  isUpdate,
  setUpdate,
  dialogInfo,
  closeDialog,
  client_id,
  region_id,
  cluster_id,
  getClusterCities,
}) => {
  const refData = useRef();
  const [clientData, setClientData] = useState({});
  const [cities, setCities] = useState([]);
  const [clientCities, setClientCities] = useState([]);

  useEffect(() => {
    //From Client/Cities sub collection
    getClientCities();
    getClient();
  }, [dialogInfo]);

  useEffect(() => {
    if (clientData.id) {
      getCities();
    }
  }, [clientCities, clientData]);

  async function getCities() {
    // let temp = clientData.isPAN ? await getCitiesList() : clientData.cities;
    let temp = clientData.cities;
    const usedCityIds = clientCities.map((city) => city.cityCode_id);
    let validCities = temp.filter(
      (currentCity) => !usedCityIds.includes(currentCity.id)
    );
    setCities(validCities);
  }

  async function getClientCities() {
    const temp = await getClientCitiesList(client_id);
    setClientCities(temp);
  }

  async function getClient() {
    const temp = await getClientById(client_id);
    setClientData(temp);
  }

  const handleClose = () => {
    setCityData(initialCityData);
    setUpdate(false);
    closeDialog();
  };

  async function handleSubmit() {
    const cityId = await getCityId(cityData.name);
    let clusterCityData = {};
    if (cityData.id) {
      clusterCityData = { id: cityData.id, cityCode_id: cityId.id };
    } else {
      clusterCityData = { cityCode_id: cityId.id };
    }
    await saveClusterCityData(
      client_id,
      region_id,
      cluster_id,
      clusterCityData
    );
    getClusterCities();
    getClientCities();
    getCities();
    handleClose();
  }

  function updateCityDetails(e) {
    let newCity = { ...cityData };
    newCity[refData.current.getAttribute("name")] = e.target.textContent;
    setCityData(newCity);
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={dialogInfo.open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {isUpdate ? "Edit City" : "Add City"}
      </DialogTitle>
      <DialogContent dividers={true}>
        <Autocomplete
          variant="outlined"
          options={cities}
          ref={refData}
          name="name"
          onChange={(e) => updateCityDetails(e)}
          style={{ marginBottom: "10px" }}
          value={cityData.name}
          getOptionLabel={(option) => option.value || cityData.name}
          getOptionSelected={(option, value) => option.value === value}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              label="City Name"
            ></TextField>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <ColorButton disabled={!cities} onClick={handleSubmit}>Submit</ColorButton>
      </DialogActions>
    </Dialog>
  );
};
export default AddClusterCitiesDialog;
