import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import { SnackbarContent } from "@material-ui/core";
import Layout from "../../common/Layout";
import { CircularProgress } from "@material-ui/core";
import { TrainRounded } from "@material-ui/icons";
import { MAIN_SERVER } from "../../common/const";
const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Marketing = (props) => {
  // hooks
  const [userInput, setUserInput] = useState(
    "Click above button to generate link"
  );
  const [open, setOpen] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(false);
  function getURl(url) {
    fetch(MAIN_SERVER + "/shorturl", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: url }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUserInput(data.url);
        setShowSpinner(false);
      })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUserInput(data.url);
      })
      .catch((error) => {
        console.error("Error:", error);
        return "Something went wrong";
      });
  }
  const insta = (e) => {
    /* Prevent button click's default behavior */
    e.preventDefault();
    setShowSpinner(true);
    const url = window.location.origin;
    /* Call the state's "setter" method to update "userInput" state */
    getURl(
      url + "/marketing-contactus" +
        "?data=" +
        "Instagram"
    );
  };
  const fb = (e) => {
    /* Prevent button click's default behavior */
    e.preventDefault();
    setShowSpinner(true);
    const url = window.location.origin;
    /* Call the state's "setter" method to update "userInput" state */
    getURl(
      url + "/marketing-contactus" +
        "?data=" +
        "facebook"
    );
  };
  const linkedin = (e) => {
    
    /* Prevent button click's default behavior */
    const url = window.location.origin;
    e.preventDefault();
    setShowSpinner(true);
    /* Call the state's "setter" method to update "userInput" state */
    getURl(
      url + "/marketing-contactus" +
        "?data=" +
        "linkedin"
    );
  };
  const copy = (e) => {
    /* Prevent button click's default behavior */
    e.preventDefault();
    /* Call the state's "setter" method to update "userInput" state */
    navigator.clipboard.writeText(userInput);
    setOpen(true);
    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setOpen(false);
    };
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={open}
                  autoHideDuration={1000}
                  onClose={handleClose}
                  message="Text Copied to clip board"
                >
                  <SnackbarContent
                    style={{
                      backgroundColor: "#0F9D58",
                    }}
                    message={
                      <span id="client-snackbar">Text copied to clipboard</span>
                    }
                  />
                </Snackbar>
                <Typography variant="h5" component="h2">
                  Generate Marketing Links
                </Typography>
                <ColorButton
                  style={{
                    justifyContent: "center",
                    fontWeight: "bold",
                    margin: 5,
                  }}
                  onClick={insta}
                >
                  Instagram
                </ColorButton>
                <ColorButton
                  style={{
                    justifyContent: "center",
                    fontWeight: "bold",
                    margin: 5,
                  }}
                  onClick={fb}
                >
                  Facebook
                </ColorButton>
                <ColorButton
                  style={{
                    justifyContent: "center",
                    fontWeight: "bold",
                    margin: 5,
                  }}
                  onClick={linkedin}
                >
                  Linkedin
                </ColorButton>
              </CardContent>
              <TextField
                id="outlined-basic"
                label=""
                value={userInput}
                variant="outlined"
                disabled="true"
                style={{ marginBottom: "10px" }}
              />
              <div>
                {showSpinner ? (
                  <div>
                    <span
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block",
                        color: "#ff0000",
                      }}
                      id="x"
                    >
                      <h5 style={{ textAlign: "center" }}>
                        Please DO NOT REFRESH the page...
                      </h5>
                    </span>
                    <CircularProgress
                      style={{
                        color: "#18D26E",
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <ColorButton
                style={{
                  justifyContent: "center",
                  fontWeight: "bold",
                  margin: 5,
                }}
                onClick={copy}
              >
                {" "}
                copy to clipboard
              </ColorButton>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketing;
