import React, { useState } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ColorButton } from "../../common/Components/ColorButton";
import { TextField } from "@material-ui/core";
import { saveRegionData } from "../../backend/saveDataToFirestore";
import "firebase/firestore";

const RegionDialog = ({
  initialRegionData,
  regionData,
  setRegionData,
  isUpdate,
  setUpdate,
  dialogInfo,
  closeDialog,
  client_id,
  getRegions,
}) => {
  const handleClose = () => {
    setRegionData(initialRegionData);
    closeDialog();
    setUpdate(false);
  };

  function handleSubmit() {
    saveRegionData(client_id, regionData);
    getRegions();
    handleClose();
  }

  function updateRegionDetails(e) {
    let newRegion = { ...regionData };
    newRegion[e.target.name] = e.target.value;
    setRegionData(newRegion);
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={dialogInfo.open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        {isUpdate ? "Edit Region" : "Add Region"}
      </DialogTitle>
      <DialogContent dividers={true}>
        <TextField
          required
          fullWidth
          label="Region Name"
          variant="outlined"
          name="name"
          value={regionData.name}
          style={{ marginBottom: "10px" }}
          onChange={(e) => updateRegionDetails(e)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <ColorButton onClick={handleSubmit}>Submit</ColorButton>
      </DialogActions>
    </Dialog>
  );
};
export default RegionDialog;
