import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import { useFirestoreCollectionData, useUser } from "reactfire";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import swal from "sweetalert";
import "firebase/firestore";
import "firebase/auth";
import firebase from "firebase/app";

import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import { useHistory } from "react-router-dom";
import {
  deleteUser,
  assignLeadToSE,
  notifySeChange,
} from "../../backend/sendRequestToServer";

import { getPageName } from "././../../common/const";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { deleteLeadWithID } from "../../backend/deleteDataFromFirestore";
import { getSalesUserFromId } from "../../backend/getDataFromFirestore";
import { ColorButton } from "../../common/Components/ColorButton";

// import firebase from 'firebase'
// import firebase from "firebase"

const GetLeadsFromRSH = () => {
  const [seList, setSeList] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [filterStatus, setFilterStatus] = useState("All");

  const [showSpinner, setShowSpinner] = React.useState(false);

  const [selectedLead, setSelectedLead] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  let history = useHistory();
  var tempRSH = "";
  var emailtest = "";

  const [leads, setLeads] = useState([]);
  const cshDocId = localStorage.getItem("userDocId"); //CSH Doc Id

  const leadsRef = useFirestore()
    .collection("Leads")
    .where("csh", "==", cshDocId);
  const { status, data } = useFirestoreCollectionData(leadsRef);

  useEffect(() => {
    console.log("It rendered");
    if (data) {
      setLeads(data);
      setSearchResult(searchLeadResult(searchValue, filterStatus));
    }
  }, [data]);

  //   var seList=[];

  // var email_test=""

  // const GetEmail = () => {

  // const [email, setEmail] = useState("");

  // const { data: firebaseUser } = useUser();

  // useEffect(() => {
  //   if (firebaseUser) {
  //     setEmail(firebaseUser.email);
  //   }
  // }, [firebaseUser]);

  //     const db = useFirestore();
  //     return("ss");
  //   };

  useEffect(() => {
    fetchSE();
  }, []);

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleReset = () => {
    window.location.reload();
  };

  const AssignLead = async (e) => {
    const swalRes = await swal(
      "Confirm SE",
      "Do you want to assign this SE for this Lead ?",
      "info",
      {
        buttons: {
          cancel: "No",
          catch: {
            text: "Yes",
            value: true,
          },
        },
      }
    );

    if (!swalRes) {
      return;
    }

    swal(
      "Assigning Lead",
      "Updating SE for Lead . Please do not refresh this page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );

    const status = await assignLeadToSE({
      personalemail: e.personalemail,
      se: e.se,
    });

    swal.close();

    if (status === 200) {
      notifySeChange({ leadId: e.id });
      setShowSpinner(false);
      swal("Success!", "Lead Assigned", "success");
    } else {
      setShowSpinner(false);
      swal("Failed!", "Failed to assign Lead. Try Again!!!", "error");
    }
  };

  const onChange = (e, id) => {
    setLeads(
      leads.map((lead) => {
        if (lead.id === id) {
          lead.se = e.target.value;
        }
        return lead;
      })
    );
  };

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const handleClose = () => {
    setOpen(false);
  };

  const fetchSE = async () => {
    // console.log("kalyan",emailtest);

    db.collection("Users")
      .where("createdby", "==", cshDocId)
      .where("userDeleted", "==", false)
      .get()
      .then((querySnapshot) => {
        let tempUserEmails = {};
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          console.log("I am here", doc.data().email);
          tempUserEmails[`${doc.id}`] = doc.data().email;

          // seList.push({value: doc.data().email});

          // console.log(seList);
        });

        setSeList(tempUserEmails);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  //  View selected lead btn click
  const handleViewBtn = (lead_detail) => {
    console.log(lead_detail);
    history.push("/lead-detail", lead_detail);
  };

  //  Search & filter in textbox
  const searchLeadResult = (name, status) => {
    return data.filter((lead) => {
      if (lead && status !== "All") {
        return (
          lead.companyName.toLowerCase().includes(name.toLowerCase()) &&
          lead.status.toLowerCase() === status.toLowerCase()
        );
      }
      if (name || status === "All") {
        return (
          lead.companyName.toLowerCase().includes(name.toLowerCase()) ||
          lead.city.toLowerCase().includes(name.toLowerCase()) ||
          seList[lead?.se]?.toLowerCase().includes(name.toLowerCase())
        );
      }
      if (status) {
        return lead.status.toLowerCase() === status.toLowerCase();
      }
      return true;
    });
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchLeadResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    setSearchResult(searchLeadResult(searchValue, status));
  };

  function editLead(lead) {
    history.push("/edit-lead", lead);
  }

  function deleteLead(lead) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Lead!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: "Deleting the Lead. Do not Refresh",
          icon: "warning",
          buttons: false,
          dangerMode: true,
        });

        const res = await deleteLeadWithID(lead.id);

        if (res.successfull) {
          swal("Success", "Lead Successfully Deleted!!!", "success");
        } else {
          swal(
            "Error",
            "Error Occur while Deleting. Please Try Again.",
            "error"
          );
        }
      }
    });
  }

  // table view
  const LeadsTableView = ({ leads }) => {
    return (
      <div className="row align-items-center">
        <Table key="LeadTable">
          <Thead>
            <Tr>
              <Th>Company Name</Th>
              <Th>Email</Th>
              <Th>Assigned To</Th>
              <Th>City</Th>
              <Th>Assgin SE</Th>
              <Th>Action</Th>
              <Th>Details</Th>
            </Tr>
          </Thead>
          <Tbody>
            {leads.map((lead, idx) => {
              return (
                <Tr key={idx}>
                  <Td>{lead.companyName}</Td>
                  <Td>{lead.personalemail}</Td>
                  <Td>{seList[`${lead.se}`]}</Td>
                  <Td>{lead.city}</Td>
                  <Td>
                    <TextField
                      required
                      className="table-select"
                      id="outlined-indian-state"
                      select
                      label="Assign to SE"
                      // value={employee.indianState}
                      // onClick={AssignDrop()}
                      value={lead.se}
                      onChange={(e) =>
                        AssignLead({ ...lead, se: e.target.value })
                      }
                      variant="outlined"
                      style={{ marginBottom: "10px" }}
                    >
                      {Object.keys(seList).length === 0 ? (
                        <MenuItem disabled key={1} value={null}>
                          No SE
                        </MenuItem>
                      ) : (
                        Object.keys(seList).map((key) => (
                          <MenuItem key={key} value={key}>
                            {seList[key]}
                          </MenuItem>
                        ))
                      )}
                    </TextField>
                  </Td>
                  <Td>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => deleteLead(lead)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      aria-label="edit"
                      id={lead.id}
                      onClick={(e) => editLead(lead)}
                    >
                      <CreateIcon />
                    </IconButton>
                    {/* <ColorButton
                      variant="contained"
                      className={classes.button}
                      onClick={() => AssignLead(lead)}
                    >
                      {" "}
                      Assign
                    </ColorButton> */}
                  </Td>
                  <Td>
                    <ColorButton
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        setOpen(true);
                        setSelectedLead(lead);
                      }}
                    >
                      {" "}
                      View
                    </ColorButton>
                  </Td>
                </Tr>
              );
            })}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"View Lead Details"}
              </DialogTitle>
              <DialogContent>
                <LeadCard lead={selectedLead} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </Tbody>
        </Table>
      </div>
    );
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>View Leads</h2>

        <div className="row pb-3 justify-content-center align-items-center">
          <div className="col-10 col-md-10">
            <input
              type="text"
              className="search-box"
              placeholder="Search Lead"
              onChange={(e) => {
                search(e);
              }}
              style={{ width: "275px", height: "40px" }}
            />
          </div>
          <div className="col col-md-2 mt-2 float-right">
            <TextField
              required
              id="outlined-indian-state"
              select
              label="Status"
              // value={employee.indianState}
              // onClick={AssignDrop()}
              value={filterStatus}
              onChange={(e) => onFilter(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                classes: {
                  root: classes.selectRoot,
                  select: classes.select,
                },
              }}
              style={{ width: "11ch", float: "right", verticalAlign: "center" }}
            >
              <MenuItem key="All" value="All">
                All
              </MenuItem>

              <MenuItem key="Warm" value="Warm">
                Warm
              </MenuItem>
              <MenuItem key="Cold" value="Cold">
                Cold
              </MenuItem>
              <MenuItem key="Lost" value="Lost">
                Lost
              </MenuItem>
              <MenuItem key="Hot" value="Hot">
                Hot
              </MenuItem>
            </TextField>
          </div>
        </div>

        {leads.length === 0 ? (
          <div className="row justify-content-center">
            <p>No lead's yet!</p>
          </div>
        ) : (
          <>
            {leads.length > 0 && filterStatus === "" && searchValue === "" ? (
              <LeadsTableView leads={leads} />
            ) : (
              <>
                {searchResult.length > 0 ? (
                  <LeadsTableView leads={searchResult} />
                ) : (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default GetLeadsFromRSH;

const LeadCard = ({ lead }) => {
  const [emails, setEmails] = useState({
    nsh: "",
    rsh: "",
    csh: "",
    se: "",
  });

  const [checkMail, setCheckMail] = useState(false);

  async function getEmails() {
    let nshData = await getSalesUserFromId(lead.nsh);
    let rshData = await getSalesUserFromId(lead.rsh);
    let cshData = await getSalesUserFromId(lead.csh);
    let seData = await getSalesUserFromId(lead.se);

    setEmails({
      nsh: nshData ? nshData.email : "",
      rsh: rshData ? rshData.email : "",
      csh: cshData ? cshData.email : "",
      se: seData ? seData.email : "",
    });

    setCheckMail(true);
  }
  const myEmail = localStorage.getItem("email");
  return (
    <div key={lead.id} style={{ width: "275px", padding: "2px" }}>
      <p className="author-name">Company Name:{lead.companyName}</p>
      <p>Email: {lead.personalemail}</p>
      <p>Phone: {lead.mobileno}</p>
      <p>Status: {lead.status}</p>
      <p>Address: {lead.address}</p>
      <p>City: {lead.city}</p>
      <p>State: {lead.state}</p>
      <p>Pincode: {lead.pincode}</p>
      <p>Source: {lead.source}</p>
      <p>
        Contact Person:{" "}
        {lead.contactPerson === undefined ? "" : lead.contactPerson}
      </p>
      <p>
        Department Name:{" "}
        {lead.departmentName === undefined ? "" : lead.departmentName}
      </p>
      <p>Lead Converted: {lead.converted ? "Yes" : "No"}</p>

      {!checkMail ? (
        <ColorButton onClick={getEmails}>Show Lead Refrences</ColorButton>
      ) : (
        <span>
          <p>NSH: {emails.nsh}</p>
          <p>RSH: {emails.rsh}</p>
          <p>CSH: {emails.csh}</p>
          <p>SE: {emails.se}</p>
        </span>
      )}

      <br />
    </div>
  );
};
