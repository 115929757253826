import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import swal from "sweetalert";
import { CircularProgress, Container } from "@material-ui/core";
import clsx from "clsx";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import { getPageName } from "././../../common/const";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Layout from "../../common/Layout";
import "firebase/storage";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import {
  indianStates,
  enterpriseTypes,
  goodsTypes,
} from "../../common/const.js";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  uploadFileToStorage,
  getDownloadURL,
} from "../../backend/uploadFileToStorage";

import {
  getEstoreCount,
  incrementEstoreCount,
} from "../../backend/getCounters";

import { saveEstoreData } from "../../backend/saveDataToFirestore";
// import sendEmailAndPassToServer from "../../backend/sendRequestToServer";
import {
  getCitiesListFromState,
  getFranchiseNameList,
} from "../../backend/getDataFromFirestore";
import { useLocation } from "react-router";
import { ColorButton } from "../../common/Components/ColorButton";
import { editEstoreData } from "../../backend/editDataInFirestore";
import { HistoryOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
// import { getCitiesList } from "../../backend/getDataFromFirestore.js";

const AddEstore = () => {
  const history = useHistory();
  const location = useLocation();
  let data = location.state;
  console.log("Data is ", data);

  const [franchiseNames, setFranchiseNames] = useState({});
  let name = localStorage.getItem("name");
  let docId = localStorage.getItem("userDocId");

  const initalFormData = {
    companyName: "",
    address: "",
    city: "",
    indianState: "",
    postalCode: "",
    // panNo: "",
    // gstNo: "",
    shopNo: "",
    // udyamNo: "",
    // udyogAadharNo: "",
    // adharNo: "",
    // typeEnterprise: "",
    // typeGoods: "",
    date: new Date(),
    assignedFranchise: name,
    franchiseId: docId,
    // bankName: "",
    // ifsc: "",
    // accountNo: "",
    // bankBranch: "",
    // branchHead: "",
    // mobileNo: "",
    // personalEmail: "",
    // personalAadhar: "",
    email: "",
    password: "",
    shopLicense: null,
    rentAgrement: null,
    // udyamCerti: null,
    // gstCerti: null,

    // cancelledChq: null,
  };
  const [formData, setFormData] = React.useState(initalFormData);
  const [showMobileErr, setMobileErr] = useState("");
  const [showPassErr, setPassErr] = useState("");
  const [showEmailErr, setEmailErr] = useState("");
  const [showPersonalErr, setPersonalErr] = useState("");
  const [cities, setCities] = useState([]);

  const [showPassword, setShowPassword] = useState(false);

  //this hook is used to identify wether edit form or add form
  useEffect(() => {
    getFrNameList();
  }, []);

  async function getCities(state) {
    const temp = await getCitiesListFromState(state);
    setCities(temp);
  }

  async function getFrNameList() {
    if (data !== undefined) {
      await getCities(data.indianState);

      setFormData({
        ...data,
        date: new Date(data.date.seconds * 1000),
        // assignedFranchise:
        //   data.assignedFranchise === undefined ? "" : data.assignedFranchise,
      });
    }
    const newFranchiseNames = await getFranchiseNameList();
    setFranchiseNames(newFranchiseNames);
  }

  async function getEstoreCode() {
    const count = (await getEstoreCount()) + 1;
    console.log("count: ", count);
    const estoreCode =
      "E" +
      formData.indianState.substr(-3, 2).toUpperCase() +
      formData.city.substr(-4, 3).toUpperCase() +
      ("000" + count).substr(-3);
    console.log(estoreCode);
    return [count, estoreCode];
  }

  console.log("FormData is ", formData);

  const changeValues = (e, field) => {
    //Add franchise Id to formData for fetching franchise details

    setFormData({ ...formData, [`${field}`]: e.target.value });

    switch (field) {
      case "mobileNo":
        if (!validatePhone(e.target.value)) {
          setMobileErr("Please Enter Valid Phone number");
        } else {
          setMobileErr("");
        }
        break;

      case "personalEmail":
        if (!validateEmail(e.target.value)) {
          setPersonalErr("Please Enter Valid Email");
        } else {
          setPersonalErr("");
        }
        break;

      case "email":
        if (!validateEmail(e.target.value)) {
          setEmailErr("Please Enter Valid Email");
        } else {
          setEmailErr("");
        }
        break;

      case "password":
        if (!validatePassword(e.target.value)) {
          setPassErr(
            "Minimum Length 8 and only alphanumeric and special characters are allowed "
          );
        } else {
          setPassErr("");
        }
        break;

      case "indianState":
        getCities(e.target.value);
        break;

      default:
        break;
    }
  };

  // phone validation
  const validatePhone = (mob) => {
    const re = /^[0-9]{10}$/;
    return re.test(mob);
  };

  const handleDateChange = (newDate) => {
    setFormData({ ...formData, date: newDate });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: "10px",
      boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
    },
    form: {
      margin: "15px 15px 15px 15px",
      borderRadius: "10px",
      padding: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    textField: {
      width: "69ch",
    },
    margin: {
      margin: theme.spacing(1),
    },

    errMsg: {
      color: "red",
      fontWeight: "bold",
      fontSize: "small",
      textAlign: "left",
      marginBottom: "10px",
    },
  }));

  function getSteps() {
    return [
      "E-Store Details",
      // "Bank Details",
      "Owner Details",
      "Upload Documents",
    ];
  }

  // email validation
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  // password validation
  const validatePassword = (pass) => {
    const re = /^[A-za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;
    return re.test(pass);
  };

  //Upload Documents

  const handleFiles = (e, field) => {
    setFormData({ ...formData, [`${field}`]: e.target.files[0] });
  };

  //Important Function Don't Remove
  const handleUpload = async (file, estoreCode) => {
    if (typeof file === "string") return file;
    const ref = `/estore-documents/${estoreCode}/${file.name}`;
    const uploadTask = uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return url;
  };

  // password visibility button
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            {/* <h2 style={{ marginLeft: "auto" }}>{getPageName()}</h2> */}
            <form className={classes.form} noValidate autoComplete="off">
              <TextField
                required
                id="outlined-company-name"
                label="E-Store Name"
                variant="outlined"
                value={formData.companyName}
                onChange={(e) => changeValues(e, "companyName")}
                style={{ marginBottom: "10px" }}
              />
              {/* <TextField
                id="outlined-franchise-name"
                select
                label="Assign Parent Franchise if any."
                value={formData.assignedFranchise}
                onChange={(e) => changeValues(e, "assignedFranchise")}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                {Object.keys(franchiseNames).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField> */}
              <TextField
                required
                id="outlined-address"
                label="Address"
                variant="outlined"
                value={formData.address}
                onChange={(e) => changeValues(e, "address")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-indian-state"
                select
                label="State"
                value={formData.indianState}
                onChange={(e) => changeValues(e, "indianState")}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                {indianStates.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                disabled={formData.indianState === ""}
                required
                fullWidth
                id="outlined-city"
                select
                label="City"
                value={formData.city}
                onChange={(e) => changeValues(e, "city")}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                {cities.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                id="outlined-postal-code"
                type="postal"
                inputProps={{ maxLength: 6 }}
                label="Postal Code"
                variant="outlined"
                value={formData.postalCode}
                onChange={(e) => changeValues(e, "postalCode")}
                style={{ marginBottom: "10px" }}
              />
              {/* <TextField
                required
                id="outlined-pan-number"
                label="PAN Number"
                inputProps={{ maxLength: 10 }}
                variant="outlined"
                value={formData.panNo}
                onChange={(e) => changeValues(e, "panNo")}
              />
              <br />
              <TextField
                required
                id="outlined-gst-number"
                label="GST Number"
                inputProps={{ maxLength: 15 }}
                variant="outlined"
                value={formData.gstNo}
                onChange={(e) => changeValues(e, "gstNo")}
              />  <br /> */}
              <TextField
                required
                id="outlined-shop-number"
                label="E-Store Shop/License Number"
                variant="outlined"
                value={formData.shopNo}
                onChange={(e) => changeValues(e, "shopNo")}
                style={{ marginBottom: "10px" }}
              />
              {/* <TextField
                required
                id="outlined-udyam-number*"
                label="Udyam Registration Number"
                inputProps={{ maxLength: 10 }}
                variant="outlined"
                value={formData.udyamNo}
                onChange={(e) => changeValues(e, "udyamNo")}
              />
              <br />
              <TextField
                required
                id="outlined-aadhar-number"
                label="Udyog Aadhar Memorandum Number"
                inputProps={{ maxLength: 12 }}
                variant="outlined"
                value={formData.udyogAadharNo}
                onChange={(e) => changeValues(e, "udyogAadharNo")}
              />
              <br />
              <TextField
                required
                id="outlined-type-enterprise"
                select
                label="Type of Enterprise"
                value={formData.typeEnterprise}
                onChange={(e) => changeValues(e, "typeEnterprise")}
                variant="outlined"
              >
                {enterpriseTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <TextField
                required
                id="outlined-type-goods"
                select
                label="Type of Goods"
                value={formData.typeGoods}
                onChange={(e) => changeValues(e, "typeGoods")}
                variant="outlined"
              >
                {goodsTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <br /> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  required
                  label="Date of E-Store Establishment"
                  format="dd/MM/yyyy"
                  value={formData.date}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  style={{ marginBottom: "10px" }}
                />
              </MuiPickersUtilsProvider>
              <br />
            </form>
          </>
        );

      // case 1:
      //   return (
      //     <>
      //       <form className={classes.form} noValidate autoComplete="off">
      //         <TextField
      //           required
      //           id="outlined-bank-name"
      //           label="Bank Name"
      //           variant="outlined"
      //           defaultValue=""
      //           value={formData.bankName}
      //           onChange={(e) => changeValues(e, "bankName")}
      //         />
      //         <br />
      //         <TextField
      //           required
      //           id="outlined-ifsc-code"
      //           inputProps={{ maxLength: 11 }}
      //           label="IFSC Code"
      //           variant="outlined"
      //           value={formData.ifsc}
      //           onChange={(e) => changeValues(e, "ifsc")}
      //         />
      //         <br />
      //         <TextField
      //           required
      //           id="outlined-account-number"
      //           label="Account Number"
      //           inputProps={{ minLength: 18 }}
      //           variant="outlined"
      //           value={formData.accountNo}
      //           onChange={(e) => changeValues(e, "accountNo")}
      //         />
      //         <br />
      //         <TextField
      //           required
      //           id="outlined-branch"
      //           label="Branch"
      //           variant="outlined"
      //           value={formData.bankBranch}
      //           onChange={(e) => changeValues(e, "bankBranch")}
      //         />
      //         <br />
      //       </form>
      //     </>
      //   );

      case 1:
        return (
          <>
            <form className={classes.form} noValidate autoComplete="off">
              {/* <TextField
                required
                id="outlined-branch-head"
                type="text"
                label="Owner/Branch Head Name"
                variant="outlined"
                value={formData.branchHead}
                onChange={(e) => changeValues(e, "branchHead")}
              />
              <br />
              <TextField
                required
                id="outlined-mobile-number"
                inputProps={{ maxLength: 10 }}
                label="Mobile Number"
                variant="outlined"
                value={formData.mobileNo}
                onChange={(e) => changeValues(e, "mobileNo")}
              />

              <span className={classes.errMsg}>{showMobileErr}</span>
              <br />
              <TextField
                required
                id="outlined-personal-email"
                type="email"
                label="Personal Email ID"
                variant="outlined"
                value={formData.personalEmail}
                onChange={(e) => changeValues(e, "personalEmail")}
              />
              <span className={classes.errMsg}>{showPersonalErr}</span>

              <br />
              <TextField
                required
                id="outlined-personal-aadhar"
                label="Personal Aadhar Number"
                inputProps={{ maxLength: 12 }}
                variant="outlined"
                value={formData.personalAadhar}
                onChange={(e) => changeValues(e, "personalAadhar")}
              />
              <br /> */}
              <TextField
                required
                disabled={formData.id !== undefined}
                id="outlined-estore-aadhar"
                type="email"
                label="E-Store Email ID"
                variant="outlined"
                value={formData.email}
                onChange={(e) => changeValues(e, "email")}
              />
              <span className={classes.errMsg}>{showEmailErr}</span>

              <FormControl className={clsx(classes.margin)} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password*
                </InputLabel>
                <OutlinedInput
                  type={showPassword ? "text" : "password"}
                  disabled={formData.id !== undefined}
                  id="outlined-adornment-password"
                  required
                  value={formData.password}
                  onChange={(e) => changeValues(e, "password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
              <span className={classes.errMsg}>{showPassErr}</span>
            </form>
          </>
        );
      case 2:
        return (
          <Container style={{ padding: "10px" }}>
            <h5 style={{ textAlign: "center" }}>Upload Documents</h5>

            {/* <InputLabel htmlFor="gst-certificate">
              Upload Gst certificate
            </InputLabel>
            <input
              id="gst-certificate"
              type="file"
              onChange={(e) => handleFiles(e, "gstCerti")}
            />
            {typeof formData.gstCerti === "string" ? (
              <ColorButton
                variant="contained"
                className={classes.button}
                onClick={() => (window.location.href = formData.gstCerti)}
              >
                {" "}
                View
              </ColorButton>
            ) : (
              ""
            )}

            <br />
            <br /> */}

            <InputLabel htmlFor="rent-agreement">
              Upload E-Store Rent agreement
            </InputLabel>
            <input
              id="rent-agreement"
              type="file"
              onChange={(e) => handleFiles(e, "rentAgrement")}
              style={{ marginBottom: "10px" }}
            />
            {typeof formData.rentAgrement === "string" ? (
              <ColorButton
                variant="contained"
                className={classes.button}
                onClick={() => window.open(formData.rentAgrement, "_blank")}
                style={{ marginBottom: "10px" }}
              >
                View
              </ColorButton>
            ) : (
              ""
            )}

            <br />

            <InputLabel htmlFor="shop-license">
              Upload E-Store Shop license
            </InputLabel>
            <input
              id="shop-license"
              type="file"
              onChange={(e) => handleFiles(e, "shopLicense")}
              style={{ marginBottom: "10px" }}
            />
            {typeof formData.shopLicense === "string" ? (
              <ColorButton
                variant="contained"
                className={classes.button}
                onClick={() => window.open(formData.shopLicense, "_blank")}
                style={{ marginBottom: "10px" }}
              >
                View
              </ColorButton>
            ) : (
              ""
            )}

            <br />

            {/* <InputLabel htmlFor="udyam-cerificate">
              Upload Udyam certificate
            </InputLabel>
            <input
              id="udyam-cerificate"
              type="file"
              onChange={(e) => handleFiles(e, "udyamCerti")}
            />
            {typeof formData.udyamCerti === "string" ? (
              <ColorButton
                variant="contained"
                className={classes.button}
                onClick={() => window.open(formData.udyamCerti, "_blank")}
              >
                View
              </ColorButton>
            ) : (
              ""
            )}

            <br />
            <br /> */}

            {/* <InputLabel htmlFor="canceled-chque">
              Upload Canceled cheque
            </InputLabel>
            <input
              id="canceled-chque"
              type="file"
              onChange={(e) => handleFiles(e, "cancelledChq")}
            />
            {typeof formData.cancelledChq === "string" ? (
              <ColorButton
                variant="contained"
                className={classes.button}
                onClick={() => window.open(formData.cancelledChq, "_blank")}
              >
                View
              </ColorButton>
            ) : (
              ""
            )}

            <br />
            <br /> */}
          </Container>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  function checkFormCompletion() {
    if (activeStep === 0) {
      if (
        formData.companyName === "" ||
        formData.address === "" ||
        formData.city === "" ||
        formData.indianStatet === "" ||
        formData.postalCode === "" ||
        // formData.panNo === "" ||
        // formData.gstNo === "" ||
        formData.shopNo === ""
        // formData.udyamNo === "" ||
        // formData.udyogAadharNo === "" ||
        // formData.typeEnterprise === "" ||
        // formData.typeGoods === "" ||
        // formData.assignedFranchise === ""
      ) {
        setErrorMsg("Fill all the form fields.");
        return false;
      }
    }
    // if (activeStep === 1) {
    //   if (
    //     formData.bankName === "" ||
    //     formData.ifsc === "" ||
    //     formData.accountNo === "" ||
    //     formData.bankBranch === ""
    //   ) {
    //     setErrorMsg("Fill all the form fields.");
    //     return false;
    //   }
    // }
    if (activeStep === 1) {
      if (
        // formData.branchHead === "" ||
        // formData.mobileNo === "" ||
        // formData.personalEmail === "" ||
        // formData.personalAadhar === "" ||
        formData.email === "" ||
        formData.password === ""
      ) {
        setErrorMsg("Fill all the form fields.");
        return false;
      }
      if (showEmailErr !== "" || showPassErr !== "") {
        console.log("email or ppass is wrong", showEmailErr, showPassErr);
        return false;
      }
    }
    return true;
  }

  async function updateDataInFireStore() {
    setShowSpinner(true);
    const franchiseCode = formData.franchiseCode;

    const rentAgrementUrl = await handleUpload(
      formData.rentAgrement,
      franchiseCode
    );
    const shopLicenseUrl = await handleUpload(
      formData.shopLicense,
      franchiseCode
    );

    // const gstUrl = await handleUpload(formData.gstCerti, franchiseCode);
    // const udyamCertiUrl = await handleUpload(
    //   formData.udyamCerti,
    //   franchiseCode
    // );
    // const cancelledChqUrl = await handleUpload(
    //   formData.cancelledChq,
    //   franchiseCode
    // );

    const newFormData = {
      ...formData,
      rentAgrement: rentAgrementUrl,
      shopLicense: shopLicenseUrl,
      // gstCerti: gstUrl,
      // cancelledChq: cancelledChqUrl,
      // udyamCerti: udyamCertiUrl,
    };

    if ((await editEstoreData(newFormData)).successfull) {
      setShowSpinner(false);
      swal("Success!", "EStore Details Edited", "success").then((value) => {
        history.goBack();
      });
    } else {
      setShowSpinner(false);
      swal("Failed!", "Unable to edit EStore. Please try again.", "error").then(
        (value) => {
          handleBack();
        }
      );
    }
  }

  async function saveDataToFirestore() {
    setShowSpinner(true);
    const data = await getEstoreCode();
    const estoreCode = data[1],
      count = data[0];

    const rentAgrementUrl = await handleUpload(
      formData.rentAgrement,
      estoreCode
    );
    const shopLicenseUrl = await handleUpload(formData.shopLicense, estoreCode);

    // const gstUrl = await handleUpload(formData.gstCerti, estoreCode);
    // const udyamCertiUrl = await handleUpload(formData.udyamCerti, estoreCode);
    // const cancelledChqUrl = await handleUpload(
    //   formData.cancelledChq,
    //   estoreCode
    // );

    const newFormData = {
      ...formData,
      rentAgrement: rentAgrementUrl,
      shopLicense: shopLicenseUrl,
      // gstCerti: gstUrl,
      // udyamCerti: udyamCertiUrl,
      // cancelledChq: cancelledChqUrl,
      estoreCode: estoreCode,
    };

    console.log(newFormData);

    if ((await saveEstoreData(newFormData)).successfull) {
      incrementEstoreCount(count);
      setShowSpinner(false);
      swal("Success!", "Add E-Store Request Sent to Admin.", "success").then(
        (value) => handleReset()
      );
    } else {
      setShowSpinner(false);
      swal(
        "Failed!",
        "Unable to request to add E-Store. Please fill the form again.",
        "error"
      ).then((value) => handleBack());
    }
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (
        formData.rentAgrement === null ||
        formData.shopLicense === null
        // formData.gstCerti === null ||
        // formData.udyamCerti === null ||
        // formData.cancelledChq === null
      ) {
        setErrorMsg("Fill all the form fields.");
        return false;
      }

      if (formData.id === undefined) {
        saveDataToFirestore();
      } else {
        updateDataInFireStore();
      }
      console.log(formData);
    }

    //nullchecks
    if (!checkFormCompletion()) {
      return null;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setFormData(initalFormData);
    setActiveStep(0);
  };

  const [errorMsg, setErrorMsg] = React.useState("");
  const [showSpinner, setShowSpinner] = React.useState(false);

  //Dynamic pagetitle variable
  const [pageTitle, setPageTitle] = useState(undefined);
  useEffect(() => {
    if (formData.id === undefined) {
      setPageTitle("Add E-Store");
    } else {
      setPageTitle("Edit E-Store Details");
    }
  }, [formData.id]);

  return (
    <>
      <Layout />

      <div className="container mt-5 pt-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6">
            <h2 style={{ textAlign: "center" }}>
              {formData.id ? "Edit E-Store Details" : "Add E-Store"}
            </h2>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {activeStep === steps.length ? (
                    <div>
                      {showSpinner ? (
                        <div>
                          <span
                            style={{ marginLeft: "10px", color: "#ff0000" }}
                          >
                            <h5>Please DO NOT REFRESH the page...</h5>
                          </span>
                          <CircularProgress
                            style={{
                              color: "#18D26E",
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {getStepContent(activeStep)}
                      <CardActions
                        style={{ justifyContent: "center", fontWeight: "bold" }}
                      >
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.backButton}
                        >
                          Back
                        </Button>
                        <ColorButton
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                        >
                          {activeStep === steps.length - 1 ? "Submit" : "Next"}
                        </ColorButton>
                        <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                          {errorMsg}
                        </span>
                      </CardActions>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      {/* </Layout> */}
    </>
  );
};

export default AddEstore;
