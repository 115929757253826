import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";

import ReactPaginate from 'react-paginate';

import { useLocation } from "react-router";
import { getProductFromId } from "../../backend/getDataFromFirestore";
import Layout from "../../common/Layout";
import ClientApprovalProductCard from "./ClientApprovalProductCard";
import { ColorButton } from "../../common/Components/ColorButton";
import {
  MenuItem,
  TextField
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  products: {
    display: "flex",
    flexWrap: "wrap",
  },
  root: {
    borderRadius: "10px",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: "69ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  stepperColor: {
    color: "#18D26E",
  },
  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
  },
  products: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  category: {
    width: "29ch",
    marginRight: "2em",
    paddingBottom: "10px",
  },
  button: {
    margin: "1em",
    width: "20ch",
  },

}));

const ClientApprovalProductList = () => {
  const classes = useStyles();
  const location = useLocation();
  let productIds = location.state.state;
  let productCategory = location.state.categories;
  const [products, setProducts] = useState([]);

  const [offset, setOffset] = useState(0);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0)
  const [showLoading,setShowLoading] = useState(false);
  const [showPaginationBtn, setShowPaginationBtn] = useState(false);

  const [category, setCategory] = useState({});
  const [subCategory, setSubCategory] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState({
   category: "",
   subCategory: "",
 });
 
 console.log(productIds)
 console.log(productCategory)
  

 async function getAllProducts(off = offset) {
  setShowLoading(true)
  let temp = []
  let ids = off*perPage
    const [data,len] = await getProductFromId(productIds,
       categoryDetails.category,
        categoryDetails.subCategory,ids, ids+perPage);
   
  
  setProducts(data);
  setShowPaginationBtn(len > 10)

  console.log("fetched Products :", data);
  console.log("Products in state :", data);

  setShowLoading(false)
  setPageCount(Math.ceil(len / perPage))
  console.log('offset : ',offset)
}

 function getCategories() {
  
setCategory(productCategory);
 console.log(productCategory) 

}



useEffect(() => {
  getCategories();
}, [])

const handlePageClick = (e) => {
  console.log(e.selected);
  const selectedPage = e.selected;
  setOffset(selectedPage);
  getAllProducts(selectedPage);
};

  return (
    <Layout>
       <div className="container mt-5 pt-5">
        <h3 className="text-center">Product List</h3>
        <form className={classes.form} noValidate autoComplete="off">
              <div>
                <TextField
                  required
                  id="outlined-category"
                  select
                  label="Type of Category"
                  variant="outlined"
                  className={classes.category}
                  onChange={(e) => {
                    setCategoryDetails({
                      ...categoryDetails,
                      category: e.target.value,
                    });
                    setOffset(0);
                    setSubCategory(category[e.target.value])
                  }}
                >
                  {Object.keys(category).map((option,i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  required
                  id="outlined-sub-category"
                  select
                  label="Type of Sub-Category"
                  variant="outlined"
                  className={classes.category}
                  onChange={(e) => {
                    setCategoryDetails({
                      ...categoryDetails,
                      subCategory: e.target.value,
                    });
                    setOffset(0);
                  }}
                >
                  {subCategory.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>

                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    
                    getAllProducts();
                  }}
                >
                  Get Products
                </ColorButton>
              </div>
                  </form>
  
        {showLoading ? <CircularProgress
      style={{
        position: "absolute",
        left: "50%",
        top: "55%",
        transform: "translate(-50%, -50%)",
        color: "#18D26E",
      }}
    /> : (

<div className="row align-items-center justify-content-center mt-5">
       {products.map((value, index) => {
            return <ClientApprovalProductCard key={index} productDetail={value} />;
          })}
      </div>
    )}
     {showPaginationBtn && <div className="row align-items-center justify-content-center paginate-div">
        <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    
                    />
        </div>} 
         </div>
    </Layout>
  );
};

export default ClientApprovalProductList;
