import React, { useEffect, useState } from "react";

import XLSX from "xlsx";
import Layout from "../../common/Layout";
import ProductsTable from "./Components/ProductsTable";
import Typography from "@material-ui/core/Typography";

import { Button, MenuItem, TextField } from "@material-ui/core";
import { ColorButton } from "../../common/Components/ColorButton";
import { getPageName } from "../../common/const";
import {
  getCitiesFromProductApproval,
  getCitiesList,
  getPendingProductDataOfCity,
  getProductDataOfCity,
  getVendorsOfCity,
} from "../../backend/getDataFromFirestore";
import swal from "sweetalert";

const ShowProductsAverage = () => {
  let file = [];
  const [productData, setProductData] = useState([]);
  const [city, setCity] = useState("");
  const [vendors, setVendors] = useState({});

  const [cities, setCities] = useState([]);

  useEffect(() => {
    getCities();
  }, []);

  async function getCities() {
    const temp = await getCitiesFromProductApproval();
    setCities(temp);
  }

  const handleData = async (e) => {
    //Read Data from Firebase

    if (city) {
      await getAllCityVendors(city);
      const data = await getPendingProductDataOfCity(city);
      const shortedStoreArray = data.products.sort(
        (a, b) => a.timestamp - b.timestamp
      );
      setProductData(shortedStoreArray);
    } else {
      swal("Error", "Please Select City", "error");
    }
  };

  async function getAllCityVendors(city) {
    const vens = await getVendorsOfCity(city);
    setVendors(vens);
  }

  return (
    <>
      <Layout />
      <div className=" m-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>
        <div className="row">
          <div className="col-12 col-md-12">
            <TextField
              required
              id="outlined-city"
              select
              label="City"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
                setProductData([]);
              }}
              variant="outlined"
              style={{ marginBottom: "10px", width: "30%" }}
            >
              {cities.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            <br />
          </div>
          <div className="col-12 col-md-12">
            <span>
              <ColorButton
                size="small"
                padding="10px"
                variant="contained"
                color="primary"
                onClick={handleData}
              >
                Extract Data
              </ColorButton>
            </span>

            <ProductsTable
              productData={productData}
              productLength={productData.length}
              city={city}
              franchiseData={vendors}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowProductsAverage;
