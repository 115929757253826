import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import Popover from "@material-ui/core/Popover";
import { getMeetingsOfMonth } from "../../backend/getDataFromFirestore";
import { MeetingCard } from "./MeetingCard";
import "../../fullCalender.css";
import { makeStyles } from "@material-ui/core";
import LeadsManagementWrapper from "PagesV2/LeadsManagement/LeadsManagement";

const useStyles = makeStyles(() => ({}));

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

const ViewMeetings = (event) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [meetings, setMeetings] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [dateMeeting, setDateMeeting] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState({});

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  async function handleOpenModal(event) {
    console.log(event);
    console.log(event.jsEvent.currentTarget);
    let a = event.jsEvent.path[2].scrollHeight;
    let b = event.jsEvent.path[10].scrollTop;
    let c = event.jsEvent.screenY;
    let d = a - b - c;
    console.log(d);

    const selectedMeet = meetings?.find(
      (meet) => meet?.id === event?.event?.id
    );
    console.log(selectedMeet);
    setSelectedMeeting(selectedMeet);
    setAnchorEl(event.jsEvent.path[2]);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const formattedDate = (date) => {
    let dates = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return dates + "/" + month + "/" + year;
  };
  const headerFormatDate = (date) => {
    let dates = date.getDate();
    let month = date.toLocaleString("default", { month: "long" });
    let weekDay = date.toLocaleString("en-us", { weekday: "long" });
    return `${dates} ${month} ${weekDay}`;
  };

  const events = meetings.map((meet) => ({
    title: `Meeting with ${meet.name}`,
    start: new Date(meet.date.seconds * 1000),
    color: "#FFBD00",
    id: meet.id,
  }));
  //   console.log(events);
  const headerToolbar = {
    start: "prev next title",
    end: "today",
  };
  const titleFormat = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  };

  const dayHeaderContent = (args) => {
    let placeTitle = document.createElement("div");
    placeTitle.textContent = "PRA";
    placeTitle.className = "font-large city-title"; // add whatever class(es) you need/want
    let dayTitle = document.createElement("span");
    dayTitle.textContent = args.text;
    // const finalElement = placeTitle + dayTitle;
    // placeTitle.setAttribute('title', 'my-title');

    // const box = document.getElementsByClassName("fc-scrollgrid-sync-inner");
    // box.appendChild(placeTitle);

    console.log(placeTitle);
    return "placeTitle";
  };

  useEffect(() => {
    getMeetings(selectedMonth);
  }, [selectedMonth]);

  async function getMeetings(date) {
    setShowSpinner(true);
    const res = await getMeetingsOfMonth(date);
    setMeetings(res);
    getMeetingsForDate(res, selectedDate);
    setShowSpinner(false);
    console.log(res);
  }
  function getMeetingsForDate(meetings, date) {
    setSelectedDate(date);
    setDateMeeting(
      meetings.filter(
        (meet) =>
          formattedDate(new Date(meet.date.seconds * 1000)) ===
          formattedDate(date)
      )
    );
  }
  const dayHeaderFormat = {
    day: "numeric",
    month: "short",
    weekday: "long",
    omitCommas: false,
  };
  return (
    <>
      <LeadsManagementWrapper>
        <div className="p-8">
          <FullCalendar
            plugins={[timeGridPlugin]}
            initialView="timeGridWeek"
            events={events}
            headerToolbar={headerToolbar}
            eventContent={renderEventContent}
            eventClick={handleOpenModal}
            slotDuration={"01:00"}
            titleFormat={titleFormat}
            allDaySlot={false}
            dayHeaderContent={function (arg) {
              return headerFormatDate(arg.date);
            }}
            // dayHeaderFormat={dayHeaderFormat}
            // dayHeaderContent={headerFormatDate}
          />
          <Popover
            id={Boolean(anchorEl) ? "simple-popover" : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MeetingCard meeting={selectedMeeting} index={1} />
          </Popover>
        </div>
      </LeadsManagementWrapper>
    </>
  );
};
export default ViewMeetings;
