import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Layout from "../../common/Layout";
import { useFirestoreCollectionData } from "reactfire";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import "firebase/firestore";
import "firebase/auth";

import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import { useHistory } from "react-router-dom";
import { assignLeadToRSH } from "../../backend/sendRequestToServer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { deleteLeadWithID } from "../../backend/deleteDataFromFirestore";
import { getSalesUserFromId } from "../../backend/getDataFromFirestore";
import { ColorButton } from "../../common/Components/ColorButton";
import { TabMenu } from "../../common/Components/TabMenu";
import StatusButton from "./StatusButton";
import CommonTable from "../../common/Table";
import { ActionCol } from "../../common/Table/ActionCol";
import { TAB_NAME } from "../../common/const1";

const GetLeads = () => {
  const [rshList, setRshList] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [sortOpt, setSortOpt] = useState("All");
  const [filterStatus, setFilterStatus] = useState("All");
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const [selectedLead, setSelectedLead] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  let history = useHistory();
  var tempRSH = "";
  var emailtest = localStorage.getItem("email");

  const [leads, setLeads] = useState([]);
  const docId = localStorage.getItem("userDocId"); // Doc Id of NSH

  const leadsRef = useFirestore().collection("Leads");
  const { status, data } = useFirestoreCollectionData(leadsRef);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setIsMobileView(true)
        : setIsMobileView(false);
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  function Rsh({ lead }) {
    return (
      <TextField
        fullWidth
        required
        className="table-select"
        id="outlined-indian-state"
        label="Assign RSH"
        select
        value={lead.rsh}
        onChange={(e) => AssignLead({ ...lead, rsh: e.target.value })}
      >
        {Object.keys(rshList).length === 0 ? (
          <MenuItem disabled key={1} value={null}>
            No RSH
          </MenuItem>
        ) : (
          Object.keys(rshList).map((key) => (
            <MenuItem key={key} value={key}>
              {rshList[key]}
            </MenuItem>
          ))
        )}
      </TextField>
    );
  }
  useEffect(() => {
    if (data) {
      setLeads(data);
      setSearchResult(searchLeadResult(searchValue, filterStatus, sortOpt));
    }
  }, [data]);

  useEffect(() => {
    fetchRSH();
  }, []);

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleReset = () => {
    window.location.reload();
  };

  const AssignLead = async (e) => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    //   if (user) {
    //     console.log("This is the user: ", user.email);
    //     emailtest = user.email;

    console.log("aa", e);
    // console.log("bb", indianState);
    console.log("cc", emailtest);
    const swalRes = await swal(
      "Confirm RSH",
      "Do you want to assign this RSH for this Lead ?",
      "info",
      {
        buttons: {
          cancel: "No",
          catch: {
            text: "Yes",
            value: true,
          },
        },
      }
    );

    if (!swalRes) {
      return;
    }

    swal(
      "Assigning Lead",
      "Updating RSH for Lead . Please do not refresh this page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );

    const status = await assignLeadToRSH({
      personalemail: e.personalemail,
      nsh: docId,
      rsh: e.rsh,
    });

    swal.close();

    if (status === 200) {
      setShowSpinner(false);
      swal("Success!", "Lead Assigned", "success");
    } else {
      setShowSpinner(false);
      swal("Failed!", "Failed to assign Lead. Try Again!!!", "error");
    }
  };

  const onChange = (e, id) => {
    // console.log(e.target.name);
    // setEmployee({ ...employee, [`${field}`]: e.target.value });

    setLeads(
      leads.map((lead) => {
        if (lead.id === id) {
          lead.rsh = e.target.value;
        }
        return lead;
      })
    );
  };

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const fetchRSH = async () => {
    //Users Created by NSH are RSH... createdby field has RSH Id.
    console.log(docId);
    db.collection("Users")
      .where("createdby", "==", docId)
      .where("userDeleted", "==", false)
      .get()
      .then((querySnapshot) => {
        let tempUserEmails = {};
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          // console.log("I am here", doc.data().region);
          tempUserEmails[`${doc.id}`] =
            doc.data().email + " (" + doc.data().region + ")";

          //   indianStates.push({ value: doc.data().email });

          //   console.log(indianStates);
        });

        setRshList(tempUserEmails);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  const searchLeadResult = (name, status, sort) => {
    return data.filter((lead) => {
      // if all conditions
      if (name !== "" && status !== "All" && sort !== "All") {
        return (
          (lead.companyName.toLowerCase().includes(name.toLowerCase()) ||
            lead.city.toLowerCase().includes(name.toLowerCase())) &&
          lead.status.toLowerCase().includes(status.toLowerCase()) &&
          lead.source.toLowerCase().includes(sort.toLowerCase())
        );
      }
      // if only name and status
      if (name !== "" && status !== "All" && sort === "All") {
        return (
          (lead.companyName.toLowerCase().includes(name.toLowerCase()) ||
            lead.city.toLowerCase().includes(name.toLowerCase())) &&
          lead.status.toLowerCase().includes(status.toLowerCase())
        );
      }
      // if only name and sort
      if (name !== "" && status === "All" && sort !== "All") {
        return (
          (lead.companyName.toLowerCase().includes(name.toLowerCase()) ||
            lead.city.toLowerCase().includes(name.toLowerCase())) &&
          lead.source.toLowerCase().includes(sort.toLowerCase())
        );
      }
      // in sort amd status
      if (name === "" && status !== "All" && sort !== "All") {
        return (
          lead.status.toLowerCase().includes(status.toLowerCase()) &&
          lead.source.toLowerCase().includes(sort.toLowerCase())
        );
      }
      // if only name
      if (name !== "" && status === "All" && sort === "All") {
        return (
          lead.companyName.toLowerCase().includes(name.toLowerCase()) ||
          lead.city.toLowerCase().includes(name.toLowerCase())
        );
      }
      // if only status
      if (name === "" && status !== "All" && sort === "All") {
        return lead.status.toLowerCase().includes(status.toLowerCase());
      }
      // if only sort
      if (name === "" && status === "All" && sort !== "All") {
        return lead.source.toLowerCase().includes(sort.toLowerCase());
      }
      return true;
    });
  };

  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchLeadResult(e.target.value, filterStatus, sortOpt));
  };

  // filter
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    setSearchResult(searchLeadResult(searchValue, status, sortOpt));
  };

  const onSort = (sort) => {
    setSortOpt(sort);
    setSearchResult(searchLeadResult(searchValue, filterStatus, sort));
  };

  function editLead(lead) {
    console.log(lead);
    const { action, customRsh, customStatus, ...rest } = lead;
    history.push("/edit-lead", rest);
  }

  function deleteLead(lead) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Lead!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: "Deleting the Lead. Do not Refresh",
          icon: "warning",
          buttons: false,
          dangerMode: true,
        });

        const res = await deleteLeadWithID(lead.id);

        if (res.successfull) {
          swal("Success", "Lead Successfully Deleted!!!", "success");
        } else {
          swal(
            "Error",
            "Error Occur while Deleting. Please Try Again.",
            "error"
          );
        }
      }
    });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columnsData = [
    {
      headerName: "Company Name",
      field: "companyName",
    },
    {
      headerName: "Email",
      field: "personalemail",
      width: 240,
    },
    {
      headerName: "Assigned To",
      field: "assignedTo",
      renderCell: ({ row }) => rshList[row?.rsh],
    },
    {
      headerName: "City",
      field: "city",
    },
    {
      headerName: "Source",
      field: "source",
    },
    {
      headerName: "Assign RSH",
      field: "customRsh",
      width: 210,
      renderCell: ({ row }) => <Rsh lead={row} />,
    },
    {
      headerName: "Status",
      field: "customStatus",
      width: 120,
      renderCell: ({ row }) => <StatusButton status={row?.status} />,
    },
    {
      headerName: "Action",
      field: "actions",
      width: 190,
      renderCell: ({ row }) => (
        <ActionCol
          data={row}
          onView={(value) => {
            setOpen(true);
            setSelectedLead(value);
          }}
          onEdit={editLead}
          onDelete={deleteLead}
        />
      ),
    },
  ];

  return (
    <Layout>
      <div className={isMobileView ? "mt-5 pt-5" : "mt-5 pt-5 mr-5 ml-5"}>
        <TabMenu tabName={TAB_NAME.LEADS_MANAGEMENT} />
        <div className="bg-white max-sm:p-2">
          <div className="pb-3 flex justify-content-center align-items-center">
            {!isMobileView && (
              <div className="col-12 col-md-8">
                <TextField
                  type="text"
                  className="search-box"
                  placeholder="Name, email, etc..."
                  onChange={(e) => {
                    search(e);
                  }}
                  style={{ width: "300px", height: "40px" }}
                  variant="outlined"
                  label="Search"
                />
              </div>
            )}
            <div className="col col-md-4 mt-2 float-right">
              <TextField
                required
                id="outlined-indian-state"
                select
                label="Status"
                // value={employee.indianState}
                // onClick={AssignDrop()}
                value={filterStatus}
                onChange={(e) => onFilter(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                // SelectProps={{
                //   classes: {
                //     root: classes.selectRoot,
                //     select: classes.select,
                //   },
                // }}
                style={{
                  width: "175px",
                  float: "right",
                  verticalAlign: "center",
                }}
              >
                <MenuItem key="All" value="All">
                  All
                </MenuItem>

                <MenuItem key="Warm" value="Warm">
                  Warm
                </MenuItem>
                <MenuItem key="Cold" value="Cold">
                  Cold
                </MenuItem>
                <MenuItem key="Lost" value="Lost">
                  Lost
                </MenuItem>
                <MenuItem key="Hot" value="Hot">
                  Hot
                </MenuItem>
              </TextField>
              <TextField
                required
                id="outlined-indian-state"
                select
                label="Sort By"
                // value={employee.indianState}
                // onClick={AssignDrop()}
                value={sortOpt}
                onChange={(e) => onSort(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                // SelectProps={{
                //   classes: {
                //     root: classes.selectRoot,
                //     select: classes.select,
                //   },
                // }}
                style={{
                  width: "175px",
                  float: "right",
                  verticalAlign: "center",
                  marginRight: "17px",
                }}
              >
                <MenuItem key="All" value="All">
                  All
                </MenuItem>

                <MenuItem key="National Sales Head" value="National Sales Head">
                  National Sales Head
                </MenuItem>
                <MenuItem key="Regional Sales Head" value="Regional Sales Head">
                  Regional Sales Head
                </MenuItem>
                <MenuItem key="Sales Executive" value="Sales Executive">
                  Sales Executive
                </MenuItem>
                <MenuItem key="City Sales Head" value="City Sales Head">
                  City Sales Head
                </MenuItem>
                <MenuItem key="Franchise" value="Franchise">
                  Franchise
                </MenuItem>
                <MenuItem key="Franchise" value="Franchise">
                  Client
                </MenuItem>
                <MenuItem key="Instagram" value="Instagram">
                  Instagram
                </MenuItem>
                <MenuItem key="facebook" value="facebook">
                  facebook
                </MenuItem>
                <MenuItem key="linkedin" value="linkedin">
                  linkedin
                </MenuItem>
              </TextField>
            </div>
          </div>

          {leads.length === 0 ? (
            <div className="row justify-content-center">
              <p>No lead's yet!</p>
            </div>
          ) : (
            <>
              {leads.length > 0 &&
              filterStatus === "All" &&
              searchValue === "" &&
              sortOpt === "All" ? (
                <CommonTable
                  data={leads}
                  mobileOuterCol={[
                    "companyName",
                    "customStatus",
                    "customRsh",
                    "action",
                  ]}
                  cols={columnsData}
                />
              ) : (
                <>
                  {searchResult.length > 0 ? (
                    <CommonTable data={searchResult} cols={columnsData} />
                  ) : (
                    <div className="row pb-3 justify-content-center">
                      <div>Search Not Found!</div>
                    </div>
                  )}
                </>
              )}
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"View Lead Details"}
                </DialogTitle>
                <DialogContent>
                  <LeadCard lead={selectedLead} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Close</Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default GetLeads;

const LeadCard = ({ lead }) => {
  const [emails, setEmails] = useState({
    nsh: "",
    rsh: "",
    csh: "",
    se: "",
  });

  const [checkMail, setCheckMail] = useState(false);

  async function getEmails() {
    let nshData = await getSalesUserFromId(lead.nsh);
    let rshData = await getSalesUserFromId(lead.rsh);
    let cshData = await getSalesUserFromId(lead.csh);
    let seData = await getSalesUserFromId(lead.se);

    setEmails({
      nsh: nshData ? nshData.email : "",
      rsh: rshData ? rshData.email : "",
      csh: cshData ? cshData.email : "",
      se: seData ? seData.email : "",
    });

    setCheckMail(true);
  }
  const myEmail = localStorage.getItem("email");
  return (
    <div key={lead.id} style={{ width: "275px", padding: "2px" }}>
      <p className="author-name">Company Name:{lead.companyName}</p>
      <p>Email: {lead.personalemail}</p>
      <p>Phone: {lead.mobileno}</p>
      <p>Status: {lead.status}</p>
      <p>Address: {lead.address}</p>
      <p>City: {lead.city}</p>
      <p>State: {lead.state}</p>
      <p>Pincode: {lead.pincode}</p>
      <p>Source: {lead.source}</p>
      <p>
        Contact Person:{" "}
        {lead.contactPerson === undefined ? "" : lead.contactPerson}
      </p>
      <p>
        Department Name:{" "}
        {lead.departmentName === undefined ? "" : lead.departmentName}
      </p>

      <p>Lead Converted: {lead.converted ? "Yes" : "No"}</p>
      {!checkMail ? (
        <ColorButton onClick={getEmails}>Show Lead Refrences</ColorButton>
      ) : (
        <span>
          <p>NSH: {emails.nsh}</p>
          <p>RSH: {emails.rsh}</p>
          <p>CSH: {emails.csh}</p>
          <p>SE: {emails.se}</p>
        </span>
      )}

      <br />
    </div>
  );
};
