// import React, { useEffect, useState} from 'react'
// import { fabric } from 'fabric';

// const ImageUpload = () => {
//     const [image,setImage] = useState("")
//     var canvasRef = new fabric.Canvas("canvas");
//     const [canvas, setCanvas] = useState();
//     const [fontStyle, setFontStyle] = useState("arial");

//     useEffect(() => {
//       setCanvas(initCanvas());
//     }, []);

//     const initCanvas = () => (
//       new fabric.Canvas('canvas', {
//         height: 800,
//         width: 800,
//         fontFamily: fontStyle,

//         // backgroundColor: 'pink'
//       })
//     )
//     function addText(canvi) { 
//         console.log("hello")
//         const text = new fabric.IText('Tap and Type', { 
//         left: 50,
//         top: 100,
//         fontFamily: '',
//         fill: '#333',
//             fontSize: 50
//         });
//         canvi.add(text);
//         canvi.renderAll();
//     }
    
//     const handleFontchange = (canvi,e) => {
//       canvi.getActiveObject().setFontFamily(e)
//       canvi.renderAll()
//     }
//    const handleImage = (e) => {
//         let file = e.target.files[0];
//         setImage(URL.createObjectURL(file))
//     }

  
//     return (
//         <div className="container mt-5 pt-5">
//             {/* <div className="row align-items-center justify-content-center">
//                 {image ? (
//                 <img src={image} />

//                 ) : <input type="file" accept={[".png",".jpg",".jpeg"]} onChange={handleImage}/>
//                 }
//             </div> */}
            
//             {/* <button onClick={() => addText(canvas)}>Add Texto</button>

// <div  className="box">

//     <canvas id="canvas"></canvas>
// </div>
				
  
//   <div id="text-wrapper" style={{marginTop: "10px"}} ngshow="getText()">

//     <div id="text-controls">
// 	  <input type="color" value="" id="text-color" size={10} />
//       <label for="font-family" style={{display:"inline-block"}}>Font family:</label>
//       <select id="font-family" onChange={(e) => handleFontchange(canvas,e.target.value)}>
//         <option value="arial">Arial</option>
//         <option value="helvetica" selected>Helvetica</option>
//         <option value="myriad pro">Myriad Pro</option>
//         <option value="delicious">Delicious</option>
//         <option value="verdana">Verdana</option>
//         <option value="georgia">Georgia</option>
//         <option value="courier">Courier</option>
//         <option value="comic sans ms">Comic Sans MS</option>
//         <option value="impact">Impact</option>
//         <option value="monaco">Monaco</option>
//         <option value="optima">Optima</option>
//         <option value="hoefler text">Hoefler Text</option>
//         <option value="plaster">Plaster</option>
//         <option value="engagement">Engagement</option>
//       </select>
//       <br />
//       <label for="text-align" style={{display:"inline-block"}}>Text align:</label>
//       <select id="text-align">
//         <option value="left">Left</option>
//         <option value="center">Center</option>
//         <option value="right">Right</option>
//         <option value="justify">Justify</option>
//       </select>
//       <div>
//         <label for="text-bg-color">Background color:</label>
//         <input type="color" value="" id="text-bg-color" size={10} />
//       </div>
//       <div>
//         <label for="text-lines-bg-color">Background text color:</label>
//         <input type="color" value="" id="text-lines-bg-color" size={10} />
//       </div>
//       <div>
//         <label for="text-stroke-color">Stroke color:</label>
//         <input type="color" value="" id="text-stroke-color" />
//       </div>
//       <div>
	  

//         <label for="text-stroke-width">Stroke width:</label>
//         <input type="range" value={1} min={1} max={5} id="text-stroke-width" />
//       </div>
//       <div>
//         <label for="text-font-size">Font size:</label>
//         <input type="range" value="" min={1} max={10} step={1} id="text-font-size" />
//       </div>
//       <div>
//         <label for="text-line-height">Line height:</label>
//         <input type="range" value="" min={0} max={10} step={0.1} id="text-line-height" />
//       </div>
//     </div>
//     <div id="text-controls-additional">
//       <input type='checkbox' name='fonttype' id="text-cmd-bold" />
//         Bold
    
//       <input type='checkbox' name='fonttype' id="text-cmd-italic" />
//         Italic
     
//       <input type='checkbox' name='fonttype' id="text-cmd-underline" />
//         Underline
      
//       <input type='checkbox' name='fonttype'  id="text-cmd-linethrough" />
//         Linethrough
     
//       <input type='checkbox' name='fonttype'  id="text-cmd-overline" />
//         Overline
      
//     </div>
    
//             </div>
//             */}


//         </div>
//     )
// }
// export default ImageUpload;
import React, { Component } from 'react';
import { render } from 'react-dom';
import { Stage, Layer, Text } from 'react-konva';

class ImageUpload extends Component {
  state = {
    isDragging: false,
    x: (window.innerWidth/2)-100,
    y: (window.innerHeight/2)-100,
    text : "",
    color : "black",
    fontSize : 18,
    fontFamily : 'Helvetica',
    align:"",
    fontStyle : "",
    bold: false,
    italic : false,
    underline : false
  };

  render() {
    return (
      <div className="container mt-5 pt-5">
        
        <div className="row align-items-center justify-content-center">
          <div className="col-8 pb-2">
            <input type="text"
             className="search-box"
             placeholder="Enter text" 
             onChange={(e) => this.setState({text : e.target.value})}/>&nbsp;

             <button onClick={() => this.setState((prev) => {
               return {
                 ...prev,
               bold : !prev.bold, 
               fontStyle : prev.fontStyle + ' bold' 
               }
               })}><i className="fa fa-bold" /></button>&nbsp;

             <button onClick={() => this.setState((prev) => {
               return {
                 ...prev,
               italic : !prev.italic, 
               fontStyle : prev.fontStyle + ' italic' 
               }
               })}><i className="fa fa-italic" /></button>&nbsp;

             <button onClick={() => this.setState((prev) => {
               return {
                 ...prev,
                underline : !prev.underline
               }
               })}><i className="fa fa-underline" /></button>&nbsp;
               
               {/* <button onClick={() => this.setState({align: "left"})}><i className="fa fa-align-left" /></button>&nbsp;
               
               <button onClick={() => this.setState({align: "center"})}><i className="fa fa-align-center" /></button>&nbsp;
               
               <button onClick={() => this.setState({align: "right"})}><i className="fa fa-align-right" /></button>&nbsp; */}
             
             <select value={this.state.fontFamily} onChange={(e) => this.setState({fontFamily : e.target.value})}>
               <option value="Arial">Arial</option>
               <option value="Helvetica" selected>Helvetica</option>
               <option value="Times New Roman">Times New Roman</option>
               <option value="sans-serif">sans-serif</option>
               <option value="serif">Serif</option>
               <option value="georgia">Georgia</option>
               <option value="courier">Courier</option>
               <option value="Comic Sans, Comic Sans MS, cursive">Comic Sans MS</option>
               <option value="impact">Impact</option>
               <option value="monospace">Monospace</option>
               <option value="optima">Optima</option>
               <option value="Zapf Chancery">Zapf Chancery</option>
               <option value="Palatino">Palatino</option>
               <option value="Lucida">Lucida</option>
             </select>&nbsp;
             <select value={this.state.fontSize} onChange={(e) => this.setState({fontSize: e.target.value})}>
                <option value={12}>12</option>
                <option value={14}>14</option>
                <option value={15}>15</option>
                <option value={16}>16</option>
                <option value={18}>18</option>
                <option value={20}>20</option>
                <option value={22}>22</option>
                <option value={24}>24</option>
                <option value={26}>26</option>
                <option value={28}>28</option>
                <option value={30}>30</option>
                <option value={32}>32</option>
                <option value={34}>34</option>
                <option value={36}>36</option>
                <option value={38}>38</option>
                <option value={40}>40</option>
                <option value={42}>42</option>
             </select>&nbsp;
             <input type="color" onChange={(e) => this.setState({color : e.target.value})} />

          </div>
          <div className="col-8">
            <Stage width={(window.innerWidth/2)+85} height={400} style={{border:"1px solid black"}}>
              <Layer>
                <Text
                  text={this.state.text}
                  x={this.state.x}
                  y={this.state.y}
                  draggable
                  fill={this.state.isDragging ? 'grey' : this.state.color}
                  onDragStart={() => {
                    this.setState({
                      isDragging: true
                    });
                  }}
                  onDragEnd={e => {
                    this.setState({
                      isDragging: false,
                      x: e.target.x(),
                      y: e.target.y()
                    });
                  }}
                  fontSize={this.state.fontSize}
                  fontFamily={this.state.fontFamily}
                  fontStyle={this.state.fontStyle}
                  textDecoration={this.state.underline ? "underline" : ""}
                  // align={this.state.align}
                />
              </Layer>
            </Stage>
            </div>
          </div>
      </div>
    );
  }
}



export default ImageUpload;