import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Avatar from "@material-ui/core/Avatar";
import CustomSpinner from "../../../common/Components/CustomSpinner";

// import {setTest} from '../firebaseConfig/firebase';
import "firebase/firestore";
import { useFirebaseApp, useFirestore, useStorageTask } from "reactfire";
import swal from "sweetalert";
import "firebase/storage";

import { useLocation, useHistory } from "react-router-dom";

import Layout from "../../../common/Layout";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../../backend/uploadFileToStorage";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const EditBlogComponent = (props) => {
  const location = useLocation();
  let history = useHistory();
  let selectedBlog = location.state;
  // hooks
  const [avatar, setAvatar] = React.useState(selectedBlog.downloadURL);
  const [showAvatar, setShowAvatar] = React.useState(false);

  const [author, setAuthor] = React.useState(selectedBlog.author);
  const [title, setTitle] = React.useState(selectedBlog.title);
  const [short_desc, setShortDesc] = React.useState(selectedBlog.short_desc);
  const [long_desc, setLongDesc] = React.useState(selectedBlog.long_desc);

  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = React.useState(null);
  const [imageAsUrl, setImageAsUrl] = React.useState(allInputs);

  // feild empty err hooks
  const [showTitleErr, setShowTitleErr] = React.useState(false);
  const [showAuthorErr, setShowAuthorErr] = React.useState(false);
  const [showShortDescErr, setShowShortDescErr] = React.useState(false);
  const [showLongDescErr, setShowLongDescErr] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(false);

  const db = useFirestore();

  console.log("khem", location.state);

  useEffect(() => {}, []);

  //   var docRef = db.collection("Blogs").doc(location.state);

  // docRef.get().then((doc) => {
  //     if (doc.exists) {
  //         console.log("Document data:", doc.data().author);
  //         // document.getElementById("author").value=doc.data().author;

  //     } else {
  //         // doc.data() will be undefined in this case
  //         console.log("No such document!");
  //     }
  // }).catch((error) => {
  //     console.log("Error getting document:", error);
  // });

  // storage
  const storage = useFirebaseApp().storage();
  let downloadURL = selectedBlog.downloadURL;

  console.log("pppppppp", downloadURL);

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile(image);
    setAvatar(URL.createObjectURL(image));
    setShowAvatar(true);
  };

  async function EditBlogsFunction() {
    // if(imageAsFile !== '' ) {
    // const uploadTask = storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile);
    //  uploadTask.on('state_changed',
    //   (snapShot) => {
    //    //takes a snap shot of the process as it is happening
    //    console.log(snapShot)
    //  }, (err) => {
    //    //catches the errors
    //    console.log(err)
    //  }, async () => {

    // gets the functions from storage refences the image storage in firebase by the children
    // gets the download url then sets the image from firebase as the value for the imgUrl key:

    //  downloadURL= await storage.ref(`/images/${imageAsFile.name}`).getDownloadURL();
    //  console.log("sss",downloadURL);
    setShowSpinner(true);
    try {
      const upTask = uploadFileToStorage(
        `/blogImages/${imageAsFile.name}`,
        imageAsFile
      );

      console.log("kalyan", imageAsFile);
      downloadURL = await getDownloadURL(upTask);
      console.log("The link was clicked.", downloadURL);

      console.log(author, title, long_desc, short_desc, downloadURL);

      setShowSpinner(false);

      if (title === "") {
        setShowTitleErr(true);
      } else if (short_desc === "") {
        setShowShortDescErr(true);
      } else if (author === "") {
        setShowAuthorErr(true);
      } else if (long_desc === "") {
        setShowLongDescErr(true);
      } else {
        var washingtonRef = db.collection("Blogs").doc(selectedBlog.id);

        // Set the "capital" field of the city 'DC'
        return washingtonRef
          .update({
            author: author,
            title: title,
            short_desc: short_desc,
            long_desc: long_desc,
            downloadURL: downloadURL,
          })
          .then(() => {
            console.log("Document successfully updated!");
            swal("Success!!!", "Blog Updated", "success");
            history.push("/view-blog");
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
        //  db.collection("Blogs")
        //  .add({
        //    author: author ,
        //    title: title ,
        //    short_desc: short_desc ,
        //    long_desc: long_desc,
        //    downloadURL: downloadURL,
        //    })

        // .catch(function(error) {
        //     console.error("Error adding document: ", error);
        // });

        //  })
        // }

        // console.log(author, title, short_desc, long_desc);
      }
    } catch {
      setShowSpinner(false);
      console.log("aa1234", downloadURL);
      if (title === "") {
        setShowTitleErr(true);
      } else if (short_desc === "") {
        setShowShortDescErr(true);
      } else if (author === "") {
        setShowAuthorErr(true);
      } else if (long_desc === "") {
        setShowLongDescErr(true);
      } else {
        var washingtonRef = db.collection("Blogs").doc(selectedBlog.id);

        // Set the "capital" field of the city 'DC'
        return washingtonRef
          .update({
            author: author,
            title: title,
            short_desc: short_desc,
            long_desc: long_desc,
            downloadURL: downloadURL,
          })
          .then(() => {
            console.log("Document successfully updated!");
            swal("Success!!!", "Blog Updated", "success");
            history.push("/view-blog");
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
        //  db.collection("Blogs")
        //  .add({
        //    author: author ,
        //    title: title ,
        //    short_desc: short_desc ,
        //    long_desc: long_desc,
        //    downloadURL: downloadURL,
        //    })

        // .catch(function(error) {
        //     console.error("Error adding document: ", error);
        // });

        //  })
        // }

        // console.log(author, title, short_desc, long_desc);
      }
    }
  }
  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Edit Blogs
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                  <Avatar
                    alt="Profile"
                    src={avatar}
                    style={{ minWidth: 100, minHeight: 100, margin: "auto" }}
                  />

                  <br />
                  <TextField
                    id="title"
                    label="Blog Title"
                    variant="outlined"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setShowTitleErr(false);
                    }}
                    error={showTitleErr}
                    helperText={showTitleErr && "Please Enter title"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="sDesc"
                    label="Blog Short Description"
                    variant="outlined"
                    value={short_desc}
                    onChange={(e) => {
                      setShortDesc(e.target.value);
                      setShowShortDescErr(false);
                    }}
                    error={showShortDescErr}
                    helperText={
                      showShortDescErr && "Please Enter blog short description"
                    }
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="author"
                    label="Blog Author Name"
                    variant="outlined"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    onChange={(e) => {
                      setAuthor(e.target.value);
                      setShowAuthorErr(false);
                    }}
                    style={{ marginBottom: "10px" }}
                    error={showAuthorErr}
                    helperText={showAuthorErr && "Please Enter Author Name"}
                  />
                  <TextField
                    rows={4}
                    id="lDesc"
                    label="Blog Long Description"
                    multiline
                    variant="outlined"
                    value={long_desc}
                    onChange={(e) => {
                      setLongDesc(e.target.value);
                      setShowLongDescErr(false);
                    }}
                    style={{ marginBottom: "10px" }}
                    error={showLongDescErr}
                    helperText={
                      showLongDescErr && "Please Enter blog long description"
                    }
                  />

                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleImageAsFile}
                    style={{ marginBottom: "10px" }}
                  />
                </form>
              </CardContent>

              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={EditBlogsFunction}
                >
                  Update Blog
                </ColorButton>

                {/* <Button className="btn btn-small submit-btn"  >
            Submit
          </Button> */}
              </CardActions>
            </Card>

            {showSpinner && <CustomSpinner />}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBlogComponent;
