import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  InputLabel,
  makeStyles,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import React, { useEffect, useRef, useState } from "react";
import {
  getAssignedLeads,
  getCitiesList,
  getCityById,
  getCityId,
  getProspectDataFromLeadId,
  getClientBranches
} from "../../backend/getDataFromFirestore";
import { ColorButton } from "../../common/Components/ColorButton";
import { gstTypes, indianStates, ProspectStatuses } from "../../common/const";
import Layout from "../../common/Layout";
import { saveClientData } from "../../backend/saveDataToFirestore";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../backend/uploadFileToStorage";
import swal from "sweetalert";
import { useLocation } from "react-router";
import { Media } from "reactstrap";
import { deletePreviousFile } from "../../backend/deleteDataFromFirestore";
import {
  clientModelTypesOptions,
  billingCycleOptions,
  getCostCenterTypeOptions,
} from "./helper";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: "69ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  stepperColor: {
    color: "#18D26E",
  },
  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
  },
  products: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  category: {
    width: "29ch",
    marginRight: "2em",
    paddingBottom: "10px",
  },
  button: {
    margin: "1em",
    width: "20ch",
  },
}));

const AddProspect = () => {
  const classes = useStyles();
  const refData = useRef();
  const location = useLocation();
  const historyLeadId = location.state ? location.state.leadId : "";
  const pageType = location.state ? location.state.pageType : "";
  const [activeStep, setActiveStep] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const steps = [
    "Prospect Details & Cost Center Type",
    "Corporate Office Details",
    "Registered Office Details",
    "Billing Details",
  ];

  const formInitialState = {
    status: ProspectStatuses.NOT_SENT,
    leadId: "",
    name: "",
    logo: null,
    logoRef: "",
    modelType: "",
    superAdminEmailId: "",
    website: "",
    corporateName: "",
    corporateAddress: "",
    corporatePincode: "",
    corporateGstType: "",
    corporateGstNo: "",
    corporateState: "",
    costCenterType: "",
    registeredName: "",
    registeredAddress: "",
    registeredPincode: "",
    registeredGstType: "",
    registeredGstNo: "",
    registeredState: "",
    billingCity: "",
    billingState: "",
    billingCycle: "",
    costCenterEmail: "",
    panNo: "",
    totalBranchCount: 0,
    onboardingBranchCount: 0,
    cities: [],
    proname: "",
    proDepartment: "",
    proLocation: "",
    proLandline: "",
    proMob: "",
    proDesig: "",
    billname: "",
    billDepartment: "",
    billLocation: "",
    billLandline: "",
    billMob: "",
    billDesig: ""
  };
  const [formData, setFormData] = useState(formInitialState);
  const [leads, setLeads] = useState({});
  const [cities, setCities] = useState([]);
  const [locationOption, setLocationOption] = useState([]);
  useEffect(() => {
    getLeadData();
    getCities();
  }, []);

  async function getLeadData() {
    let userDocId = localStorage.getItem("userDocId");
    let branches = await getClientBranches(localStorage.getItem("userDocId"))
    const temp = await getAssignedLeads(userDocId);
    setLeads(temp);

    if (historyLeadId) {
      setFormDetails({ target: { name: "leadId", value: historyLeadId } });
    }
  }

  async function getCities() {
    const temp = await getCitiesList();

    setCities(temp);
  }

  async function setFormDetails(e, value) {
    if (e.target.name === "leadId") {
      let leadId = e.target.value;
      const res = await getProspectDataFromLeadId(leadId);
      if (res.isPresent) {
        let tempClient = {
          ...formInitialState,
          ...res.prospect,
        };
        setLocationOption(tempClient?.cities)
        setFormData(tempClient);
      } else {
        setFormData({
          ...formInitialState,
          [e.target.name]: e.target.value,
          name: leads[leadId].companyName,
          corporateName: leads[leadId].companyName,
          corporateAddress: leads[leadId].address,
          corporatePincode: leads[leadId].pincode,
          corporateState: leads[leadId].state,
          costCenterType: leads[leadId].costCenterType,
          registeredName: leads[leadId].companyName,
          registeredAddress: leads[leadId].address,
          registeredPincode: leads[leadId].pincode,
          registeredState: leads[leadId].state,
          billingCity: leads[leadId].city,
          billingState: leads[leadId].state,
        });
      }
    } else if (e.target.name === "logo") {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else if (e.target.name === "modelType") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        costCenterType: "",
      });
    } else if (e.target.name === "cities") {
      setFormData({
        ...formData,
        cities: value,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  }
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <form className={classes.form} noValidate autoComplete="off">
              {!historyLeadId && (
                <span>
                  <TextField
                    required
                    fullWidth
                    id="outlined-type-model"
                    select
                    label="Select Lead"
                    variant="outlined"
                    name="leadId"
                    value={formData.leadId}
                    onChange={setFormDetails}
                    style={{ marginBottom: "10px" }}
                  >
                    {Object.keys(leads).map((option) => (
                      <MenuItem key={option} value={option}>
                        {leads[option].companyName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <br />
                </span>
              )}

              <TextField
                required
                fullWidth
                id="outlined-company-name"
                label="Name"
                variant="outlined"
                name="proname"
                value={formData.proname}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-company-name"
                label="Department"
                variant="outlined"
                name="proDepartment"
                value={formData.proDepartment}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              {/* <TextField
                required
                fullWidth
                id="outlined-type-model"
                select
                label="Location"
                variant="outlined"
                name="proLocation"
                value={formData.proLocation}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              >
                {locationOption.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField> */}
              <TextField
                fullWidth
                id="outlined-company-name"
                label="Landline"
                variant="outlined"
                name="proLandline"
                value={formData.proLandline}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-company-name"
                label="Mobile"
                variant="outlined"
                name="proMob"
                value={formData.proMob}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-company-name"
                label="Designation"
                variant="outlined"
                name="proDesig"
                value={formData.proDesig}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-company-name"
                label="Company Name"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />

              <InputLabel htmlFor="company-logo">
                Upload Company Logo
              </InputLabel>
              <input
                id="company-logo"
                type="file"
                accept="image/*"
                name="logo"
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              {typeof formData.logo === "string" ? (
                <ColorButton
                  variant="contained"
                  className={classes.button}
                  onClick={() => window.open(formData.logo, "_blank")}
                  style={{ marginBottom: "10px" }}
                >
                  View
                </ColorButton>
              ) : null}

              <TextField
                required
                fullWidth
                id="outlined-type-model"
                select
                label="Type of Model"
                variant="outlined"
                name="modelType"
                value={formData.modelType}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              >
                {clientModelTypesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                fullWidth
                select
                id="outlined-cost-center-type"
                label="Cost Center Type"
                variant="outlined"
                name="costCenterType"
                value={formData.costCenterType}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              >
                {getCostCenterTypeOptions(formData.modelType).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                type="number"
                onWheel={() => document.activeElement.blur()}
                InputLabelProps={{
                  shrink: true,
                }}
                id="outlined-total-branch-count"
                label="Total Branch Count"
                variant="outlined"
                name="totalBranchCount"
                value={formData.totalBranchCount}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />

              <TextField
                fullWidth
                type="number"
                onWheel={() => document.activeElement.blur()}
                InputLabelProps={{
                  shrink: true,
                }}
                id="outlined-onboarding-branch-count"
                label="Onboarding Branch Count"
                variant="outlined"
                name="onboardingBranchCount"
                value={formData.onboardingBranchCount}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />

              <TextField
                required
                fullWidth
                type="email"
                id="outlined-billing-cost-center"
                label="Client Super Admin Email"
                variant="outlined"
                name="superAdminEmailId"
                value={formData.superAdminEmailId}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />

              <Autocomplete
                multiple
                id="outlined-branch-cities"
                options={cities}
                ref={refData}
                name="cities"
                value={formData.cities}
                getOptionLabel={(option) => option.value}
                onChange={(e, value) => {
                  e.target.name = "cities";
                  setFormDetails(e, value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="cities"
                    variant="outlined"
                    label="Branch Cities"
                    placeholder="City"
                  />
                )}
                style={{ marginBottom: "10px" }}
              />

              <TextField
                fullWidth
                id="outlined-website"
                label="Website"
                variant="outlined"
                name="website"
                value={formData.website}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <br />
            </form>
          </>
        );
      case 1:
        return (
          <>
            <form className={classes.form} noValidate autoComplete="off">
              <TextField
                required
                fullWidth
                id="outlined-corporate-office-name"
                label="Corporate Office Name"
                variant="outlined"
                name="corporateName"
                value={formData.corporateName}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-corporate-office-address"
                label="Corporate Office address"
                variant="outlined"
                name="corporateAddress"
                value={formData.corporateAddress}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-corporate-office-pincode"
                label="Corporate Office Pincode"
                variant="outlined"
                name="corporatePincode"
                value={formData.corporatePincode}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-corporate-office-gst"
                label="Corporate Office GST Number"
                variant="outlined"
                name="corporateGstNo"
                value={formData.corporateGstNo}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-corporate-office-type-gst"
                select
                label="Type of GST"
                variant="outlined"
                name="corporateGstType"
                value={formData.corporateGstType}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              >
                {gstTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                fullWidth
                id="outlined-corporate-office-state"
                label="Corporate Office State"
                select
                variant="outlined"
                name="corporateState"
                value={formData.corporateState}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              >
                {indianStates.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <br />
            </form>
          </>
        );
      case 2:
        return (
          <>
            <form className={classes.form} noValidate autoComplete="off">
              <TextField
                required
                fullWidth
                id="outlined-Registered-office-name"
                label="Registered Office Name"
                variant="outlined"
                name="registeredName"
                value={formData.registeredName}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-Registered-office-address"
                label="Registered Office address"
                variant="outlined"
                name="registeredAddress"
                value={formData.registeredAddress}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-Registered-office-pincode"
                label="Registered Office Pincode"
                variant="outlined"
                name="registeredPincode"
                value={formData.registeredPincode}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-Registered-office-gst"
                label="Registered Office GST Number"
                variant="outlined"
                name="registeredGstNo"
                value={formData.registeredGstNo}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-Registered-office-type-gst"
                select
                label="Type of GST"
                variant="outlined"
                name="registeredGstType"
                value={formData.registeredGstType}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              >
                {gstTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                fullWidth
                id="outlined-Registered-office-state"
                label="Registered Office State"
                select
                variant="outlined"
                name="registeredState"
                value={formData.registeredState}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              >
                {indianStates.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <br />
            </form>
          </>
        );
      case 3:
        return (
          <>
            <form className={classes.form} noValidate autoComplete="off">
            <TextField
                required
                fullWidth
                id="outlined-company-name"
                label="Name"
                variant="outlined"
                name="billname"
                value={formData.billname}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-company-name"
                label="Department"
                variant="outlined"
                name="billDepartment"
                value={formData.billDepartment}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              {/* <TextField
                required
                fullWidth
                id="outlined-type-model"
                select
                label="Location"
                variant="outlined"
                name="billLocation"
                value={formData.billLocation}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              >
                {locationOption.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField> */}
              <TextField
                fullWidth
                id="outlined-company-name"
                label="Landline"
                variant="outlined"
                name="billLandline"
                value={formData.billLandline}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-company-name"
                label="Mobile"
                variant="outlined"
                name="billMob"
                value={formData.billMob}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-company-name"
                label="Designation"
                variant="outlined"
                name="billDesig"
                value={formData.billDesig}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-billing-cost-center"
                label="Cost Center Email"
                variant="outlined"
                name="costCenterEmail"
                value={formData.costCenterEmail}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />

              <TextField
                required
                fullWidth
                select
                id="outlined-billing-city"
                label="City"
                variant="outlined"
                name="billingCity"
                value={formData.billingCity}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              >
                {cities.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                fullWidth
                id="outlined-billing-state"
                label="State"
                select
                variant="outlined"
                name="billingState"
                value={formData.billingState}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              >
                {indianStates.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                fullWidth
                id="outlined-billing-pan-number"
                label="PAN Number"
                variant="outlined"
                name="panNo"
                value={formData.panNo}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              />

              <TextField
                required
                fullWidth
                id="outlined-billing-cycle"
                label="Billing Cycle"
                select
                variant="outlined"
                name="billingCycle"
                value={formData.billingCycle}
                onChange={setFormDetails}
                style={{ marginBottom: "10px" }}
              >
                {billingCycleOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
              <br />
            </form>
          </>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  const handleUpload = async (file, logoRef, clientCode) => {
    if (typeof file === "string") return { url: file, ref: logoRef };

    if (logoRef) await deletePreviousFile(logoRef);

    const ref = `/client-documents/${clientCode}/${file.name}`;
    const uploadTask = uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return { url, ref };
  };

  async function saveDataToFirestore() {
    setShowSpinner(true);
    if (formData.logo) {
      const logoUrl = await handleUpload(
        formData.logo,
        formData.logoRef,
        "123"
      );
      formData["logo"] = logoUrl.url;
      formData["logoRef"] = logoUrl.ref;
    } else {
      delete formData.logo;
      delete formData.ref;
    }

    // let products = [];
    // Object.keys(selectedProducts).map((val) => {
    //   if (selectedProducts[val] === 1) products.push(val);
    // });
    const newFormData = {
      ...formData,

      nsh: formData.nsh ?? leads[formData.leadId].nsh,
      rsh: formData.rsh ?? leads[formData.leadId].rsh,
      csh: formData.csh ?? leads[formData.leadId].csh,
      se: formData.se ?? leads[formData.leadId].se,
    };
    const res = await saveClientData(newFormData);
    if (res.successfull) {
      setShowSpinner(false);
      setFormData(res.data);
      return "Saved";
    } else {
      setShowSpinner(false);
      return "Failed";
    }
  }

  async function saveDraftOrSubmit() {
    const res = await saveDataToFirestore();
    if (res === "Saved") {
      swal("Success!", "Prospect Data Saved", "success").then((val) => {
        window.location.pathname = "/view-prospects";
      });
    } else {
      swal("Error!", "Error while saving Prospect Data ", "error");
    }
  }

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    window.location.reload();
  };

  return (
    <Layout>
      <div className=" mx-4 my-5 pt-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-11">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2" align="center">
                  {historyLeadId ? "Edit Prospect" : "Add Prospect"}
                </Typography>

                <div className="stepper">
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                {/* <div> */}
                {activeStep === steps.length ? (
                  <div>
                    {showSpinner ? (
                      <div>
                        <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                          <h5>Please DO NOT REFRESH the page...</h5>
                        </span>
                        <CircularProgress
                          style={{
                            color: "#18D26E",
                          }}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ) : (
                  <>
                    {getStepContent(activeStep)}
                    <CardActions
                      style={{ justifyContent: "center", fontWeight: "bold" }}
                    >
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.backButton}
                      >
                        Back
                      </Button>
                      {activeStep < steps.length - 1 && (
                        <ColorButton
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                        >
                          Next
                        </ColorButton>
                      )}

                      {formData.status !== ProspectStatuses.APPROVED && (
                        <ColorButton
                          variant="contained"
                          color="primary"
                          onClick={saveDraftOrSubmit}
                        >
                          {"Save & Exit"}
                        </ColorButton>
                      )}
                      <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                        {errorMsg}
                      </span>
                    </CardActions>
                  </>
                )}
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddProspect;
