import { useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { makeStyles } from "@material-ui/core/styles";
import { Media } from "reactstrap";
import { ColorButton, MarkVisited } from "../../common/Components/ColorButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import {
  getRemarksOfMeeting,
} from "../../backend/getDataFromFirestore";
import { deleteMeetingWithID } from "../../backend/deleteDataFromFirestore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import swal from "sweetalert";
import { useHistory } from "react-router";
import { saveRemarkForMeeting } from "../../backend/saveDataToFirestore";
import { LocationContext } from "../../common/LocationProvider";
import { MeetingDetails } from "./MeetingDetails";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  details: {
    padding: "8px",
  },
}));

const formattedDate = (date) => {
  let dates = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  return dates + "/" + month + "/" + year;
};
// GET Current user Email
// GET Current user Email

export const MeetingCard = ({ meeting, index }) => {
  let history = useHistory();

  const { checkIn, checkOut, meetData } = useContext(LocationContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  // console.log(meeting);
  const classes = useStyles();

  // TODO
  // Compare currently logged in Doc id with meetings created by field
  // if true => show Button (Mark as Visited) else do not show any thing
  const docId = localStorage.getItem("userDocId");
  // console.log(docId);
  // if (meeting.createdBy == DocId){
  //   setButtonVisible(true)
  // }
  const date = new Date(meeting?.date?.seconds * 1000);
  // console.log(date);

  function handleClose(permission) {
    if (permission) {
      checkOut({ fromButton: true }); // After submitting Remark, Chekout User
    }
    setOpenDialog(false);
  }

  async function addRemark(meeting) {
    // console.log(meeting);
    setSelectedMeeting({ ...meeting, option: "add" });
    setOpenDialog(true);
  }

  async function showRemarks(meeting) {
    // console.log(meeting);
    setSelectedMeeting({ ...meeting, option: "show" });
    setOpenDialog(true);
  }

  async function DeleteMeeting(id) {
    const swalRes = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Meeting!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: "Deleting the Meeting. Do not Refresh",
          icon: "warning",
          buttons: false,
          dangerMode: true,
        });
        const res = await deleteMeetingWithID(id);
        if (res) {
          swal("Success!", "Meeting Deleted", "success").then((value) => {
            window.location.reload();
          });
        } else {
          swal(
            "Failed!",
            "Unable to Delete Meeting. Please try again.",
            "error"
          );
        }
      }
    });
    if (!swalRes) {
      return;
    }

    const res = await deleteMeetingWithID(id);
    if (res) {
      swal("Success!", "Meeting Deleted", "success").then((value) => {
        window.location.reload();
      });
    } else {
      swal("Failed!", "Unable to Delete Meeting. Please try again.", "error");
    }
  }

  function EditMeeting(data) {
    history.push("/add-meeting", data);
  }

  const meetDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;

  return (
    <>
      <Media
        list
        key={index}
        style={{ margin: "0px", marginTop: "0px" }}
        className="meetings-card mt-2"
      >
        <Media tag="li">
          <Media body>
            <div style={{ paddingLeft: "5px" }}>
              <Typography
                style={{ fontWeight: 600 }}
                variant="h5"
                color={grey[20]}
              >
                {meeting.name}
              </Typography>
              <Typography
                style={{
                  fontWeight: 500,
                  paddingTop: "24px",
                  paddingBottom: "8px",
                }}
                variant="h6"
                color={grey[30]}
              >
                Agenda: {meeting.agenda}
              </Typography>
              <Typography
                style={{ paddingBottom: "42px" }}
                variant={"body1"}
                color={grey[30]}
              >
                Date:{" "}
                {`${date.getDate()}/${
                  date.getMonth() + 1
                }/${date.getFullYear()}`}
              </Typography>
            </div>
            <MeetingDetails title={"mail"} show={meeting.email} />
            <MeetingDetails title={"phone"} show={"7500337411"} />
            <MeetingDetails title={"role"} show={meeting.createdByRole ?? ""} />
            <MeetingDetails
              title={"createdByMail"}
              show={meeting?.createdByEmail ?? ""}
            />

            <div style={{ paddingTop: "53px" }}>
              {meeting.createdBy === docId ? (
                <div className="view-edit">
                  <IconButton
                    aria-label="delete"
                    id={meeting.id}
                    onClick={(e) => DeleteMeeting(meeting.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    id={meeting.id}
                    onClick={(e) => EditMeeting(meeting)}
                  >
                    <CreateIcon />
                  </IconButton>

                  {date.toLocaleDateString() ===
                    new Date().toLocaleDateString() &&
                    !meetData.id &&
                    !meeting.checkIn && (
                      <MarkVisited
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                          // getLocation(meeting);
                          checkIn(history, meeting);
                        }}
                      >
                        Check In
                      </MarkVisited>
                    )}

                  {meeting.checkIn && !meeting.remarks && (
                    <MarkVisited
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        addRemark(meeting);
                      }}
                    >
                      Check Out
                    </MarkVisited>
                  )}
                </div>
              ) : null}
              <Button color={"info.main"} onClick={(e) => showRemarks(meeting)}>
                Show Remarks
              </Button>
            </div>
          </Media>
        </Media>
        <Dialog
          open={openDialog}
          // onClose={handleClose}
          fullWidth={true}
          maxWidth={"xs"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <RemarkCard meeting={selectedMeeting} handleClose={handleClose} />
        </Dialog>
      </Media>
    </>
  );
};

const RemarkCard = ({ meeting, handleClose }) => {
  const [remark, setRemark] = useState("Meeting Ended Successfully");
  const [remarks, setRemarks] = useState([]);

  useEffect(() => {
    if (meeting.option === "show") {
      getRemarksOfMeeting(meeting.id).then((data) => {
        setRemarks(data);
      });
    }
  }, []);

  function getDateAsString(date) {
    let timestamp = new Date(date.seconds * 1000);
    let dateStr =
      timestamp.getDate() +
      "/" +
      (timestamp.getMonth() + 1) +
      "/" +
      timestamp.getFullYear();

    return dateStr;
  }
  async function saveRemark() {
    const res = await saveRemarkForMeeting(meeting, remark);
    if (res.successfull) {
      handleClose(true);

      swal("Success!", "Successfully Added Remark", "success").then((val) =>
        window.location.reload()
      );
    } else {
      swal("Error!", "Failed to Save Remark", "error");
    }
  }
  return (
    <span>
      <DialogTitle id="alert-dialog-title">
        {meeting.option === "add" ? "Add Remark" : "Show Remarks"}
      </DialogTitle>
      <DialogContent>
        {meeting.option === "add" && (
          <TextField
            fullWidth={true}
            placeholder="Write your remark"
            multiline
            rows={2}
            rowsMax={4}
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        )}

        {meeting.option === "show" && remarks.length
          ? remarks.map((remark, index) => (
              <p key={index}>
                {remark.remark} - At {getDateAsString(remark.timestamp)}
              </p>
            ))
          : "No Remarks"}
      </DialogContent>
      <DialogActions>
        {meeting.option === "show" && (
          <Button
            onClick={() => {
              handleClose(false);
            }}
          >
            Close
          </Button>
        )}
        {meeting.option === "add" && (
          <ColorButton onClick={saveRemark}>Save</ColorButton>
        )}
      </DialogActions>
    </span>
  );
};
