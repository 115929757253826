import React, { useContext } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { RateContractContext } from "../../../common/RateContractProvider";
import { ColorButton } from "../../../common/Components/ColorButton";

export const DownloadAsPDF = ({ version, isPAN, products, city }) => {
  const { selectedProducts } = useContext(RateContractContext);

  const exportPDF = async () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = `${city}, ${version}`;
    const headers = [
      [
        "SKU ID",
        "Brand Name",
        "Product Name",
        "Product Category",
        "GST%",
        "MRP",
        "Nexstoc Price (Excl. GST)",
        // "PM Price",
        "Final Rate (Excl. GST)",
        "Final Rate (Incl. GST)",
      ],
    ];

    const data = products.map((col) => [
      col["SKU ID"],
      col["Brand Name"],
      col["Product Name"],
      col["Product Category"],
      col["GST%"],
      col["MRP"],
      parseFloat(isPAN ? col["maxPrice"] : col["finalPrice"]).toFixed(2),
      // col["productManagerPrice"],
      selectedProducts[city][col.id]
        ? parseFloat(selectedProducts[city][col.id]).toFixed(2)
        : " ",
      selectedProducts[city][col.id]
        ? parseFloat(
            selectedProducts[city][col.id] +
              selectedProducts[city][col.id] * +(col["GST%"] / 100)
          ).toFixed(2)
        : " ",
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`${version}_${city}.pdf`);
  };

  return (
    <div>
      <ColorButton
        variant="contained"
        style={{ margin: "10px" }}
        color="primary"
        onClick={exportPDF}
      >
        PDF Download
      </ColorButton>
    </div>
  );
};

export default DownloadAsPDF;
