import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
// import {setTest} from '../firebaseConfig/firebase';

import "firebase/firestore";
import { useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useHistory } from "react-router";
import { useState } from "react";
import { addUserManager } from "../../backend/sendRequestToServer";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  pos: {
    marginTop: 20,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  label: {
    color: "#000",
  },

  textField: {
    "& .MuiInputBase-input": {
      color: "#000",
    },
  },

  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
    marginBottom: "10px",
  },
}));

const AddUserManager = (props) => {
  // hooks
  const [location, setLocation] = React.useState("");
  const [title, setJobTitle] = React.useState("");
  const [jobDesc, setJobDesc] = React.useState("");
  const [qual, setQual] = React.useState("");

  const [showMobileErr, setMobileErr] = useState("");
  const [showEmailErr, setShowEmailErr] = useState("");

  const db = useFirestore();
  let history = useHistory();
  const [showSpinner, setShowSpinner] = React.useState(false);
  const handleReset = () => {
    document.getElementById("sales-form").reset();
  };

  // feild empty err hooks
  const [showNameErr, setShowNameErr] = React.useState(false);
  const [showPhoneErr, setShowPhoneErr] = React.useState(false);
  const [showLocationErr, setShowLocationErr] = React.useState(false);
  const [showQualErr, setShowQualErr] = React.useState(false);

  //priviledges

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: "#fff",
        color: "#000",
      },
    },
  };

  const [employee, setEmployee] = useState({
    name: "",
    email: "",
    phone: "",
    role: "User Manager",
    pageName: [
      "Add National Sales Head",
      "View National Sales Head",
      "Deleted National Sales Head",
      "Add Regional Sales Head",
      "View Regional Sales Head",
      "Deleted Regional Sales Head",
      "Add City Sales Head",
      "View City Sales Head",
      "Deleted City Sales Head",
      "Add Sales Executive",
      "View Sales Executive",
      "Deleted Sales Executive",
      "View Franchise",
    ],
  });

  const { name, email, phone, role, pageName } = employee;

  const onChange = (e) => {
    console.log(e.target.name);
    console.log(employee[e.target.value]);
    setEmployee({ ...employee, [e.target.name]: e.target.value });

    // switch (e.target.name) {
    //   case "phone":
    //     if (!validatePhone(e.target.value)) {
    //       setMobileErr("Please Enter Valid Phone number");
    //     } else {
    //       setMobileErr("");
    //     }
    //     break;

    //   case "email":
    //     if (!validateEmail(e.target.value)) {
    //       setShowEmailErr("Please Enter Valid Email");
    //     } else {
    //       setEmailErr("");
    //     }
    //     break;

    //   default:
    //     break;
    // }
  };

  const addManager = async (e) => {
    e.preventDefault();

    // if (
    //   email === "" ||
    //   name === "" ||
    //   phone === ""
    // ) {
    //   alert("Please enter all fields");
    // } else if (showEmailErr !== "" || showMobileErr !== "") {
    //   console.log("Email or phone is wrong");
    //   return false;
    if (name == "") {
      setShowNameErr("Please Enter  Name");
    } else if (email == "") {
      setShowEmailErr("Please enter Email");
    } else if (!validateEmail(email)) {
      setShowEmailErr("Enter valid Email");
    } else if (phone == "") {
      setShowPhoneErr("Please Enter Phone");
    } else if (!validatePhone(phone)) {
      setShowPhoneErr("Enter valid Mobile Number");
    } else {
      setShowSpinner(true);
      const status = await addUserManager({
        name,
        email,
        phone,
        role,
        privileges: pageName,
      });

      if (status === 200) {
        setShowSpinner(false);
        swal("Success!", "Employee Added", "success").then((value) =>
          handleReset()
        );
      } else {
        setShowSpinner(false);
        swal(
          "Failed!",
          "Employee with this email id already exists. Please Try Again!!!",
          "error"
        );
      }
    }
  };

  // email validation
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  // phone validation
  const validatePhone = (mob) => {
    const re = /^[0-9]{10}$/;
    return re.test(mob);
  };

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Add User Manager
                </Typography>
                <form
                  id="sales-form"
                  className={classes.form}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Name*"
                    name="name"
                    variant="outlined"
                    onChange={(e) => {
                      onChange(e);
                      setShowNameErr(false);
                    }}
                    error={showNameErr}
                    helperText={showNameErr}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Email*"
                    name="email"
                    variant="outlined"
                    onChange={(e) => {
                      onChange(e);
                      setShowEmailErr(false);
                    }}
                    error={showEmailErr}
                    helperText={showEmailErr}
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    variant="outlined"
                    aria-label="empty textarea"
                    label="Phone*"
                    name="phone"
                    onChange={(e) => {
                      onChange(e);
                      setShowPhoneErr(false);
                    }}
                    style={{ marginBottom: "10px" }}
                    error={showPhoneErr}
                    helperText={showPhoneErr}
                  />
                </form>
              </CardContent>
              <div>
                {showSpinner ? (
                  <div>
                    <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                      <h5>Please DO NOT REFRESH the page...</h5>
                    </span>
                    <CircularProgress
                      style={{
                        color: "#18D26E",
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={addManager}
                >
                  Add User Manager
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUserManager;
