import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

const useStyles = makeStyles({
  desDetails: {
    height: 120,
    overflow: "auto",
  },
});
const ImagesField = ({ changeFileNames, error }) => {
  const classes = useStyles();
  const [fileNames, setFileNames] = useState([]);

  return (
    <div>
      <div className={classes.desDetails}>
        Show Names of files :
        {fileNames.map((file, i) => {
          return <div key={i}>{file}</div>;
        })}
      </div>

      <input
        type="file"
        name="productImages"
        accept="image/*"
        multiple
        onChange={(e) => {
          if (e.target.files[0].size <= 5000000) {
            if (fileNames.length < 4) {
              changeFileNames(e.target.files);
              setFileNames([...fileNames, e.target.files[0].name]);
            } else {
              swal("Failed!", "Max 4 images for product allowed!!!", "error");
              return;
            }
          } else {
            swal("Failed!", "Images size should be upto 5mb only", "error");
          }
        }}
      />
      {error !== "" && <p className="text-danger">{error}</p>}
    </div>
  );
};

export default ImagesField;
