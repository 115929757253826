import React, { useEffect, useState } from "react";
import app from "firebase/app";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import Swal from "sweetalert2";

import {
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import FinalPriceField from "./FinalPriceField";
import { ColorButton } from "../../../common/Components/ColorButton";
import {
  savesellerCounter,
  saveProductsApprovalsData,
} from "../../../backend/saveDataToFirestore";
import {
  approveSellerProductsByPM,
  approveSellerProductsBySA,
  deleteProductsByID,
  editFinalProductRate,
  rejectSellerProductsByPM,
  rejectSellerProductsBySA,
  updateSellerProductsData,
} from "../../../backend/editDataInFirestore";
import swal from "sweetalert";
import {
  saveFinalProducts,
  sendProductStatusMail,
} from "../../../backend/sendRequestToServer";
import {
  getSellerProductsCounter,
  incrementSellerProductsCount,
} from "../../../backend/getCounters";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "100px",
  },
}))(TableRow);

const useStyles = makeStyles({
  container: {
    maxHeight: "75vh",
  },
  desDetails: {
    height: 100,
    overflow: "auto",
  },
});

const ProductsTable = ({
  productData,
  productLength,
  seller,
  isApproved,
  rejectedBy,
}) => {
  // console.log(productData);
  // console.log(seller);
  // console.log(isApproved);
  const role = localStorage.getItem("role");

  const rates = React.useRef(null);

  const classes = useStyles();

  const [showMsg, setShowMsg] = useState("");
  // console.log("length of products", productLength, "filenames are ", fileNames);

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    // console.log(seller);
    if (seller && seller.status === "Approved") {
      setShowMsg("Products Approved By Product Manager");
    } else if (seller && seller.status === "Rejected") {
      setShowMsg("Products Rejected By Super Admin");
    } else if (
      rejectedBy === "Product Manager" &&
      seller.status === "Pending"
    ) {
      setShowMsg("Products Rejected By Product Manager");
    }

    rates.current = new Array(productLength).fill();
    for (let i = 0; i < productLength; i++) {
      rates.current[i] = productData[i]["Seller Price"];
    }
  }, [productData]);

  useEffect(() => {
    setSearchResult(productData);
  }, [productData]);

  const changeRate = (index, val) => {
    rates.current[index] = isNaN(val) ? undefined : val;
    // console.log(rates.current);
  };

  const search = (e) => {
    setSearchValue(e.target.value);
    const data = searchLeadResult(e.target.value, "");
    setSearchResult([...data]);
  };
  const searchLeadResult = (name, status) => {
    // console.log(name, status, searchResult);
    return productData.filter((lead) => {
      if (name && status) {
        return lead["Brand Name"].toLowerCase().includes(name.toLowerCase());
      }
      if (name) {
        return (
          lead["Brand Name"].toLowerCase().includes(name.toLowerCase()) ||
          lead["Product Category"].toLowerCase().includes(name.toLowerCase()) ||
          lead["Product Sub-Category"]
            .toLowerCase()
            .includes(name.toLowerCase()) ||
          lead["Product Name"].toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const approveData = async (event) => {
    const swalRes = await swal(
      "Approve Seller Products",
      "Are you sure you want to approve Products",
      "warning",
      {
        buttons: {
          cancel: "No",
          catch: {
            text: "Yes",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    swal(
      "Updating Product Status",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    // console.log("Update Rates");

    // TODO : Update Rates of each Product set by each franchise on Firestore
    //        FranchiseProductApprovals > sellerName
    let counter = await getSellerProductsCounter(seller.city);
    let res;
    if (role === "Product Manager" || role === "Super Admin") {
      // console.log(rates.current);
      for (let i = 0; i < productLength; i++) {
        counter++;
        productData[i]["SKU ID"] =
          "S" +
          seller.city.substr(0, 3).toUpperCase() +
          ("000000" + counter).substr(-6);

        productData[i]["finalPrice"] = rates.current[i];
        productData[i]["status"] = "Approved";

        await updateSellerProductsData(seller.id, productData[i]);
      }

      await incrementSellerProductsCount(counter, seller.city);

      res = await approveSellerProductsByPM(seller.id);
      if (res.successfull) {
        sendProductStatusMail({
          sellerId: seller.id,
          productStatus: "Approved-Manager",
        });
      }
    }
    swal.close();

    if (res.successfull) {
      swal("Success!", "Approved Seller Products", "success").then(() => {
        window.location.pathname = "/seller-products-requests";
      });
    } else {
      swal("Error!", "Some Error Occured", "error");
    }
  };

  const rejectData = () => {
    Swal.fire({
      title:
        "Are you sure you want to Reject Products? Please give us a reason",
      html: '<input id="swal-input1" class="swal2-input">',
      inputLabel: "Your Reason",
      showCancelButton: true,
      focusConfirm: false,

      preConfirm: async () => {
        if (document.getElementById("swal-input1").value) {
          const reason = document.getElementById("swal-input1").value;
          await rejectAllProducts(reason);
        } else {
          Swal.showValidationMessage("First input missing");
        }
      },
    });
  };

  const rejectAllProducts = async (reason) => {
    // const swalRes = await swal(
    //   "Reject Seller Products",
    //   "Are you sure you want to Reject Products",
    //   "warning",
    //   {
    //     buttons: {
    //       cancel: "No",
    //       catch: {
    //         text: "Yes",
    //         value: true,
    //       },
    //     },
    //   }
    // );
    // if (!swalRes) {
    //   return;
    // }

    swal(
      "Updating Product Status",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    // console.log("Update Rates");

    // TODO : Update Rates of each Product set by each franchise on Firestore
    //        FranchiseProductApprovals > sellerName
    let res;
    if (role === "Product Manager" || role === "Super Admin") {
      for (let i = 0; i < productLength; i++) {
        if (role === "Super Admin") {
          productData[i]["status"] = "Rejected";
          productData[i]["rejectedBySA"] = reason;
        } else {
          productData[i]["status"] = "Rejected By PM";
          productData[i]["rejectedByPM"] = reason;
        }
        await updateSellerProductsData(seller.id, productData[i]);
      }
      res = await rejectSellerProductsByPM(seller.id);
      if (res.successfull) {
        sendProductStatusMail({
          sellerId: seller.id,
          productStatus:
            role === "Product Manager" ? "Rejected-Manager" : "Rejected-Admin",
        });
      }
    }
    swal.close();

    if (res.successfull) {
      swal("Success!", "Rejected Seller Products", "success").then(() => {
        window.location.pathname = "/seller-products-requests";
      });
    } else {
      swal("Error!", "Some Error Occured", "error");
    }
  };

  const rejectProducts = async (id) => {
    await deleteProductsByID(id, seller.id);
  };

  return (
    <>
      <div>
        <br />
        <br />
        {!seller ? (
          ""
        ) : showMsg && !isApproved ? (
          <h5>{showMsg}</h5>
        ) : role === "Product Manager" &&
          rejectedBy === "Product Manager" &&
          !isApproved ? (
          <h5>{showMsg}</h5>
        ) : (
          !isApproved &&
          role === "Product Manager" && (
            <span>
              <ColorButton
                variant="contained"
                color="primary"
                onClick={approveData}
              >
                Approve Products
              </ColorButton>
              &nbsp;
              <ColorButton
                variant="contained"
                color="primary"
                onClick={rejectData}
              >
                Reject Products
              </ColorButton>
            </span>
          )
        )}
        <div />

        {(seller.status === "Pending" || "Approved") &&
          role === "Super Admin" &&
          (rejectedBy !== "None" || productLength > 0) &&
          !isApproved && (
            <span>
              <ColorButton
                variant="contained"
                color="primary"
                onClick={approveData}
              >
                Approve Products
              </ColorButton>
              &nbsp;
              <ColorButton
                variant="contained"
                color="primary"
                onClick={rejectData}
              >
                Reject Products
              </ColorButton>
            </span>
          )}
        <div>
          <div style={{ margin: "10px 0px" }}>
            <input
              type="text"
              className="search-box col-12 col-md-8"
              placeholder="Search Product Name, Category, Sub-Category, Brand Name"
              onChange={(e) => {
                search(e);
              }}
            />
          </div>
        </div>

        <TableContainer className={classes.container} component={Paper}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                {isApproved && (
                  <TableCell style={{ width: "10em" }}>SKU Id</TableCell>
                )}

                <TableCell style={{ width: "10em" }}>Brand</TableCell>

                <TableCell style={{ width: "10em" }}>
                  Product Category
                </TableCell>

                <TableCell style={{ width: "10em" }}>
                  Product Sub-Category
                </TableCell>

                <TableCell style={{ width: "10em" }}>Product Name</TableCell>

                <TableCell style={{ width: "10em" }}>Description</TableCell>

                <TableCell style={{ width: "20em" }}>
                  Description Details
                </TableCell>

                <TableCell style={{ width: "20em" }}>Product Image</TableCell>

                <TableCell style={{ width: "10em" }}>UNIT</TableCell>

                <TableCell style={{ width: "10em" }}>GST%</TableCell>

                <TableCell style={{ width: "10em" }}>HSN CODE</TableCell>

                <TableCell style={{ width: "10em" }}>MRP</TableCell>

                <TableCell style={{ width: "10em" }}>
                  Seller Prices(Excl.GST)
                </TableCell>

                <TableCell style={{ width: "10em" }}>
                  Seller Prices(Incl.GST)
                </TableCell>

                <TableCell style={{ width: "10em" }}>
                  Final Prices(Excl. GST)
                </TableCell>

                <TableCell style={{ width: "10em" }}>
                  Final Prices(Incl. GST)
                </TableCell>

                <TableCell style={{ width: "10em" }}>Reject Product</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {productData === undefined ||
              (role === "Product Manager" &&
                rejectedBy === "Product Manager" &&
                !isApproved) ? (
                <div></div>
              ) : searchValue && searchResult.length === 0 ? (
                <div className="row pb-3 justify-content-center">
                  <TableCell style={{ marginTop: "10px" }}>
                    Search Not Found!
                  </TableCell>
                </div>
              ) : (
                searchResult.map((p, index) => {
                  return (
                    <StyledTableRow key={index}>
                      {isApproved && (
                        <TableCell component="th" scope="row">
                          {p["SKU ID"]}
                        </TableCell>
                      )}

                      <TableCell>{p["Brand Name"]}</TableCell>

                      <TableCell>{p["Product Category"]}</TableCell>

                      <TableCell>{p["Product Sub-Category"]}</TableCell>

                      <TableCell>{p["Product Name"]}</TableCell>

                      <TableCell>
                        <div className={classes.desDetails}>
                          {p["Description"]}
                        </div>
                      </TableCell>

                      <TableCell>
                        <div className={classes.desDetails}>
                          {p["Description Details"]}
                        </div>
                      </TableCell>

                      <TableCell>
                        {" "}
                        <img
                          style={{
                            display: "block",
                            maxHeight: "80px",
                            maxWidth: "100%",
                          }}
                          src={
                            p["Product Images"] && p["Product Images"][0]
                              ? p["Product Images"][0]
                              : p["Product Image"]
                          }
                        />
                      </TableCell>

                      <TableCell>{p["UNIT"]}</TableCell>

                      <TableCell>{p["GST%"]}%</TableCell>

                      <TableCell>{p["HSN CODE"]}</TableCell>

                      <TableCell>{Number(p["MRP"]).toFixed(2)}</TableCell>

                      <TableCell>
                        {Number(p["Seller Price"]).toFixed(2)}
                      </TableCell>

                      <TableCell>
                        {+p["Seller Price"] +
                          +Number(
                            p["Seller Price"] * (p["GST%"] / 100)
                          ).toFixed(2)}
                      </TableCell>

                      {
                        <FinalPriceField
                          index={index}
                          changeRate={changeRate}
                          price={p["Seller Price"]}
                          product={p}
                        />
                      }
                      {/* <TableCell>{rates.current[index]}</TableCell> */}

                      <TableCell>
                        <Button
                          onClick={() => {
                            rejectProducts(p["id"]);
                          }}
                        >
                          <DeleteSweepIcon></DeleteSweepIcon>
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ProductsTable;
