import { Checkbox } from "@material-ui/core";
import React, { useContext } from "react";
import { RateContractContext } from "../../common/RateContractProvider";

const SelectProduct = ({ checked, city, product }) => {
  return (
    <div>
      <Checkbox disabled={true} name="checkedC" checked={checked} />
    </div>
  );
};

export default SelectProduct;
