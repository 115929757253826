import React, { useEffect, useState } from "react";
import { makeStyles, TableCell, TextField } from "@material-ui/core";

const useStyles = makeStyles({
  showRedBg: {
    display: "inline-block",
    backgroundColor: "red",
    height: "100%",
  },
});

const FinalPriceField = ({ index, changeRate, price, avg, product }) => {
  const [rate, setRate] = useState(product.finalPrice ?? avg ?? 0);
  const [average, setAverage] = useState(avg);
  const classes = useStyles();
  useEffect(() => {
    setAverage(avg);
  }, [avg]);

  return (
    <>
      <TableCell>
        <div className={avg !== 0 && rate > average ? classes.showRedBg : ""}>
          <TextField
            type="Number"
            onWheel={() => document.activeElement.blur()}
            size="small"
            label="Set Final Price"
            variant="outlined"
            value={rate}
            onChange={(e) => {
              const val = e.target.valueAsNumber;
              const finalVal = isNaN(val) || val <= 0 ? 0 : val;
              if (/^\d+(\.\d{0,2})?$/.test(finalVal)) {
                changeRate(index, finalVal);
                setRate(finalVal);
              }
            }}
          />
        </div>
      </TableCell>
      <TableCell>
        {Number(+rate + +(rate * (product["GST%"] / 100))).toFixed(2)}
      </TableCell>
    </>
  );
};

export default FinalPriceField;
