import { useState } from "react";
import { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { getDivertRequest } from "../../../backend/getDataFromFirestore";
import Layout from "../../../common/Layout";
import { ColorButton } from "../../../common/Components/ColorButton";
import swal from "sweetalert";
import {
  divertFranchise,
  updateDivertRequestStatus,
  updateVendorDetails,
} from "../../../backend/saveDataToFirestore";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "100px",
  },
}))(TableRow);
const useStyles = makeStyles({
  container: {
    maxHeight: "75vh",
  },
  desDetails: {
    height: 100,
    overflow: "auto",
  },
  duplicate: {
    backgroundColor: "red",
  },
});

export const DivertRequest = ({ franchiseData }) => {
  const classes = useStyles();
  const [request, setRequest] = useState([]);
  useEffect(() => {
    getRequest();
  }, []);
  async function getRequest() {
    const data = await getDivertRequest();
    setRequest(data);
  }
  async function approveRequest(branch, index) {
    const toDelete = await swal({
      title: "Are you sure?",
      text: "You want to Divert?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!toDelete) {
      return;
    }

    swal("Diverting Franchise", "Please do not refresh this page", "info", {
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: false,
    });
    try {
      const dataa = {};
      dataa["clientId"] = branch.client_id;
      dataa["branchId"] = branch.branch_id;
      dataa["vendorId"] = branch.vendor;
      dataa["vendorDist"] = branch.vendorDist;
      dataa["vendorName"] = branch.vendorName;
      dataa["vendorType"] = branch.vendorType;
      await updateVendorDetails(dataa); //----
      await divertFranchise(branch);
      await updateDivertRequestStatus(branch.id);
      swal("Success!", "Request Approved", "success").then(
        window.location.reload()
      );
    } catch (error) {
      swal("Failed!", "Failed to Approved Request. Try Again!!!", "error");
    }
    swal.close();
  }
  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <h3 style={{ textAlign: "center" }}>Divert Franchise Request</h3>
        <TableContainer className={classes.container} component={Paper}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "10em" }}>Branch Name</TableCell>
                <TableCell style={{ width: "10em" }}>Address</TableCell>
                <TableCell style={{ width: "10em" }}>Pincode</TableCell>
                <TableCell style={{ width: "10em" }}>
                  Shipping Address
                </TableCell>
                <TableCell style={{ width: "10em" }}>State</TableCell>
                <TableCell style={{ width: "10em" }}>City</TableCell>
                <TableCell style={{ width: "10em" }}>District</TableCell>
                <TableCell style={{ width: "10em" }}>MailRoom</TableCell>
                <TableCell style={{ width: "10em" }}>Vendor Name</TableCell>
                <TableCell style={{ width: "10em" }}>Vendor Type</TableCell>
                <TableCell style={{ width: "10em" }}>New Vendor</TableCell>
                <TableCell style={{ width: "10em" }}>New Vendor Type</TableCell>
                <TableCell style={{ width: "10em" }}>Status</TableCell>
                <TableCell style={{ width: "10em" }}>Approve</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {request === undefined ? (
                <div></div>
              ) : (
                request.map((branch, index) => {
                  return (
                    <StyledTableRow
                      key={index}
                      className={branch["duplicate"] ? classes.duplicate : null}
                    >
                      <TableCell component="th" scope="row">
                        {branch.name}
                      </TableCell>
                      <TableCell>{branch.address}</TableCell>
                      <TableCell>{branch.pincode}</TableCell>
                      <TableCell>{branch.shippingAddress}</TableCell>
                      <TableCell>{branch.state}</TableCell>
                      <TableCell>{branch.city}</TableCell>
                      <TableCell>{branch.district}</TableCell>
                      <TableCell>{branch.isMailRoom ? "YES" : "NO"}</TableCell>
                      <TableCell>{branch.previousVendorName}</TableCell>
                      <TableCell>{branch.previousVendorType}</TableCell>
                      <TableCell>{branch.vendorName}</TableCell>
                      <TableCell>{branch.vendorType}</TableCell>
                      <TableCell>{branch.status}</TableCell>
                      <TableCell>
                        {
                          <ColorButton
                            variant="contained"
                            className={classes.button}
                            style={{ marginBottom: "10px" }}
                            disabled={branch.status === "Approved"}
                            onClick={() => approveRequest(branch, index)}
                          >
                            Approve
                          </ColorButton>
                        }
                      </TableCell>
                    </StyledTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
