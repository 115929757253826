import React from "react";
import { TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import DrawerWrapper from "../../../common/DrawerWrapper/DrawerWrapper";
import {
  CancelButton,
  StyledCreateButton,
} from "../../../common/Components/ColorButton";
import { OPEN_MODAL_TITLE } from "../constants.js";
import { useFirestore } from "reactfire";
import swal from "sweetalert";

const initialState = {
  CompanyName: "",
  CompanyPosition: "",
  TDesc: "",
  TestimonialAuthor: "",
  TestimonialTitle: "",
};

function TestimonialAdd({ open, onClose }) {
  const db = useFirestore();
  const [testimonial, setTestimonial] = useState(initialState);
  const [showSpinner, setShowSpinner] = React.useState(false);

  const onChange = (e) => {
    setTestimonial({ ...testimonial, [e.target.name]: e.target.value });
  };

  const handleAddTestimonial = async () => {
    setShowSpinner(true);

    const docRef = await db.collection("Testimonials").add({
      CompanyName: testimonial.CompanyName,
      CompanyPosition: testimonial.CompanyPosition,
      TDesc: testimonial.TDesc,
      TestimonialAuthor: testimonial.TestimonialAuthor,
      TestimonialTitle: testimonial.TestimonialTitle,
    });

    const washingtonRef = db.collection("Testimonials").doc(docRef.id);
    await washingtonRef.update({
      id: docRef.id,
    });
    setShowSpinner(false);
    onClose();
    swal("Success!!!", "Testimonials Added", "success");
  };

  return (
    <DrawerWrapper state={open} onClose={onClose}>
      <Typography variant="h5" className="!font-semibold !mb-7">
        {OPEN_MODAL_TITLE.ADD_TESTIMONIAL}
      </Typography>
      <form
        className="flex flex-col gap-6"
        noValidate
        autoComplete="off"
        id="addTestimonialForm"
      >
        <TextField
          label="Company Name*"
          name="CompanyName"
          onChange={onChange}
        />
        <TextField
          label="Company Position*"
          name="CompanyPosition"
          onChange={onChange}
        />
        <TextField label="TDesc" name="TDesc" onChange={onChange} />
        <TextField
          label="Testimonial Author"
          name="TestimonialAuthor"
          onChange={onChange}
        />
        <TextField
          label="Testimonial Title"
          name="TestimonialTitle"
          onChange={onChange}
        />
      </form>
      <div className="fixed bottom-0 mb-14 flex gap-1.5">
        <StyledCreateButton onClick={handleAddTestimonial}>
          Create
        </StyledCreateButton>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </div>
    </DrawerWrapper>
  );
}

export default TestimonialAdd;
