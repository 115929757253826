import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
// import {setTest} from '../firebaseConfig/firebase';
import "firebase/firestore";
import { useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useLocation, useHistory } from "react-router";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const EditTestimonial = (props) => {
  const location12 = useLocation();
  let selectedTestimonial = location12.state;
  console.log(selectedTestimonial);

  // hooks
  const [TestimonialTitle, setTestimonialTitle] = React.useState(
    selectedTestimonial.TestimonialTitle
  );
  const [TestimonialAuthor, setTestimonialAuthor] = React.useState(
    selectedTestimonial.TestimonialAuthor
  );
  const [CompanyName, setCN] = React.useState(selectedTestimonial.CompanyName);
  const [CompanyPosition, setCP] = React.useState(
    selectedTestimonial.CompanyPosition
  );

  const [showTestimonialTitleErr, setShowTestimonialTitleErr] =
    React.useState(false);
  const [showTestimonialAuthorErr, setShowTestimonialAuthorErr] =
    React.useState(false);
  const [showCompanyNameErr, setShowCNErr] = React.useState(false);
  const [showCompanyPositionErr, setShowCPErr] = React.useState(false);
  const [TDesc, setTDesc] = React.useState(selectedTestimonial.TDesc);
  const [showTDescErr, setShowTDescErr] = React.useState(false);

  const db = useFirestore();
  let history = useHistory();

  async function EditTestimonialFunction() {
    console.log("The link was clicked.");

    // db.collection("Testimonials")
    //   .doc()
    //   .set({
    //     TestimonialTitle: TestimonialTitle ,
    //     TestimonialAuthor: TestimonialAuthor ,
    //     CompanyName: CompanyName ,
    //     CompanyPosition: CompanyPosition ,
    //     })
    //   .then(() => {
    //     swal("Success!!!", "Testimonial Added", "success");
    //   });

    //     db.collection("Testimonials")
    //     .add({
    //       TestimonialTitle: TestimonialTitle ,
    //           TestimonialAuthor: TestimonialAuthor ,
    //           CompanyName: CompanyName ,
    //           CompanyPosition: CompanyPosition ,
    //       })
    //    .then(function(docRef) {
    //        console.log("Document written with ID: ", docRef.id);
    //        var washingtonRef = db.collection("Testimonials").doc(docRef.id);

    // // Set the "capital" field of the city 'DC'
    // return washingtonRef.update({
    //  id: docRef.id
    // })
    // .then(() => {
    //  console.log("Document successfully updated!");
    //  swal("Success!!!", "Testimonial Added", "success");
    // })
    // .then(() => history.push('/view-testimonial'))
    // .catch((error) => {
    //  // The document probably doesn't exist.
    //  console.error("Error updating document: ", error);
    // });

    //    })
    //    .catch(function(error) {
    //        console.error("Error adding document: ", error);
    //    });

    if (TestimonialTitle === "") {
      setShowTestimonialTitleErr(true);
    } else if (TestimonialAuthor === "") {
      setShowTestimonialAuthorErr(true);
    } else if (CompanyName === "") {
      setShowCNErr(true);
    } else if (CompanyPosition === "") {
      setShowCPErr(true);
    } else if (TDesc === "") {
      setShowTDescErr(true);
    } else {
      var washingtonRef = db
        .collection("Testimonials")
        .doc(selectedTestimonial.id);

      // Set the "capital" field of the city 'DC'
      return washingtonRef
        .update({
          TestimonialTitle: TestimonialTitle,
          TestimonialAuthor: TestimonialAuthor,
          CompanyName: CompanyName,
          CompanyPosition: CompanyPosition,
          TDesc: TDesc,
        })
        .then(() => {
          console.log("Document successfully updated!");
          swal("Success!!!", "Testimonial Updated", "success");
          history.push("/view-testimonial");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }
  }
  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <span className="mt-5"></span>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Edit Testimonials
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                  <TextField
                    id="outlined-basic"
                    label="Testimonial Title"
                    variant="outlined"
                    value={TestimonialTitle}
                    onChange={(e) => {
                      setTestimonialTitle(e.target.value);
                      setShowTestimonialTitleErr(false);
                    }}
                    error={showTestimonialTitleErr}
                    helperText={showTestimonialTitleErr && "Please enter title"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Author Name"
                    variant="outlined"
                    value={TestimonialAuthor}
                    onChange={(e) => {
                      setTestimonialAuthor(e.target.value);
                      setShowTestimonialAuthorErr(false);
                    }}
                    error={showTestimonialAuthorErr}
                    helperText={
                      showTestimonialAuthorErr && "Please enter author name"
                    }
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Company Name"
                    variant="outlined"
                    value={CompanyName}
                    onChange={(e) => {
                      setCN(e.target.value);
                      setShowCNErr(false);
                    }}
                    error={showCompanyNameErr}
                    helperText={
                      showCompanyNameErr && "Please enter company name"
                    }
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Company Position"
                    variant="outlined"
                    value={CompanyPosition}
                    onChange={(e) => {
                      setCP(e.target.value);
                      setShowCPErr(false);
                    }}
                    error={showCompanyPositionErr}
                    helperText={
                      showCompanyPositionErr && "Please enter comapny position"
                    }
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Testimonial Description*"
                    variant="outlined"
                    value={TDesc}
                    onChange={(e) => {
                      setTDesc(e.target.value);
                      setShowTDescErr(false);
                    }}
                    error={showTDescErr}
                    helperText={showTDescErr && "Please enter description"}
                    style={{ marginBottom: "10px" }}
                  />
                </form>
              </CardContent>

              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  variant="contained"
                  color="primary"
                  onClick={EditTestimonialFunction}
                >
                  Update Testimonials
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTestimonial;
