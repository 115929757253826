import { TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { RateContractContext } from "../../../common/RateContractProvider";

const SetPriceField = ({ city, product, onlyShow }) => {
  const { selectedProducts, changePrice } = useContext(RateContractContext);
  const finalPrice = product.maxPrice ?? product.finalPrice;

  return (
    <>
      {onlyShow ? (
        selectedProducts[city][product.id]
      ) : (
        <TextField
          required
          type="number"
          onWheel={() => document.activeElement.blur()}
          disabled={
            selectedProducts[city] &&
            selectedProducts[city].hasOwnProperty(product.id)
              ? false
              : true
          }
          style={{ width: "100%" }}
          id="outlined-company-name"
          variant="outlined"
          name="price"
          value={
            selectedProducts[city][product.id] !== 0
              ? selectedProducts[city][product.id]
              : ""
          }
          onChange={(e) => {
            if (e.target.valueAsNumber && e.target.valueAsNumber >= 0) {
              if (/^\d+(\.\d{0,2})?$/.test(e.target.value)) {
                const val = e.target.valueAsNumber;
                changePrice(
                  product.id,
                  val,
                  city,
                  true,
                  product.MRP,
                  product["GST%"],
                  finalPrice
                );
              }
            } else {
              changePrice(
                product.id,
                0,
                city,
                true,
                product.MRP,
                product["GST%"],
                finalPrice
              );
            }
          }}
        />
      )}
    </>
  );
};

export default SetPriceField;
