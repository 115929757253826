import React, { useEffect } from "react";
import { Avatar, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import DrawerWrapper from "../../../common/DrawerWrapper/DrawerWrapper";
import {
  CancelButton,
  StyledCreateButton,
} from "../../../common/Components/ColorButton";
import { OPEN_MODAL_TITLE } from "../constants.js";
import { useFirestore } from "reactfire";
import swal from "sweetalert";

const initialState = {
  title: "",
  author: "",
  short_desc: "",
  long_desc: "",
  downloadURL: "",
};

function EditBlog({ open, onClose, editBlogDetails }) {
  const db = useFirestore();
  const [blog, setBlog] = useState(initialState);
  const [showSpinner, setShowSpinner] = React.useState(false);

  useEffect(() => {
    if (editBlogDetails) {
      setBlog(editBlogDetails);
    }
  }, [editBlogDetails]);

  const onChange = (e) => {
    setBlog({ ...blog, [e.target.name]: e.target.value });
  };

  const handleUpdateBlog = async () => {
    if (!blog?.id) return;

    setShowSpinner(true);
    await db.collection("Blogs").doc(blog?.id).update({
      author: blog.author,
      title: blog.title,
      short_desc: blog.short_desc,
      long_desc: blog.long_desc,
      downloadURL: "",
    });
    setShowSpinner(false);

    onClose();
    swal("Success!!!", "Blog Updated", "success").then(() =>
      window.location.reload()
    );
  };

  return (
    <DrawerWrapper state={open} onClose={onClose}>
      <Typography variant="h5" className="!font-semibold !mb-7">
        {OPEN_MODAL_TITLE.EDIT_BLOG}
      </Typography>
      <form
        className="flex flex-col gap-6"
        noValidate
        autoComplete="off"
        id="UpdateBlogForm"
      >
        <TextField
          label="Blog Title*"
          name="title"
          value={blog.title}
          onChange={onChange}
        />
        <TextField
          label="Author Name*"
          name="author"
          value={blog.author}
          onChange={onChange}
        />
        <TextField
          label="Short Description*"
          name="short_desc"
          value={blog.short_desc}
          onChange={onChange}
        />
        <TextField
          label="Long Description*"
          name="long_desc"
          value={blog.long_desc}
          onChange={onChange}
        />
      </form>
      <div className="fixed bottom-0 mb-14 flex gap-1.5">
        <StyledCreateButton onClick={handleUpdateBlog}>
          Update
        </StyledCreateButton>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </div>
    </DrawerWrapper>
  );
}

export default EditBlog;
