import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import swal from "sweetalert";
import { getUnblockSubCategoryData } from "../../backend/getDataFromFirestore";
import { discontinueCoasterWiseUsers } from "../../backend/saveDataToFirestore";
import { ColorButton } from "../../common/Components/ColorButton";
import { BlockingCategories } from "../../common/const";

const useStyles = makeStyles((theme) => ({
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  category: {
    width: "29ch",
    marginRight: "2em",
    paddingBottom: "10px",
  },
}));

const DiscontinueClient = ({ clientData, handleClose }) => {
  const classes = useStyles();
  const [categoryBlockValue, setCategoryBlockValue] = useState(""); //selected values on first textfield
  const [subCategoryBlockValue, setSubCategoryBlockValue] = useState(""); //region_id, cluster_id ....
  const [subCategoryBlockName, setSubCategoryBlockName] = useState(""); //region_id, cluster_id ....
  const [blockSubCategory, setBlockSubCategory] = useState([]); //data of region, cluster, city, ....

  const getUnblockDetails = async (event) => {
    console.log(event.target);
    let data;
    setCategoryBlockValue(event.target.value);
    setSubCategoryBlockValue("");
    setSubCategoryBlockName("");
    if (event.target.value !== "Pan India") {
      data = await getUnblockSubCategoryData(clientData.id, event.target.value);
      setBlockSubCategory(data);
    } else {
      setBlockSubCategory([]);
    }
  };

  const blockedUsers = async (event) => {
    //ids of region, cluster, ...
    console.log(event.target);
    setSubCategoryBlockValue(event.target.value.id);
    setSubCategoryBlockName(event.target.value.name);
  };

  async function discontinueAdmin() {
    const swalRes = await swal(
      `Are you sure?`,
      `you want to Discontinue ${categoryBlockValue} Admin, Once deleted, you will not be able to recover this Client!`,
      "warning",
      {
        buttons: {
          cancel: "Cancel",
          catch: {
            text: "Save",
            value: true,
          },
        },
      }
    );
    if (!swalRes) {
      return;
    }
    let res = await discontinueCoasterWiseUsers(
      categoryBlockValue,
      subCategoryBlockValue,
      subCategoryBlockName,
      clientData.id
    );
    console.log(res)
    if (res.successfull) {
      // const res = await discontinueClient({ clientId: clientId });
      // if (res.message) {
      //   swal(
      //     "Success!",
      //     "Client has been Deleted Successfully",
      //     "success"
      //   ).then(() => {
      //     // window.location.reload();
      //   });
      // } else {
      //   swal("Error!", "There is something error", "error");
      // }
      await handleClose();
      await swal(
        "Success!",
        `${categoryBlockValue} Admin and below Hierarchy have been deleted.`,
        "success"
      ).then(() => {
        // window.location.reload();
      });
    } else {
      console.log("something wrong");
    }
  }

  return (
    <span>
      <DialogTitle id="alert-dialog-title">{"Discontinue Client"}</DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate autoComplete="off">
          <div>
            <TextField
              required
              id="outlined-category"
              select
              label="Type of Discontinue Category"
              variant="outlined"
              className={classes.category}
              onChange={getUnblockDetails}
            >
              {Object.keys(BlockingCategories).map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              disabled={!blockSubCategory.length}
              required
              id="outlined-sub-category"
              select
              label="Type of Sub-Category"
              variant="outlined"
              className={classes.category}
              onChange={blockedUsers}
            >
              {blockSubCategory ? (
                blockSubCategory.map((option) => (
                  <MenuItem key={option.id} value={option}>
                    {option.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value={""}>
                  No Option
                </MenuItem>
              )}
            </TextField>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose(false);
          }}
        >
          Close
        </Button>
        <ColorButton
          variant="contained"
          color="primary"
          disabled={
            categoryBlockValue !== "Pan India" && !subCategoryBlockValue
          }
          onClick={discontinueAdmin}
        >
          Discontinue
        </ColorButton>
      </DialogActions>
    </span>
  );
};
export default DiscontinueClient;
