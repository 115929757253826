import React, { useState } from "react";
import CommonTable from "../../../common/Table";
import { ActionCol } from "../../../common/Table/ActionCol";
import { Avatar } from "@material-ui/core";
import AdvancedSearch from "common/AdvancedSearch/AdvancedSearch";
import { useFirestore } from "reactfire";
import { useEffect } from "react";
import { useGetPermissions } from "context/PermissionsProvider";
import EditBlog from "../EditBlog/EditBlog";

const advancedSearchConfig = {
  searchProps: {
    searchTitle: "Author Name, Title, etc...",
    searchByOptions: ["author", "title"],
  },
};

export function ViewBlogTable() {
  const { userPermissions } = useGetPermissions();
  const db = useFirestore();
  const [blogs, setBlogs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editBlogData, setEditBlogData] = useState(null);

  const canEditBlog = userPermissions?.["Blog"]?.Edit;
  const canDeleteBlog = userPermissions?.["Blog"]?.Delete;

  const fetchBlogs = async () => {
    const response = db.collection("Blogs").orderBy("title");
    const data = await response.get();
    const blogsData = [];
    data.docs.forEach((item) => {
      blogsData.push(item.data());
    });
    setBlogs(blogsData);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleEdit = (blogData) => {
    setEditBlogData(blogData);
  };

  const handleDelete = async (blogData) => {
    if (!blogData?.id) return;
    await db.collection("Blogs").doc(blogData?.id).delete();
    window.location.reload();
  };

  const columnsData = [
    {
      headerName: "Avatar",
      field: "downloadURL",
      width: 120,
      renderCell: ({ row }) => <Avatar alt="logo" src={row.downloadURL} />,
    },
    {
      headerName: "Blog Title",
      field: "title",
      width: 180,
    },
    {
      headerName: "Author Name",
      field: "author",
      width: 180,
    },
    {
      headerName: "Description",
      field: "short_desc",
      width: 210,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 190,
      renderCell: ({ row }) => (
        <ActionCol
          data={row}
          onDelete={canDeleteBlog ? handleDelete : null}
          onEdit={canEditBlog ? handleEdit : null}
        />
      ),
    },
  ];

  if (!blogs?.length) {
    return (
      <div className="row justify-content-center">
        <p>No lead's yet!</p>
      </div>
    );
  }

  return (
    <div>
      <EditBlog
        open={!!editBlogData}
        editBlogDetails={editBlogData}
        onClose={() => setEditBlogData(null)}
      />
      <AdvancedSearch
        data={blogs}
        searchConfig={advancedSearchConfig}
        setFilteredData={setFilteredData}
      />
      <CommonTable data={filteredData} cols={columnsData} />
    </div>
  );
}
