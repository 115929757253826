// import axios from "axios";
import { getToken } from "./authenticateUser";
import {
  ADD_EMPLOYEE_FAIL,
  ADD_EMPLOYEE_SUCCESS,
  CLEAR_MESSAGE,
} from "./types";

import { MAIN_SERVER, TEAM_A_SERVER, TEAM_B_SERVER } from "../common/const.js";

// MAIN_SERVER
// TEAM_A_SERVER
// TEAM_B_SERVER

//This function sends user's email and password from form fields to server when user is being created.
export default async function sendEmailAndPassToServer(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/createUser", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res.status;
}

export async function getLocationUsingAddress(data) {
  const res = await fetch(MAIN_SERVER + "/getLocation", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data }),
  });
  const resJson = await res.json();
  return { status: res.status, ...resJson };
}

// forgot password
//  MAIN_SERVER
// TEAM_A_SERVER
// TEAM_B_SERVER
export async function reset_password(data) {
  const res = await fetch(MAIN_SERVER + "/forgotPassword", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(data),
  });
  console.log(await res.json());
  return res;
}
// Add Employee
//  MAIN_SERVER
// TEAM_A_SERVER
// TEAM_B_SERVER
export async function addEmployee(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/createemployee", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  console.log(await res.status);
  return res.status;
}

export async function addNSH(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/addnationalsaleshead", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res.status;
}

export async function addRSH(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/addregionalsaleshead", {
    // const res = await fetch("http://localhost:3001"+"/addregionalsaleshead", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res.status;
}

export async function addCSH(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/addcsh", {
    // const res = await fetch( "http://localhost:3001"+ "/addcsh", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res.status;
}

export async function addSE(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/addsalesex", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res.status;
}

export async function sendLeadsToNSH(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/SendLeadsToNSH", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res.status;
}

export async function assignLeadToRSH(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/assignleadstorsh", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      personalemail: data.personalemail,
      nsh: data.nsh,
      rsh: data.rsh,
      token: token,
    }),
  });
  console.log(await res.json());
  return res.status;
}

export async function assignLeadToCSH(data) {
  console.log(data.nsh);
  console.log(data.rsh);
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/assignleadstocsh", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      personalemail: data.personalemail,
      csh: data.csh,
      token: token,
    }),
  });
  console.log(await res.json());
  return res.status;
}

export async function assignLeadToSE(data) {
  console.log(data.nsh);
  console.log(data.rsh);
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/assignleadstose", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({
      personalemail: data.personalemail,
      se: data.se,
      token: token,
    }),
  });
  console.log(await res.json());
  return res.status;
}

// export async function assignLeadToRSH(data) {
//   var xhr = new XMLHttpRequest();
//   xhr.open("POST", "https://teambserver.herokuapp.com/assignleadstorsh", true);
//   xhr.setRequestHeader('Content-Type', 'application/json');
//   xhr.send(JSON.stringify({
//       nsh:data.nsh,
//       rsh:data.rsh
//   }))

//   xhr.onload = function() {
//     var data = JSON.parse(this.responseText);
//     console.log(data);
//   };

// }

// Edit Employee
//  MAIN_SERVER
// TEAM_A_SERVER
// TEAM_B_SERVER
export async function editEmployee(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/updateemp", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res.status;
}

export async function editNSH(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/updateemp", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res.status;
}

//  MAIN_SERVER
// TEAM_A_SERVER
// TEAM_B_SERVER
export async function deleteUser(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/deleteUser", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res;
}

export async function deleteSalesUser(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/deleteSalesUser", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res;
}

export async function addUserManager(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/addusermanager", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res.status;
}

export async function notifySeChange(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/nofitySeChange", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res.status;
}

export async function sendLeadNotification(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/sendLeadNotification", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res.status;
}

export async function saveFinalProducts(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/saveFinalProductRates", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res;
}

export async function sendProductStatusMail(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/sendProductStatusMail", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res;
}

export async function saveProspectAsApproved(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/approveProspect", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res;
}

export async function saveApprovedSellerProducts(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/saveSellerProducts", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res;
}

export async function startTimerForSeLeave(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/startTimerForSe", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res;
}

export async function discontinueClient(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/deleteClient", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res;
}

//use it later for send mail on block
export async function sendMailToFranchise() {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/blockUnblock", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...{email: 'arjunshivhare22@gmail.com'}, token: token }),
  })
}

export async function discontinueFranchisee(data) {
  const token = await getToken();
  const res = await fetch(MAIN_SERVER + "/discontinueUser", {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify({ ...data, token: token }),
  });
  console.log(await res.json());
  return res;
}