import React, { useEffect, useState } from "react";
import Layout from "../../common/Layout";

import { Media } from "reactstrap";
import { Button, IconButton, MenuItem, TextField } from "@material-ui/core";
import { ColorButton, MarkVisited } from "../../common/Components/ColorButton";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { changeLeadStatus } from "../../backend/editDataInFirestore";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { deleteLeadWithID } from "../../backend/deleteDataFromFirestore";
import swal from "sweetalert";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getSalesUserFromId } from "../../backend/getDataFromFirestore";

const ShowRequests = () => {
  let history = useHistory();

  const [leads, setLeads] = useState([]);
  const role = localStorage.getItem("role");
  const seId = localStorage.getItem("userDocId");
  if (role !== "Product Manager") {
    window.location.pathname = "/dashboard";
  }
  const leadsRef = useFirestore().collection("PM-Requests");
  const { status, data } = useFirestoreCollectionData(leadsRef);
  const [filterStatus, setFilterStatus] = useState("All");

  useEffect(() => {
    console.log("It rendered");
    if (data) {
      setLeads(data);
      setSearchResult(searchLeadResult(searchValue, filterStatus));
    }
  }, [data]);

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [selectedLead, setSelectedLead] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const leadStatus = [
    {
      value: "Approved",
      label: "Approved",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
    {
      value: "Pending",
      label: "Pending",
    },
    {
      value: "All",
      label: "All",
    },
  ];

  // const [currentLeadStatus, setLeadStatus] = useState(
  //   {
  //     status: "",
  //   }
  // );

  const handleClose = () => {
    setOpen(false);
  };

  async function handleChangeStatus(newStatus, lead) {
    const swalRes = await swal(
      "Change Status",
      "Do you want to change status for this Lead ?",
      "info",
      {
        buttons: {
          cancel: "No",
          catch: {
            text: "Yes",
            value: true,
          },
        },
      }
    );

    if (!swalRes) {
      return;
    }

    swal(
      "Changing Status",
      "Updating Status for Lead . Please do not refresh this page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );

    const res = await changeLeadStatus(newStatus, lead.id);

    swal.close();

    if (res.successfull) {
      console.log("Status changed");
      swal("Success!", "Lead Status Updated", "success");
    } else {
      console.log("Error while changing Status");
      swal("Failed!", "Failed to update Lead Status. Try Again!!!", "error");
    }
  }

  //  Search & filter in textbox
  const searchLeadResult = (name, status) => {
    return data.filter((lead) => {
      if (lead && status !== "All") {
        return (
          lead.name.toLowerCase().includes(name.toLowerCase()) &&
          lead.status.toLowerCase() === status.toLowerCase()
        );
      }
      if (name || status === "All") {
        return lead.name.toLowerCase().includes(name.toLowerCase());
      }
      if (status) {
        return lead.status.toLowerCase() === status.toLowerCase();
      }
      return true;
    });
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchLeadResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    setSearchResult(searchLeadResult(searchValue, status));
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const viewLeadDetails = (lead_details) => {
    console.log("view details button pressed", JSON.stringify(lead_details));
    setOpen(true);
    setSelectedLead(lead_details);
    // history.push("/view-lead-details", lead_details);
  };

  // const addClient = (lead_details) => {
  //   console.log("Add Client pressed", {
  //     nsh: lead_details.nsh,
  //     rsh: lead_details.rsh,
  //     csh: lead_details.csh,
  //     se: lead_details.se,
  //   });
  //   history.push("/add-client", {
  //     nsh: lead_details.nsh,
  //     rsh: lead_details.rsh,
  //     csh: lead_details.csh,
  //     se: lead_details.se,
  //     leadId: lead_details.id,
  //     approved: false,
  //   });
  // };

  // const [status, setStatus] = useState("");
  function editLead(lead) {
    history.push("/edit-lead", lead);
  }

  function deleteLead(lead) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Lead!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: "Deleting the Lead. Do not Refresh",
          icon: "warning",
          buttons: false,
          dangerMode: true,
        });

        const res = await deleteLeadWithID(lead.id);

        if (res.successfull) {
          swal("Success", "Lead Successfully Deleted!!!", "success");
        } else {
          swal(
            "Error",
            "Error Occur while Deleting. Please Try Again.",
            "error"
          );
        }
      }
    });
  }
  // table view
  const LeadsTableView = ({ leads }) => {
    return (
      <div className="row align-items-center">
        <Table key="LeadTable">
          <Thead>
            <Tr>
              <Th>SE Name</Th>
              <Th>SE Email</Th>
              <Th>Contact</Th>
              <Th>City</Th>
              <Th>Region</Th>

              <Th>References</Th>

              <Th>Status</Th>
              <Th>Requests</Th>
              {/* <Th>Details</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {leads.map((se, index) => {
              return (
                <Tr key={index}>
                  <Td>{se.name}</Td>
                  <Td>{se.email}</Td>
                  <Td>{se.contact}</Td>

                  <Td>{se.city}</Td>
                  <Td>{se.region}</Td>

                  <Td>
                    <ColorButton
                      onClick={(e) => {
                        setSelectedLead(se);
                        setOpen(true);
                      }}
                    >
                      View
                    </ColorButton>
                  </Td>
                  <Td>{se.status}</Td>

                  <Td>
                    {/* <IconButton
                      aria-label="delete"
                      onClick={(e) => deleteLead(lead)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      aria-label="edit"
                      id={lead.id}
                      onClick={(e) => editLead(lead)}
                    >
                      <CreateIcon />
                    </IconButton>*/}
                    <ColorButton
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        history.push("/client-asking-price-approval", {
                          seId: se.id,
                        });
                      }}
                    >
                      View
                    </ColorButton>
                  </Td>
                  {/* <Td>
                    <MarkVisited
                      variant="contained"
                      className={classes.button}
                      onClick={() => addClient(lead)}
                    >
                      Add Client
                    </MarkVisited>
                  </Td> */}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"View Reference Details"}
          </DialogTitle>
          <DialogContent>
            <ShowRef lead={selectedLead} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h3 style={{ textAlign: "center" }}>Requests From SE</h3>

        <div className="row pb-3 justify-content-center align-items-center">
          <div className="col-10 col-md-10">
            <input
              type="text"
              className="search-box"
              placeholder="Search Lead"
              onChange={(e) => {
                search(e);
              }}
              style={{ width: "275px", height: "40px" }}
            />
          </div>
          <div className="col col-md-2 mt-2 float-right">
            <TextField
              required
              id="outlined-indian-state"
              select
              label="Status"
              // value={employee.indianState}
              // onClick={AssignDrop()}
              value={filterStatus}
              onChange={(e) => onFilter(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                classes: {
                  root: classes.selectRoot,
                  select: classes.select,
                },
              }}
              style={{ width: "11ch", float: "right", verticalAlign: "center" }}
            >
              <MenuItem key="All" value="All">
                All
              </MenuItem>

              <MenuItem key="Pending" value="Pending">
                Pending
              </MenuItem>
              <MenuItem key="Approved" value="Approved">
                Approved
              </MenuItem>
              <MenuItem key="Rejected" value="Rejected">
                Rejected
              </MenuItem>
            </TextField>
          </div>
        </div>

        {leads.length === 0 ? (
          <div className="row justify-content-center">
            <p>No lead's yet!</p>
          </div>
        ) : (
          <>
            {leads.length > 0 && filterStatus === "" && searchValue === "" ? (
              <LeadsTableView leads={leads} />
            ) : (
              <>
                {searchResult.length > 0 ? (
                  <LeadsTableView leads={searchResult} />
                ) : (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default ShowRequests;

const ShowRef = ({ lead }) => {
  const [emails, setEmails] = useState({ nsh: "", csh: "", rsh: "", se: "" });

  useEffect(() => {
    getEmails();
  }, [lead]);

  async function getEmails() {
    if (!lead) return;
    let nshData = await getSalesUserFromId(lead.nsh);
    let rshData = await getSalesUserFromId(lead.rsh);
    let cshData = await getSalesUserFromId(lead.csh);
    let seData = await getSalesUserFromId(lead.id);

    console.log(nshData);
    setEmails({
      nsh: nshData ? nshData.email : "",
      rsh: rshData ? rshData.email : "",
      csh: cshData ? cshData.email : "",
      se: seData ? seData.email : "",
    });
  }

  return (
    <span>
      <p>NSH: {emails.nsh}</p>
      <p>RSH: {emails.rsh}</p>
      <p>CSH: {emails.csh}</p>
      <p>SE: {emails.se}</p>
    </span>
  );
};
