import app from "firebase/app";

//Sign In user and set UserUID in local Storage
const authenticateUser = async (email, password) => {
  const db = app.firestore();

  var docRef = await db.collection("Users").where("email", "==", email).get();

  if (docRef.docs.length) {
    try {
      const res = await app.auth().signInWithEmailAndPassword(email, password);
      //when user sucessfully signed in
      localStorage.setItem("userUID", res.user.uid);
      return { success: true, authState: res };
    } catch (error) {
      console.log(error); //signin Failed wrong passwrod/not exists
      return { success: false, message: error.message };
    }
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");

    //  swal("Error","User Does Not Exist.","error");
    return { success: false, message: "User Does Not Exist." };
  }
};

//  check if userUID is invalid and set user log out
export const checkUserLoggedOutState = () => {
  console.log("User is Logged-In/Logged-Out");
  return (
    localStorage.getItem("userUID") === undefined ||
    localStorage.getItem("userUID") === null
  );
};

//log out user and remove userUID & previlages
export const logOutUser = async () => {
  try {
    const res = await app.auth().signOut();
    localStorage.removeItem("userUID");
    localStorage.removeItem("previleges");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    localStorage.removeItem("userDocId");

    window.location.href = "/";
    //when user sucessfully signed in
    return { success: true };
  } catch (error) {
    console.log(error); //signin Failed wrong passwrod/not exists
    return { success: false, message: error.message };
  }
};

//Get Id token of current user for API
export const getToken = async () => {
  const idToken = await app.auth().currentUser.getIdToken();
  return idToken;
};

export default authenticateUser;
