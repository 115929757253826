import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import { getPageName } from "././../../common/const";
import {
  indianStates,
  enterpriseTypes,
  goodsTypes,
} from "../../common/const.js";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";

import clsx from "clsx";
// import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
// import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Layout from "../../common/Layout";
import "firebase/storage";
import {
  uploadFileToStorage,
  getDownloadURL,
} from "../../backend/uploadFileToStorage";
import { CircularProgress, Container } from "@material-ui/core";
import getFranchiseCount, {
  incrementFranchiseCount,
} from "../../backend/getCounters";
import saveFranchiseData from "../../backend/saveDataToFirestore";
import sendEmailAndPassToServer from "../../backend/sendRequestToServer";
import swal from "sweetalert";
import { ColorButton } from "../../common/Components/ColorButton.js";
import { editFranchiseData } from "../../backend/editDataInFirestore.js";
import {
  getCitiesList,
  getCitiesListFromState,
} from "../../backend/getDataFromFirestore.js";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

const AddFranchise = () => {
  const location = useLocation();
  let data = location.state;

  const initalFormData = {
    companyName: "",
    address: "",
    city: "",
    indianState: "",
    postalCode: "",
    panNo: "",
    gstNo: "",
    shopNo: "",
    udyamNo: "",
    udyogAadharNo: "",
    // adharNo: "",
    typeEnterprise: "",
    typeGoods: "",
    date: new Date(),
    commission: "",
    bankName: "",
    ifsc: "",
    accountNo: "",
    bankBranch: "",
    branchHead: "",
    mobileNo: "",
    personalEmail: "",
    personalAadhar: "",
    email: "",
    password: "",
    gstCerti: null,
    rentAgrement: null,
    udyamCerti: null,
    shopLicense: null,
    cancelledChq: null,
    role: "",
  };

  const [formData, setFormData] = React.useState(initalFormData);
  const [showMobileErr, setMobileErr] = useState("");
  const [showPassErr, setPassErr] = useState("");
  const [showEmailErr, setEmailErr] = useState("");
  const [showPersonalErr, setPersonalErr] = useState("");
  const [nextBtn, setNextBtn] = useState(false);
  const [cities, setCities] = useState([]);

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (data !== undefined) {
      async function cities(){
      setFormData({ ...data, date: new Date(data.date.seconds * 1000) });
      await getCities(data.indianState)
    }
    cities()
    }
  }, []);

  async function getCities(state) {
    const temp = await getCitiesListFromState(state);
    setCities(temp);
    console.log(temp)
  }

  async function getFranchiseCode() {
    const count = (await getFranchiseCount()) + 1;
    console.log("count: ", count);
    const franchiseCode =
      "F" +
      formData.indianState.substr(-3, 2).toUpperCase() +
      formData.city.substr(-4, 3).toUpperCase() +
      ("000" + count).substr(-3);
    console.log("code is", franchiseCode);
    return [count, franchiseCode];
  }

  // password visibility
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changeValues = (e, field) => {
    switch (field) {
      case "commission":
        if (e.target.value < 0) {
          e.target.value = 0;
        }
        break;
      case "mobileNo":
        if (!validatePhone(e.target.value)) {
          setMobileErr("Please Enter Valid Phone number");
        } else {
          setMobileErr("");
        }
        break;

      case "personalEmail":
        if (!validateEmail(e.target.value)) {
          setPersonalErr("Please Enter Valid Email");
        } else {
          setPersonalErr("");
        }
        break;

      case "email":
        if (!validateEmail(e.target.value)) {
          setEmailErr("Please Enter Valid Email");
        } else {
          setEmailErr("");
        }
        break;

      case "password":
        if (!validatePassword(e.target.value)) {
          setPassErr(
            "Minimum Length 8 and only alphanumeric and special characters are allowed "
          );
        } else {
          setPassErr("");
        }
        break;

      case "indianState":
        getCities(e.target.value);
        break;

      default:
        break;
    }

    setFormData({ ...formData, [`${field}`]: e.target.value });
  };

  const handleDateChange = (newDate) => {
    setFormData({ ...formData, date: newDate });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: "10px",
      boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
    },
    form: {
      margin: "15px 15px 15px 15px",
      borderRadius: "10px",
      padding: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    textField: {
      width: "69ch",
    },
    margin: {
      margin: theme.spacing(1),
    },
    stepperColor: {
      color: "#18D26E",
    },
    errMsg: {
      color: "red",
      fontWeight: "bold",
      fontSize: "small",
      textAlign: "left",
      marginBottom: "10px",
    },
  }));

  function getSteps() {
    return [
      "Franchise Details",
      "Bank Details",
      "Owner Details",
      "Upload Documents",
    ];
  }

  // email validation
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  // password validation
  const validatePassword = (pass) => {
    const re = /^[A-za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;
    return re.test(pass);
  };

  // phone validation
  const validatePhone = (mob) => {
    const re = /^[0-9]{10}$/;
    return re.test(mob);
  };

  //Upload Documents
  const handleFiles = (e, field) => {
    setFormData({ ...formData, [`${field}`]: e.target.files[0] });
  };

  //Important Function Don't Remove
  const handleUpload = async (file, franchiseCode) => {
    if (typeof file === "string") return file;
    const ref = `/franchise-documents/${franchiseCode}/${file.name}`;
    const uploadTask = uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return url;
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <form className={classes.form} noValidate autoComplete="off">
              {/* <div> */}
              <TextField
                required
                fullWidth
                id="outlined-company-name"
                label="Franchise Name"
                variant="outlined"
                value={formData.companyName}
                onChange={(e) => changeValues(e, "companyName")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-address"
                label="Address"
                variant="outlined"
                value={formData.address}
                onChange={(e) => changeValues(e, "address")}
                style={{ marginBottom: "10px" }}
              />

              <TextField
                required
                fullWidth
                id="outlined-indian-state"
                select
                label="State"
                value={formData.indianState}
                onChange={(e) => changeValues(e, "indianState")}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                {indianStates.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                disabled={formData.indianState === ""}
                required
                fullWidth
                id="outlined-city"
                select
                label="City"
                value={formData.city}
                onChange={(e) => changeValues(e, "city")}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                {cities.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                fullWidth
                id="outlined-postal-code"
                type="postal"
                inputProps={{ maxLength: 6 }}
                label="Postal Code"
                variant="outlined"
                value={formData.postalCode}
                onChange={(e) => changeValues(e, "postalCode")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-pan-number"
                label="PAN Number"
                inputProps={{ maxLength: 10 }}
                variant="outlined"
                value={formData.panNo}
                onChange={(e) => changeValues(e, "panNo")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                fullWidth
                required
                id="outlined-gst-number"
                label="GST Number"
                inputProps={{ maxLength: 15 }}
                variant="outlined"
                value={formData.gstNo}
                onChange={(e) => changeValues(e, "gstNo")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-shop-number"
                label="Shop/License Number"
                variant="outlined"
                value={formData.shopNo}
                onChange={(e) => changeValues(e, "shopNo")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-udyam-number*"
                label="Udyam Registration Number"
                inputProps={{ maxLength: 10 }}
                variant="outlined"
                value={formData.udyamNo}
                onChange={(e) => changeValues(e, "udyamNo")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-aadhar-number"
                label="Udyog Aadhar Memorandum Number"
                inputProps={{ maxLength: 12 }}
                variant="outlined"
                value={formData.udyogAadharNo}
                onChange={(e) => changeValues(e, "udyogAadharNo")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-type-enterprise"
                select
                label="Type of Enterprise"
                value={formData.typeEnterprise}
                onChange={(e) => changeValues(e, "typeEnterprise")}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                {enterpriseTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                fullWidth
                id="outlined-type-goods"
                select
                label="Type of Goods"
                value={formData.typeGoods}
                onChange={(e) => changeValues(e, "typeGoods")}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              >
                {goodsTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                style={{ marginBottom: "10px" }}
              >
                <KeyboardDatePicker
                  margin="normal"
                  fullWidth
                  id="date-picker-dialog"
                  required
                  label="Date of Establishment"
                  format="dd/MM/yyyy"
                  value={formData.date}
                  onChange={handleDateChange}
                  disableFuture={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <TextField
                fullWidth
                id="outlined-number"
                label="Commission Percentage %"
                type="number"
                onWheel={() => document.activeElement.blur()}
                variant="outlined"
                value={formData.commission}
                min={0}
                onChange={(e) => changeValues(e, "commission")}
                style={{ marginBottom: "10px" }}
              />
              <br />
              {/* </div> */}
            </form>
          </>
        );
      case 1:
        return (
          <>
            <form className={classes.form} noValidate autoComplete="off">
              {/* <div> */}
              <TextField
                required
                fullWidth
                id="outlined-bank-name"
                label="Bank Name"
                variant="outlined"
                defaultValue=""
                value={formData.bankName}
                onChange={(e) => changeValues(e, "bankName")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-ifsc-code"
                inputProps={{ maxLength: 11 }}
                label="IFSC Code"
                variant="outlined"
                value={formData.ifsc}
                onChange={(e) => changeValues(e, "ifsc")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-account-number"
                label="Account Number"
                inputProps={{ minLength: 18 }}
                variant="outlined"
                value={formData.accountNo}
                onChange={(e) => changeValues(e, "accountNo")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-branch"
                label="Branch"
                variant="outlined"
                value={formData.bankBranch}
                onChange={(e) => changeValues(e, "bankBranch")}
                style={{ marginBottom: "10px" }}
              />
              {/* </div> */}
            </form>
          </>
        );
      case 2:
        return (
          <>
            <form className={classes.form} noValidate autoComplete="off">
              {/* <div> */}
              <TextField
                required
                fullWidth
                id="outlined-branch-head"
                type="text"
                label="Branch Head"
                variant="outlined"
                value={formData.branchHead}
                onChange={(e) => changeValues(e, "branchHead")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                id="outlined-mobile-number"
                inputProps={{ maxLength: 10 }}
                label="Mobile Number"
                variant="outlined"
                value={formData.mobileNo}
                onChange={(e) => changeValues(e, "mobileNo")}
              />
              <span className={classes.errMsg}>{showMobileErr}</span>
              <TextField
                required
                fullWidth
                id="outlined-personal-email"
                type="email"
                label="Personal Email ID"
                variant="outlined"
                value={formData.personalEmail}
                onChange={(e) => changeValues(e, "personalEmail")}
              />
              <span className={classes.errMsg}>{showPersonalErr}</span>
              <TextField
                required
                fullWidth
                id="outlined-personal-aadhar"
                label="Personal Aadhar Number"
                inputProps={{ maxLength: 12 }}
                variant="outlined"
                value={formData.personalAadhar}
                onChange={(e) => changeValues(e, "personalAadhar")}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                fullWidth
                disabled={formData.id !== undefined}
                id="outlined-franchise-aadhar"
                type="email"
                label="Franchise Email ID"
                variant="outlined"
                value={formData.email}
                onChange={(e) => changeValues(e, "email")}
              />
              <span className={classes.errMsg}>{showEmailErr}</span>
              <FormControl className={clsx(classes.margin)} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password*
                </InputLabel>
                <OutlinedInput
                  type={showPassword ? "text" : "password"}
                  disabled={formData.id !== undefined}
                  id="outlined-adornment-password"
                  required
                  value={formData.password}
                  onChange={(e) => changeValues(e, "password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
              <span className={classes.errMsg}>{showPassErr}</span>
              <br />
              {/* </div> */}
            </form>
          </>
        );
      case 3:
        return (
          <Container style={{ padding: "10px" }}>
            <h5 style={{ textAlign: "center" }}>Upload Documents</h5>

            <InputLabel htmlFor="gst-certificate">
              Upload Gst certificate
            </InputLabel>
            <input
              id="gst-certificate"
              type="file"
              onChange={(e) => handleFiles(e, "gstCerti")}
              style={{ marginBottom: "10px" }}
            />
            {typeof formData.gstCerti === "string" ? (
              <ColorButton
                variant="contained"
                className={classes.button}
                onClick={() => window.open(formData.gstCerti, "_blank")}
              >
                {" "}
                View
              </ColorButton>
            ) : (
              ""
            )}

            <InputLabel htmlFor="rent-agreement">
              Upload Rent agreement
            </InputLabel>
            <input
              id="rent-agreement"
              type="file"
              onChange={(e) => handleFiles(e, "rentAgrement")}
              style={{ marginBottom: "10px" }}
            />
            {typeof formData.rentAgrement === "string" ? (
              <ColorButton
                variant="contained"
                className={classes.button}
                onClick={() => window.open(formData.rentAgrement, "_blank")}
              >
                View
              </ColorButton>
            ) : (
              ""
            )}

            <InputLabel htmlFor="shop-license">Upload Shop license</InputLabel>
            <input
              id="shop-license"
              type="file"
              onChange={(e) => handleFiles(e, "shopLicense")}
              style={{ marginBottom: "10px" }}
            />
            {typeof formData.shopLicense === "string" ? (
              <ColorButton
                variant="contained"
                className={classes.button}
                onClick={() => window.open(formData.shopLicense, "_blank")}
              >
                View
              </ColorButton>
            ) : (
              ""
            )}

            <InputLabel htmlFor="udyam-cerificate">
              Upload Udyam certificate
            </InputLabel>
            <input
              id="udyam-cerificate"
              type="file"
              onChange={(e) => handleFiles(e, "udyamCerti")}
              style={{ marginBottom: "10px" }}
            />
            {typeof formData.udyamCerti === "string" ? (
              <ColorButton
                variant="contained"
                className={classes.button}
                onClick={() => window.open(formData.udyamCerti, "_blank")}
              >
                View
              </ColorButton>
            ) : (
              ""
            )}

            <InputLabel htmlFor="canceled-chque">
              Upload Canceled cheque
            </InputLabel>
            <input
              id="canceled-chque"
              type="file"
              onChange={(e) => handleFiles(e, "cancelledChq")}
              style={{ marginBottom: "10px" }}
            />
            {typeof formData.cancelledChq === "string" ? (
              <ColorButton
                variant="contained"
                className={classes.button}
                onClick={() => window.open(formData.cancelledChq, "_blank")}
              >
                View
              </ColorButton>
            ) : (
              ""
            )}

            <br />
            <br />
          </Container>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  function checkFormCompletion() {
    if (activeStep === 0) {
      if (
        formData.companyName === "" ||
        formData.address === "" ||
        formData.city === "" ||
        formData.indianState === "" ||
        formData.postalCode === "" ||
        formData.panNo === "" ||
        formData.gstNo === "" ||
        formData.shopNo === "" ||
        formData.udyamNo === "" ||
        formData.udyogAadharNo === "" ||
        formData.typeEnterprise === "" ||
        formData.typeGoods === "" ||
        formData.commission === ""
      ) {
        setErrorMsg("Fill all the form fields.");
        return false;
      }
    }
    if (activeStep === 1) {
      if (
        formData.bankName === "" ||
        formData.ifsc === "" ||
        formData.accountNo === "" ||
        formData.bankBranch === ""
      ) {
        setErrorMsg("Fill all the form fields.");
        return false;
      }
    }
    if (activeStep === 2) {
      if (
        formData.branchHead === "" ||
        formData.mobileNo === "" ||
        formData.personalEmail === "" ||
        formData.email === "" ||
        formData.password === "" ||
        formData.personalAadhar === ""
      ) {
        setErrorMsg("Fill all the form fields.");
        return false;
      }
      if (showEmailErr !== "" || showPassErr !== "") {
        console.log("email or ppass is wrong", showEmailErr, showPassErr);
        return false;
      }
    }
    return true;
  }

  async function updateDataInFireStore() {
    setShowSpinner(true);
    const franchiseCode = formData.franchiseCode;

    const gstUrl = await handleUpload(formData.gstCerti, franchiseCode);
    const rentAgrementUrl = await handleUpload(
      formData.rentAgrement,
      franchiseCode
    );
    const udyamCertiUrl = await handleUpload(
      formData.udyamCerti,
      franchiseCode
    );
    const shopLicenseUrl = await handleUpload(
      formData.shopLicense,
      franchiseCode
    );
    const cancelledChqUrl = await handleUpload(
      formData.cancelledChq,
      franchiseCode
    );

    const newFormData = {
      ...formData,
      gstCerti: gstUrl,
      rentAgrement: rentAgrementUrl,
      udyamCerti: udyamCertiUrl,
      shopLicense: shopLicenseUrl,
      cancelledChq: cancelledChqUrl,
    };

    if ((await editFranchiseData(newFormData)).successfull) {
      setShowSpinner(false);
      swal("Success!", "Franchise Details Edited", "success").then((value) => {
        window.location.href = "/view-franchise";
      });
    } else {
      setShowSpinner(false);
      swal(
        "Failed!",
        "Unable to edit Franchise. Please try again.",
        "error"
      ).then((value) => handleBack());
    }
  }

  async function saveDataToFirestore() {
    setShowSpinner(true);

    const data = await getFranchiseCode();
    const franchiseCode = data[1],
      count = data[0];

    const gstUrl = await handleUpload(formData.gstCerti, franchiseCode);
    const rentAgrementUrl = await handleUpload(
      formData.rentAgrement,
      franchiseCode
    );
    const udyamCertiUrl = await handleUpload(
      formData.udyamCerti,
      franchiseCode
    );
    const shopLicenseUrl = await handleUpload(
      formData.shopLicense,
      franchiseCode
    );
    const cancelledChqUrl = await handleUpload(
      formData.cancelledChq,
      franchiseCode
    );

    const newFormData = {
      ...formData,
      gstCerti: gstUrl,
      rentAgrement: rentAgrementUrl,
      udyamCerti: udyamCertiUrl,
      shopLicense: shopLicenseUrl,
      cancelledChq: cancelledChqUrl,
      franchiseCode: franchiseCode,
    };

    console.log(newFormData);

    if ((await saveFranchiseData(newFormData, count)).successfull) {
      const status = await sendEmailAndPassToServer({
        email: formData.email,
        password: formData.password,
      });

      // incrementFranchiseCount(count);

      //TODO: EdgeCase : If network fails during saverfranchise but sendEmail runs than enail will be send but data will not be stored and
      //cannot send the data again as same email is now allowed.
      if (status === 200) {
        setShowSpinner(false);
        swal("Success!", "Franchise Added", "success").then((value) =>
          handleReset()
        );
      } else {
        swal(
          "Failed!",
          "Unable to send email to Franchise. This occured due to some network error or email already exist. Please fill the form again.",
          "error"
        ).then((value) => handleBack());
      }
    } else {
      setShowSpinner(false);
      swal(
        "Failed!",
        "Unable to add Franchise. Please fill the form again.",
        "error"
      ).then((value) => handleBack());
    }
  }

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const handleNext = () => {
    setErrorMsg("");
    if (activeStep === steps.length - 1) {
      if (
        formData.gstCerti === null ||
        formData.rentAgrement === null ||
        formData.udyamCerti === null ||
        formData.shopLicense === null ||
        formData.cancelledChq === null
      ) {
        setErrorMsg("Upload All the Files");
        return false;
      }

      if (formData.id === undefined) {
        saveDataToFirestore();
      } else {
        updateDataInFireStore();
      }
      console.log(formData);
    }

    // nullchecks
    if (!checkFormCompletion()) {
      return false;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setFormData(initalFormData);
    setActiveStep(0);
  };

  const [errorMsg, setErrorMsg] = React.useState("");
  const [showSpinner, setShowSpinner] = React.useState(false);

  //Dynamic pagetitle variable
  const [pageTitle, setPageTitle] = useState(undefined);
  useEffect(() => {
    if (formData.id === undefined) {
      setPageTitle("Add Franchise");
    } else {
      setPageTitle("Edit Franchise Details");
    }
  }, [formData.id]);

  return (
    <>
      <Layout />

      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>
          {formData.id ? "Edit Franchise Details" : "Add Franchise"}
        </h2>
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <div className="stepper">
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel
                        //   StepIconProps={{
                        //   classes: { iconContainer: classes.stepperColor }
                        // }}
                        >
                          {label}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                {/* <div> */}
                {activeStep === steps.length ? (
                  <div>
                    {showSpinner ? (
                      <div>
                        <span style={{ marginLeft: "50%", color: "#ff0000" }}>
                          <h5>Please DO NOT REFRESH the page...</h5>
                        </span>
                        <CircularProgress
                          style={{
                            color: "#18D26E",
                            marginLeft: "50%",
                          }}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ) : (
                  <>
                    {getStepContent(activeStep)}
                    <CardActions
                      style={{ justifyContent: "center", fontWeight: "bold" }}
                    >
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.backButton}
                      >
                        Back
                      </Button>
                      <ColorButton
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1 ? "Submit" : "Next"}
                      </ColorButton>
                      <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                        {errorMsg}
                      </span>
                    </CardActions>
                  </>
                )}
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      {/* </Layout> */}
    </>
  );
};

export default AddFranchise;
