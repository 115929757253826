import React, { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { getCities } from '../../backend/getDataFromFirestore';
import Layout from '../../common/Layout';

function ViewCity(props) {

    const [cities, setCities] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searchResult, setSearchResult] = useState([]);
  
    useEffect(() => {
        fetchCity();
    }, [])

    // fetch city table info
    const fetchCity = async () => {
        const city = await getCities();
        setCities(city)
    }

    //  Search & filter in textbox
  const searchLeadResult = (name) => {
    return cities.filter((lead) => {
      if (name) {
        return (
          lead["state"].toLowerCase().includes(name.toLowerCase()) ||
          lead["city"].toLowerCase().includes(name.toLowerCase()) ||
          lead["code"].toLowerCase().includes(name.toLowerCase())

        );
      }
      return true;
    });
  };
    // search
    const search = (e) => {
        setSearchValue(e.target.value);
        setSearchResult(searchLeadResult(e.target.value));
      };

    // table view
  const LeadsTableView = ({ leads }) => {
    return (
      <div className="row align-items-center">
        <Table key="LeadTable">
          <Thead>
            <Tr>
              <Th>State</Th>
              <Th>City </Th>
              <Th>City Code</Th>
            </Tr>
          </Thead>
          <Tbody>
            {leads.map((store, index) => {
              return (
                <Tr key={index}>
                  <Td>{store["state"]}</Td>
                  <Td>{store["city"]}</Td>
                  <Td>{store["code"]}</Td>
                  
                </Tr>
              );
            })}
          </Tbody>
        </Table>

      </div>
    );
  };
    return (
        <Layout>
            <div className="container mt-5 pt-5">
                 <h3 style={{ textAlign: "center" }}>List of Cities</h3>

                 <div className="row pb-3 justify-content-center align-items-center">
                    {/* <div className="col-10 col-md-10"> */}
                        <input
                        type="text"
                        className="search-box col-10 col-md-6"
                        placeholder="Search State,City,Code"
                        onChange={(e) => {
                            search(e);
                        }}
                        // style={{ width: "275px", height: "40px" }}
                        />
                    {/* </div> */}
                </div>
                 {cities.length === 0 ? (
                    <div className="row justify-content-center">
                        <p>No City's yet!</p>
                    </div>
                ) : (
                    <>
            {cities.length > 0 &&
            searchValue === "" ? (
              <LeadsTableView leads={cities} />
            ) : (
              <>
                {searchResult.length > 0 ? (
                  <LeadsTableView leads={searchResult} />
                ) : (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                )}
              </>
            )}
          </>
        )} 
        
            </div>
        </Layout>
    );
}

export default ViewCity;