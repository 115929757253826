import React, { createContext, useEffect, useState } from "react";

import { Geolocation } from "@capacitor/geolocation";
import { Capacitor } from "@capacitor/core";
import {
  getLocationLeftFromMeeting,
  getMeetingsDataFromId,
} from "../backend/getDataFromFirestore";
import {
  updateCheckInToMeeting,
  updateCheckOutToMeeting,
  updateLocationStatusToMeeting,
} from "../backend/editDataInFirestore";
import swal from "sweetalert";

export const LocationContext = createContext({
  checkIn: null,
  checkOut: null,
  locationData: {},
  setMeetingData: null,
  meetData: { id: "" },
});

const LocationProvider = ({ children }) => {
  const [meeting, setMeeting] = useState({ id: "" });

  const [locationLog, setLocationLog] = useState({});

  useEffect(() => {
    if (meeting.id && !meeting.hasOwnProperty("checkOut")) {
      // To stop timer if user is already check out.
      getMeetingsDataFromId(meeting.id).then((meet) => {
        if (meet.hasOwnProperty("checkOut"))
          checkOut({ alreadyCheckOut: true });
      });
      const intervalId = setInterval(() => getLocation(), 5000);
      return () => clearInterval(intervalId);
    }
  }, [locationLog, meeting]);

  function setMeetingData(meet) {
    setMeeting(meet);
  }

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const getLocation = async () => {
    console.log("Get Location Triggered", { locationLog });
    if (Capacitor.isNativePlatform()) {
      const coordinates = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });

      if (!coordinates || !coordinates.coords) {
        console.log("Permission not granted");
        return null;
      }

      console.log("Current position:", coordinates);
      const locObj = {
        lat: coordinates.coords.latitude,
        lng: coordinates.coords.longitude,
        accuracy: coordinates.coords.accuracy,
      };
      setLocationLog(locObj);
    } else {
      if (!navigator.geolocation) {
        console.log("Geolocation is not supported by your browser");
      } else {
        console.log("Locating...");
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const locObj = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              accuracy: position.coords.accuracy,
            };
            setLocationLog(locObj);
            checkLocationStatus(locObj);
          },
          (err) => {
            console.log("Unable to retrieve your location", err);
          },
          options
        );
      }
    }
  };

  function checkDistance(checkPoint, centerPoint, km) {
    console.log("checkpoitn: ", checkPoint);
    console.log("centerpoint: ", centerPoint);
    var ky = 40000 / 360;
    var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
    var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    const dist = Math.sqrt(dx * dx + dy * dy);
    return dist <= km;
  }

  const checkLocationStatus = async (location) => {
    const companyLocation = { lat: meeting.lat, lng: meeting.lng };
    const res = checkDistance(location, companyLocation, 2);
    const { successfull, locationLeft, locationReentered } =
      await getLocationLeftFromMeeting(meeting);

    if (res) {
      //User Already left the place and now he reenters
      if (locationLeft && !locationReentered) {
        updateLocationStatusToMeeting(meeting, "Reentered");
      }
    } else {
      //User Already left the place and now he leaves again
      if (locationLeft && locationReentered) {
        await swal("Warning", "You will be check out in 5 min", "warning");
        await checkOut();
      }
      //User Leaves the meeting for 1st time
      else if (!locationLeft) {
        const swalRes = await swal(
          "Do you want to check out ?",
          "You are Outside Meeting. Please make sure you reenter the meeting if you don't want to check out",
          "warning",
          {
            buttons: {
              cancel: "No",
              catch: {
                text: "Yes",
                value: true,
              },
            },
          }
        );
        if (swalRes) {
          checkOut();
          return;
        }
        updateLocationStatusToMeeting(meeting, "Left");
      }
    }
  };

  async function checkOut({
    fromButton = false,
    alreadyCheckOut = false,
  } = {}) {
    if (fromButton) {
      const { successfull, locationLeft, locationReentered } =
        await getLocationLeftFromMeeting(meeting);
      if (!successfull) return;
      if (locationLeft && !locationReentered) {
        swal("Warning", "To Check out please re-enter meeting", "warning");
        return;
      }
    }
    if (alreadyCheckOut) {
      setMeeting({});
      return;
    }
    const res = await updateCheckOutToMeeting(meeting);
    if (res.successfull) {
      setMeeting({});
      window.location.pathname = "/view-meetings";
    } else {
      swal("Error!", "Can't Update Check Out Status of Meeting", "error");
    }
  }

  async function successCallback(position, history, meeting) {
    // if (position.coords.accuracy > 1000) {
    //   swal(
    //     "Error", 
    //     "Unable to retrieve your accurate location. Please use your mobile.",
    //     "error"
    //   );
    //   return;
    // }
    const myLocation = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    console.log("meeting: ", meeting);
    const companyLocation = { lat: meeting.lat, lng: meeting.lng };

    const res = checkDistance(myLocation, companyLocation, 2);
    console.log("Res: ", res);
    if (res) {
      const res = await updateCheckInToMeeting(meeting);
      if (res.successfull) {
        setMeeting(meeting);
        await swal("Success", "Check In Successfull", "success");
        history.push("/location-tracking", { meeting: meeting });
      }
    } else {
      swal("Error", "You are too far away from Meeting Location", "error");
    }
  }

  const checkIn = async (history, meeting) => {
    if (Capacitor.isNativePlatform()) {
      const coordinates = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });

      if (!coordinates || !coordinates.coords) {
        console.log("Permission not granted");
        return null;
      }

      console.log("Current position:", coordinates);

      successCallback(coordinates, history, meeting);
    } else {
      if (!navigator.geolocation) {
        console.log("Geolocation is not supported by your browser");
      } else {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            successCallback(position, history, meeting);
          },
          (err) => {
            console.log("Unable to retrieve your location", err);
            swal("Error", "Please accept location access request.", "error");
          },
          options
        );
      }
    }
  };

  return (
    <LocationContext.Provider
      value={{
        checkIn,
        checkOut,
        locationData: locationLog,
        setMeetingData,
        meetData: meeting,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
export default LocationProvider;
