import React from "react";
import BlogComponent from "./Components/BlogComponent";
import "firebase/analytics";
// import { useAnalytics } from "reactfire";
import Layout from "../../common/Layout";
import ViewBlogComponent from "./Components/ViewBlogComponent";

const Home = () => {
  // const analytics = useAnalytics();

  return (
    <Layout>
      <BlogComponent />
    </Layout>
  );
};

export default Home;
