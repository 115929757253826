import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  withStyles,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  getAllUsersforMarketingLeads,
  getLeadsForMarketing,
  getSalesUserFromId,
} from "../../backend/getDataFromFirestore";
import Layout from "../../common/Layout";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { deleteLeadWithID } from "../../backend/deleteDataFromFirestore";
import { assignLeadtoUser } from "../../backend/saveDataToFirestore";
import { ColorButton } from "../../common/Components/ColorButton";

function GetAllLeads() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("All");
  const [filterValue, setFilterValue] = useState("All");
  const [leadData, setLeadData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedLead, setSelectedLead] = useState({});
  const [nshData, setNshData] = useState([]);
  const [rshData, setRshData] = useState([]);
  const [cshData, setCshData] = useState([]);
  const [seData, setSeData] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const searchLeadResult = (name, status, sort) => {
    return leadData.filter((lead) => {
      // if all conditions
      if (name !== "" && status !== "All" && sort !== "All") {
        return (
          (lead.companyName.toLowerCase().includes(name.toLowerCase()) ||
            lead.city.toLowerCase().includes(name.toLowerCase())) &&
          lead.status.toLowerCase().includes(status.toLowerCase()) &&
          lead.source.toLowerCase().includes(sort.toLowerCase())
        );
      }
      // if only name and status
      if (name !== "" && status !== "All" && sort == "All") {
        return (
          (lead.companyName.toLowerCase().includes(name.toLowerCase()) ||
            lead.city.toLowerCase().includes(name.toLowerCase())) &&
          lead.status.toLowerCase().includes(status.toLowerCase())
        );
      }
      // if only name and sort
      if (name !== "" && status == "All" && sort !== "All") {
        return (
          (lead.companyName.toLowerCase().includes(name.toLowerCase()) ||
            lead.city.toLowerCase().includes(name.toLowerCase())) &&
          lead.source.toLowerCase().includes(sort.toLowerCase())
        );
      }
      // in sort amd status
      if (name == "" && status !== "All" && sort !== "All") {
        return (
          lead.status.toLowerCase().includes(status.toLowerCase()) &&
          lead.source.toLowerCase().includes(sort.toLowerCase())
        );
      }
      // if only name
      if (name !== "" && status == "All" && sort == "All") {
        return (
          lead.companyName.toLowerCase().includes(name.toLowerCase()) ||
          lead.city.toLowerCase().includes(name.toLowerCase())
        );
      }
      // if only status
      if (name == "" && status !== "All" && sort == "All") {
        return lead.status.toLowerCase().includes(status.toLowerCase());
      }
      // if only sort
      if (name == "" && status == "All" && sort !== "All") {
        return lead.source.toLowerCase().includes(sort.toLowerCase());
      }
      return true;
    });
  };

  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchLeadResult(e.target.value, filterValue, sortValue));
  };

  // filter
  const onFilter = (status) => {
    console.log(status);
    setFilterValue(status);
    setSearchResult(searchLeadResult(searchValue, status, sortValue));
  };

  const onSort = (sort) => {
    setSortValue(sort);
    setSearchResult(searchLeadResult(searchValue, filterValue, sort));
  };

  function editLead(lead) {
    history.push("/edit-lead", lead);
  }

  function deleteLead(lead) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Lead!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: "Deleting the Lead. Do not Refresh",
          icon: "warning",
          buttons: false,
          dangerMode: true,
        });

        const res = await deleteLeadWithID(lead.id);

        if (res.successfull) {
          swal("Success", "Lead Successfully Deleted!!!", "success");
        } else {
          swal(
            "Error",
            "Error Occur while Deleting. Please Try Again.",
            "error"
          );
        }
      }
    });
  }

  async function assignUsers(user,leadId){
    let res = await assignLeadtoUser(user,leadId)
    return res
  }

  const assignLead = async (user, leadId) => {
    const swalRes = await swal(
      "Confirm User",
      "Do you want to assign this User for this Lead ?",
      "info",
      {
        buttons: {
          cancel: "No",
          catch: {
            text: "Yes",
            value: true,
          },
        },
      }
    );

    if (!swalRes) {
      return;
    }

    swal(
      "Assigning Lead",
      "Updating User for Lead . Please do not refresh this page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    
    let res = await assignUsers(user, leadId);
    console.log(res, "function api res")
    swal.close();
    if (!res.successfull) swal("Failed!", "Failed to assign Lead. Try Again!!!", "error");

    if(res.successfull) swal("Success!", "Lead Assigned", "success");
  };
  console.log(selectedUser, "select123");
  // table view
  const LeadsTableView = ({ leads }) => {
    return (
      <div className="row align-items-center">
        <Table key="LeadTable">
          <Thead>
            <Tr>
              <Th>Company Name</Th>
              <Th>Email</Th>
              <Th>Assigned To</Th>
              <Th>City</Th>
              <Th>Assign to</Th>
              <Th>Action</Th>
              <Th>Source</Th>
              <Th>Source Name</Th>
              <Th>Details</Th>
            </Tr>
          </Thead>
          <Tbody>
            {leads.map((lead, idx) => {
              var uEmail
              const assignedEmail = () => {
                if (lead.se) {
                  return userData.filter((item) => {
                    if (item.id === lead.se) uEmail = item.email;
                  });
                } else if (lead.csh) {
                  return userData.filter((item) => {
                    if (item.id === lead.csh) uEmail = item.email;
                  });
                } else if (lead.rsh) {
                  return userData.filter((item) => {
                    if (item.id === lead.rsh) uEmail = item.email;
                  });
                } else if (lead.nsh)
                  return userData.filter((item) => {
                    if (item.id === lead.nsh) uEmail = item.email;
                  });
              };
              assignedEmail()
              return (
                <Tr key={idx}>
                  <Td>{lead.companyName}</Td>
                  <Td>{lead.personalemail}</Td>
                  <Td>{uEmail ? uEmail : ""}</Td>
                  <Td>{lead.city}</Td>
                  <Td>
                    <TextField
                      required
                      className="table-select"
                      id="outlined-indian-state"
                      select
                      onChange={(e) => {
                        assignLead(e.target.value, lead.id);
                      }}
                      variant="outlined"
                    >
                      <MenuItem>(designation)email(name)</MenuItem>
                      {userData &&
                        userData.map((user, idx) => {
                          return (
                            <MenuItem key={idx} value={user}>
                              {`(${user.designation})${user.email}(${user.name})`}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Td>
                  <Td>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => deleteLead(lead)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      aria-label="edit"
                      id={lead.id}
                      onClick={(e) => editLead(lead)}
                    >
                      <CreateIcon />
                    </IconButton>
                  </Td>
                  <Td>{lead.source}</Td>
                  <Td>{lead.sourceName ? lead.sourceName : ""}</Td>
                  <Td>
                    <ColorButton
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        setSelectedLead(lead);
                        handleOpen();
                      }}
                    >
                      {" "}
                      View
                    </ColorButton>
                  </Td>
                </Tr>
              );
            })}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"View Lead Details"}
              </DialogTitle>
              <DialogContent>
                <LeadCard lead={selectedLead} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </Tbody>
        </Table>
      </div>
    );
  };

  async function getAllLead() {
    let res = await getLeadsForMarketing();
    // console.log(res)
    setLeadData(res);
    setSearchResult(searchLeadResult(searchValue, filterValue, sortValue));
  }

  async function getUsers() {
    let res = await getAllUsersforMarketingLeads();
    setUserData(res.sort((a, b) => a.designation.localeCompare(b.designation)));
  }
  console.log(userData, "userData");

  const sortUsers = () => {
    setNshData(
      userData.filter((item) => {
        return item.designation.toLowerCase().includes("national sales head");
      })
    );
    setRshData(
      userData.filter((item) => {
        return item.designation.toLowerCase().includes("regional sales head");
      })
    );
    setCshData(
      userData.filter((item) => {
        return item.designation.toLowerCase().includes("city sales head");
      })
    );
    setSeData(
      userData.filter((item) => {
        return item.designation.toLowerCase().includes("sales executive");
      })
    );
  };

  useEffect(() => {
    getAllLead();
    getUsers();
  }, []);

  useEffect(() => {
    sortUsers();
  }, [userData]);

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>View Leads</h2>

        <div className="row pb-3 justify-content-center align-items-center">
          <div className="col-12 col-md-8">
            <input
              type="text"
              className="search-box"
              placeholder="Search Lead"
              onChange={(e) => {
                search(e);
              }}
              style={{ width: "275px", height: "40px" }}
            />
          </div>
          <div className="col col-md-4 mt-2 float-right">
            <TextField
              required
              id="outlined-indian-state"
              select
              label="Status"
              value={filterValue}
              onChange={(e) => onFilter(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "11ch", float: "right", verticalAlign: "center" }}
            >
              <MenuItem key="All" value="All">
                All
              </MenuItem>
              <MenuItem key="Warm" value="Warm">
                Warm
              </MenuItem>
              <MenuItem key="Cold" value="Cold">
                Cold
              </MenuItem>
              <MenuItem key="Lost" value="Lost">
                Lost
              </MenuItem>
              <MenuItem key="Hot" value="Hot">
                Hot
              </MenuItem>
            </TextField>
            <TextField
              required
              id="outlined-indian-state"
              select
              label="Sort By"
              value={sortValue}
              onChange={(e) => onSort(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "11ch", float: "right", verticalAlign: "center" }}
            >
              <MenuItem key="All" value="All">
                All
              </MenuItem>

              <MenuItem key="National Sales Head" value="National Sales Head">
                National Sales Head
              </MenuItem>
              <MenuItem key="Regional Sales Head" value="Regional Sales Head">
                Regional Sales Head
              </MenuItem>
              <MenuItem key="Sales Executive" value="Sales Executive">
                Sales Executive
              </MenuItem>
              <MenuItem key="City Sales Head" value="City Sales Head">
                City Sales Head
              </MenuItem>
              <MenuItem key="Franchise" value="Franchise">
                Franchise
              </MenuItem>
              <MenuItem key="Franchise" value="Franchise">
                Client
              </MenuItem>
              <MenuItem key="Instagram" value="Instagram">
                Instagram
              </MenuItem>
              <MenuItem key="facebook" value="facebook">
                facebook
              </MenuItem>
              <MenuItem key="linkedin" value="linkedin">
                linkedin
              </MenuItem>
            </TextField>
          </div>
        </div>

        {leadData.length === 0 ? (
          <div className="row justify-content-center">
            <p>No lead's yet!</p>
          </div>
        ) : (
          <>
            {leadData.length > 0 &&
            filterValue === "All" &&
            searchValue === "" &&
            sortValue === "All" ? (
              <LeadsTableView leads={leadData} />
            ) : (
              <>
                {searchResult.length > 0 ? (
                  <LeadsTableView leads={searchResult} />
                ) : (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default GetAllLeads;

const LeadCard = ({ lead }) => {
  const [emails, setEmails] = useState({
    nsh: "",
    rsh: "",
    csh: "",
    se: "",
  });

  const [checkMail, setCheckMail] = useState(false);

  async function getEmails() {
    let nshData = await getSalesUserFromId(lead.nsh);
    let rshData = await getSalesUserFromId(lead.rsh);
    let cshData = await getSalesUserFromId(lead.csh);
    let seData = await getSalesUserFromId(lead.se);

    setEmails({
      nsh: nshData ? nshData.email : "",
      rsh: rshData ? rshData.email : "",
      csh: cshData ? cshData.email : "",
      se: seData ? seData.email : "",
    });

    setCheckMail(true);
  }
  const myEmail = localStorage.getItem("email");
  return (
    <div key={lead.id} style={{ width: "275px", padding: "2px" }}>
      <p className="author-name">Company Name:{lead.companyName}</p>
      <p>Email: {lead.personalemail}</p>
      <p>Phone: {lead.mobileno}</p>
      <p>Status: {lead.status}</p>
      <p>Address: {lead.address}</p>
      <p>City: {lead.city}</p>
      <p>State: {lead.state}</p>
      <p>Pincode: {lead.pincode}</p>
      <p>Source: {lead.source}</p>
      <p>
        Contact Person:{" "}
        {lead.contactPerson === undefined ? "" : lead.contactPerson}
      </p>
      <p>
        Department Name:{" "}
        {lead.departmentName === undefined ? "" : lead.departmentName}
      </p>

      <p>Lead Converted: {lead.converted ? "Yes" : "No"}</p>
      {!checkMail ? (
        <ColorButton onClick={getEmails}>Show Lead Refrences</ColorButton>
      ) : (
        <span>
          <p>NSH: {emails.nsh}</p>
          <p>RSH: {emails.rsh}</p>
          <p>CSH: {emails.csh}</p>
          <p>SE: {emails.se}</p>
        </span>
      )}

      <br />
    </div>
  );
};
