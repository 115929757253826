import React, { useEffect } from "react";
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import swal from "sweetalert";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import {
  CancelButton,
  StyledCreateButton,
} from "common/Components/ColorButton";
import { AddPermissions } from "Pages/AddProfile/AddPermissions";
import { OPEN_MODAL_TITLE } from "PagesV2/Marketing/constants";
import DrawerWrapper from "common/DrawerWrapper/DrawerWrapper";
import { AssignedPrivilege } from "Pages/AddProfile/AssignedPrivilege";
import { updateProfileData } from "backend/saveDataToFirestore";

const useStyles = makeStyles((theme) => ({
  privilegeButton: {
    width: "100%",
    marginTop: 24,
    marginBottom: 48,
    height: 42,
    textTransform: "capitalize",
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main,
  },
}));

const EditProfile = ({ show, onClose, profileDetails }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [profileId, setProfileId] = useState(null);
  const [profileName, setProfileName] = useState(null);
  const [permissions, setPermissions] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (profileDetails) {
      setProfileId(profileDetails?.id);
      setProfileName(profileDetails?.name);
      setPermissions(profileDetails?.permissions);
    }
  }, [profileDetails]);

  const handleReset = () => {
    setProfileName(null);
    setPermissions({});
    setProfileId(null);
  };

  const handleModal = () => {
    setOpen((state) => !state);
  };

  const updateNewProfile = async () => {
    if (!profileName || !profileId) return;

    setShowSpinner(true);

    const result = await updateProfileData({
      name: profileName,
      permissions,
      id: profileId,
    });

    if (result.successful) {
      await swal("Success!", "Profile Update", "success");
      handleReset();
      onClose(true);
    } else {
      swal("Failed!", "Failed to update Profile. Try Again!!!", "error");
    }

    setShowSpinner(false);
  };
  return (
    <DrawerWrapper state={show} onClose={() => onClose()}>
      <Typography
        variant="h5"
        style={{ fontWeight: 600, marginBottom: "28px" }}
      >
        {OPEN_MODAL_TITLE.EDIT_PROFILE}
      </Typography>
      <form
        className="flex flex-col"
        noValidate
        autoComplete="off"
        id="addProfileForm"
      >
        <TextField
          label="New Profile*"
          value={profileName}
          onChange={(e) => setProfileName(e.target.value)}
        />
      </form>
      <div>
        <Button
          className={classes.privilegeButton}
          variant="outlined"
          onClick={handleModal}
          startIcon={<AddIcon />}
        >
          Edit Privileges
        </Button>
        <AddPermissions
          open={open}
          handleClose={handleModal}
          permissions={permissions}
          setPermissions={setPermissions}
        />
      </div>
      <div>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          Assigned Privileges:
        </Typography>
        <AssignedPrivilege privileges={permissions} />
      </div>
      <div className="fixed bottom-0 mb-14 flex gap-1.5">
        <StyledCreateButton onClick={updateNewProfile}>
          Update
        </StyledCreateButton>
        <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
      </div>
      <div>
        {showSpinner ? (
          <div>
            <span style={{ marginLeft: "10px", color: "#ff0000" }}>
              <h5>Please DO NOT REFRESH the page...</h5>
            </span>
            <CircularProgress
              style={{
                color: "#18D26E",
              }}
            />
          </div>
        ) : null}
      </div>
    </DrawerWrapper>
  );
};

export default EditProfile;
