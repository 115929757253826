import app from "firebase/app";

//get Franchise count
export default async function getFranchiseCount() {
  const firestore = app.firestore();
  const data = await firestore.collection("Counters").doc("Franchise").get();
  return data.data()?.count || 0;
}

//increment Franchise count
// export async function incrementFranchiseCount(currentCount) {
//   const firestore = app.firestore();
//   await firestore.collection("Counters").doc("Franchise").set(
//     {
//       count: currentCount,
//     },
//     { merge: true }
//   );
// }

//get Seller count
export async function getSellerCount() {
  const firestore = app.firestore();
  const data = await firestore.collection("Counters").doc("Seller").get();
  return data.data()?.count || 0;
}

//increment Seller count
export async function incrementSellerCount(currentCount) {
  const firestore = app.firestore();
  await firestore.collection("Counters").doc("Seller").set({
    count: currentCount,
  });
}

//get E-Store count
export async function getEstoreCount() {
  const firestore = app.firestore();
  const data = await firestore.collection("Counters").doc("Estore").get();
  return data.data()?.count || 0;
}

//increment E-Store count
export async function incrementEstoreCount(currentCount) {
  const firestore = app.firestore();
  await firestore.collection("Counters").doc("Estore").set(
    {
      count: currentCount,
    },
    { merge: true }
  );
}

//decrement city count
export async function decrementCityCounter(documentId, city) {
  const firestore = app.firestore();
  const decrement = app.firestore.FieldValue.increment(-1);

  await firestore
    .collection("Counters")
    .doc(documentId)
    .update({
      [`${city}`]: decrement,
    });
}

//get Franchise Product count
export async function getFranchiseProductsCounter() {
  const firestore = app.firestore();
  const data = await firestore
    .collection("Counters")
    .doc("FranchiseProducts")
    .get();
  return data.data()?.count || 0;
}

//increment Seller count
export async function incrementFranchiseProductsCount(currentCount) {
  const firestore = app.firestore();
  await firestore.collection("Counters").doc("FranchiseProducts").set({
    count: currentCount,
  });
}

//get Seller Product count
export async function getSellerProductsCounter(city) {
  const firestore = app.firestore();
  const data = await firestore
    .collection("Counters")
    .doc("SellerProducts")
    .get();
  return data?.data()?.[city] ?? 0;
}

//increment Seller Products count
export async function incrementSellerProductsCount(currentCount, city) {
  const firestore = app.firestore();
  await firestore
    .collection("Counters")
    .doc("SellerProducts")
    .update({
      [city]: currentCount,
    });
}
