import app from "firebase/app";
import firebase from "firebase/app";
import {
  BlockingCategories,
  BlockingCategoriesCollection,
  ClientAdminName,
  getBranchLocation,
} from "../common/const";
import { deleteApprovalClient } from "./deleteDataFromFirestore";
import { changeConvertedInLead } from "./editDataInFirestore";
import {
  getCityAndRegionOfSE,
  getFranchiseCityCounter,
  getSalesUserFromId,
} from "./getDataFromFirestore";
import { discontinueClient } from "./sendRequestToServer";

//This function saves Franchise Data to firestore.
async function saveFranchiseData(data, currentCount) {
  const firestore = app.firestore();

  const franchiseLocation = await getBranchLocation(
    data.companyName,
    data.address,
    data.city,
    data.indianState,
    data.postalCode
  );
  const docRef = firestore.collection("Franchises").doc();
  await docRef
    .set({
      ...data,
      ...franchiseLocation,
      id: docRef.id,
      role: "Franchise Owner",
      privileges: [
        "dashboard",
        "Add Employees",
        "View Employees",
        "Franchise View Clients",
        "Franchise My Client Orders",
        "Franchise View Special Seller",
        "Show Products",
        "Show Final Products",
        "Franchise Seller",
        "Orders",
        "View Estore",
        "Add E-Store",
        "Approve Products",
      ],
    })
    .catch((e) => {
      return { successfull: false };
    });
  const increment = app.firestore.FieldValue.increment(1);

  const countDocRef = firestore.collection("Counters").doc("Franchise");

  countDocRef.set(
    {
      [`${data.city}`]: increment,
      count: currentCount,
    },
    { merge: true }
  );
  console.log("Franchise saved.");
  return { successfull: true };
}

//This function saves Seller Data to firestore.
export async function saveSellerData(data) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Sellers").doc();
  await docRef.set({ ...data, id: docRef.id }).catch((e) => {
    return { successfull: false };
  });
  console.log("Seller saved.");
  return { successfull: true };
}

//This function saves E-store Data to firestore.
export async function saveEstoreData(data) {
  const firestore = app.firestore();
  const estoreLocation = await getBranchLocation(
    data.companyName,
    data.address,
    data.city,
    data.indianState,
    data.postalCode
  );
  const docRef = firestore.collection("Estores").doc();
  await docRef
    .set({
      ...data,
      ...estoreLocation,
      role: "Estore Owner",
      privileges: [
        "dashboard",
        "Add Employees",
        "View Employees",
        "E-Store View Clients",
        "E-Store My Client Orders",
        "E-Store View Special Seller",
        "Show Products",
        "Show Final Products",
        "Store Orders",
        "Add Product",
        "View Product",
      ],
      id: docRef.id,
    })
    .catch((e) => {
      return { successfull: false };
    });
  const increment = app.firestore.FieldValue.increment(1);

  const countDocRef = firestore.collection("Counters").doc("Estore");

  countDocRef.set(
    {
      [`${data.city}`]: increment,
    },
    { merge: true }
  );
  console.log("Seller saved.");
  return { successfull: true };
}

//This function saves Client Data to firestore.
export async function saveClientData(data) {
  const firestore = app.firestore();
  let docRef;
  if (data.id) {
    docRef = firestore.collection("Prospects").doc(data.id);
  } else {
    docRef = firestore.collection("Prospects").doc();
    data["id"] = docRef.id;
  }
  await docRef.set({ ...data }, { merge: true }).catch((e) => {
    return { successfull: false };
  });
  console.log("Client saved.");
  return { successfull: true, data: data };
}

export async function saveRegionData(client_id, region_data) {
  const firestore = app.firestore();
  let docRef = firestore.collection("Clients").doc(client_id);
  if (region_data.id) {
    docRef = docRef.collection("Regions").doc(region_data.id);
    delete region_data.id;
  } else {
    docRef = docRef.collection("Regions").doc();
  }
  await docRef.set({ ...region_data }, { merge: true }).catch((e) => {
    return { successfull: false };
  });
  return { successfull: true, data: region_data };
}

export async function saveClusterData(client_id, region_id, cluster_data) {
  const firestore = app.firestore();
  let docRef = firestore.collection("Clients").doc(client_id);
  cluster_data["region_id"] = region_id;
  if (cluster_data.id) {
    docRef = docRef.collection("Clusters").doc(cluster_data.id);
    delete cluster_data.id;
  } else {
    docRef = docRef.collection("Clusters").doc();
  }
  await docRef.set({ ...cluster_data }, { merge: true }).catch((e) => {
    return { successfull: false };
  });
  return { successfull: true, data: cluster_data };
}

export async function saveClusterCityData(
  client_id,
  region_id,
  cluster_id,
  city_data
) {
  const firestore = app.firestore();
  let docRef = firestore.collection("Clients").doc(client_id);
  city_data["region_id"] = region_id;
  city_data["cluster_id"] = cluster_id;
  if (city_data.id) {
    docRef = docRef.collection("Cities").doc(city_data.id);
    delete city_data.id;
  } else {
    docRef = docRef.collection("Cities").doc();
  }
  await docRef.set({ ...city_data }, { merge: true }).catch((e) => {
    return { successfull: false };
  });
  return { successfull: true, data: city_data };
}

export async function saveBranchData(client_id, branch_data) {
  const firestore = app.firestore();
  let docRef = firestore.collection("Clients").doc(client_id);
  if (branch_data.id) {
    docRef = docRef.collection("Branches").doc(branch_data.id);
  } else {
    docRef = docRef.collection("Branches").doc();
    branch_data["id"] = docRef.id;
  }
  await docRef.set({ ...branch_data }, { merge: true }).catch((e) => {
    return { successfull: false };
  });
  return { successfull: true, data: branch_data };
}

export async function saveCityData(data) {
  const firestore = app.firestore();
  let docRef = firestore.collection("CityCodes").doc();
  await docRef.set({ ...data }).catch((e) => {
    return { successfull: false };
  });
  return { successfull: true };
}

export async function saveApprovedClientData(data) {
  const prevId = data.id;
  const firestore = app.firestore();
  let docRef;
  if (data.id) {
    docRef = firestore.collection("Clients").doc(data.id);
  } else {
    docRef = firestore.collection("Clients").doc();
    data["id"] = docRef.id;
  }
  await docRef.set({ ...data, id: docRef.id }, { merge: true }).catch((e) => {
    return { successfull: false };
  });
  changeConvertedInLead(data.leadId);
  deleteApprovalClient(prevId);
  return { successfull: true };
}

//This function save Designation Data to firestore
export async function saveProfileData(data) {
  const createdBy = localStorage.getItem("role");
  const createdDocId = localStorage.getItem("userDocId");
  const createdEmailId = localStorage.getItem("email");

  const { name, permissions } = data;

  const payloadData = {
    name,
    permissions,
    createdBy,
    createdDocId,
    createdEmailId,
  };

  const firestore = app.firestore();
  const docRef = firestore.collection("Profile").doc();
  await docRef.set({ ...payloadData, id: docRef.id }).catch((e) => {
    return { successful: false };
  });
  return { successful: true };
}

export async function updateProfileData(data) {
  const { name, permissions, id } = data;

  const payloadData = {
    name,
    permissions,
    id,
  };

  const firestore = app.firestore();
  const docRef = firestore.collection("Profile").doc(id);
  await docRef.update(payloadData).catch((e) => {
    return { successful: false };
  });
  return { successful: true };
}

export async function deleteProfileData(profileId) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Profile").doc(profileId);
  await docRef.delete().catch((e) => {
    return { successful: false };
  });
  return { successful: true };
}

//This function saves Client Meeting to firestore.
export async function saveMeetingData(data) {
  let role = localStorage.getItem("role");
  const DocId = localStorage.getItem("userDocId");

  let designation = "se";
  switch (role) {
    case "Super Admin":
      designation = "sa";
      break;
    case "National Sales Head":
      designation = "nsh";
      break;
    case "Regional Sales Head":
      designation = "rsh";
      break;
    case "City Sales Head":
      designation = "csh";
      break;
    case "Sales Executive":
      designation = "se";
      break;
    default:
      designation = "se";
  }
  console.log(designation, role);
  if (designation == "nsh" || designation == "sa") {
    data["rsh"] = "";
    data["csh"] = "";
    data["se"] = "";
    const firestore = app.firestore();
    const docRef = firestore.collection("Meetings").doc();
    await docRef
      .set({ ...data, id: docRef.id, createdBy: DocId })
      .catch((e) => {
        return { successfull: false };
      });
    console.log("Client saved.");
    return { successfull: true };
  } else if (designation == "rsh") {
    data["csh"] = "";
    data["se"] = "";
    const firestore = app.firestore();
    const docRef = firestore.collection("Meetings").doc();
    await docRef
      .set({ ...data, id: docRef.id, createdBy: DocId })
      .catch((e) => {
        return { successfull: false };
      });
    console.log("Client saved.");
    return { successfull: true };
  } else if (designation == "csh") {
    data["se"] = "";
    const firestore = app.firestore();
    const docRef = firestore.collection("Meetings").doc();
    await docRef
      .set({ ...data, id: docRef.id, createdBy: DocId })
      .catch((e) => {
        return { successfull: false };
      });
    console.log("Client saved.");
    return { successfull: true };
  } else if (designation == "se") {
    const firestore = app.firestore();
    const docRef = firestore.collection("Meetings").doc();
    await docRef
      .set({ ...data, id: docRef.id, createdBy: DocId })
      .catch((e) => {
        return { successfull: false };
      });
    console.log("Client saved.");
    return { successfull: true };
  }
  // Remove code
  // const firestore = app.firestore();
  // const docRef = firestore.collection("Meetings").doc();
  // await docRef.set({ ...data, id: docRef.id }).catch((e) => {
  //   return { successfull: false };
  // });
  // console.log("Client saved.");
  // return { successfull: true };
}

//This function saves Leads to firestore.
export async function saveLeadsData(data) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Leads").doc();
  await docRef.set({ ...data, converted: false, id: docRef.id }).catch((e) => {
    return { successfull: false };
  });
  console.log("Client saved.");
  return { successfull: true, leadId: docRef.id };
}

//This function saves ProductApproval to firestore.
export async function saveProductsApprovalsData(city, data) {
  const firestore = app.firestore();
  const docRef = firestore
    .collection("FranchiseProductApprovals")
    .doc(city)
    .collection("Products")
    .doc();
  await docRef.set({ ...data, id: docRef.id }).catch((e) => {
    return { successfull: false };
  });
  console.log("Product saved.");
  return { successfull: true };
}

// this function send products to franchaise for approvals

export async function sendProductRequestforApproval(city, data) {
  const firestore = app.firestore();
  const docRef = firestore
    .collection("FranchiseProductApprovals")
    .doc(city)
    .collection("Products")
    .doc(data.id);
  await docRef.update({ ...data, id: docRef.id }).catch((e) => {
    return { successfull: false };
  });
  console.log("Product sent for approval");
  return { successfull: true };
}

//This function saves PAN ProductApproval to firestore.
export async function savePANProductsApprovalsData(cities, data) {
  const firestore = app.firestore();

  //Add Product to PAN LEVEL First to get SAME DocID
  const panDocRef = firestore.collection("PAN-Products").doc();
  await panDocRef
    .set({ ...data, maxPrice: 0, id: panDocRef.id })
    .catch((e) => {});

  for (let city of cities) {
    console.log(city);
    const docRef = firestore
      .collection("FranchiseProductApprovals")
      .doc(city.value)
      .collection("Products")
      .doc(panDocRef.id);
    await docRef
      .set({
        ...data,
        id: docRef.id,
        timestamp: app.firestore.FieldValue.serverTimestamp(),
      })
      .catch((e) => {});
    await saveCityCounter(city.value, "NexStoc Product Catalog");
  }

  console.log("Product saved.");
  return { successfull: true };
}

//This function saves city Counter to firestore.
export async function saveCityCounter(city, title) {
  const cityCounter = await getFranchiseCityCounter(city);
  const firestore = app.firestore();
  const docRef = firestore.collection("FranchiseProductApprovals").doc(city);
  await docRef
    .set({ counter: cityCounter, title: title }, { merge: true })
    .catch((e) => {
      return { successfull: false };
    });
  console.log(city, "City Counter saved.");
  return { successfull: true };
}

//This function saves city Counter to firestore.
export async function saveSelectedProducts(
  id,
  selectedProducts,
  isPAN,
  selectedProductsData
) {
  const firestore = app.firestore();
  const docRef = firestore.collection("Prospects").doc(id);
  await docRef
    .update({
      isPAN: isPAN,
      selectedProducts: selectedProducts,
      selectedProductsData: selectedProductsData,
    })
    .catch((e) => {
      return { successfull: false };
    });
  console.log("Products saved.");
  return { successfull: true };
}

//save quotation as version
export async function saveQuotationAsVersion(
  id,
  selectedProducts,
  noOfQuotation
) {
  const quotationVersion = `Version${noOfQuotation}`;
  const firestore = app.firestore();
  const docRef = firestore.collection("Prospects").doc(id);
  let totalVersions;

  await docRef
    .get()
    .then(async (snapshots) => {
      let oldVersions = snapshots.data().quotationProducts;
      if (oldVersions) {
        oldVersions[quotationVersion] = selectedProducts;
      } else {
        oldVersions = {};
        oldVersions[quotationVersion] = selectedProducts;
      }
      totalVersions = oldVersions;
    })
    .catch((e) => {
      return { successfull: false };
    });

  await docRef
    .update({
      quotationProducts: totalVersions,
      noOfQuotation: noOfQuotation,
    })
    .catch((e) => {
      return { successfull: false };
    });
  console.log("Quotation saved.");
  return {
    successfull: true,
    message: `Your prospect have been saved as Version ${noOfQuotation}`,
  };
}

//This function saves product request Data to firestore.
export async function sendProductRequestToPM(
  product,
  city,
  seId,
  seEmail,
  seName,
  prospectId,
  prospectName,
  price,
  priceHistory
) {
  const firestore = app.firestore();
  var fieldValue = app.firestore.FieldValue;

  try {
    const refData = await getCityAndRegionOfSE(seId);
    if (!refData) {
      return { successfull: false };
    }
    console.log(refData);
    const docRef = firestore.collection("PM-Requests").doc(seId);
    await docRef
      .set(
        {
          prospects: { [prospectId]: prospectName },
          ...refData,
          email: seEmail,
          name: seName,
          status: "Pending",
          id: docRef.id,
        },
        { merge: true }
      )
      .catch((e) => {
        return { successfull: false };
      });

    await docRef
      .collection(prospectId)
      .doc(product.id)
      .set(
        {
          ...product,
          prospectPrice: price,
          city: city,
          id: product.id,
          status: "Pending",
          prospectPriceHistory: priceHistory,
          statusBySE: "Pending",
        },
        { merge: true }
      )
      .catch((e) => {
        return { successfull: false };
      });

    console.log("Product saved.");
    return { successfull: true };
  } catch (e) {
    console.log(e.message);
    return { successfull: false };
  }
}

export async function sendNewProductRequestToPM(
  product,
  seId,
  prospectId,
  price,
  historyData
) {
  const firestore = app.firestore();

  try {
    const docRef = firestore.collection("PM-Requests").doc(seId);

    await docRef
      .collection(prospectId)
      .doc(product.id)
      .set(
        {
          prospectPrice: price,
          status: "Pending",
          prospectPriceHistory: historyData,
        },
        { merge: true }
      )
      .catch((e) => {
        return { successfull: false };
      });

    console.log("Product Prospect Price saved.");
    return { successfull: true };
  } catch (e) {
    console.log(e.message);
    return { successfull: false };
  }
}

export async function saveRemarkForMeeting(meeting, remark) {
  const firestore = app.firestore();
  let fieldValue = app.firestore.FieldValue;

  try {
    const docRef = firestore.collection("Meetings").doc(meeting.id);
    const remarkMap = {
      remark: remark,
      timestamp: app.firestore.Timestamp.now(),
    };
    await docRef
      .update(
        {
          remarks: fieldValue.arrayUnion(remarkMap),
        },
        { merge: true }
      )
      .catch((e) => {
        return { successfull: false };
      });

    console.log("Remark saved.");
    return { successfull: true };
  } catch (e) {
    console.log(e.message);
    return { successfull: false };
  }
}

export async function addMissingMettingReason(meeting, reason) {
  const firestore = app.firestore();
  try {
    const docRef = firestore.collection("Meetings").doc(meeting.id);
    await docRef
      .update(
        {
          missingReason: reason,
        },
        { merge: true }
      )
      .catch((e) => {
        return { successfull: false };
      });

    console.log("Reason added.");
    return { successfull: true };
  } catch (e) {
    console.log(e.message);
    return { successfull: false };
  }
}

export async function blockCoasterWiseUsers(
  categoryBlockValue,
  ids,
  name,
  clientId
) {
  //categoryBlockValue = Region, Cluster, ...

  let db = app.firestore();
  if (categoryBlockValue !== "Pan India") {
    db.collection("ClientEmployees")
      .where(BlockingCategoriesCollection[categoryBlockValue], "==", ids)
      .get()
      .then(async (snapshots) => {
        const updates = [];
        snapshots.forEach((doc) =>
          updates.push(
            doc.ref.update({
              isBlocked: true,
              blockedAdmin: ClientAdminName[categoryBlockValue],
            })
          )
        );
        await Promise.all(updates);
        // db.collection("ClientEmployees")
        //   .where("client_id", "==", clientId)
        //   .where("role", "==", "Super Admin")
        //   .get()
        //   .then(async (snapshots) => {
        //     const updates = [];
        //     snapshots.forEach((doc) =>
        //       updates.push(
        //         doc.ref.update({
        //           // blockedAdmin: ClientAdminName[categoryBlockValue],
        //           blockedAdmins: [
        //             ...(doc.data()?.blockedAdmins
        //               ? doc.data()?.blockedAdmins
        //               : ""),
        //             {
        //               coaster: ClientAdminName[categoryBlockValue],
        //               coasterName: name,
        //             },
        //           ],
        //         })
        //       )
        //     );
        await Promise.all(updates);
        db.collection("Clients")
          .doc(clientId)
          .collection(BlockingCategories[categoryBlockValue])
          .doc(ids)
          .update({ isBlocked: true }, { merge: true })
          .catch((e) => {
            return { successfull: false };
          });

        console.log("Reason added.");
        return { successfull: true };
        // })
        // .catch((e) => {
        //   return { successfull: false };
        // });
      })
      .catch((e) => {
        return { successfull: false };
      });
    return { successfull: true };
  } else {
    db.collection("ClientEmployees")
      .where("client_id", "==", clientId)
      .get()
      .then(async (snapshots) => {
        const updates = [];
        snapshots.forEach((doc) =>
          updates.push(
            doc.ref.update({
              isBlocked: true,
              blockedAdmin: ClientAdminName[categoryBlockValue],
            })
          )
        );
        await Promise.all(updates);
        return { successfull: true };
      })
      .catch((e) => {
        return { successfull: false };
      });
    return { successfull: true };
  }
}

export async function unblockCoasterWiseUsers(
  categoryBlockValue,
  ids,
  name,
  clientId
) {
  //categoryBlockValue = Region, Cluster, ...

  let db = app.firestore();
  if (categoryBlockValue !== "Pan India") {
    db.collection("ClientEmployees")
      .where(BlockingCategoriesCollection[categoryBlockValue], "==", ids)
      .get()
      .then(async (snapshots) => {
        const updates = [];
        snapshots.forEach((doc) =>
          updates.push(
            doc.ref.update({
              isBlocked: false,
              blockedAdmin: firebase.firestore.FieldValue.delete(),
            })
          )
        );
        await Promise.all(updates);
        db.collection("Clients")
          .doc(clientId)
          .collection(BlockingCategories[categoryBlockValue])
          .doc(ids)
          .update({ isBlocked: false }, { merge: true })
          .catch((e) => {
            return { successfull: false };
          });
      })
      .catch((e) => {
        return { successfull: false };
      });
    return { successfull: true };
  } else {
    db.collection("ClientEmployees")
      .where("client_id", "==", clientId)
      .get()
      .then(async (snapshots) => {
        const updates = [];
        snapshots.forEach((doc) =>
          updates.push(
            doc.ref.update({
              isBlocked: false,
              blockedAdmin: firebase.firestore.FieldValue.delete(),
            })
          )
        );
        await Promise.all(updates);
        return { successfull: true };
      })
      .catch((e) => {
        return { successfull: false };
      });
    return { successfull: true };
  }
}

export async function discontinueCoasterWiseUsers(
  categoryBlockValue,
  ids,
  name,
  clientId
) {
  //categoryBlockValue = Region, Cluster, ...

  let db = app.firestore();
  const userIds = [];
  if (categoryBlockValue !== "Pan India") {
    db.collection("ClientEmployees")
      .where(BlockingCategoriesCollection[categoryBlockValue], "==", ids)
      .get()
      .then(async (snapshots) => {
        const updates = [];
        snapshots.forEach((doc) => {
          userIds.push(doc.data().userUID);
          updates.push(
            doc.ref.update({
              isBlocked: firebase.firestore.FieldValue.delete(),
              blockedAdmin: firebase.firestore.FieldValue.delete(),
              isActive: false,
            })
          );
        });
        console.log(userIds);
        await Promise.all(updates);
        db.collection("Clients")
          .doc(clientId)
          .collection(BlockingCategories[categoryBlockValue])
          .doc(ids)
          .update(
            { isBlocked: firebase.firestore.FieldValue.delete() },
            { merge: true }
          )
          .catch((e) => {
            return { successfull: false };
          });
      })
      .catch((e) => {
        return { successfull: false };
      });
    return userIds;
    // return { successfull: true, userIds: userIds };
  } else {
    db.collection("ClientEmployees")
      .where("client_id", "==", clientId)
      .get()
      .then(async (snapshots) => {
        const updates = [];
        snapshots.forEach((doc) => {
          userIds.push(doc.data().userUID);
          updates.push(
            doc.ref.update({
              isBlocked: firebase.firestore.FieldValue.delete(),
              blockedAdmin: firebase.firestore.FieldValue.delete(),
              isActive: false,
            })
          );
        });
        // console.log(userIds);
        await Promise.all(updates);
        return await deleteId(userIds);
      })
      .catch((e) => {
        return { successfull: false };
      });
    console.log(userIds);
    return { successfull: true };
  }
}
export async function deleteId(data) {
  const res = await discontinueClient({ clientId: data });
  console.log(data);
  return { successfull: res.message, userIds: data };
}

// tis function is used my marketing user to assign lead to any user
export async function assignLeadtoUser(user, leadId) {
  try {
    console.log(user, leadId, "api arg");
    const firestore = app.firestore();
    const leadRef = firestore.collection("Leads").doc(leadId);
    // console.log(leadRef.data(), "leadRef")
    // var updateRef;

    // if(user.designation === "National Sales Head"){
    //   await leadRef.update({
    //     nsh: user.id,
    //   }).then(()=>{
    //     console.log("then")
    //     return true
    //   }).catch((err)=>{
    //     console.log("catch ")
    //     console.log(err)
    //     return false
    //   })
    // }
    switch (user.designation) {
      case "National Sales Head":
        console.log("nsh here");
        await leadRef
          .update({
            nsh: user.id,
            rsh: "",
            csh: "",
            se: "",
          })
          .then(() => {
            console.log("then");
            return { successfull: true };
          })
          .catch((err) => {
            console.log("catch");
            console.log(err);
            return false;
          });
        break;

      case "Regional Sales Head":
        var nsh = await firestore.collection("Users").doc(user.createdby).get();
        var nshData = nsh.data();
        await leadRef.update({
          nsh: nshData.id,
          rsh: user.id,
          csh: "",
          se: "",
        });
        break;

      case "City Sales Head":
        var rsh = await firestore.collection("Users").doc(user.createdby).get();
        var rshData = await rsh.data();
        var nsh = await firestore
          .collection("Users")
          .doc(rshData.createdby)
          .get();
        var nshData = await nsh.data();
        await leadRef.update({
          nsh: nshData.id,
          rsh: rshData.id,
          csh: user.id,
          se: "",
        });
        break;

      case "Sales Executive":
        var csh = await firestore.collection("Users").doc(user.createdby).get();
        var cshData = csh.data();
        var rsh = await firestore
          .collection("Users")
          .doc(cshData.createdby)
          .get();
        var rshData = rsh.data();
        var nsh = await firestore
          .collection("Users")
          .doc(rshData.createdby)
          .get();
        var nshData = nsh.data();
        await leadRef.update({
          nsh: nshData.id,
          rsh: rshData.id,
          csh: cshData.id,
          se: user.id,
        });
        break;

      default:
        break;
    }
    // console.log(updateRef, "api update res");
    // return updateRef;
    return { successfull: true };
  } catch (error) {
    console.log(error);
    return { successfull: false };
    // return false;
  }
}

export async function changeProductStatusToApproved(city) {
  const firebase = app.firestore();
  const docref = await firebase
    .collection("FranchiseProductApprovals")
    .doc(city)
    .collection("Products")
    .where("STATUS", "==", "franchise-pending")
    .get();

  //   return docref.forEach((doc)=>{
  //   console.log(doc.data())
  // })

  docref.forEach(async (doc) => {
    let item = doc.data();
    await firebase
      .collection("FranchiseProductApprovals")
      .doc(city)
      .collection("Products")
      .doc(item.id)
      .update({
        STATUS: "approved",
      })
      .catch((e) => {
        return false;
      });
  });
  return true;
}

export async function saveBranchDataToFranchise(client_id, branchData) {
  const firestore = app.firestore();
  let docRef;
  try {
    if (branchData.vendorType === "Franchise") {
      docRef = firestore.collection("Franchises").doc(branchData.vendor);
    } else {
      docRef = firestore.collection("Estores").doc(branchData.vendor);
    }

    await docRef.get().then(async (snapshots) => {
      const existData = (await snapshots.ref.get()).data().assignedBranch;
      snapshots.ref.update({
        assignedBranch: { ...existData, [branchData.id]: client_id },
      });
    });
    return { successfull: true };
  } catch (e) {
    console.log(e.message);
    return { successfull: false };
  }
}

//add reason and status for blocking in franchise
export async function blockFranchiseAndAllEstores(franchiseId, blockReason) {
  try {
    let db = app.firestore();
    await db.collection("Franchises").doc(franchiseId).update(
      {
        isBlockedFranchise: true,
        isBlockedReason: blockReason,
        isBlockedDate: new Date(),
      },
      { merge: true }
    );
    await db
      .collection("Estores")
      .where("franchiseId", "==", franchiseId)
      .get()
      .then(async (snapshots) => {
        const updates = [];
        snapshots.forEach((doc) =>
          updates.push(
            doc.ref.update({
              isBlockedFranchise: true,
              isBlockedReason: blockReason,
              isBlockedDate: new Date(),
            })
          )
        );
      });
    return true;
  } catch (error) {
    return false;
  }
}

//Change Franchise Data in Client Collection
export async function updateVendorDetails(data) {
  const firestore = app.firestore();
  let clientRef = firestore.collection("Clients");
  await clientRef
    .doc(data.clientId)
    .collection("Branches")
    .doc(data.branchId)
    .update({
      vendor: data.vendorId,
      vendorDist: data.vendorDist,
      vendorName: data.vendorName,
      vendorType: data.vendorType,
    });
}
export async function unblockFranchiseBranch(data) {
  const firestore = app.firestore();
  let clientRef = await firestore.collection("Clients");
  for (let vendorDetails of data) {
    const branchObj = vendorDetails?.assignedBranch;
    if (branchObj) {
      for (let branch of Object.keys(branchObj)) {
        await clientRef
          .doc(branchObj[branch])
          .collection("Branches")
          .doc(branch)
          .update({
            vendor: vendorDetails.id,
            vendorName: vendorDetails.companyName,
            vendorType:
              vendorDetails.role === "Franchise Owner" ? "Franchise" : "Estore",
          });
      }
    }
  }
  return true;
}
export async function unblockFranchiseAndAllEstores(data) {
  const firestore = app.firestore();
  for (let vendor of data) {
    if (vendor.role === "Franchise Owner") {
      await firestore.collection("Franchises").doc(vendor.id).update({
        isBlockedFranchise: app.firestore.FieldValue.delete(),
        isBlockedReason: app.firestore.FieldValue.delete(),
        isBlockedDate: app.firestore.FieldValue.delete(),
      });
    } else {
      await firestore.collection("Estores").doc(vendor.id).update({
        isBlockedFranchise: app.firestore.FieldValue.delete(),
        isBlockedReason: app.firestore.FieldValue.delete(),
        isBlockedDate: app.firestore.FieldValue.delete(),
      });
    }
  }
}

export async function discontinueAssignFranchise(data) {
  const firestore = app.firestore();
  for (let vendorDetails of data) {
    let vendorRef;
    if (vendorDetails.vendorType === "Franchise") {
      vendorRef = firestore.collection("Franchises").doc(vendorDetails.vendor);
    } else {
      vendorRef = firestore.collection("Estores").doc(vendorDetails.vendor);
    }
    await vendorRef.get().then(async (snapshots) => {
      const existData = (await snapshots.ref.get()).data().assignedBranch;
      snapshots.ref.update({
        assignedBranch: {
          ...existData,
          [vendorDetails.id]: vendorDetails.client_id,
        },
      });
    });
  }
}

export async function discontinueFranchiseAndAllEstores(franchiseId) {
  try {
    let db = app.firestore();
    await db.collection("Franchises").doc(franchiseId).update(
      {
        isDiscontinueFranchise: true,
        assignedBranch: app.firestore.FieldValue.delete(),
      },
      { merge: true }
    );
    await db
      .collection("Estores")
      .where("franchiseId", "==", franchiseId)
      .get()
      .then(async (snapshots) => {
        const updates = [];
        snapshots.forEach((doc) =>
          updates.push(
            doc.ref.update({
              isDiscontinueFranchise: true,
              assignedBranch: app.firestore.FieldValue.delete(),
            })
          )
        );
      });
    return true;
  } catch (error) {
    return false;
  }
}
export async function createDivertRequest(requestData) {
  let db = app.firestore();
  try {
    await db
      .collection("DivertRequest")
      .doc()
      .set({ ...requestData, status: "Pending" });
    return { successfull: true };
  } catch (error) {
    return { successfull: false };
  }
}

export async function divertFranchise(data) {
  let db = app.firestore();
  try {
    //for adding assigned object
    const vendorRef =
      data.vendorType === "Franchise"
        ? db.collection("Franchises").doc(data.vendor)
        : db.collection("Estores").doc(data.vendor);
    await vendorRef.get().then(async (snapshots) => {
      const existData = (await snapshots.ref.get()).data().assignedBranch;
      snapshots.ref.update({
        assignedBranch: {
          ...existData,
          [data.branch_id]: data.client_id,
        },
      });
    });
    const vendorRef1 =
      data.previousVendorType === "Franchise"
        ? db.collection("Franchises").doc(data.previousVendor)
        : db.collection("Estores").doc(data.previousVendor);
    await vendorRef1.get().then(async (snapshots) => {
      const existData = (await snapshots.ref.get()).data().assignedBranch;
      delete existData[data.branch_id];
      snapshots.ref.update({
        assignedBranch: {
          ...existData,
        },
      });
    });
  } catch (error) {
    return false;
  }
}
export async function updateDivertRequestStatus(id) {
  const db = app.firestore();
  await db.collection("DivertRequest").doc(id).update({ status: "Approved" });
  return true;
}
export default saveFranchiseData;
