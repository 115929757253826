import React from "react";
import Navbar from "./Navbar";
import "firebase/auth";

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar>{children}</Navbar>
    </div>
  );
};

export default Layout;
