import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@material-ui/core";
import { Delete as DeleteIcon, Create as CreateIcon } from "@material-ui/icons";
import { ColorButton } from "../../common/Components/ColorButton";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { getClientBranches } from "../../backend/getDataFromFirestore";
import Layout from "../../common/Layout";
import {
  deleteBranchData,
  deleteFileFromStorage,
} from "../../backend/deleteDataFromFirestore";
import BranchDialog from "../../common/Components/BranchDialog";

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: "100px",
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "75vh",
  },
  button: {
    marginTop: "10px",
  },
  desDetails: {
    height: 100,
    overflow: "auto",
  },
  duplicate: {
    backgroundColor: "red",
  },
}));

const initialBranchData = {
  id: "",
  name: "",
  address: "",
  pincode: "",
  shippingAddress: "",
  sezLetter: "",
  sezImage: null,
  sezRef: "",
  gstType: "",
  gstNo: "",
  gstImage: null,
  gstRef: "",
  state: "",
  city: "",
  district: "",
  isMailRoom: false,
  vendor: "",
  vendorName: "",
  vendorType: "",
  vendorDist: 0,
  lat: 0,
  lng: 0,
};

const ViewBranch = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const client_id = location.state;
  const [branchData, setBranchData] = useState(initialBranchData);
  const [branches, setBranches] = useState([]);
  const { dialogInfo, openDialog, closeDialog } = DialogHelper();

  useEffect(() => {
    getBranches();
  }, []);

  async function getBranches() {
    const temp = await getClientBranches(client_id);
    setBranches(temp);
    console.log(temp);
  }
  async function deleteBranch(client_id, branch) {
    const toDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Branch!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    if (!toDelete) {
      return;
    }

    swal("Deleting Branch", "Please do not refresh this page", "info", {
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: false,
    });

    if (branch.gstImage?.length > 0) deleteFileFromStorage(branch.gstImage);
    if (branch.sezImage?.length > 0) deleteFileFromStorage(branch.sezImage);

    deleteBranchData(client_id, branch.id).then((res) => {
      swal.close();

      console.log("delete res", res);
      if (res.successfull) {
        swal("Success!", "Branch deleted", "success").then(() => {
          window.location.pathname = "/view-branch";
        });
      } else {
        swal("Failed!", "Failed to delete Branch. Try Again!!!", "error").then(
          () => {
            window.location.pathname = "/view-branch";
          }
        );
      }
    });
  }

  const addBranchBtn = () => {
    history.push("/add-branch", client_id);
  };

  async function editBranch(client_id, branch) {
    setBranchData(branch);
    openDialog(branch, "edit", -1);
  }
  return (
    <>
      <Layout />
      <div className=" m-5 pt-5">
        <h2 style={{ textAlign: "center" }}>View Branch</h2>
        <div className="row">
          <div className="col-12 col-md-12">
            <br />
            <>
              <TableContainer className={classes.container} component={Paper}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ tableLayout: "fixed" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "10em" }}>
                        Branch Name
                      </TableCell>
                      <TableCell style={{ width: "10em" }}>Address</TableCell>
                      <TableCell style={{ width: "10em" }}>Pincode</TableCell>
                      <TableCell style={{ width: "10em" }}>
                        Shipping Address
                      </TableCell>
                      <TableCell style={{ width: "10em" }}>GST Type</TableCell>
                      <TableCell style={{ width: "10em" }}>
                        SEZ Letter Number
                      </TableCell>
                      <TableCell style={{ width: "10em" }}>SEZ File</TableCell>
                      <TableCell style={{ width: "10em" }}>
                        GST Number
                      </TableCell>
                      <TableCell style={{ width: "10em" }}>GST File</TableCell>
                      <TableCell style={{ width: "10em" }}>State</TableCell>
                      <TableCell style={{ width: "10em" }}>City</TableCell>
                      <TableCell style={{ width: "10em" }}>District</TableCell>
                      <TableCell style={{ width: "10em" }}>MailRoom</TableCell>
                      <TableCell style={{ width: "10em" }}>
                        Nearest Vendor
                      </TableCell>
                      <TableCell style={{ width: "10em" }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {branches === undefined ? (
                      <div></div>
                    ) : (
                      branches.map((branch, index) => {
                        return (
                          <StyledTableRow
                            key={index}
                            className={
                              branch["duplicate"] ? classes.duplicate : null
                            }
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              <p>{branch.name}</p>
                            </TableCell>
                            <TableCell>{branch.address}</TableCell>
                            <TableCell>{branch.pincode}</TableCell>
                            <TableCell>{branch.shippingAddress}</TableCell>
                            <TableCell>{branch.gstType}</TableCell>
                            <TableCell>{branch.sezLetter}</TableCell>
                            <TableCell>
                              {branch.sezImage ? (
                                <ColorButton
                                  variant="contained"
                                  className={classes.button}
                                  onClick={() =>
                                    window.open(branch.sezImage, "_blank")
                                  }
                                  style={{ marginBottom: "10px" }}
                                >
                                  View
                                </ColorButton>
                              ) : null}
                            </TableCell>
                            <TableCell>{branch.gstNo}</TableCell>
                            <TableCell>
                              {branch.gstImage ? (
                                <ColorButton
                                  variant="contained"
                                  className={classes.button}
                                  onClick={() =>
                                    window.open(branch.gstImage, "_blank")
                                  }
                                  style={{ marginBottom: "10px" }}
                                >
                                  View
                                </ColorButton>
                              ) : null}
                            </TableCell>
                            <TableCell>{branch.state}</TableCell>
                            <TableCell>{branch.city}</TableCell>
                            <TableCell>{branch.district}</TableCell>
                            <TableCell>
                              {branch.isMailRoom ? "YES" : "No"}
                            </TableCell>
                            <TableCell>{branch.vendorName}</TableCell>
                            <TableCell>
                              <IconButton
                                aria-label="delete"
                                onClick={(e) => deleteBranch(client_id, branch)}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                aria-label="edit"
                                onClick={(e) => editBranch(client_id, branch)}
                              >
                                <CreateIcon />
                              </IconButton>
                            </TableCell>
                          </StyledTableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
                {dialogInfo.open && (
                  <BranchDialog
                    key={1}
                    dialogInfo={dialogInfo}
                    classes={classes}
                    closeDialog={closeDialog}
                    client_id={client_id}
                    data={branchData}
                    style={{ marginBottom: "10px" }}
                  />
                )}
              </TableContainer>
              <ColorButton
                variant="contained"
                fullWidth="max"
                className={classes.button}
                onClick={() => addBranchBtn()}
              >
                Add Branch
              </ColorButton>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

const DialogHelper = () => {
  const [dialogInfo, setDialogInfo] = useState({
    open: false,
    branchVal: {},
    type: "",
    index: -1,
  });

  function openDialog(val, type, index) {
    setDialogInfo({
      open: true,
      branchVal: val,
      type: type,
      index: index,
    });
  }
  function closeDialog() {
    setDialogInfo({
      open: false,
      branchVal: {},
      type: "",
      index: -1,
    });
  }
  return { dialogInfo, openDialog, closeDialog };
};

export default ViewBranch;
