import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { ColorButton } from "../../../common/Components/ColorButton";
import {
  getBranchdataFromIds,
  getEstoresBranchIds,
} from "../../../backend/getDataFromFirestore";
import DivertBranchTable from "./DivertBranchTable";
import swal from "sweetalert";
import { Box, DialogTitle, TextField } from "@material-ui/core";
import {
  blockFranchiseAndAllEstores,
  updateVendorDetails,
} from "../../../backend/saveDataToFirestore";

export const BlockFranchise = ({ franchiseData }) => {
  const [open, setOpen] = React.useState(false);
  const [openReasonDialog, setOpenReasonDialog] = React.useState(false);
  const [branchData, setBranchData] = React.useState([]);
  const [blockReason, setBlockReason] = React.useState("");

  const handleClickOpen = async () => {
    const allBranchObj = await getEstoresBranchIds(franchiseData);
    const res = await getBranchdataFromIds(allBranchObj);
    setBranchData(res);
    setOpen(true);
  };

  const handleClose = async () => {
    setBlockReason("");
    setOpen(false);
  };

  const handleCloseBlock = async () => {
    setOpenReasonDialog(false);
  };

  return (
    <div>
      <ColorButton
        variant="contained"
        style={{ margin: "10px" }}
        onClick={() => handleClickOpen()}
      >
        Block Franchise
      </ColorButton>
      <Dialog
        fullWidth
        maxWidth="1216px"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DivertBranchTable
          franchiseData={franchiseData}
          branchData={branchData}
          setBranchData={setBranchData}
        />
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <ColorButton
            variant="contained"
            color="primary"
            disabled={!branchData?.length}
            onClick={() => {
              setOpenReasonDialog(true);
            }}
          >
            Reason
          </ColorButton>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="616px"
        open={openReasonDialog}
        onClose={handleCloseBlock}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Reason</DialogTitle>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            id="outlined-basic"
            label="Block Reason"
            value={blockReason}
            variant="outlined"
            onChange={(e) => setBlockReason(e.target.value)}
          />
        </Box>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseBlock();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <ColorButton
            variant="contained"
            color="primary"
            onClick={() => {
              uploadData(franchiseData, branchData, blockReason);
            }}
          >
            Block
          </ColorButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const uploadData = async (franchiseData, branchData, blockReason) => {
  let isValidBranchData = true;

  for (let branch of branchData) {
    if (!validateBranchFields(branch)) {
      isValidBranchData = false;
      break;
    }
  }
  if (blockReason === "") {
    swal("Error!", "Please Add Reason!", "error");
    return;
  } else if (!isValidBranchData) {
    swal("Error!", "Please Provide all the Vendors!", "error");
    return;
  } else {
    swal(
      "Uploading Data",
      "Please wait it will take some time. Please do not refresh the page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );
    console.log("Upload Data");
    try {
      for (const branch of branchData) {
        const dataa = {};
        dataa["clientId"] = branch.client_id;
        dataa["branchId"] = branch.id;
        dataa["vendorId"] = branch.vendor;
        dataa["vendorDist"] = branch.vendorDist;
        dataa["vendorName"] = branch.vendorName;
        dataa["vendorType"] = branch.vendorType;
        await updateVendorDetails(dataa); //----
      }
      await blockFranchiseAndAllEstores(franchiseData.id, blockReason); //----
      swal.close();
      swal("Success!", "Franchise Blocked Successfully", "success").then(() => {
        window.location.reload();
      });
    } catch (e) {
      swal.close();
      swal("Error!", "Something went wrong", "error");
    }
  }
};

const validateBranchFields = (branch) => {
  if (branch === {} || branch.vendor === "" || branch.isChangeVendor !== true) {
    return false;
  }
  return true;
};
