import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import clsx from "clsx";
import { indianStates } from "../../common/const";
import { CircularProgress } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import {setTest} from '../firebaseConfig/firebase';
import { saveLeadsData } from "../../backend/saveDataToFirestore";

import "firebase/firestore";
import { useFirestore } from "reactfire";
import swal from "sweetalert";
import Layout from "../../common/Layout";
import { useHistory } from "react-router";
import { useState, useContext, useEffect } from "react";
import {
  getLocationUsingAddress,
  sendLeadNotification,
  sendLeadsToNSH,
} from "../../backend/sendRequestToServer";
import { pages } from "../../common/const";
import { getPageName } from "../../common/const";
import {
  getSalesUserFromId,
  getSalesUserFromRole,
} from "../../backend/getDataFromFirestore";
import { useLocation } from "react-router-dom";
import { deleteContactBlog } from "../../backend/deleteDataFromFirestore";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 275,
    // margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  pos: {
    marginTop: 20,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  label: {
    color: "#000",
  },

  textField: {
    "& .MuiInputBase-input": {
      color: "#000",
    },
  },

  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
    marginBottom: "10px",
  },
}));

const SendLeadsToSales = (props) => {
  const [showMobileErr, setMobileErr] = useState("");
  const [showEmailErr, setEmailErr] = useState("");

  const db = useFirestore();

  const location = useLocation();
  const blog = location.state ? location.state.blog : {};
  const [showSpinner, setShowSpinner] = React.useState(false);
  const handleReset = () => {
    window.location.reload();
    setEmployee(initialEmployee);
  };

  // feild empty err hooks
  const [showTitleErr, setShowTitleErr] = React.useState(false);
  const [showDescErr, setShowDescErr] = React.useState(false);
  const [showLocationErr, setShowLocationErr] = React.useState(false);
  const [showCityErr, setShowCityErr] = React.useState(false);
  const [showPinErr, setShowPinErr] = React.useState(false);
  const [showQualErr, setShowQualErr] = React.useState(false);
  const [showSourceErr, setShowSourceErr] = React.useState(false);
  const [showDeptErr, setShowDeptErr] = useState(false);
  const [showContactPerErr, setShowContactPerErr] = useState(false);
  const [showStateErr, setShowStateErr] = useState(false);

  const [nshList, setNshList] = useState({});
  const [rshList, setRshList] = useState({});
  const [cshList, setCshList] = useState([]);

  const [salesUser, setSalesUser] = useState({ nsh: "", rsh: "", csh: "" });

  useEffect(() => {
    getSalesUserFromRole("National Sales Head").then((users) => {
      let usersObj = users.reduce((acc, curr, i) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      console.log(usersObj);

      setNshList(usersObj);
    });

    setEmployee({
      ...employee,
      name: blog.name,
      email: blog.email,
      phone: blog.phone,
    });
  }, []);

  //priviledges

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        backgroundColor: "#fff",
        color: "#000",
      },
    },
  };

  const initialEmployee = {
    name: "",
    email: "",
    phone: "",
    address: "",
    indianState: "",
    city: "",
    pincode: "",
    source: "",
    contactPerson: "",
    departmentName: "",
  };
  const [employee, setEmployee] = useState(initialEmployee);

  const {
    name,
    email,
    phone,
    address,
    indianState,
    city,
    pincode,
    source,
    contactPerson,
    departmentName,
  } = employee;

  const onChange = (e, field) => {
    console.log(e.target.name);
    setEmployee({ ...employee, [`${field}`]: e.target.value });
    switch (e.target.name) {
      case "phone":
        if (!validatePhone(e.target.value)) {
          setMobileErr("Please Enter Valid Phone number");
        } else {
          setMobileErr("");
        }
        break;

      case "email":
        if (!validateEmail(e.target.value)) {
          setEmailErr("Please Enter Valid Email");
        } else {
          setEmailErr("");
        }
        break;

      default:
        break;
    }
  };

  const SendLeadsToNSH = async (e) => {
    e.preventDefault();

    if (email === "" || name === "" || phone === "" || address === "") {
      alert("Please enter all fields");
    } else if (showEmailErr !== "" || showMobileErr !== "") {
      console.log("Email or phone is wrong");
      return false;
    } else if (city === "") {
      setShowCityErr(true);
    } else if (!indianState) {
      setShowStateErr(true);
    } else if (pincode === "") {
      setShowPinErr(true);
    } else if (source === "") {
      setShowSourceErr(true);
    } else if (contactPerson === "") {
      setShowContactPerErr(true);
    } else if (departmentName === "") {
      setShowDeptErr(true);
    } else {
      setShowDeptErr(false);
      setShowContactPerErr(false);
      setShowPinErr(false);
      setShowCityErr(false);
      setShowStateErr(false);
      setShowSourceErr(false);

      setShowSpinner(true);
      let lat = "",
        lng = "";

      const completeAddress =
        name +
        ", " +
        address +
        ", " +
        city +
        ", " +
        indianState.substr(0, indianState.length - 5) +
        ", India, " +
        pincode;
      try {
        const location = await getLocationUsingAddress({
          address: completeAddress,
        });
        if (location.status == 200) {
          lat = location.lat;
          lng = location.lng;
          console.log("lat : ", lat, "lng : ", lng);

          const status = await saveLeadsData({
            ...salesUser,
            personalemail: email,
            address: address,
            city: city,
            companyName: name,
            mobileno: phone,
            pincode: pincode,
            se: "",
            source: source,
            state: indianState,
            status: "cold",
            lat: lat,
            lng: lng,
            contactPerson,
            departmentName,
          });

          if (status.successfull) {
            sendLeadNotification({ leadId: status.leadId });
            // Delete Contact after succesfull Addition of lead
            // await deleteContactBlog(blog.id);

            setShowSpinner(false);
            swal("Success!", "Lead Added", "success").then((value) =>
              handleReset()
            );
          } else {
            setShowSpinner(false);
            swal("Failed!", "Failed to add Lead. Try Again!!!", "error");
          }
        }
      } catch (e) {
        setShowSpinner(false);
        swal(
          "Failed!",
          "Failed to fetch Location. Please make sure your address is correct and use Comma (,) to seperate landmarks / streets / town.",
          "error"
        );
      }
    }
  };

  // email validation
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  // phone validation
  const validatePhone = (mob) => {
    const re = /^[0-9]{10}$/;
    return re.test(mob);
  };

  // custom button

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  const SelectNSH = () => {
    return (
      <TextField
        required
        select
        label="Assign NSH"
        value={salesUser.nsh}
        onChange={async (e) => {
          setSalesUser({ nsh: e.target.value, rsh: "", csh: "" });
          let rshData = {};
          if (nshList[e.target.value].RSH)
            for (let val of nshList[e.target.value].RSH) {
              const temp = await getSalesUserFromId(val);
              if (temp.id) rshData[temp.id] = temp;
            }
          setRshList(rshData);
        }}
        variant="outlined"
        style={{ marginBottom: "10px", textAlign: "left" }}
      >
        {Object.keys(nshList).map((option) => (
          <MenuItem key={nshList[option].id} value={nshList[option].id}>
            {nshList[option].email}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const SelectRSH = () => {
    return (
      <TextField
        required
        select
        label="Assign RSH"
        value={salesUser.rsh}
        onChange={async (e) => {
          setSalesUser({ ...salesUser, rsh: e.target.value, csh: "" });
          let cshData = [];
          if (rshList[e.target.value].CSH)
            for (let val of rshList[e.target.value].CSH) {
              const temp = await getSalesUserFromId(val);
              if (temp.id) cshData.push(temp);
            }
          setCshList(cshData);
        }}
        variant="outlined"
        style={{ marginBottom: "10px", textAlign: "left" }}
      >
        {Object.keys(rshList).map((option) => (
          <MenuItem key={rshList[option].id} value={rshList[option].id}>
            {rshList[option].email}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const SelectCSH = () => {
    return (
      <TextField
        required
        select
        label="Assign CSH"
        value={salesUser.csh}
        onChange={(e) => {
          setSalesUser({ ...salesUser, csh: e.target.value });
        }}
        variant="outlined"
        style={{ marginBottom: "10px", textAlign: "left" }}
      >
        {cshList.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.email}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  return (
    <>
      <Layout />
      <div className="container mt-5 pt-5">
        {/* <h2 style={{ textAlign: "center" }}>{getPageName()}</h2> */}

        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6">
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  Send Leads To Sales Users
                </Typography>
                <form className={classes.form} noValidate autoComplete="off">
                  <TextField
                    id="outlined-basic"
                    label="Lead Company Name*"
                    name="name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => onChange(e, "name")}
                    error={showTitleErr}
                    helperText={showTitleErr && "Please Enter Name"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Email*"
                    name="email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => onChange(e, "email")}
                    error={showDescErr}
                    helperText={showDescErr && "Please Enter Email"}
                  />
                  <span className={classes.errMsg}>{showEmailErr}</span>

                  <TextField
                    variant="outlined"
                    aria-label="empty textarea"
                    label="Phone*"
                    name="phone"
                    value={phone}
                    onChange={(e) => onChange(e, "phone")}
                    error={showQualErr}
                    helperText={showQualErr && "Please Enter Phone"}
                  />
                  <span className={classes.errMsg}>{showMobileErr}</span>

                  <TextField
                    id="outlined-basic"
                    label="Company Address*"
                    variant="outlined"
                    name="address"
                    onChange={(e) => onChange(e, "address")}
                    error={showLocationErr}
                    helperText={showLocationErr && "Please Enter Address"}
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="City*"
                    variant="outlined"
                    name="city"
                    onChange={(e) => onChange(e, "city")}
                    error={showCityErr}
                    helperText={showCityErr && "Please Enter City"}
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    required
                    fullWidth
                    id="outlined-indian-state"
                    select
                    label="State"
                    value={employee.indianState}
                    onChange={(e) => onChange(e, "indianState")}
                    variant="outlined"
                    style={{ marginBottom: "10px", textAlign: "left" }}
                  >
                    {indianStates.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-basic"
                    label="Pin Code*"
                    variant="outlined"
                    name="pincode"
                    onChange={(e) => onChange(e, "pincode")}
                    error={showPinErr}
                    helperText={showPinErr && "Please Enter Pin Code"}
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Reference*"
                    variant="outlined"
                    name="source"
                    onChange={(e) => onChange(e, "source")}
                    error={showSourceErr}
                    helperText={showSourceErr && "Please Enter Source"}
                    style={{ marginBottom: "10px" }}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Contact Person*"
                    variant="outlined"
                    name="contactPerson"
                    onChange={(e) => onChange(e, "contactPerson")}
                    error={showContactPerErr}
                    helperText={
                      showContactPerErr && "Please Enter Contact Person Name"
                    }
                    style={{ marginBottom: "10px" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Department name*"
                    variant="outlined"
                    name="departmentName"
                    onChange={(e) => onChange(e, "departmentName")}
                    error={showDeptErr}
                    helperText={showDeptErr && "Please Enter Department"}
                    style={{ marginBottom: "10px" }}
                  />

                  <SelectNSH />
                  {salesUser.nsh && <SelectRSH />}
                  {salesUser.rsh && <SelectCSH />}
                </form>
              </CardContent>
              <div>
                {showSpinner ? (
                  <div>
                    <span style={{ marginLeft: "10px", color: "#ff0000" }}>
                      <h5>Please DO NOT REFRESH the page...</h5>
                    </span>
                    <CircularProgress
                      style={{
                        color: "#18D26E",
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <CardActions
                style={{ justifyContent: "center", fontWeight: "bold" }}
              >
                <ColorButton
                  disabled={showSpinner}
                  variant="contained"
                  color="primary"
                  onClick={SendLeadsToNSH}
                >
                  Send
                </ColorButton>
              </CardActions>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendLeadsToSales;
