import {
  billingCycleTypes,
  clientModelTypes,
  costCenterTypes,
} from "../../common/const";

export const clientModelTypesOptions = [
  {
    value: clientModelTypes.model1,
    label: "Model 1",
  },
  {
    value: clientModelTypes.model2,
    label: "Model 2",
  },
  {
    value: clientModelTypes.model3,
    label: "Model 3",
  },
];

export const billingCycleOptions = [
  {
    value: billingCycleTypes.weekly,
  },
  {
    value: billingCycleTypes.twice_a_month,
  },
  {
    value: billingCycleTypes.monthly,
  },
];

const costCenterType1 = [
  {
    value: costCenterTypes.pan_India_category,
  },
  {
    value: costCenterTypes.pan_India_department,
  },
];

const costCenterType2 = [
  ...costCenterType1,
  {
    value: costCenterTypes.branch_category,
  },
  {
    value: costCenterTypes.branch_department,
  },
];

const costCenterType3 = [
  ...costCenterType2,
  {
    value: costCenterTypes.regional_category,
  },
  {
    value: costCenterTypes.regional_department,
  },
  {
    value: costCenterTypes.city_cluster_category,
  },
  {
    value: costCenterTypes.city_cluster_department,
  },
];

export const getCostCenterTypeOptions = (modelType) => {
  switch (modelType) {
    case clientModelTypes.model1:
      return costCenterType1;
    case clientModelTypes.model2:
      return costCenterType2;
    case clientModelTypes.model3:
      return costCenterType3;
    default:
      return [];
  }
};
