import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getProductsCategoriesDetails } from "../../backend/getDataFromFirestore";
import Layout from "../../common/Layout";
import { ColorButton } from "../../common/Components/ColorButton";
import { MenuItem, TextField } from "@material-ui/core";
import RateContractProvider from "../../common/RateContractProvider";
import { ProductsTable } from "./Components/ProductsTable";
import SaveSelectedButton from "./Components/SaveProducts";
import { QuotationProductsTable } from "./Components/QuotationProductsTable";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    boxShadow: "0 5px 10px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.21)",
  },
  form: {
    margin: "15px 15px 15px 15px",
    borderRadius: "10px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    width: "69ch",
  },
  margin: {
    margin: theme.spacing(1),
  },
  stepperColor: {
    color: "#18D26E",
  },
  errMsg: {
    color: "red",
    fontWeight: "bold",
    fontSize: "small",
    textAlign: "left",
  },
  products: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  category: {
    width: "29ch",
    marginRight: "2em",
    paddingBottom: "10px",
  },
  button: {
    margin: "1em",
    width: "20ch",
  },
}));

const ViewQuotation = () => {
  const classes = useStyles();
  const location = useLocation();

  let prospectId = location.state.prospectId ?? "";
  let prospectName = location.state.prospectName ?? "";
  const prospectDetails = { prospectId, prospectName };

  let quotationProducts = location.state.quotationProducts ?? {};
//   let noOfQuotation = location.state.noOfQuotation ?? 1;

  const [isPAN, setIsPan] = useState(false);
  const [version, setVersion] = useState("");
  const [city, setCity] = useState("");
  const [citiesSRPM, setCitiesSRPM] = useState({});

  const [category, setCategory] = useState({});
  const [categoryDetails, setCategoryDetails] = useState({
    category: "",
    subCategory: "",
  });

  const [cities, setCities] = useState({});
  const [showProducts, setShowProducts] = useState(false);
  const [editProducts, setEditProducts] = useState(false);

  async function getCitiesAndSelectedProducts(versionName) {
    let newCities = {};
    newCities = quotationProducts[versionName];
    setCities(newCities);
  }

  async function citiesForSRPM(cityName) {
    //setCitiesSRPM
    let citiesObj = {};
    if (cityName === "PAN-Products") {
      citiesObj["PAN-Products"] = {};
    } else {
      for (let city of Object.keys(quotationProducts[version])) {
        citiesObj[city] = {};
      }
    }
    setCitiesSRPM(citiesObj);
  }

  async function getCategories() {
    let temp = await getProductsCategoriesDetails();
    setCategory(temp);
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h3 className="text-center">Product List</h3>
        <RateContractProvider cityProducts={cities} cities={citiesSRPM}>
          <form className={classes.form} noValidate autoComplete="off">
            <div>
              <TextField
                required
                id="outlined-branches"
                select
                label="Select Versions"
                variant="outlined"
                className={classes.category}
                onChange={(e) => {
                  setVersion(e.target.value);
                  setCity("");
                  getCitiesAndSelectedProducts(e.target.value);
                  setShowProducts(false);
                  setEditProducts(false);
                }}
              >
                {Object.keys(quotationProducts).map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                required
                id="outlined-branches"
                select
                label="Select City"
                variant="outlined"
                className={classes.category}
                disabled={version === ""}
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  citiesForSRPM(e.target.value);
                  e.target.value === "PAN-Products"
                    ? setIsPan(true)
                    : setIsPan(false);
                  setShowProducts(false);
                  setEditProducts(false);
                }}
              >
                {version !== ""
                  ? Object.keys(quotationProducts[version]).map((option, i) => (
                      <MenuItem key={i} value={option}>
                        {option}
                      </MenuItem>
                    ))
                  : null}
              </TextField>

              <TextField
                id="outlined-category"
                select
                label="Type of Category"
                variant="outlined"
                className={classes.category}
                onChange={(e) => {
                  setCategoryDetails({
                    ...categoryDetails,
                    category: e.target.value,
                  });
                  setShowProducts(false);
                  setEditProducts(false);
                }}
              >
                {Object.keys(category).map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                disabled={categoryDetails.category === ""}
                required
                id="outlined-sub-category"
                select
                label="Type of Sub-Category"
                variant="outlined"
                className={classes.category}
                onChange={(e) => {
                  setCategoryDetails({
                    ...categoryDetails,
                    subCategory: e.target.value,
                  });
                  setShowProducts(false);
                  setEditProducts(false);
                }}
              >
                {category[categoryDetails.category] ? (
                  category[categoryDetails.category].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value={""}>
                    No Option
                  </MenuItem>
                )}
              </TextField>

              <ColorButton
                variant="contained"
                color="primary"
                disabled={!city}
                onClick={(e) => {
                  e.preventDefault();
                  setShowProducts(true);
                  setEditProducts(false);
                }}
              >
                Get Products
              </ColorButton>
              <ColorButton
                variant="contained"
                style={{ marginLeft: "10px" }}
                color="primary"
                disabled={!city || !version}
                onClick={(e) => {
                  e.preventDefault();
                  setShowProducts(false);
                  setEditProducts(true);
                }}
              >
                Edit Version
              </ColorButton>
              <SaveSelectedButton
                prospectDetails={prospectDetails}
                buttonLocation={"View Quotation"}
                selectedVersion={version}
                selectedCity={city}
                editProductsStatus={editProducts}
                isPAN={isPAN}
              />
            </div>
          </form>

          {showProducts && city && (
            <>
              <div className="row align-items-center justify-content-center mt-5">
                <QuotationProductsTable
                  versionName={version}
                  isPAN={isPAN}
                  city={city}
                  category={categoryDetails.category}
                  subCategory={categoryDetails.subCategory}
                  prospectDetails={prospectDetails}
                  onlyShow={true}
                />
              </div>
            </>
          )}
          {editProducts && city && (
            <>
              <div className="row align-items-center justify-content-center mt-5">
                <ProductsTable
                  isPAN={isPAN}
                  city={city}
                  category={categoryDetails.category}
                  subCategory={categoryDetails.subCategory}
                  prospectDetails={prospectDetails}
                  onlyShow={false}
                />
              </div>
            </>
          )}
        </RateContractProvider>
      </div>
    </Layout>
  );
};

export default ViewQuotation;
