import React, { useEffect, useState } from "react";
import CommonTable from "../../../common/Table";
import { ActionCol } from "../../../common/Table/ActionCol";
import AdvancedSearch from "common/AdvancedSearch/AdvancedSearch";
import { useFirestore } from "reactfire";
import { useGetPermissions } from "context/PermissionsProvider";
import EditCareer from "../EditCareer/EditCareer";

const advancedSearchConfig = {
  searchProps: {
    searchTitle: "Job Desc, Title, etc...",
    searchByOptions: ["jobDesc", "title"],
  },
};

export function ViewCareerTable() {
  const { userPermissions } = useGetPermissions();
  const db = useFirestore();

  const [careers, setCareers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [editCareerData, setEditCareerData] = useState(null);

  const canEditCareer = userPermissions?.["Career"]?.Edit;
  const canDeleteCareer = userPermissions?.["Career"]?.Delete;

  const fetchCareers = async () => {
    const response = db.collection("Careers").orderBy("title");
    const data = await response.get();
    const careersData = [];
    data.docs.forEach((item) => {
      careersData.push(item.data());
    });
    setCareers(careersData);
  };

  useEffect(() => {
    fetchCareers();
  }, []);

  const handleEdit = (blogData) => {
    setEditCareerData(blogData);
  };

  const handleDelete = async (careerData) => {
    if (!careerData?.id) return;
    await db.collection("Careers").doc(careerData?.id).delete();
    window.location.reload();
  };

  const columnsData = [
    {
      headerName: "Career Title",
      field: "title",
      width: 180,
    },
    {
      headerName: "Job Description",
      field: "jobDesc",
      width: 180,
    },
    {
      headerName: "Location",
      field: "location",
      width: 210,
    },
    {
      headerName: "Qualifications",
      field: "qual",
      width: 210,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 190,
      renderCell: ({ row }) => (
        <ActionCol
          data={row}
          onDelete={canDeleteCareer ? handleDelete : null}
          onEdit={canEditCareer ? handleEdit : null}
        />
      ),
    },
  ];

  if (!careers?.length) {
    return (
      <div className="row justify-content-center">
        <p>No lead's yet!</p>
      </div>
    );
  }

  return (
    <div>
      <EditCareer
        open={!!editCareerData}
        editCareerDetails={editCareerData}
        onClose={() => setEditCareerData(null)}
      />
      <AdvancedSearch
        data={careers}
        searchConfig={advancedSearchConfig}
        setFilteredData={setFilteredData}
      />
      <CommonTable data={filteredData} cols={columnsData} />
    </div>
  );
}
