import React, { useEffect, useState } from "react";
import { Media } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Layout from "../../common/Layout";
import { useFirestoreCollectionData, useUser } from "reactfire";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import swal from "sweetalert";
import "firebase/firestore";
import "firebase/auth";
import firebase from "firebase/app";

import { useFirebaseApp, useFirestore } from "reactfire";
import "firebase/storage";
import { useHistory } from "react-router-dom";
import {
  assignLeadToCSH,
  assignLeadToRSH,
  sendLeadNotification,
} from "../../backend/sendRequestToServer";
import { BlurOff } from "@material-ui/icons";
import { getPageName } from "../../common/const";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { deleteLeadWithID } from "../../backend/deleteDataFromFirestore";
import {
  getSalesUserFromId,
  getSalesUserFromRole,
} from "../../backend/getDataFromFirestore";
import { ColorButton } from "../../common/Components/ColorButton";
import { updateLeadData } from "../../backend/editDataInFirestore";

// import firebase from 'firebase'
// import firebase from "firebase"

const SocialMediaLeads = () => {
  const [rshList, setRshList] = useState({});
  const [cshList, setCshList] = useState({});
  const [salesUser, setSalesUser] = useState({ rsh: "", csh: "" });

  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [filterStatus, setFilterStatus] = useState("All");
  const [showSpinner, setShowSpinner] = React.useState(false);

  const [selectedLead, setSelectedLead] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [openAssign, setOpenAssign] = React.useState(false);

  const db = useFirestore();
  const storage = useFirebaseApp().storage();
  let history = useHistory();
  var tempRSH = "";
  var emailtest = localStorage.getItem("email");

  const [leads, setLeads] = useState([]);
  const docId = localStorage.getItem("userDocId"); // Doc Id of NSH

  const leadsRef = useFirestore()
    .collection("Leads")
    .where("source", "in", ["Instagram", "facebook", "linkedin"]);
  const { status, data } = useFirestoreCollectionData(leadsRef);

  useEffect(() => {
    console.log("It rendered");
    if (data) {
      setLeads(data);
      setSearchResult(searchLeadResult(searchValue, filterStatus));
    }
  }, [data]);

  useEffect(() => {
    fetchRSH();
  }, []);

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const handleReset = () => {
    window.location.reload();
  };

  const AssignLeadRSH = async (e) => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    //   if (user) {
    //     console.log("This is the user: ", user.email);
    //     emailtest = user.email;

    console.log("aa", e);
    // console.log("bb", indianState);
    console.log("cc", emailtest);
    const swalRes = await swal(
      "Confirm RSH",
      "Do you want to assign this RSH for this Lead ?",
      "info",
      {
        buttons: {
          cancel: "No",
          catch: {
            text: "Yes",
            value: true,
          },
        },
      }
    );

    if (!swalRes) {
      return;
    }

    swal(
      "Assigning Lead",
      "Updating RSH for Lead . Please do not refresh this page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );

    const status = await assignLeadToRSH({
      personalemail: e.personalemail,
      nsh: e.nsh,
      rsh: e.rsh,
    });

    swal.close();

    if (status === 200) {
      setShowSpinner(false);
      swal("Success!", "Lead Assigned", "success");
    } else {
      setShowSpinner(false);
      swal("Failed!", "Failed to assign Lead. Try Again!!!", "error");
    }
    //   } else {
    //     // No user is signed in.
    //     console.log("There is no logged in user");
    //   }
    // });
  };

  const AssignLeadCSH = async (e) => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    //   if (user) {
    //     console.log("This is the user: ", user.email);
    //     emailtest = user.email;

    console.log("aa", e);
    // console.log("bb", indianState);
    console.log("cc", emailtest);
    const swalRes = await swal(
      "Confirm CSH",
      "Do you want to assign this CSH for this Lead ?",
      "info",
      {
        buttons: {
          cancel: "No",
          catch: {
            text: "Yes",
            value: true,
          },
        },
      }
    );

    if (!swalRes) {
      return;
    }

    swal(
      "Assigning Lead",
      "Updating CSH for Lead . Please do not refresh this page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );

    const status = await assignLeadToCSH({
      personalemail: e.personalemail,
      csh: e.csh,
    });

    swal.close();

    if (status === 200) {
      setShowSpinner(false);
      swal("Success!", "Lead Assigned", "success");
    } else {
      setShowSpinner(false);
      swal("Failed!", "Failed to assign Lead. Try Again!!!", "error");
    }
    //   } else {
    //     // No user is signed in.
    //     console.log("There is no logged in user");
    //   }
    // });
  };

  // custom button

  //  const [employee, setEmployee] = useState({
  //     indianState: "",
  //   });

  //   const { indianState } = employee;

  const onChange = (e, id) => {
    // console.log(e.target.name);
    // setEmployee({ ...employee, [`${field}`]: e.target.value });

    setLeads(
      leads.map((lead) => {
        if (lead.id === id) {
          lead.rsh = e.target.value;
        }
        return lead;
      })
    );
  };

  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: "#FCBF00",
      color: "white",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: "#FCBF00",
      },
    },
  }))(Button);

  const fetchRSH = async () => {
    //Users Created by NSH are RSH... createdby field has RSH Id.
    console.log(docId);
    db.collection("Users")
      .where("designation", "==", "Regional Sales Head")
      .where("userDeleted", "==", false)
      .get()
      .then((querySnapshot) => {
        let tempUserEmails = {};
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          console.log("I am here", doc.data().region);
          tempUserEmails[doc.id] = doc.data();

          //   indianStates.push({ value: doc.data().email });

          //   console.log(indianStates);
        });
        setRshList(tempUserEmails);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  //  View selected lead btn click
  const handleViewBtn = (lead_detail) => {
    console.log(lead_detail);
    history.push("/lead-detail", lead_detail);
  };

  //  Search & Filter in textbox
  const searchLeadResult = (name, status) => {
    return data.filter((lead) => {
      
      if (lead && status !== 'All') {
        return (
          lead.companyName.toLowerCase().includes(name.toLowerCase()) &&
          lead.status.toLowerCase() === status.toLowerCase()
        );
      }
      if (name || status === "All") {
        return lead.companyName.toLowerCase().includes(name.toLowerCase());
      }
      if (status) {
        return lead.status.toLowerCase() === status.toLowerCase();
      }
      return true;
    });
  };
  const search = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setSearchResult(searchLeadResult(e.target.value, filterStatus));
  };

  // filter
  const onFilter = (status) => {
    console.log(status);
    setFilterStatus(status);
    setSearchResult(searchLeadResult(searchValue, status));
  };

  function editLead(lead) {
    history.push("/edit-lead", lead);
  }

  function deleteLead(lead) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Lead!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal({
          title: "Deleting the Lead. Do not Refresh",
          icon: "warning",
          buttons: false,
          dangerMode: true,
        });

        const res = await deleteLeadWithID(lead.id);

        if (res.successfull) {
          swal("Success", "Lead Successfully Deleted!!!", "success");
        } else {
          swal(
            "Error",
            "Error Occur while Deleting. Please Try Again.",
            "error"
          );
        }
      }
    });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAssign = () => {
    setOpenAssign(false);
  };

  // table view
  const LeadsTableView = ({ leads }) => {
    return (
      <div className="row align-items-center">
        <Table key="LeadTable">
          <Thead>
            <Tr>
              <Th>Company Name</Th>
              <Th>Email</Th>
              <Th>City</Th>
              <Th>Source</Th>
              <Th>Assigned To</Th>

              <Th>Assgin</Th>

              {/* <Th>Action</Th> */}
              <Th>Details</Th>
            </Tr>
          </Thead>
          <Tbody>
            {leads.map((lead, idx) => {
              return (
                <Tr key={idx}>
                  <Td>{lead.companyName}</Td>
                  <Td>{lead.personalemail}</Td>
                  <Td>{lead.city}</Td>
                  <Td>{lead.source}</Td>
                  <Td>{rshList[lead.rsh] ? rshList[lead.rsh]["email"] : ""}</Td>

                  <Td>
                    <ColorButton
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        setOpenAssign(true);
                        setSelectedLead(lead);
                      }}
                    >
                      {" "}
                      Assign
                    </ColorButton>
                  </Td>

                  {/* <Td>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => deleteLead(lead)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      aria-label="edit"
                      id={lead.id}
                      onClick={(e) => editLead(lead)}
                    >
                      <CreateIcon />
                    </IconButton>
                    <ColorButton
                      variant="contained"
                      className={classes.button}
                      onClick={() => AssignLead(lead)}
                    >
                      {" "}
                      Assign
                    </ColorButton>
                  </Td> */}
                  <Td>
                    <ColorButton
                      variant="contained"
                      className={classes.button}
                      onClick={() => {
                        setOpen(true);
                        setSelectedLead(lead);
                      }}
                    >
                      {" "}
                      View
                    </ColorButton>
                  </Td>
                </Tr>
              );
            })}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"View Lead Details"}
              </DialogTitle>
              <DialogContent>
                <LeadCard lead={selectedLead} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </Tbody>
        </Table>

        <Dialog
          open={openAssign}
          onClose={handleCloseAssign}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Assign Lead"}</DialogTitle>
          <AssignContent
            lead={selectedLead}
            rshList={rshList}
            handleCloseAssign={handleCloseAssign}
          />
        </Dialog>
      </div>
    );
  };

  return (
    <Layout>
      <div className="container mt-5 pt-5">
        <h2 style={{ textAlign: "center" }}>View Leads</h2>

        <div className="row pb-3 justify-content-center align-items-center">
          <div className="col-10 col-md-10">
            <input
              type="text"
              className="search-box"
              placeholder="Search Lead"
              onChange={(e) => {
                search(e);
              }}
              style={{ width: "275px", height: "40px" }}
            />
          </div>
          <div className="col col-md-2 mt-2 float-right">
            <TextField
              required
              id="outlined-indian-state"
              select
              label="Status"
              // value={employee.indianState}
              // onClick={AssignDrop()}
              value={filterStatus}
              onChange={(e) => onFilter(e.target.value)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              // SelectProps={{
              //   classes: {
              //     root: classes.selectRoot,
              //     select: classes.select,
              //   },
              // }}
              style={{ width: "11ch", float: "right", verticalAlign: "center" }}
            >
              <MenuItem key="All" value="All">
                All
              </MenuItem>
              <MenuItem key="Warm" value="Warm">
                Warm
              </MenuItem>
              <MenuItem key="Cold" value="Cold">
                Cold
              </MenuItem>
              <MenuItem key="Lost" value="Lost">
                Lost
              </MenuItem>
              <MenuItem key="Hot" value="Hot">
                Hot
              </MenuItem>
            </TextField>
          </div>
        </div>

        {leads.length === 0 ? (
          <div className="row justify-content-center">
            <p>No lead's yet!</p>
          </div>
        ) : (
          <>
            {leads.length > 0 && filterStatus === "" && searchValue === "" ? (
              <LeadsTableView leads={leads} />
            ) : (
              <>
                {searchResult.length > 0 ? (
                  <LeadsTableView leads={searchResult} />
                ) : (
                  <div className="row pb-3 justify-content-center">
                    <div>Search Not Found!</div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default SocialMediaLeads;

const LeadCard = ({ lead }) => {
  const [emails, setEmails] = useState({
    nsh: "",
    rsh: "",
    csh: "",
    se: "",
  });

  const [checkMail, setCheckMail] = useState(false);

  async function getEmails() {
    let nshData = await getSalesUserFromId(lead.nsh);
    let rshData = await getSalesUserFromId(lead.rsh);
    let cshData = await getSalesUserFromId(lead.csh);
    let seData = await getSalesUserFromId(lead.se);

    setEmails({
      nsh: nshData ? nshData.email : "",
      rsh: rshData ? rshData.email : "",
      csh: cshData ? cshData.email : "",
      se: seData ? seData.email : "",
    });

    setCheckMail(true);
  }
  const myEmail = localStorage.getItem("email");
  return (
    <div key={lead.id} style={{ width: "275px", padding: "2px" }}>
      <p className="author-name">Company Name:{lead.companyName}</p>
      <p>Email: {lead.personalemail}</p>
      <p>Phone: {lead.mobileno}</p>
      <p>Status: {lead.status}</p>
      <p>Address: {lead.address}</p>
      <p>City: {lead.city}</p>
      <p>State: {lead.state}</p>
      <p>Pincode: {lead.pincode}</p>
      <p>Source: {lead.source}</p>
      <p>
        Contact Person:{" "}
        {lead.contactPerson === undefined ? "" : lead.contactPerson}
      </p>
      <p>
        Department Name:{" "}
        {lead.departmentName === undefined ? "" : lead.departmentName}
      </p>

      <p>Lead Converted: {lead.converted ? "Yes" : "No"}</p>
      {!checkMail ? (
        <ColorButton onClick={getEmails}>Show Lead Refrences</ColorButton>
      ) : (
        <span>
          <p>NSH: {emails.nsh}</p>
          <p>RSH: {emails.rsh}</p>
          <p>CSH: {emails.csh}</p>
          <p>SE: {emails.se}</p>
        </span>
      )}

      <br />
    </div>
  );
};

const AssignContent = ({ lead, rshList, handleCloseAssign }) => {
  const [cshList, setCshList] = useState({});

  const [salesUser, setSalesUser] = useState({
    rsh: lead.rsh ?? "",
    csh: lead.csh ?? "",
  });

  async function getCSHList(rsh) {
    let cshData = {};
    if (rshList[rsh] && rshList[rsh].CSH)
      for (let val of rshList[rsh].CSH) {
        const temp = await getSalesUserFromId(val);
        if (temp.id) {
          cshData[temp.id] = temp;
        }
      }
    console.log(cshData);
    setCshList(cshData);
  }

  useEffect(() => {
    if (lead.rsh) getCSHList(lead.rsh);
  }, []);

  async function assignRshAndCsh() {
    if (!salesUser.rsh && !salesUser.csh) {
      return;
    }
    const swalRes = await swal(
      "Confirm RSH and CSH",
      "Do you want to assign this RSH and CSH for this Lead ?",
      "info",
      {
        buttons: {
          cancel: "No",
          catch: {
            text: "Yes",
            value: true,
          },
        },
      }
    );

    if (!swalRes) {
      return;
    }

    swal(
      "Assigning Lead",
      "Updating RSH and CSH for Lead . Please do not refresh this page",
      "info",
      {
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: false,
      }
    );

    let status1, status2;

    const res = await updateLeadData(
      lead,
      rshList[salesUser.rsh]["createdby"],
      salesUser.rsh,
      salesUser.csh
    );

    swal.close();

    if (res.successfull) {
      sendLeadNotification({ leadId: lead.id });

      swal("Success!", "Lead Assigned", "success");
      handleCloseAssign();
    } else {
      swal("Failed!", "Failed to assign Lead. Try Again!!!", "error");
    }
  }
  return (
    <div>
      <DialogContent>
        <TextField
          fullWidth
          required
          select
          label="Assign RSH"
          value={salesUser.rsh}
          onChange={async (e) => {
            // AssignLeadRSH({
            //   ...lead,
            //   nsh: rshList[e.target.value]["createdBy"],
            //   rsh: e.target.value,
            // });
            // setSalesUser({ ...salesUser, rsh: e.target.value, csh: "" });
            getCSHList(e.target.value);
            setSalesUser({ rsh: e.target.value, csh: "" });
          }}
          variant="outlined"
          style={{ marginBottom: "10px", textAlign: "left" }}
        >
          {Object.keys(rshList).map((option) => (
            <MenuItem key={rshList[option].id} value={rshList[option].id}>
              {/* {rshList[option].email} */}

              {`${rshList[option]["email"]} (${rshList[option]["region"]})`}
            </MenuItem>
          ))}
        </TextField>
        <br />
        <TextField
          fullWidth
          required
          select
          label="Assign CSH"
          value={salesUser.csh}
          onChange={(e) => {
            setSalesUser({ ...salesUser, csh: e.target.value });

            // AssignLeadCSH({ ...lead, csh: e.target.value });
            // setSalesUser({ ...salesUser, csh: e.target.value });
          }}
          variant="outlined"
          style={{ marginBottom: "10px", textAlign: "left" }}
        >
          {Object.keys(cshList).map((option) => (
            <MenuItem key={option} value={option}>
              {cshList[option].email}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAssign}>Close</Button>
        <ColorButton onClick={assignRshAndCsh}>Assign</ColorButton>
      </DialogActions>
    </div>
  );
};
