import React, { useEffect, useState } from "react";
import DrawerWrapper from "../../../common/DrawerWrapper/DrawerWrapper";
import { TextField, Typography } from "@material-ui/core";
import {
  CancelButton,
  StyledCreateButton,
} from "../../../common/Components/ColorButton";
import MenuItem from "@material-ui/core/MenuItem";
import { getProfiles, getUsers } from "backend/getDataFromFirestore";
import { addEmployee } from "backend/sendRequestToServer";
import swal from "sweetalert";

const initialState = {
  name: "",
  email: "",
  phone: "",
  city: "",
  state: "",
  address: "",
  designation: "",
  profileId: "",
  reportingManagerId: "",
  reportingManagerName: "",
};

function AddNewEmployee({ open, onClose }) {
  const [employeeDetails, setEmployeeDetails] = useState(initialState);
  const [profiles, setProfiles] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [showSpinner, setShowSpinner] = React.useState(false);

  useEffect(() => {
    (async () => {
      let { status, data } = await getProfiles();
      if (status === 200) setProfiles(data);
    })();
    (async () => {
      let { status, data } = await getUsers();
      if (status === 200) setUsers(data);
    })();
  }, []);

  const onChange = (e, a) => {
    if (e.target.name === "reportingManagerId") {
      const userDetails = users.find(({ id }) => id === e.target.value);
      setEmployeeDetails({
        ...employeeDetails,
        reportingManagerId: userDetails?.id,
        reportingManagerName: userDetails?.name,
      });
      return;
    }
    setEmployeeDetails({ ...employeeDetails, [e.target.name]: e.target.value });
  };

  const addNewProfile = async () => {
    setShowSpinner(true);
    const status = await addEmployee(employeeDetails);

    if (status === 200) {
      setShowSpinner(false);
      swal("Success!", "Employee Added", "success").then((value) =>
        setEmployeeDetails(initialState)
      );
    } else {
      setShowSpinner(false);
      swal("Failed!", "Failed to create Employee. Try Again!!!", "error");
    }
  };

  return (
    <DrawerWrapper state={open} onClose={onClose}>
      <Typography variant="h5" className="mb-7 !font-semibold">
        Create Employee
      </Typography>
      <form className="flex flex-col" autoComplete="off" id="addEmpForm">
        <div className="mt-6">
          <div className="mb-6">
            <TextField
              fullWidth
              size="small"
              label="Name*"
              name="name"
              value={employeeDetails.name}
              onChange={onChange}
            />
          </div>
          <div className="flex gap-6 mb-6">
            <TextField
              fullWidth
              size="small"
              label="Email*"
              name="email"
              value={employeeDetails.email}
              onChange={onChange}
            />
            <TextField
              fullWidth
              size="small"
              label="Phone*"
              name="phone"
              value={employeeDetails.phone}
              onChange={onChange}
            />
          </div>
          <div className="flex gap-6 mb-6">
            <TextField
              fullWidth
              size="small"
              label="City*"
              name="city"
              value={employeeDetails.city}
              onChange={onChange}
            />
            <TextField
              fullWidth
              size="small"
              label="State*"
              name="state"
              value={employeeDetails.state}
              onChange={onChange}
            />
          </div>
          <div className="mb-6">
            <TextField
              fullWidth
              size="small"
              label="Address*"
              name="address"
              value={employeeDetails.address}
              onChange={onChange}
            />
          </div>
        </div>
        <hr />
        <div className="mt-4">
          <div className="mb-6">
            <TextField
              fullWidth
              size="small"
              label="Designation*"
              name="designation"
              value={employeeDetails.designation}
              onChange={onChange}
            />
          </div>
          <div className="mb-6">
            {profiles?.length ? (
              <TextField
                select
                fullWidth
                size="small"
                label="Profile*"
                name="profileId"
                value={employeeDetails.profileId}
                onChange={onChange}
              >
                {profiles.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <p>Please add a Profile</p>
            )}
          </div>
          <div className="mb-6">
            {users?.length ? (
              <TextField
                select
                fullWidth
                size="small"
                label="Reporting Manager*"
                name="reportingManagerId"
                value={employeeDetails.reportingManagerId}
                onChange={onChange}
              >
                {users.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <p>Please add a User</p>
            )}
          </div>
        </div>
      </form>
      <div className="fixed bottom-0 mb-14 flex gap-1.5">
        <StyledCreateButton onClick={addNewProfile}>Create</StyledCreateButton>
        <CancelButton onClick={() => onClose()}>Cancel</CancelButton>
      </div>
    </DrawerWrapper>
  );
}

export default AddNewEmployee;
