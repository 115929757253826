import React from "react";
import { Avatar, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import DrawerWrapper from "../../../common/DrawerWrapper/DrawerWrapper";
import {
  CancelButton,
  StyledCreateButton,
} from "../../../common/Components/ColorButton";
import { OPEN_MODAL_TITLE } from "../constants.js";

function CaseStudyAdd({ open, onClose }) {
  const [caseStudy, setCaseStudy] = useState({});

  const onChange = (e) => {
    setCaseStudy({ ...caseStudy, [e.target.name]: e.target.value });
  };

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setCaseStudy({ ...caseStudy, [e.target.name]: image });
  };

  const handleAddCaseStudy = async () => {
    console.log(caseStudy);
  };

  return (
    <DrawerWrapper state={open} onClose={onClose}>
      <Typography variant="h5" className="!font-semibold !mb-7">
        {OPEN_MODAL_TITLE.ADD_CASE_STUDY}
      </Typography>
      <form
        className="flex flex-col gap-6"
        noValidate
        autoComplete="off"
        id="addCaseStudyForm"
      >
        <TextField label="Case Study Title*" name="title" onChange={onChange} />
        <TextField label="Author Name*" name="author" onChange={onChange} />
        <TextField
          label="Short Description*"
          name="short_desc"
          onChange={onChange}
        />
        <TextField
          label="Long Description*"
          name="long_desc"
          onChange={onChange}
        />
        <input
          accept="image/*"
          name="caseStudyImage"
          type="file"
          onChange={handleImageAsFile}
        />
        {!!caseStudy?.caseStudyImage && (
          <Avatar
            src={URL?.createObjectURL(caseStudy?.caseStudyImage)}
            alt={caseStudy?.author}
          />
        )}
      </form>
      <div className="fixed bottom-0 mb-14 flex gap-1.5">
        <StyledCreateButton onClick={handleAddCaseStudy}>
          Create
        </StyledCreateButton>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </div>
    </DrawerWrapper>
  );
}

export default CaseStudyAdd;
