import React, { useEffect, useState } from "react";

import XLSX from "xlsx";
import Layout from "../../common/Layout";
import ProductsTable from "./Components/ProductsTable";
import Typography from "@material-ui/core/Typography";

import { Button, MenuItem, TextField } from "@material-ui/core";
import { ColorButton } from "../../common/Components/ColorButton";
import { getPageName } from "././../../common/const";
import { getCitiesList } from "../../backend/getDataFromFirestore";

const Products = () => {
  let file = [];
  const [productData, setProductData] = useState([]);
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [title, setTitle] = useState("NexStoc Product Catalog");

  useEffect(() => {
    getCities();
  }, []);

  async function getCities() {
    const temp = await getCitiesList();
    setCities(temp);
  }

  const handleUpload = (event) => {
    file = event.target.files;
  };

  const handleData = (e) => {
    if (file.length < 1) {
      console.log("Choose Correct File...");
      return;
    }
    let skuIds = {};
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          const workbook = XLSX.read(reader.result, { type: "binary" });
          const firstSheet = workbook.SheetNames[0];
          let excelRows = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[firstSheet]
          );
          for (let row in excelRows) {
            if (skuIds[excelRows[row]["SKU ID"]]) {
              excelRows[row]["duplicate"] = true;
            } else {
              excelRows[row]["duplicate"] = false;
              skuIds[excelRows[row]["SKU ID"]] = 1;
            }
          }

          setProductData(excelRows);
        };
        reader.readAsBinaryString(file[0]);
      }
    } else {
      console.log("This browser does not support HTML5.");
    }
  };

  return (
    <>
      <Layout />
      <div className=" m-5 pt-5">
        <h2 style={{ textAlign: "center" }}>{getPageName()}</h2>
        <div className="row">
          <div className="col-12 col-md-12">
            <TextField
              required
              id="outlined-seller-aadhar"
              type="text"
              label="Enter Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />

            <TextField
              required
              id="outlined-city"
              select
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              variant="outlined"
              style={{ marginBottom: "10px", width: "30%" }}
            >
              {cities.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            <br />
            {/* <Typography variant="h5" component="h2" align="center">
              Add Products
            </Typography> */}
            <>
              <input type="file" accept=".xlsx" onChange={handleUpload} />

              <span>
                <ColorButton
                  size="small"
                  padding="10px"
                  variant="contained"
                  color="primary"
                  onClick={handleData}
                >
                  Extract Data
                </ColorButton>
              </span>

              <ProductsTable
                productData={productData}
                productLength={productData.length}
                city={city}
                title={title}
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
