import { getLocationUsingAddress } from "../backend/sendRequestToServer";

export const matchNavLabelToLink = {
  "Add Blog": "/add-blog",
  "View Blog": "/view-blog",
  "Add Career": "/AddCareer",
  "View Career": "/view-career",
  "Add Case Study and Testimonial": "/AddCaseStudy",
  "View Case Study": "/view-caseStudy",
  "View Testimonial": "/view-testimonial",
  "Add Franchise": "/add-franchise",
  "View Franchise": "/view-franchise",
  "Add E-Store": "/add-estore",
  "View E-Store": "/view-eStore",
  "Show Estore Requests": "/show-estore-requests",
  "Add Seller": "/add-seller",
  "View Seller": "/view-seller",
  // "Add Products": "/add-products",
  "Add Employees": "/add-employee",
  "View Employees": "/view-employee",
  "Add Designation": "/add-designation",
  // "Edit Employee": "/edit-employee",
  "Add National Sales Head": "/add-nsh",
  "View National Sales Head": "/view-nsh",
  "Deleted National Sales Head": "/deleted-nsh",

  "Add Regional Sales Head": "/add-rsh",
  "View Regional Sales Head": "/view-rsh",
  "Deleted Regional Sales Head": "/deleted-rsh",

  Marketing: "/marketing",
  // "Marketing Contact Us": "/marketing-contactus",
  "Marketing View Contact Us": "/marketing-view-contactus",
  "Add City Sales Head": "/add-csh",
  "View City Sales Head": "/view-csh",
  "Deleted City Sales Head": "/deleted-csh",

  "Add Sales Executive": "/add-se",
  "View Sales Executive": "/view-se",
  "Deleted Sales Executive": "/deleted-se",

  "Add City": "/add-city",
  "View City": "/view-city",
  "Send Leads To NSH": "/sendLeadsToNSH",
  "Get Leads": "/getLeads",
  "Get Leads From NSH": "/getLeadsfromnsh",
  "Get Leads From RSH": "/getLeadsfromrsh",
  "Approve Clients": "/",
  "Support Team": "/",
  "Send Lead to Sales": "/",
  "Sales List": "/",
  "Client List": "/",
  "Marketing Details": "/",
  "Get leads from Sales": "/",
  "Assigned Leads": "/assigned-leads",
  "Add Meeting": "/add-meeting",
  "View Meetings": "/view-meetings",
  "View Approved Clients": "/client-list",
  "View Client Approvals": "/approve-clients",
  "My Client Approvals": "/client-approval-list",
  "Show Products": "/show-products-average",
  "Show Seller Products": "/show-seller-products",
  "Add User Manager": "/add-user-manager",
  "Send lead to NSH": "/send-lead-to-nsh",
  "Add Lead": "/add-lead",
  "Send Leads To Sales User": "/send-leads-to-sales",
  "Add Prospect": "/add-prospect",
  "View Prospects": "/view-prospects",
  "Seller Products Requests": "/seller-products-requests",
  "Social Media Leads": "/social-media-leads",
  "Show Final Products": "/show-final-products",
  "Show SE Requests": "/show-se-requests",
  "Client Asking Price Approval": "/client-asking-price-approval",
  "Client Asking Price For SE": "/client-asking-price-for-se",

  "Add PAN Products": "/add-pan-products",
  "Add New Product": "/add-new-product",

  "Show Locations": "/show-locations",
  "Missed Meetings": "/missed-meetings",

  "Add Product Main Category": "/add-category",
  "Add Product Sub Category": "/add-subcategory",
  "View Product Categories": "/view-category",
  "View Divert Franchise Request": "/franchise-divert-request",
  "Approval From Franchise": "/approval-from-franchise",
};

export const matchNavLinkToLabel = {
  "/dashboard": "Dashboard",
  "/add-blog": "Add Blog",
  "/view-blog": "View Blog",
  "/AddCareer": "Add Career",
  "/view-career": "View Career",
  "/AddCaseStudy": "Add Case Study and Testimonial",
  "/view-caseStudy": "View Case Study",
  "/view-testimonial": "View Testimonial",
  "/add-franchise": "Add Franchise",
  "/view-franchise": "View Franchise",
  "/add-estore": "Add E-Store",
  "/view-eStore": "View E-Store",
  "/show-estore-requests": "Show Estore Requests",
  "/add-seller": "Add Seller",
  "/view-seller": "View Seller",
  // "/add-products": "Add Products",
  "/add-employee": "Add Employees",
  "/view-employee": "View Employees",
  "add-designation": "Add Designation",
  "/edit-employee": "Edit Employee",
  "/add-nsh": "Add National Sales Head",
  "/view-nsh": "View National Sales Head",
  "/deleted-nsh": "Deleted National Sales Head",
  "/add-rsh": "Add Regional Sales Head",
  "/view-rsh": "View Regional Sales Head",
  "/deleted-rsh": "Deleted Regional Sales Head",

  "/marketing": "Marketing",
  "/marketing-contactus": "Marketing Contact Us",
  "/marketing-view-contactus": "Marketing View Contact Us",
  "/add-csh": "Add City Sales Head",
  "/view-csh": "View City Sales Head",
  "/deleted-csh": "Deleted City Sales Head",

  "/add-se": "Add Sales Executive",
  "/view-se": "View Sales Executive",
  "/deleted-se": "Deleted Sales Executive",

  "/add-city": "Add City",
  "/view-city": "View City",
  "/assigned-leads": "Assigned Leads",
  "/add-meeting": "Add Meeting",
  "/view-meetings": "View Meetings",
  "/sendLeadsToNSH": "Send Leads To NSH",
  "/getLeads": "Get Leads",
  "/getLeadsfromnsh": "Get Leads From NSH",
  "/getLeadsfromrsh": "Get Leads From RSH",

  "/add-pan-products": "Add PAN Products",
  "/add-new-product": "Add New Product",

  "/add-category": "Add Product Main Category",
  "/add-subcategory": "Add Product Sub Category",
  "/view-category": "View Product Categories",
  "/franchise-divert-request": "View Divert Franchise Request",
  "/approval-from-franchise": "Approval From Franchise",
};

export const pages = [
  "Add Blog",
  "View Blog",
  "Add Career",
  "View Career",
  "Add Case Study and Testimonial",
  "View Case Study",
  "View Testimonial",
  "Add Franchise",
  "View Franchise",
  "Add E-Store",
  "View E-Store",
  "Show Estore Requests",
  "Add Seller",
  "View Seller",
  // "Add Products",
  "Add Employees",
  "View Employees",
  "Add Designation",
  "Add National Sales Head",
  "View National Sales Head",
  "Deleted National Sales Head",
  "Add Regional Sales Head",
  "View Regional Sales Head",
  "Deleted Regional Sales Head",

  "Marketing",
  "Marketing Contact Us",
  "Marketing View Contact Us",
  "Add City",
  "View City",
  "Approve Clients",
  "Support Team",
  "Send Lead to Sales",
  "Sales List",
  "Client List",
  "Marketing Details",
  "Get leads from Sales",
  "Add City Sales Head",
  "View City Sales Head",
  "Deleted City Sales Head",

  "Add Sales Executive",
  "View Sales Executive",
  "Deleted Sales Executive",

  "Assigned Leads",
  "Add Meeting",
  "View Meetings",
  "View Approved Clients",
  "View Client Approvals",
  "My Client Approvals",
  "Send Leads To NSH",
  "Get Leads",
  "Get Leads From NSH",
  "Get Leads From RSH",

  "Add Product Main Category",
  "Add Product Sub Category",
  "View Product Categories",
  "View Divert Franchise Request",
];

//get Page Name
export const getPageName = () => {
  const pathname = window.location.pathname;
  return matchNavLinkToLabel[pathname];
};

export const indianStates = [
  {
    value: "Andaman and Nicobar Islands (AN)",
  },
  {
    value: "Andhra Pradesh (AD)",
  },
  {
    value: "Arunachal Pradesh (AR)",
  },
  {
    value: "Assam (AS)",
  },
  {
    value: "Bihar (BH)",
  },
  {
    value: "Chandigarh (CH)",
  },
  {
    value: "Chhattisgarh (CT)",
  },
  {
    value: "Dadra and Nagar Haveli (DN)",
  },
  {
    value: "Daman and Diu (DD)",
  },
  {
    value: "Delhi (DL)",
  },
  {
    value: "Goa (GA)",
  },
  {
    value: "Gujarat (GJ)",
  },
  {
    value: "Haryana (HR)",
  },
  {
    value: "Himachal Pradesh (HP)",
  },
  {
    value: "Jammu and Kashmir (JK)",
  },
  {
    value: "Jharkhand (JH)",
  },
  {
    value: "Karnataka (KA)",
  },
  {
    value: "Kerala (KL)",
  },
  {
    value: "Ladakh (LA)",
  },
  {
    value: "Lakshadweep (LD)",
  },
  {
    value: "Madhya Pradesh (MP)",
  },
  {
    value: "Maharashtra (MH)",
  },
  {
    value: "Manipur (MN)",
  },
  {
    value: "Meghalaya (ME)",
  },
  {
    value: "Mizoram (MI)",
  },
  {
    value: "Nagaland (NL)",
  },
  {
    value: "Odisha (OR)",
  },
  {
    value: "Pondicherry (PY)",
  },
  {
    value: "Punjab (PB)",
  },
  {
    value: "Rajasthan (RJ)",
  },
  {
    value: "Sikkim (SK)",
  },
  {
    value: "Tamil Nadu (TN)",
  },
  {
    value: "Telangana (TS)",
  },
  {
    value: "Tripura (TR)",
  },
  {
    value: "Uttar Pradesh (UP)",
  },
  {
    value: "Uttarakhand (UT)",
  },
  {
    value: "West Bengal (WB)",
  },
];

export const enterpriseTypes = [
  {
    value: "Micro",
    label: "Micro",
  },
  {
    value: "Small",
    label: "Small",
  },
  {
    value: "Medium",
    label: "Medium",
  },
];

export const goodsTypes = [
  {
    value: "Micro",
    label: "Micro",
  },
  {
    value: "Small",
    label: "Small",
  },
  {
    value: "Medium",
    label: "Medium",
  },
];

export const getRoleSF = {
  "Super Admin": "sa",
  "National Sales Head": "nsh",
  "Regional Sales Head": "rsh",
  "City Sales Head": "csh",
  "Sales Executive": "se",
};

export const extraRoleBasedPrivileges = {
  "Super Admin": [
    "View Prospects",
    "View Rate Contract",
    "Show Estore Requests",
    "Show Locations",
    "Missed Meetings",
    "View City",
    "Add Product Main Category",
    "Add Product Sub Category",
    "View Product Categories",
    "Seller Products Requests",
    "View Divert Franchise Request",
  ],
  "National Sales Head": ["Edit Lead", "View Prospects", "View Rate Contract"],
  "Regional Sales Head": ["Edit Lead", "View Prospects", "View Rate Contract"],
  "City Sales Head": ["Edit Lead", "View Prospects", "View Rate Contract"],
  "Sales Executive": [
    "Edit Lead",
    "Add Prospect",
    "View Prospects",
    "Generate Rate Contract",
    "View Rate Contract",
    "Client Asking Price For SE",
    "Add Region",
    "Add Region Clusters",
    "Add Cluster Cities",
    "Add Branch",
    "View Branch",
  ],
  "Product Manager": [
    "Add PAN Products",
    "Show Seller Products",
    "Seller Products Requests",
    "Show Final Products",
    "Show SE Requests",
    "Client Asking Price Approval",
    "Add New Product",
    "Add Product Main Category",
    "Add Product Sub Category",
    "View Product Categories",
    "Approval From Franchise",
  ],
  "User Manager": ["Franchise Block", "Franchise Divert"],
  Marketing: ["Social Media Leads"],
};

export const drawerCollapseable = {
  Marketing: [
    "Add Blog",
    "View Blog",
    "Add Career",
    "View Career",
    "Add Case Study and Testimonial",
    "View Case Study",
    "View Testimonial",
    "Marketing",
    // "Marketing Contact Us",
    "Marketing View Contact Us",
  ],
  Franchise: [
    "Add Franchise",
    "View Franchise",
    "View Divert Franchise Request",
  ],
  "E-Store": ["View E-Store", "Show Estore Requests"],
  Seller: ["Add Seller", "View Seller"],
  "Product Manager": [
    // "Add Products",
    "Add PAN Products",
    "Show Products",
    "Show Final Products",
    "Seller Products Requests",
    "Show SE Requests",
    "Client Asking Price For SE",
    "Add New Product",
    "Approval From Franchise",
  ],
  "Employee": ["Add Employees", "View Employees", "Add Designation"],
  "Sales": [
    "Add National Sales Head",
    "View National Sales Head",
    "Deleted National Sales Head",
    "Add Regional Sales Head",
    "View Regional Sales Head",
    "Deleted Regional Sales Head",
    "Add City Sales Head",
    "View City Sales Head",
    "Deleted City Sales Head",
    "Add Sales Executive",
    "View Sales Executive",
    "Deleted Sales Executive",
  ],
  "Client": [
    "View Approved Clients",
    // "View Client Approvals",
    // "My Client Approvals",
  ],
  "Lead": [
    "Get Leads",
    "Get Leads From NSH",
    "Get Leads From RSH",
    "Send Leads To NSH",
    "Assigned Leads",
    "Add Meeting",
    "View Meetings",
    "Send lead to NSH",
    "Add Lead",
    "Send Leads To Sales User",
    "Add Prospect",
    "View Prospects",
    "Social Media Leads",
    "Show Locations",
    "Missed Meetings",
  ],
  Other: [
    "Add City",
    "View City",
    "Add User Manager",
    "Add Product Main Category",
    "Add Product Sub Category",
    "View Product Categories",
  ],
};

export const gstTypes = [
  {
    value: "SGST/CGST",
    label: "SGST/CGST",
  },

  {
    value: "IGST",
    label: "IGST",
  },

  {
    value: "SEZ",
    label: "SEZ",
  },
];

// export const MAIN_SERVER = "https://nexstoc.herokuapp.com";

export const MAIN_SERVER = "http://localhost:3001";

export const TEAM_A_SERVER = "https://nexstoc-team-a.herokuapp.com";

export const TEAM_B_SERVER = "https://teambserver.herokuapp.com";

// export const CLIENT = "";

export function checkDistance(checkPoint, centerPoint, km) {
  if (
    !checkPoint.lng ||
    !checkPoint.lat ||
    !centerPoint.lng ||
    !centerPoint.lat
  ) {
    return { inRadius: false, distance: 0 };
  }
  // console.log("checkpoitn: ", checkPoint);
  // console.log("centerpoint: ", centerPoint);
  var ky = 40000 / 360;
  var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
  var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
  let dist = Math.sqrt(dx * dx + dy * dy);
  return { inRadius: dist <= km, distance: dist };
}

export async function getBranchLocation(
  name,
  address,
  city,
  state,
  postalCode
) {
  // const locationName = name;
  const locationAddress = address;
  const locationCity = city;
  const locationState = state;
  const locationPostalCode = postalCode;

  const completeAddress =
    // locationName
    //   ? locationName + ", "
    //   : " " +
    locationAddress +
    ", " +
    locationCity +
    ", " +
    locationState.substr(0, state.length - 5) +
    ", India, " +
    locationPostalCode;

  let branchLocation = {};
  const location = await getLocationUsingAddress({
    address: completeAddress,
  });
  if (location.status === 200) {
    branchLocation["lat"] = location.lat;
    branchLocation["lng"] = location.lng;
  }
  return branchLocation;
}

export const NO_IMAGE_URL =
  "https://firebasestorage.googleapis.com/v0/b/nexstoc-8fb29.appspot.com/o/product-images%2Fno-image.png?alt=media&token=d1df9cbf-ca3a-46d2-ad87-6891c9eb79b0";

export const SAMPLE_BRANCH_URL =
  "https://firebasestorage.googleapis.com/v0/b/nexstoc-8fb29.appspot.com/o/sample%2Fsample.xlsx?alt=media&token=425d8f39-e81b-4011-96b7-a1d4900d61ec";

export const clientModelTypes = {
  model1: "Model 1",
  model2: "Model 2",
  model3: "Model 3",
};

export const billingCycleTypes = {
  weekly: "Weekly",
  twice_a_month: "Twice A Month",
  monthly: "Monthly",
};

export const costCenterTypes = {
  pan_India_category: "PAN India + Category",
  pan_India_department: "PAN India + Department",
  branch_category: "Branch wise + Category",
  branch_department: "Branch wise + Department",
  regional_category: "Regional wise + Category",
  regional_department: "Regional wise + Department",
  city_cluster_category: "City/Cluster wise + Category",
  city_cluster_department: "City/Cluster wise + Department",
};

export const ProspectStatuses = {
  APPROVED: "Approved",
  REJECTED: "Rejected",
  PENDING: "Pending",
  NOT_SENT: "Not Sent",
};

export const UnitValues = {
  BOX: "Box",
  BOTTLE: "Bottle",
  CAN: "Can",
  DOZEN: "Dozen",
  FEET: "Feet",
  GRAM: "Gram",
  KILO: "Kilogram",
  LITRE: "Litre",
  METER: "Meter",
  NOS: "NOS",
  PIECES: "Piece",
  PAIR: "Pair",
  PACKET: "Packet",
  ROLL: "Roll",
  TON: "Ton",
  SQ_FEET: "Square Feet",
  SQ_METER: "Square Meter",
};

export const BlockingCategories = {
  "Pan India": "Pan India",
  Region: "Regions",
  Cluster: "Clusters",
  City: "Cities",
  Branch: "Branches",
  // Department: "Departments",
};

export const BlockingCategoriesCollection = {
  Region: "region_id",
  Cluster: "cluster_id",
  City: "city_id",
  Branch: "branch_id",
  // Department: "department_id",
};
export const ClientAdminName = {
  "Pan India": "Super Admin",
  Region: "Region Admin",
  Cluster: "Cluster Admin",
  City: "City Admin",
  Branch: "Branch Admin",
  // Department: "Department Admin",
};
export const userRoleStorageKey = "role";
export const userPrevilegekey = "previleges";

export const prospectSortStatus = [
  {
    value: "SKU ID Ascending",
    label: "SKU ID Ascending",
  },
  {
    value: "SKU ID Descending",
    label: "SKU ID Descending",
  },
];